<design-radio-expansion-panel
  class="measurement-group-list"
  *ngIf="extendedMeasurements() as measurements"
>
  <design-expansion-panel-header
    color="secondary"
    class="measurement-group-list--header"
  >
    <div designLabel class="measurement-group-list--header-label">
      <span class="name">Area Measurements ({{ measurements.length }})</span>
    </div>
  </design-expansion-panel-header>
  <ng-template designRadioExpansionPanelContent>
    <ng-container
      *ngFor="let measurement of measurements; trackBy: areaMeasurementsId"
    >
      <feature-measurement-item
        [measurement]="measurement"
        [suffix]="suffix"
        class="measurement-item"
      >
        <ng-template #suffix>
          <button design-icon-button [designMenuTriggerFor]="menu">
            <i designIcon="more_second"></i>
          </button>
          <design-menu-panel #menu>
            <ng-container
              *ngIf="
                measurement.rootNoteId &&
                  !canAttachDetachToNote(measurement.rootNoteCreatorId!);
                else Detach
              "
            >
              <design-tooltip
                i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
                tooltip="Unsufficient privileges"
                position="left"
              >
                <ng-template [ngTemplateOutlet]="Detach"></ng-template>
              </design-tooltip>
            </ng-container>

            <ng-template #Detach>
              <button
                *ngIf="measurement.rootNoteId"
                design-menu-item
                (click)="unassignFromNote(measurement)"
                [disabled]="
                  !canAttachDetachToNote(measurement.rootNoteCreatorId!)
                "
                i18n="@@AREM_DETACH_FROM_NOTE"
              >
                Detach From Note
              </button>
            </ng-template>
          </design-menu-panel></ng-template
        >
      </feature-measurement-item>
    </ng-container>
  </ng-template>
</design-radio-expansion-panel>
