import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StageComponent } from '@simlab/data-access';
import { ComponentsFacade } from '@simlab/data-store';
import { map, Observable } from 'rxjs';

@Injectable()
export class CanLoadSynchronizationGuard  {
  constructor(
    private readonly componentsFacade: ComponentsFacade,
    private readonly router: Router
  ) {}
  canActivate(): Observable<boolean> {
    return this.componentsFacade.selectedComponent$.pipe(
      map((component: StageComponent | undefined) => {
        if (component === undefined) {
          const indexToStart = window.location.href.indexOf('/project');
          const indexToEnd = window.location.href.indexOf('/synchronization');
          this.router.navigate([
            window.location.href.slice(indexToStart, indexToEnd),
          ]);
          return false;
        }
        return true;
      })
    );
  }
}
