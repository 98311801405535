import { computed, inject, Injectable, Signal } from '@angular/core';
import { Marker } from '@simlab/data-access';
import { TagNoteTypes } from '@simlab/matterport/index';
import { ApiProcoreService } from '@simlab/procore/data-access';
import { RFIStatus } from '@simlab/procore/models';
import { RFIListStore } from '@simlab/procore/services';
import { map, mergeMap, of, tap } from 'rxjs';
import { Vector3 } from 'three';
import {
  AnnotationMarker,
  AnnotationTagPlacement,
  PlaceMarkerInAnnotationParams
} from '../tag-placement.component';

@Injectable()
export class RFITagPlacementService implements AnnotationTagPlacement {
  private readonly _api = inject(ApiProcoreService);
  private readonly _rfiListStore = inject(RFIListStore);

  readonly hasMarker: Signal<boolean | undefined> = computed(
    () => this._rfiListStore.itemSelected()?.marker !== undefined
  );
  readonly selectedAnnotationId$ = this._rfiListStore.getSelectedItemId$();

  readonly removePosition$ = () =>
    this.selectedAnnotationId$.pipe(
      mergeMap((id) =>
        this._api.removeMarkerFromRFI(id).pipe(
          map(() => {
            const selectedPunchItem = this._rfiListStore.itemSelected();
            if (selectedPunchItem === undefined) return <AnnotationMarker>{};

            return this._getMappedPunchItemToAnnotationMarker();
          }),
          tap(() => this._rfiListStore.updateSelectedItemMarker(undefined))
        )
      )
    );

  readonly initMarkerSet$ = () =>
    of(this._getMappedPunchItemToAnnotationMarker());

  placeMarkerInAnnotation$(params: PlaceMarkerInAnnotationParams) {
    const marker: Marker = {
      position: params.convertedPosition,
      rotation: { x: 0, y: 0, z: 0, w: 1 },
      anchorPointNormal: new Vector3(0, 0, 0)
    };

    return this._api
      .placeMarkerToRFI({ id: params.annotationId, ...marker })
      .pipe(
        tap(() => {
          this._rfiListStore.updateSelectedItemMarker(marker);
        }),
        map(() => params.annotationId)
      );
  }

  private _getMappedPunchItemToAnnotationMarker(): AnnotationMarker {
    const rfiItem = this._rfiListStore.itemSelected();
    if (rfiItem === undefined) throw new Error('RFI is not selected');

    const rfiIcon: Record<RFIStatus, TagNoteTypes> = {
      [RFIStatus.Closed]: <TagNoteTypes>'RFI_CLOSED',
      [RFIStatus.Draft]: <TagNoteTypes>'RFI_DRAFT',
      [RFIStatus.Open]: <TagNoteTypes>'RFI_DRAFT'
    };

    return <AnnotationMarker>{
      id: rfiItem.procoreId,
      marker: rfiItem.marker,
      type: rfiIcon[rfiItem.status]
    };
  }
}
