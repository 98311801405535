<button class="button-slide" design-icon-button (click)="slidePrevious()">
  <i class="rotate-180" designIcon="arrow_big" designSize="1.5rem"></i>
</button>

<div class="scroller" #parent>
  <div class="container" style="transform: translateX(0px)" #container>
    <div class="items-wrapper">
      <ng-container
        *ngFor="
          let item of stages$ | async;
          trackBy: trackById;
          let idx = index
        "
      >
        <div
          [ngClass]="{ selected: item.id === selectedElement }"
          class="item"
          [id]="item.id"
          #items
          (click)="selectItem(items.id)"
        >
          <div class="item__number">
            {{ idx + 1 | number: '2.0-0' }}
          </div>
          <div class="item__info">
            <div class="item__info--name">{{ item.name }}</div>
            <div class="item__info--date">
              {{ item.stageDate | formatDate | async }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<button class="button-slide" design-icon-button (click)="slideNext()">
  <i designIcon="arrow_big" designSize="1.5rem"></i>
</button>
