<div class="place_marker_window">
  <span i18n="@@PLACE_MARKER">PLACE MARKER</span>

  <div class="btn_section">
    <button
      design-icon-button
      (click)="setComponentPosition()"
      [disabled]="placing()"
    >
      @if (noteHasMarker()) {
        <i designIcon="replace" designSize="2rem"></i>
      } @else {
        <i designIcon="add" designSize="2rem"></i>
      }
    </button>
    <button design-icon-button (click)="removePosition()">
      <i designIcon="trash" designSize="2rem"></i>
    </button>
  </div>
  <div class="footer">
    <button
      design-flat-button
      designButtonColor="secondary"
      class="full-w"
      (click)="closeLocalizationEdition()"
    >
      <div i18n="@@CANCEL">Cancel</div>
    </button>
  </div>
</div>
