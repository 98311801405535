import { Injectable } from '@angular/core';
import {
  DocumentsStateService,
  mixinDownloadDocument
} from '@simlab/documents/services';
import { mixinCopyToProcore } from '../pages/documents-page/procore-base-state/procore-copy-to-state';

const _ProcoreDocumentBase = mixinCopyToProcore(
  mixinDownloadDocument(DocumentsStateService)
);
@Injectable()
export class ProcoreDocumentsStateService extends _ProcoreDocumentBase {}
