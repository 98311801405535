import { Pipe, PipeTransform } from '@angular/core';
import { RFIStatus } from '@simlab/procore/models';

const rfiStatusLabel: Record<RFIStatus, string> = {
  [RFIStatus.Draft]: $localize`:@@DRAFT:Draft`,
  [RFIStatus.Open]: $localize`:@@OPEN:Open`,
  [RFIStatus.Closed]: $localize`:@@CLOSED:Closed`
};

@Pipe({ name: 'getRFIStatusLabel', standalone: true })
export class RFIStatusLabelGetterPipe implements PipeTransform {
  transform(status: RFIStatus): string {
    return rfiStatusLabel[status];
  }
}
