import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { fromEvent, takeUntil, tap } from 'rxjs';
import { FormFieldControl } from '../../../ui-form-field/directives/form-field-control.directive';
import { TextareaBaseDirective } from '../../directives/textarea-base.directive';

const ROW_HEIGHT = 19;
@Component({
  selector: 'ui-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [{ provide: FormFieldControl, useExisting: TextareaComponent }],
})
export class TextareaComponent
  extends TextareaBaseDirective
  implements AfterViewInit
{
  @ViewChild('textArea') textarea!: ElementRef<HTMLTextAreaElement>;
  private _realtime = false;
  // private readonly _destroy$: Subject<void> = new Subject<void>();

  @Output() lostFocus: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() detectEnterPressed: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private _tooltipText: string | undefined = undefined;
  @Input() set tooltipText(value: string) {
    this._tooltipText = value;
  }
  get tooltipText(): string | undefined {
    return this._tooltipText;
  }

  @Input() set realtime(value: BooleanInput) {
    this._realtime = coerceBooleanProperty(value);
  }
  get realtime(): boolean {
    return this._realtime;
  }

  get keydownDetect(): BooleanInput {
    return this._keydownDetect;
  }
  @Input()
  set keydownDetect(value: BooleanInput) {
    this._keydownDetect = coerceBooleanProperty(value);
  }
  protected _keydownDetect = false;

  // get readonly(): boolean {
  //   return this._readonly;
  // }

  // @Input()
  // set readonly(value: boolean) {
  //   this._readonly = coerceBooleanProperty(value);
  // }
  // private _readonly = false;
  editMode = false;
  private _minRows = 1;
  public get minRows() {
    return this._minRows;
  }
  @Input() set minRows(value) {
    this._minRows = value;
  }

  public characterInputLength = 0;
  private _maxCharacters = 0;
  @Input() set maxCharacters(value: number) {
    this._maxCharacters = value;
  }

  get maxCharacters(): number {
    return this._maxCharacters;
  }

  private _maxRows = 5;
  public get maxRows() {
    return this._maxRows;
  }
  @Input() set maxRows(value) {
    this._maxRows = value;
  }

  @Input()
  set notEditable(state: BooleanInput) {
    this._notEditable = coerceBooleanProperty(state);
    if (!this._notEditable) {
      this._enableNgContentAction = true;
      this.realtime = false;
    } else {
      this.realtime = true;
    }
  }
  get notEditable(): boolean {
    return this._notEditable;
  }
  protected _notEditable = false;

  @Input() set placeholder(value: string) {
    this._placeholder = value;
  }
  get placeholder() {
    return this._placeholder ?? '';
  }
  protected _placeholder?: string;

  get enableNgContentAction(): BooleanInput {
    return this._enableNgContentAction;
  }

  @Input()
  set enableNgContentAction(value: BooleanInput) {
    this._enableNgContentAction = coerceBooleanProperty(value);
    if (this._enableNgContentAction) {
      this.editMode = true;
    }
  }
  private _enableNgContentAction = false;

  edit() {
    this.editMode = !this.editMode;

    if (this.editMode) {
      this.textarea.nativeElement.focus();
      this.textarea.nativeElement.select();
    }
  }

  focus() {
    this.textarea.nativeElement.focus();
  }
  focusoutEmitter($event: FocusEvent) {
    this.lostFocus.emit(($event.target as HTMLTextAreaElement).value);
    this._onTouched();
  }

  select() {
    this.textarea.nativeElement.select();
  }
  saveChanges(textArea: HTMLTextAreaElement) {
    this.editMode = !this.editMode;
    this.value = textArea.value;
    this._onChange(this.value);
    this.saveChange.emit(this.value);
  }

  discardChanges(textArea: HTMLTextAreaElement) {
    this.editMode = !this.editMode;
    this.tempValue = this.value;
    textArea.value = this.value ?? '';
  }

  valueChange(event: Event) {
    this._onTouched();
    this.characterInputLength = (event.target as HTMLInputElement).value.length;
    if (this._realtime || this._enableNgContentAction) {
      this.value = (event.target as HTMLInputElement).value;
      this._onChange(this.value);
    }
  }

  constructor(
    @Optional()
    @Self()
    private readonly ngControl: NgControl,
    private readonly renderer: Renderer2
  ) {
    super(ngControl);
  }

  ngAfterViewInit(): void {
    this.sizeObserver();
    this._calcSize(this.textarea?.nativeElement);
    this._valueChange.subscribe(() => {
      const observer = new ResizeObserver(() => {
        this._calcSize(this.textarea?.nativeElement);
      });
      observer.observe(this.textarea?.nativeElement);
    });
  }

  sizeObserver() {
    if (this.textarea?.nativeElement) {
      fromEvent<InputEvent>(this.textarea.nativeElement, 'input')
        .pipe(
          tap((event: InputEvent) => {
            this._calcSize(event.target as HTMLTextAreaElement);
          }),
          takeUntil(this._destroySource)
        )
        .subscribe();
    }
  }
  private _calcSize(textArea: HTMLTextAreaElement) {
    if (textArea.value === '') {
      this.renderer.setStyle(textArea, 'height', 'auto');
      return;
    }
    if (textArea.scrollHeight < this.maxRows * ROW_HEIGHT) {
      this.renderer.setStyle(textArea, 'height', 'auto');
      this.renderer.setStyle(textArea, 'height', `${textArea.scrollHeight}px`);
    } else {
      this.renderer.setStyle(
        textArea,
        'height',
        `${this.maxRows * ROW_HEIGHT}px`
      );
    }
  }

  keyDownFunction(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.detectEnterPressed.emit(true);
    }
  }
}
