<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <div class="header">
      <button
        class="top-section__return-button"
        design-stroked-button
        (click)="backTo()"
      >
        <i designIcon="arrow_back" designSize="1.4rem"></i>
        <ng-container i18n="@@BACK_TO_PROJECT_INFO">
          Back to Project info
        </ng-container>
      </button>

      @if (!successConnected()) {
        <button design-icon-button>
          <i designIcon="cross" designSize="1.4rem"></i>
        </button>
      }
    </div>
  </ng-container>
  <ng-container designDialogContent>
    <div class="body">
      @if (successConnected().state) {
        <ui-image-info
          class="body__info"
          imageSrc="success_connected_projects"
          header="Success!"
          [descriptions]="[infoString]"
          [maxWidth]="270"
        />
        <div class="connected-projects">
          <procore-linked-project-card [data]="successConnected().stage!" />
          <procore-linked-project-card [data]="successConnected().procore!" />
        </div>
      } @else {
        <div class="body__info-connect" i18n="@@CONNECT_PROJECT_PROCORE">
          Connect {{ nameProject }} with Procore project
        </div>
        <procore-linking-project
          [loadedCompanies]="loadedCompanies()"
          [companies]="companies()"
          [projects]="projects()"
          [(selectedCompany)]="selectedCompany"
          [(selectedProject)]="selectedProject"
        >
          <button
            #connectedButton
            design-flat-button
            designButtonColor="accent"
            type="button"
            [disabled]="disabledButton()"
            i18n="@@CONNECT_PROJECT"
            [designShowLoading]="connectingProject()"
          >
            Connect Projects

            <design-button-loader
              designIconSuffix
              designColor="primary"
            ></design-button-loader>
          </button>
        </procore-linking-project>
      }
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    @if (successConnected()) {
      <button
        design-flat-button
        designButtonColor="accent"
        type="button"
        (click)="close()"
        i18n="@@CLOSE"
      >
        Close
      </button>
    }
  </ng-container>
</design-dialog-wrapper>
