import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const RecordAudioAnimation = trigger('activeInactive', [
  state(
    'active',
    style({
      backgroundColor: 'var(--ui-theme-red-300)',
    })
  ),
  state(
    'inactive',
    style({
      backgroundColor: 'var(--ui-theme-blue-300)',
    })
  ),
  transition('active => inactive', [animate('0.5s')]),
  transition('inactive => active', [animate('0.25s')]),
]);
