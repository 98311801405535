import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBase } from '@simlab/data-access';
import {
  DirectoryModel,
  DocumentsFetchQuery,
  DocumentsListResponse,
  MoveResourcesRequest,
  MoveResourcesResponse,
  SubdirectoryModel,
  TDocumentChangeName,
  TDocumentsToZipStatus,
  TElementsToZip,
  TFileModelFull,
  TFileUploadDetails,
  TTaskIdElementsToZip,
  UploadDirectory,
  UploadFile
} from '@simlab/documents/models';
import { requestToHttpParamsMapper } from '@simlab/util/core';
import { Observable } from 'rxjs';
import { StageDocumentsApi } from '../models/stages-documents-api.modal';

@Injectable()
export class StagesDocumentsApiService
  extends ApiBase
  implements StageDocumentsApi
{
  getResourcesDownloadUrls$(documentIds: string[]): Observable<string[]> {
    let params = new HttpParams();
    documentIds.forEach((id) => (params = params.append('DocumentIds', id)));

    return this.httpClient.get<string[]>(
      `${this.url}/construction-site-management-module/get-resources-download-urls?${params.toString()}`
    );
  }

  createDirectory$(
    projectId: string,
    parentId: string,
    name: string
  ): Observable<string> {
    return this.httpClient.post<string>(
      `${this.url}/construction-site-management-module/create-directory`,
      {
        projectId,
        parentId,
        name
      }
    );
  }

  getDirectory$(id: string): Observable<DirectoryModel> {
    return this.httpClient.get<DirectoryModel>(
      `${this.url}/construction-site-management-module/get-directory?id=${id}`
    );
  }

  getDocument$(id: string): Observable<TFileModelFull> {
    return this.httpClient.get<TFileModelFull>(
      `${this.url}/construction-site-management-module/get-document?id=${id}`
    );
  }

  getPackDocumentsToZipStatus$(
    taskId: string
  ): Observable<TDocumentsToZipStatus> {
    return this.httpClient.get<TDocumentsToZipStatus>(
      `${this.url}/construction-site-management-module/get-pack-documents-to-zip-status?TaskId=${taskId}`
    );
  }

  packDocumentsToZip$(
    payload: TElementsToZip
  ): Observable<TTaskIdElementsToZip> {
    return this.httpClient.post<TTaskIdElementsToZip>(
      `${this.url}/construction-site-management-module/pack-documents-to-zip`,
      payload
    );
  }

  changeDocumentName$(payload: TDocumentChangeName): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/construction-site-management-module/change-document-name`,
      payload
    );
  }

  changeDirectoryName$(payload: TDocumentChangeName): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/construction-site-management-module/change-directory-name`,
      payload
    );
  }
  deleteResources$(
    directoryIds: string[] = [],
    documentIds: string[] = []
  ): Observable<void> {
    let params = new HttpParams();
    directoryIds.forEach((id) => (params = params.append('DirectoryIds', id)));
    documentIds.forEach((id) => (params = params.append('DocumentIds', id)));

    return this.httpClient.delete<void>(
      `${this.url}/construction-site-management-module/delete-resources?${params.toString()}`
    );
  }

  fetch$(query: DocumentsFetchQuery): Observable<DocumentsListResponse> {
    const params = requestToHttpParamsMapper(query);

    return this.httpClient.get<DocumentsListResponse>(
      `${this.url}/construction-site-management-module/get-documents`,
      { params }
    );
  }

  moveResources$({
    directoryIds,
    documentIds,
    targetDirectoryId
  }: MoveResourcesRequest): Observable<MoveResourcesResponse> {
    return this.httpClient.put<MoveResourcesResponse>(
      `${this.url}/construction-site-management-module/move-resources`,
      {
        directoryIds,
        documentIds,
        targetDirectoryId
      }
    );
  }

  getSubdirectories$(
    projectId: string,
    directoryId: string
  ): Observable<SubdirectoryModel[]> {
    let params = new HttpParams();
    params = params.append('ProjectId', projectId);
    params = params.append('DirectoryId', directoryId);

    return this.httpClient.get<SubdirectoryModel[]>(
      `${this.url}/construction-site-management-module/get-subdirectories?${params.toString()}`
    );
  }

  addDocumentToProject$(payload: UploadFile): Observable<string> {
    return this.httpClient.post<string>(
      `${this.url}/construction-site-management-module/add-document-to-project`,
      payload
    );
  }

  addDirectoriesToProject$(
    payload: UploadDirectory
  ): Observable<TFileUploadDetails[]> {
    return this.httpClient.post<TFileUploadDetails[]>(
      `${this.url}/construction-site-management-module/add-directories-to-project`,
      payload
    );
  }

  uploadFileToBlob(payload: FormData) {
    return this.httpClient.post(
      `${this.url}/blobs-module/upload-images-to-blob`,
      payload,
      { responseType: 'text' }
    );
  }
}
