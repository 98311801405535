import {
  DestroyRef,
  Directive,
  EventEmitter,
  inject,
  Input,
  NgZone,
  Output
} from '@angular/core';
import { Units } from '@simlab/matterport/api';
import {
  IMeasurementTool,
  MeasurementMode,
  TMeasurementMeshChange,
  TransformControlsEvents
} from '@simlab/simlab-facility-management/sub-features/measurement';
import {
  defer,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  Observable,
  skip,
  switchMap,
  take
} from 'rxjs';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { MatterportComponent } from '../public-api';

const SHOW_MEASUREMENT_KEY = 'V';

@Directive({
  standalone: true
})
export abstract class MatterportBaseMeasurementDirective<
  T,
  W extends {
    id: string;
    color?: string;
  }
> implements IMeasurementTool<T, W>
{
  @Output() readonly successFirstDrawAreaMeasurement$ = defer(() => {
    return this.matterportHost.matterportIsOpen$.pipe(
      filter((e) => e),
      switchMap(() =>
        this.ngZone.run(() =>
          this.mesh.selectedMeasurement$.pipe(
            skip(1),
            take(1),
            distinctUntilChanged()
          )
        )
      )
    );
  });

  set segmentsVisibility(visible: boolean) {}
  readonly destroyRef = inject(DestroyRef);
  readonly ngZone = inject(NgZone);
  readonly matterportHost = inject(MatterportComponent);

  abstract get mesh(): IMeasurementTool<T, W>;

  @Input() set selectedMeasurementMode(mode: MeasurementMode) {
    this.mesh.selectedMeasurementMode = mode;
  }
  get selectedMeasurementMode(): MeasurementMode {
    return this.mesh.selectedMeasurementMode;
  }
  @Input() set selectedMeasurement(areaId: string | undefined) {
    if (areaId === undefined) this.mesh.selectedMeasurement = areaId;
    firstValueFrom(
      this.matterportHost.matterportIsOpen$.pipe(filter((isOpen) => isOpen))
    ).then(() => {
      this.mesh.selectedMeasurement = areaId;
    });
  }
  get selectedMeasurement(): string | undefined {
    return this.mesh.selectedMeasurement;
  }

  isTouchDevice = () => this.mesh.isTouchDevice();

  @Input() set sizeUnit(unit: Units) {
    firstValueFrom(
      this.matterportHost.matterportIsOpen$.pipe(filter((isOpen) => isOpen))
    ).then(() => {
      this.mesh.sizeUnit = unit;
    });
  }

  get canRedo(): boolean {
    return this.mesh.canRedo;
  }
  get canUndo(): boolean {
    return this.mesh.canUndo;
  }
  finish() {
    this.mesh.finish();
  }

  @Output() readonly selectedMeasurement$: Observable<string | undefined> =
    defer(() => {
      return this.matterportHost.matterportIsOpen$.pipe(
        filter((e) => e),
        switchMap(() =>
          this.ngZone.run(() =>
            this.mesh.selectedMeasurement$.pipe(skip(1), distinctUntilChanged())
          )
        )
      );
    });
  @Output() readonly selectedMeasurementMode$: Observable<MeasurementMode> =
    defer(() => {
      return this.matterportHost.matterportIsOpen$.pipe(
        filter((e) => e),
        switchMap(() =>
          this.ngZone.run(() =>
            this.mesh.selectedMeasurementMode$.pipe(skip(1))
          )
        )
      );
    });

  @Output() readonly selectedMeasurementChange$: Observable<
    TMeasurementMeshChange<W> | undefined
  > = defer(() => {
    return this.matterportHost.matterportIsOpen$.pipe(
      filter((e) => e),
      switchMap(() =>
        this.ngZone.run(() =>
          this.mesh.selectedMeasurementChange$.pipe(skip(1))
        )
      )
    );
  });

  @Output() readonly loaded: EventEmitter<void> = new EventEmitter<void>();

  addMeasurement = (area: W): string => {
    return this.mesh.addMeasurement(area);
  };

  createMeasurement = (): string => {
    return this.mesh.createMeasurement();
  };
  cancelMeasurementCreation = () => {
    this.mesh.cancelMeasurementCreation();
  };

  deleteMeasurement = (areaId: string) => {
    this.mesh.deleteMeasurement(areaId);
  };
  deleteSelectedMeasurement = () => {
    this.mesh.deleteSelectedMeasurement();
  };
  editSelectedMeasurement = () => {
    this.mesh.editSelectedMeasurement();
  };
  updateSelectedMeasurementColor = (color: string | undefined) => {
    this.mesh.updateSelectedMeasurementColor(color);
  };

  updateMeasurementColor = (id: string, color: string | undefined) =>
    this.mesh.updateMeasurementColor(id, color);

  addListener = (
    listenerActionType: 'ADD_POINT' | 'REMOVE_POINT' | 'CREATION_POINT'
  ): Observable<string | undefined> => {
    return this.mesh.addListener(listenerActionType);
  };

  removeListener = () => {
    this.mesh.removeListener();
  };
  hideMeasurements = (omit: string[] = []) => {
    this.mesh.hideMeasurements(omit);
  };
  showMeasurements = (omit: string[] = []) => {
    this.mesh.showMeasurements(omit);
  };
  deleteAllMeasurements = () => {
    this.mesh.deleteAllMeasurements();
  };
  undo = () => {
    this.mesh.undo();
  };
  redo = () => {
    this.mesh.redo();
  };

  get transformationEvent$(): Observable<TransformControlsEvents | undefined> {
    return this.mesh.transformationEvent$;
  }
  get selectedComponent(): T | undefined {
    return this.mesh.selectedComponent;
  }
  get transformControls(): TransformControls | undefined {
    return this.mesh.transformControls;
  }
  get hiddenMeasurementsOmit(): undefined | string[] {
    return this.mesh.hiddenMeasurementsOmit;
  }
  get listenersEnabled(): boolean {
    return this.mesh.listenersEnabled;
  }
  get count(): number {
    return this.mesh.count;
  }
  dispose = () => {
    this.mesh.dispose();
  };
}
