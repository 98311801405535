@if (stageWarningInfo(); as info) {
  <div class="wrapper-image-info">
    @if (mobile()) {
      <ui-image-info
        [imageSrc]="info.img"
        [header]="info.header"
        [descriptions]="info.descriptions"
        [maxWidth]="info.phoneWidth ?? 200"
        [button]="info.button"
      ></ui-image-info>
    } @else {
      <ui-image-info
        [imageSrc]="info.img"
        [header]="info.header"
        [descriptions]="info.descriptions"
        [maxWidth]="info.maxWidth ?? 200"
        [button]="info.button"
      ></ui-image-info>
    }
  </div>
}
@if (!elementLoaded()) {
  <ui-progress-spinner class="spinner" uiColor="primary"></ui-progress-spinner>
}

<div class="background">
  <simlab-matterport
    #simlabMatterport
    [localizationPanelOpen$]="localizationPanelOpen$"
    [screenshotMode]="(screenshotMode$ | async) === true"
    [initialPosition]="initialPosition"
    [initialFloor]="initialFloor"
    #simlabMatterportAreaMeasurement="simlabMatterportAreaMeasurement"
    [simlabMatterportAreaMeasurement]="areaMeasurement()"
    #simlabMatterportLineMeasurement="simlabMatterportLineMeasurement"
    [simlabMatterportLineMeasurement]="distanceMeasurement()"
    (selectedMeasurement$)="selectedAreaIdChange($event)"
    (successFirstDrawAreaMeasurement$)="successDrawMeasurement()"
    [sizeUnit]="(userPreferencesUnit$ | async)!"
    (showMeasurementsSegments)="showMeasurementsSegments()"
    (showDistanceMeasurementsSegments)="showDistanceMeasurementsSegments()"
    [segmentsVisibility]="(segmentsVisibility$ | async)!"
    [segmentsLineVisibility]="(segmentsLineVisibility$ | async)!"
  >
    <ng-template #tagPlacement>
      <annotation-tag-placement />
    </ng-template>
  </simlab-matterport>
</div>
@if (
  (matterportIsOpen$ | async) &&
    sidenavContent() === 'measurement-area' &&
    (areaMeasurementComponent$ | async);
  as areaMeasurement
) {
  @if (areaMeasurement.openEditor()) {
    <simlab-area-measurement-tool
      class="measurement-tool"
      (cancelEmit)="cancelMeasurementsData($event)"
      (saveEmit)="saveMeasurementsData($event)"
    />
  }
}

@if (
  (matterportIsOpen$ | async) &&
    sidenavContent() === 'measurement-distance' &&
    (distanceMeasurementComponent$ | async);
  as distanceMeasurement
) {
  @if (distanceMeasurement.openEditor()) {
    <simlab-distance-measurement-tool
      class="measurement-tool"
      (cancelEmit)="cancelMeasurementsDistanceData($event)"
      (saveEmit)="saveMeasurementsDistanceData($event)"
    />
  }
}
