import { inject, Injectable, OnDestroy } from '@angular/core';
import { ApiFacadeService, StageComponent } from '@simlab/data-access';
import { ComponentsFacade, StagesFacade } from '@simlab/data-store';
import {
  MatterportService,
} from '@simlab/matterport';
import { ComponentConfiguration, PlaneConfiguration } from '@simlab/simlab-facility-management/scene-object';
import { Transform } from '@simlab/transform';
import {
  filter,
  map,
  mergeAll,
  mergeMap,
  race,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { Quaternion, Vector3 } from 'three';
import {
  MATTERPORT_TOKEN,
  MatterportBaseService,
} from './matterport-base.service';

@Injectable()
export class MatterportBlueprintService implements OnDestroy {
  private readonly _matterportService = inject<MatterportService>(MATTERPORT_TOKEN);
  private readonly _componentsFacade = inject(ComponentsFacade);
  private readonly _stageFacade = inject(StagesFacade);
  private readonly _apiFacadeService = inject(ApiFacadeService);
  private readonly _matterportBaseService = inject(MatterportBaseService);
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private readonly _localDestroyer: Subject<void> = new Subject<void>();
  readonly blueprint$ = this._stageFacade.selectedId$.pipe(
    switchMap((stageId: string) => {
      return this._componentsFacade.blueprintComponentsForStage$(stageId).pipe(
        map((blueprintComponents: StageComponent[]) => {
          return blueprintComponents.filter((blueprint) => {
            if (!blueprint.visible) {
              const index = this._blueprints.indexOf(blueprint.id);
              if (index >= 0) this._blueprints.splice(index, 1);
              this._matterportService.deleteBlueprint(blueprint.id);
            }
            return blueprint.visible;
          });
        })
      );
    }),
    takeUntil(
      race(this._localDestroyer, this._matterportBaseService.changedComponent)
    )
  );
  private _blueprints: string[] = [];

  constructor(
  ) {
    this._blueprintObserver();
  }
  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }
  private _blueprintObserver() {
    this._matterportBaseService.matterportIsOpen$
      .pipe(
        tap(() => ((this._blueprints = []), this._localDestroyer.next())),
        filter((isOpen) => isOpen),
        switchMap(() =>
          this.blueprint$.pipe(
            mergeAll(),
            filter(
              (blueprint: StageComponent) =>
                this._blueprints.indexOf(blueprint.id) < 0
            ),
            mergeMap((blueprint: StageComponent) =>
              this._apiFacadeService.blobs
                .generateBlobUrlForDownload({ blobUrl: blueprint.componentUrl })
                .pipe(
                  map((componentUrl: string) => ({
                    ...blueprint,
                    componentUrl,
                  })),
                  tap(({ id, offset, componentUrl }: StageComponent) => {
                    try {
                      const { position, rotation, scale, normal } = offset
                        ? JSON.parse(offset)
                        : {
                          ...Transform.default,
                          normal: { x: 0, y: 0, z: 0 },
                        };
                      this._blueprints.push(id);
                      const blueprintConfiguration: ComponentConfiguration =
                      {
                        id,
                        position: new Vector3(
                          position?.x || 0,
                          position?.y || 0,
                          position?.z || 0
                        ),
                        rotation: new Quaternion(
                          rotation?.x || 0,
                          rotation?.y || 0,
                          rotation?.z || 0,
                          rotation?.w || 1
                        ),
                        stemHeight: 0,

                        scale: new Vector3(
                          scale?.x || 1,
                          scale?.y || 1,
                          scale?.z || 1
                        ),
                        normal: new Vector3(
                          normal?.x || 0,
                          normal?.y || 0,
                          normal?.z || 0
                        ),
                        objects: [
                          new PlaneConfiguration({
                            texture: componentUrl,
                          }),
                        ],
                      };
                      this._matterportService
                        .addBlueprint(blueprintConfiguration)
                        .then(() =>
                          this._componentsFacade.blueprintLoaded$.next(id)
                        )
                        .catch(() =>
                          this._componentsFacade.blueprintLoaded$.next(id)
                        );
                    } catch (e) {
                      this._componentsFacade.blueprintLoaded$.next(id);
                    }
                  })
                )
            ),
            takeUntil(this._localDestroyer)
          )
        ),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }
}
