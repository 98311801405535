import { NgModule } from '@angular/core';
import { DesignAutocompleteOrigin } from '../components/autocomplete-origin.directive';
import { DesignAutocompleteTrigger } from '../components/autocomplete-trigger.directive';
import { DesignAutocomplete } from '../components/autocomplete.component';

@NgModule({
  imports: [
    DesignAutocomplete,
    DesignAutocompleteTrigger,
    DesignAutocompleteOrigin,
  ],
  exports: [
    DesignAutocomplete,
    DesignAutocompleteTrigger,
    DesignAutocompleteOrigin,
  ],
})
export class DesignAutocompleteModule {}
