import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  Signal,
  ViewChild,
  computed,
  inject,
  input,
  output
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { SystemOfMeasurement } from '@simlab/data-access';
import { DesignFlatButton, DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';

import {
  UiListOption,
  UiSelectionList,
  UiSelectionListChange
} from '@simlab/design/select-list';
import { UiTooltip } from '@simlab/design/tooltip';
import {
  IAddAreaMeasurementToGroups,
  IAreaMeasurementComponent,
  IAssignAreaMeasurementToRootNote,
  IRemoveAreaMeasurementElement,
  IUpdateAreaMeasurementColor,
  IUpdateMeasurementTitle,
  NoteMeasurement,
  TAddAreaMeasurementTo,
  TBaseUpdate,
  TMeasurement,
  TMeasurementData,
  TMeasurementExtended,
  TMeasurementGroups,
  TVerticle,
  _IAreaMeasurementAction
} from '@simlab/feature-measurement/models';
import { UnitsPreferredByUserPipe } from '@simlab/feature-measurement/pipes';
import { UiHelperModule } from '@simlab/ui/helper';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import { UiMenuModule } from '@simlab/ui/menu';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import {
  BehaviorSubject,
  Observable,
  defer,
  filter,
  firstValueFrom,
  switchMap,
  take,
  tap
} from 'rxjs';
import { MeasurementPermission } from '../../../services/measurement.privileges';
import {
  DISPLAY_VALUE,
  PARAMS_TO_SORT,
  SORT_OPTION,
  TYPE_MEASUREMENT
} from '../../../tokens/token';
import { TDistanceCalculatorMeasurement } from '../dialogs/calculators/calculator-distance/distance-calculator.component';
import { MeasurementItemComponent } from '../measurement-item/measurement-item.component';
import {
  FilterChange,
  SearchAndSortFilterComponent,
  SortOption
} from '../search-and-sort-filter/search-and-sort-filter.component';

export const SELECT_COLOR_DIALOG: import('../dialogs/select-color/select-color.component').SelectColorData =
  {
    title: $localize`:@@AREM_CHANGE_COLOR_FOR_MEASUREMENT:Change color for measurement`,
    description: $localize`:@@AREM_CHOOSE_NEW_COLOR_FOR_SELECTED_AREA:Choose a new color for selected area measurement.`,
    colors: [
      '#CD403D',
      '#F14037',
      '#E4255B',
      '#F5849E',
      '#914386',
      '#5C33AB',
      '#2970EB',
      '#13B2CC',
      '#489D5F',
      '#33CF71',
      '#C6D73F',
      '#FAC729',
      '#FEA42C',
      '#FE6220',
      '#A1AFBB',
      '#56717F'
    ],
    multiselect: false,
    selected: undefined
  };

const GROUP_CONTAIN_AREA_TOOLTIP = $localize`:@@AREM_GROUP_CONTAINS_AREA:Group contains this Area Measurement`;
const INSUFFICIENT_GROUP_PRIVILEGES_TOOLTIP = $localize`:@@AREM_INSUFFICIENT_GROUPS_PRIVILEGES:Insufficient groups privileges`;
const INSUFFICIENT_NOTE_PRIVILEGES_TOOLTIP = $localize`:@@AREM_INSUFFICIENT_NOTES_PRIVILEGES:Insufficient notes privileges`;

const ADD_AREA_TO_GROUP: import('../dialogs/multiselect-list/multiselect-list.component').MultiselectListData =
  {
    description: $localize`:@@AREM_SELECT_GROUP_TO_WHICH_WANT_ADD:Select the group(s) to which you want to add the selected area measurement.`,
    title: $localize`:@@AREM_ADD_GROUP:Add to group`,
    list: []
  };

const DELETE_MEASUREMENT_CONFIRMATION: import('../dialogs/delete-measurement-group/delete-measurement-group.component').DeleteConfirmation =
  {
    description: $localize`:@@AREM_ARE_YOU_SURE_DELETE_MEASUREMENT:Are you sure you want to delete the measurement?`,
    title: $localize`:@@AREM_DELETE_MEASUREMENT:Delete Measurement`
  };
const DELETE_MEASUREMENT_ARE_LINKED = $localize`:@@SELECTED_MEASUREMENT_IS_USED:Selected measurement is linked to other scene elements (group or note).`;

export const SET_MEASUREMENT_TITLE: import('../dialogs/set-name/set-name.component').SetNameDialogData =
  {
    title: $localize`:@@AREM_MEASUREMENT_TITLE:Measurement Title`,
    current: undefined,
    description: $localize`:@@AREM_ENTER_AREA_TITLE:Enter Area Measurement title.`,
    inputLabel: $localize`:@@AREM_MEASUREMENT_TITLE:Measurement Title`,
    inputPlaceholder: $localize`:@@AREM_SET_MEASUREMENT_TITLE:Set measurement title`
  };

const ATTACH_TO_NOTE: import('../dialogs/single-select-list/single-select-list.component').SelectionListData =
  {
    title: $localize`:@@AREM_ATTACH_TO_NOTE: Attach to note `,
    description: $localize`:@@AREM_SELECT_NOTE_WHICH_WANT_ATTACH_SELECTED_AREA:Select the note to which you want to attach the selected area measurement.`,
    selectedId: undefined,
    list: []
  };

const DESCRIPTION_IMAGE_INFO: string[] = [
  $localize`:@@AREM_NO_MEASUREMENTS_INFO_1:Add a measurement to check the size of the Area.`,
  $localize`:@@AREM_NO_MEASUREMENTS_INFO_2:Sum up areas and create groups of measurements.`
];

@Component({
  selector: 'feature-measurements',
  standalone: true,
  imports: [
    CommonModule,
    UiSelectionList,
    UiListOption,
    MeasurementItemComponent,
    DesignFlatButton,
    SearchAndSortFilterComponent,
    UiMenuPanelModule,
    // UnitsPreferredByUserPipe,
    UiImageInfoModule,
    DesignIconButton,
    DesignIcon,
    UiMenuModule,
    UiTooltip,
    UiHelperModule
  ],
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasurementsComponent
  extends MeasurementPermission
  implements IAreaMeasurementComponent<_IAreaMeasurementAction>
{
  readonly descriptionInfo: string[] = DESCRIPTION_IMAGE_INFO;
  private readonly _displayValue = inject(DISPLAY_VALUE);
  private readonly _typeMeasurement = inject(TYPE_MEASUREMENT);
  private readonly _paramsToSort = inject(PARAMS_TO_SORT);
  private readonly _router = inject(Router);
  private readonly _ngZone = inject(NgZone);
  private readonly _dialog = inject(Dialog);
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _unitsPreferredByUserPipe = inject(UnitsPreferredByUserPipe);

  private _selectedMeasurement: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);
  get selectedMeasurement(): string | undefined {
    return this._selectedMeasurement.getValue();
  }
  @Input() set selectedMeasurement(value: string | undefined) {
    const init = !this._selectedMeasurement.getValue();
    this._selectedMeasurement.next(value);

    init
      ? setTimeout(() => {
          this.showSelectedInView();
        }, 300)
      : this.showSelectedInView();
  }

  readonly measurements = input<TMeasurement[]>([]);
  readonly systemOfMeasurement = input<SystemOfMeasurement>();
  readonly measurementsGroup = input<TMeasurementGroups[]>([]);
  readonly notes = input<NoteMeasurement[]>([]);

  readonly onAdd = output();
  readonly edit = output<any>();
  readonly onGoTo = output<string>();
  readonly goToSelected = output<void>();

  readonly openRelatedNote = output<string>();
  readonly unassignFromNote = output<TBaseUpdate>();
  readonly add = output<void | TAddAreaMeasurementTo>();
  readonly changeColor = output<IUpdateAreaMeasurementColor>();
  readonly changeTitle = output<IUpdateMeasurementTitle>();
  readonly selectedMeasurementChange = output<string | undefined>();
  readonly assignToNote = output<IAssignAreaMeasurementToRootNote>();
  readonly addMeasurementToGroups = output<IAddAreaMeasurementToGroups>();
  readonly deleteAreaMeasurement = output<IRemoveAreaMeasurementElement>();

  // only for test
  //constructor() {
  //  super();
  //  this._router.events
  //    .pipe(
  //      filter((event) => event instanceof NavigationEnd),
  //      map((event) => console.log(event))
  //    )
  //    .subscribe();
  //}

  @ViewChild(SearchAndSortFilterComponent, { static: true })
  filters!: SearchAndSortFilterComponent;
  readonly filters$: Observable<FilterChange> = defer(() => {
    const filters = this.filters;
    if (filters) {
      return filters.filterChange;
    }
    return this._ngZone.onStable.pipe(
      take(1),
      switchMap(() => this.filters$)
    );
  });
  readonly filtersSignal = toSignal(
    this.filters$.pipe(
      tap((e) => {
        console.log(e);
      })
    )
  );

  readonly sortOptions: SortOption[] = inject(SORT_OPTION);

  readonly extendedMeasurements: Signal<TMeasurementExtended[]> = computed(
    () => {
      const units = this.systemOfMeasurement() || SystemOfMeasurement.Metric;

      const extendedMeasurements = this.measurements().map((measurement) => {
        return {
          ...measurement,
          displayValue: this._displayValue(
            measurement,
            units,
            this._unitsPreferredByUserPipe
          )
        };
      });
      const filterValue = this.filtersSignal()?.filter || '';
      const sortByValue = this.filtersSignal()?.sortBy || '';

      return extendedMeasurements
        ?.filter((measurement) => {
          return `${measurement.title}${measurement.displayValue}`
            .toLocaleLowerCase()
            .includes(filterValue.toLocaleLowerCase());
        })
        .sort((a: TMeasurementExtended, b: TMeasurementExtended) => {
          if (!sortByValue) return 1;

          const left = this._paramsToSort(a, sortByValue);
          const right = this._paramsToSort(b, sortByValue);
          if (left === undefined || right === undefined) return 1;
          if (sortByValue.direction === 'asc') {
            if (left < right) {
              return -1;
            }
            if (left > right) {
              return 1;
            }
            return 0;
          } else {
            if (left > right) {
              return -1;
            }
            if (left < right) {
              return 1;
            }
            return 0;
          }
        });
    }
  );

  getNoteCreatorId(noteId: string): string {
    return this.notes().find((note) => note.id === noteId)?.creatorId || '';
  }

  showSelectedInView() {
    if (!this.selectedMeasurement) return;
    const selectedOption = document.querySelector(
      `design-list-option[id="${this.selectedMeasurement}"]`
    );
    selectedOption?.scrollIntoView({ behavior: 'smooth' });
    this._cdr.markForCheck();
  }

  trackByMeasurementId(index: number, item: TMeasurement) {
    return item.id;
  }

  async calculator() {
    if (this._typeMeasurement === 'measurement-area') {
      const calculatorModal = (
        await import(
          '../dialogs/calculators/calculator-area/area-calculator.component'
        )
      ).AreaCalculatorComponent;
      this._dialog.open<
        string,
        import('../dialogs/calculators/calculator-area/area-calculator.component').AreaCalculatorData,
        import('../dialogs/calculators/calculator-area/area-calculator.component').AreaCalculatorComponent
      >(calculatorModal, {
        width: 'min(90%, 450px)',
        maxHeight: 'min(750px,90dvh)',
        data: {
          data: this.measurements().map((e) => {
            return { ...e, data: e.data as TMeasurementData };
          }),
          units: this.systemOfMeasurement() || SystemOfMeasurement.Metric
        }
      });
    } else {
      const calculatorModal = (
        await import(
          '../dialogs/calculators/calculator-distance/distance-calculator.component'
        )
      ).DistanceCalculatorComponent;

      this._dialog.open<
        string,
        import('../dialogs/calculators/calculator-distance/distance-calculator.component').DistanceCalculatorData,
        import('..//dialogs/calculators/calculator-distance/distance-calculator.component').DistanceCalculatorComponent
      >(calculatorModal, {
        width: 'min(90%, 450px)',
        maxHeight: 'min(750px,90dvh)',
        data: {
          data: this.measurements().map((e) => {
            const verticle = e.data as TVerticle[];
            const v2 = verticle.map((e) => ({ ...e, id: '' }));

            return {
              ...e,
              data: verticle,
              distance: 0
            } as TDistanceCalculatorMeasurement;
          }),
          units: this.systemOfMeasurement() || SystemOfMeasurement.Metric
        }
      });
    }
  }
  isTouchDevice() {
    return (
      ('ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        ('msMaxTouchPoints' in navigator &&
          (navigator['msMaxTouchPoints'] as number) > 0)) &&
      !window.matchMedia('(pointer:fine)').matches
    );
  }

  async editMeasurement(measurement: TMeasurementExtended) {
    if (this.selectedMeasurement !== measurement.id) {
      this.selectedMeasurementChange.emit(measurement.id);
      await firstValueFrom(
        this._selectedMeasurement.pipe(
          filter((value) => value === measurement.id)
        )
      );
    }
    if (!this.canEditDeleteMeasurement(measurement.creatorId)) return;
    this.edit.emit(measurement);
  }

  async addAreaMeasurementToGroups({ id }: TMeasurement) {
    if (this.selectedMeasurement !== id) {
      this.selectedMeasurementChange.emit(id);
      await firstValueFrom(
        this._selectedMeasurement.pipe(filter((value) => value === id))
      );
    }
    const multiselectList: import('../dialogs/multiselect-list/multiselect-list.component').MultiselectList[] =
      this.measurementsGroup()?.map(
        ({ id: groupId, name, measurements, creatorId }) => {
          const hasPermission = this.canEditDeleteMeasurementGroup(creatorId);
          const groupContainArea = measurements.some((area) => area.id === id);
          return {
            id: groupId,
            name,
            disabled: groupContainArea || !hasPermission,
            selected: groupContainArea,
            disableDescription: `${
              groupContainArea ? GROUP_CONTAIN_AREA_TOOLTIP : ''
            }. ${!hasPermission ? INSUFFICIENT_GROUP_PRIVILEGES_TOOLTIP : ''}`
          };
        }
      ) || [];
    const multiselectData: import('../dialogs/multiselect-list/multiselect-list.component').MultiselectListData =
      {
        ...ADD_AREA_TO_GROUP,
        list: multiselectList
      };
    const addMeasurementsToGroupModal = (
      await import('../dialogs/multiselect-list/multiselect-list.component')
    ).MultiselectListComponent;
    const _dialogRef = this._dialog.open<
      string[],
      import('../dialogs/multiselect-list/multiselect-list.component').MultiselectListData,
      import('../dialogs/multiselect-list/multiselect-list.component').MultiselectListComponent
    >(addMeasurementsToGroupModal, {
      width: 'min(90%, 450px)',
      maxHeight: 'min(750px,90dvh)',
      data: multiselectData
    });
    _dialogRef.closed
      .pipe(
        tap((groupsIds: string[] | undefined) => {
          groupsIds &&
            groupsIds.length &&
            this.addMeasurementToGroups.emit({ id, groupsIds });
        })
      )
      .subscribe();
  }
  async goTo(id: string) {
    if (this.selectedMeasurement !== id) {
      this.selectedMeasurementChange.emit(id);
      await firstValueFrom(
        this._selectedMeasurement.pipe(filter((value) => value === id))
      );
    }
    this.goToSelected.emit();
  }

  async moreInfo({
    createdAt,
    creatorName,
    rootNoteId,
    data,
    id
  }: TMeasurementExtended) {
    if (this.selectedMeasurement !== id) {
      this.selectedMeasurementChange.emit(id);
      await firstValueFrom(
        this._selectedMeasurement.pipe(filter((value) => value === id))
      );
    }
    const measurementInfoModal = (
      await import('../dialogs/measurement-info/measurement-info.component')
    ).MeasurementInfoComponent;
    this._dialog.open<
      string,
      import('../dialogs/measurement-info/measurement-info.component').MeasurementInfoData,
      import('../dialogs/measurement-info/measurement-info.component').MeasurementInfoComponent
    >(measurementInfoModal, {
      width: 'min(90%, 450px)',
      maxHeight: 'min(750px,90dvh)',

      data: {
        createdAt,
        creatorName,
        groupsNames:
          this.measurementsGroup()
            .filter((group) =>
              group.measurements.map(({ id }) => id).includes(id)
            )
            .map(({ name }) => name) || [],
        noteName:
          this.notes().find((note) => note.id === rootNoteId)?.name || '',
        data,
        units: this.systemOfMeasurement() || SystemOfMeasurement.Metric
      },
      providers: [
        {
          provide: UnitsPreferredByUserPipe,
          useValue: this._unitsPreferredByUserPipe
        }
      ]
    });
  }
  async addMeasurementToRootNote({ id, creatorId }: TMeasurementExtended) {
    if (this.selectedMeasurement !== id) {
      this.selectedMeasurementChange.emit(id);
      await firstValueFrom(
        this._selectedMeasurement.pipe(filter((value) => value === id))
      );
    }
    if (!this.canEditDeleteMeasurement(creatorId)) return;
    const selectNoteModal = (
      await import('../dialogs/single-select-list/single-select-list.component')
    ).SingleSelectListComponent;
    const noteList: import('../dialogs/single-select-list/single-select-list.component').SelectionListData['list'] =
      this.notes().map(({ id, name, creatorId }) => ({
        id,
        name,
        disabled: !this.canAttachDetachToNote(creatorId),
        description: `${
          !this.canAttachDetachToNote(creatorId)
            ? INSUFFICIENT_NOTE_PRIVILEGES_TOOLTIP
            : ''
        }`
      })) || [];

    const dialogRef = this._dialog.open<
      import('../dialogs/single-select-list/single-select-list.component').SingleSelectResponse,
      import('../dialogs/single-select-list/single-select-list.component').SelectionListData,
      import('../dialogs/single-select-list/single-select-list.component').SingleSelectListComponent
    >(selectNoteModal, {
      width: 'min(90%, 450px)',
      maxHeight: 'min(750px,90dvh)',

      data: {
        ...ATTACH_TO_NOTE,
        list: noteList
      }
    });
    dialogRef.closed
      .pipe(
        tap((e) => {
          console.log(e);
        }),
        tap(
          (response) =>
            response &&
            this.assignToNote.emit({
              id,
              rootNoteId: response.rootNoteId,
              name: response.name
            })
        )
      )
      .subscribe();
  }
  selectionChange(event: UiSelectionListChange) {
    const selectedId: string = event.options.find(
      (option) => option.selected
    )?.value;
    this.selectedMeasurementChange.emit(selectedId);
    this._selectedMeasurement.next(selectedId);
    this.showSelectedInView();
  }
  async changeTitleModal({ id, title: currentTitle, creatorId }: TMeasurement) {
    if (this.selectedMeasurement !== id) {
      this.selectedMeasurementChange.emit(id);
      await firstValueFrom(
        this._selectedMeasurement.pipe(filter((value) => value === id))
      );
    }
    if (!this.canEditDeleteMeasurement(creatorId)) return;
    const changeNameModal = (
      await import('../dialogs/set-name/set-name.component')
    ).SetNameComponent;
    const dialogRef = this._dialog.open<
      string,
      import('../dialogs/set-name/set-name.component').SetNameDialogData,
      import('../dialogs/set-name/set-name.component').SetNameComponent
    >(changeNameModal, {
      width: 'min(90%, 450px)',
      data: {
        ...SET_MEASUREMENT_TITLE,
        current: currentTitle
      }
    });
    dialogRef.closed
      .pipe(tap((title) => title && this.changeTitle.emit({ id, title })))
      .subscribe();
  }

  async changeColorModal({
    id,
    color: actColor,
    creatorId
  }: TMeasurementExtended) {
    if (this.selectedMeasurement !== id) {
      this.selectedMeasurementChange.emit(id);
      await firstValueFrom(
        this._selectedMeasurement.pipe(filter((value) => value === id))
      );
    }
    if (!this.canEditDeleteMeasurement(creatorId)) return;
    const selectColorModal = (
      await import('../dialogs/select-color/select-color.component')
    ).SelectColorComponent;
    const dialogRef = this._dialog.open<
      string,
      import('../dialogs/select-color/select-color.component').SelectColorData,
      import('../dialogs/select-color/select-color.component').SelectColorComponent
    >(selectColorModal, {
      width: 'min(90%, 450px)',
      data: {
        ...SELECT_COLOR_DIALOG,
        selected: actColor
      }
    });
    dialogRef.closed
      .pipe(tap((color) => color && this.changeColor.emit({ color, id })))
      .subscribe();
  }
  async deleteMeasurement({ id, groupIds, rootNoteId }: TMeasurementExtended) {
    const deleteGroupModal = (
      await import(
        '../dialogs/delete-measurement-group/delete-measurement-group.component'
      )
    ).DeleteMeasurementGroupComponent;
    const areaLinkedToGroups =
      this.measurementsGroup().filter(
        (group) => group.measurements.findIndex((area) => area.id === id) !== -1
      ).length > 0;
    const _dialogRef = this._dialog.open<
      boolean,
      import('../dialogs/delete-measurement-group/delete-measurement-group.component').DeleteConfirmation,
      import('../dialogs/delete-measurement-group/delete-measurement-group.component').DeleteMeasurementGroupComponent
    >(deleteGroupModal, {
      width: 'min(90%, 450px)',
      data: {
        ...DELETE_MEASUREMENT_CONFIRMATION,
        description: `${DELETE_MEASUREMENT_CONFIRMATION.description} ${
          areaLinkedToGroups || rootNoteId ? DELETE_MEASUREMENT_ARE_LINKED : ''
        }`
      }
    });
    _dialogRef.closed
      .pipe(
        tap(
          (deleteConfirm) =>
            deleteConfirm && this.deleteAreaMeasurement.emit({ id })
        )
      )
      .subscribe();
  }
}
