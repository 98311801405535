import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProcoreConnectionStatusEnum } from '@simlab/data-access';
import {
  PROJECTS_FEATURE_KEY,
  State,
  projectsAdapter
} from './projects.reducer';

// Lookup the 'Projects' feature state managed by NgRx
export const getProjectsState =
  createFeatureSelector<State>(PROJECTS_FEATURE_KEY);

const { selectAll, selectEntities } = projectsAdapter.getSelectors();

export const getProjectsLoaded = createSelector(
  getProjectsState,
  (state: State) => state.loaded
);

export const getProjectsMetadata = createSelector(
  getProjectsState,
  (state: State) => state.metadata
);

export const getProjectsError = createSelector(
  getProjectsState,
  (state: State) => state.error
);

export const getAllProjects = createSelector(getProjectsState, (state: State) =>
  selectAll(state)
);

export const getProjectsEntities = createSelector(
  getProjectsState,
  (state: State) => selectEntities(state)
);

export const getProjectById = (getProjectById: string) =>
  createSelector(
    getProjectsState,
    (state: State) => state?.entities[getProjectById] ?? undefined
  );

export const getSelectedId = createSelector(
  getProjectsState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getProjectsEntities,
  getSelectedId,
  (entities, selectedId) => {
    return selectedId ? entities[selectedId] : undefined;
  }
);
export const loading = createSelector(
  getProjectsState,
  (state) => state.loaded
);
export const isSelectedProjectConnectedToProcore = (getProjectById: string) =>
  createSelector(getProjectsEntities, (entities) => {
    return (
      entities[getProjectById]?.procoreConnectionStatus ===
        ProcoreConnectionStatusEnum.Connected || false
    );
  });

export const selectedProjectConnectionProcoreStatus = (projectId: string) =>
  createSelector(
    getProjectsEntities,
    (entities) => entities[projectId]?.procoreConnectionStatus
  );
