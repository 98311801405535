import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class HasOrganizationParamGuard  {
      private readonly router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return route.queryParams['organizationId']
      ? true
      : this.router.parseUrl('/projects');
  }
}
