import { computed, inject, Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  procoreBaseInfoPayload,
  ProcoreBaseProjectInfo,
  PunchItem,
  PunchItemDisplayElements
} from '@simlab/procore/models';
import { PunchItemsListStore } from '@simlab/procore/services';
import { distinctUntilChanged, filter, pipe, switchMap, tap } from 'rxjs';

type TPunchItemState = {
  punchItem: PunchItem;
  displayStatus: PunchItemDisplayElements | undefined;
  isStatusLoading: boolean;
};

const initialState: TPunchItemState = {
  displayStatus: undefined,
  punchItem: {
    id: '',
    procoreId: 0,
    stageId: '',
    attachments: [],
    assignments: [],
    assignees: [],
    ballInCourt: [],
    showYellowBackground: true,
    link: '',
    costCode: {
      id: 0,
      name: '',
      fullCode: ''
    },
    costImpact: 0,
    costImpactAmount: 0,
    canEmail: false,
    comments: [],
    currentDrawingRevisionIds: [],
    createdAt: '',
    createdBy: {
      id: 0,
      login: '',
      name: '',
      companyName: ''
    },
    customFields: {
      additionalProp1: {
        dataType: '',
        value: ''
      },
      additionalProp2: {
        dataType: '',
        value: ''
      },
      additionalProp3: {
        dataType: '',
        value: ''
      }
    },
    deletedAt: '',
    description: '',
    dueDate: '',
    drawingIds: [],
    hasAttachments: false,
    hasResolvedResponses: false,
    hasUnresolvedResponses: false,
    closedAt: '',
    closedBy: {
      id: 0,
      login: '',
      name: '',
      companyName: ''
    },
    location: {
      id: 0,
      name: '',
      nodeName: '',
      parentId: 0,
      createdAt: '',
      updatedAt: ''
    },
    marker: undefined,
    distributionMembers: [],
    trade: {
      id: 0,
      name: '',
      active: false,
      updatedAt: false
    },
    reference: '',
    finalApprover: {
      id: 0,
      login: '',
      name: '',
      companyName: ''
    },
    name: '',
    position: 0,
    priority: 1,
    private: false,
    punchItemManager: {
      id: 0,
      login: '',
      name: '',
      companyName: ''
    },
    punchItemType: {
      id: 0,
      name: ''
    },
    scheduleImpact: 0,
    scheduleImpactDays: 0,
    status: 1,
    updatedAt: '',
    workflowStatus: 1,
    richTextDescription: '',
    itemUrl: ''
  },
  isStatusLoading: false
};

@Injectable()
export class PunchItemState {
  private readonly _store = inject(PunchItemsListStore);
  private readonly _api = inject(ApiProcoreService);
  private readonly _baseInfoProcore = inject(procoreBaseInfoPayload);

  private readonly _state = signalState({
    ...initialState,
    punchItem: { ...initialState.punchItem }
  });

  readonly isLoading = computed(() => {
    const isStatusLoading = this._state.isStatusLoading();
    const isItemLoading = this._store.isItemLoading();

    return isStatusLoading || isItemLoading;
  });
  readonly punchItem = this._state.punchItem;
  readonly displayStatus = this._state.displayStatus;

  readonly loadStatus = rxMethod<ProcoreBaseProjectInfo | undefined>(
    pipe(
      filter(
        (procoreData): procoreData is ProcoreBaseProjectInfo =>
          procoreData !== undefined
      ),
      switchMap(({ procoreCompanyId, procoreProjectId }) =>
        this._store.getItemSelected$().pipe(
          distinctUntilChanged(),
          tap(() => patchState(this._state, { isStatusLoading: true })),
          switchMap(({ procoreId }) => {
            return this._api
              .getPunchItemDisplayElements({
                procoreCompanyId,
                procoreProjectId,
                punchItemId: procoreId
              })
              .pipe(
                tapResponse({
                  next: (displayStatus) =>
                    patchState(this._state, { displayStatus }),
                  error: console.error,
                  finalize: () =>
                    patchState(this._state, { isStatusLoading: false })
                })
              );
          })
        )
      )
    )
  );

  readonly loadPunchItem = rxMethod<void>(
    pipe(
      switchMap(() =>
        this._store
          .getItemSelected$()
          .pipe(tap((punchItem) => patchState(this._state, { punchItem })))
      )
    )
  );

  constructor() {
    this.loadPunchItem();
    this.loadStatus(this._baseInfoProcore());
  }

  deletePunchItem() {
    const { procoreProjectId, procoreCompanyId } =
      this._baseInfoProcore() || {};
    const selectedItem = this._store.itemSelected();
    const displayStatus = this.displayStatus();
    if (
      !selectedItem ||
      !displayStatus ||
      !procoreProjectId ||
      !procoreCompanyId
    )
      return;

    this._store.deleteItem({
      procoreId: selectedItem.procoreId,
      procoreProjectId,
      procoreCompanyId,
      withActionNumber: displayStatus.showYellowBackground
    });
  }
}
