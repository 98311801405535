<img
  class="model-list-item--image"
  [src]="item.image || 'assets/placeholders/default_project.png'"
  alt=""
  draggable="false"
/>
<div class="container">
  <div class="model-list-item--name">{{ item.name }}</div>
  <div style="flex: 1"></div>
  <div *ngIf="item.address" class="model-list-item--address">
    <span>{{ item.address }}</span>
  </div>
  <div class="model-list-item--date">
    {{ item.created | formatDate | async }}
  </div>
</div>
