<div class="thumbnail-container" (click)="navigateToProject()">
  @if (thumbnail !== undefined) {
    <img [src]="thumbnail" alt="" class="thumbnail" draggable="false" />
  } @else {
    <i ui-icon name="icon_stages" size="img"></i>
  }

  @if (!item().isActive) {
    <div class="wrapper-project-archived">
      <div class="project-archived" i18n="@@PROJECT_ARCHIVED">
        PROJECT ARCHIVED
      </div>
    </div>
  }
</div>
<div
  class="body-container"
  (click)="navigateToProject()"
  style="cursor: pointer"
>
  <h1 class="truncate" data-cy="name-project-text">{{ item().name }}</h1>
  <div class="inner-body">
    <div class="section section--left">
      <div class="section-element">
        <b i18n="@@AUTHOR_WITH_COLON">Author:</b>
        <div class="truncate" data-cy="author-project-text">
          {{ item().creatorName }}
        </div>
      </div>
      <div class="section-element">
        <b i18n="@@ORGANIZATION_WITH_COLON">Organization:</b>
        <div class="truncate" data-cy="organization-project-text">
          {{ item().organizationName }}
        </div>
      </div>
    </div>

    <div class="section">
      <div class="section-element">
        <b i18n="@@CREATION_DATE_WITH_COLON">Creation date:</b>
        <div data-cy="created-project-text">
          {{ item().createdAt | formatDate | async }}
        </div>
      </div>
      @if (item().modifiedAt) {}
      <div class="section-element">
        <b i18n="@@MODIFICATION_DATE_WITH_COLON">Last modified:</b>
        <div data-cy="modify-project-text">
          {{ item().modifiedAt | formatDate | async }}
        </div>
      </div>
    </div>
  </div>
</div>
<ui-divider></ui-divider>
<div class="action-container">
  <button
    design-flat-button
    designButtonColor="secondary"
    (click)="openProjectInfo()"
  >
    <i designIconSuffix designIcon="info_first"></i>
    <div i18n="@@PROJECT_INFO">Project info</div>
  </button>

  @if (procoreProject && item().isActive) {
    <i
      class="icon-procore"
      ui-icon
      name="icon_procore"
      size="img"
      defaultColor
    ></i>
  }

  <design-chip *ngIf="!item().isActive">
    <label designLabe class="view-only-formatter" i18n="@@VIEW_ONLY">
      View Only
    </label>
  </design-chip>
</div>

<button
  design-icon-button
  class="favorite-button"
  [disabled]="!item().isActive"
  (click)="toggleFavorite()"
>
  @if (item().markedAsFavourite) {
    <i
      ui-font
      designIcon="star_filled"
      class="color-favorite"
      designSize="2rem"
    ></i>
  } @else {
    <i ui-font fontName="star" fontColor="#FFFFFF" fontSize="2rem"></i>
  }
</button>
