import { Injectable, OnDestroy, effect, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GlobalFiltersStore } from '@simlab/annotations-filters/services';
import { DateRange, NoteFilters } from '@simlab/data-access';
import {
  NotesFacade,
  ShowcaseNotesFacade,
  StagesFacade,
  UserPreferenceFacade
} from '@simlab/data-store';
import { EmptyObject, SortModel } from '@simlab/design/sort-switch';
import { NoteFiltersStore } from '@simlab/feature-stages/services';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { RouterStoreParams } from '../+state/router.selectors';

/**
 * @desc../models/filter-chip-base
 * Implements interface {@link Filters}
 *  which is used in store and for project request
 */
export class StageFilterBase implements NoteFilters {
  constructor(
    public rootNoteTypes: string[] = [],
    public rootNoteStatuses: string[] = [],
    public rootNoteCreated: DateRange = { from: '', to: '' },
    public rootNoteModified: DateRange = { from: '', to: '' },
    public stageDate: DateRange = { from: '', to: '' },
    public stagesRange: string[] = [],
    public onlyMyNotes = false,
    public rootNoteAuthors: string[] = [],
    public name = '',
    public stakeholders: string[] = [],
    public byNoteAuthor = false,
    public selectedStakeholders = false
  ) {}
}

const FILTER_CHANGE_DEBOUNCE = 300;
@Injectable()
export class StageFiltersService implements OnDestroy {
  private readonly _notesFacade = inject(NotesFacade);
  private readonly _stagesFacade = inject(StagesFacade);
  private readonly _showcaseNotesFacade = inject(ShowcaseNotesFacade);
  private readonly _userPreferenceFacade = inject(UserPreferenceFacade);
  private readonly _globalFiltersStore = inject(GlobalFiltersStore);
  private readonly _noteFiltesStore = inject(NoteFiltersStore);

  private readonly _initialFormValues: { [name: string]: unknown } = {};
  private readonly _filterForm: UntypedFormGroup = this._initFilterForm(
    new StageFilterBase()
  );

  private readonly _filterByName$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  private readonly _selectedPage$: BehaviorSubject<{
    pageSize?: number;
    pageNumber: number;
  }> = new BehaviorSubject<{ pageSize?: number; pageNumber: number }>({
    pageNumber: 1
  });
  private readonly _destroy$: Subject<void> = new Subject<void>();
  private _authorsEmail: string[] = [];
  private _formatDate: 'MM/dd/yyyy' | 'dd/MM/yyyy' = 'dd/MM/yyyy';
  get authorsEmail(): string[] {
    return this._authorsEmail;
  }
  set authorsEmail(value: string[]) {
    this._authorsEmail = value;
  }

  private _stakeholderEmail: string[] = [];
  get stakeholderEmail(): string[] {
    return this._stakeholderEmail;
  }
  set stakeholderEmail(value: string[]) {
    this._stakeholderEmail = value;
  }
  constructor() {
    this._selectedStageObserver$();
    this._filterChangesObserver$();
    this._formatDateUpdated$();

    effect(
      () => {
        const filters = this._globalFiltersStore.filter();
        const noteFilters = this._noteFiltesStore.filter();

        const hasOnlySelectedAuthorFromProcore =
          !!filters.authors.length &&
          filters.authors.every(({ id }) => id === undefined);

        const hasOnlySelectedStakeHoldersFromProcore =
          !!filters.stakeholders.length &&
          filters.stakeholders.every(({ id }) => id === undefined);

        if (
          hasOnlySelectedAuthorFromProcore ||
          hasOnlySelectedStakeHoldersFromProcore
        ) {
          this._notesFacade.clearStore();
          return;
        }

        this._filterForm.patchValue({
          rootNoteAuthors: filters.authors
            .map(({ id }) => id)
            .filter((id): id is string => id !== undefined),
          rootNoteTypes: noteFilters.noteTypes,
          rootNoteStatuses: noteFilters.noteStatuses,
          rootNoteCreated: {
            from: filters.dateCreation.from,
            to: filters.dateCreation.to
          },
          rootNoteModified: {
            from: filters.dateModification.from,
            to: filters.dateModification.to
          },
          stakeholders: filters.stakeholders
            .map(({ id }) => id)
            .filter((id): id is string => id !== undefined),
          stagesRange: noteFilters.stagesRange
        });
      },
      { allowSignalWrites: true }
    );
  }

  ngOnDestroy(): void {
    this._filterByName$.next('');
    this._filterByName$.complete();
    this._destroy$.next();
    this._destroy$.complete();
  }
  /**
   * @description
   * Reset filters value to initial state
   * @returns
   */
  public reset(): void {
    this._filterForm.reset();
    this._filterForm.patchValue(this._initialFormValues);
  }

  /**
   * @description
   * Initialize filter form group and create controls
   * Then return reference
   * @returns
   */
  public _initFilterForm(model: StageFilterBase): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    (Object.keys(model) as Array<keyof NoteFilters>).forEach(
      (prop: keyof NoteFilters) => {
        this._initialFormValues[prop] = model[prop];
        form.addControl(prop, new UntypedFormControl(model[prop]));
      }
    );
    return form;
  }

  /**
   * @description
   * Return reference to filter form
   * @returns
   */
  public get filterForm(): UntypedFormGroup {
    return this._filterForm;
  }

  /**
   * @description
   * Emits any filter value change
   * @returns
   */
  public get filterChanges$(): Observable<StageFilterBase> {
    return this._filterForm.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(FILTER_CHANGE_DEBOUNCE)
    );
  }

  private _formatDateUpdated$(): void {
    this._userPreferenceFacade.getDateFormat$
      .pipe(takeUntil(this._destroy$))
      .subscribe((formaDate) => {
        if (formaDate) {
          this._formatDate = formaDate;
        }
      });
  }

  /**
   * @description
   * Observe on filter value chagnge
   *
   */
  private _filterChangesObserver$(): void {
    this._filterForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(FILTER_CHANGE_DEBOUNCE),
        takeUntil(this._destroy$)
      )
      .subscribe(() => this.applyFilters());
  }

  /**
   * @description
   * Return filter form value
   * @returns
   */
  public get selectedValues(): StageFilterBase {
    return this._filterForm.value;
  }

  /**
   * Set next value in _selectedPage$
   */
  public set selectedPage(pageNumber: number) {
    const selectedPage = this._selectedPage$.getValue();
    this._selectedPage$.next({ ...selectedPage, ...{ pageNumber } });
  }

  pageChange(page: number, name?: string | number) {
    const selectedStagesFilters = (this._filterForm.value as NoteFilters)
      .stagesRange;
    if (selectedStagesFilters.length) {
      this.applyFilters(page);
    } else {
      this._stagesFacade.selectedId$
        .pipe(take(1))
        .subscribe((stageId: string) => {
          this._getStoreData([stageId], page, name?.toString());
        });
    }
  }

  searchByName(name: string | number) {
    this._filterByName$.next(name.toString());

    const selectedStagesFilters = (this._filterForm.value as NoteFilters)
      .stagesRange;
    if (selectedStagesFilters.length) {
      this.applyFilters();
    } else {
      this._stagesFacade.selectedId$
        .pipe(take(1))
        .subscribe((stageId: string) => {
          if (stageId) this._getStoreData([stageId], 1, name.toString());
        });
    }
  }

  sortChange(sort: SortModel | EmptyObject, name: string) {
    const selectedStagesFilters = (this._filterForm.value as NoteFilters)
      .stagesRange;
    if (selectedStagesFilters.length) {
      this.applyFilters();
    } else {
      this._stagesFacade.selectedId$
        .pipe(take(1))
        .subscribe((stageId: string) => {
          this._getStoreData([stageId], 1, name?.toString(), { sort });
        });
    }
  }

  /**
   * Observe when slected stage is changed
   * if it is not selected any other range of stages
   * then get notes from api
   */
  private _selectedStageObserver$() {
    const observerBody$ = (noteId?: string) =>
      this._stagesFacade.selectedId$.pipe(
        filter((selectedStage: string | undefined) => !!selectedStage), //NOTE: (olek) if it is selected stage
        filter(
          () => !(this._filterForm.value as NoteFilters).stagesRange?.length //NOTE: (olek) if it is !!NOT!! selected range of stages
        ),
        map((selectedStage: string | undefined) => selectedStage as string),
        tap(() => (this.selectedPage = 1)), // reset page position
        debounceTime(FILTER_CHANGE_DEBOUNCE), //NOTE: (olek) prevent from fast changes
        takeUntil(this._destroy$),
        tap((selectedStage: string) => {
          this._getStoreData(
            [selectedStage],
            this._selectedPage$.getValue().pageNumber,
            undefined,
            { noteId }
          );
        })
      );

    this._stagesFacade.getRouteNestedParams$
      .pipe(
        take(1),
        mergeMap((routerParams: RouterStoreParams) => {
          return observerBody$(routerParams.queryParams['noteId']);
        })
      )
      .subscribe();

    //TODO: do we need this? :D
    // observerBody$().pipe(skip(1)).subscribe();
  }

  /**
   * Gets ids of all available stages and send request form new filtered data
   */
  public applyFilters(page?: number) {
    this._stagesFacade.selectedId$
      .pipe(take(1))
      .subscribe((selectedStageIs: string) => {
        const nextPage = page
          ? page
          : this._selectedPage$.getValue().pageNumber;
        this._selectedPage$.next({
          ...this._selectedPage$.getValue(),
          pageNumber: 1
        });
        this._getStoreData(
          (() => {
            const stagesRange = this.filterForm.value.stagesRange;

            return Array.isArray(stagesRange) && stagesRange.length
              ? stagesRange
              : [selectedStageIs];
          })(),
          nextPage,
          this._filterByName$.getValue()
        );
      });
  }

  /**
   * Merge selected filters + selectedPage + selectedStage
   * and get data from api
   */
  private _getStoreData(
    forIds: string[] | null,
    page: number,
    name = '',
    { noteId, sort }: { noteId?: string; sort?: SortModel | EmptyObject } = {}
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { stagesRange, ...filters } = this._filterForm
      .value as StageFilterBase;
    const selectedFilters: Omit<StageFilterBase, 'stagesRange'> = {
      ...filters,
      name
    };

    const selectedPage: { pageSize?: number; pageNumber: number } = {
      pageNumber: page
    };

    const notesPayload: {
      stageId: string[] | null;
      page: { pageSize?: number; pageNumber: number };
      rootNotesFilter?: NoteFilters;
      sort?: SortModel | EmptyObject;
    } = {
      stageId: forIds,
      rootNotesFilter: selectedFilters as NoteFilters,
      page: selectedPage,
      sort
    };

    //TODO: select note from query params
    this._notesFacade.initStore(notesPayload, noteId);
    this._showcaseNotesFacade.initStore({
      stageId: notesPayload.stageId,
      rootNotesFilter: notesPayload.rootNotesFilter
    });
  }
}
