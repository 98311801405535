import { NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  inject,
  input,
  model,
  viewChild
} from '@angular/core';
import { outputFromObservable, toSignal } from '@angular/core/rxjs-interop';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { MediaObserverService } from '@simlab/design/layout';
import { SubdirectoryModel } from '@simlab/documents/models';
import { defer, EMPTY, filter, fromEvent, map, merge, switchMap } from 'rxjs';

@Component({
  selector: 'feature-projects-document-move-modal-item',
  standalone: true,
  imports: [DesignIcon, DesignIconButton, NgStyle],
  templateUrl: './document-move-modal-item.component.html',
  styleUrl: './document-move-modal-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    tabindex: '0',
    '[class.disabled]': 'disabled()'
  }
})
export class DocumentMoveModalItemComponent {
  private readonly _media = inject(MediaObserverService);
  private readonly _elementRef = inject(ElementRef);
  private readonly _goBtnView = viewChild('goBtn', {
    read: ElementRef<HTMLButtonElement>
  });

  protected readonly isTouchableScreen = toSignal(
    this._media.isTouchableScreen$
  );

  readonly data = input<SubdirectoryModel | null>(null);
  readonly disabled = input<boolean>(false);
  readonly isCurrentLocation = input<boolean>();
  readonly isPrimary = input<boolean>(false);

  readonly isSelected = model<boolean>(false);

  readonly selection = outputFromObservable(
    this._media.isTouchableScreen$.pipe(
      filter((isTouchableScreen) => !isTouchableScreen),
      switchMap(() =>
        fromEvent(this._elementRef.nativeElement, 'click').pipe(map(() => this))
      )
    )
  );

  readonly open = outputFromObservable(
    defer(() => {
      const hostDblclickEvent$ = fromEvent(
        this._elementRef.nativeElement,
        'dblclick'
      );

      const goBtnView = this._goBtnView();
      const arrowBtnClickEvent$ = goBtnView
        ? fromEvent(goBtnView.nativeElement, 'click')
        : EMPTY;

      return this._media.isTouchableScreen$.pipe(
        switchMap((isTouchableScreen) =>
          isTouchableScreen
            ? fromEvent(this._elementRef.nativeElement, 'click')
            : merge(hostDblclickEvent$, arrowBtnClickEvent$)
        ),
        map(() => this)
      );
    })
  );

  @HostBinding('class.primary') get primaryBindingHandler() {
    return this.isPrimary();
  }

  @HostBinding('class.selected') get isSelectedHandler() {
    return this.isSelected();
  }
}
