import { inject, Pipe, PipeTransform } from '@angular/core';
import { DtoNameId } from '@simlab/design/breadcrumb';
import {
  CUSTOM_ROOT_FOLDER,
  FolderAsPathItem
} from '@simlab/documents/data-access';

@Pipe({
  name: 'getLocationByPath',
  standalone: true
})
export class DocumentsLocationByPathGetterPipe implements PipeTransform {
  private readonly _rootFolder = inject<FolderAsPathItem[]>(CUSTOM_ROOT_FOLDER);
  transform(path: DtoNameId[] | undefined): FolderAsPathItem[] {
    if (path === undefined) return [...this._rootFolder];
    return [...this._rootFolder, ...path];
  }
}
