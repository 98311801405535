import { Pipe, PipeTransform } from '@angular/core';
import { RFIStatus } from '@simlab/procore/models';
import { ICON_TYPE } from '@simlab/ui/icon';

const iconByRFIStatus: Record<RFIStatus, ICON_TYPE> = {
  [RFIStatus.Draft]: 'icon_rfi_open_type',
  [RFIStatus.Open]: 'icon_rfi_open_type',
  [RFIStatus.Closed]: 'icon_rfi_closed_type'
};

@Pipe({ name: 'getRFIIconByStatus', standalone: true })
export class RFIIconTypeGetterPipe implements PipeTransform {
  transform(status: RFIStatus): ICON_TYPE {
    return iconByRFIStatus[status];
  }
}
