import { DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Note } from "@simlab/data-access";
import { NEW_NOTE_LOCALLY_ID, NotesFacade } from "@simlab/data-store";
import { AbstractConstructor, Constructor } from "@simlab/design/internal";
import { PanelRightService } from "@simlab/feature/stages";
import { of, switchMap, take, tap } from "rxjs";

export type CanToggleNoteNotification = {
  toggleNoteNotification(): void;
}

type TCanToggleNoteNotification = Constructor<CanToggleNoteNotification> & AbstractConstructor<CanToggleNoteNotification>;
export function mixinToggleNoteNotification<T extends AbstractConstructor<any>>(base: T): TCanToggleNoteNotification & T;
export function mixinToggleNoteNotification<T extends Constructor<any>>(base: T) {
  return class extends base {
    private readonly _panelRightService = inject(PanelRightService);
    private readonly _notesFacade = inject(NotesFacade);
    private readonly _destroyRef = inject(DestroyRef);
  
    private readonly _selectedNote$ = this._panelRightService.selectedNote$;
  
  
    toggleNoteNotification(): void {
      this._selectedNote$
        .pipe(
          switchMap((note) => {
            if (note.id === NEW_NOTE_LOCALLY_ID) {
              this._notesFacade.saveLocalNote();
              return this._notesFacade.noteAdded$;
            }
            return of(note);
          }),
          tap((note: Note) => {
            this._notesFacade.updateNoteNotification(
              note.id,
              !note.notificationsEnabled
            );
          }),
          take(1),
          takeUntilDestroyed(this._destroyRef)
        )
        .subscribe();
    }
  }
}