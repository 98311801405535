/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { Directive, ElementRef } from '@angular/core';
import { mixinDisabled } from '@simlab/design/internal';

// Boilerplate for applying mixins to UiListItemBase.
const _UiListOptionBase = mixinDisabled(class {});

@Directive({
  host: {
    '[class.ui-list-option--disabled]': 'disabled',
    '[attr.aria-disabled]': 'disabled',
  },
  inputs: ['disabled'],
})
export abstract class UiListOptionBase extends _UiListOptionBase {
  get hostElement(): HTMLElement {
    return this.__elementRef.nativeElement;
  }

  constructor(private readonly __elementRef: ElementRef<HTMLElement>) {
    super();
  }
}
