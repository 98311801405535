import {
  animate,
  AnimationBuilder,
  AnimationMetadata,
  AnimationPlayer,
  style,
  transition,
} from '@angular/animations';
import {
  AfterViewInit,
  computed,
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  filter,
  fromEvent,
  Observable,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { UiTooltip } from './components/ui-tooltip.component';

const TIME_DELAY = '3s';
const TIME_DURATION = '0.2s';

type TooltipOnClickCloseEventBehavior =
  | 'AFTER_DELAY'
  | 'AFTER_CLICK_OUTSIDE_A_CONTENT';

@Directive({
  selector: 'design-tooltip[designTooltipOnClick]',
  standalone: true,
  host: {
    '(click)': 'click()',
  },
})
export class TooltipOnClickDirective implements AfterViewInit, OnChanges {
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _elementRef = inject(ElementRef);
  private readonly _animationBuilder = inject(AnimationBuilder);
  private readonly _isOpen$ = new BehaviorSubject<boolean>(false);

  private readonly _uiTooltip = inject(UiTooltip);
  private readonly _animationElement = computed(
    () => this._uiTooltip.tooltipWrapper()?.nativeElement,
  );
  private _player?: AnimationPlayer;

  readonly uiFireOnClick = input<Observable<unknown>>();
  readonly closeEventBehavior =
    input<TooltipOnClickCloseEventBehavior>('AFTER_DELAY');
  private _fireOnClick$!: Observable<unknown>;

  ngOnChanges(changes: SimpleChanges): void {
    const stream$ = changes['uiFireOnClick']?.currentValue;
    if (stream$ === undefined) return;

    this._fireOnClick$ = stream$;
  }

  ngAfterViewInit(): void {
    this._animationElement().classList.remove('ui-tooltip-wrapper');
    this._animationElement().classList.add('ui-tooltip-wrapper-custom-event');
    this._afterClickOutsideAContentEvent();
  }

  private _afterClickOutsideAContentEvent() {
    this._isOpen$
      .pipe(
        filter(
          (isOpen) =>
            isOpen &&
            this.closeEventBehavior() === 'AFTER_CLICK_OUTSIDE_A_CONTENT',
        ),
        switchMap(() =>
          fromEvent(document, 'click').pipe(
            filter(
              ({ target }) =>
                target instanceof Node &&
                !this._elementRef.nativeElement.contains(target),
            ),
            take(1),
          ),
        ),
        tap(() => {
          this._isOpen$.next(false);
          this._triggerHideAnimation();
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }

  private _triggerHideAnimation(): void {
    const factory = this._animationBuilder.build(this._getHideTooltip());
    this._player = factory.create(this._animationElement());
    this._player.play();
  }

  private _getHideTooltip(): AnimationMetadata[] {
    return [
      style({
        visibility: 'hidden',
        opacity: 0,
      }),

      transition('end => start', []),
    ];
  }

  private _getShowTooltip(): AnimationMetadata[] {
    return [
      style({
        visibility: 'visible',
        opacity: 1,
      }),
      this.closeEventBehavior() === 'AFTER_DELAY'
        ? animate(`${TIME_DURATION} ${TIME_DELAY}`)
        : transition('* => open', [animate(`${TIME_DURATION} ${TIME_DELAY}`)]),
    ];
  }

  private _showAnimated(): void {
    const factory = this._animationBuilder.build(this._getShowTooltip());
    this._player = factory.create(this._animationElement());
    this._player.play();
  }

  click(): void {
    if (!this._fireOnClick$) {
      this._showAnimated();
    } else {
      this._fireOnClick$
        .pipe(
          tap(() => {
            this._showAnimated();
          }),
          take(1),
        )
        .subscribe();
    }

    this._isOpen$.next(true);
  }
}
