import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiHelperModule } from '../ui-helper/public-api';
import { UiMatterportLoadingComponent } from './components/ui-matterport-loading.component';

@NgModule({
  declarations: [UiMatterportLoadingComponent],
  imports: [CommonModule, UiHelperModule],
  exports: [UiMatterportLoadingComponent],
})
export class UiMatterportLoadingModule {}
