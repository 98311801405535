import { DtoNameId } from '../components/breadcrumb.component';

export const breadcrumbPathMockup: DtoNameId[] = [
  { id: 'crypto.randomUUID()', name: 'Main folder 1' },
  { id: 'crypto.randomUUID()', name: 'Folder 2' },
  { id: 'crypto.randomUUID()', name: 'Folder o bardzo długiej nazwie 3' },
  { id: 'crypto.randomUUID()', name: 'Folder o bardzo długiej nazwie 4' },
  { id: 'crypto.randomUUID()', name: 'Folder o bardzo długiej nazwie 5' },
  { id: 'crypto.randomUUID()', name: 'Folder o bardzo długiej nazwie 6' },
];
