import { inject, Injectable, signal } from '@angular/core';

import {
  DocumentStates,
  ErrorState,
  LoadedState,
  LoadingState
} from '@simlab/documents/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentsActionService } from '../documents-action/documents-action.service';
import { DocumentsStore } from '../documents.store';

export type ViewDocumentType = 'TABLE' | 'GRID';

export type DataState =
  | LoadedState<DocumentStates[]>
  | LoadingState
  | ErrorState;

@Injectable()
export class DocumentsStateService extends DocumentsActionService {
  private readonly _documentStore = inject(DocumentsStore);
  private _viewModel: BehaviorSubject<ViewDocumentType> =
    new BehaviorSubject<ViewDocumentType>('TABLE');

  readonly data = this._documentStore.data;
  readonly breadcrumbs = this._documentStore.breadcrumbs;
  readonly isLoading = this._documentStore.isLoading;
  readonly directoryId = this._documentStore.directoryId;
  readonly isFilterMode = this._documentStore.isFilterMode;
  readonly sort = this._documentStore.sort;
  readonly searchValue = this._documentStore.searchInput;
  readonly selectedIds = this._documentStore.selectedIds;
  readonly setFilters = this._documentStore.setFilters;
  readonly setSort = this._documentStore.setSort;
  readonly updateSearchName = this._documentStore.updateSearchName;

  readonly viewModel$: Observable<ViewDocumentType> =
    this._viewModel.asObservable();

  readonly sidenavEndOpen = signal(false);
  selectDocument!: (documentId: string) => void;

  changeViewModel(value: ViewDocumentType) {
    this._viewModel.next(value);
  }

  setSelectedIds = this._documentStore.setSelected;

  toggleSidenavEndState() {
    this.sidenavEndOpen.set(!this.sidenavEndOpen());
  }

  openSidenav(documentId: string) {
    this.sidenavEndOpen.set(true);
    this.selectDocument(documentId);
  }
}
