import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_URL } from '@simlab/data-access';
import { CollaboratorProjectAndProcore } from '@simlab/feature-stages/models';
import { Observable } from 'rxjs';

@Injectable()
export class ApiProjectService {
  private readonly _url = inject<string>(API_URL);
  private readonly _httpClient = inject(HttpClient);

  getProjectAndProcoreCollaborators(
    projectId: string
  ): Observable<CollaboratorProjectAndProcore[]> {
    return this._httpClient.get<CollaboratorProjectAndProcore[]>(
      `${this._url}/organizations-module/get-project-stages-and-procore-collaborators?ProjectId=${
        projectId
      }`
    );
  }
}
