import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { StagesFacade } from '@simlab/data-store';
import { mergeMap, Observable, of, take } from 'rxjs';

@Injectable()
export class FetchAvailableStagesResolver  {
  constructor(private readonly stagesFacade: StagesFacade) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.stagesFacade.getRouteNestedParams$.pipe(
      take(1),
      mergeMap((data: { params: Params; queryParams: Params }) => {
        this.stagesFacade.initStore(
          data.params['projectId'],
          data.queryParams['stageId']
        );

        return of(true);
      })
    );
  }
}
