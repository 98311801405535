import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  viewChild
} from '@angular/core';
import { documentsActionsToken } from '@simlab/documents/data-access';
import { FolderStates } from '@simlab/documents/models';
import { FolderComponent } from '@simlab/documents/ui';
import { ProcoreInModalSelectStateService } from './services/procore-in-modal-select-state.service';
import { ProcoreDocumentsInModalComponent } from './ui/procore-documents-in-modal/procore-documents-in-modal.component';
import { ProcoreDrawingsInModalComponent } from './ui/procore-drawings-in-modal/procore-drawings-in-modal.component';
import { ProcorePicturesInModalComponent } from './ui/procore-pictures-in-modal/procore-pictures-in-modal.component';

@Component({
  selector: 'feature-projects-procore-documents-wrapper-in-modal',
  standalone: true,
  imports: [
    FolderComponent,
    ProcoreDrawingsInModalComponent,
    ProcoreDocumentsInModalComponent,
    ProcorePicturesInModalComponent
  ],
  templateUrl: './procore-documents-wrapper-in-modal.component.html',
  styleUrl: './procore-documents-wrapper-in-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: documentsActionsToken,
      useValue: { actionList: [] }
    },
    ProcoreInModalSelectStateService
  ]
})
export class ProcoreDocumentsWrapperInModalComponent {
  private readonly _documents = viewChild(ProcoreDocumentsInModalComponent);
  private readonly _drawings = viewChild(ProcoreDrawingsInModalComponent);
  private readonly _pictures = viewChild(ProcorePicturesInModalComponent);

  protected readonly state = inject(ProcoreInModalSelectStateService);
  protected readonly states: FolderStates[] = [
    {
      state: 'LOADED',
      data: {
        id: 'documents',
        isDirectory: true,
        name: $localize`:@@PROCORE_DOCUMENTS:Documents`
      }
    },
    {
      state: 'LOADED',
      data: {
        id: 'drawings',
        isDirectory: true,
        name: $localize`:@@PROCORE_DRAWINGS:Drawings`
      }
    },
    {
      state: 'LOADED',
      data: {
        id: 'pictures',
        isDirectory: true,
        name: $localize`:@@PROCORE_PICTURES:Pictures`
      }
    }
  ];

  readonly selectedDocuments = computed(() => {
    const selectedDocuments = this._documents()?.selectedItems() ?? [];
    const selectedDrawings = this._drawings()?.selectedItems() ?? [];
    const selectedPictures = this._pictures()?.selectedItems() ?? [];

    if (selectedDocuments.length)
      return {
        documents: selectedDocuments,
        from: 'procoreDocuments' as const
      };

    if (selectedDrawings.length)
      return { documents: selectedDrawings, from: 'procoreDrawings' as const };

    if (selectedPictures.length)
      return { documents: selectedPictures, from: 'procorePictures' as const };

    return { documents: [], from: undefined };
  });

  resetSelectedItems(): void {
    this._documents()?.resetSelectedItems();
    this._drawings()?.resetSelectedItems();
    this._pictures()?.resetSelectedItems();
  }
}
