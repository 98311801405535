<div class="standalone-container"></div>

<div class="tools-container">
  <ui-form-field>
    <label i18n="@@SELECTED_STAGE_HEADER">SELECTED STAGE</label>
    <ui-select
      name="matterports"
      id="matterports"
      i18n-placeholder="@@SELECT_STAGE_PROMPT"
      placeholder="Select stage"
      (valueChange)="changeMatterport($event)"
      [value]="selectedStageId"
    >
      <ng-container *ngFor="let stage of stages">
        <ui-option [value]="stage.id">
          <div class="stages-option">
            {{ stage.name }}
          </div>
        </ui-option>
      </ng-container>
    </ui-select>
  </ui-form-field>
</div>
<div
  class="fullscreen"
  *ngIf="(hasMatterport$ | async) === false; else scanReload"
>
  <ui-image-info
    imageSrc="icon_select_stages"
    i18n-header="@@SELECT_STAGE_PROMPT"
    header="Select stage"
    [descriptions]="descriptionImageInfoSelectComponent"
    [maxWidth]="225"
  ></ui-image-info>
</div>
<ng-template #scanReload>
  <div
    class="fullscreen spinner"
    *ngIf="(scanReloaded$ | async) === false || (reloadingMatterport$ | async)"
  >
    <ui-matterport-loading></ui-matterport-loading>
  </div>
</ng-template>
<ng-container #matterport></ng-container>
