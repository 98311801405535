import { Pipe, PipeTransform } from '@angular/core';
import {
  DistanceConversion,
  SystemOfMeasurement
} from '@simlab/feature-measurement/models';
import { UnitsPreferredByUserPipe } from './base-units-preferred.pipe';

export const AREA_CONVERSION: Record<SystemOfMeasurement, DistanceConversion> =
  {
    [SystemOfMeasurement.Metric]: {
      fn: function (area: number): number {
        if (!area) return 0;
        return area;
      },
      unit: 'm²',
      segmentUnit: 'm'
    },
    [SystemOfMeasurement.Imperial]: {
      fn: function (area: number): number {
        if (!area) return 0;
        return area * 10.7639104;
      },
      unit: 'ft²',
      segmentUnit: 'ft'
    }
  };

@Pipe({
  name: 'unitsPreferredByTheUser',
  standalone: true
})
export class UnitsAreaPreferredByUserPipe
  extends UnitsPreferredByUserPipe
  implements PipeTransform
{
  transform(value: number, units: SystemOfMeasurement): number {
    return AREA_CONVERSION[units].fn(value);
  }
}
