<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel i18n="@@TRANSFER_NOTE">Transfer note</h2>
  </ng-container>

  <ng-container designDialogContent>
    <p
      *ngIf="containMeasurements$ | async"
      class="danger-text"
      i18n="@@TRANSFER_NOTE_CONTAINS_MEASUREMENTS"
    >
      The note you want to move contains attached measurements. All measurements
      attached to the note will be transferred to another stage. If you do not
      want to move the measurements, detach them from the note.
    </p>
    <div class="dialog-content-container" [formGroup]="formGroup">
      <ng-container *ngIf="test$ | async as data">
        <ui-form-field>
          <label i18n="@@MOVE_TO_STAGE">Move to selected Stage.</label>
          <ui-select
            formControlName="selectedStage"
            placeholder="Select stage"
            i18n-placeholder="@@SELECT_STAGE"
          >
            <ui-option
              *ngFor="let stage of data.stages; trackBy: trackDataByStageId"
              [value]="stage.id"
            >
              {{ stage.name }}
            </ui-option>
          </ui-select>
        </ui-form-field>
        <ui-checkbox type="customize" formControlName="keepPosition">
          <label i18n="@@KEEP_NOTE_POSITION">Keep note position</label>
        </ui-checkbox>
      </ng-container>
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <button design-stroked-button (click)="closeDialog()">
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button
      design-flat-button
      designButtonColor="accent"
      (click)="confirmDialog()"
      [disabled]="formGroup.valid === false"
    >
      <div i18n="@@CONFIRM">Confirm</div>
    </button>
  </ng-container>
</design-dialog-wrapper>
