<design-dialog-wrapper class="dialog-wrapper" cdkTrapFocus>
  <h1 designDialogHeader i18n="@@SELECT_FILES">Select files</h1>

  <ng-container designDialogContent>
    <design-tab-group
      class="ui-tab-group"
      [alwaysShowTabCount]="false"
      (selectIndexChange)="documentsInstanceChange($event)"
    >
      <ui-divider uiDivider></ui-divider>
      <design-tab>
        <ng-template designTabLabel>
          <div class="tab-label" i18n="@@STAGES">Stages</div>
        </ng-template>
        @if (selectedInstance() === 'stagesDocuments') {
          <feature-projects-stages-documents-in-modal />
        }
      </design-tab>

      @if (projectConnectedWithProcore()) {
        <design-tab>
          <ng-template designTabLabel>
            <div class="tab-label" i18n="@@PROCORE">Procore</div>
          </ng-template>
          @if (selectedInstance() === 'procoreDocuments') {
            <feature-projects-procore-documents-wrapper-in-modal />
          }
        </design-tab>
      }
    </design-tab-group>
  </ng-container>

  <div designDialogFooter class="btn-container">
    <button design-stroked-button type="button" (click)="cancel()">
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button
      design-flat-button
      designButtonColor="accent"
      type="button"
      [disabled]="!selectedDocuments().documents.length"
      (click)="add()"
    >
      <div i18n="@@ADD">Add</div>
    </button>
  </div>
</design-dialog-wrapper>
