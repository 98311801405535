import {
  DocumentSourceEnumValues,
  DocumentTypeEnumValues
} from '@simlab/documents/models';
import { StagesFiltersParams } from './stages-documents-filters.component';
import { StagesDocumentsFilters } from './StagesDocumentsFiltersFormBuilder';

export const StagesDocumentsFiltersUrlParamsMapper = {
  getMappedFilters: (
    urlParams: StagesFiltersParams
  ): StagesDocumentsFilters => {
    return {
      documentSources:
        StagesDocumentsFiltersUrlParamsMapper.getMappedEnum<DocumentSourceEnumValues>(
          urlParams.documentSources
        ),
      documentTypes:
        StagesDocumentsFiltersUrlParamsMapper.getMappedEnum<DocumentTypeEnumValues>(
          urlParams.documentTypes
        ),

      creatorIds: StagesDocumentsFiltersUrlParamsMapper.getMappedCreatorIds(
        urlParams.creatorIds
      )
    };
  },

  getMappedEnum: <TEnum extends number>(
    urlData: StagesFiltersParams[
      | 'documentSources'
      | 'creatorIds'
      | 'documentTypes']
  ) => {
    const data = typeof urlData === 'string' ? [urlData] : urlData;

    return (data ?? []).map((documentSource: string) =>
      Number.parseInt(documentSource)
    ) as TEnum[];
  },

  getMappedCreatorIds: (urlData: StagesFiltersParams['creatorIds']) => {
    const data = typeof urlData === 'string' ? [urlData] : urlData;

    return data ?? [];
  }
};
