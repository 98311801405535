@if (viewModel().folders.length) {
  <header class="title__container">
    <h3 class="title" i18n="@@FOLDERS">Folders</h3>

    <ng-container *ngTemplateOutlet="sortSwitch"></ng-container>
  </header>
  <div class="folders__container">
    @for (item of viewModel().folders; track item) {
      <documents-folder [data]="item" />
    }
  </div>
}

@if (viewModel().files.length) {
  <header class="title__container">
    <h3 class="title" i18n="@@FILES">Files</h3>

    @if (!viewModel().folders.length) {
      <ng-container *ngTemplateOutlet="sortSwitch"></ng-container>
    }
  </header>
  <div class="files__container">
    @for (item of viewModel().files; track item) {
      <documents-file-card [data]="item" />
    }
  </div>
}

<ng-template #sortSwitch>
  <ng-content select="design-sort-switch" />
</ng-template>
