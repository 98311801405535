import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  model,
  signal
} from '@angular/core';
import {
  DesignFlatButtonModule,
  DesignIconButton
} from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { Company, Project } from '@simlab/procore/models';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiIconModule } from '@simlab/ui/icon';
import { UiInputModule } from '@simlab/ui/input';
import { UiSelectModule } from '@simlab/ui/select';

@Component({
  selector: 'procore-linking-project',
  standalone: true,
  imports: [
    UiIconModule,
    DesignFlatButtonModule,
    DesignIconButton,
    DesignIcon,
    UiMenuPanelModule,
    UiSelectModule,
    UiFormFieldModule,
    UiInputModule
  ],
  template: `
    <div class="icon-wrapper">
      <i ui-icon name="icon_procore_logo" size="img" defaultColor></i>
    </div>
    <div class="body">
      <div class="body__selected-section">
        <ui-form-field>
          <label i18n="@@COMPANY">Company</label>
          <ui-select
            (valueChange)="selectedCompany.set($event)"
            [placeholder]="companiesPlaceholder()"
            (closed)="search.set('')"
            [disabled]="!loadedCompanies()"
          >
            <div class="body__selected-section__search-wrapper">
              <ui-input
                class="body__selected-section__search-wrapper--search"
                [suffix]="search_input_suffix"
                [type]="'text'"
                i18n-placeholder="@@SEARCH"
                placeholder="Search"
                [value]="search()"
                (valueChange)="search.set($event)"
              >
                <ng-template #search_input_suffix>
                  <i ui-font fontName="search" fontSize="1.5rem"></i>
                </ng-template>
              </ui-input>
            </div>

            @for (company of companiesSearch(); track $index) {
              <ui-option [value]="company.id">
                {{ company.name }}
              </ui-option>
            }
          </ui-select>
        </ui-form-field>

        <ui-form-field [class.disabled]="selectedCompany() === undefined">
          <label i18n="@@PROJECTS">Projects</label>
          <ui-select (valueChange)="selectedProject.set($event)">
            @for (project of projects(); track $index) {
              <ui-option [value]="project.id">
                {{ project.name }}
              </ui-option>
            }
          </ui-select>
        </ui-form-field>
      </div>
      <div class="info">
        <div i18n="@@CHOOSE_PROCORE_COMPANY_INFO">
          You must choose Procore company and project to connect with.
        </div>
        <div i18n="@@CHOOSE_PROCORE_COMPANY_INFO_2">
          In order to see the projects and the organization, you need to combine
          them in Procore.
        </div>
      </div>
    </div>
    <div class="action-section">
      <ng-content select="button"></ng-content>
    </div>
  `,
  styleUrl: './linking-project.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkingProjectComponent {
  readonly companies = input.required<Company[]>();
  readonly projects = input.required<Project[]>();
  readonly selectedCompany = model<number | undefined>(undefined);
  readonly selectedProject = model<number | undefined>(undefined);
  readonly search = signal<string>('');
  readonly loadedCompanies = input.required<boolean>();

  readonly companiesPlaceholder = computed(() => {
    const loaded = this.loadedCompanies();

    return loaded ? 'Select company' : 'fetching data...';
  });

  readonly companiesSearch = computed(() => {
    const search = this.search();
    const companies = this.companies();
    return companies.filter((company) =>
      company.name.toLowerCase().includes(search.toLowerCase())
    );
  });
}
