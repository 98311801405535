<design-radio-expansion-panel value="filters">
  <design-expansion-panel-header
    color="secondary"
    data-cy="expansion-header"
    (emitClick)="selected.emit(item().id)"
    [showArrow]="(componentsContainsElement$ | async) === true"
  >
    <div class="header-expansion" designLabel>
      <div class="item__number">
        <h3>{{ indexNumber() | number: '2.0-0' }}</h3>
      </div>
      <div class="item__info">
        <div class="item__info--name">{{ name() }}</div>
        <div class="item__info__bottom-line">
          <div>
            {{ utcDate() | formatDate | async }}
          </div>
          <ng-container i18n="@@X_ANNOTATIONS">
            {{ annotations() | number: '2.0-0' }} Annotations
          </ng-container>
        </div>
      </div>
    </div>
  </design-expansion-panel-header>
  <ng-template designRadioExpansionPanelContent>
    <div class="components-wrapper">
      <ng-container
        *ngFor="
          let component of components$ | async;
          let i = index;
          trackBy: trackByComponentId
        "
      >
        <div
          class="component-wrapper"
          [class.selected]="
            (selectedComponentId$ | async) === component.id &&
            (stageId$ | async) === true
          "
          (click)="selectedComponent(component.id, i)"
        >
          <i ui-font fontName="link-bold" fontSize="1.3rem"></i>
          <div class="component-wrapper__name">
            {{ component.name }}
          </div>
        </div>
      </ng-container>
      <ui-divider></ui-divider>
      <ng-container
        *ngFor="
          let blueprint of blueprints$ | async;
          trackBy: trackByComponentId
        "
      >
        <feature-stages-other-component-item
          #blueprintRef
          [attr.data-cy]="'blueprint-' + blueprint.id"
          icon="Jpg"
          [name]="blueprint.name"
          [visible]="blueprint.visible || false"
          (visibleChange)="changeVisibility($event, blueprint.id, blueprintRef)"
        ></feature-stages-other-component-item>
      </ng-container>
    </div>
  </ng-template>
</design-radio-expansion-panel>
