/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, inject } from '@angular/core';

@Directive()
export abstract class AutocompleteOriginBase {
      public readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);
}

@Directive({
  selector: '[designAutocompleteOrigin]',
  exportAs: 'designAutocompleteOrigin',
  standalone: true,
})
export class DesignAutocompleteOrigin extends AutocompleteOriginBase {}
