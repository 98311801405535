import { Injectable, OnDestroy, inject } from '@angular/core';
import {
  ACCEPTED_AUDIO,
  ACCEPTED_DOCUMENT,
  ACCEPTED_PHOTO,
  ACCEPTED_VIDEO,
  ApiFacadeService,
  BlobBody,
  ContextType,
  Media,
  MediaType,
  Note,
  ResponseMediaType
} from '@simlab/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  NEW_NOTE_LOCALLY_ID,
  NotesFacade,
  StagesFacade
} from '@simlab/data-store';
import { DocumentTypeEnum } from '@simlab/documents/models';
import { SelectedDocumentsForNotes } from '@simlab/feature/projects';
import { MODAL_DATA, ModalService } from '@simlab/ui/modal';
import {
  RouterStoreParams,
  ToastEventService,
  prepareFormData
} from '@simlab/util-shared';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  exhaustMap,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import { MediaModalComponent } from '../components/media-modal/media-modal.component';

const WRONG_PROCORE_DOCUMENTS_EXTENSION = $localize`:@@WRONG_PROCORE_DOCUMENTS_EXTENSION: Wrong procore documents extension`;

@Injectable()
export class NoteMediaService implements OnDestroy {
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private readonly _toastEventService = inject(ToastEventService);

  constructor(
    private readonly apiFacadeService: ApiFacadeService,
    private readonly notesFacade: NotesFacade,
    private readonly stagesFacade: StagesFacade,
    private readonly modalService: ModalService
  ) {}

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  private readonly _sendFileToBlob = (
    projectId: string,
    type: MediaType,
    file: File,
    noteId?: string
  ) =>
    of(noteId).pipe(
      switchMap((noteId: string | undefined) => {
        return noteId ? of(noteId) : this.notesFacade.selectedNoteId$;
      }),
      mergeMap((noteId: string) => {
        return iif(
          () => type !== 'photo',
          this.apiFacadeService.blobs
            .sendFileToBlobWithNoteId(file, projectId, noteId, type)
            .pipe(
              mergeMap((blobBody: BlobBody) =>
                this.apiFacadeService.digitalNotes.addDigitalNoteToRootNote({
                  ...blobBody,
                  type: type
                })
              )
            ),
          this.apiFacadeService.blobs
            .uploadImagesToBlob(
              prepareFormData(file, projectId, ContextType.Project)
            )
            .pipe(
              mergeMap((fileUrl: string) => {
                const blobBody = {
                  rootNoteId: noteId,
                  name: file.name,
                  blobUrl: fileUrl,
                  extension: file.name.split('.')[1]
                } as BlobBody;
                return this.apiFacadeService.digitalNotes.addDigitalNoteToRootNote(
                  { ...blobBody, type }
                );
              })
            )
        ).pipe(
          tap((digital: Media) => this._addDigitalNote(digital, noteId, type)),
          take(1)
        );
      }),
      catchError((error) => {
        console.log(error);
        this.notesFacade.removeEmptyDigitalNote(type);
        return of(error);
      })
    );

  addFileFromDocuments$(
    noteId: string,
    { id, documentType: type }: SelectedDocumentsForNotes['documents'][number],
    documentType: SelectedDocumentsForNotes['from']
  ) {
    return this.stagesFacade.getRouteNestedParams$.pipe(
      tap(() => {
        const emptyNoteType = this._getMediaTypeFromDocumentType(type);
        if (emptyNoteType === undefined) return;

        this._addEmptyNoteToStore(emptyNoteType);
      }),
      mergeMap(() => {
        return this._addDocument$(documentType, noteId, id).pipe(
          tap((digital: Media) => {
            let digitalType =
              digital.type.toLowerCase() as Lowercase<ResponseMediaType>;

            if (documentType === 'procorePictures') digitalType = 'photo';
            if (documentType === 'procoreDrawings') digitalType = 'document';
            if (documentType === 'procoreDocuments') {
              const digitalTypeFromProcoreDocument =
                this._getProcoreDocumentsDigitalType(
                  digital.name.toLocaleLowerCase()
                );
              if (digitalTypeFromProcoreDocument === undefined) {
                this._toastEventService.addEvent({
                  action: of('Error'),
                  message: { Error: WRONG_PROCORE_DOCUMENTS_EXTENSION }
                });
                return;
              }

              digitalType = digitalTypeFromProcoreDocument;
            }

            this._addDigitalNote(digital, noteId, digitalType);
          })
        );
      }),
      take(1)
    );
  }

  private _getMediaTypeFromDocumentType(
    documentType: SelectedDocumentsForNotes['documents'][number]['documentType']
  ): MediaType | undefined {
    switch (documentType) {
      case DocumentTypeEnum.Image:
      case DocumentTypeEnum.Blueprint:
        return 'photo';
      case DocumentTypeEnum.Video:
        return 'video';
      case DocumentTypeEnum.Audio:
        return 'audio';
      case DocumentTypeEnum.Doc:
        return 'document';

      default:
        return undefined;
    }
  }

  private _addDocument$(
    from: SelectedDocumentsForNotes['from'],
    noteId: string,
    id: string | number
  ) {
    switch (from) {
      case 'stagesDocuments':
        return this.apiFacadeService.digitalNotes.addDocumentToRootNote({
          rootNoteId: noteId,
          documentId: id as string
        });

      case 'procoreDocuments':
        return this.apiFacadeService.digitalNotes.addProcoreDocumentToRootNote({
          rootNoteId: noteId,
          procoreFileId: id as number
        });

      case 'procoreDrawings':
        return this.apiFacadeService.digitalNotes.addProcoreDrawingDocumentToRootNote(
          {
            rootNoteId: noteId,
            procoreDrawingId: id as number
          }
        );

      case 'procorePictures':
        return this.apiFacadeService.digitalNotes.addProcoreImageDocumentToRootNote(
          { rootNoteId: noteId, procoreImageId: id as number }
        );

      default:
        return EMPTY;
    }
  }

  private _getProcoreDocumentsDigitalType(
    name: string
  ): Lowercase<ResponseMediaType> | undefined {
    if (
      ACCEPTED_PHOTO.some((acceptedFormat) => name.includes(acceptedFormat))
    ) {
      return 'photo';
    }

    if (
      ACCEPTED_VIDEO.some((acceptedFormat) => name.includes(acceptedFormat))
    ) {
      return 'video';
    }

    if (
      ACCEPTED_AUDIO.some((acceptedFormat) => name.includes(acceptedFormat))
    ) {
      return 'audio';
    }

    if (
      ACCEPTED_DOCUMENT.some((acceptedFormat) => name.includes(acceptedFormat))
    ) {
      return 'document';
    }

    return undefined;
  }

  private _addDigitalNote(
    digital: Media,
    noteId: string,
    type: MediaType | (string & {})
  ) {
    if (type === 'photo' || type === 'video' || type === 'procoreimage') {
      this.notesFacade.removeEmptyDigitalNote(type);
    }
    digital.url = digital.digitalNoteUrl ?? '';
    this.notesFacade.addDigitalNoteByID(digital, type, noteId);
  }

  uploadFile(file: File, type: MediaType, noteId?: string): void {
    this.stagesFacade.getRouteNestedParams$
      .pipe(
        take(1),
        tap(() => {
          this._addEmptyNoteToStore(type);
        }),
        mergeMap((routerStoreParams: RouterStoreParams) =>
          this._sendFileToBlob(
            routerStoreParams.params['projectId'],
            type,
            file,
            noteId
          )
        )
      )
      .subscribe();
  }

  uploadScreenshotFile(
    formData: FormData,
    type: MediaType,
    filename: string
  ): void {
    this.stagesFacade.getRouteNestedParams$
      .pipe(
        take(1),
        mergeMap((routerStoreParams: RouterStoreParams) => {
          if (routerStoreParams.params['projectId'] && type === 'photo') {
            formData.append('contextId', routerStoreParams.params['projectId']);
            this._addEmptyNoteToStore(type);
            return this.notesFacade.selectedNoteId$.pipe(
              take(1),
              mergeMap((noteId: string) => {
                if (noteId === NEW_NOTE_LOCALLY_ID) {
                  this.notesFacade.saveLocalNote();
                  return this.notesFacade.noteAdded$.pipe(
                    map((note: Note) => note.id)
                  );
                }
                return of(noteId);
              }),
              mergeMap((noteId: string) =>
                this.apiFacadeService.blobs
                  .uploadBase64ImagesToBlob(formData)
                  .pipe(
                    mergeMap((fileUrl: string) => {
                      const blobBody = {
                        rootNoteId: noteId,
                        name: filename,
                        blobUrl: fileUrl,
                        extension: 'jpg'
                      } as BlobBody;
                      return this.apiFacadeService.digitalNotes.addDigitalNoteToRootNote(
                        { ...blobBody, type }
                      );
                    })
                  )
              ),
              tap((digital: Media) => {
                this.notesFacade.removeEmptyDigitalNote(type);
                digital.url = digital.digitalNoteUrl ?? '';
                this.notesFacade.addDigitalNote(digital, type);
              }),
              take(1),
              catchError((error) => {
                this.notesFacade.removeEmptyDigitalNote(type);
                return of(error);
              })
            );
          } else {
            return of();
          }
        })
      )
      .subscribe();
  }

  uploadDrawingImageFile(formData: FormData, file: Media): Observable<void> {
    return this.apiFacadeService.blobs.uploadBase64ImagesToBlob(formData).pipe(
      exhaustMap((fileUrl: string) =>
        this.apiFacadeService.digitalNotes
          .editDigitalNoteFile({
            digitalNoteId: file.id,
            blobUrl: fileUrl
          })
          .pipe(
            tap(() => {
              file.url = fileUrl;
              this.notesFacade.updateMedia(file.id, 'photo', file);
            })
          )
      ),
      take(1),
      catchError((error) => {
        return of(error);
      })
    );
  }

  private _addEmptyNoteToStore(type: MediaType): void {
    if (type === 'photo' || type === 'video') {
      const emptyMedia: Media = {
        id: '',
        name: '',
        url: '',
        creatorId: '',
        addedByName: '',
        addedAt: '',
        lastEditorId: '',
        modifiedAt: '',
        createdAt: '',
        lastEditorName: '',
        addedBy: '',
        type: this._changeMediaTypeToResponseMediaType(type)
      };
      this.notesFacade.addDigitalNote(emptyMedia, type);
    }
  }

  private _changeMediaTypeToResponseMediaType(
    type: MediaType
  ): ResponseMediaType {
    return (type[0].toUpperCase() + type.slice(1)) as ResponseMediaType;
  }

  openDialog(
    media: Observable<Media[]>,
    type: Extract<MediaType, 'photo' | 'video'>,
    openImageIndex: number,
    currentName: string,
    mediaId: string
  ): void {
    this.notesFacade.selectedNoteId$
      .pipe(take(1))
      .subscribe((noteId: string) => {
        this.modalService.createModalWithProviders(
          MediaModalComponent,
          {
            centered: true,
            offset: {
              x: 0,
              y: 0
            },
            maxHeight: 'min(100%, 100vh)',
            maxWidth: 'min(100%, 100vw)',
            height: '100%',
            width: '100%'
          },
          [
            {
              provide: MODAL_DATA,
              useValue: {
                type,
                media,
                noteId,
                openImageIndex,
                currentName,
                mediaId
              }
            },
            {
              provide: NoteMediaService,
              useValue: this
            }
          ]
        );
      });
  }
}
