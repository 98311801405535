import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationModalRef, MODAL_DATA } from '@simlab/ui/modal';

@Component({
  selector: 'feature-projects-limit-reached-activate-project',
  templateUrl: './limit-reached-activate-project.component.html',
  styleUrls: ['./limit-reached-activate-project.component.scss']
})
export class LimitReachedActivateProjectComponent {
      private readonly organizationId = inject<string>(MODAL_DATA);
      private readonly modalRef = inject<ConfirmationModalRef<unknown>>(ConfirmationModalRef<unknown>);
      private readonly router = inject(Router);

  confirmDialog(): void {
    this.router.navigate([
      `/organizations/${this.organizationId}/subscription`
    ]);
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }

  cancel(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }
}
