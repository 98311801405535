/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-class-suffix */
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterContentInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  inject,
} from '@angular/core';
import {DesignAutocomplete} from '@simlab/design/autocomplete';
import {DesignChipInputPanel} from './chip-input-panel.component';

let nextUniqueId = 0;

export interface DesignChipInputControl {
  /** The text control's placeholder text. */
  placeholder: string;
  hostElement: HTMLInputElement;
  id: string;
}

//TODO: create interface/or base class for chip-input and input-base?

@Directive({
  selector: 'input[designChipInput]',
  standalone: true,
  host: {
    class: 'design-base-input design-chip-input',
    '[attr.placeholder]': 'placeholder || null',
    '[id]': 'id',
    // Native input properties that are overwritten by Angular inputs need to be synced with
    // the native input element. Otherwise property bindings for those don't work.
    '[attr.id]': 'id',
    '[disabled]': 'disabled',
    '[attr.disabled]': 'disabled || null',
    '(focus)': 'focusChanged(true)',
    '(blur)': 'focusChanged(false)',
    '(input)': 'onInputNOOP()',
  },
})
export class DesignChipInput
  implements DesignChipInputControl, AfterContentInit, OnChanges
{
  private readonly _designChipInputPanel = inject(DesignChipInputPanel, {
    host: true,
  });
  private readonly _elementRef = inject(ElementRef);

  @Input()
  set designAutocomplete(value: DesignAutocomplete) {
    this._autocomplete = value;
  }

  /** Unique id for the input. */
  @Input()
  id = `design-chip-list-input-${nextUniqueId++}`;

  private _autocomplete?: DesignAutocomplete;

  /** Whether the input is disabled. */
  @Input()
  get disabled(): boolean {
    return (
      this._disabled ||
      (this._designChipInputPanel && this._designChipInputPanel.disabled)
    );
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }

  get hostElement(): HTMLInputElement {
    return this._elementRef.nativeElement;
  }

  private _disabled = false;

  /** The input's placeholder text. */
  @Input() placeholder = '';

  ngOnChanges() {
    this._designChipInputPanel.stateChanges.next();
  }

  ngAfterContentInit(): void {
    this._designChipInputPanel.registerInput(this);
  }

  /** Callback for the cases where the focused state of the input changes. */
  focusChanged(isFocused: boolean): void {
    if (isFocused !== this._designChipInputPanel.focused) {
      this._designChipInputPanel.focused = isFocused;
      this._designChipInputPanel.stateChanges.next();
    }
  }

  onInputNOOP(): void {
    // This is a noop function and is used to let Angular know whenever the value changes.
    // Angular will run a new change detection each time the `input` event has been dispatched.
    // It's necessary that Angular recognizes the value change, because when floatingLabel
    // is set to false and Angular forms aren't used, the placeholder won't recognize the
    // value changes and will not disappear.
    // Listening to the input event wouldn't be necessary when the input is using the
    // FormsModule or ReactiveFormsModule, because Angular forms also listens to input events.
  }
}
