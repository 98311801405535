<header class="header">
  <h1 class="header__title">{{ data.name }}</h1>

  <button design-icon-button class="header__action-btn" (click)="close()">
    <i class="button--close" designIcon="cross" designSize="2.5rem"></i>
  </button>
</header>

<div class="content-preview">
  @if (isVideoDocument || isPhotoDocument) {
    <div class="content-preview__carousel">
      <ui-slideshow
        [collection]="(slideshowSource$ | async) ?? []"
        [currentIndex]="0"
        [type]="isPhotoDocument ? 'photo' : 'video'"
        class="slideshow"
      ></ui-slideshow>
    </div>
  } @else {
    <design-dialog-wrapper class="container__wrapper" cdkTrapFocus>
      <div designDialogContent class="container">
        <i
          ui-icon
          [name]="
            (data.name | documentsThumbnailIconGetter: data.type | async)!
          "
          defaultColor
          class="card__thumbnail"
        ></i>

        @if (isAudioDocument) {
          <span class="file-name" i18n="@@AUDIO">Audio</span>
          <ui-audio [src]="(audioSource$ | async)!" />
        } @else {
          <span class="file-name" i18n="@@NO_PREVIEW_AVAILABLE"
            >No preview available</span
          >
        }
      </div>
    </design-dialog-wrapper>
  }
</div>
