import { Component, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { StageWithCount, StagesFacade } from '@simlab/data-store';
import { UiButtonModule } from '@simlab/ui/button';
import { UiIconModule } from '@simlab/ui/icon';
import { PositionCameraService } from '@simlab/util-shared';
import { filter, firstValueFrom, map, tap } from 'rxjs';
import { TimelineAnimation } from '../../animation/timeline-animation';
import { TimelineCondensedDraggableComponent } from '../timeline-condensed-draggable/timeline-condensed-draggable.component';

@Component({
  selector: 'simlab-timeline-condensed',
  standalone: true,
  imports: [TimelineCondensedDraggableComponent, UiButtonModule, UiIconModule],
  providers: [StagesFacade],
  templateUrl: './timeline-condensed.component.html',
  styleUrls: ['./timeline-condensed.component.scss'],
  animations: [TimelineAnimation]
})
export class TimelineCondensedComponent {
  private readonly _stagesFacade = inject(StagesFacade, { skipSelf: true });
  private readonly _positionCameraService = inject(PositionCameraService, {
    optional: true
  });

  protected readonly selected = toSignal(
    this._stagesFacade.selectedStages$.pipe(
      filter((stage: StageWithCount | undefined) => !!stage),
      map((stage: StageWithCount | undefined) => stage as StageWithCount)
    )
  );
  protected readonly state = signal(false);

  protected toggle(): void {
    this.state.update((value) => !value);
  }

  protected selectionChange(stageId: string): void {
    if (!stageId) return;

    firstValueFrom(
      this._stagesFacade.selectedId$.pipe(
        tap((selectedId: string) => {
          if (stageId === selectedId) return;
          this._stagesFacade.setSelectedStageId(
            stageId,
            this._positionCameraService?.cameraPose?.position
          );
        })
      )
    );
  }
}
