import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, HostBinding, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormFieldControl } from '../../ui-form-field/directives/form-field-control.directive';

@Directive({
  selector: '[uiTextareaBase]',
})
export abstract class TextareaBaseDirective
  implements ControlValueAccessor, FormFieldControl<any>, OnDestroy
{
  protected readonly _destroySource: Subject<void> = new Subject<void>();
  protected readonly _valueChange: Subject<void> = new Subject<void>();

  /*
    //TODO: changes will be make from here!
  */
  @Input()
  get required(): boolean {
    return (
      this._required ??
      this._ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  _required: boolean | undefined;

  get tempValue(): string | undefined {
    return this._unconfirmedValue;
  }
  set tempValue(value: string | undefined) {
    this._unconfirmedValue = value;
  }
  private _unconfirmedValue: string | undefined = '';

  @Input()
  get value(): string | undefined {
    return this._value;
  }
  set value(newValue: string | undefined) {
    this._value = this._unconfirmedValue = newValue;
  }
  private _value!: string | undefined;

  @HostBinding('attr.disabled')
  get attrDisabled(): boolean | null {
    return this.disabled || null;
  }

  @HostBinding('attr.aria-disabled')
  get attrAriaDisabled(): string {
    return (this.disabled ?? false).toString();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  constructor(readonly _ngControl: NgControl) {
    if (this._ngControl !== null) {
      this._ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  /**
   * Sets control value. Part of the ControlValueAccessor interface
   * required to integrate with Angular's core forms API.
   * @param value
   */
  writeValue(value: string): void {
    this.value = value;
    this._valueChange.next();
  }
  /**
   * Part of the ControlValueAccessor interface required to integrate with Angular's core forms API.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  /**
   * Part of the ControlValueAccessor interface required to integrate with Angular's core forms API.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * Disables the select. Part of the ControlValueAccessor interface required
   * to integrate with Angular's core forms API.
   *
   * @param isDisabled Sets whether the component is disabled.
   */
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    // this._changeDetectorRef.markForCheck();
    // this.stateChanges.next();
  }

  /** `View -> model callback called when value changes` */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected _onChange: (value: any) => void = () => {};

  /** `View -> model callback called when select has been touched` */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected _onTouched = () => {};
}
