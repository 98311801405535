import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';

import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { Subject, merge, takeUntil, tap } from 'rxjs';

import { SortDirection } from '../models/sort-direction';
import { DesignSort } from '../sort';
import { DesignSortAnimations } from '../sort-animations';

interface DesignSortHeaderColumnDef {
  name: string;
}
export type ArrowViewState = SortDirection | 'hint' | 'active';

export interface ArrowViewStateTransition {
  fromState?: ArrowViewState;
  toState?: ArrowViewState;
}

@Component({
  selector: '[design-sort-header]',
  exportAs: 'designSortHeader',
  templateUrl: './sort-header.component.html',
  styleUrls: ['./sort-header.component.scss'],
  standalone: true,
  imports: [DesignIcon, DesignIconButton],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '(click)': '_handleClick()',
    '[attr.aria-sort]': '_getAriaSortAttribute()',
    '(mouseenter)': '_setIndicatorHintVisible(true)',
    '(mouseleave)': '_setIndicatorHintVisible(false)',
  },
  animations: [DesignSortAnimations.arrowOpacity],
})
export class DesignSortHeader implements OnInit {
  private readonly _sort = inject(DesignSort);
  private readonly _columnDef = inject<DesignSortHeaderColumnDef>(
    'DESIGN_SORT_HEADER_COLUMN_DEF' as any /* TODO(inject-migration): Please check if the type is correct */,
    { optional: true },
  );
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroySource = new Subject<void>();
  private _viewState: ArrowViewStateTransition = {};
  private _arrowDirection: SortDirection = 'asc';
  private _disableViewStateAnimation = false;
  private _showIndicatorHint = false;
  @Input('design-sort-header') id!: string;
  @Input() disableClear!: boolean;
  @Input() start!: SortDirection;

  get arrowDirection(): SortDirection {
    return this._arrowDirection;
  }

  ngOnInit(): void {
    this._handleStateChanges();

    if (!this.id && this._columnDef) {
      this.id = this._columnDef.name;
    }

    this._setAnimationTransitionState({
      toState: this._isSorted() ? 'active' : this._arrowDirection,
    });

    this._updateArrowDirection();

    this._sort.register(this);
  }

  ngOnDestroy() {
    this._sort.deregister(this);
    this._sort.active = undefined;
    this.destroySource.next();
    this.destroySource.complete();
  }

  getArrowViewState() {
    const fromState = this._viewState.fromState;
    return (fromState ? `${fromState}-to-` : '') + this._viewState.toState;
  }

  private _handleClick() {
    this._sort.sort(this);
  }

  private _setIndicatorHintVisible(visible: boolean) {
    this._showIndicatorHint = visible;
    if (!this._isSorted()) {
      this._updateArrowDirection();
      if (this._showIndicatorHint) {
        this._setAnimationTransitionState({
          fromState: this._arrowDirection,
          toState: 'hint',
        });
      } else {
        this._setAnimationTransitionState({
          fromState: 'hint',
          toState: this._arrowDirection,
        });
      }
    }
  }

  private _setAnimationTransitionState(viewState: ArrowViewStateTransition) {
    this._viewState = viewState || {};
    if (this._disableViewStateAnimation) {
      this._viewState = { toState: viewState.toState };
    }
  }

  private _isSorted() {
    return (
      this._sort.active == this.id &&
      (this._sort.direction === 'asc' || this._sort.direction === 'desc')
    );
  }

  private _updateArrowDirection() {
    this._arrowDirection = this._isSorted()
      ? this._sort.direction
      : this.start || this._sort.start;
  }

  private _getAriaSortAttribute() {
    if (!this._isSorted()) {
      return 'none';
    }

    return this._sort.direction == 'asc' ? 'ascending' : 'descending';
  }

  private _handleStateChanges() {
    merge(this._sort.sortChange, this._sort._stateChanges)
      .pipe(
        tap(() => {
          if (this._isSorted()) {
            this._updateArrowDirection();
            if (
              this._viewState.toState === 'hint' ||
              this._viewState.toState === 'active'
            ) {
              this._disableViewStateAnimation = true;
            }

            this._setAnimationTransitionState({
              fromState: this._arrowDirection,
              toState: 'active',
            });
            this._showIndicatorHint = false;
          }

          if (
            !this._isSorted() &&
            this._viewState &&
            this._viewState.toState === 'active'
          ) {
            this._disableViewStateAnimation = false;
            this._setAnimationTransitionState({
              fromState: 'active',
              toState: this._arrowDirection,
            });
          }

          this._changeDetectorRef.markForCheck();
        }),
        takeUntil(this.destroySource),
      )
      .subscribe();
  }
}
