import {
  animate,
  AnimationBuilder,
  AnimationMetadata,
  AnimationPlayer,
  style,
  transition,
} from '@angular/animations';
import {
  AfterViewInit,
  computed,
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  filter,
  fromEvent,
  merge,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';
import { UiTooltip } from './components/ui-tooltip.component';

const TIME_DELAY = '3s';
const TIME_DURATION = '0.2s';

@Directive({
  selector: 'design-tooltip[designTooltipHoldOnHover]',
  standalone: true,
})
export class TooltipOnHoverDirective implements AfterViewInit {
  private readonly _elementRef = inject(ElementRef);
  private readonly _animationBuilder = inject(AnimationBuilder);
  private readonly _destroyRef = inject(DestroyRef);

  private readonly _isOpen$ = new BehaviorSubject<boolean>(false);
  private _player?: AnimationPlayer;

  private readonly _uiTooltip = inject(UiTooltip);
  private _animationElement = computed(
    () => this._uiTooltip.tooltipWrapper().nativeElement,
  );

  readonly delayedClosure = input<number>(500);

  ngAfterViewInit(): void {
    this._prepareTooltipForHoldOnHover();

    this._pointerEnterObserver();
    this._pointerLeaveObserver();
  }

  private _pointerEnterObserver(): void {
    merge(
      fromEvent(this._elementRef.nativeElement, 'pointerenter').pipe(
        tap(() => this._triggerShowAnimation()),
      ),
      fromEvent(this._animationElement(), 'pointerenter'),
    )
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this._isOpen$.next(true));
  }

  private _pointerLeaveObserver(): void {
    merge(
      fromEvent(this._elementRef.nativeElement, 'mouseleave'),
      fromEvent(this._animationElement(), 'mouseleave'),
      this._touchableDeviceEventObserver$,
    )
      .pipe(
        tap(() => this._isOpen$.next(false)),
        switchMap(() => timer(this.delayedClosure())),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe(() => {
        if (this._isOpen$.value) return;

        this._triggerHideAnimation();
      });
  }

  private _touchableDeviceEventObserver$ = this._isOpen$.pipe(
    filter((isOpen) => isOpen && matchMedia('(pointer: coarse)').matches),
    switchMap(() =>
      fromEvent(document, 'touchstart').pipe(
        filter(({ target }) =>
          target instanceof Node
            ? !this._animationElement().contains(target) &&
              !this._elementRef.nativeElement.contains(target)
            : false,
        ),
        take(1),
      ),
    ),
  );

  private _prepareTooltipForHoldOnHover(): void {
    this._animationElement().classList.remove('ui-tooltip-wrapper');
    this._animationElement().classList.add('ui-tooltip-wrapper-custom-event');
    this._animationElement().classList.add(
      'ui-tooltip-wrapper-hold-on-hover-event',
    );
  }

  private _triggerHideAnimation(): void {
    const factory = this._animationBuilder.build(this._getHideTooltip());
    this._player = factory.create(this._animationElement());
    this._player.play();
  }

  private _getHideTooltip(): AnimationMetadata[] {
    return [
      style({
        visibility: 'hidden',
        opacity: 0,
      }),
      transition('end => start', []),
    ];
  }

  private _triggerShowAnimation(): void {
    const factory = this._animationBuilder.build(this._getShowTooltip());
    this._player = factory.create(this._animationElement());
    this._player.play();
  }

  private _getShowTooltip(): AnimationMetadata[] {
    return [
      style({
        visibility: 'visible',
        opacity: 1,
      }),
      transition('* => open', [animate(`${TIME_DURATION} ${TIME_DELAY}`)]),
    ];
  }
}
