import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { Media } from '@simlab/data-access';
import { NotesFacade } from '@simlab/data-store';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { AudioComponent } from '@simlab/ui/audio';
import { Observable, catchError, map, of } from 'rxjs';
import { NoteMediaAudioItemComponent } from './note-media-audio-item/note-media-audio-item.component';

@Component({
  selector: 'feature-stages-note-media-audio',
  templateUrl: './note-media-audio.component.html',
  styleUrls: ['./note-media-audio.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    AudioComponent,
    UiMenuPanelModule,
    NoteMediaAudioItemComponent
  ]
})
export class NoteMediaAudioComponent {
  @HostBinding('class.audio') class = true;
  readonly audios$: Observable<Media[]> = this.notesFacade.audios$.pipe(
    map((audios: Media[] | null) => {
      if (audios?.length) {
        return audios;
      } else {
        return [];
      }
    }),
    catchError((e) => {
      console.log(e);
      return of(e);
    })
  );

  constructor(private readonly notesFacade: NotesFacade) {}

  trackById(index: number, item: Media): string {
    return item.id;
  }
}
