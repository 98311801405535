import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  TemplateRef,
  viewChild
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import {
  CUSTOM_ROOT_FOLDER,
  documentsActionsToken,
  documentsApiToken,
  FolderAsPathItem
} from '@simlab/documents/data-access';
import {
  DocumentsStateService,
  DocumentsStore
} from '@simlab/documents/services';
import {
  DocumentActionPanelComponent,
  DocumentActionsDirective,
  DocumentsRootComponent,
  DocumentsSelectedItemActionPanelComponent
} from '@simlab/documents/ui';
import {
  TEventElementItem,
  UiEventElementComponent
} from '@simlab/event-queue';
import { ProcoreDownloadResourceGetterService } from '@simlab/procore/services';
import { ProcoreDocumentsApiService } from '../../../../../data-access/procore-documents-api.service';
import { ProcoreDocumentsStateService } from '../../../../../services/procore-documents-state.service';
import { ProcoreInModalSelectStateService } from '../../services/procore-in-modal-select-state.service';
import { ProcoreDocumentsActionsBase } from './../../../../../pages/documents-page/procore-documents/data-access/procore-documents-menu-actions.token';
import { ProcoreDocumentsInModalActionsService } from './data-access/procore-documents-in-modal-actions/procore-documents-in-modal-actions.service';

@Component({
  selector: 'feature-projects-procore-documents-in-modal',
  standalone: true,
  imports: [
    DocumentsRootComponent,
    DocumentActionsDirective,
    DocumentActionPanelComponent,
    DocumentsSelectedItemActionPanelComponent,
    DesignIcon,
    DesignIconButton,
    UiEventElementComponent
  ],
  templateUrl: './procore-documents-in-modal.component.html',
  styleUrl: './procore-documents-in-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProcoreDownloadResourceGetterService,
    { provide: documentsApiToken, useClass: ProcoreDocumentsApiService },
    DocumentsStore,
    DocumentsStateService,
    {
      provide: CUSTOM_ROOT_FOLDER,
      useFactory: () => {
        const state = inject(ProcoreInModalSelectStateService);

        return [
          {
            id: '1',
            name: 'Procore',
            clickHandler: () => state.setSelectedState(undefined)
          },
          {
            id: '2',
            name: 'Documents',
            clickHandler: () => state.setSelectedState('documents')
          }
        ] as FolderAsPathItem[];
      }
    },
    ProcoreDocumentsStateService,
    {
      provide: documentsActionsToken,
      useClass: ProcoreDocumentsInModalActionsService
    }
  ]
})
export class ProcoreDocumentsInModalComponent implements AfterViewInit {
  private readonly _actions = inject<ProcoreDocumentsActionsBase>(
    documentsActionsToken
  );
  protected readonly _rootComponent = viewChild.required(
    DocumentsRootComponent
  );

  readonly downloadTask = viewChild('downloadTask', {
    read: TemplateRef<{ context: TEventElementItem }>
  });

  readonly selectedItems = computed(() =>
    this._rootComponent().selectedItems()
  );

  ngAfterViewInit(): void {
    this._actions.templateToEventItem = this.downloadTask();
  }

  resetSelectedItems(): void {
    this._rootComponent().clearSelection();
  }

  protected downloadDocuments(): void {
    const data = this.selectedItems().map((item) => item.data().data);

    this._actions.download(data);
  }
}
