@switch (state.selectedState()) {
  @case ('documents') {
    <feature-projects-procore-documents-in-modal />
  }

  @case ('drawings') {
    <feature-projects-procore-drawings-in-modal />
  }

  @case ('pictures') {
    <feature-projects-procore-pictures-in-modal />
  }

  @default {
    <header class="title__container">
      <h1 class="title" i18n="@@FOLDERS">Folders</h1>
    </header>

    <div class="folders__container">
      @for (data of states; track $index) {
        <documents-folder
          [data]="data"
          (selection)="state.setSelectedState($event.id)"
        />
      }
    </div>
  }
}
