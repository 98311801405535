<common-annotation-panel-root
  [name]="name()"
  [subName]="stageName()"
  [iconType]="
    displayStatus()?.showYellowBackground
      ? 'icon_punch_draft_status'
      : 'icon_punch_closed_status'
  "
  [loading]="isLoading()"
>
  @if (!isLoading()) {
    @if (displayStatus(); as display) {
      <procore-annotation-panel-notifications
        [text]="display.statusDescription"
        [title]="display.status"
        [icon]="status() | getPunchItemStatusIcon"
        [type]="display.showYellowBackground ? 'Yellow' : 'Blue'"
      />
    }
    <common-annotation-panel-location-3d
      [positionExist]="hasMarker()"
      [changeAndSetActionDisabled]="!canEdit()"
      (goToButtonClick)="goTo()"
      (setLocationButtonClick)="setLocation()"
      (changeLocationButtonClick)="reassignLocation()"
    />

    <procore-request-container
      type="Issue"
      [title]="name()"
      [description]="richTextDescription()"
    />

    <ui-image-info
      class="image-info"
      imageSrc="icon_open_in_browser"
      i18n-header="@@GO_TO_PROCORE_MANAGE_PUNCH_ITEM"
      header="Go to Procore to manage the Punch Item"
      [button]="button"
    />
  }
</common-annotation-panel-root>
