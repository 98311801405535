<ng-container *ngIf="photo$ | async; let photo">
  <ng-container
    *ngIf="photo.url !== '' && photo.privateUrl !== ''; else spinner"
  >
    <img
      #photoElement
      [src]="photo.privateUrl"
      (loaded)="loadedImage($event)"
    />

    @if (cantLoadImage()) {
      <div class="default_image">
        <i ui-icon name="thumbnail_image" size="4x"> </i>
      </div>
    }
  </ng-container>
</ng-container>

<ng-template #spinner>
  <ui-progress-spinner
    class="spinner"
    [strokeWidth]="8"
    [diameter]="70"
    uiColor="secondary"
  ></ui-progress-spinner>
</ng-template>
