<documents-documents-action-menu #x="triggerMenu" [data]="data()">
  <button
    design-icon-button
    type="button"
    [designMenuTriggerFor]="x.menu()"
    class="btn"
  >
    <i designIcon="more_second" designSize="20px"></i>
  </button>
</documents-documents-action-menu>
