<design-dialog-wrapper class="dialog-wrapper" cdkTrapFocus>
  <ng-container designDialogHeader>
    <button type="button" class="back-btn" (click)="backTo()">
      <i designIcon="arrow_back" designSize="20px"></i>
      <div class="back-btn__text" i18n="@@BACK_TO_PROJECT_INFO">
        Back to Project info
      </div>
    </button>
  </ng-container>

  <ng-container designDialogContent>
    <div class="header">
      <h1 class="message" i18n="@@PROJECTS_CONNECT_MESSAGE">
        Connected {{ data.projects[0].projectName }} with
        {{ data.projects[1].projectName }}
      </h1>

      <div class="status-chips">
        <div class="status-chips__badge status-chips__badge--success"></div>
        @if (isMobile()) {
          <i
            class="status-chips__mobile-icon--success"
            designIcon="link"
            designSize="20px"
          ></i>
        } @else {
          <div class="status-chips__name">{{ data.status | titlecase }}</div>
        }
      </div>
    </div>

    <div class="projects">
      @for (project of data.projects; track $index) {
        <procore-linked-project-card [data]="project" />
      }
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      type="button"
      (click)="close()"
      design-flat-button
      designButtonColor="accent"
      i18n="@@CLOSE"
    >
      Close
    </button>
  </ng-container>
</design-dialog-wrapper>
