<design-dialog-wrapper class="dialog-wrapper" cdkTrapFocus>
  <h1 class="title" designDialogHeader>{{ dialogData.title }}</h1>

  <ng-container designDialogContent>
    @if (path().length === 1) {
      <feature-projects-document-move-modal-item
        [isPrimary]="true"
        [isCurrentLocation]="currentLocationFolderId() === rootFolder[0].id"
      >
        <div>{{ rootFolder[0].name }}</div>
      </feature-projects-document-move-modal-item>
    } @else {
      <div class="back-btn">
        <button type="button" design-icon-button #backBtn>
          <i designIcon="arrow_forward" designSize="20px"></i>
        </button>

        <span> {{ path()[path().length - 1].name }}</span>
      </div>
    }

    <div class="folders__container">
      @for (element of foldersWithDisabledStatus(); track element.folder.id) {
        @if (element.disabled) {
          <design-tooltip
            i18n-tooltip="@@NOT_POSSIBLE_TO_MOVE_FOLDER_TO_ITSELF"
            tooltip="It is not possible to move the folder to itself"
          >
            <feature-projects-document-move-modal-item
              [data]="element.folder"
              [disabled]="element.disabled"
            >
              {{ element.folder.name }}
            </feature-projects-document-move-modal-item>
          </design-tooltip>
        } @else {
          <feature-projects-document-move-modal-item
            [data]="element.folder"
            [disabled]="element.disabled || false"
          >
            {{ element.folder.name }}
          </feature-projects-document-move-modal-item>
        }
      }
    </div>

    @if (!isMobile()) {
      <design-breadcrumb
        [ngStyle]="{ visibility: path().length > 1 ? 'visible' : 'hidden' }"
        [path]="path()"
      />
    }
  </ng-container>

  <div class="btn__container" designDialogFooter>
    <button design-stroked-button (click)="onCancel()" type="button">
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button
      design-flat-button
      designButtonColor="accent"
      [disabled]="
        selectedFolder().id === currentLocationFolderId() ||
        isCurrentMoveFolder()
      "
      (click)="onSelect()"
      type="button"
    >
      <div>{{ dialogData.btnLabel }}</div>
    </button>
  </div>
</design-dialog-wrapper>
