<audio #audioElement class="audio-element" controls>
  <source [src]="src" />
</audio>

<div class="content-wrapper">
  <button class="btn-play" design-icon-button (click)="toggle()">
    <!-- <i *ngIf="isPlaying; else playIcon" ui-icon name="icon_stop" size="sm"></i> -->
    <i *ngIf="isPlaying; else playIcon" designIcon="stop" designSize="1rem"></i>
    <ng-template #playIcon>
      <i designIcon="play_second" designSize="1rem"></i>
    </ng-template>
  </button>
  <div class="content-container">
    <b *ngIf="hasTitle" class="text text-ellipsis">
      {{ title }}
    </b>
    <div class="flex-container">
      <div class="flex-container">
        <span class="text right-margin">{{ timeProgress }}</span>
      </div>
      <div class="slider-container">
        <input
          #rangeElement
          type="range"
          min="0"
          max="0"
          value="0"
          class="slider"
        />
      </div>
    </div>
  </div>

  <ng-container *ngIf="controls">
    <button design-icon-button [designMenuTriggerFor]="optionsMenu">
      <i designIcon="more_second"></i>
    </button>
    <design-menu-panel #optionsMenu>
      <ng-content></ng-content>
    </design-menu-panel>
  </ng-container>
</div>
