<ng-container>
  <ui-sidenav-container>
    <ui-sidenav
      class="left-panel"
      data-cy="stages-left-panel-ui"
      mode="over"
      position="start"
      [opened]="hamburgerState$ | async"
    >
      <feature-stages-panel-left
        data-cy="stages-panel-left"
        #leftPanel
        (closed)="closeLeftPanel()"
        (openLeftPanel)="openFilterModal()"
      ></feature-stages-panel-left>
    </ui-sidenav>

    <ui-sidenav-content>
      <ng-container [ngTemplateOutlet]="background"> </ng-container>
    </ui-sidenav-content>

    <ui-sidenav
      #rightPanel
      class="right-panel"
      mode="over"
      position="end"
      (openedChange)="rightPanelOpenedChange($event)"
      *ngIf="(screenshot$ | async) === false"
      [opened]="noteSidePanelState$ | async"
    >
      @switch (sidenavContent$ | async) {
        @case ('note') {
          <feature-stages-stage-side-panel [projectId]="projectId()" />
        }
        @case ('punch-item') {
          <procore-punch-item-side-panel
            [stageName]="stageName()"
            (onClosePanel)="closeAndCleanSidenav()"
            (onTransferElement)="onTransferElement('Punch Item', $event)"
          />
        }
        @case ('rfi') {
          <procore-rfi-side-panel
            [stageName]="stageName()"
            (onClosePanel)="closeAndCleanSidenav()"
            (onTransferElement)="onTransferElement('RFI', $event)"
          />
        }
        @case ('measurement-area') {
          <feature-measurement-area
            [stageId]="stageId()"
            [noteId]="noteId()"
            [notes]="notes() || []"
            (onClose)="closeMeasurementTool(null)"
            [systemOfMeasurement]="(systemOfMeasurement$ | async)!"
            (onCloseWithType)="closeMeasurementTool($event)"
            [successCreateNote$]="noteAdded$"
            (onCreateNote)="createNote($event)"
            (onOpenRelatedNote)="onOpenRelatedNote($event)"
            [opened]="(rightPanel.openedChange | async) || rightPanel.opened"
          />
        }
        @case ('measurement-distance') {
          <feature-measurement-distance
            [stageId]="stageId()"
            [noteId]="noteId()"
            [notes]="notes() || []"
            (onClose)="closeMeasurementTool(null)"
            [systemOfMeasurement]="(systemOfMeasurement$ | async)!"
            (onCloseWithType)="closeMeasurementTool($event)"
            [successCreateNote$]="noteAdded$"
            (onCreateNote)="createNote($event)"
            (onOpenRelatedNote)="onOpenRelatedNote($event)"
            [opened]="(rightPanel.openedChange | async) || rightPanel.opened"
          />
        }
      }
    </ui-sidenav>
  </ui-sidenav-container>
</ng-container>

<ng-template #background let-showcase="showcase">
  <div class="foreground">
    <div class="content-container">
      <ng-container *ngIf="(screenshotMode$ | async) === false">
        <div
          data-index="1"
          class="content-container__float-button first"
          *ngIf="menuVisibilityState"
        >
          <design-tooltip
            i18n-tooltip="@@SHOW_STAGES_LISTS"
            tooltip="Show stages list"
            position="right"
          >
            <button
              design-fab-button
              data-cy="stages-fab-button"
              (click)="openLeftPanel()"
            >
              <i designIcon="stages"></i>
            </button>
          </design-tooltip>
        </div>

        <!-- <div
          data-index="2"
          class="content-container__float-button second"
          *ngIf="menuVisibilityState"
        >
          <design-tooltip
            i18n-tooltip="@@NOTE_FILTERS"
            tooltip="Notes filters"
            position="right"
          >
            <button design-fab-button (click)="openFilterModal()">
              <i designIcon="filter"></i>
            </button>
          </design-tooltip>
        </div> -->
      </ng-container>

      <div class="content-container__wrapper">
        <div
          class="content-container__wrapper--navigation"
          *ngIf="(screenshot$ | async) === false"
        >
          <ui-menu *ngIf="menuVisibilityState">
            <a
              design-flat-button
              designButtonColor="secondary"
              routerLink="info"
              routerLinkActive="design-button-selected"
              i18n="@@STAGE_INFO"
              data-cy="project-nav-stage-info"
              [queryParams]="{
                noteId: null,
                rfiId: null,
                punchItemId: null,
                sidenavContent: null
              }"
              queryParamsHandling="merge"
            >
              stage info
            </a>
            <a
              design-flat-button
              designButtonColor="secondary"
              data-cy="project-nav-communication"
              routerLink="annotations"
              [queryParams]="{
                noteId: null,
                rfiId: null,
                punchItemId: null,
                sidenavContent: null
              }"
              routerLinkActive="design-button-selected"
              queryParamsHandling="merge"
            >
              <ng-container i18n="@@ANNOTATIONS">Annotations</ng-container>
            </a>
            <a
              class="matterport-nav"
              design-flat-button
              designButtonColor="secondary"
              routerLink="matterport"
              data-cy="project-nav-matterport"
              [queryParams]="{
                noteId: null,
                rfiId: null,
                punchItemId: null,
                sidenavContent: null
              }"
              routerLinkActive="design-button-selected"
              i18n="@@MATTERPORT"
              queryParamsHandling="merge"
              i18n="@@MATTERPORT"
            >
              matterport
            </a>
          </ui-menu>
          @if (projectId()) {
            <div class="active-filter-section__filters">
              <lib-annotations-active-filter-chips
                (showAllFilters)="openFilterModal()"
              />
            </div>
          }
        </div>
        <div class="content-container__wrapper--router">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</ng-template>
