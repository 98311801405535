<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogContent>
    <p class="text">
      {{ data.text }}
    </p>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      *ngIf="data.cancel.visible"
      design-stroked-button
      designButtonColor="primary"
      (click)="cancel()"
    >
      {{ data.cancel.label }}
    </button>
    <button
      *ngIf="data.confirm.visible"
      design-flat-button
      designButtonColor="secondary"
      (click)="confirm()"
    >
      {{ data.confirm.label }}
    </button>
  </ng-container>
</design-dialog-wrapper>
