import { NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  Signal,
  viewChild,
  viewChildren
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { SortSwitchComponent } from '@simlab/design/sort-switch';
import {
  DocumentStates,
  FileCardStates,
  FolderStates,
  RowStates
} from '@simlab/documents/models';
import { DeleteConfirmationDialogComponent } from '@simlab/ui/modal';
import { Observable } from 'rxjs';
import {
  SelectableOption,
  TSelectableItem
} from '../can-select/can-select.directive';
import { DocumentActionsDirective } from '../document-action-panel/documentAction.directive';
import { DocumentsActionMenuComponent } from '../documents-action-menu/documents-action-menu.component';
import { FileCardComponent } from '../file-card/file-card.component';
import { FolderComponent } from '../folder/folder.component';

interface GridViewModel {
  folders: FolderStates[];
  files: FileCardStates[];
}

@Component({
  selector: 'documents-grid',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    FileCardComponent,
    FolderComponent,
    DocumentsActionMenuComponent,
    DeleteConfirmationDialogComponent,
    DocumentActionsDirective
  ],
  templateUrl: './documents-grid.component.html',
  styleUrl: './documents-grid.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsGridComponent implements AfterViewInit {
  readonly data = input.required<DocumentStates[]>();
  readonly selectedIds = input<string[]>();
  readonly viewModel: Signal<GridViewModel> = computed(() => {
    const data = this.data();

    return {
      folders: data.filter(
        (item): item is DocumentStates<'FOLDER'> => item.data.isDirectory
      ),
      files: data.filter(
        (item): item is DocumentStates<'FILE'> => !item.data.isDirectory
      )
    };
  });
  readonly options =
    viewChildren<TSelectableItem<FileCardStates | FolderStates | RowStates>>(
      SelectableOption
    );
  readonly options$: Observable<readonly TSelectableItem<any>[]> = toObservable(
    this.options
  );
  readonly sortSwitchView = viewChild(SortSwitchComponent);

  ngAfterViewInit() {
    const selectedIds = this.selectedIds();
    this.options().forEach(
      (item) => item.id && selectedIds?.includes(item.id) && item.select()
    );
  }
}
