import { Pipe, PipeTransform, inject } from '@angular/core';
import {
  SystemOfMeasurement,
  TMeasurement,
  TVerticle
} from '@simlab/feature-measurement/models';
import { Vector3 } from 'three';
import { CalculateMeasurementSumPipe } from './base-calculate-measurement.pipe';
import { UnitsPreferredByUserPipe } from './base-units-preferred.pipe';
function distanceBetweenVectors(
  vector1: TVerticle,
  vector2: TVerticle
): number {
  return Math.sqrt(
    Math.pow(vector1.x - vector2.x, 2) +
      Math.pow(vector1.y - vector2.y, 2) +
      Math.pow(vector1.z - vector2.z, 2)
  );
}
@Pipe({
  name: 'calculateDistanceMeasurementSum'
})
export class CalculateDistanceMeasurementSumPipe
  extends CalculateMeasurementSumPipe
  implements PipeTransform
{
  private readonly _unitsPreferredByUserPipe = inject(UnitsPreferredByUserPipe);
  transform(value: TMeasurement[], units: SystemOfMeasurement): number {
    return value.reduce((previousValue: number, currentValue: TMeasurement) => {
      const data = currentValue.data as TVerticle[];
      let value: number = 0;
      for (let index = 0; index < data.length - 1; index++) {
        const v1 = new Vector3(data[index].x, data[index].y, data[index].z);
        const v2 = new Vector3(
          data[index + 1].x,
          data[index + 1].y,
          data[index + 1].z
        );
        value += v1.distanceTo(v2);
      }
      return (
        previousValue + this._unitsPreferredByUserPipe.transform(value, units)
      );
    }, 0);
  }
}
