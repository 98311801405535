import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { SortDirection } from './models/sort-direction';

export interface DesignSortable {
  /** The id of the column being sorted. */
  id: string;

  /** Starting sort direction. */
  start: SortDirection;

  /** Whether to disable clearing the sorting state. */
  disableClear: boolean;
}

export interface Sort {
  /** The id of the column being sorted. */
  active: string;

  /** The sort direction. */
  direction: SortDirection;
}

@Directive({
  selector: '[designSort]',
  exportAs: 'designSort',
  host: {
    class: 'design-sort',
  },
  standalone: true,
})
export class DesignSort implements OnChanges, OnDestroy, OnInit {
  private _initializedStream = new ReplaySubject<void>(1);

  private _sortables = new Map<string, DesignSortable>();

  readonly _stateChanges = new Subject<void>();

  @Input('designSortActive') active: string | undefined;

  @Input('designSortStart') start: SortDirection = 'asc';

  @Input('designSortDirection')
  get direction(): SortDirection {
    return this._direction;
  }
  set direction(direction: SortDirection) {
    this._direction = direction;
  }
  private _direction: SortDirection = 'asc';

  @Output('designSortChange') readonly sortChange: EventEmitter<Sort> =
    new EventEmitter<Sort>();

  initialized$: Observable<void> = this._initializedStream;

  register(sortable: DesignSortable): void {
    this._sortables.set(sortable.id, sortable);
  }

  deregister(sortable: DesignSortable): void {
    this._sortables.delete(sortable.id);
  }

  sort(sortable: DesignSortable): void {
    if (this.active != sortable.id) {
      this.active = sortable.id;
      this.direction = 'desc';
    } else {
      this.direction = this.getNextSortDirection(sortable);
    }

    this.sortChange.emit({ active: this.active, direction: this.direction });
  }

  getNextSortDirection(sortable: DesignSortable): SortDirection {
    const sortDirectionCycle = this.getSortDirectionCycle(
      sortable.start || this.start,
    );

    let nextDirectionIndex = sortDirectionCycle.indexOf(this.direction) + 1;
    if (nextDirectionIndex >= sortDirectionCycle.length) {
      nextDirectionIndex = 0;
    }
    return sortDirectionCycle[nextDirectionIndex];
  }

  getSortDirectionCycle(start: SortDirection): SortDirection[] {
    const sortOrder: SortDirection[] = ['desc', 'asc'];
    if (start == 'asc') {
      sortOrder.reverse();
    }
    return sortOrder;
  }

  ngOnInit() {
    this._initializedStream.next();
  }

  ngOnChanges() {
    this._stateChanges.next();
  }

  ngOnDestroy() {
    this._stateChanges.complete();
    this._initializedStream.complete();
  }
}
