import { computed, inject, Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { Marker } from '@simlab/data-access';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  procoreBaseInfoPayload,
  ProcoreBaseProjectInfo,
  RFI,
  RFIStatus,
  RfiUserDisplayElements
} from '@simlab/procore/models';
import { RFIListStore } from '@simlab/procore/services';
import { distinctUntilChanged, filter, pipe, switchMap, tap } from 'rxjs';

export type TRfiState = {
  rfi: RFI;
  allowedActions: RfiUserDisplayElements | undefined;
  isAllowedActionLoading: boolean;
};

const initialState: TRfiState = {
  rfi: {
    id: '0',
    procoreId: 0,
    stageId: '',
    accepted: false,
    assignee: {
      id: 0,
      login: '',
      name: '',
      companyName: ''
    },
    assignees: [],
    ballInCourts: [],
    ballInCourt: {
      id: 0,
      login: '',
      name: '',
      companyName: ''
    },
    ballInCourtRole: 1,
    costCode: {
      id: 0,
      name: '',
      fullCode: ''
    },
    costImpact: {
      status: '',
      value: 0
    },
    createdAt: '',
    createdBy: {
      id: 0,
      login: '',
      name: '',
      companyName: ''
    },
    deletedAt: '',
    deleted: false,
    drawingNumber: 0,
    drawingIds: [],
    dueDate: '',
    draft: false,
    fullNumber: '',
    initiatedAt: '',
    link: '',
    number: '',
    prefix: '',
    private: false,
    rfiManager: {
      id: 0,
      login: '',
      name: '',
      companyName: ''
    },
    status: RFIStatus.Draft,
    specificationSection: {
      id: 0,
      number: '',
      description: '',
      label: '',
      currentRevisionId: 0
    },
    subject: '',
    scheduleImpact: {
      status: '',
      value: 0
    },
    updatedAt: '',
    translatedStatus: '',
    timeResolved: '',
    title: '',
    distributionlist: [],
    receivedFrom: {
      id: 0,
      login: '',
      name: '',
      companyName: ''
    },
    reference: '',
    responsibleContractor: {
      id: 0,
      name: ''
    },
    questions: [],
    customFields: {
      additionalProp1: {
        dataType: '',
        value: ''
      },
      additionalProp2: {
        dataType: '',
        value: ''
      },
      additionalProp3: {
        dataType: '',
        value: ''
      }
    },
    marker: <Marker>{},
    itemUrl: ''
  },
  allowedActions: undefined,
  isAllowedActionLoading: true
};

@Injectable()
export class RFIState {
  private readonly _store = inject(RFIListStore);
  private readonly _api = inject(ApiProcoreService);
  private readonly _baseInfoProcore = inject(procoreBaseInfoPayload);

  private readonly _state = signalState({
    ...initialState,
    rfi: { ...initialState.rfi }
  });

  readonly isLoading = computed(() => {
    const isItemLoading = this._store.isItemLoading();
    const isAllowedActionLoading = this._state.isAllowedActionLoading();

    return isItemLoading || isAllowedActionLoading;
  });
  readonly allowedActions = this._state.allowedActions;
  readonly rfi = this._state.rfi;

  readonly loadRfi = rxMethod<void>(
    pipe(
      switchMap(() =>
        this._store
          .getItemSelected$()
          .pipe(tap((rfi) => patchState(this._state, { rfi })))
      )
    )
  );

  readonly loadAllowedActions = rxMethod<ProcoreBaseProjectInfo | undefined>(
    pipe(
      filter(
        (procoreData): procoreData is ProcoreBaseProjectInfo =>
          procoreData !== undefined
      ),
      switchMap(({ procoreCompanyId, procoreProjectId }) =>
        this._store.getItemSelected$().pipe(
          distinctUntilChanged(),
          tap(() => patchState(this._state, { isAllowedActionLoading: true })),
          switchMap(({ procoreId }) => {
            return this._api
              .getRfiAllowedActions({
                procoreCompanyId,
                procoreProjectId,
                procoreRfiId: procoreId
              })
              .pipe(
                tapResponse({
                  next: (allowedActions) =>
                    patchState(this._state, { allowedActions }),
                  error: console.error,
                  finalize: () =>
                    patchState(this._state, { isAllowedActionLoading: false })
                })
              );
          })
        )
      )
    )
  );

  constructor() {
    this.loadRfi();
    this.loadAllowedActions(this._baseInfoProcore());
  }

  deleteRFI() {
    const { procoreProjectId, procoreCompanyId } =
      this._baseInfoProcore() || {};
    const rfi = this._store.itemSelected();
    if (!rfi || !procoreProjectId || !procoreCompanyId) return;

    this._store.deleteItem({
      procoreId: rfi.procoreId,
      procoreProjectId,
      procoreCompanyId,
      status: rfi.status
    });
  }
}
