import {
  Directive,
  InjectionToken,
  Input,
  Output,
  signal
} from '@angular/core';

import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TDistance } from '@simlab/feature-measurement/models';
import { LineMeasurementComponent } from '@simlab/matterport/lib/services/line-measurement-tool.service';
import { TMeasurementMesh } from '@simlab/simlab-facility-management/sub-features/line-measurement';
import { IMeasurementTool } from '@simlab/simlab-facility-management/sub-features/measurement';
import {
  defer,
  filter,
  firstValueFrom,
  fromEvent,
  merge,
  of,
  switchMap,
  tap
} from 'rxjs';
import { MatterportBaseMeasurementDirective } from './matterport-base-measurement';

export const LineMeasurementToken = new InjectionToken<
  IMeasurementTool<LineMeasurementComponent, { id: string; color?: string }>
>('Measurement controller');
const SHOW_MEASUREMENT_KEY = 'V';

@Directive({
  selector: '[simlabMatterportLineMeasurement]',
  exportAs: 'simlabMatterportLineMeasurement',
  standalone: true,
  providers: [
    {
      provide: LineMeasurementToken,
      useExisting: MatterportLineMeasurementDirective
    }
  ]
})
export class MatterportLineMeasurementDirective extends MatterportBaseMeasurementDirective<
  LineMeasurementComponent,
  TMeasurementMesh
> {
  private _drawAreaOnMatterportOpen$ = defer(() =>
    this.matterportHost.matterportIsOpen$.pipe(
      filter((isOpen) => isOpen),
      tap(() => {
        this.mesh.deleteAllMeasurements();
      }),
      tap(() => {
        const measurements = this._lineMeasurement();
        measurements.forEach(({ id, color, data }) => {
          this.addMeasurement({
            id,
            color,
            points: data
          });
        });
      })
    )
  );

  readonly matterportIsOpen = toSignal(this.matterportHost.matterportIsOpen$);

  @Input() set segmentsLineVisibility(visible: boolean) {
    this.ngZone.runTask(() => {
      const isOpen = this.matterportIsOpen();
      if (isOpen) this.mesh.segmentsVisibility = visible;
    });
  }

  @Output() showDistanceMeasurementsSegments = defer(() =>
    this.ngZone.runTask(() =>
      this.matterportHost.matterportIsOpen$.pipe(
        filter((isOpen) => isOpen),
        switchMap(() =>
          merge(
            this.matterportHost.matterportManagerService.events.fromMatterportEvent<KeyboardEvent>(
              'keyup'
            ),
            fromEvent<KeyboardEvent>(window, 'keyup')
          ).pipe(
            filter(
              (event: KeyboardEvent) =>
                event.key.toUpperCase() === SHOW_MEASUREMENT_KEY
            ),
            switchMap((key) => this.ngZone.run(() => of(key)))
          )
        )
      )
    )
  );

  constructor() {
    super();
    this._drawAreaOnMatterportOpen$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  get mesh(): IMeasurementTool<LineMeasurementComponent, TMeasurementMesh> {
    return this.matterportHost.matterportManagerService.lineMeasurementMesh;
  }

  override hideMeasurements = (omit: string[] = []) => {
    this.mesh.hideMeasurements(omit);
  };

  private readonly _lineMeasurement = signal<TDistance[]>([]);
  private readonly _matterportOpened = toSignal(
    this.matterportHost.matterportIsOpen$
  );
  public get simlabMatterportLineMeasurement() {
    return this._lineMeasurement();
  }
  @Input() set simlabMatterportLineMeasurement(value: TDistance[]) {
    this._lineMeasurement.set(value);
  }

  createAreaPromise = (): Promise<string> => {
    return firstValueFrom(
      this.matterportHost.matterportIsOpen$.pipe(filter((e) => e))
    ).then(() => this.mesh.createMeasurement());
  };
}
