import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { DesignIcon } from '@simlab/design/icon';
import { ILLUSTRATION_TYPE } from '@simlab/design/illustration';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiIconModule } from '@simlab/ui/icon';

export type ProjectCard = {
  logoUrl?: string | undefined;
  logoIcon?: ILLUSTRATION_TYPE | undefined;
  companyName: string;
  projectName: string;
  status: 'CONNECTED' | 'DISCONNECTED';
};

const translation = {
  company: $localize`:@@COMPANY:Company`,
  project: $localize`:@@PROJECT:Project`
};

@Component({
  selector: 'procore-linked-project-card',
  standalone: true,
  imports: [
    DesignIcon,
    UiDividerModule,
    NgTemplateOutlet,
    NgClass,
    UiIconModule
  ],
  templateUrl: './linked-project-card.component.html',
  styleUrl: './linked-project-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkedProjectCardComponent {
  protected readonly translation = translation;

  readonly data = input.required<ProjectCard>();
}
