import { Pipe, PipeTransform } from '@angular/core';
import { DesignSymbolInput } from '@simlab/design/internal';
import { PunchListWorkflowStatus } from '@simlab/procore/models';

const punchItemStatusLabel: Record<PunchListWorkflowStatus, DesignSymbolInput> =
  {
    [PunchListWorkflowStatus.Draft]: 'InfoCircle',
    [PunchListWorkflowStatus.Initiated]: 'InfoCircle',
    [PunchListWorkflowStatus.InDispute]: 'InfoCircle',
    [PunchListWorkflowStatus.WorkRequired]: 'InfoCircle',
    [PunchListWorkflowStatus.ReadyForReview]: 'InfoCircle',
    [PunchListWorkflowStatus.WorkNotAccepted]: 'InfoCircle',
    [PunchListWorkflowStatus.ReadyToClose]: 'InfoCircle',
    [PunchListWorkflowStatus.NotAcceptedByCreator]: 'InfoCircle',
    [PunchListWorkflowStatus.Closed]: 'InfoCircle'
  };

@Pipe({ name: 'getPunchItemStatusIcon', standalone: true })
export class PunchItemStatusIconGetterPipe implements PipeTransform {
  transform(status: PunchListWorkflowStatus): DesignSymbolInput {
    return punchItemStatusLabel[status];
  }
}
