import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  input,
  OnChanges,
  Renderer2,
  Signal,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DesignHelperModule } from '@simlab/design/helper';
import { IllustrationSizes } from '../../models/illustration-sizes';
import { ILLUSTRATION_TYPE } from '../../models/illustration-type';
import { IllustrationCacheService } from '../../services/illustration-cache.service';

type ComponentParam<
  TName extends keyof IllustrationComponent,
  TValue = IllustrationComponent[TName],
> =
  TValue extends Signal<unknown>
    ? ReturnType<TValue> | null | undefined
    : never;

@Component({
  selector: `i[design-illustration]`,
  exportAs: 'designIllustration',
  template: '',
  styleUrls: ['./illustration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DesignHelperModule],
  providers: [IllustrationCacheService],
  standalone: true,
})
export class IllustrationComponent implements OnChanges {
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _iconCacheService = inject(IllustrationCacheService, {
    optional: true,
  });
  private readonly _elementRef = inject(ElementRef);
  private readonly _renderer = inject(Renderer2);

  readonly name = input.required<ILLUSTRATION_TYPE>();
  readonly size = input.required<IllustrationSizes>();
  readonly customSize = input<string | undefined>(undefined);
  readonly defaultColor = input(false, { transform: booleanAttribute });

  constructor() {
    const element = this._renderer.createElement('span');
    this._renderer.addClass(element, 'ui-icon-wrapper');
    this._renderer.appendChild(this._elementRef.nativeElement, element);
    this._renderer.addClass(this._elementRef.nativeElement, 'icon');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._defaultColorChangesHandler(changes['defaultColor']?.currentValue);
    this._customSizeChangesHandler(changes['customSize']?.currentValue);
    this._sizeChangesHandler(changes['size']?.currentValue);
    this._nameChangesHandler(changes['name']?.currentValue);
  }

  private _defaultColorChangesHandler(value: ComponentParam<'defaultColor'>) {
    if (!value) return;

    this._renderer.addClass(
      this._elementRef.nativeElement,
      'force-default-color',
    );
  }

  private _customSizeChangesHandler(value: ComponentParam<'customSize'>) {
    if (value == null) return;

    const wrapper = (this._elementRef.nativeElement as Element).firstChild;
    if (!wrapper) return;

    this._renderer.setStyle(wrapper, 'width', value);
    this._renderer.setStyle(wrapper, 'height', value);
  }

  private _sizeChangesHandler(value: ComponentParam<'size'>) {
    if (value == null) return;

    const wrapper = (this._elementRef.nativeElement as Element).firstChild;
    if (!wrapper) return;

    this._renderer.addClass(wrapper, `ui-${value}`);
  }

  private _nameChangesHandler(value: ComponentParam<'name'>) {
    if (value == null) return;

    const wrapper = (this._elementRef.nativeElement as Element).firstChild;
    if (!wrapper || !this._iconCacheService || !value) return;

    this._iconCacheService
      .getSvg(value)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((svg: string) => {
        (wrapper as Element).innerHTML = svg;
      });
  }
}
