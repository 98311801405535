<div [class]="type === 'color' ? 'range-slider-color' : 'range-slider'">
  <ui-slider-color
    *ngIf="type === 'color'"
    (color)="color.emit($event)"
    (changeColorOfBrush)="changeColorOfBrush.emit($event)"
  ></ui-slider-color>
  <input
    *ngIf="type === 'triangle'"
    [class]="type"
    type="range"
    [value]="rangeValue"
    (input)="trackInput($event)"
    [step]="rangeStage"
    [min]="rangeMin"
    [max]="rangeMax"
  />
</div>
