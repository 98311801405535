<ng-template let-formFieldId="id">
  <div
    #optionsPanel
    class="design-autocomplete-panel"
    role="listbox"
    cdkTrapFocus
  >
    <ng-content select="[designOption]"></ng-content>
  </div>
</ng-template>
