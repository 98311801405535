import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  standalone: true,
  selector: 'user-link',
  template: `<ng-content></ng-content>`,

  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UserComponent {
  private _value!: string;
  @Input() set value(value: string) {
    this._value = value;
  }
  get value() {
    return this._value;
  }
  // @HostListener('click')
  // onClick() {
  // }
}
