import { Injectable } from '@angular/core';
import { ProjectsFacade, StagesFacade } from '@simlab/data-store';
import { ViewOnlyDialogComponent } from '@simlab/feature/projects';
import { ConfirmationModalRef, ModalService } from '@simlab/ui/modal';
import { RouterStoreParams } from '@simlab/util-shared';
//with path is problem to test
import { Observable, iif, map, mergeMap, of, tap } from 'rxjs';
@Injectable()
export class ProjectLimitsService {
  readonly projectIsActive$: Observable<boolean> =
    this.stagesFacade.getRouteNestedParams$.pipe(
      mergeMap((routerParams: RouterStoreParams) =>
        iif(
          () => routerParams.params['projectId'],
          this.projectFacade
            .selectProjectById$(routerParams.params['projectId'] as string)
            .pipe(map((data) => data?.isActive || false)),
          of(false)
        )
      )
    );

  readonly checkViewOnly$: Observable<boolean> = this.projectIsActive$.pipe(
    tap((isActivate: boolean) => {
      if (!isActivate) {
        this._openProjectViewOnlyInfoDialog();
      }
    })
  );

  constructor(
    private readonly modalService: ModalService,
    private readonly stagesFacade: StagesFacade,
    private readonly projectFacade: ProjectsFacade
  ) {}

  private _openProjectViewOnlyInfoDialog(): ConfirmationModalRef<ViewOnlyDialogComponent> {
    return this.modalService.createModalWithProviders(ViewOnlyDialogComponent, {
      centered: true,
      offset: {
        x: 0,
        y: 0
      },
      maxWidth: 'min(90%, 380px)',
      minWidth: 'min(90%, 380px)',
      maxHeight: '90%'
    });
  }
}
