import { inject, Injectable, TemplateRef } from '@angular/core';
import { DesignSymbol } from '@simlab/design/internal';
import {
  DocumentsAction,
  DocumentsActionsBase
} from '@simlab/documents/data-access';
import { DocumentComponentsLoadedState } from '@simlab/documents/models';
import { ProcoreDocumentsStateService } from '../../../../../../../services/procore-documents-state.service';

export interface ProcoreDrawingsActionsBase extends DocumentsActionsBase {
  templateToDownload: TemplateRef<any> | undefined;
  download: (data: DocumentComponentsLoadedState[]) => void;
}

const translation = {
  download: $localize`:@@GEN_DOWNLOAD:Download`
};

@Injectable()
export class ProcoreDrawingsInModalActionsService
  implements ProcoreDrawingsActionsBase
{
  private readonly _docState = inject(ProcoreDocumentsStateService);

  templateToDownload: TemplateRef<any> | undefined;

  download(items: DocumentComponentsLoadedState[]) {
    this._docState.downloadFiles(items, this.templateToDownload);
  }

  actionList: DocumentsAction[] = [
    {
      name: translation.download,
      icon: DesignSymbol.FileDownload,
      trigger: (data) => this.download([data])
    }
  ];
}
