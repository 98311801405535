import { Directive, Input, inject } from '@angular/core';
import { ApiFacadeService, Media, MediaInfo } from '@simlab/data-access';
import { ProcoreDownloadResourceGetterService } from '@simlab/procore/services';
import {
  Observable,
  Subject,
  catchError,
  forkJoin,
  map,
  mergeMap,
  of
} from 'rxjs';

export type PhotoQuality =
  | 'Hd'
  | 'Thumbnail'
  | 'Small'
  | 'Medium'
  | 'Big'
  | 'None';
@Directive()
export class BaseMedia {
  protected readonly _apiFacadeService = inject(ApiFacadeService);
  private readonly _procoreResource = inject(
    ProcoreDownloadResourceGetterService,
    {
      optional: true
    }
  );

  @Input() media!: Media;
  protected readonly _destroySource: Subject<void> = new Subject<void>();

  getBlobSecureUrl(
    mediaList: Media[] | null,
    quality: PhotoQuality = 'None'
  ): Observable<Media[]> {
    const url: Observable<Media>[] = [];
    mediaList?.forEach((media: Media) => {
      if (media.url !== '') {
        url.push(
          this._apiFacadeService.blobs
            .generateBlobUrlForDownload({
              blobUrl:
                quality !== 'None'
                  ? this._correctPhotoUrl(media.url, quality)
                  : media.url
            })
            .pipe(
              mergeMap((url: string) => {
                return of({ ...media, privateUrl: url } as Media);
              }),
              catchError((e) => {
                console.error(e);
                return of(e);
              })
            )
        );
      } else {
        url.push(of(media));
      }
    });
    return forkJoin(url);
  }

  getSingleBlobSecureUrl(
    media: Media,
    quality: PhotoQuality = 'None'
  ): Observable<Media> {
    if (
      (this.isProcoreImageMedia(media) || this.isProcoreDocumentMedia(media)) &&
      this._procoreResource !== null
    ) {
      return this._procoreResource.getResourceDownloadUrl$(media.url).pipe(
        map((resource) => <Media>{ ...media, privateUrl: resource }),
        catchError((e) => {
          console.error(e);
          return of(e);
        })
      );
    }

    if (this.isProcoreDrawingMedia(media) && this._procoreResource !== null) {
      return of(<Media>{
        ...media,
        name: media.name
      });
    }

    if (media.url !== '') {
      return this._apiFacadeService.blobs
        .generateBlobUrlForDownload({
          blobUrl:
            quality !== 'None'
              ? this._correctPhotoUrl(media.url, quality)
              : media.url
        })
        .pipe(
          mergeMap((url: string) => {
            return of({ ...media, privateUrl: url } as Media);
          }),
          catchError((e) => {
            console.error(e);
            return of(e);
          })
        );
    } else {
      return of(media);
    }
  }

  getObservableBlobSecureUrl(
    media: Media,
    quality: PhotoQuality = 'None'
  ): Observable<string> {
    if (
      (this.isProcoreImageMedia(media) || this.isProcoreDocumentMedia(media)) &&
      this._procoreResource !== null
    ) {
      return this._procoreResource.getResourceDownloadUrl$(media.url);
    }

    if (this.isProcoreDrawingMedia(media) && this._procoreResource !== null) {
      return of(media.pdfUrl as string);
    }

    if (media.url !== '') {
      return this._apiFacadeService.blobs
        .generateBlobUrlForDownload({
          blobUrl:
            quality !== 'None'
              ? this._correctPhotoUrl(media.url, quality)
              : media.url
        })
        .pipe(
          map((url: string) => {
            return url;
          }),
          catchError((e) => {
            console.error(e);
            return of(e);
          })
        );
    } else {
      return of(media.url);
    }
  }

  private _correctPhotoUrl = (url: string, quality: PhotoQuality) => {
    const splitedUrl = url.split('/');
    const [name, ext] = splitedUrl[splitedUrl.length - 1].split('.');
    const small = `${name}_${quality}.${ext}`;
    splitedUrl.splice(splitedUrl.length - 1, 1, small);
    return splitedUrl.join('/');
  };

  protected deleteMedia$(
    media: Media | MediaInfo,
    selectedNoteId: string
  ): Observable<unknown> {
    if (this.isProcoreImageMedia(media))
      return this._apiFacadeService.digitalNotes.removeProcoreImage({
        id: media.id,
        rootNoteId: selectedNoteId
      });
    if (this.isProcoreDocumentMedia(media))
      return this._apiFacadeService.digitalNotes.removeProcoreDocument({
        id: media.id,
        rootNoteId: selectedNoteId
      });

    if (this.isProcoreDrawingMedia(media))
      return this._apiFacadeService.digitalNotes.removeProcoreDrawing({
        id: media.id,
        rootNoteId: selectedNoteId
      });

    return this._apiFacadeService.digitalNotes.removeDigitalNoteFromRootNote({
      digitalNoteId: media.id,
      rootNoteId: selectedNoteId
    });
  }

  protected isProcoreImageMedia(media: Media | MediaInfo) {
    return (
      ('procoreImageId' in media && 'thumbnailUrl' in media) ||
      media.type === 'ProcoreImage'
    );
  }

  protected isProcoreDrawingMedia(media: Media | MediaInfo) {
    return (
      ('procoreDrawingId' in media && 'pdfUrl' in media && 'pngUrl' in media) ||
      media.type === 'ProcoreDrawing'
    );
  }

  protected isProcoreDocumentMedia(media: Media | MediaInfo) {
    return 'procoreDocumentId' in media || media.type === 'ProcoreDocument';
  }
}
