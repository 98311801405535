import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, Input, inject } from '@angular/core';
import { designChipToken } from '../tokens/chip.token';
import { DesignChip } from './chip.component';

@Directive()
export abstract class ChipActionBase {
      protected readonly _parentChip = inject<DesignChip>(designChipToken);

  /** Whether the action is disabled. */
  @Input()
  get disabled(): boolean {
    return this._disabled || this._parentChip.disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  handleClick(event: MouseEvent) {
    throw new Error('Nie ma lipy :D');
  }
}
