<input
  #photo
  type="file"
  id="imageFile"
  [accept]="acceptExtension"
  data-cy="input-image-upload"
  style="display: none"
  [disabled]="disabled"
  (touch)="onTouch()"
  (change)="processFile($event)"
/>
<ng-content></ng-content>

<!-- NOTE: ngx-image-cropper supports (only png, gif and jpg) -->
<!-- https://www.npmjs.com/package/ngx-image-cropper -->
