<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel i18n="@@AUDIO_RECORDING">Audio recording</h2>
  </ng-container>

  <ng-container designDialogContent>
    <div class="dialog-content-container" [formGroup]="dialogFormGroup" #form>
      <ng-container *ngIf="hasError; else noErrorContent">
        <div class="split-view">
          <i ui-font fontName="audio" fontSize="4rem"></i>
          <div class="push-to-right">
            <div>
              <b>
                <ng-container
                  *ngIf="permissionDenied; else wentWrong"
                  i18n="@@NOTE_AUDIO_ALLOW_USE_MIRCO"
                >
                  Allow application to use microphone
                </ng-container>
                <ng-template #wentWrong i18n="@@NOTE_AUDIO_WENT_WRONG">
                  Oops! Something went wrong.
                </ng-template>
              </b>
            </div>
            <div>
              <ng-container
                *ngIf="permissionDenied; else changeBrowser"
                i18n="@@NOTE_AUDIO_THEN_REFRESH_BROWSER"
              >
                Then refresh the browser
              </ng-container>
              <ng-template #changeBrowser i18n="@@NOTE_AUDIO_CHANGE_BROWSER">
                Change browser or plug in microphone.
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noErrorContent>
        <ng-container *ngIf="hasRecording; else record">
          <span class="text" i18n="@@RE_RECORDING_MAX_DURATION">
            Click to re-record (MAX 60S)
          </span>
        </ng-container>
        <ng-template #record>
          <span class="text" i18n="@@RECORDING_MAX_DURATION">
            Click to record (MAX 60S)
          </span>
        </ng-template>

        <button
          class="recording-btn"
          design-fab-button
          designButtonColor="primary"
          (click)="toggle()"
          [@activeInactive]="state ? 'active' : 'inactive'"
        >
          <ng-container *ngIf="state; else iconElement">
            <span class="counter-text">{{ counter$ | async }}</span>
          </ng-container>
          <ng-template #iconElement>
            <i designIcon="audio" designSize="2rem"></i>
          </ng-template>
        </button>

        <ng-container *ngIf="recording$ | async as source">
          <ng-container *ngIf="hasRecording && !state">
            <ui-audio [src]="source.url"> </ui-audio>
            <ui-form-field>
              <label i18n="@@AUDIO_TITLE">Audio title</label>
              <ui-input
                placeholder="my-simple-recording"
                formControlName="title"
              ></ui-input>
            </ui-form-field>
          </ng-container>
        </ng-container>
      </ng-template>
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <ng-container *ngIf="hasError; else noErrorAction">
      <button
        design-flat-button
        (click)="close()"
        designButtonColor="secondary"
      >
        <div i18n="@@OK">Ok</div>
      </button>
    </ng-container>
  </ng-container>
</design-dialog-wrapper>

<ng-template #noErrorAction>
  <button design-stroked-button (click)="close()">Cancel</button>
  <button
    design-flat-button
    designButtonColor="accent"
    (click)="save()"
    [disabled]="hasRecording === false || state"
  >
    <div i18n="@@SAVE">Save</div>
  </button>
</ng-template>
