<ng-container *ngIf="!onPointAdd; else toggleButton">
  <button
    [disabled]="addPointDisabled || onPointDelete"
    design-icon-button
    class="icon-button-color"
    (click)="addPoint()"
  >
    <i designIcon="add_circle" designSize="2rem"></i>
  </button>
</ng-container>
<ng-container *ngIf="!onPointDelete; else toggleButton">
  <button
    [disabled]="removePointDisabled || onPointAdd || areaMode === 'create'"
    design-icon-button
    class="icon-button-color"
    (click)="deletePoint()"
  >
    <i designIcon="remove_circle" designSize="2rem"></i>
  </button>
</ng-container>

<ui-divider vertical></ui-divider>
<button
  [disabled]="undoDisabled || onPointAdd || onPointDelete || !canUndo"
  design-icon-button
  class="icon-button-color"
  (click)="undo()"
>
  <i designIcon="back" designSize="2rem"></i>
</button>
<button
  [disabled]="redoDisabled || onPointAdd || onPointDelete || !canRedo"
  design-icon-button
  class="icon-button-color"
  (click)="redo()"
>
  <i designIcon="forward" designSize="2rem"></i>
</button>
<ui-divider vertical></ui-divider>
<button design-flat-button designButtonColor="secondary" (click)="cancel()">
  <div class="btn-content-conteiner">
    <i designIcon="cross" designSize="1.5rem"></i
    ><span i18n="@@CANCEL">Cancel</span>
  </div>
</button>
<button
  [disabled]="saveDisabled || onPointAdd || onPointDelete"
  design-flat-button
  designButtonColor="accent"
  (click)="save()"
>
  <div class="btn-content-conteiner">
    <i designIcon="save" designSize="1.5rem"></i
    ><span i18n="@@GEN_SAVE_AND_EXIT">Save and Exit</span>
  </div>
</button>
<ng-template #toggleButton>
  <button
    design-icon-button
    class="icon-button-color"
    (click)="cancelListener()"
  >
    <i designIcon="cross" designSize="2rem"></i>
  </button>
</ng-template>
