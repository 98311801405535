@if (data(); as data) {
  <header class="card__header">
    <div class="card__header-name-container">
      <documents-document-badge-state
        [data]="data"
        [isSelected]="isSelected()"
      />

      <span class="card__file-name" [title]="data.data.name">{{
        data.data.name
      }}</span>
    </div>

    <documents-documents-action-btn [data]="data.data" />
  </header>

  <div class="card__image-container">
    @if (data.state === 'LOADED') {
      <documents-item-card-image
        [thumbnailUrl]="data.data.mediumSizeUrl || data.data.thumbnailUrl"
        [name]="data.data.name"
        [documentType]="data.data.documentType"
      />
    }
  </div>

  <div class="card__creator-container">
    <div class="card__creator">{{ data.data.creatorName }}</div>
    <div>
      {{ data.data.createdAt | date: 'dd/MM/yyyy' }}
    </div>
  </div>
}
