import { DialogRef } from '@angular/cdk/dialog';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { ApiFacadeService, LicenseStatusConst } from '@simlab/data-access';
import { DesignStrokedButton } from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { FormatDatePipe } from '@simlab/design/format-date';
import { UiTableModule } from '@simlab/ui/table';
import { Observable, firstValueFrom } from 'rxjs';
import { MyLicensesDataSource } from './my-licenses.data-source';

@Component({
  selector: 'simlab-my-licenses-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DesignDialogWrapperModule,
    DesignStrokedButton,
    UiTableModule,
    CdkTableModule,
    FormatDatePipe
  ],
  templateUrl: './my-licenses-dialog.component.html',
  styleUrls: ['./my-licenses-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyLicensesDialogComponent implements OnInit {
  private readonly _modalRef = inject(DialogRef);
  private readonly apiFacadeService = inject(ApiFacadeService);
  readonly myLicensesDataSource: MyLicensesDataSource =
    new MyLicensesDataSource(this.apiFacadeService);

  readonly loadingData$: Observable<boolean> =
    this.myLicensesDataSource.loading$;

  readonly LicenseStatus = LicenseStatusConst;

  readonly displayedColumns: string[] = [
    'column-0',
    'column-1',
    'column-2',
    'column-3',
    'column-4'
  ];

  ngOnInit(): void {
    firstValueFrom(this.myLicensesDataSource.getUserLicense());
  }

  cancel(): void {
    this._modalRef.close();
  }
}
