import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  DesignFlatButtonModule,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignIcon } from '@simlab/design/icon';
import { UiIconModule } from '@simlab/ui/icon';
import { ConfirmationModalRef } from '@simlab/ui/modal';

@Component({
  selector: 'feature-projects-view-only-dialog',
  templateUrl: './view-only-dialog.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DesignDialogWrapperModule,
    DesignFlatButtonModule,
    DesignStrokedButton,
    DesignIcon,
    UiIconModule
  ]
})
export class ViewOnlyDialogComponent {
      private readonly modalRef = inject<ConfirmationModalRef<unknown>>(ConfirmationModalRef<unknown>);

  confirmDialog(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }
}
