import { Dialog } from '@angular/cdk/dialog';
import {
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  input,
  OnInit,
  output,
  ViewContainerRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { MediaObserverService } from '@simlab/design/layout';
import { isNotDirectory } from '@simlab/documents/functions';
import {
  DocumentTypeEnumValues,
  FileCardStates,
  FolderStates,
  RowStates
} from '@simlab/documents/models';
import { filter, from, fromEvent, merge, switchMap } from 'rxjs';
import {
  DocumentsPreviewModalComponent,
  DocumentsPreviewModalData
} from '../documents-preview-modal/documents-preview-modal.component';

@Directive({
  selector: '[documentsCanOpen]',
  standalone: true
})
export class CanOpenDirective<
  T extends FileCardStates | FolderStates | RowStates
> implements OnInit
{
  private readonly _navigate = inject(Router);
  private readonly _dialog = inject(Dialog);
  private readonly _viewRef = inject(ViewContainerRef);
  private readonly _media = inject(MediaObserverService);
  private readonly _ref = inject(ElementRef);
  private readonly _destroyRef = inject(DestroyRef);

  readonly data = input.required<T>();
  readonly openSource = output<void>();

  ngOnInit(): void {
    merge(
      fromEvent(this._ref.nativeElement, 'click').pipe(
        switchMap(() => this._isTouchableScreen$)
      ),
      fromEvent(this._ref.nativeElement, 'dblclick').pipe(
        switchMap(() => this._isNotTouchableScreen$)
      )
    )
      .pipe(
        switchMap(() => this._open$()),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }

  private readonly _isTouchableScreen$ = this._media.isTouchableScreen$.pipe(
    filter((isTouchableScreen) => isTouchableScreen)
  );

  private readonly _isNotTouchableScreen$ = this._media.isTouchableScreen$.pipe(
    filter((isTouchableScreen) => !isTouchableScreen)
  );

  private _open$() {
    const data = this.data();

    if (isNotDirectory(data.data)) {
      return this._openFile(
        data.data.name,
        data.data.id,
        data.data.documentType
      );
    }

    return from(
      this._navigate.navigate([], {
        queryParams: { folderId: data.data.id },
        queryParamsHandling: 'merge'
      })
    );
  }

  private _openFile(
    fileName: string,
    fileId: string,
    fileType: DocumentTypeEnumValues
  ) {
    return this._dialog.open<
      void,
      DocumentsPreviewModalData,
      DocumentsPreviewModalComponent
    >(DocumentsPreviewModalComponent, {
      maxHeight: 'min(100%, 100svh)',
      maxWidth: 'min(100%, 100svw)',
      height: '100%',
      width: '100%',
      data: {
        name: fileName,
        type: fileType,
        fileId
      },
      viewContainerRef: this._viewRef
    }).closed;
  }
}
