/* eslint-disable @angular-eslint/no-host-metadata-property */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  InjectionToken,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewContainerRef, inject } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  defer,
  fromEvent,
  map,
  merge,
  takeUntil,
} from 'rxjs';
import { MentionOverlayComponent } from './mention-overlay.component';
export const MENTION_DATA = new InjectionToken<MentionData[]>('MENTION_DATA');
export const MENTION_DATA_FILTER = new InjectionToken<Observable<string>>(
  'MENTION_DATA_FILTER'
);
export const MENTION_KEYS = {
  AT: '@',
  ESCAPE: 'Escape',
  RETURN: 'Enter',
  SPACE: ' ',
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
};
export type AttributeData = MentionData;

export interface MentionData {
  value: string | number | boolean;
  displayName: string;
}

let index = 0;
@Directive({
  standalone: true,
  selector: 'div[designMention]',
  exportAs: 'designMention',
  host: {
    '(keyup)': 'keyupHandler()',
  },
})
export class MentionDirective implements OnInit, OnDestroy {
      private readonly _elementRef = inject(ElementRef);
      private readonly _renderer = inject(Renderer2);
      private readonly _viewContainerRef = inject(ViewContainerRef);
      private readonly _overlay = inject(Overlay);
      private readonly _cdr = inject(ChangeDetectorRef);
      private readonly _platform = inject(Platform);
  @Input() mentionData: MentionData[] = [];
  @Input() mentionTemplate: TemplateRef<unknown> | undefined | null;
  @Input() externalTagName!: string;
  @Input() set value(value: string) {
    this.contentElement.innerHTML = value || '';
    this.contentElement.innerHTML = this.convertUrlToTag(
      this.contentElement.innerHTML
    );
    this._parseToHTML();
  }
  get value() {
    return this.contentElement.innerHTML;
  }
  @Input() set placeholder(value: string) {
    this._placeholder = value;
  }
  get placeholder() {
    return this._placeholder ?? '';
  }
  protected _placeholder?: string;
  @Input() set readonly(isReadonly: BooleanInput) {
    this.contentElement.contentEditable = (!coerceBooleanProperty(
      isReadonly
    )).toString();
  }
  get readonly() {
    return !this.contentElement.isContentEditable;
  }
  @Output() contentChange: Observable<string> = defer(
    () => this._onContentChange$
  );
  @Output() enterPressed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
) {
    if (!this.contentElement.isContentEditable) {
      this.contentElement.contentEditable = 'true';
    }
  }
  private _overlayRef: OverlayRef = this._overlay.create();
  private _mentionPortal!: ComponentPortal<MentionOverlayComponent>;
  private readonly _filter: BehaviorSubject<string | undefined | null> =
    new BehaviorSubject<string | undefined | null>(undefined);
  private readonly _destroyOverlay: Subject<void> = new Subject<void>();
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private readonly _customEvent: Subject<void> = new Subject<void>();
  private _startRange: Range | undefined;
  private _temporaryUserTagRef: Element | null | undefined;
  private _prevKey: string | null | undefined;
  private _range: Range | undefined | null;

  ngOnInit(): void {
    this.contentElement.setAttribute('placeholder', this.placeholder);
    this._mentionPortal = new ComponentPortal(
      MentionOverlayComponent,
      null,
      Injector.create({
        providers: [
          { provide: MENTION_DATA, useValue: this.mentionData },
          {
            provide: MENTION_DATA_FILTER,
            useValue: this._filter.asObservable(),
          },
        ],
      })
    );

    this._contentEventObserver$();
  }

  convertUrlToString() {
    const urlReges = /<a\s+[^>]*href=["']([^"']+)["'][^>]*>(.*?)<\/a>/gi;
    const urlArray = [...this.contentElement.innerHTML.matchAll(urlReges)];
    const uniqueUrl = [...new Set(urlArray.flat(2))];

    for (let i = 0; i < uniqueUrl.length; i = i + 2) {
      this.contentElement.innerHTML = this.contentElement.innerHTML.replaceAll(
        uniqueUrl[i],
        uniqueUrl[i + 1]
      );
    }
  }

  convertUrlToTag(value: string) {
    const urlRegex =
      /(?<!<a\s*[^>]*?>)((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9$\-_\.\+\!\*\'\(\),;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9$\-_\.\+\!\*\'\(\),;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?!<a\s*[^>]*?>)[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?!<a\s*[^>]*?>)(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz]|biz|b[abdefghijmnorstvwyz]|cat|com|coop|c[acdfghiklmnoruvxyz]|d[ejkmoz]|edu|e[cegrstu]|f[ijkmor]|gov|g[abdefghilmnpqrstuwy]|h[kmnrtu]|info|int|i[delmnoqrst]|jobs|j[emop]|k[eghimnrwyz]|l[abcikrstuvy]|mil|mobi|museum|m[acdghklmnopqrstuvwxyz]|name|net|n[acefgilopruz]|org|om|pro|p[aefghklmnrstwy]|qa|r[eouw]|s[abcdeghijklmnortuvyz]|tel|travel|t[cdfghjklmnoprtvwz]|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\),_])|(?:\%[a-fA-F0-9]{2})|\/)*)?(?:\b|$)/gi;
    const regUrlArray = value.match(urlRegex);
    if (regUrlArray) {
      const uniqueUrl = [...new Set(regUrlArray.flat(1))];
      uniqueUrl.forEach((url: string) => {
        const regForUrl = this._prepareRegexToDetectCurrent(url);
        const replacement = `<a class="comment-url" href="${this._checkPrefix(
          url
        )}" target="_blank">${url}</a>`;
        value = value.replace(new RegExp(regForUrl, 'g'), replacement);
      });
    }
    return value;
  }

  private _checkPrefix(url: string): string {
    return url.startsWith('http://') || url.startsWith('https://')
      ? url
      : 'http://' + url;
  }

  private _prepareRegexToDetectCurrent(value: string) {
    return (
      '\\b' +
      value
        .replace(/\//g, '\\/')
        .replaceAll('?', '\\?')
        .replaceAll('.', '\\.') +
      '(?=[^w/]|$)'
    );
  }
  private _contentEventObserver$() {
    if (this.isAndroid) {
      fromEvent<InputEvent>(this.contentElement, 'beforeinput')
        .pipe(debounceTime(100), takeUntil(this._destroySource))
        .subscribe((event: InputEvent) => {
          const { inputType } = event;
          const key =
            inputType === 'deleteContentBackward'
              ? MENTION_KEYS.ESCAPE
              : event.data && event.data.length === 1
              ? event.data
              : undefined;
          if (key) {
            event.preventDefault();
            const keyboardEvent = new KeyboardEvent('keydown', {
              key,
            });

            this.keydownHandler(keyboardEvent);
          }
        });
    } else {
      fromEvent<KeyboardEvent>(this.contentElement, 'keydown')
        .pipe(takeUntil(this._destroySource))
        .subscribe((event: KeyboardEvent) => this.keydownHandler(event));
    }
  }
  private get _onContentChange$(): Observable<string> {
    return merge(
      fromEvent<InputEvent>(this.contentElement, 'input'),
      this._customEvent.asObservable()
    ).pipe(
      takeUntil(this._destroySource),
      map(() => this.contentElement.innerHTML)
    );
  }
  ngOnDestroy(): void {
    this._closeOverlay();
    this._destroySource.next();
    this.mentionTemplate = null;
    this._overlayRef?.dispose();
  }

  private _closeOverlay(): void {
    this._destroyOverlay.next();
    this._overlayRef.detach();
  }

  get contentElement(): HTMLDivElement {
    return this._elementRef.nativeElement as HTMLDivElement;
  }

  get contentValue(): string {
    this._closeOverlay();
    return this.contentElement.innerHTML;
  }

  emitNewValue() {
    this._customEvent.next();
  }
  private get range(): Range | undefined {
    const isSupported = typeof window.getSelection !== 'undefined';
    if (isSupported) {
      const selection = window.getSelection();
      if (selection && selection.rangeCount !== 0) {
        const range = selection.getRangeAt(0);
        return range;
      }
    }
    return undefined;
  }
  private _overlayEmitObserver(
    componentRef: ComponentRef<MentionOverlayComponent>
  ): void {
    componentRef.instance.close$
      .pipe(takeUntil(this._destroyOverlay))
      .subscribe((value: string | number | boolean) => {
        if (this.mentionTemplate) {
          const selected = this.mentionData.find(
            (mention: MentionData) => mention.value === value
          );
          if (!selected) return;
          if (this._isTemporaryTag) {
            const html = this._getHTMLNode(this.mentionTemplate, selected);
            this._cdr.markForCheck();
            if (!html) return;
            this._replaceTemporaryUserTag(html);
          } else if (this._isUserTag) {
            if (!this._editedTag) return;
            this._updateUserTag(this._editedTag, selected);
            if (this._editedTag.parentElement) {
              this._editedTag.parentElement.appendChild(this._nbsp);
              this._setEndOfNode(this._editedTag.parentElement);
            }
          } else {
            console.log(this._range?.startContainer.parentElement?.nodeName);
          }
        }
        this._removeTemporaryUserTag();

        this._closeOverlay();
        this._customEvent.next();
      });
  }
  private get isAndroid(): boolean {
    return this._platform.ANDROID;
  }

  private get _isTemporaryTag(): boolean {
    return (
      this._range?.startContainer.nodeName === 'SPAN' ||
      (this._range?.startContainer instanceof Text &&
        this._range?.startContainer.parentElement?.nodeName === 'SPAN')
    );
  }

  private get _editedTag(): Element | null {
    return this._range?.startContainer instanceof Text
      ? this._range?.startContainer.parentElement
      : (this._range?.startContainer as Element);
  }
  private get _isUserTag(): boolean {
    return (
      this._range?.startContainer.nodeName ===
        this.externalTagName.toUpperCase() ||
      (this._range?.startContainer instanceof Text &&
        this._range?.startContainer.parentElement?.nodeName ===
          this.externalTagName.toUpperCase())
    );
  }
  private _updateUserTag(userTag: Element, selected: MentionData): Element {
    userTag.textContent = `@${
      selected.displayName || selected.value.toString()
    }`;
    userTag.setAttribute('value', selected.value.toString());

    this._cdr.markForCheck();
    return userTag;
  }

  private _replaceTemporaryUserTag(html: Element): void {
    this._insertBeforeTag(html, this._temporaryUserTagRef);
    this._insertBeforeTag(this._nbsp, this._temporaryUserTagRef);
    this._removeTemporaryUserTag();
    this._cloneSelection();
    this._temporaryUserTagRef = null;
  }

  private get _nbsp(): Element {
    return this._renderer.createText('\u00A0');
  }

  private _getTextElement(text: string): Element {
    return this._renderer.createText(text);
  }

  private _insertBeforeTag(
    node: Element,
    tag: Element | null | undefined
  ): void {
    if (tag) {
      const parent = tag.parentElement;
      if (!parent) return;
      parent.insertBefore(node, tag);
      this._setEndOfNode(parent);
    }
  }

  private _parseToHTML(): void {
    if (this.mentionTemplate) {
      const userLinkSelector = this.contentElement.querySelectorAll(
        this.externalTagName
      );
      userLinkSelector.forEach((userLinkTag) => {
        const value = userLinkTag.getAttribute('data-user-info');
        const displayName = `${userLinkTag.getAttribute('data-user-name')}`;
        if (!value || !displayName) return;
        const node = this._getHTMLNode(
          this.mentionTemplate as TemplateRef<unknown>,
          {
            displayName,
            value,
          }
        );
        this._insertBeforeTag(node, userLinkTag);
        this._insertBeforeTag(this._nbsp, userLinkTag);
        this._setEndOfNode(this.contentElement);
        userLinkTag.remove();
      });
    }
  }
  private _getHTMLNode(
    template: TemplateRef<unknown>,
    selected: MentionData
  ): Element {
    const context = this._viewContainerRef.createEmbeddedView(template, {
      value: selected.value,
      name: `@${selected.displayName}`,
    });
    const [element] = context.rootNodes as Element[];
    return this._updateUserTag(element, selected);
  }
  private _overlayKeyboardEventsObserver(
    mentionOverlayComponentRef: ComponentRef<MentionOverlayComponent>,
    tagNode: Element
  ): void {
    const instance = mentionOverlayComponentRef.instance;
    this._overlayRef
      .keydownEvents()
      .pipe(takeUntil(this._destroyOverlay))
      .subscribe((event: KeyboardEvent) => {
        setTimeout(() => {
          const text = tagNode.textContent;
          if (this._filter.getValue() !== text) this._filter.next(text);
        }, 0);
        switch (event.key) {
          case MENTION_KEYS.RETURN: {
            event.preventDefault();
            instance.emitSelected();
            break;
          }
          case MENTION_KEYS.DOWN: {
            event.preventDefault();
            instance.selectNext();
            break;
          }
          case MENTION_KEYS.UP: {
            event.preventDefault();
            instance.selectPrevious();
            break;
          }
        }
      });
  }
  keyupHandler(): void {
    if (
      this.range?.startContainer === this.contentElement ||
      (this.range?.endContainer instanceof Text &&
        this.range?.endContainer.parentElement === this.contentElement)
    ) {
      this._closeOverlay();
    }
  }
  keydownHandler(event: KeyboardEvent): void {
    if ((this.range?.startContainer as Element).innerHTML === '<br>') {
      const element = this.range?.startContainer as Element;
      element.innerHTML = '';
      this._setEndOfNode(element);
    }

    if (
      this.range?.startContainer.parentElement?.nodeName ===
      this.externalTagName.toUpperCase()
    ) {
      const userTag = this.range?.startContainer.parentElement;
      setTimeout(() => {
        const mentionComponentRef = this._attachOverlay();
        if (!mentionComponentRef) return;
        const text = userTag.innerText;

        this._filter.next(text);
        this._overlayKeyboardEventsObserver(mentionComponentRef, userTag);
        this._overlayEmitObserver(mentionComponentRef);
        this._setOverlayPosition(userTag);
        this._range = this.range?.cloneRange();
      }, 0);
    }

    switch (event.key) {
      case MENTION_KEYS.AT: {
        this._startRange = this.range?.cloneRange();
        const mentionComponentRef = this._attachOverlay();
        if (!mentionComponentRef) return;
        event.preventDefault();
        this._filter.next(undefined);
        index += 1;
        const tagNode = this._createNode('span', event.key, ['user-mention'], {
          displayName: 'id',
          value: `userTemporary-${index}`,
        });

        this._overlayKeyboardEventsObserver(mentionComponentRef, tagNode);
        this._overlayEmitObserver(mentionComponentRef);
        this._insertNode(tagNode);
        this._temporaryUserTagRef = tagNode;
        this._setOverlayPosition(tagNode);
        this._range = this.range?.cloneRange();
        if (this.isAndroid) {
          const range = this._range?.cloneRange();
          if (!range) return;
          range.endContainer.previousSibling &&
            range.endContainer.previousSibling.nodeValue?.length &&
            (range.endContainer.previousSibling.nodeValue =
              range.endContainer.previousSibling.nodeValue?.substring(
                0,
                range.endContainer.previousSibling.nodeValue.length - 1
              ));
        }
        break;
      }
      case MENTION_KEYS.SPACE: {
        if (this._prevKey === MENTION_KEYS.AT && this._overlayAttached) {
          this._cancelMentioning();
        }
        break;
      }
      case MENTION_KEYS.ESCAPE: {
        event.preventDefault();
        this._cancelMentioning();
        break;
      }
      case MENTION_KEYS.RETURN: {
        if (!this._overlayRef.hasAttached() && !event.shiftKey) {
          this.enterPressed.emit();
        }
        break;
      }
      default: {
        // console.log(event);
      }
    }
    this._prevKey = event.key;
  }
  private _cancelMentioning(): void {
    this._closeOverlay();
    if (this._temporaryUserTagRef?.textContent) {
      const text = this._getTextElement(this._temporaryUserTagRef.textContent);
      this._insertBeforeTag(text, this._temporaryUserTagRef);
    }
    this._removeTemporaryUserTag();
  }
  private _setOverlayPosition(tagNode: Element): void {
    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(tagNode)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);
    this._overlayRef.updatePositionStrategy(positionStrategy);
  }
  private _attachOverlay(): ComponentRef<MentionOverlayComponent> | undefined {
    if (this._overlayAttached) return;
    return this._overlayRef.attach(this._mentionPortal);
  }
  private get _overlayAttached(): boolean {
    return this._overlayRef.hasAttached();
  }

  private _insertNode(node: Element): void {
    if (this._startRange) {
      const idx = this._startRange.startOffset;
      if (this._startRange.startContainer.nodeName === 'DIV') {
        this._startRange.startContainer.appendChild(node);
      } else if (this._startRange.startContainer instanceof Text) {
        this._startRange.startContainer.parentNode?.insertBefore(
          node,
          (this._startRange.startContainer as Text).splitText(idx)
        );
      } else {
        console.log(this._startRange);
      }
    }
    this._setEndOfNode(node);
  }
  private _removeTemporaryUserTag(): void {
    if (this._temporaryUserTagRef) this._temporaryUserTagRef.remove();
    const tempUser: HTMLSpanElement | null = this.contentElement.querySelector(
      `#userTemporary-${index}`
    );
    if (!tempUser) return;
    tempUser.remove();
  }

  private _cloneSelection(): void {
    const selection = window.getSelection(); //get the selection object (allows you to change selection)
    if (!selection) return;

    selection.removeAllRanges(); //remove any selections already made
    if (this._range) selection.addRange(this._range); //make the range you have just created the visible selection
  }

  private _setEndOfNode(contentEditableElement: Element): void {
    let range: Range;
    let selection: Selection | null;
    if (document.createRange) {
      //Firefox, Chrome, Opera, Safari, IE 9+
      range = document.createRange(); //Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
      range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
      selection = window.getSelection(); //get the selection object (allows you to change selection)
      if (!selection) return;

      selection.removeAllRanges(); //remove any selections already made
      selection.addRange(range); //make the range you have just created the visible selection
    }
  }
  private _createNode(
    tagName: string,
    content: string,
    customClasses: string[],
    ...attr: AttributeData[]
  ): Element {
    const element: Element = this._renderer.createElement(tagName);
    element.textContent = content;
    customClasses.forEach((className) => {
      element.classList.add(className);
    });
    attr.forEach((attrRow: AttributeData) => {
      element.setAttribute(
        attrRow.displayName || attrRow.value.toString(),
        attrRow.value.toString()
      );
    });
    return element;
  }
}
