<measurement-base
  [stageId]="stageId()"
  [noteId]="noteId()"
  [notes]="notes()"
  [opened]="opened()"
  [cancel]="cancel.asObservable()"
  (onClose)="onClose.emit($event)"
  (onCloseWithType)="onCloseWithType.emit('measurement-distance')"
  (onGoTo)="goToMeasurement($event)"
  (onAdd)="addMeasurement()"
  (openPanelAction$)="openPanelAction()"
  (onCloseAction)="onCloseAction($event)"
  [editData]="editData"
  (onEdit)="editMeasurement()"
  [successCreateNote$]="successCreateNote$()"
  [systemOfMeasurement]="systemOfMeasurement()"
  (onOpenEditor)="_openEditor.set($event)"
  (onCreateNote)="onCreateNote.emit($event)"
  (onOpenRelatedNote)="onOpenRelatedNote.emit($event)"
>
  Distance Measurement
</measurement-base>
