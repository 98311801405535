import { Injectable } from '@angular/core';

import { ProjectsFacade } from '@simlab/data-store';
import { iif, Observable, of, switchMap, tap } from 'rxjs';

@Injectable()
export class StageProjectResolver  {
  constructor(private readonly projectFacade: ProjectsFacade) {}

  resolve(): Observable<boolean> {
    return this.projectFacade.loaded$.pipe(
      switchMap((loaded: boolean) =>
        iif(
          () => loaded,
          of(true),
          of(true).pipe(tap(() => this.projectFacade.initStore({})))
        )
      )
    );
  }
}
