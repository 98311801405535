import { DateRange, ProjectsFilterBase } from '@simlab/data-access';
import { FiltersService } from '../services/filters/filters.service';

/**
 * @description
 * Implements interface ProjectFilter {@link ProjectsFilter}
 * which is used in store and for project request
 */
export class ProjectsFilter implements ProjectsFilterBase {
  constructor(
    public sortByFavourites: boolean = false,
    public owner: boolean = false,
    public archived: boolean = true,
    public organizationsId: string[] = [],
    public projectAuthors: string[] = [],
    public projectName: string = '',
    public modified: DateRange = { from: '', to: '' },
    // public created: DateRange = { from: '', to: '' },
    public createdFrom: string = '',
    public createdTo: string = '',
  ) {}
}

export function filterProjectServiceFactory(): FiltersService<ProjectsFilterBase> {
  return new FiltersService<ProjectsFilterBase>();
}
