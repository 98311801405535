import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule
} from '@angular/core';
import { FormatDatePipe } from '@simlab/design/format-date';

export interface ModelListItem {
  id: string;
  name: string;
  created: string;
  address?: string;
  image?: string;
}

@Component({
  selector: 'feature-stages-model-list-item',
  templateUrl: './model-list-item.component.html',
  styleUrls: ['./model-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelListItemComponent {
  @Input() item!: ModelListItem;
}

@NgModule({
  declarations: [ModelListItemComponent],
  exports: [ModelListItemComponent],
  imports: [CommonModule, FormatDatePipe]
})
export class ModelListItemModule {}
