<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <i ui-icon name="icon_no_access" size="12x"></i>
  </ng-container>

  <ng-container designDialogContent>
    This project is archived and is only available for viewing. You can activate
    the project in the organization panel.
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-flat-button
      designButtonColor="primary"
      (click)="confirmDialog()"
    >
      I get it
    </button>
  </ng-container>
</design-dialog-wrapper>
