import {
  Directive,
  InjectionToken,
  Input,
  Output,
  signal
} from '@angular/core';

import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TAreaMeasurement } from '@simlab/feature-measurement/models';
import { MeasurementComponent } from '@simlab/matterport/lib/services/measurement-tool.service';
import {
  IMeasurementTool,
  TAreaMesh
} from '@simlab/simlab-facility-management/sub-features/measurement';
import {
  defer,
  filter,
  firstValueFrom,
  fromEvent,
  merge,
  of,
  switchMap,
  tap
} from 'rxjs';
import { MatterportBaseMeasurementDirective } from './matterport-base-measurement';
export const AreaMeasurementToken = new InjectionToken<
  IMeasurementTool<MeasurementComponent, { id: string; color?: string }>
>('Measurement controller');
const SHOW_MEASUREMENT_KEY = 'V';
@Directive({
  selector: '[simlabMatterportAreaMeasurement]',
  exportAs: 'simlabMatterportAreaMeasurement',
  standalone: true,
  providers: [
    {
      provide: AreaMeasurementToken,
      useExisting: MatterportAreaMeasurementDirective
    }
  ]
})
export class MatterportAreaMeasurementDirective extends MatterportBaseMeasurementDirective<
  MeasurementComponent,
  TAreaMesh
> {
  private _drawAreaOnMatterportOpen$ = defer(() =>
    this.matterportHost.matterportIsOpen$.pipe(
      filter((isOpen) => isOpen),
      tap(() => {
        this.mesh.deleteAllMeasurements();
      }),
      tap(() => {
        const areaMeasurements = this._areaMeasurement();
        areaMeasurements.forEach(({ id, color, data }, index) => {
          this.addMeasurement({
            id,
            surfaceSize: data.surfaceSize,
            triangles: data.triangles,
            vertices: data.vertices,
            color
          });
        });
      })
    )
  );

  constructor() {
    super();
    this._drawAreaOnMatterportOpen$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  readonly matterportIsOpen = toSignal(this.matterportHost.matterportIsOpen$);

  @Input() override set segmentsVisibility(visible: boolean) {
    this.ngZone.runTask(() => {
      const isOpen = this.matterportIsOpen();
      if (isOpen) this.mesh.segmentsVisibility = visible;
    });
  }

  @Output() showMeasurementsSegments = defer(() =>
    this.ngZone.runTask(() =>
      this.matterportHost.matterportIsOpen$.pipe(
        filter((isOpen) => isOpen),
        switchMap(() =>
          merge(
            this.matterportHost.matterportManagerService.events.fromMatterportEvent<KeyboardEvent>(
              'keyup'
            ),
            fromEvent<KeyboardEvent>(window, 'keyup')
          ).pipe(
            filter(
              (event: KeyboardEvent) =>
                event.key.toUpperCase() === SHOW_MEASUREMENT_KEY
            ),
            switchMap((key) => this.ngZone.run(() => of(key)))
          )
        )
      )
    )
  );

  get mesh(): IMeasurementTool<MeasurementComponent, TAreaMesh> {
    return this.matterportHost.matterportManagerService.areaMesh;
  }
  private readonly _areaMeasurement = signal<TAreaMeasurement[]>([]);
  private readonly _matterportOpened = toSignal(
    this.matterportHost.matterportIsOpen$
  );
  public get simlabMatterportAreaMeasurement() {
    return this._areaMeasurement();
  }
  @Input() set simlabMatterportAreaMeasurement(value: TAreaMeasurement[]) {
    this._areaMeasurement.set(value);
  }

  createAreaPromise = (): Promise<string> => {
    return firstValueFrom(
      this.matterportHost.matterportIsOpen$.pipe(filter((e) => e))
    ).then(() => this.mesh.createMeasurement());
  };
}
