<ng-container
  *ngFor="let photo of photos$ | async; trackBy: trackByPhotoUrl; let i = index"
>
  <feature-stages-note-media-photo-item
    [media]="photo"
    (imageLoadedEmitter)="loadedImage($event)"
    (click)="openDialog(i, photo.name, photo.id)"
  >
  </feature-stages-note-media-photo-item>
</ng-container>
