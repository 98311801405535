<div class="main-content">
  <div class="container">
    @if (status() === 'WORKING') {
      <design-progress-spinner
        class="spinner"
        designColor="accent"
        [diameter]="20"
        [strokeWidth]="5"
      />
    }

    <h4 class="title" [title]="title()">{{ title() }}</h4>
  </div>
</div>

@if (status() === 'COMPLETED_WITH_FAILURES') {
  <div class="fail-container">
    <i class="icon-procore" ui-icon name="error" size="img" defaultColor></i>
    <div class="fail-container__text" i18n="@@FAILED">Failed</div>
  </div>
}
