/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  NgModule,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { ColorDirective } from '@simlab/design/helper';
import { DirectionDirective } from '../directives/direction.directive';
import { FireOnClickDirective } from '../directives/fire-on-click.directive';
import { FireOnSetDirective } from '../directives/fire-on-set.directive';
import { ButtonBase } from './button-base.directive';
import { ButtonLoaderComponent } from './button-loader.component';

@Component({
  selector: 'button[design-button], a[design-button]',
  exportAs: 'designButton',
  standalone: true,
  imports: [CommonModule],
  template: ` <ng-content select="[designIconPrefix]"></ng-content>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <ng-content select="[designIconSuffix]"></ng-content>`,
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: ButtonBase, useExisting: DesignButton }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'design-base-button design-button',
  },
  inputs: [
    'disabled',
    'disabled: designButtonDisabled',
    'loaded: designButtonLoaded',
    'color: designButtonColor',
  ],
})
export class DesignButton extends ButtonBase {
  private readonly elementRef = inject(ElementRef);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  @HostBinding('class.design-button--suffix')
  get hasIconSuffix(): boolean {
    return this._hasIconSuffix;
  }

  @HostBinding('class.design-button--prefix')
  get hasIconPrefix(): boolean {
    return this._hasIconPrefix;
  }
}

@NgModule({
  exports: [
    DesignButton,
    FireOnClickDirective,
    FireOnSetDirective,
    ButtonLoaderComponent,
    ColorDirective,
  ],
  imports: [
    DesignButton,
    ColorDirective,
    FireOnClickDirective,
    FireOnSetDirective,
    DirectionDirective,
    ButtonLoaderComponent,
  ],
})
export class DesignButtonModule {}
