<ng-container *ngIf="selectedStage$ | async; let selectedStage; else: noStages">
  <div [formGroup]="formGroup">
    <div class="stage-name">
      <input
        #stageName
        (focus)="stageName.select()"
        formControlName="name"
        (focusout)="stopEditing()"
        class="title-input"
      />
      <div class="btn-flex-end">
        <design-tooltip
          i18n-tooltip="@@STAGE_MENU"
          tooltip="stage menu"
          position="left"
        >
          <button
            design-icon-button
            data-cy="stage-options-button"
            [designMenuTriggerFor]="menu"
            *ngIf="projectIsActive$ | async"
          >
            <i designIcon="more_second" designSize="1.5rem"></i>
          </button>
        </design-tooltip>
        <design-menu-panel #menu>
          <button
            design-menu-item
            (click)="edit()"
            data-cy="stage-edit-button"
            [disabled]="((addEditDeleteStages$ | async) ?? true) === false"
            i18n="@@EDIT"
          >
            Edit
          </button>
          <button
            design-menu-item
            (click)="remove()"
            data-cy="stage-remove-button"
            [disabled]="((addEditDeleteStages$ | async) ?? true) === false"
          >
            <ng-container i18n="@@REMOVE">Remove</ng-container>
          </button>
        </design-menu-panel>
      </div>
    </div>
    <div class="stage-info-container">
      <div class="info">
        <i class="info__icon" ui-font fontName="users" fontSize="1.3rem"></i>
        <div class="info--inline">
          <h3 i18n="@@AUTHOR">Author</h3>
          <span>: </span>
        </div>
        <span>
          {{ selectedStage?.creatorName }}
        </span>
      </div>
      <div class="info">
        <i class="info__icon" ui-font fontName="time" fontSize="1.3rem"></i>
        <div class="info--inline">
          <h3 i18n="@@MODIFICATION_DATE">Last modified</h3>
          <span>: </span>
        </div>
        <span>
          {{
            selectedStage?.modifiedAt ?? selectedStage?.createdAt
              | formatDate
              | async
          }}
        </span>
      </div>
      <div class="info">
        <i class="info__icon" ui-font fontName="calendar" fontSize="1.3rem"></i>
        <div class="info--inline">
          <h3 i18n="@@STAGE_CREATION_DATE">Creation date</h3>
          <span>: </span>
        </div>
        <span>
          {{ selectedStage.createdAt | formatDate | async }}
        </span>
      </div>
    </div>

    <div class="row">
      <ui-form-field class="flex-1">
        <ui-textarea
          formControlName="description"
          i18n-tooltipText="@@EDIT_STAGE_DESCRIPTION"
          tooltipText="edit stage description"
          (saveChange)="saveChanges($event)"
        ></ui-textarea>
      </ui-form-field>
    </div>

    @if (selectedStages(); as selectedStages) {
      <feature-stages-annotation-item-info
        class="annotation-item-info"
        [selectedStage]="selectedStages"
      />
    }
  </div>
</ng-container>

<ng-template #noStages>
  <div class="wrapper-no-stages">
    <ui-image-info
      data-cy="stage-info-image-info"
      imageSrc="icon_no_stages"
      i18n-header="@@NO_STAGES_HEADER"
      header="No Stages"
      [descriptions]="descriptionImageInfoNoStages"
      [maxWidth]="100"
    ></ui-image-info>
  </div>
</ng-template>
