<design-tooltip
  designTooltipOnClick
  closeEventBehavior="AFTER_CLICK_OUTSIDE_A_CONTENT"
  [tooltip]="view"
  class="breadcrumb-tooltip"
  customClass="custom-breadcrumb-tooltip"
  position="left"
>
  <ng-content></ng-content>
</design-tooltip>

<ng-template #view>
  <design-breadcrumb [path]="path()" [showIcon]="true" class="no-wrap" />
</ng-template>
