import {
  Directive,
  HostListener,
  inject,
  input,
  OnChanges,
  OnInit,
  output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { Pointer } from '@simlab/matterport';

@Directive({
  selector: '[simlabIframeTracker]',
  standalone: true
})
export class IframeTrackerDirective implements OnInit, OnChanges {
  private readonly _renderer = inject(Renderer2);

  readonly iframeClickerDetected = input.required<Pointer | undefined>();
  readonly iframeMatterportClickUp = input.required<Pointer | undefined>();
  private _iframeMouseOver = false;

  readonly iframeClick = output<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['iframeMatterportClickUp']) {
      this._iframeMouseOver = false;
    }
    if (changes['iframeClickerDetected']) {
      window.focus();
      this.iframeClick.emit();
      this._iframeMouseOver = true;
    }
  }

  ngOnInit(): void {
    this._renderer.listen(window, 'blur', () => this.onWindowBlur());
  }

  matterportTest() {
    this._iframeMouseOver = false;
  }

  @HostListener('mouseover')
  private onIframeMouseOver() {
    this._iframeMouseOver = true;
    window.focus();
  }

  @HostListener('mouseout')
  private onIframeMouseOut() {
    this._iframeMouseOver = false;
  }

  private onWindowBlur() {
    if (this._iframeMouseOver) {
      this.iframeClick.emit();
    }
  }
}
