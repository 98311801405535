/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  inject
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiFacadeService, StageComponent } from '@simlab/data-access';
import { ComponentsFacade } from '@simlab/data-store';

import { Vector3 } from 'three';

import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet
} from '@angular/common';
import { MatterportAnnotationControlService } from '@simlab/annotation/data-access';
import { TagPlacementComponent } from '@simlab/annotation/ui';
import { StageWithCount, StagesFacade } from '@simlab/data-store';
import { DesignFlatButtonModule } from '@simlab/design/button';
import {
  MATTERPORT_TOKEN,
  MatterportBaseService,
  MatterportComponent as MatterportComp,
  MatterportOauthService,
  injectMatterport
} from '@simlab/feature/matterport';
import { SynchronizeConfirmationDialogComponent } from '@simlab/feature/stages';
import {
  MatterportComponent,
  MatterportManagerService,
  MatterportService,
  StartPlacingConfig,
  TagNote,
  TagNoteTypes
} from '@simlab/matterport';
import { TransformConverter } from '@simlab/transform';
import { UiInputModule } from '@simlab/ui/input';
import {
  ConfirmationModalRef,
  DialogResponse,
  ModalService
} from '@simlab/ui/modal';
import { PanelRightService } from 'libs/feature/stages/src/lib/services/panel-right.service';
import { StagesRootService } from 'libs/feature/stages/src/lib/services/stages-root.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  firstValueFrom,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  race,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError
} from 'rxjs';

type SyncComponent =
  | (StageComponent & { stageName: string })
  | { stageName: string; name: string; id: string };

enum SyncPoint1 {
  'A1' = 'A1',
  'B1' = 'B1',
  'C1' = 'C1'
}

enum SyncPoint2 {
  'A2' = 'A2',
  'B2' = 'B2',
  'C2' = 'C2'
}

type SyncPoint = SyncPoint1 | SyncPoint2;

type SyncPointType = `${SyncPoint}`;

const FIRST_STEP_HINT =
  `<p>` +
  $localize`:@@SET_MARKERS_POINTS:Set markers in the extreme points of the component` +
  `</p>`;
const MAX_SCREENSHOT_SIZE = { h: 600, w: 800 };

@Component({
  standalone: true,
  templateUrl: './synchronization.component.html',
  styleUrls: ['./synchronization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgFor,
    NgIf,
    NgClass,
    MatterportComp,
    NgSwitch,
    NgSwitchCase,
    DesignFlatButtonModule,
    NgTemplateOutlet,
    UiInputModule,
    TagPlacementComponent
  ],
  providers: [
    MatterportService,
    MatterportBaseService,
    MatterportManagerService,
    MatterportOauthService,
    {
      provide: MATTERPORT_TOKEN,
      useFactory: injectMatterport,
      deps: [MatterportManagerService]
    }
  ]
})
export class SynchronizationComponent implements OnDestroy {
  private readonly _matterportAnnotationControl = inject(
    MatterportAnnotationControlService
  );
  private readonly _cancelPlacement$: Subject<void> = new Subject<void>();
  private readonly _destroySource$: Subject<void> = new Subject<void>();

  private readonly _currentStep: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);

  private _smallScreen!: boolean;
  private _selectedComponent!: StageComponent;
  private _componentToSynchronize!: StageComponent;

  readonly allPointsSelected: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly allSyncPointsSelected: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  readonly localizationPanelOpen$: Observable<boolean> =
    this._matterportAnnotationControl.isAnnotationMarkerInActiveMode$;

  readonly componentLoaded$: Observable<boolean> = combineLatest([
    this.matterportBaseService.hasMatterport$,
    this.matterportBaseService.hasSpinner$
  ]).pipe(
    map(([hasMatterport, spinner]: [boolean, boolean]) => {
      return hasMatterport && !spinner;
    })
  );

  readonly allSynchronizedComponents$: Observable<SyncComponent[]> =
    this._getSynchronizedComponentsForStages$();

  readonly currentStep$: Observable<number> = this._currentStep.pipe(
    tap((step: number) => {
      if (step === 1) {
        this.matterportManagerService.setHint(
          FIRST_STEP_HINT,
          {
            top: '10px'
          },
          'var(--ui-theme-surface-primary)',
          'var(--ui-theme-text-primary)'
        );
      } else if (step === 3) {
        this.rootService.loadSelectedScan(this._selectedComponent);
        this.matterportManagerService.setHint(
          FIRST_STEP_HINT,
          {
            top: '10px'
          },
          'var(--ui-theme-surface-primary)',
          'var(--ui-theme-text-primary)'
        );
      } else {
        this.matterportManagerService.setHint(
          undefined,
          { top: '10px' },
          'var(--ui-theme-surface-primary)',
          'var(--ui-theme-text-primary)'
        );
      }
    })
  );

  readonly pointsPositions: {
    [pointLabel: string]: Vector3 | undefined;
  } = {
      A1: undefined,
      B1: undefined,
      C1: undefined,
      A2: undefined,
      B2: undefined,
      C2: undefined
    };
  readonly screenshot: {
    [pointLabel: string]:
    | {
      screenshot: string | undefined;
      clip: {
        top: number;
        left: number;
      };
    }
    | undefined;
  } = {
      A1: undefined,
      B1: undefined,
      C1: undefined
    };

  doubleSize = false;
  selectedPoint: SyncPointType | undefined;
  currentScreenshot: `${SyncPoint1}` | undefined;

  get selectedComponent(): string {
    return this._selectedComponent?.id ?? '';
  }
  get targetCompName(): string {
    return this._componentToSynchronize?.name ?? '';
  }

  get referenceCompName(): string {
    return this._selectedComponent?.name ?? '';
  }

  constructor(
    private readonly router: Router,
    private readonly ngZone: NgZone,
    private readonly stagesFacade: StagesFacade,
    private readonly modalService: ModalService,
    private readonly components: ComponentsFacade,
    private readonly rootService: StagesRootService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly panelService: PanelRightService,
    private readonly apiFacadeService: ApiFacadeService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly matterportManagerService: MatterportService,
    private readonly matterportBaseService: MatterportBaseService
  ) {
    this.matterportBaseService.hideOverlayElements = true;

    this.rootService.mobileScreenSize$
      .pipe(
        tap((isMobile: boolean) => (this._smallScreen = isMobile)),
        takeUntil(this._destroySource$)
      )
      .subscribe();

    firstValueFrom(
      this.components.selectedComponent$.pipe(
        tap((components: StageComponent | undefined) => {
          if (components) {
            this._componentToSynchronize = components;
          }
        })
      )
    );
  }
  ngOnDestroy(): void {
    this._destroySource$.next();
    this._destroySource$.complete();
  }

  touchScreen = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  openSynchronizeConfirmationDialog(): void {
    const dialogRef = this._createSynchronizeConfirmationDialog<unknown>();

    firstValueFrom(
      dialogRef.events$.pipe(
        tap((response: DialogResponse<unknown>) => {
          if (response.state) {
            this._close();
          }
        })
      )
    );
  }
  nextStep(): void {
    this._currentStep.next(this._currentStep.getValue() + 1);
  }
  previousStep(): void {
    this._currentStep.next(this._currentStep.getValue() - 1);
  }
  acceptSync(): void {
    this.components.synchronizeComponent({
      targetComponent: this._componentToSynchronize.id,
      targetStage: this._componentToSynchronize.stageId,
      referenceComponent: this._selectedComponent.id,
      referenceStage: this._selectedComponent.stageId,
      targetPoint1: this.pointsPositions[SyncPoint1.A1]!,
      targetPoint2: this.pointsPositions[SyncPoint1.B1]!,
      targetPoint3: this.pointsPositions[SyncPoint1.C1]!,
      referencePoint1: this.pointsPositions[SyncPoint2.A2]!,
      referencePoint2: this.pointsPositions[SyncPoint2.B2]!,
      referencePoint3: this.pointsPositions[SyncPoint2.C2]!
    });
    firstValueFrom(
      this.components.acceptSynchronizationSuccess$.pipe(
        tap((component: StageComponent) => {
          this._componentToSynchronize = component;
          this._close();
        })
      )
    );
  }
  selectComponent(comp: SyncComponent): void {
    if ('id' in comp) {
      this._selectedComponent = comp as StageComponent;
    }
  }

  private _setActivePoint(pointLabel: SyncPointType | undefined): void {
    switch (pointLabel) {
      case SyncPoint2.A2: {
        this.currentScreenshot = SyncPoint1.A1;
        this.selectedPoint = pointLabel;
        break;
      }
      case SyncPoint2.B2: {
        this.currentScreenshot = SyncPoint1.B1;
        this.selectedPoint = pointLabel;
        break;
      }
      case SyncPoint2.C2: {
        this.currentScreenshot = SyncPoint1.C1;
        this.selectedPoint = pointLabel;
        break;
      }
      case SyncPoint1.A1: {
        this.currentScreenshot = undefined;
        this.selectedPoint = pointLabel;
        break;
      }
      case SyncPoint1.B1: {
        this.currentScreenshot = undefined;
        this.selectedPoint = pointLabel;
        break;
      }
      case SyncPoint1.C1: {
        this.currentScreenshot = undefined;
        this.selectedPoint = pointLabel;
        break;
      }
      default: {
        this.currentScreenshot = undefined;
        this.selectedPoint = undefined;
      }
    }

    this._cropImage();
    this.doubleSize = false;
    const formValue = this.pointsPositions;
    this.allPointsSelected.next(
      formValue[SyncPoint1.A1] !== undefined &&
      formValue[SyncPoint1.B1] !== undefined &&
      formValue[SyncPoint1.C1] !== undefined
    );

    this.allSyncPointsSelected.next(
      formValue[SyncPoint2.A2] !== undefined &&
      formValue[SyncPoint2.B2] !== undefined &&
      formValue[SyncPoint2.C2] !== undefined
    );
  }
  placePoint(event: Event, pointLabel: SyncPointType): void {
    event.preventDefault();
    event.stopPropagation();
    this.ngZone.run(() => {
      this._cancelPlacement$.next();

      this._setActivePoint(pointLabel);
    });
    of(pointLabel)
      .pipe(
        mergeMap((pointLabel: string) => {
          const actions: Observable<void | string[]>[] = [];
          if (this._smallScreen && this.touchScreen()) {
            this.matterportManagerService.setHint(
              '<p>Hold down to place a marker</p>',
              { top: '10px' }
            );
          }
          const config: StartPlacingConfig = {
            note: {
              id: pointLabel,
              noteType: pointLabel as TagNoteTypes,
              position: new Vector3(0, 0, 0)
            },
            autoFinishByClick: true,
            mobile: this._smallScreen && this.touchScreen()
          };
          if (this.pointsPositions[pointLabel]) {
            this.matterportManagerService.deleteNote(pointLabel);
            this.pointsPositions[pointLabel] = undefined;
          }
          actions.push(
            this.matterportManagerService.startPlacingComponent$(config)
          );
          return forkJoin(actions);
        }),
        switchMap(() => {
          return race(
            this.matterportManagerService.tagPlacementAccepted$,
            this._cancelPlacement$.asObservable()
          );
        }),

        mergeMap(
          (
            value: void | {
              tagNote: TagNote;
              comp: MatterportComponent | undefined;
            }
          ) => {
            if (value === undefined) {
              return throwError(() => new Error('Abandon tag placing'));
            }
            const transformConverter = new TransformConverter(
              this.matterportBaseService._lastKnownMatterportOffset ?? ''
            );
            const { x, y, z } = value.tagNote.position;

            this.pointsPositions[pointLabel] = transformConverter.to3dPosition(
              new Vector3(x, y, z)
            );
            return of(value);
          }
        ),
        switchMap(
          (
            tag: void | {
              tagNote: TagNote;
              comp: MatterportComponent | undefined;
            }
          ) => {
            if (tag === undefined) {
              return throwError(() => new Error('Abandon tag placing'));
            }
            const matterport = document.getElementById('matterport');
            return this.matterportManagerService.worldToScreen(
              tag.tagNote.position,
              {
                h: matterport?.clientHeight ?? 100,
                w: matterport?.clientWidth ?? 100
              }
            );
          }
        ),
        switchMap((tagPos: Vector3 | undefined) => {
          const matterport = document.getElementById('matterport');
          let clip: {
            top: number;
            left: number;
          };
          if (tagPos) {
            clip = this._calcOffset(
              {
                h: matterport?.clientHeight ?? 0,
                w: matterport?.clientWidth ?? 0
              },
              tagPos
            );
          }
          return from(
            this.matterportManagerService.screenshot({
              height: matterport?.clientHeight ?? 600,
              width: matterport?.clientWidth ?? 800
            })
          ).pipe(
            map((screenshot: string | undefined) => {
              return {
                screenshot,
                clip
              };
            })
          );
        }),
        tap(
          (resp: {
            screenshot: string | undefined;
            clip: {
              top: number;
              left: number;
            };
          }) => {
            this.screenshot[pointLabel] = resp;
          }
        ),
        catchError((e) => {
          console.error(e);
          this.matterportManagerService.abandonPlacingComponent();
          this.matterportManagerService.setSweepsActive(true);
          return of(e);
        }),
        take(1)
      )
      .subscribe(() => {
        this.ngZone.run(() => {
          this._setActivePoint(undefined);
          this.matterportManagerService.setSweepsActive(true);
        });
      });
  }

  private _close(): void {
    this.router.navigate(['../../stages/info'], {
      queryParamsHandling: 'preserve',
      relativeTo: this.activatedRoute
    });
  }

  private _createSynchronizeConfirmationDialog<T>(): ConfirmationModalRef<T> {
    return this.modalService.createModal(
      SynchronizeConfirmationDialogComponent,
      {},
      `Are you sure you want to leave synchronization?`
    );
  }

  private _screenshotSize(): { width: number; height: number } {
    const matterport = document.getElementById('matterport');
    const width =
      matterport?.clientWidth && matterport?.clientWidth < MAX_SCREENSHOT_SIZE.w
        ? matterport?.clientWidth
        : MAX_SCREENSHOT_SIZE.w;
    const height =
      matterport?.clientHeight &&
        matterport?.clientHeight < MAX_SCREENSHOT_SIZE.h
        ? matterport?.clientHeight
        : MAX_SCREENSHOT_SIZE.h;
    return { width, height };
  }
  private _calcOffset(
    resolution: { h: number; w: number },
    tagPosition: Vector3
  ) {
    let right = 0;
    let left = 0;
    let top = 0;
    let bottom = 0;
    let widthOffset = 0;
    let hightOffset = 0;
    const screenshotSize = this._screenshotSize();

    if (Math.abs(tagPosition.x) + screenshotSize.width * 0.5 < resolution.w) {
      right =
        resolution.w - (Math.abs(tagPosition.x) + screenshotSize.width * 0.5);
    } else {
      right = 0;
      widthOffset =
        Math.abs(tagPosition.x) + screenshotSize.width * 0.5 - resolution.w;
    }
    if (Math.abs(tagPosition.x) - screenshotSize.width * 0.5 > 0) {
      left = Math.abs(tagPosition.x) - screenshotSize.width * 0.5;
    } else {
      left = 0;
      widthOffset = Math.abs(tagPosition.x) - screenshotSize.width * 0.5;
    }

    if (Math.abs(tagPosition.y) + screenshotSize.height * 0.5 < resolution.h) {
      bottom =
        resolution.h - (Math.abs(tagPosition.y) + screenshotSize.height * 0.5);
    } else {
      bottom = 0;
      hightOffset =
        Math.abs(tagPosition.y) + screenshotSize.height * 0.5 - resolution.h;
    }
    if (Math.abs(tagPosition.y) - screenshotSize.height * 0.5 > 0) {
      top = Math.abs(tagPosition.y) - screenshotSize.height * 0.5;
    } else {
      top = 0;
      hightOffset = Math.abs(tagPosition.y) - screenshotSize.height * 0.5;
    }
    if (widthOffset !== 0) {
      if (widthOffset < 0) {
        right = right + widthOffset;
      } else {
        left = left - widthOffset;
      }
    }
    if (hightOffset !== 0) {
      if (hightOffset < 0) {
        bottom = bottom + hightOffset;
      } else {
        top = top - hightOffset;
      }
    }
    return { left, top };
  }
  private _cropImage(): void {
    if (this.currentScreenshot) {
      const { width, height } = this._screenshotSize();
      const screenshot = this.currentScreenshot;
      const image = new Image();
      image.onload = () => {
        const clip = this.screenshot[screenshot]?.clip;
        const canvas = document.getElementById(
          'destImage'
        ) as HTMLCanvasElement;
        canvas.width = width;
        canvas.height = height;
        if (canvas) {
          const contex = canvas.getContext('2d');

          if (image) {
            contex?.drawImage(
              image,
              clip?.left || 0,
              clip?.top || 0,
              width,
              height,
              0,
              0,
              width,
              height
            );
            this.changeDetectorRef.markForCheck();
          }
        }
      };
      image.src = this.screenshot[screenshot]?.screenshot ?? '';
    }
  }
  private _getSynchronizedComponentsForStages$(): Observable<SyncComponent[]> {
    return this.stagesFacade.allStages$.pipe(
      switchMap((stages: StageWithCount[]) => {
        return forkJoin(
          stages.map((stage: StageWithCount) =>
            this.apiFacadeService.components
              .getSynchronizedComponentsForStage<StageComponent>({
                stageId: stage.id
              })
              .pipe(
                map((components: StageComponent[]) => {
                  return components.filter((comp: StageComponent) => {
                    return comp.id !== this._componentToSynchronize.id;
                  });
                }),
                map((components: StageComponent[]) => {
                  if (components.length) {
                    return [
                      ...[{ stageName: stage.name, name: '', id: '' }],
                      ...components.map((component: StageComponent) => {
                        return { ...component, stageName: '' };
                      })
                    ];
                  } else {
                    return [
                      ...components.map((component: StageComponent) => {
                        return { ...component, stageName: '' };
                      })
                    ];
                  }
                })
              )
          )
        );
      }),
      map((syncComponent: SyncComponent[][]) => {
        return syncComponent.flat();
      }),
      take(1)
    );
  }
}
