import { Injectable } from '@angular/core';
import { AnnotationModules } from '@simlab/data-access';
import { Observable, Subject } from 'rxjs';

type MoveToAnnotationMarker = {
  annotationType: AnnotationModules;
  flag: boolean;
}

@Injectable()
export class MatterportNoteControlService {
  private readonly _moveToNote = new Subject<MoveToAnnotationMarker>();
  readonly moveToNote$ = this._moveToNote.asObservable();

  set moveToNote(value: MoveToAnnotationMarker) {
    this._moveToNote.next(value);
  }

  private readonly _takeScreenshot: Subject<void> = new Subject<void>();
  readonly takeScreenshot$: Observable<void> =
    this._takeScreenshot.asObservable();

  takeScreenshot() {
    this._takeScreenshot.next();
  }
}
