import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { SlicePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  output,
  signal,
  ViewEncapsulation
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CURRENT_PROJECT_ID, StagesFacade } from '@simlab/data-store';
import { FormatDateSignalPipe } from '@simlab/design/format-date';
import { DesignIcon } from '@simlab/design/icon';
import { ApiProjectService } from '@simlab/feature-stages/data-access';
import {
  CollaboratorProject,
  NoteExtendStatus
} from '@simlab/feature-stages/models';
import { NoteStatusLabel, NoteTypeLabel } from '@simlab/feature/stages';
import { PunchListWorkflowStatus } from '@simlab/procore/models';
import { PunchItemStatusLabel } from '@simlab/procore/pipes';
import { UiChipsModule } from '@simlab/ui/chips';
import { AnnotationsFilterService } from 'libs/pages/project/services/annotations-filter.service';
import { derivedAsync } from 'ngxtension/derived-async';
import { map } from 'rxjs';
import { IChipItem } from '../../models/chip.models';
import { ChipsFilterCategoryTranslatePipe } from '../../pipes/chip-category.pipe';

type CollaboratorData = Pick<CollaboratorProject, 'userName'> & {
  isAssignee: boolean;
};

type CollaboratorRecord = Record<string, CollaboratorData>;
type StageRecord = Record<string, string>;

@Component({
  selector: 'lib-annotations-active-filter-chips',
  standalone: true,
  imports: [
    OverlayModule,
    UiChipsModule,
    ChipsFilterCategoryTranslatePipe,
    SlicePipe,
    DesignIcon
  ],
  providers: [FormatDateSignalPipe],
  templateUrl: './annotations-active-filter-chips.component.html',
  styleUrl: './annotations-active-filter-chips.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'annotation-active-filters'
  }
})
export class AnnotationsActiveFilterChipsComponent {
  private readonly _formatDate = inject(FormatDateSignalPipe);
  private readonly _annotationsFilterService = inject(AnnotationsFilterService);
  private readonly _projectService = inject(ApiProjectService);
  private readonly _stagesFacade = inject(StagesFacade);
  private readonly _projectId = inject(CURRENT_PROJECT_ID);

  private readonly _projectCollaborators = derivedAsync(
    () => {
      return this._projectService
        .getProjectAndProcoreCollaborators(this._projectId()!)
        .pipe(
          map((collaborators) => {
            return collaborators.reduce(
              (record, { id, userName, procoreUserId }) => {
                return {
                  ...record,
                  [id]: {
                    userName,
                    isAssignee: !!procoreUserId
                  }
                };
              },
              {} as CollaboratorRecord
            );
          })
        );
    },
    { initialValue: {} }
  );

  private readonly _projectStages = toSignal(
    this._stagesFacade.allStages$.pipe(
      map((stages) =>
        stages.reduce(
          (record, stage) => ({ ...record, [stage.id]: stage.name }),
          {} as StageRecord
        )
      )
    ),
    { initialValue: {} as StageRecord }
  );

  readonly showAllFilters = output();

  protected filterState = this._annotationsFilterService.filterState;
  protected filtersLength = this._annotationsFilterService.filtersLength;
  protected maxVisibleFiltersLength = 5;

  protected readonly chips = computed(() => {
    return [
      ...this._getAuthorChips(),
      ...this._getStakeholderChips(),
      ...this._getStagesChips(),
      ...this._getNoteStatusesChips(),
      ...this._getNoteTypesChips(),
      ...this._getCreationDateChip(),
      ...this._getModificationDateChip(),
      ...this._getPunchItemStatusesChips(),
      ...this._getPunchItemBallInCourtChips(),
      ...this._getPunchItemDueDateChip(),
      ...this._getRFIStatusesChips(),
      ...this._getRFIBallInCourtChips(),
      ...this._getRFIDueDateChip()
    ];
  });

  protected readonly positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 4
    }
  ];

  protected readonly panelOpen = signal<boolean>(false);

  protected close() {
    this.panelOpen.set(false);
  }

  protected openPanel() {
    this.panelOpen.set(!this.panelOpen());
  }

  protected clearAllFilters() {
    this._annotationsFilterService.resetFilters();
  }

  protected removeSelectedFilter(chip: IChipItem) {
    this._annotationsFilterService.removeSelectedFilter(chip);
  }

  private _getAuthorChips(): IChipItem[] {
    return this.filterState()
      .authors.filter(
        (author): author is typeof author & { id: string } =>
          author.id !== undefined
      )
      .filter((stakeholder) => this._projectCollaborators()[stakeholder.id])
      .map((author) => {
        return {
          value: author.id,
          displayValue: this._projectCollaborators()[author.id].userName,
          key: 'Author'
        };
      });
  }

  private _getStakeholderChips(): IChipItem[] {
    return this.filterState()
      .stakeholders.filter(
        (stakeholder): stakeholder is typeof stakeholder & { id: string } =>
          stakeholder.id !== undefined
      )
      .filter((stakeholder) => this._projectCollaborators()[stakeholder.id])
      .map((stakeholder) => {
        const collaborator = this._projectCollaborators()[stakeholder.id];
        return {
          value: stakeholder.id,
          displayValue: collaborator?.userName,
          key: collaborator?.isAssignee ? 'Assignee' : 'Stakeholder'
        };
      });
  }

  private _getStagesChips(): IChipItem[] {
    return this.filterState().stagesRange.map((stage) => ({
      value: stage,
      displayValue: this._projectStages()[stage],
      key: 'Stage'
    }));
  }

  private _getNoteStatusesChips(): IChipItem[] {
    return this.filterState().noteStatuses.map((noteStatus) => ({
      value: noteStatus,
      displayValue: NoteStatusLabel[noteStatus as NoteExtendStatus<'Issue'>],
      key: 'NoteStatus'
    }));
  }

  private _getNoteTypesChips(): IChipItem[] {
    return this.filterState()
      .noteTypes.filter((type) => type !== 'Issue')
      .map((type) => ({
        value: type,
        displayValue: NoteTypeLabel[type],
        key: 'NoteType'
      }));
  }

  private _getCreationDateChip(): IChipItem[] {
    const { from, to } = this.filterState().dateCreation;
    return from && to
      ? [
          {
            value: ``,
            displayValue: `${this.getFormattedDateByUserPreferences(from)} - ${this.getFormattedDateByUserPreferences(to)}`,
            key: 'Created'
          }
        ]
      : [];
  }

  private _getModificationDateChip(): IChipItem[] {
    const { from, to } = this.filterState().dateModification;

    return from && to
      ? [
          {
            value: ``,
            displayValue: `${this.getFormattedDateByUserPreferences(from)} - ${this.getFormattedDateByUserPreferences(to)}`,
            key: 'Modified'
          }
        ]
      : [];
  }

  private _getPunchItemStatusesChips(): IChipItem[] {
    const punchItemStatuses = this.filterState().punchItemStatuses;
    return punchItemStatuses
      ? punchItemStatuses.map((status) => ({
          value: status,
          displayValue: PunchItemStatusLabel[PunchListWorkflowStatus[status]],
          key: 'Punch'
        }))
      : [];
  }

  private _getPunchItemBallInCourtChips(): IChipItem[] {
    const punchItemBallInCourt = this.filterState().punchItemBallInCourt;
    return punchItemBallInCourt
      ? punchItemBallInCourt.map((ballInCourt) => ({
          value: ballInCourt,
          displayValue: ballInCourt,
          key: 'PunchBallInCourt'
        }))
      : [];
  }

  private _getPunchItemDueDateChip(): IChipItem[] {
    return this.filterState().punchItemDueDate
      ? [
          {
            value: ``,
            displayValue: `${this.filterState().punchItemDueDate}`,
            key: 'PunchDueDate'
          }
        ]
      : [];
  }

  private _getRFIStatusesChips(): IChipItem[] {
    const rfiStatuses = this.filterState().rfiStatuses;
    return rfiStatuses
      ? rfiStatuses.map((status) => ({
          value: status,
          displayValue: status, //TODO: Add RFI Status translations
          key: 'RFI'
        }))
      : [];
  }

  private _getRFIBallInCourtChips(): IChipItem[] {
    const rfiBallInCourt = this.filterState().rfiBallInCourt;
    return rfiBallInCourt
      ? rfiBallInCourt.map((ballInCourt) => ({
          value: ballInCourt,
          displayValue: ballInCourt,
          key: 'RFIBallInCourt'
        }))
      : [];
  }

  private _getRFIDueDateChip(): IChipItem[] {
    return this.filterState().rfiDueDate
      ? [
          {
            value: ``,
            displayValue: `${this.filterState().rfiDueDate}`,
            key: 'RFIDueDate'
          }
        ]
      : [];
  }

  private getFormattedDateByUserPreferences(
    date: Date | string | undefined
  ): string {
    if (!date) return '';

    return this._formatDate.transform(date);
  }
}
