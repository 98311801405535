import { inject, Injectable, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class ProcoreInModalSelectStateService {
  private readonly _selectedState = signal<string | undefined>(undefined);
  private readonly _router = inject(Router);
  private readonly _activatedRouter = inject(ActivatedRoute);

  readonly selectedState = this._selectedState.asReadonly();

  setSelectedState(state: string | undefined): void {
    this._resetFolderIdFromUrl();
    this._selectedState.set(state);
  }

  private _resetFolderIdFromUrl() {
    this._router.navigate([], {
      queryParams: { folderId: null },
      queryParamsHandling: 'merge',
      relativeTo: this._activatedRouter
    });
  }
}
