import { NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, contentChild, input, TemplateRef } from '@angular/core';
import { DesignProgressSpinnerComponent } from '@simlab/design/progress-spinner';
import { ICON_TYPE } from '@simlab/ui/icon';
import { AnnotationPanelHeaderBtnComponent } from '../annotation-panel-header-btn/annotation-panel-header-btn.component';
import { AnnotationPanelHeaderComponent } from '../annotation-panel-header/annotation-panel-header.component';

@Component({
  selector: 'common-annotation-panel-root',
  standalone: true,
  imports: [AnnotationPanelHeaderComponent, AnnotationPanelHeaderBtnComponent, DesignProgressSpinnerComponent, NgTemplateOutlet],
  templateUrl: './annotation-panel-root.component.html',
  styleUrl: './annotation-panel-root.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotationPanelRootComponent {
  protected readonly nameTemplate = contentChild<TemplateRef<unknown>>('nameTemplate');

  readonly name = input('', {
    transform: (nameValue: string | undefined | null) => nameValue ?? ''
  });
  readonly subName = input('', {
    transform: (subNameValue: string | undefined | null) => subNameValue ?? ''
  });
  readonly iconType = input.required<ICON_TYPE>();
  readonly loading = input(false, {transform: booleanAttribute});
}
