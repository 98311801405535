import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  model,
  ViewEncapsulation,
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { DesignOptionComponent } from '@simlab/design/option';

export const sortDictionaryHelpers = {
  sortDataList: <TType extends EnumRecord>(
    sortDictionary: SortDictionary<TType>,
  ) => Object.values(sortDictionary),
  sortDataByType: <TType extends EnumRecord>(
    sortDictionary: SortDictionary<TType>,
  ) =>
    sortDictionaryHelpers.sortDataList(sortDictionary).reduce(
      (acc, item) => {
        if (acc[item.type] !== undefined) return acc;

        acc[item.type] = item;

        return acc;
      },
      {} as Record<EnumRecordValues, SortItem>,
    ),
};

export type EmptyObject = Record<string, never>;
export type EnumRecord = Record<string, number>;
export type EnumRecordValues = EnumRecord[number];

export type SortModel = {
  isAscending: boolean;
  sortColumn: number;
};

export type SortItem<TKey = keyof EnumRecord> = {
  type: EnumRecordValues;
  name: string;
  key: TKey;
};

export type SortDictionary<TType extends EnumRecord> = {
  [TKey in keyof TType]: SortItem<TKey>;
};

@Component({
  selector: 'design-sort-switch',
  standalone: true,
  imports: [
    DesignIcon,
    DesignIconButton,
    UiMenuPanelModule,
    DesignOptionComponent,
  ],
  templateUrl: './sort-switch.component.html',
  styleUrl: './sort-switch.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'design-sort-switch',
  },
})
export class SortSwitchComponent<TType extends EnumRecord> {
  private readonly _sortDataByType = computed(() =>
    sortDictionaryHelpers.sortDataByType(this.dictionary()),
  );

  protected readonly sortDataList = computed(() =>
    sortDictionaryHelpers.sortDataList(this.dictionary()),
  );

  protected readonly isAscending = computed(
    () => this.sortData()?.isAscending ?? this.defaultSortState().isAscending,
  );

  protected readonly sortStateName = computed(() => {
    const sortColumn =
      this.sortData()?.sortColumn ?? this.defaultSortState().sortColumn;
    const stateName = this._sortDataByType()[sortColumn].name;

    return stateName;
  });

  readonly dictionary = input.required<SortDictionary<TType>>();
  readonly defaultSortState = input.required<SortModel>();
  readonly sortData = model<SortModel | EmptyObject>({});

  protected selectSortState(state: SortItem) {
    this.sortData.update(({ isAscending }) => ({
      isAscending: isAscending ?? this.defaultSortState().isAscending,
      sortColumn: state.type,
    }));
  }

  protected toggleIsAscendingState() {
    this.sortData.update(({ isAscending, sortColumn }) => ({
      isAscending: !isAscending,
      sortColumn: sortColumn ?? this.defaultSortState().sortColumn,
    }));
  }
}
