@if (loading()) {
  <design-progress-spinner designColor="accent" class="progress-spinner" />
}

<div class="annotation-header">
  <lib-annotation-panel-header
  class="annotation-header__instance"
    [name]="name()"
    [subName]="subName()"
    [iconType]="iconType()"
  >
  @if(nameTemplate() !== undefined) {
    <ng-template #nameContent>
      <ng-container [ngTemplateOutlet]="nameTemplate() || null" />
    </ng-template>
  }
</lib-annotation-panel-header>

  <div class="annotation-header__right-side">
    <ng-content select="[headerContent]" />
    <lib-annotation-panel-header-btn />
  </div>
</div>
<div class="annotation-content">
  <ng-content />
</div>
