import { inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { GlobalFiltersStore } from '@simlab/annotations-filters/services';
import {
  ComponentsFacade,
  ProjectsFacade,
  UserPreferenceFacade
} from '@simlab/data-store';
import { NoteFiltersStore } from '@simlab/feature-stages/services';
import { provideMatterportConfiguration } from '@simlab/feature/matterport';
import {
  CanLoadSynchronizationGuard,
  FetchAvailableComponentsResolver,
  FetchAvailableStagesResolver,
  PanelsClosedGuard,
  StageProjectResolver
} from '@simlab/feature/stages';
import { ProcoreProjectInfo } from '@simlab/procore/models';
import { ProcoreFiltersStore } from '@simlab/procore/services';
import { Environment, RouterFacadeService } from '@simlab/util-shared';
import { distinctUntilChanged, map, of, switchMap, take } from 'rxjs';
import { InfoComponent } from './info/info.component';
import { MatterportPageComponent } from './matterport/matterport.component';
import { SplitViewComponent } from './split-view/split-view.component';
import { StagesRootComponent } from './stages-root/stages-root.component';
import { SynchronizationComponent } from './synchronization/synchronization.component';
import { TagsImporterComponent } from './tags-importer/tags-importer.component';

export const provideProcoreFilterStore = () => {
  return {
    provide: ProcoreFiltersStore,
    useFactory: () => {
      const userConnectedToProcore =
        inject(UserPreferenceFacade).userConnectedToProcore();
      return userConnectedToProcore ? new ProcoreFiltersStore() : undefined;
    }
  };
};

export const getProjectProcoreInfo = (
  facade: ProjectsFacade,
  routerFacade: RouterFacadeService
) => {
  return toSignal(
    routerFacade.getNthRouterParam$(1).pipe(
      map(
        (route: ActivatedRouteSnapshot | null | undefined) =>
          route?.params['projectId'] as string | undefined
      ),
      distinctUntilChanged(),
      switchMap((projectId) => {
        if (!projectId) return of(undefined);

        return facade.projectConnectedWithProcore$(projectId).pipe(
          switchMap((isConnectedToProcore) => {
            if (!isConnectedToProcore) return of(undefined);

            return facade.selectProjectById$(projectId).pipe(
              map((projectData) => {
                if (projectData === undefined) return undefined;

                return {
                  projectId: projectData.id,
                  procoreCompanyId: projectData.procoreInfo?.companyId,
                  procoreProjectId: projectData.procoreInfo?.projectId
                } as ProcoreProjectInfo;
              }),
              take(1)
            );
          })
        );
      })
    )
  );
};

export const projectFiltersProviders = [
  GlobalFiltersStore,
  NoteFiltersStore,
  provideProcoreFilterStore()
];

export const stagesRoutes = (environment: Environment): Routes => [
  {
    path: '',
    providers: [
      ComponentsFacade,
      FetchAvailableStagesResolver,
      FetchAvailableComponentsResolver,
      StageProjectResolver,
      PanelsClosedGuard,
      CanLoadSynchronizationGuard
    ],
    children: [
      {
        path: '',
        component: StagesRootComponent,
        canDeactivate: [PanelsClosedGuard],
        resolve: [
          StageProjectResolver,
          FetchAvailableStagesResolver,
          FetchAvailableComponentsResolver
        ],
        children: [
          { path: '', redirectTo: 'matterport', pathMatch: 'full' },
          {
            path: 'info',
            component: InfoComponent,
            canDeactivate: [PanelsClosedGuard]
          },
          {
            path: 'annotations',
            loadComponent: () =>
              import('./annotations/annotations.component').then(
                (m) => m.AnnotationsComponent
              ),
            canDeactivate: [PanelsClosedGuard],
            loadChildren: () =>
              import('./annotations/annotations.routing').then(
                (m) => m.annotationsRoutes
              )
          },
          {
            path: 'matterport',
            component: MatterportPageComponent,
            canDeactivate: [PanelsClosedGuard],
            providers: [provideMatterportConfiguration(environment)]
          },
          {
            path: 'synchronization',
            component: SynchronizationComponent,
            canActivate: [CanLoadSynchronizationGuard],
            providers: [provideMatterportConfiguration(environment)]
          },
          { path: 'notes', redirectTo: 'annotations/notes' }

          // { path: '**', redirectTo: 'info' },
        ]
      },
      {
        path: 'importer',
        component: TagsImporterComponent,
        providers: [provideMatterportConfiguration(environment)]
      },
      {
        path: 'splitView',
        component: SplitViewComponent,

        providers: [provideMatterportConfiguration(environment)]
      },
      { path: '**', redirectTo: 'info' }
    ]
  },

  { path: '**', redirectTo: 'info' }
];
