import { Pipe, PipeTransform } from '@angular/core';
import { NoteType } from '@simlab/feature-stages/models';

export const NoteTypeLabel: Record<NoteType, string> = {
  Issue: $localize`:@@NOTE_TYPE_ISSUE:Issue`,
  Information: $localize`:@@NOTE_TYPE_INFORMATION:Information`
};

@Pipe({ name: 'getNoteTypeLabel', standalone: true })
export class NoteTypeLabelGetterPipe implements PipeTransform {
  transform(status: NoteType): string {
    return NoteTypeLabel[status];
  }
}
