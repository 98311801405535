import {
  ChangeDetectionStrategy,
  Component,
  inject,
  isSignal,
  Pipe,
  PipeTransform,
  signal,
  viewChild
} from '@angular/core';
import {
  MenuPanelComponent,
  UiMenuPanelModule
} from '@simlab/design/menu-panel';
import {
  AnnotationAction,
  annotationsActionsToken
} from 'common/annotation-panel/models';

@Pipe({
  name: 'annotationPanelHeaderActionName',
  standalone: true
})
export class AnnotationPanelHeaderActionNamePipe implements PipeTransform {
  transform(name: AnnotationAction['name']) {
    return isSignal(name) ? name : signal(name);
  }
}

@Component({
  selector: 'lib-annotation-panel-header-action',
  standalone: true,
  imports: [UiMenuPanelModule, AnnotationPanelHeaderActionNamePipe],
  templateUrl: './annotation-panel-header-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'triggerMenu'
})
export class AnnotationPanelHeaderActionComponent {
  readonly menu = viewChild.required(MenuPanelComponent);
  private readonly _actions = inject(annotationsActionsToken);
  readonly actionsList = signal(this._actions.actionList);
}
