import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild, inject } from '@angular/core';
import { DesignOptionBase } from '@simlab/design/common';
import { Subscription } from 'rxjs';

/** Event object that is emitted when an autocomplete option is activated. */
export interface DesignAutocompleteEvent {
  /** Reference to the autocomplete panel that emitted the event. */
  source: AutocompleteBase;

  /** Option that was selected. */
  option: DesignOptionBase | null;
}

@Directive()
export abstract class AutocompleteBase implements AfterContentInit, OnDestroy {
      private _changeDetectorRef = inject(ChangeDetectorRef);
  private _activeOptionChanges = Subscription.EMPTY;
  protected abstract _options: QueryList<DesignOptionBase>;

  // The @ViewChild query for TemplateRef here needs to be static because some code paths
  // lead to the overlay being created before change detection has finished for this component.
  // Notably, another component may trigger `focus` on the autocomplete-trigger.
  /** @docs-private */
  @ViewChild(TemplateRef, { static: true })
  set template(value: TemplateRef<any>) {
    this._template = value;
  }
  get template(): TemplateRef<any> {
    return this._template;
  }
  private _template!: TemplateRef<any>;

  get options(): QueryList<DesignOptionBase> {
    return this._options;
  }

  /** Function that maps an option's control value to its display value in the trigger. */
  @Input() displayWith: ((value: any) => string) | null = null;

  /** Manages active item in option list based on key events. */
  _keyManager!: ActiveDescendantKeyManager<DesignOptionBase>;

  @Output()
  readonly opened: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readonly closed: EventEmitter<void> = new EventEmitter<void>();

  /** Emits whenever an option is activated. */
  @Output()
  readonly optionActivated: EventEmitter<DesignAutocompleteEvent> = new EventEmitter<DesignAutocompleteEvent>();

  /** Event that is emitted whenever an option from the list is selected. */
  @Output()
  readonly optionSelected: EventEmitter<DesignAutocompleteEvent> = new EventEmitter<DesignAutocompleteEvent>();

  /** Whether the autocomplete panel should be visible, depending on option length. */
  showPanel = false;

  /** Whether the autocomplete panel is open. */
  get isOpen(): boolean {
    return this._isOpen;
  }
  set isOpen(value: boolean) {
    this._isOpen = value;
  }
  protected _isOpen = false;

  /** Whether the active option should be selected as the user is navigating. */
  @Input()
  get autoSelectActiveOption(): boolean {
    return this._autoSelectActiveOption;
  }
  set autoSelectActiveOption(value: BooleanInput) {
    this._autoSelectActiveOption = coerceBooleanProperty(value);
  }
  private _autoSelectActiveOption = true;

  /**
   * Specify the width of the autocomplete panel.  Can be any CSS sizing value, otherwise it will
   * match the width of its host.
   */
  @Input()
  panelWidth!: string | number;

  /**
   * Whether the first option should be highlighted when the autocomplete panel is opened.
   */
  @Input()
  get autoActiveFirstOption(): boolean {
    return this._autoActiveFirstOption;
  }
  set autoActiveFirstOption(value: BooleanInput) {
    this._autoActiveFirstOption = coerceBooleanProperty(value);
  }
  private _autoActiveFirstOption = true;

  ngAfterContentInit(): void {
    this._keyManager = new ActiveDescendantKeyManager<DesignOptionBase>(
      this._options
    ).withWrap();

    this._activeOptionChanges = this._keyManager.change.subscribe((index) => {
      if (this.isOpen) {
        this.optionActivated.emit(
          Object.assign({}, <DesignAutocompleteEvent>{
            source: this,
            option: this._options.toArray()[index] || null,
          })
        );
      }
    });

    // Set the initial visibility state.
    this._setVisibility();
  }

  ngOnDestroy(): void {
    this._activeOptionChanges.unsubscribe();
  }

  /** Emits the `select` event. */
  _emitSelectEvent(option: DesignOptionBase): void {
    const event = Object.assign({}, <DesignAutocompleteEvent>{
      source: this,
      option: option,
    });

    this.optionSelected.emit(event);
  }

  /** Panel should hide itself when the option list is empty. */
  _setVisibility() {
    this.showPanel = !!this.options.length;
    // this._setVisibilityClasses(this._classList);
    this._changeDetectorRef.markForCheck();
  }
}
