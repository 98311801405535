import {
  Directive,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

@Directive()
export class TimelineDraggableDirective {
  @ViewChild('container') protected _container!: ElementRef<HTMLDivElement>;
  @ViewChildren('items') protected _items!: QueryList<
    ElementRef<HTMLDivElement>
  >;

  slideNext(): void {
    const elementWidth = this._items.first.nativeElement.offsetWidth;
    const calcTransform =
      this._getCurrentTransform() - this._items.first.nativeElement.offsetWidth;
    if (calcTransform > this._items.length * elementWidth * -1) {
      this._container.nativeElement.style.transform = `translateX(${calcTransform}px)`;
    }
  }

  slidePrevious(): void {
    const elementWidth = this._items.first.nativeElement.offsetWidth;
    const calcTransform = this._getCurrentTransform() + elementWidth;
    if (calcTransform <= 0) {
      this._container.nativeElement.style.transform = `translateX(${calcTransform}px)`;
    }
  }

  private _getCurrentTransform() {
    return Number.parseInt(
      (this._container.nativeElement.style.transform as string)
        .replace('translateX(', '')
        .replace('px)', '')
    );
  }
}
