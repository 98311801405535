import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  DestroyRef,
  inject,
  OnInit,
  output,
  viewChild
} from '@angular/core';
import {
  outputToObservable,
  takeUntilDestroyed,
  toObservable,
  toSignal
} from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { BreadcrumbComponent } from '@simlab/design/breadcrumb';
import { DesignIconButton, DesignStrokedButton } from '@simlab/design/button';
import {
  DesignButtonGroupComponent,
  DesignButtonToggleComponent
} from '@simlab/design/button-group';
import {
  DragAndDropComponent,
  DragAndDropDirective,
  TUploadFile
} from '@simlab/design/drag-and-drop';
import { EmptyPlaceholderComponent } from '@simlab/design/empty-placeholder';
import { DesignIcon } from '@simlab/design/icon';
import { breakpointsObserver } from '@simlab/design/layout';
import { DesignProgressSpinnerComponent } from '@simlab/design/progress-spinner';
import { SearchInputComponent } from '@simlab/design/search-input';
import { SortModel, SortSwitchComponent } from '@simlab/design/sort-switch';
import {
  CUSTOM_ROOT_FOLDER,
  DocumentsInstanceDataToken,
  documentsSortDictionary,
  FolderAsPathItem
} from '@simlab/documents/data-access';
import {
  DocumentsPlatformKeyEventsService,
  DocumentsStateService,
  ViewDocumentType
} from '@simlab/documents/services';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiSidenavModule } from '@simlab/ui/sidenav';
import { merge, switchMap } from 'rxjs';
import { DocumentActionPanelComponent } from '../document-action-panel/document-action-panel.component';
import { DocumentActionsDirective } from '../document-action-panel/documentAction.directive';
import { DocumentsGridComponent } from '../documents-grid/documents-grid.component';
import { DocumentsPreviewModalComponent } from '../documents-preview-modal/documents-preview-modal.component';
import { DocumentTableComponent } from '../documents-table/documents-table.component';
import { DocumentsViewSwitchComponent } from '../documents-view-switch/documents-view-switch.component';
import { ElementInfoComponent } from '../item-info/element-info.component';
import { DocumentsSelection } from './documents-selection';

export const DEFAULT_INFO_SIDENAV_RENDER_STATE = true;

@Component({
  selector: 'documents-root',
  standalone: true,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    NgStyle,
    NgClass,
    DocumentTableComponent,
    DocumentsGridComponent,
    UiSidenavModule,
    ElementInfoComponent,
    DesignIcon,
    DesignIconButton,
    BreadcrumbComponent,
    DesignButtonToggleComponent,
    DesignButtonGroupComponent,
    UiDividerModule,
    SearchInputComponent,
    DocumentsViewSwitchComponent,
    DocumentActionsDirective,
    ScrollingModule,
    DesignStrokedButton,
    DragAndDropComponent,
    DragAndDropDirective,
    DocumentsPreviewModalComponent,
    DesignProgressSpinnerComponent,
    EmptyPlaceholderComponent,
    SortSwitchComponent
  ],
  templateUrl: './documents-root.component.html',
  styleUrls: ['./documents-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DocumentsPlatformKeyEventsService]
})
export class DocumentsRootComponent
  extends DocumentsSelection
  implements AfterContentInit, OnInit
{
  private readonly _router = inject(Router);
  private readonly _rootFolder = inject<FolderAsPathItem[]>(CUSTOM_ROOT_FOLDER);
  private readonly _destroyRef = inject(DestroyRef);
  protected readonly state = inject(DocumentsStateService);
  private readonly _actions = contentChild(DocumentActionPanelComponent, {
    descendants: true
  });

  protected readonly instanceData = inject(DocumentsInstanceDataToken);
  protected readonly hasInfoSidenav =
    this.instanceData.hasInfoSidenav ?? DEFAULT_INFO_SIDENAV_RENDER_STATE;

  protected readonly isLessThanMd = inject(breakpointsObserver).lessThanMd;
  protected readonly data = this.state.data;
  protected readonly isLoading = this.state.isLoading;
  protected readonly sort = this.state.sort;
  protected readonly search = this.state.searchValue;

  protected readonly setSort = this.state.setSort;

  readonly existData = computed(() => {
    return this.data().length;
  });

  protected readonly view = toSignal<ViewDocumentType>(this.state.viewModel$);

  protected readonly filterChange = this.state.filtersChange;
  protected readonly rootFolderName = this._rootFolder[0].name ?? '-';
  protected readonly path = computed(() => [
    ...this._rootFolder,
    ...(this.state.breadcrumbs() ?? [])
  ]);
  protected readonly isFilterMode = this.state.isFilterMode;
  protected readonly sortDictionary = documentsSortDictionary;
  protected readonly defaultSortState: SortModel = {
    isAscending: false,
    sortColumn: documentsSortDictionary.Name.type
  };
  readonly directoryId = this.state.directoryId;
  readonly sidenavEndOpen = this.state.sidenavEndOpen;

  readonly gridView = viewChild(DocumentsGridComponent);
  readonly tableView = viewChild(DocumentTableComponent);

  readonly dndFiles = output<TUploadFile[]>();

  readonly selectableItems = computed(() => [
    ...(this.gridView()?.options() ?? []),
    ...(this.tableView()?.options() ?? [])
  ]);

  readonly selectedItems = computed(() =>
    this.selectableItems().filter(({ selected }) => selected())
  );
  readonly selectedItems$ = toObservable(this.selectableItems);
  readonly selection$ = this.selectedItems$.pipe(
    switchMap((items) =>
      merge(...items.map((item) => outputToObservable(item.selection)))
    )
  );

  readonly gridView$ = toObservable(this.gridView);
  readonly tableView$ = toObservable(this.tableView);

  protected hasDndFilesListeners = false;

  constructor() {
    super();
    this.selectionObserver.subscribe();
    this.state.selectDocument = this._selectDocument.bind(this);
  }

  ngOnInit(): void {
    this.hasDndFilesListeners = this.dndFiles['listeners'] !== null;
  }

  ngAfterContentInit(): void {
    const actions = this._actions();
    if (actions === undefined) return;

    actions.filtersEmitter$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((data) => this.state.setFilters(data));
  }

  toggleOpenInfo() {
    this.state.toggleSidenavEndState();
  }

  uploadFile(files: TUploadFile[]) {
    this.dndFiles.emit(files);
  }

  onSearch(value: string) {
    this.state.updateSearchName(value);
  }

  clearSelection() {
    this.selectedItems().forEach((option) => option.deselect());
  }

  protected switchView($event: ViewDocumentType | undefined) {
    if (!$event) {
      const current = this.view()!;
      this.state.changeViewModel(current === 'GRID' ? 'TABLE' : 'GRID');
      return;
    }
    this.state.changeViewModel($event);
  }

  private _selectDocument(documentId: string) {
    const selectableItems = this.selectableItems();
    this.state.setSelectedIds(documentId);
    selectableItems.forEach((item) =>
      item.id === documentId ? item.select() : item.deselect()
    );
  }
  breadcrumbNavigation(item: FolderAsPathItem) {
    if (item.clickHandler !== undefined) {
      item.clickHandler();
      return;
    }

    if (item.url) {
      this._router.navigateByUrl(item.url);
    } else
      this._router.navigate([], {
        queryParams: { folderId: item.id },
        queryParamsHandling: 'merge'
      });
  }
}
