<div class="top-section">
  <button
    class="top-section__return-button"
    design-stroked-button
    routerLink="../stages"
  >
    <i designIcon="arrow_back" designSize="20px"></i>
    <ng-container i18n="@@BACK_TO_PROJECT">Back to project</ng-container>
  </button>

  <h2 class="top-section__title">Project documents</h2>
</div>

<design-tab-group
  class="ui-tab-group"
  [alwaysShowTabCount]="false"
  [selectIndex]="currentSelectedInstance() ?? null"
  preventDefaultSelectIndexEvent
>
  <design-tab>
    <ng-template designTabLabel>
      <a [routerLink]="['./stages']" routerLinkActive="router-link-active">
        Stages
      </a>
    </ng-template>
  </design-tab>
  @if (projectConnectedWithProcore()) {
    <design-tab>
      <ng-template designTabLabel>
        <a [routerLink]="['./procore']" routerLinkActive="router-link-active">
          Procore</a
        >
      </ng-template>
    </design-tab>
  }
</design-tab-group>
<div class="root-document">
  <router-outlet></router-outlet>
</div>
