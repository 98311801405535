import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  OnInit,
  signal
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ApiFacadeService,
  Location,
  ProcoreConnectionStatusEnum,
  ProcoreProjectInfo,
  Project
} from '@simlab/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { toSignal } from '@angular/core/rxjs-interop';
import { EnumFlagHelper, ProjectPermission } from '@simlab/data-access';
import { ProjectsFacade } from '@simlab/data-store';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';
import { DesignChip } from '@simlab/design/chip';
import { DesignCommonModule, DesignLabel } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { TooltipOnClickDirective, UiTooltip } from '@simlab/design/tooltip';
import { ApiProcoreService } from '@simlab/procore/data-access';
import { ConnectProjectInfoComponent } from '@simlab/procore/ui';
import { UiButtonModule } from '@simlab/ui/button';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiHintModule } from '@simlab/ui/hint';
import { UiIconModule } from '@simlab/ui/icon';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import { UiInputModule } from '@simlab/ui/input';
import { API_GOOGLE, UiMapModule } from '@simlab/ui/map';
import { ConfirmationModalRef, MODAL_DATA } from '@simlab/ui/modal';
import { UiProgressSpinnerModule } from '@simlab/ui/progress-spinner';
import { UiTextareaModule } from '@simlab/ui/textarea';
import { UiUploadImageModule } from '@simlab/ui/upload-image';
import { Environment, ENVIRONMENT_CONFIG } from '@simlab/util-shared';
import { BehaviorSubject, Observable, of, take, takeUntil, tap } from 'rxjs';
import { ProjectBase } from '../../models/project-base';
import { ProjectForm } from '../../models/project-form';
import { PipesModule } from '../../pipes/pipes.module';

export type TProjectInfoBase = {
  id: string;
  name: string;
  companyName: string;
  procoreInfo?: ProcoreProjectInfo;
};

export type TProjectProcoreInfoBase = {
  companyName: ProcoreProjectInfo['companyName'];
  projectName: ProcoreProjectInfo['projectName'];
};

export interface ProjectInfoDialogResponse {
  data: TProjectInfoBase;
  action: 'confirm' | 'connectProcore' | 'detailConnection' | 'refreshData';
}

const DESCRIPTION_IMAGE_INFO: string[] = [
  $localize`:@@NO_LOCATION_1:It seems this project doesn’t have an assigned location.`
];

@Component({
  selector: 'feature-projects-project-info-dialog',
  templateUrl: './project-info-dialog.component.html',
  styleUrls: ['./project-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DesignCommonModule,
    DesignDialogWrapperModule,
    UiButtonModule,
    UiIconModule,
    PipesModule,
    CommonModule,
    UiDividerModule,
    UiButtonModule,
    UiIconModule,
    UiMapModule,
    UiInputModule,
    UiHintModule,
    UiUploadImageModule,
    UiTextareaModule,
    UiFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    UiProgressSpinnerModule,
    UiImageInfoModule,
    //NEW
    DesignChip,
    DesignLabel,
    DesignFlatButton,
    DesignStrokedButton,
    UiTooltip,
    TooltipOnClickDirective,
    ConnectProjectInfoComponent
  ]
})
export default class ProjectInfoDialogComponent
  extends ProjectBase
  implements OnInit
{
  public readonly enviroments = inject<Environment>(ENVIRONMENT_CONFIG);
  private readonly _apiProcoreService = inject(ApiProcoreService);
  public readonly project = inject<Project>(MODAL_DATA);
  public readonly googleApi$ = inject<Observable<unknown>>(API_GOOGLE);
  private readonly _modalRef = inject<
    ConfirmationModalRef<ProjectInfoDialogResponse>
  >(ConfirmationModalRef<ProjectInfoDialogResponse>);
  private readonly _projectsFacade = inject(ProjectsFacade);
  private readonly _apiFacadeService = inject(ApiFacadeService);
  readonly descriptionImageInfo: string[] = DESCRIPTION_IMAGE_INFO;
  private readonly _projectPermission = ProjectPermission;
  private readonly _bitValues =
    EnumFlagHelper.enumToBitValues(ProjectPermission);
  private readonly _procoreConnectStatus$ =
    this._projectsFacade.procoreConnectionStatus$(this.projectId);

  get projectId() {
    return this.project.id;
  }

  readonly hasAdminInStageProject = computed(() => {
    const projectPermissions = this.getUserPermissionsInProject();
    if (!projectPermissions) return false;

    return projectPermissions.hasAdminRoleInProject;
  });

  readonly canConnectProcore = computed(() => {
    const procoreToken = this.hasProcoreToken();
    const hasAdminInProject = this.hasAdminInStageProject();

    return hasAdminInProject && !!procoreToken;
  });

  readonly canEditProjectInfo = computed(() => {
    const userPermissionToEditProjectInfo =
      this.hasPermissionToEditProjectInfo();
    if (!userPermissionToEditProjectInfo) return false;

    return userPermissionToEditProjectInfo && this.project.isActive;
  });

  readonly hasPermissionToEditProjectInfo = computed(() => {
    const projectPermissions = this.getUserPermissionsInProject();
    if (!projectPermissions) return false;

    const privileges = EnumFlagHelper.bitValuesToSelected(
      this._bitValues,
      projectPermissions.permissions
    );

    return (
      privileges[this._projectPermission.EditProjectInfo] ||
      projectPermissions.hasAdminRoleInProject
    );
  });

  readonly getUserPermissionsInProject = toSignal(
    this._apiFacadeService.projects.getUserPermissionsInProject({
      projectId: this.project.id
    })
  );

  readonly hasProcoreToken = toSignal(
    this._apiProcoreService.hasUserProcoreToken()
  );

  readonly updatingProject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  readonly procoreConnectStatus = toSignal(this._procoreConnectStatus$);
  readonly procoreConnectionInfo = signal<ProcoreProjectInfo | undefined>(
    undefined
  );

  readonly procoreConnectStatusConnected = computed(() => {
    const procoreConnectStatus = this.procoreConnectStatus();
    if (!procoreConnectStatus) return false;

    return procoreConnectStatus === ProcoreConnectionStatusEnum.Connected;
  });

  ngOnInit(): void {
    this.name = this.project.name;
    this.description = this.project.description;
    this.address = this.project.place?.address;
    this.initialThumbnail =
      this.project?.projectThumbnailUrlWithSas?.urlWithSas;
    this.geolocation = {
      latitude: this.project.place?.latitude as number,
      longitude: this.project.place?.longitude as number
    };
    this._projectsFacade.initProcoreProjectStatus(
      this.projectId,
      this.project.procoreConnectionStatus
    );
    this.procoreConnectionInfo.set(this.project.procoreInfo);
  }

  connectProject() {
    this._modalRef.emit({
      state: true,
      result: {
        action: 'connectProcore',
        data: {
          id: this.project.id,
          name: this.project.name,
          companyName: this.project.organizationName
        }
      }
    });
    this._modalRef.close();
  }

  assignProject(procoreInfo: ProcoreProjectInfo) {
    this._projectsFacade.assignProcoreProject(this.projectId, procoreInfo);
  }

  openDetailsProcore() {
    const connectionInfo = this.procoreConnectionInfo();

    this._modalRef.emit({
      state: true,
      result: {
        action: 'detailConnection',
        data: {
          id: this.project.id,
          name: this.project.name,
          companyName: this.project.organizationName,
          procoreInfo: connectionInfo && {
            companyName: connectionInfo.companyName,
            projectName: connectionInfo.projectName,
            projectId: connectionInfo.projectId,
            companyId: connectionInfo.companyId
          }
        }
      }
    });
    this._modalRef.close();
  }

  readonly copyProjectId$ = of(this.project.id).pipe(
    tap((id) => {
      setTimeout(async () => await navigator.clipboard.writeText(id));
    })
  );

  submitForm(): void {
    this.updatingProject$.next(true);
    const editData: Partial<ProjectForm> = {
      name: this.nameControl?.value,
      description: this.descriptionControl?.value,
      address: this.addressControl?.value,
      thumbnail:
        !this.formGroup.value.imageData && this._initialThumbnail
          ? this.cleanUrl(this._initialThumbnail)
          : undefined,
      imageData: this.formGroup.value.imageData,
      geolocation: this.geolocationControl?.value
    };
    this._projectsFacade.updateProject(
      this.project.id,
      editData as ProjectForm
    );

    this._projectsFacade.updateProjectSuccess$
      .pipe(
        tap(() => {
          this.updatingProject$.next(false);
          this._modalRef.emit({ state: false });
          this._modalRef.close();
        }),
        take(1),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  cancel(): void {
    this._modalRef.emit({ state: false });
    this._modalRef.close();
  }

  closeWithRefreshProcoreStateRequest(): void {
    const connectionInfo = this.procoreConnectionInfo();

    this._modalRef.emit({
      state: true,
      result: {
        action: 'refreshData',
        data: {
          id: this.project.id,
          name: this.project.name,
          companyName: this.project.organizationName,
          procoreInfo: connectionInfo && {
            companyName: connectionInfo.companyName,
            projectName: connectionInfo.projectName,
            projectId: connectionInfo.projectId,
            companyId: connectionInfo.companyId
          }
        }
      }
    });
    this._modalRef.close();
  }

  setCoordinates(value: Location): void {
    this.address = value.address;
    this.geolocation = value.coordinates;
  }
}
