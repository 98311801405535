import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input
} from '@angular/core';
import { documentsActionsToken } from '@simlab/documents/data-access';
import { FolderStates } from '@simlab/documents/models';
import { CanOpenDirective } from '../can-open/can-open.directive';
import {
  CanSelectDirective,
  SelectableOption
} from '../can-select/can-select.directive';
import { DocumentBadgeStateComponent } from '../document-badge-state/document-badge-state.component';
import { DocumentsActionBtnComponent } from '../documents-action-btn/documents-action-btn.component';

@Component({
  selector: 'documents-folder',
  standalone: true,
  imports: [DocumentsActionBtnComponent, DocumentBadgeStateComponent],
  templateUrl: './folder.component.html',
  styleUrl: './folder.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    tabindex: '0'
  },
  hostDirectives: [
    {
      directive: CanSelectDirective,
      inputs: ['data'],
      outputs: ['selection']
    },
    {
      directive: CanOpenDirective,
      inputs: ['data'],
      outputs: ['openSource']
    }
  ]
})
export class FolderComponent {
  private readonly _selection = inject(SelectableOption);
  private readonly _actionList = inject(documentsActionsToken);
  protected readonly hasActions = this._actionList.actionList?.length > 0;
  protected readonly isSelected = this._selection.selected;

  readonly data = input.required<FolderStates>();
}
