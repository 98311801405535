import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  signal,
  viewChild,
  ViewEncapsulation
} from '@angular/core';
import { DesignIcon } from '@simlab/design/icon';
import {
  MenuPanelComponent,
  UiMenuPanelModule
} from '@simlab/design/menu-panel';
import { documentsActionsToken } from '@simlab/documents/data-access';
import { DocumentComponentsLoadedState } from '@simlab/documents/models';

@Component({
  selector: 'documents-documents-action-menu',
  standalone: true,
  imports: [CommonModule, UiMenuPanelModule, DesignIcon],
  templateUrl: './documents-action-menu.component.html',
  styleUrl: './documents-action-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'triggerMenu',
  host: {
    class: 'action-menu',
    '[class.action-menu--visible]': 'this.actionsLength()'
  }
})
export class DocumentsActionMenuComponent {
  readonly data = input.required<DocumentComponentsLoadedState>();
  readonly menu = viewChild.required(MenuPanelComponent);

  private readonly _actions = inject(documentsActionsToken);
  readonly actionsList = signal(this._actions.actionList);
  readonly actionsLength = computed(() => {
    const actions = this.actionsList();
    return actions?.length || 0;
  });
}
