<feature-stages-annotation-item-info-card
  class="annotations-info-container__item"
  i18n-name="@@NOTE_TYPE_ISSUE"
  name="Issue"
  icon="icon_note_issue_basic"
  [resultCount]="activeIssueNotesCount()"
>
  @if (inPendingNoteCount() > 0) {
    <design-status-badge class="status-badge--note-pending">{{
      inPendingNoteCount()
    }}</design-status-badge>
  }
  @if (unresolvedNoteCount() > 0) {
    <design-status-badge class="status-badge--note-unresolved">{{
      unresolvedNoteCount()
    }}</design-status-badge>
  }
  @if (inProgressNoteCount() > 0) {
    <design-status-badge class="status-badge--note-in-progress">{{
      inProgressNoteCount()
    }}</design-status-badge>
  }
  @if (resolvedNoteCount() > 0) {
    <design-status-badge class="status-badge--note-resolved">{{
      resolvedNoteCount()
    }}</design-status-badge>
  }
</feature-stages-annotation-item-info-card>
<feature-stages-annotation-item-info-card
  class="annotations-info-container__item"
  i18n-name="@@INFO"
  name="Info"
  icon="icon_note_info_basic"
  [resultCount]="selectedStage().noteCounts?.informationRootNotesCount ?? 0"
/>

@if (projectConnectedWithProcore()) {
  <feature-stages-annotation-item-info-card
    class="annotations-info-container__item"
    name="RFI"
    icon="icon_rfi_basic"
    [resultCount]="rfiCount()"
  >
    @if (rfiDraftAndOpenCount() > 0) {
      <design-status-badge class="status-badge--rfi-open-or-draft">{{
        rfiDraftAndOpenCount()
      }}</design-status-badge>
    }

    @if (rfiClosedCount() > 0) {
      <design-status-badge class="status-badge--rfi-closed">{{
        rfiClosedCount()
      }}</design-status-badge>
    }
  </feature-stages-annotation-item-info-card>
  <feature-stages-annotation-item-info-card
    class="annotations-info-container__item"
    name="Punch List"
    icon="icon_punch_item_basic"
    [resultCount]="punchListCount()"
  >
    @if (punchItemWithActionCount() > 0) {
      <design-status-badge
        class="status-badge--punch-item-closed-or-work-required"
        >{{ punchItemWithActionCount() }}</design-status-badge
      >
    }
    @if (punchItemWithNoActionCount() > 0) {
      <design-status-badge
        class="status-badge--punch-item-draft-or-was-sent-to-assignees"
        >{{ punchItemWithNoActionCount() }}</design-status-badge
      >
    }
  </feature-stages-annotation-item-info-card>
}
