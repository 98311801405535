<div class="body" *ngIf="!imageToDraw; else imageDrawing">
  <div class="body__header">
    <label class="body__header--label">{{ currentMediaInfo.name }}</label>

    <div class="body__header--button-action">
      <button design-icon-button (click)="close()">
        <i class="button--close" designIcon="cross" designSize="2.5rem"></i>
      </button>
    </div>
  </div>
  <div class="body__content">
    <div class="body__content--carousel">
      <ui-slideshow
        [collection]="(media$ | async) ?? []"
        [currentIndex]="data.openImageIndex"
        [type]="data.type"
        (currentMedia)="setCurrentMediaInfo($event)"
      >
      </ui-slideshow>
    </div>
  </div>

  <div class="body__tools" *ngIf="projectIsActive$ | async">
    <button
      *ngIf="data.type === 'photo' && !isProcoreDocuments"
      design-icon-button
      designButtonColor="secondary"
      class="body__tools--buttons"
      (click)="openImageDrawing()"
    >
      <i designIcon="brush_first" designSize="2rem"></i>
    </button>
    <button
      design-icon-button
      designButtonColor="secondary"
      class="body__tools--buttons"
      (click)="delete()"
    >
      <i designIcon="trash" designSize="2rem"></i>
    </button>
  </div>
</div>

<ng-template #imageDrawing>
  <feature-stages-image-drawing
    [image]="imageToDraw"
    [imageName]="currentMediaInfo.name"
    [dimension]="dimension"
    (closeEmitter)="close()"
    (saveImageEmitter)="saveDrawingImage($event)"
    [savingImage$]="savingImage$"
  >
  </feature-stages-image-drawing>
</ng-template>
