import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  Signal,
  signal,
  viewChild
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ProjectsFacade } from '@simlab/data-store';
import {
  ButtonLoaderComponent,
  DesignFlatButtonModule,
  DesignIconButton,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignFormFieldModule } from '@simlab/design/form-field';
import { DesignIcon } from '@simlab/design/icon';
import { ToastService } from '@simlab/design/toast';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  Company,
  Project,
  TActionOnCloseModal,
  TProcoreProjectIntegration
} from '@simlab/procore/models';
import { ImageInfoComponent } from '@simlab/ui/image-info';
import { derivedAsync } from 'ngxtension/derived-async';
import {
  catchError,
  filter,
  finalize,
  fromEvent,
  map,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import {
  LinkedProjectCardComponent,
  ProjectCard
} from '../linked-project-card/linked-project-card.component';
import { LinkingProjectComponent } from '../linking-project/linking-project.component';

@Component({
  selector: 'procore-project-integration',
  standalone: true,
  imports: [
    AsyncPipe,
    DesignDialogWrapperModule,
    DesignIcon,
    DesignIconButton,
    DesignFormFieldModule,
    DesignFlatButtonModule,
    DesignStrokedButton,
    LinkingProjectComponent,
    LinkedProjectCardComponent,
    ImageInfoComponent,
    ButtonLoaderComponent
  ],
  templateUrl: './project-integration.component.html',
  styleUrl: './project-integration.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectIntegrationComponent {
  readonly connectingProject = signal<boolean>(false);
  readonly connectedButton = viewChild('connectedButton', {
    read: ElementRef<HTMLButtonElement>
  });
  private readonly _dialogRef = inject(DialogRef<TActionOnCloseModal>);
  private readonly _dialogData: TProcoreProjectIntegration =
    inject(DIALOG_DATA);
  private readonly _toastService = inject(ToastService);
  private readonly _apiProcoreService = inject(ApiProcoreService);
  private readonly _projectFacade = inject(ProjectsFacade);

  readonly selectedCompany = signal<number | undefined>(undefined);
  readonly selectedProject = signal<number | undefined>(undefined);

  readonly loadedCompanies = signal<boolean>(false);
  readonly companies = toSignal(
    this._apiProcoreService
      .getCompanies$()
      .pipe(tap(() => this.loadedCompanies.set(true))),
    {
      initialValue: []
    }
  );

  get nameProject() {
    return this._dialogData.name;
  }

  get stagesCompanyName() {
    return this._dialogData.companyName;
  }

  get infoString() {
    return `${this.nameProject} and Procore ${this.selectedProcoreProjectName()} are connected!`;
  }

  readonly selectedProcoreCompanyName = computed(() => {
    const selectedCompanyId = this.selectedCompany();
    const companies = this.companies();
    if (!selectedCompanyId || companies === undefined) return '';

    return companies.find(
      (company: Company) => company.id === selectedCompanyId
    )!.name;
  });

  readonly selectedProcoreProjectName = computed(() => {
    const selectedProjectId = this.selectedProject();
    const projects = this.projects();
    if (!selectedProjectId || projects === undefined) return '';

    return projects.find(
      (project: Project) => project.id === selectedProjectId
    )!.name;
  });

  readonly disabledButton = computed(() => {
    return (
      this.selectedCompany() === undefined ||
      this.selectedProject() === undefined
    );
  });

  readonly projects: Signal<Project[]> = derivedAsync(
    () => {
      const selectedCompany = this.selectedCompany();
      if (selectedCompany) {
        return this._apiProcoreService
          .getCompanyProjects$(selectedCompany)
          .pipe(
            map((projects) => projects.filter((project) => project.canConnect))
          );
      }
      return [];
    },
    { requireSync: true }
  );

  private readonly _connectedButton$ = toObservable(this.connectedButton);
  readonly connectingProjectAction = toSignal(
    this._connectedButton$.pipe(
      filter(
        (connectbtn): connectbtn is ElementRef<HTMLButtonElement> =>
          connectbtn !== undefined
      ),
      switchMap((connectbtn) =>
        fromEvent(connectbtn.nativeElement, 'click').pipe(
          switchMap(() => {
            this.connectingProject.set(true);
            const procoreCompanyId = this.selectedCompany()!;
            const procoreProjectId = this.selectedProject()!;
            const projectId = this._dialogData.id;
            return this._apiProcoreService
              .assignProcoreProject$({
                projectId,
                procoreProjectId,
                procoreCompanyId
              })
              .pipe(
                tap(() => {
                  this._projectFacade.assignProcoreProject(projectId, {
                    projectId: procoreProjectId,
                    companyId: procoreCompanyId,
                    projectName: this.selectedProcoreProjectName(),
                    companyName: this.selectedProcoreCompanyName()
                  });
                }),
                catchError((e) => {
                  this._toastService.open(e.error.errorMessage, 'Error');
                  return of(false);
                }),
                finalize(() => this.connectingProject.set(false)),
                take(1)
              );
          })
        )
      )
    )
  );

  readonly successConnected = computed(() => {
    const connectingProject = this.connectingProjectAction();
    if (connectingProject !== false && connectingProject !== undefined) {
      this._toastService.open(
        $localize`:@@NAME_PROJECT_CONNECTED_WITH_NAME_SECOND_PROJECT:${this.nameProject} is connected with ${this.selectedProcoreProjectName()}.`,
        'Success'
      );
      const stage: ProjectCard = {
        logoUrl: 'assets/icons/logo_text.svg',
        companyName: this.stagesCompanyName,
        projectName: this.nameProject,
        status: 'CONNECTED'
      };

      const procore: ProjectCard = {
        logoIcon: 'icon_procore_logo',
        companyName: this.selectedProcoreCompanyName(),
        projectName: this.selectedProcoreProjectName(),
        status: 'CONNECTED'
      };

      return { state: true, stage, procore };
    }

    return { state: false };
  });

  backTo() {
    this._dialogRef.close({
      action: 'BackTo',
      state: this.successConnected().state
    });
  }
  close() {
    this._dialogRef.close({
      action: 'Close',
      state: this.successConnected().state
    });
  }
}
