@if (selected(); as stage) {
  <div class="switch-container">
    <button ui-mini-fab-button data-cy="timeline-toogle" (click)="toggle()">
      <i
        ui-font
        fontName="arrow-big"
        fontSize="1.5rem"
        [fontRotateBy]="state() ? 90 : -90"
      ></i>
    </button>
  </div>
  <div class="timeline-content" [@openClose]="state() ? 'open' : 'closed'">
    <div class="timeline-content__wrapper">
      <h2 class="timeline-content__wrapper--header">
        {{ stage.name }}
      </h2>
      <simlab-timeline-condensed-draggable
        (selectionChange)="selectionChange($event)"
      >
      </simlab-timeline-condensed-draggable>
    </div>
  </div>
}
