import { InjectionToken } from '@angular/core';
import { SystemOfMeasurement } from '@simlab/data-access';
import { TypeMeasurement } from '../models/src/common-models';
import {
  TDisplayValueFunction,
  TParamsToSortFunction
} from '../models/src/display-value';
import { DistanceConversion } from '../models/src/distance-conversion';
import { SortOption } from '../ui/src/search-and-sort-filter/search-and-sort-filter.component';

export const TYPE_MEASUREMENT = new InjectionToken<TypeMeasurement>(
  'TYPE_MEASUREMENT'
);

export const DISPLAY_VALUE = new InjectionToken<TDisplayValueFunction>(
  'DISPLAY_SHOW'
);
export const PARAMS_TO_SORT = new InjectionToken<TParamsToSortFunction>(
  'PARAMS_TO_SORT'
);
export const MEASUREMENT_CONVERSION = new InjectionToken<
  Record<SystemOfMeasurement, DistanceConversion>
>('PARAMS_TO_SORT');

export const SORT_OPTION = new InjectionToken<SortOption[]>('SORT_OPTION');
