import { inject, TemplateRef } from '@angular/core';
import { AbstractConstructor, Constructor } from '@simlab/design/internal';
import {
  documentsApiToken,
  DocumentsInstanceDataToken
} from '@simlab/documents/data-access';
import { mapIds } from '@simlab/documents/functions';
import {
  BlobsPackingStatus,
  DocumentComponentsLoadedState,
  TDocumentsToZipStatus
} from '@simlab/documents/models';
import { TEventElementItem } from '@simlab/event-queue';
import { TEventQueueItem } from '@simlab/event-queue/models';
import { QueueService } from '@simlab/event-queue/services';
import { DownloadFilesService, ToastEventService } from '@simlab/util-shared';
import {
  catchError,
  delay,
  firstValueFrom,
  of,
  retry,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';

const BLOBS_PACKING_FAILURE = $localize`:@@BLOBS_PACKING_FAILURE:File zipping failed`;
const BLOBS_PACKING_UNKNOWN = $localize`:@@BLOBS_PACKING_UNKNOWN:File zipping return unknown status`;
const BLOBS_PACKING_SUCCESS = $localize`:@@BLOBS_PACKING_SUCCESS:Downloading files`;
const DOWNLOADING_FILES = $localize`:@@DOWNLOADING_FILES:Downloading files`;
const PACKING_FILES = $localize`:@@PACKING_FILES:Packing files`;

export type CanDownload = {
  downloadFiles(
    elements: DocumentComponentsLoadedState[],
    template: TemplateRef<any> | undefined,
    parentDocumentId?: string | number
  ): void;
};

type TCanDownload = Constructor<CanDownload> & AbstractConstructor<CanDownload>;
export function mixinDownloadDocument<T extends AbstractConstructor<any>>(
  base: T
): TCanDownload & T;
export function mixinDownloadDocument<T extends Constructor<any>>(base: T) {
  return class extends base {
    private readonly _api = inject(documentsApiToken);
    private readonly _documentsInstanceDataToken = inject(
      DocumentsInstanceDataToken
    );
    private readonly _queueService = inject(QueueService);
    private readonly _toastEventService = inject(ToastEventService);
    private readonly _downloadFilesService = inject(DownloadFilesService);

    downloadFiles(
      elements: DocumentComponentsLoadedState[],
      template: TemplateRef<any> | undefined,
      parentDocumentId?: string | number
    ) {
      if (elements.length === 1 && !elements[0].isDirectory) {
        this._downloadOneFile(elements[0]);
      } else {
        this._downloadDirectoryOrMultipleFiles(
          elements,
          template,
          parentDocumentId
        );
      }
    }

    private _downloadOneFile(element: DocumentComponentsLoadedState) {
      firstValueFrom(
        this._api.getResourcesDownloadUrls$([element.id]).pipe(
          switchMap((response) =>
            this._downloadFilesService.downloadFile(response[0], element.name)
          ),
          catchError((e) => {
            console.error(e);
            return e;
          })
        )
      );
    }

    private _downloadDirectoryOrMultipleFiles(
      elements: DocumentComponentsLoadedState[],
      template: TemplateRef<any> | undefined,
      parentDocumentId?: string | number
    ) {
      const directoryIds = mapIds(elements, (element) => element.isDirectory);
      const documentIds = mapIds(elements, (element) => !element.isDirectory);
      const title = PACKING_FILES;
      const destroy = new Subject<void>();
      const context: TEventElementItem = {
        title,
        currentElement: 0,
        countOfElements: 100,
        status: {
          workStatus: 'WORKING',
          showErrorDetails: false,
          failed: []
        },
        cancel: () => destroy.next()
      };
      const projectId = this._documentsInstanceDataToken.projectId();
      let id = '';
      id = this._queueService.addEvent({
        type: 'PACK-DOCUMENTS-TO-ZIP',
        templateRef: template,
        context,
        execute$: this._api
          .packDocumentsToZip$({
            projectId,
            directoryIds,
            documentIds,
            parentDocumentId
          })
          .pipe(
            switchMap(({ taskId }) =>
              this._api.getPackDocumentsToZipStatus$(taskId).pipe(
                delay(500),
                tap(({ progress, status }) => {
                  context.currentElement = progress || 0;
                  this._queueService.updateView(id, context);
                  if (
                    status === BlobsPackingStatus.Processing ||
                    status === BlobsPackingStatus.Waiting
                  ) {
                    throw Error('Pending, try again');
                  }
                }),
                retry(),
                tap(({ notFoundFilePaths }) => {
                  context.currentElement = 100;
                  context.title = DOWNLOADING_FILES;
                  if (notFoundFilePaths) {
                    context.status.showErrorDetails = true;
                    context.status.failed = notFoundFilePaths;
                    context.status.workStatus = 'COMPLETED_WITH_FAILURES';
                  }
                  this._queueService.updateView(id, context);
                }),
                catchError((e) => {
                  this._failureStat(id, context);
                  return of(e);
                })
              )
            ),
            takeUntil(destroy),
            catchError((e) => {
              this._failureStat(id, context);
              return of(e);
            })
          ),
        start: this._jobDownloadStart,
        end: this._jobDownloadEnd
      });
    }

    protected _jobDownloadStart = (
      e: TEventQueueItem<TDocumentsToZipStatus, TEventElementItem>
    ) => {};
    protected _jobDownloadEnd = (
      e: TDocumentsToZipStatus,
      event: TEventQueueItem<TDocumentsToZipStatus, TEventElementItem>
    ) => {
      const viewId = event.id!;
      const context: TEventElementItem = this._queueService.getViewContext(
        event.id!
      );

      switch (e.status) {
        case BlobsPackingStatus.Failure:
          this._failureStat(viewId, context);
          break;
        case BlobsPackingStatus.Finished:
          this._finishedStat(viewId, context);
          window.location.href = e.resultUrl!;
          break;
        case BlobsPackingStatus.Unknown:
          this._toastEventService.addEvent({
            action: of('Info'),
            message: {
              Info: BLOBS_PACKING_UNKNOWN
            },
            performOnce: true
          });
          break;
      }
    };

    private _failureStat(id: string, context: TEventElementItem) {
      this._queueService.updateView(id, {
        ...context,
        status: {
          ...context.status,
          showErrorDetails: false,
          workStatus: 'COMPLETED_WITH_FAILURES'
        }
      });

      this._toastEventService.addEvent({
        action: of('Info'),
        message: {
          Info: BLOBS_PACKING_FAILURE
        },
        performOnce: true
      });
    }

    private _finishedStat(id: string, context: TEventElementItem) {
      this._queueService.updateView(id, {
        ...context,
        status: {
          ...context.status,
          workStatus: 'COMPLETED'
        }
      });

      this._toastEventService.addEvent({
        action: of('Success'),
        message: {
          Success: BLOBS_PACKING_SUCCESS
        },
        performOnce: true
      });
    }
  };
}
