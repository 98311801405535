<documents-root>
  <documents-action-panel>
    <feature-projects-documents-stages-documents-filters
      filters
      [formsValue]="filters()"
    />
  </documents-action-panel>
  <documents-selected-item-action-panel>
    <button
      design-icon-button
      type="button"
      (click)="downloadDocuments()"
      class="action-btn"
    >
      <i
        designIcon="file_download"
        designSize="24px"
        class="action-btn__icon"
      ></i>
    </button>
  </documents-selected-item-action-panel>

  <design-empty-placeholder
    emptyListSlot
    class="empty-list-slot"
    illustration="add_folder_illustration"
  >
    <ng-container titleSlot i18n="@@ADD_DOCUMENTS">Add documents</ng-container>
    <ng-container descriptionSlot>
      <ng-container i18n="@@START_ADDING_YOUR_DOCUMENTS">
        Start adding your documents.
      </ng-container>
      <br />
      <ng-container
        i18n="@@DROP_FILES_OR_FOLDERS_TO_UPLOAD_THEM_OR_USE_A_BUTTONS_BELOW"
        >Drop files or folders to upload them or use a buttons
        below.</ng-container
      >
    </ng-container>
  </design-empty-placeholder>
</documents-root>
