<div class="feature-stages-measurement-item__description">
  <span class="feature-stages-measurement-item__description--surface-size"
    >{{ measurement().displayValue }}
  </span>
  <span class="feature-stages-measurement-item__description--title">{{
    measurement().title || '-'
  }}</span>
</div>
<div class="feature-stages-measurement-item__button-group">
  <ng-template [ngTemplateOutlet]="suffix() || empty"></ng-template>
  <ng-template #empty></ng-template>
</div>
