import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

type CanDeactivate = {
  canDeactivate: () => boolean;
};
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class PanelsClosedGuard {
  canDeactivate(component: CanDeactivate): boolean | Observable<boolean> {
    try {
      return component.canDeactivate ? component.canDeactivate() : true;
    } catch {
      //NOTE: fix for component being null for some unknown reason..
      return true;
    }
  }
}
