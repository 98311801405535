import { Pipe, PipeTransform } from '@angular/core';
import {
  DistanceConversion,
  SystemOfMeasurement
} from '@simlab/feature-measurement/models';
import { UnitsPreferredByUserPipe } from './base-units-preferred.pipe';

export const DISTANCE_CONVERSION: Record<
  SystemOfMeasurement,
  DistanceConversion
> = {
  [SystemOfMeasurement.Metric]: {
    fn: function (distance: number): number {
      if (!distance) return 0;
      return distance;
    },
    unit: 'm',
    segmentUnit: 'm'
  },
  [SystemOfMeasurement.Imperial]: {
    fn: function (distance: number): number {
      if (!distance) return 0;
      return distance * 10.7639104;
    },
    unit: 'ft',
    segmentUnit: 'ft'
  }
};

@Pipe({
  name: 'unitsPreferredByTheUser',
  standalone: true
})
export class UnitsDistancePreferredByUserPipe
  extends UnitsPreferredByUserPipe
  implements PipeTransform
{
  transform(value: number, units: SystemOfMeasurement): number {
    return DISTANCE_CONVERSION[units].fn(value);
  }
}
