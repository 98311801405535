<div
  class="mention-wrapper"
  [style.--mention-height]="rows"
  designMention
  #designMention="designMention"
  [mentionTemplate]="mentionTemplate"
  [mentionData]="mentionData"
  [placeholder]="placeholder"
  [externalTagName]="externalTagName"
  [readonly]="readonly"
  [value]="value"
  (contentChange)="contentChange($event)"
  (enterPressed)="enterPressed.emit($event)"
></div>
<ng-template #mentionTemplate let-value="value" let-name="name">
  <user-link [value]="value">{{ name }}</user-link>
</ng-template>
