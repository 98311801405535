import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  ViewEncapsulation
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet
} from '@angular/router';
import { DesignStrokedButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { DocumentsInstanceDataToken } from '@simlab/documents/data-access';
import { UiDividerModule } from '@simlab/ui/divider';

import { UiTabsModule } from '@simlab/design/tabs';
import { canRenderProcoreEntity } from '@simlab/procore/data-access';
import { filter, map, startWith } from 'rxjs';

const documentInstance = {
  'documents/stages': 0,
  'documents/procore': 1
} as const;
type DocumentInstanceIndex =
  (typeof documentInstance)[keyof typeof documentInstance];

@Component({
  selector: 'feature-projects-documents-page',
  standalone: true,
  imports: [
    RouterModule,
    UiTabsModule,
    UiDividerModule,
    DesignStrokedButton,
    DesignIcon,
    RouterOutlet
  ],
  templateUrl: './documents-page.component.html',
  styleUrls: ['./documents-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'feature-projects-documents-page'
  },
  providers: [
    {
      provide: DocumentsInstanceDataToken,
      useExisting: DocumentsPageComponent
    }
  ]
})
export class DocumentsPageComponent {
  private readonly _router = inject(Router);

  protected readonly currentSelectedInstance = toSignal(
    this._router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => event.url),
      startWith(this._router.url),
      map((currentUrl) => {
        for (const [url, index] of Object.entries(documentInstance)) {
          if (currentUrl.includes(url)) return index;
        }

        return undefined;
      }),
      filter(
        (
          currentSelectedInstance
        ): currentSelectedInstance is DocumentInstanceIndex =>
          currentSelectedInstance !== undefined
      )
    )
  );

  readonly projectConnectedWithProcore = toSignal(
    canRenderProcoreEntity(inject(ActivatedRoute).snapshot)
  );
  readonly projectId = input.required<string>();
}
