<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel i18n="@@PROJECT_INFO">Project info</h2>
  </ng-container>
  <ng-container designDialogContent>
    <ng-container
      *ngTemplateOutlet="mainInformation; context: { form: formGroup }"
    ></ng-container>

    <ui-divider class="container__content--divider"></ui-divider>
    <ng-container *ngTemplateOutlet="projectContent"></ng-container>

    <ui-divider class="container__content--divider"></ui-divider>
    <div class="container__google-map">
      @if (project.place?.latitude !== undefined || canEditProjectInfo()) {
        <ui-map
          *ngIf="googleApi$ | async"
          class="google-map"
          [readonly]="!canEditProjectInfo()"
          [address]="addressControl?.value"
          [geolocation]="geolocationControl?.value"
          (coordinatesEmitter)="setCoordinates($event)"
        ></ui-map>
      } @else {
        <ui-image-info
          imageSrc="icon_no_location"
          i18n-header="@@NO_LOCATION"
          header="No location"
          [descriptions]="descriptionImageInfo"
          [maxWidth]="200"
        ></ui-image-info>
      }
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-stroked-button
      designButtonColor="primary"
      (click)="cancel()"
    >
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    @if (canEditProjectInfo()) {
      <button
        design-flat-button
        designButtonColor="accent"
        [disabled]="formGroup.valid === false"
        (click)="submitForm()"
      >
        <div i18n="@@SAVE_CHANGES">Save Changes</div>
      </button>
    }
  </ng-container>
</design-dialog-wrapper>

<ng-template #mainInformation [formGroup]="formGroup">
  <div class="information__content">
    <h3 class="information__content--section-title">
      <ng-container i18n="@@MAIN_INFORMATION">Main information</ng-container>
    </h3>
    <div class="information__content__mobile-procore">
      <design-tooltip
        class="information__content__mobile-procore--flex-1"
        designTooltipOnClick
        [uiFireOnClick]="copyProjectId$"
        i18n-tooltip="@@COPIED"
        tooltip="Copied"
        position="bottom"
      >
        <button
          class="copy-btn"
          class="information__content__mobile-procore--width-100"
          design-stroked-button
          designButtonColor="primary"
          data-cy="project-copy-id"
          type="button"
        >
          <div i18n="@@COPY_PROJECT_ID">Copy Project ID</div>
        </button>
      </design-tooltip>
      @if (hasProcoreToken() || procoreConnectStatusConnected()) {
        <procore-connect-project-info
          class="information__content__mobile-procore--flex-1"
          [projectId]="projectId"
          [connectedStatus]="procoreConnectStatus()"
          [connectionInfo]="procoreConnectionInfo()"
          (onConnectClick)="connectProject()"
          (onDetailsProject)="openDetailsProcore()"
          (onRedistributeData)="closeWithRefreshProcoreStateRequest()"
          [permissionToManage]="canConnectProcore()"
          [hasAdminInStageProject]="hasAdminInStageProject()"
        />
      }
    </div>

    @if (hasProcoreToken() || procoreConnectStatusConnected()) {
      <procore-connect-project-info
        class="information__content--procore-no-mobile"
        [projectId]="projectId"
        [connectedStatus]="procoreConnectStatus()"
        [connectionInfo]="procoreConnectionInfo()"
        (onConnectClick)="connectProject()"
        (onDetailsProject)="openDetailsProcore()"
        (onRedistributeData)="closeWithRefreshProcoreStateRequest()"
        [permissionToManage]="canConnectProcore()"
        [hasAdminInStageProject]="hasAdminInStageProject()"
      />
    }
    <ui-form-field class="information__content--project-title">
      <label i18n="@@PROJECT_TITLE">PROJECT TITLE</label>
      <ui-input
        formControlName="name"
        i18n-placeholder="@@TITLE"
        placeholder="Title"
        [notEditable]="!canEditProjectInfo()"
      ></ui-input>
      <ui-hint uiRawColor="red"> </ui-hint>
    </ui-form-field>
    <ui-form-field class="information__content--photo">
      <label i18n="@@PHOTO">Photo</label>
      <ui-upload-image
        [disabled]="!canEditProjectInfo()"
        (selected)="handleImageReady($event)"
        class="information__content__image-upload"
      >
        <div
          *ngIf="imageSource$ | async as imageSource; else noImage"
          [style.backgroundImage]="'url(' + imageSource + ')'"
          class="information__content__image-upload--image"
        ></div>
        <ng-template #noImage>
          <i ui-icon name="icon_stages" size="img"></i>
        </ng-template>
        @if (canEditProjectInfo()) {
          <div class="information__content__image-upload--overlay"></div>
        }
      </ui-upload-image>
      <ui-hint
        i18n-message="@@ONLY_EXTENSION"
        message="You can add only .jpg, .jpeg, .png, .bmp"
        [hidden]="!notSupportedFile"
      >
      </ui-hint>
    </ui-form-field>
    <ui-form-field class="information__content--description">
      <label i18n="@@PROJECT_DESCRIPTION">Project description</label>
      <ui-textarea
        [realtime]="true"
        formControlName="description"
        i18n-placeholder="@@DESCRIPTION"
        placeholder="Description"
        [minRows]="6"
        [notEditable]="canEditProjectInfo()"
        [class.ui-textarea-notEditable]="!canEditProjectInfo()"
      ></ui-textarea>
      <ui-hint uiRawColor="red"> </ui-hint>
    </ui-form-field>

    <design-tooltip
      class="information__content--copy"
      designTooltipOnClick
      [uiFireOnClick]="copyProjectId$"
      i18n-tooltip="@@COPIED"
      tooltip="Copied"
      position="right"
    >
      <button
        class="copy-btn"
        design-stroked-button
        designButtonColor="primary"
        data-cy="project-copy-id"
        type="button"
      >
        <div i18n="@@COPY_PROJECT_ID">Copy Project ID</div>
      </button>
    </design-tooltip>
  </div>
</ng-template>

<ng-template #projectContent>
  <div
    class="project-content"
    [class.project-content-notEditable]="canEditProjectInfo()"
  >
    <h3 i18n="@@PROJECT_CONTENT">Project Content</h3>
    <div class="project-content__body">
      <div class="project-content__body__main-info">
        <div>
          <span i18n="@@STAGES">Stages</span>
          <span>:</span>
          <span>{{ project.projectObjectsCounts.stagesCount }}</span>
        </div>
        <div class="project-content__body__main-info--space">|</div>
        <div>
          <span i18n="@@NOTES_TOTAL">Notes Total</span><span>:</span>
          <span>
            {{
              (project.projectObjectsCounts.rootNotesCounts!
                | noteCount: 'None') +
                (project.projectObjectsCounts.rootNotesCounts!
                  | noteCount: 'Pending') +
                (project.projectObjectsCounts.rootNotesCounts!
                  | noteCount: 'Unresolved') +
                (project.projectObjectsCounts.rootNotesCounts!
                  | noteCount: 'Resolved') +
                (project.projectObjectsCounts.rootNotesCounts!
                  | noteCount: 'InProgress')
            }}
          </span>
        </div>
      </div>
      <div class="project-content__body__notes">
        <div
          class="project-content__body__notes__item project-content__body__notes__item--info"
        >
          <i
            class="project-content__body__notes__item__ic"
            ui-icon
            name="icon_note_info"
            size="2x"
            defaultColor
          >
          </i>
          <div class="project-content__body__notes__item__text">
            <ng-container i18n="@@INFO">Info</ng-container>
          </div>
          <div class="project-content__body__notes__item__count">
            <span i18n="@@X_NOTES">
              {{
                project.projectObjectsCounts.rootNotesCounts!
                  | noteCount: 'None'
              }}
              Notes
            </span>
          </div>
        </div>

        <div
          class="project-content__body__notes__item project-content__body__notes__item--pending"
        >
          <i
            class="project-content__body__notes__item__ic"
            ui-icon
            name="icon_note_pending"
            size="2x"
            defaultColor
          >
          </i>
          <div class="project-content__body__notes__item__text">
            <ng-container i18n="@@PENDING">Pending</ng-container>
          </div>
          <div class="project-content__body__notes__item__count">
            <span i18n="@@X_NOTES">
              {{
                project.projectObjectsCounts.rootNotesCounts!
                  | noteCount: 'Pending'
              }}
              Notes
            </span>
          </div>
        </div>

        <div
          class="project-content__body__notes__item project-content__body__notes__item--inProgress"
        >
          <i
            class="project-content__body__notes__item__ic"
            ui-icon
            name="icon_note_in_progress"
            size="2x"
            defaultColor
          >
          </i>
          <div class="project-content__body__notes__item__text">
            <ng-container i18n="@@IN_PROGRESS">In Progress</ng-container>
          </div>
          <div class="project-content__body__notes__item__count">
            <span i18n="@@X_NOTES">
              {{
                project.projectObjectsCounts.rootNotesCounts!
                  | noteCount: 'InProgress'
              }}
              Notes
            </span>
          </div>
        </div>

        <div
          class="project-content__body__notes__item project-content__body__notes__item--unresolved"
        >
          <i
            class="project-content__body__notes__item__ic"
            ui-icon
            name="icon_note_unresolved"
            size="2x"
            defaultColor
          >
          </i>
          <div class="project-content__body__notes__item__text">
            <ng-container i18n="@@UNRESOLVED">Unresolved</ng-container>
          </div>
          <div class="project-content__body__notes__item__count">
            <span i18n="@@X_NOTES">
              {{
                project.projectObjectsCounts.rootNotesCounts!
                  | noteCount: 'Unresolved'
              }}
              Notes
            </span>
          </div>
        </div>

        <div
          class="project-content__body__notes__item project-content__body__notes__item--resolved"
        >
          <i
            class="project-content__body__notes__item__ic"
            ui-icon
            name="icon_note_resolved"
            size="2x"
            defaultColor
          >
          </i>
          <div class="project-content__body__notes__item__text">
            <ng-container i18n="@@RESOLVED">Resolved</ng-container>
          </div>
          <div class="project-content__body__notes__item__count">
            <span i18n="@@X_NOTES">
              {{
                project.projectObjectsCounts.rootNotesCounts!
                  | noteCount: 'Resolved'
              }}
              Notes
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
