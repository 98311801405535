import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  TemplateRef,
  viewChild
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import {
  CUSTOM_ROOT_FOLDER,
  documentsActionsToken,
  documentsApiToken,
  FolderAsPathItem
} from '@simlab/documents/data-access';
import {
  DocumentsStateService,
  DocumentsStore
} from '@simlab/documents/services';
import {
  DocumentActionPanelComponent,
  DocumentActionsDirective,
  DocumentsRootComponent,
  DocumentsSelectedItemActionPanelComponent
} from '@simlab/documents/ui';
import { UiEventElementComponent } from '@simlab/event-queue';
import { ProcoreDownloadResourceGetterService } from '@simlab/procore/services';
import { ProcoreDocumentsStateService } from '../../../../../services/procore-documents-state.service';
import { ProcoreInModalSelectStateService } from '../../services/procore-in-modal-select-state.service';
import { ProcorePicturesApiService } from './../../../../../data-access/procore-pictures-api.service';
import {
  ProcorePicturesInModalActionsBase,
  ProcorePicturesInModalActionsService
} from './data-access/procore-pictures-in-modal-actions/procore-pictures-in-modal-actions.service';

@Component({
  selector: 'feature-projects-procore-pictures-in-modal',
  standalone: true,
  imports: [
    DocumentsRootComponent,
    DocumentActionsDirective,
    DocumentActionPanelComponent,
    DocumentsSelectedItemActionPanelComponent,
    DesignIcon,
    DesignIconButton,
    UiEventElementComponent
  ],
  templateUrl: './procore-pictures-in-modal.component.html',
  styleUrl: './procore-pictures-in-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProcoreDownloadResourceGetterService,
    { provide: documentsApiToken, useClass: ProcorePicturesApiService },
    DocumentsStore,
    DocumentsStateService,
    {
      provide: CUSTOM_ROOT_FOLDER,
      useFactory: () => {
        const state = inject(ProcoreInModalSelectStateService);
        return [
          {
            id: '1',
            name: 'Procore',
            clickHandler: () => state.setSelectedState(undefined)
          },
          {
            id: '2',
            name: 'Pictures',
            clickHandler: () => state.setSelectedState('pictures')
          }
        ] as FolderAsPathItem[];
      }
    },
    ProcoreDocumentsStateService,
    {
      provide: documentsActionsToken,
      useClass: ProcorePicturesInModalActionsService
    }
  ]
})
export class ProcorePicturesInModalComponent implements AfterViewInit {
  private readonly _actions = inject<ProcorePicturesInModalActionsBase>(
    documentsActionsToken
  );
  protected readonly _rootComponent = viewChild.required(
    DocumentsRootComponent
  );
  readonly downloadTask = viewChild('downloadTask', {
    read: TemplateRef<{ context: { taskId: string; progress: number } }>
  });

  readonly selectedItems = computed(() =>
    this._rootComponent().selectedItems()
  );

  ngAfterViewInit(): void {
    this._actions.templateToDownload = this.downloadTask();
  }

  protected downloadDocuments() {
    const data = this.selectedItems().map((item) => item.data().data);

    this._actions.download(data);
  }

  resetSelectedItems(): void {
    this._rootComponent().clearSelection();
  }
}
