import { Dialog } from '@angular/cdk/dialog';
import { Platform } from '@angular/cdk/platform';
import { inject, Injectable } from '@angular/core';
import { UserPreferenceFacade } from '@simlab/data-store';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  ProcoreAuthorization,
  ProcoreAuthorizationResponse
} from '@simlab/procore/models';
import {
  Environment,
  ENVIRONMENT_CONFIG,
  OpenNewTabSafariComponent
} from '@simlab/util-shared';
import {
  catchError,
  delay,
  filter,
  fromEvent,
  map,
  Observable,
  of,
  race,
  Subject,
  switchMap,
  tap
} from 'rxjs';

const TIME_WAIT_LOGOUT = 2000;

@Injectable()
export class ProcoreAuthorizationService {
  private readonly _logoutProcoreUrl =
    inject(ENVIRONMENT_CONFIG).configuration.procore.logout;
  private _windowRef!: Window | null;
  private readonly _onWindowClose: Subject<void> = new Subject<void>();
  private _api: ApiProcoreService = inject(ApiProcoreService);
  private readonly _dialog = inject(Dialog);
  private readonly _platform = inject(Platform);
  private _environments: Environment = inject(ENVIRONMENT_CONFIG);
  private readonly _userFacade = inject(UserPreferenceFacade);

  readonly userConnected$: Observable<boolean> =
    this._api.hasUserProcoreToken();
  readonly disconnect$: Observable<void> = this._api
    .removeProcoreUserToken()
    .pipe(tap(() => this._userFacade.updateUserProcoreConnectionStatus(false)));

  windowAuthorization$(value: string) {
    const timer = setInterval(() => {
      if (this._windowRef?.closed) {
        this._onWindowClose.next();
        clearInterval(timer);
      }
    }, 1000);
    return of(value).pipe(
      tap(() => {
        this._windowRef = window.open(
          this._logoutProcoreUrl,
          'OAuth',
          'width=800,height=800'
        );
      }),
      delay(TIME_WAIT_LOGOUT),
      tap(() => {
        this._windowRef!.location.href = value;
      }),
      switchMap(() =>
        race(
          fromEvent(window, 'message').pipe(
            filter(
              (e: any) =>
                e.source === this._windowRef && e.data.type === 'auth_procore'
            ),
            map((e: any) => {
              if (!this._windowRef) return;

              const { state, code } = e.data;

              this._windowRef?.close();
              clearInterval(timer);
              return {
                status: 'SUCCESS',
                response: { state, code }
              } as ProcoreAuthorizationResponse<ProcoreAuthorization>;
            })
          ),
          this._onWindowClose.asObservable().pipe(
            tap((e) => {
              console.log(e);
            }),
            map(
              () =>
                ({
                  status: 'CLOSE',
                  response: undefined
                }) as ProcoreAuthorizationResponse<ProcoreAuthorization>
            )
          )
        )
      )
    );
  }

  fullAuthorization$() {
    return this._api
      .getProcoreAuthorizationCodeUrl(
        this._environments.configuration.procore.redirectUrl
      )
      .pipe(
        switchMap((url) => this.modalToOpenForSafari(url)),
        switchMap((url) => this.windowAuthorization$(url)),
        switchMap(
          (
            e: ProcoreAuthorizationResponse<ProcoreAuthorization> | undefined
          ) => {
            if (e && e.status === 'SUCCESS' && e.response) {
              const { code, state } = e.response;

              return this._api
                .addProcoreUserToken({
                  code,
                  state,
                  redirectUrl:
                    this._environments.configuration.procore.redirectUrl
                })
                .pipe(
                  tap(() =>
                    this._userFacade.updateUserProcoreConnectionStatus(true)
                  ),
                  map(() => e.status)
                );
            }

            return of(e ? e.status : 'CLOSE');
          }
        ),
        catchError((e) => {
          console.error(e);
          return of(e);
        })
      );
  }

  private modalToOpenForSafari(redirectUlr: string): Observable<string> {
    return of(true).pipe(
      switchMap(() => {
        if (this._platform.SAFARI) {
          const dialogRef = this._dialog.open<
            boolean,
            string,
            OpenNewTabSafariComponent
          >(OpenNewTabSafariComponent, {
            width: 'min(90%, 400px)',
            data: this._environments.configuration.procore.logout
          });

          return dialogRef.closed.pipe(
            map((e) => {
              if (e) {
                this._windowRef = window.open(
                  this._environments.configuration.procore.logout,
                  'OAuth',
                  'width=800,height=800'
                );
              }
              return redirectUlr;
            })
          );
        } else {
          this._windowRef = window.open(
            this._environments.configuration.procore.logout,
            'OAuth',
            'width=800,height=800'
          );
          return of(redirectUlr);
        }
      }),
      delay(TIME_WAIT_LOGOUT)
    );
  }
}
