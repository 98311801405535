import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { DesignRadioAccordion } from '@simlab/design/accordion';

@Component({
  selector: 'feature-stages-stage-list',
  templateUrl: './stage-list.component.html',
  styleUrls: ['./stage-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DesignRadioAccordion],
})
export class StageListComponent {
  // @Output() selected = new EventEmitter<string>();

  @HostListener('click', ['$event'])
  handleClick(event: PointerEvent) {
    const element = event.target as HTMLElement;
    if (element?.id && element.getAttribute('aria-selected') === 'false') {
      // this.selected.emit(element.id);
    }
  }
}
