import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UiIconModule } from '../ui-icon/ui-icon.module';
import { SliderColorModule } from './components/slider-color/slider-color.module';

export type TYPE_SLIDER = 'triangle' | 'color';

@Component({
  selector: 'ui-slider',
  templateUrl: './ui-slider.component.html',
  styleUrls: ['./ui-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SliderColorModule, UiIconModule],
})
export class UiSliderComponent {
  @Output() color: EventEmitter<string> = new EventEmitter();
  @Output() changeColorOfBrush: EventEmitter<string> = new EventEmitter();
  @Output() value: EventEmitter<number> = new EventEmitter();

  @Input() rangeMin?: number = 0;

  @Input() rangeStage?: number = 1;

  @Input() rangeMax?: number = 100;

  @Input() set defaultRange(value: number) {
    this.rangeValue = value;
  }
  rangeValue: number | undefined;

  @Input()
  set type(value: TYPE_SLIDER) {
    this._type = value;
  }
  get type(): TYPE_SLIDER {
    return this._type;
  }
  private _type: TYPE_SLIDER = 'triangle';

  trackInput(ev: Event): void {
    this.value.emit(Number((ev.target as HTMLInputElement).value));
  }
}
