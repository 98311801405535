@if (selectedNote(); as selectedNote) {
  @if (noteIcon(); as noteIcon) {
    <common-annotation-panel-root
      [name]="name()"
      [subName]="stageName()"
      [iconType]="noteIcon"
      [loading]="!isLoaded()"
      [formGroup]="formGroup"
    >
      <ng-template #nameTemplate>
        <input
          #noteName
          class="title-input"
          i18n-placeholder="@@NOTE_NAME_PLACEHOLDER"
          placeholder="Note name..."
          formControlName="name"
          data-cy="rp-note-name"
          (focusout)="focusOut()"
        />
      </ng-template>

      <ng-container headerContent>
        @if (noteNotification()) {
          <i
            designIcon="mark_email_read"
            designSize="20px"
            class="note-info__notification-icon"
          ></i>
        }
      </ng-container>

      <ui-divider class="note-info__divider" />

      <div class="note-info__basic-data">
        <div class="note-info-container">
          <i designIcon="users" class="note-info-container__logo"></i>
          <div>
            <div class="note-info-container__title">
              <h4 i18n="@@AUTHOR">Author</h4>
              <span>:</span>
            </div>

            <span class="note-info-container__value" data-cy="rp-note-author">
              {{ selectedNote.creatorName }}
            </span>
          </div>
        </div>

        <div class="note-info-container">
          <i designIcon="calendar" class="note-info-container__logo"></i>

          <div>
            <div class="note-info-container__title">
              <h4 i18n="@@CREATION_DATE">Creation date</h4>
              <span>:</span>
            </div>
            <span
              class="note-info-container__value"
              data-cy="rp-note-created-date"
            >
              {{ selectedNote.createdAt | formatDateSignal }}
            </span>
          </div>
        </div>

        <div class="note-info-container">
          <i designIcon="time" class="note-info-container__logo"></i>

          <div>
            <div class="note-info-container__title">
              <h4 i18n="@@MODIFICATION_DATE">Last modified</h4>
              <span>:</span>
            </div>

            <span
              class="note-info-container__value"
              data-cy="rp-note-last-modified"
            >
              {{ selectedNote.modifiedAt | formatDateSignal }}
            </span>
          </div>
        </div>
      </div>

      <common-annotation-panel-location-3d
        class="note-info__panel-location-3d"
        [positionExist]="selectedNote.marker !== undefined"
        [changeAndSetActionDisabled]="!addReplaceMarker()"
        (goToButtonClick)="moveTo()"
        (setLocationButtonClick)="assignNewLocation()"
        (changeLocationButtonClick)="reassignLocation()"
      />

      <div class="form__enum-types">
        <ui-form-field class="form__enum-types-element">
          <label i18n="@@NOTE_TYPE">Type</label>
          <ui-select formControlName="noteType" #type data-cy="rp-note-type">
            @for (note of noteType | keyvalue; track $index) {
              <ui-option [value]="note.key">{{ note.value }}</ui-option>
            }
          </ui-select>
        </ui-form-field>

        <ui-form-field class="form__enum-types-element">
          <label i18n="@@NOTE_STATUS">Status</label>
          <ui-select formControlName="noteStatus" data-cy="rp-note-status">
            @for (status of noteStatus | keyvalue; track $index) {
              <ui-option [value]="status.key">
                {{ status.value }}
              </ui-option>
            }

            @if (type.value === 'Information') {
              <ui-option value="None" i18n="@@NOTE_STATUS_NONE">
                None
              </ui-option>
            }
          </ui-select>
        </ui-form-field>
      </div>

      <ui-form-field class="flex-1">
        <label i18n="@@STAKEHOLDER">Stakeholder</label>
        <ui-select
          data-cy="rp-note-stakeholder"
          formControlName="stakeholderId"
          i18n-placeholder="@@STAKEHOLDER"
          [placeholder]="stakeholderPlaceholder()"
        >
          <ui-option class="stakeholder" [value]="">
            <div i18n="@@STAKEHOLDER">Stakeholder</div>
          </ui-option>
          @for (collaborator of collaborators(); track collaborator.id) {
            <ui-option [value]="collaborator.id">
              {{ collaborator.email }}
            </ui-option>
          }
        </ui-select>
      </ui-form-field>

      <ui-form-field class="flex-1">
        <ui-content-editable
          data-cy="rp-note-description"
          formControlName="description"
          i18n-placeholder="@@ADD_DESCRIPTION"
          placeholder="Add description"
        >
          <label i18n="@@NOTE_DESCRIPTION">DESCRIPTION</label>
        </ui-content-editable>
        <ui-hint
          uiRawColor="red"
          [validationErrors]="descriptionControl.errors"
          [hidden]="descriptionControl.valid"
        ></ui-hint>
      </ui-form-field>

      <section class="media">
        <feature-stages-note-media-container
          [projectId]="projectId()"
          [disabled]="!addEditDeleteOwnElements()"
        />
        @if (selectedNote.areaMeasurements.length) {
          <feature-area-measurement-attachment
            [noteId]="selectedNote.id"
            [stageId]="selectedNote.stageId"
            (goToAreaMeasurement)="goToAreaMeasurement()"
          />
        }

        @if (selectedNote.distanceMeasurements.length) {
          <feature-distance-measurement-attachment
            [noteId]="selectedNote.id"
            [stageId]="selectedNote.stageId"
            (goToAreaMeasurement)="goToAreaMeasurement()"
          />
        }
      </section>

      <section>
        <feature-stages-comment-list [maxCommentLength]="maxCommentLength" />
      </section>
    </common-annotation-panel-root>
  }
}
