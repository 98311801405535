<design-menu-panel customPanelClass="document-action-menu">
  @for (action of actionsList(); track action) {
    <button design-menu-item (click)="action.trigger(data())">
      <i [designIcon]="action.icon" designSize="20px"></i>
      {{ action.name }}
    </button>
  }
</design-menu-panel>

<ng-content select="button[type='button']"></ng-content>
