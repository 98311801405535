<ng-container *ngIf="selectedStage$ | async">
  <div class="component-header">
    <h1 i18n="@@STAGE_COMPONENTS">Stage Components</h1>
    <button
      design-flat-button
      [disabled]="((addEditDeleteOwnComponents$ | async) ?? true) === false"
      (click)="addComponent()"
      designButtonColor="secondary"
      data-cy="add-component-button"
    >
      + <span i18n="@@ADD_COMPONENT">Add component</span>
    </button>
  </div>
  <cdk-table
    [dataSource]="componentList$"
    class="full"
    [trackBy]="trackByComponentId"
    [formGroup]="componentsGroup"
  >
    <ng-container cdkColumnDef="icon">
      <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
      <cdk-cell *cdkCellDef="let element">
        <i
          [designIcon]="element.extension ? 'Jpg' : 'LinkBold'"
          class="component-icon"
          designSize="2rem"
        ></i>
      </cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="name">
      <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
      <cdk-cell *cdkCellDef="let element">
        <div class="component-name">
          <input
            [attr.data-cy]="'component-name-input-' + element.name"
            #inputRef
            [id]="element.id"
            [formControlName]="element.id"
            (change)="updateName($event, element)"
            (focus)="onFocus(inputRef, date)"
            (focusout)="stopEditing(element.id, date)"
            class="title-input"
          />
          <span #date>{{ element.createdAt | formatDate | async }}</span>
        </div>
      </cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="isSynchronizingAccepted">
      <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
      <cdk-cell *cdkCellDef="let element" class="cell-synchronized">
        <div>
          <div
            class="sync-info"
            data-cy="sync-info-div"
            [ngClass]="{ notsync: !element.isSynchronizingAccepted }"
          >
            <ng-container
              *ngIf="element.isSynchronizingAccepted; else notSynced"
            >
              <div i18n="@@SYNCED">Synced</div>
            </ng-container>
            <ng-template #notSynced>
              <div i18n="@@NOT_SYNCED">Not Synced</div>
            </ng-template>
          </div>
        </div>
      </cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="contextMenu">
      <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
      <cdk-cell *cdkCellDef="let element">
        <button
          design-icon-button
          [designMenuTriggerFor]="menu"
          data-cy="more-option-component-button"
          *ngIf="projectIsActive$ | async"
        >
          <i designIcon="more_second" fontSize="1.5rem"></i>
        </button>
        <design-menu-panel #menu>
          <button
            design-menu-item
            (click)="setAsSynchronized(element)"
            data-cy="set-as-synchronize-button"
            *ngIf="!element.isSynchronizingAccepted && !element.extension"
            [disabled]="
              ((canAccessSynchronizationPanel$ | async) ?? true) === false
            "
          >
            <ng-container i18n="@@SET_AS_SYNCHRONIZED">
              Set as synchronized
            </ng-container>
          </button>
          <button
            design-menu-item
            (click)="synchronizeComponent(element)"
            *ngIf="!element.isSynchronizingAccepted && !element.extension"
            [disabled]="
              ((canAccessSynchronizationPanelWithComponentsCheck$ | async) ??
                true) === false
            "
          >
            <ng-container i18n="@@SYNCHRONIZE"> Synchronize </ng-container>
          </button>
          <button
            design-menu-item
            *ngIf="element.isSynchronizingAccepted"
            (click)="setAsDesynchronized(element)"
            [disabled]="
              ((canAccessSynchronizationPanel$ | async) ?? true) === false
            "
            i18n="@@DELETE_SYNCHRONIZATION"
          >
            Delete synchronization
          </button>
          <button
            design-menu-item
            *ngIf="!element.extension"
            [disabled]="
              !element.isSynchronizingAccepted ||
              !element.componentUrl ||
              ((addEditDeleteElements$ | async) ?? true) === false
            "
            (click)="openTagImporter(element)"
            i18n="@@IMPORT_MATTERTAGS"
          >
            Import Mattertags
          </button>
          <button
            design-menu-item
            (click)="renameComponent(element.id)"
            [disabled]="((addEditDeleteElements$ | async) ?? true) === false"
            i18n="@@RENAME_COMPONENT"
            data-cy="rename-component-button"
          >
            Rename Component
          </button>
          <button
            design-menu-item
            (click)="removeComponent(element)"
            [disabled]="((addEditDeleteElements$ | async) ?? true) === false"
            i18n="@@DELETE_COMPONENT"
            data-cy="delete-component-button"
          >
            Delete Component
          </button>
        </design-menu-panel>
      </cdk-cell>
    </ng-container>

    <cdk-header-row *cdkHeaderRowDef="displayedColumns"></cdk-header-row>
    <cdk-row
      *cdkRowDef="let row; columns: displayedColumns"
      class="pointer"
      [attr.data-cy]="'component-item-' + row.name"
    >
    </cdk-row>
  </cdk-table>
  <ng-container *ngIf="componentList$ | async; let componentList">
    <ui-image-info
      *ngIf="componentList.length === 0"
      imageSrc="icon_add_component"
      data-cy="component-info-image-info"
      i18n-header="@@ADD_COMPONENT"
      header="Add component"
      [descriptions]="descriptionImageInfo"
      [button]="buttonInfo"
      [maxWidth]="150"
    ></ui-image-info>
  </ng-container>
</ng-container>
