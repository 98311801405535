<div class="title">{{ type() }}</div>

<div class="request-container">
  <div class="request-container__top">
    <label>{{ titleLabel() | uppercase }}</label>
    <div>{{ title() }}</div>
  </div>
  <div class="request-container__down">
    <label>{{ descriptionLabel() | uppercase }}</label>
    <div [innerHTML]="description()"></div>
  </div>
</div>
