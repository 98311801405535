<div class="wrapper-synchronization">
  <div class="close">
    <button design-icon-button (click)="openSynchronizeConfirmationDialog()">
      <i designIcon="cross" designSize="1rem"></i>
    </button>
  </div>
</div>

<div class="background-matterport">
  <simlab-matterport #simlabMatterport>
    <ng-template #tagPlacement>
      <annotation-tag-placement />
    </ng-template>
  </simlab-matterport>

  <ng-container
    [ngTemplateOutlet]="content"
    [ngTemplateOutletContext]="{
      loaded: (componentLoaded$ | async)
    }"
  ></ng-container>
</div>

<ng-template #content let-loaded="loaded">
  <ng-container [ngSwitch]="currentStep$ | async">
    <ng-container *ngSwitchCase="1">
      <div class="step-wrapper" *ngIf="loaded">
        <div
          class="btn-container pointer-events"
          *ngIf="allPointsSelected | async"
        >
          <button
            design-flat-button
            designButtonColor="secondary"
            (click)="nextStep()"
          >
            <div i18n="@@GEN_NEXT_STEP">Next step</div>
          </button>
        </div>
        <div class="step background pointer-events">
          <div class="points">
            <ng-container
              [ngTemplateOutlet]="pointWrapper"
              [ngTemplateOutletContext]="{ point: 'A1' }"
            ></ng-container>
            <ng-container
              [ngTemplateOutlet]="pointWrapper"
              [ngTemplateOutletContext]="{ point: 'B1' }"
            ></ng-container>
            <ng-container
              [ngTemplateOutlet]="pointWrapper"
              [ngTemplateOutletContext]="{ point: 'C1' }"
            ></ng-container>
          </div>
          <div class="label small">
            <span i18n="@@ACTIVE_COMPONENT">ACTIVE COMPONENT</span>
          </div>
          <div class="input-container">
            <ui-input [disabled]="true" [value]="targetCompName"></ui-input>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <div class="step-wrapper">
        <div class="step full-h-mobile background pointer-events">
          <h2><ng-container i18n="@@STEP"> Step </ng-container>2</h2>
          <div class="hint">
            <p i18n="@@SELECT_ONE_OF_SYNCHRO_COMPONENTS_TO_ALIGN">
              Select one of the synchronized components to which you want to
              align
            </p>
          </div>
          <div class="label small">
            <span i18n="@@REFERENCE_COMPONENT">REFERENCE COMPONENT</span>
          </div>
          <div class="comp-list">
            <ng-container
              *ngFor="let comp of allSynchronizedComponents$ | async"
            >
              <ng-container *ngIf="comp.stageName; else row">
                <div class="row label">
                  <i
                    ui-font
                    fontName="stages"
                    fontSize="1rem"
                    fontColor="#B9BCBF"
                  ></i>
                  <span>{{ comp.stageName }}</span>
                </div>
              </ng-container>
              <ng-template #row>
                <div
                  class="row pointer"
                  (click)="selectComponent(comp)"
                  [ngClass]="{ selected: comp.id === selectedComponent }"
                >
                  <i ui-font></i>
                  <span>{{ comp.name }}</span>
                </div>
              </ng-template>
            </ng-container>
          </div>
          <div class="btn-section">
            <div class="btn-wrapper">
              <button
                (click)="previousStep()"
                design-flat-button
                designButtonColor="secondary"
              >
                <div i18n="@@BACK">Back</div>
              </button>
              <button
                (click)="nextStep()"
                design-flat-button
                designButtonColor="primary"
                [disabled]="!selectedComponent"
              >
                <div i18n="@@ACCEPT_COMPONENT">Accept Component</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <ng-container *ngIf="loaded">
        <div
          class="prev-container prev-postion pointer-events"
          [ngClass]="{ 'double-size': doubleSize }"
          (click)="doubleSize = !doubleSize"
        >
          <canvas
            id="destImage"
            width="800"
            height="600"
            style="width: 100%"
          ></canvas>
        </div>
        <div class="step-wrapper">
          <div
            class="btn-container pointer-events"
            *ngIf="allSyncPointsSelected | async"
          >
            <button
              design-flat-button
              designButtonColor="secondary"
              (click)="acceptSync()"
            >
              <div i18n="@@ACCEPT_SYNC">Accept Sync</div>
            </button>
          </div>
          <div class="step background pointer-events">
            <div class="points">
              <ng-container
                [ngTemplateOutlet]="pointWrapper"
                [ngTemplateOutletContext]="{ point: 'A2' }"
              ></ng-container>

              <ng-container
                [ngTemplateOutlet]="pointWrapper"
                [ngTemplateOutletContext]="{ point: 'B2' }"
              ></ng-container>

              <ng-container
                [ngTemplateOutlet]="pointWrapper"
                [ngTemplateOutletContext]="{ point: 'C2' }"
              ></ng-container>
            </div>
            <div class="label small">
              <span i18n="@@ACTIVE_COMPONENT">ACTIVE COMPONENT</span>
            </div>
            <div class="input-container">
              <ui-input
                [disabled]="true"
                [value]="referenceCompName"
              ></ui-input>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #pointWrapper let-point="point">
  <div class="point-wrapper">
    <div
      class="point"
      [ngClass]="{
        selected: pointsPositions[point],
        current: selectedPoint === point
      }"
      (click)="placePoint($event, point)"
    >
      <div></div>
    </div>
    <label class="small">{{ point }}</label>
  </div>
</ng-template>
