<ng-container *ngIf="video$ | async; let video">
  <ng-container *ngIf="video.url !== ''; else spinner">
    <div>
      <video preload="metadata">
        <source [src]="video.privateUrl + '#t=0.5'" type="video/mp4" />
      </video>
    </div>
  </ng-container>
</ng-container>

<ng-template #spinner>
  <ui-progress-spinner
    class="spinner"
    [strokeWidth]="8"
    [diameter]="70"
    uiColor="secondary"
  ></ui-progress-spinner>
</ng-template>
