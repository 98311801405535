<section class="measurement-actions">
  <div class="measurement-actions__buttons">
    <button
      design-flat-button
      designButtonColor="accent"
      (click)="onAdd.emit()"
      [disabled]="!canAddMeasurement()"
    >
      <i designIcon="area_measurements" designSize="1.4rem"></i>
      <div i18n="@@AREM_ADD_MEASUR">Add measurement</div>
    </button>
    <button
      design-flat-button
      designButtonColor="secondary"
      (click)="calculator()"
    >
      <ng-container i18n="@@GEN_CALCULATOR">Calculator</ng-container>
    </button>
  </div>
  <div class="measurement-actions__filters">
    <feature-measurement-search-and-sort-filter
      [sortOptions]="sortOptions"
      class="measurement-group-actions__filters--component"
    />
  </div>
</section>

<ng-container *ngIf="measurements()?.length; else noItems">
  <design-selection-list
    class="measurement-list"
    (selectionChange)="selectionChange($event)"
    id="uiOptionList"
  >
    <design-list-option
      *ngFor="
        let measurement of extendedMeasurements();
        trackBy: trackByMeasurementId
      "
      [id]="measurement.id"
      [value]="measurement.id"
      [selected]="selectedMeasurement === measurement.id"
    >
      <feature-measurement-item [measurement]="measurement" [suffix]="suffix">
        <ng-template #suffix>
          <button design-icon-button disabled *ngIf="measurement.rootNoteId">
            <i designIcon="attached_note"></i>
          </button>
          <design-tooltip
            i18n-tooltip="@@GO_TO"
            tooltip="go to"
            position="left"
          >
            <button design-icon-button (click)="goTo(measurement.id)">
              <i designIcon="location"></i>
            </button>
          </design-tooltip>
          <button design-icon-button [designMenuTriggerFor]="menu">
            <i designIcon="more_second"></i>
          </button>
          <design-menu-panel #menu>
            <button
              design-menu-item
              (click)="moreInfo(measurement)"
              i18n="@@GEN_MORE_INFO"
            >
              More info
            </button>
            <ng-container
              *ngIf="
                !canEditDeleteMeasurement(measurement.creatorId);
                else Edit
              "
            >
              <design-tooltip
                i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
                tooltip="Unsufficient privileges"
                position="left"
              >
                <ng-template [ngTemplateOutlet]="Edit"></ng-template>
              </design-tooltip>
            </ng-container>

            <ng-template #Edit>
              <button
                design-menu-item
                (click)="editMeasurement(measurement)"
                [disabled]="!canEditDeleteMeasurement(measurement.creatorId)"
                i18n="@@EDIT"
              >
                Edit
              </button>
            </ng-template>
            <ng-container
              *ngIf="
                !canEditDeleteMeasurement(measurement.creatorId);
                else title
              "
            >
              <design-tooltip
                i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
                tooltip="Unsufficient privileges"
                position="left"
              >
                <ng-template [ngTemplateOutlet]="title"></ng-template>
              </design-tooltip>
            </ng-container>

            <ng-template #title>
              <button
                design-menu-item
                (click)="changeTitleModal(measurement)"
                [disabled]="!canEditDeleteMeasurement(measurement.creatorId)"
                i18n="@@CHANGE_TITLE"
              >
                Change title
              </button>
            </ng-template>
            <ng-container
              *ngIf="
                !canEditDeleteMeasurement(measurement.creatorId);
                else color
              "
            >
              <design-tooltip
                i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
                tooltip="Unsufficient privileges"
                position="left"
              >
                <ng-template [ngTemplateOutlet]="color"></ng-template>
              </design-tooltip>
            </ng-container>

            <ng-template #color>
              <button
                design-menu-item
                (click)="changeColorModal(measurement)"
                [disabled]="!canEditDeleteMeasurement(measurement.creatorId)"
                i18n="@@AREM_CHANGE_COLOR"
              >
                Change color
              </button>
            </ng-template>

            <ng-container
              *ngIf="allMeasurementGroupPrivilegesNotAllowed(); else group"
            >
              <design-tooltip
                i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
                tooltip="Unsufficient privileges"
                position="left"
              >
                <ng-template [ngTemplateOutlet]="group"></ng-template>
              </design-tooltip>
            </ng-container>

            <ng-template #group>
              <button
                design-menu-item
                (click)="addAreaMeasurementToGroups(measurement)"
                [disabled]="allMeasurementGroupPrivilegesNotAllowed()"
              >
                <div i18n="@@AREM_ADD_GROUP">Add to group</div>
              </button>
            </ng-template>

            <button
              *ngIf="measurement.rootNoteId"
              design-menu-item
              (click)="openRelatedNote.emit(measurement.rootNoteId)"
              i18n="@@AREM_OPEN_RELATED_NOTE"
            >
              Open related Note
            </button>

            <ng-container
              *ngIf="
                !canEditDeleteMeasurement(measurement.creatorId);
                else Attach
              "
            >
              <design-tooltip
                i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
                tooltip="Unsufficient privileges"
                position="left"
              >
                <ng-template [ngTemplateOutlet]="Attach"></ng-template>
              </design-tooltip>
            </ng-container>

            <ng-template #Attach>
              <button
                *ngIf="!measurement.rootNoteId"
                design-menu-item
                (click)="addMeasurementToRootNote(measurement)"
                [disabled]="!canEditDeleteMeasurement(measurement.creatorId)"
              >
                <ng-container i18n="@@AREM_ATTACH_TO_NOTE">
                  Attach to note
                </ng-container>
              </button>
            </ng-template>

            <ng-container
              *ngIf="
                measurement.rootNoteId &&
                  !canAttachDetachToNote(
                    measurement.rootNoteCreatorId ||
                      getNoteCreatorId(measurement.rootNoteId)
                  );
                else Detach
              "
            >
              <design-tooltip
                i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
                tooltip="Unsufficient privileges"
                position="left"
              >
                <ng-template [ngTemplateOutlet]="Detach"></ng-template>
              </design-tooltip>
            </ng-container>

            <ng-template #Detach>
              <button
                *ngIf="measurement.rootNoteId"
                design-menu-item
                (click)="unassignFromNote.emit({ id: measurement.id })"
                [disabled]="
                  !canAttachDetachToNote(
                    measurement.rootNoteCreatorId ||
                      getNoteCreatorId(measurement.rootNoteId)
                  )
                "
                i18n="@@AREM_DETACH_FROM_NOTE"
              >
                Detach From Note
              </button>
            </ng-template>
            <ng-container
              *ngIf="
                !canEditDeleteMeasurement(measurement.creatorId);
                else Delete
              "
            >
              <design-tooltip
                i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
                tooltip="Unsufficient privileges"
                position="left"
              >
                <ng-template [ngTemplateOutlet]="Delete"></ng-template>
              </design-tooltip>
            </ng-container>

            <ng-template #Delete>
              <button
                design-menu-item
                (click)="deleteMeasurement(measurement)"
                [disabled]="!canEditDeleteMeasurement(measurement.creatorId)"
              >
                <ng-container i18n="@@DELETE">Delete</ng-container>
              </button>
            </ng-template>
          </design-menu-panel>
        </ng-template>
      </feature-measurement-item>
    </design-list-option>
  </design-selection-list>
</ng-container>

<ng-template #noItems>
  <ui-image-info
    class="image-info"
    imageSrc="icon_no_measurement"
    i18n-header="@@AREM_NO_MEASUREMENTS"
    header="No Measurements"
    [descriptions]="descriptionInfo"
    [maxWidth]="220"
  ></ui-image-info>
</ng-template>
