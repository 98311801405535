<ng-content></ng-content>
<lib-annotation-panel-header-action #x="triggerMenu">
  <button
    design-icon-button
    type="button"
    [designMenuTriggerFor]="x.menu()"
    class="btn"
  >
    <i designIcon="more_second" designSize="20px"></i>
  </button>
</lib-annotation-panel-header-action>

<button design-icon-button type="button" class="btn" (click)="closePanel()">
  <i designIcon="cross" designSize="32px"></i>
</button>
