import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy, inject } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  ApiFacadeService,
  OrganizationWithCreateProjectPermission
} from '@simlab/data-access';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { UiButtonModule } from '@simlab/ui/button';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiHintModule } from '@simlab/ui/hint';
import { UiIconModule } from '@simlab/ui/icon';
import { ConfirmationModalRef } from '@simlab/ui/modal';
import { UiProgressSpinnerModule } from '@simlab/ui/progress-spinner';
import { UiSelectModule } from '@simlab/ui/select';
import { Subject, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'feature-projects-organization-selection-dialog',
  templateUrl: './organization-selection-dialog.component.html',
  styleUrls: ['./organization-selection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [ApiFacadeService],
  imports: [
    CommonModule,
    DesignCommonModule,
    DesignDialogWrapperModule,
    UiButtonModule,
    UiIconModule,
    UiHintModule,
    UiFormFieldModule,
    UiSelectModule,
    ReactiveFormsModule,
    UiProgressSpinnerModule,
    DesignFlatButton,
    DesignStrokedButton
  ]
})
export class OrganizationSelectionDialogComponent implements OnDestroy {
      private readonly modalRef = inject<ConfirmationModalRef<{
          organizationId?: string;
          routeTo?: boolean;
        }>>(ConfirmationModalRef<{
          organizationId?: string;
          routeTo?: boolean;
        }>);
      private readonly apiFacadeService = inject(ApiFacadeService);
      private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly _destroySource = new Subject<void>();

  formGroup = new UntypedFormGroup({
    organizationId: new UntypedFormControl('', [Validators.required])
  });

  organizations: OrganizationWithCreateProjectPermission[] | undefined;
  hasOrganizations: boolean | undefined = undefined;

  get organizationId(): string {
    return this.formGroup.get('organizationId')?.value;
  }

  constructor(
) {
    this.apiFacadeService.organizations
      .getOrganizationsWithCreateProjectPermissions$()
      .pipe(
        take(1),
        takeUntil(this._destroySource),
        tap((organizations: OrganizationWithCreateProjectPermission[]) => {
          this.organizations = organizations;
          this.hasOrganizations =
            this.organizations && this.organizations.length > 0;
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  trackRoleById(
    index: number,
    organization: OrganizationWithCreateProjectPermission
  ): string {
    return organization.organizationId;
  }

  closeDialog(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }

  confirmDialog(): void {
    if (this.formGroup.valid) {
      this.modalRef.emit({
        state: true,
        result: {
          organizationId: this.organizationId
        }
      });
      this.modalRef.close();
    }
  }

  goToOrganizations(): void {
    this.modalRef.emit({
      state: true,
      result: {
        routeTo: true
      }
    });
    this.modalRef.close();
  }
}
