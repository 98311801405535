import { Action, createReducer, on } from '@ngrx/store';
import {
  EnumFlagHelper,
  OrganizationPermission,
  OrganizationWithPermissions,
} from '@simlab/data-access';
import {
  OrganizationApiActions,
  selectOrganization,
} from '../organizations/organizations.actions';
import { OrganizationPrivilegesActions } from './privileges.actions';

export const PRIVILEGES_FEATURE_KEY = 'privileges';
const BIT_VALUES = EnumFlagHelper.enumToBitValues(OrganizationPermission);

export interface State {
  organizationWithPermissions?: OrganizationWithPermissions | null;
  permissions: Record<string, boolean>;
  loaded: boolean;
  error?: string | null;
}

export const initialState: State = {
  organizationWithPermissions: null,
  permissions: EnumFlagHelper.bitValuesToSelected(BIT_VALUES, 0),
  loaded: false,
  error: null,
};

export const organizationPrivilegesReducer = createReducer(
  initialState,
  on(selectOrganization, (state) => ({
    ...state,
    loaded: false,
  })),
  on(OrganizationApiActions.leaveOrganizationSuccess, (state) => ({
    ...state,
    organizationWithPermissions: null,
    permissions: EnumFlagHelper.bitValuesToSelected(BIT_VALUES, 0),
    loaded: false,
    error: null,
  })),
  on(
    OrganizationPrivilegesActions.loadOrganizationWithPermissionsSuccess,
    (state, { organizationWithPermissions }) => ({
      ...state,
      organizationWithPermissions,
      permissions: EnumFlagHelper.bitValuesToSelected(
        BIT_VALUES,
        organizationWithPermissions.permissions
      ),
      loaded: true,
    })
  ),
  on(
    OrganizationPrivilegesActions.loadOrganizationWithPermissionsFailure,
    (state, { error }) => ({ ...state, error, loaded: true })
  ),
  on(
    OrganizationApiActions.editOrganizationDescriptionSuccess,
    (state, action) => ({
      ...state,
      organizationWithPermissions: state.organizationWithPermissions
        ? {
            ...state.organizationWithPermissions,
            description: action.update.changes.description
              ? action.update.changes.description
              : '',
          }
        : null,
    })
  ),

  on(OrganizationApiActions.editOrganizationSuccess, (state, action) => ({
    ...state,
    organizationWithPermissions: state.organizationWithPermissions
      ? {
          ...state.organizationWithPermissions,
          name: action.update.changes.name ? action.update.changes.name : '',
        }
      : null,
  })),
  on(
    OrganizationApiActions.editOrganizationThumbnailSuccess,
    (state, { organizationId, thumbnailUrl }) => {
      if (
        organizationId !== state.organizationWithPermissions?.organizationId ||
        state.organizationWithPermissions === null
      )
        return state;

      return {
        ...state,
        organizationWithPermissions: {
          ...state.organizationWithPermissions,
          thumbnailUrl: {
            urlWithSas: thumbnailUrl,
            sasExpirationDate:
              state.organizationWithPermissions?.thumbnailUrl
                ?.sasExpirationDate || '',
          },
        },
        loaded: true,
      };
    }
  )
);

export function reducer(state: State | undefined, action: Action) {
  return organizationPrivilegesReducer(state, action);
}
