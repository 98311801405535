<label class="section-header" i18n="@@COMMENTS_HEADER">COMMENTS</label>
@if (mentionData(); as mentionData) {
  @if (projectIsActive()) {
    <feature-stages-add-comment
      [maxLength]="maxCommentLength()"
      (commented)="addComment($event)"
      [mentionData]="mentionData"
      [reset]="resetComments$ | async"
      [enableAddComment]="
        ((addEditDeleteOwnComments$ | async) ?? true) === true
      "
    />
  }

  @for (comment of commentList(); track trackByCommentId) {
    <feature-stages-comment
      #comments
      [maxLength]="maxCommentLength()"
      [comment]="comment"
      [mentionData]="mentionData"
    >
      @for (reply of comment.replies; track reply) {
        <feature-stages-comment
          [comment]="reply"
          [mentionData]="mentionData"
          [maxLength]="maxCommentLength()"
        />
      }
    </feature-stages-comment>
  }
}
