<div class="header">
  <nav
    class="grid-toolbar center-big"
    [ngClass]="{
      'grid-toolbar--is-rendered-documents-feature':
        isDocumentsFeatureRendered()
    }"
    *ngIf="authenticated$ | async; else unknownUser"
  >
    <div class="hamburger-area inline" id="hamburger-button">
      <ng-container *ngIf="authenticated$ | async">
        <simlab-hamburger
          name="hamburger"
          size="2rem"
          color="white"
        ></simlab-hamburger>
      </ng-container>
    </div>
    <div class="menu-area inline">
      <img
        class="logo"
        src="assets/icons/logo_text.svg"
        alt="logo"
        draggable="false"
        routerLink="/projects/list"
      />
      <ng-container *ngIf="authenticated$ | async">
        <a
          design-flat-button
          class="button-override"
          routerLink="/projects"
          routerLinkActive="ui-button-selected"
        >
          <i
            ui-font
            fontName="project-filled"
            fontSize="1.5rem"
            fontColor="#e1e1e1"
          ></i>
          <span i18n="@@PROJECTS" class="button-name">Projects</span>
        </a>

        <a
          design-flat-button
          class="button-override"
          routerLink="/organizations"
          routerLinkActive="ui-button-selected"
        >
          <i
            ui-font
            fontName="organization-filled"
            fontSize="1.5rem"
            fontColor="#e1e1e1"
          ></i>
          <span
            class="button-name"
            i18n="@@ORGANIZATIONS"
            data-cy="navbar-organization"
            >Organizations</span
          >
        </a>
      </ng-container>
      <!-- </ng-container> -->
    </div>
    <div class="title-area inline">
      <ng-container *ngIf="authenticated$ | async">
        <div class="nav-title wide-padding">
          <h2 class="overflow-ellipsis" data-cy="nav-title">
            {{ title$ | async }}
          </h2>
        </div>
      </ng-container>
    </div>
    @if (authenticated$ | async) {
      <div class="user-info-area end inline">
        @if (queueState().viewInProgress > 0) {
          <button
            design-flat-button
            [designMenuTriggerFor]="queue"
            [uiOffsetY]="uiOffsetYEventQueue"
          >
            <div class="queue-btn">
              <design-progress-spinner
                [diameter]="20"
                [strokeWidth]="5"
                class="spinner"
                designColor="accent"
              ></design-progress-spinner>
              <ng-container i18n="@@X_OPERATION_IN_PROGRESS">
                {{ queueState().viewInProgress }} operations in progress...
              </ng-container>
              <i class="color" designIcon="expand_more" designSize="2rem"></i>
            </div>
          </button>
        } @else if (queueState().failedResults > 0) {
          <button
            design-flat-button
            [designMenuTriggerFor]="queue"
            [uiOffsetY]="uiOffsetYEventQueue"
          >
            <div class="queue-btn">
              <i
                class="icon-procore"
                ui-icon
                name="error"
                size="img"
                defaultColor
              ></i>
              {{ queueState().failedResults }} task failed
              <i class="color" designIcon="expand_more" designSize="2rem"></i>
            </div>
          </button>
        }

        <ng-container>
          <span class="user-data color overflow-ellipsis">
            {{ userName$ | async }}
          </span>
        </ng-container>
      </div>

      <div class="user-icon-area inline">
        <ng-template [ngTemplateOutlet]="userButton"></ng-template>
      </div>
    }
  </nav>
</div>
<ui-sidenav-container class="content header-padding">
  <ui-sidenav-content class="scrollable">
    <router-outlet></router-outlet>
  </ui-sidenav-content>
</ui-sidenav-container>

<ng-template #desktopPortalContent>
  <div class="inline">
    <img
      class="logo"
      src="assets/icons/logo_text.svg"
      alt="logo"
      draggable="false"
      routerLink="/projects/list"
    />

    <ng-container *ngIf="authenticated$ | async">
      <a
        ui-button
        class="button-override"
        routerLink="/projects"
        routerLinkActive="ui-button-selected"
      >
        <i ui-font fontName="project-filled" fontSize="1.5rem"></i>
        <span class="button-name" i18n="@@PROJECTS">Projects</span>
      </a>

      <a
        ui-button
        class="button-override"
        routerLink="/organizations"
        routerLinkActive="ui-button-selected"
      >
        <i ui-font fontName="organization-filled" fontSize="1.5rem"></i>
        <span class="button-name" i18n="@@ORGANIZATIONS">Organizations</span>
      </a>
    </ng-container>
  </div>
  <div class="inline">
    <ng-container *ngIf="authenticated$ | async; else unknownUser">
      <span class="user-data color overflow-ellipsis">
        {{ userName$ | async }}
      </span>
      <button design-icon-button [designMenuTriggerFor]="menu">
        <i class="color" designIcon="profile" designSize="1.5rem"></i>
      </button>
    </ng-container>
  </div>
  <ng-container *ngIf="authenticated$ | async">
    <div class="nav-title wide-padding">
      <div class="overflow-ellipsis">{{ title$ | async }}</div>
    </div>
  </ng-container>
</ng-template>
<ng-template #mobilePortalContent>
  <div class="inline">
    <ng-container *ngIf="authenticated$ | async">
      <simlab-hamburger
        name="hamburger"
        size="2rem"
        color="white"
      ></simlab-hamburger>
    </ng-container>
  </div>
  <div class="inline">
    <ng-container *ngIf="authenticated$ | async; else unknownUser">
      <button design-icon-button [designMenuTriggerFor]="menu">
        <i class="color" designIcon="profile" designSize="2rem"></i>
      </button>
    </ng-container>
  </div>
  <ng-container *ngIf="authenticated$ | async">
    <div class="nav-title">
      <div class="overflow-ellipsis">{{ title$ | async }}</div>
    </div>
  </ng-container>
</ng-template>
<design-menu-panel
  #queue
  class="menu-personal-panel"
  customPanelClass="panel-background"
>
  <event-queue></event-queue>
</design-menu-panel>
<design-menu-panel #menu class="menu-personal-panel">
  <ng-container
    [ngTemplateOutlet]="stagesAccount"
    [ngTemplateOutletContext]="{ userData: userData$ | async }"
  >
  </ng-container>
</design-menu-panel>

<ng-template #unknownUser>
  <div class="unknown-user-toolbar">
    <div class="menu-area inline">
      <img
        class="logo"
        src="assets/icons/logo_text.svg"
        alt="logo"
        draggable="false"
        routerLink="/projects/list"
      />
    </div>
    <button design-flat-button designButtonColor="error" (click)="login()">
      <div i18n="@@LOGIN">Login</div>
    </button>
  </div>
</ng-template>
<ng-template #userButton>
  <button
    design-icon-button
    [designMenuTriggerFor]="menu"
    data-cy="account-modal"
  >
    <i class="color" designIcon="profile" designSize="2.2rem"></i>
  </button>
</ng-template>

<ng-template #stagesAccount let-userData="userData">
  <div class="personal-panel" data-cy="account-panel">
    <label i18n="@@ACCOUNT">Account</label>

    <div class="personal-panel__user">
      <h3>{{ userData?.family_name }} {{ userData?.given_name }}</h3>
      <div>{{ userData?.email }}</div>
    </div>
    <div class="personal-panel__settings">
      <button
        class="personal-panel--button"
        design-flat-button
        designButtonColor="secondary"
        data-cy="personal-settings-button"
        (click)="openPersonal()"
      >
        <i designIconPrefix designIcon="settings"></i>
        <div i18n="@@PERSONAL_SETTINGS">Personal settings</div>
      </button>

      <button
        class="personal-panel--button"
        design-flat-button
        designButtonColor="secondary"
        (click)="openMyLicenses()"
      >
        <i designIconPrefix designIcon="secured"></i>
        <div i18n="@@MY_LICENSES">My Licenses</div>

        <div class="info-badge" *ngIf="myLicenseLength > 0">
          {{ myLicenseLength }}
        </div>
      </button>

      <button
        class="personal-panel--button"
        design-flat-button
        designButtonColor="secondary"
        (click)="goToSimlabCloud()"
      >
        <i designIconPrefix designIcon="stages"></i>
        <div i18n="@@SIMLAB_CLOUD">SIMLAB Cloud</div>
      </button>
    </div>
    <button
      class="personal-panel--button"
      design-flat-button
      designButtonColor="accent"
      (click)="logout()"
      data-cy="log-out-button"
    >
      <div i18n="@@LOG_OUT">Log out</div>
    </button>
  </div>
</ng-template>
