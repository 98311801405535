import { Dialog } from '@angular/cdk/dialog';
import { Injectable, inject } from '@angular/core';
import type { ModalComponent, ModalData } from '@simlab/modal/ui';
import { RFIState } from '@simlab/procore/annotation-panel/services';
import { OpenNewTabService } from '@simlab/util-shared';
import { exhaustMap, from, take } from 'rxjs';

const TEXT = {
  titleText: (name: string) =>
    $localize`:@@REMOVE_RFI_ITEM:Are you sure you want to delete ${name}?`,
  message: $localize`:@@REMOVE_RFI_ITEM_LABEL:This document will be removed from both platforms. You will be able to restore it in the Recycle Bin in Procore.`,
  delete: $localize`:@@DELETE:Delete`,
  cancel: $localize`:@@CANCEL:Cancel`
};

@Injectable()
export abstract class BaseRFISidePanel {
  private readonly _dialog = inject(Dialog);
  protected readonly rfiState = inject(RFIState);
  protected readonly openNewTab = inject(OpenNewTabService);

  protected readonly rfi = this.rfiState.rfi;
  protected readonly punchItem = this.rfiState.rfi;
  protected readonly isLoading = this.rfiState.isLoading;
  protected readonly allowedActions = this.rfiState.allowedActions;

  protected getModalDelete$() {
    const { title } = this.rfiState.rfi();
    const data: ModalData = {
      title: TEXT.titleText(title),
      message: TEXT.message,
      type: 'alert',
      btnContent: {
        confirm: {
          label: TEXT.delete,
          returnValue: true
        },
        cancel: {
          label: TEXT.cancel,
          returnValue: false
        }
      }
    };

    return from(import('@simlab/modal/ui')).pipe(
      exhaustMap(
        ({ ModalComponent }) =>
          this._dialog.open<boolean, ModalData, ModalComponent>(
            ModalComponent,
            {
              data,
              width: 'min(90%, 610px)'
            }
          ).closed
      ),
      take(1)
    );
  }
}
