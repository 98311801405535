<div class="split-view-toolbar">
  <button
    design-flat-button
    designButtonColor="primary"
    (click)="close(src, dst)"
  >
    <div i18n="@@EXIT_COMPARE_VIEW">Exit compare view</div>
  </button>
</div>
<div class="matterports-container">
  <simlab-matterport-compare-element
    #src
    [stages]="stages"
    [initialSelectedModel]="selectedComponent"
    [initCameraPose]="initialPosition"
    [initFloor]="initialFloor"
    simlabIframeTracker
    (iframeClick)="src.focus = true"
    (focusActivated)="dst.focus = false"
    [iframeClickerDetected]="src.clickerDetected()"
    [iframeMatterportClickUp]="dst.matterportDetectClickPointerUp()"
    (modeChanged)="dst.setMode($event)"
    (floorChanged)="dst.setFloor($event)"
  ></simlab-matterport-compare-element>
  <simlab-matterport-compare-element
    #dst
    [stages]="stages"
    [initialSelectedModel]="stageInitSecond"
    [initCameraPose]="initialPosition"
    [initFloor]="initialFloor"
    simlabIframeTracker
    (iframeClick)="dst.focus = true"
    [iframeClickerDetected]="dst.clickerDetected()"
    [iframeMatterportClickUp]="src.matterportDetectClickPointerUp()"
    (focusActivated)="src.focus = false"
    (modeChanged)="src.setMode($event)"
    (floorChanged)="src.setFloor($event)"
  ></simlab-matterport-compare-element>
</div>
