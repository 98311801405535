<div class="content-wrapper">
  <h1 i18n="@@MATTERTAG_IMPORTER">Mattertag Importer</h1>
  <p class="second-text" i18n="@@IMPORT_MATTERTAGS_TURN_TO_NOTES">
    Import Mattertags and turn then into Stages Notes
  </p>
  <div class="filter-container">
    <h2 i18n="@@SELECT_VIEW">1. Select View:</h2>
    <ui-select [(ngModel)]="scanId" placeholder="View">
      <ng-container *ngFor="let view of scanViews$ | async">
        <ui-option [value]="view.id">{{ view.name || 'Default' }}</ui-option>
      </ng-container>
    </ui-select>
  </div>
  <div class="filter-container">
    <h2 i18n="@@SELECT_MATTERTAGS">2. Select Mattertags:</h2>
    <feature-stages-tag-filters #filters></feature-stages-tag-filters>
  </div>
</div>
<feature-stages-tag-list
  *ngIf="scanId && projectId"
  #tagList
  [scanId]="scanId"
  [projectId]="projectId"
  [searchText]="(filters.searchFilterChange | async) ?? ''"
  [selectedColors]="(filters.selectedColorsChange | async) ?? {}"
></feature-stages-tag-list>
<div class="content-wrapper padding-bottom">
  <h2 i18n="@@SET_AS">3. Set as:</h2>
  <div class="flex-row">
    <i
      ui-font
      [fontName]="(selectedIcon$ | async)!"
      fontSize="1.665rem"
      defaultColor
    ></i>
    <ui-select
      [formControl]="noteType"
      i18n-placeholder="@@NOTE_TYPE"
      placeholder="Type"
      #type
    >
      <ui-option [value]="'Issue'" i18n="@@NOTE_TYPE_ISSUE">Issue</ui-option>
      <ui-option [value]="'Information'">
        <ng-container i18n="@@NOTE_TYPE_INFORMATION">Information</ng-container>
      </ui-option>
    </ui-select>
    <ui-select
      [formControl]="noteStatus"
      i18n-placeholder="@@NOTE_STATUS"
      placeholder="Status"
    >
      <ui-option [value]="'Pending'" i18n="@@NOTE_STATUS_PENDING"
        >Pending</ui-option
      >
      <ui-option [value]="'InProgress'" i18n="@@NOTE_STATUS_IN_PROGRESS"
        >In Progress</ui-option
      >
      <ui-option [value]="'Resolved'" i18n="@@NOTE_STATUS_RESOLVED"
        >Resolved</ui-option
      >
      <ui-option [value]="'Unresolved'" i18n="@@NOTE_STATUS_UNRESOLVED"
        >Unresolved</ui-option
      >
    </ui-select>
  </div>

  <ui-divider></ui-divider>
  <button
    design-flat-button
    designButtonColor="accent"
    [disabled]="emptyTagList"
    [designFireOnClick]="importDialogAction$"
  >
    <design-button-loader
      designIconSuffix
      designColor="surface"
    ></design-button-loader>
    <div i18n="@@IMPORT_SELECTED">Import Selected</div>
  </button>
</div>
