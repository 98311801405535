import { Pipe, PipeTransform } from '@angular/core';
import { ProjectPrivilegesFacade } from '@simlab/data-store';
import { Observable, iif, mergeMap, of } from 'rxjs';

@Pipe({ standalone: true, name: 'checkCommentsPrivilegesAsync' })
export class AddEditDeleteCommentsPipe implements PipeTransform {
  constructor(private readonly privilegesFacade: ProjectPrivilegesFacade) {}

  transform(isOwner: boolean): Observable<boolean> {
    return of(isOwner).pipe(
      mergeMap((state: boolean) =>
        iif(
          () => state,
          this.privilegesFacade.addEditDeleteOwnComments$,
          this.privilegesFacade.editDeleteOtherUserComments$
        )
      )
    );
  }
}
