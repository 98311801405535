import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Signal,
  computed,
  inject,
  input,
  output
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SystemOfMeasurement } from '@simlab/data-access';
import { DesignRadioAccordionModule } from '@simlab/design/accordion';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiTooltip } from '@simlab/design/tooltip';
import {
  TDistance,
  TMeasurementExtended,
  TVerticle
} from '@simlab/feature-measurement/models';
import {
  DISTANCE_CONVERSION,
  UnitsDistancePreferredByUserPipe
} from '@simlab/feature-measurement/pipes';
import { DistanceFacade } from '@simlab/feature-measurement/state';
import { UiHelperModule } from '@simlab/ui/helper';
import { UiMenuModule } from '@simlab/ui/menu';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { tap } from 'rxjs';
import { MeasurementPermission } from '../../../services/measurement.privileges';
import { MeasurementItemComponent } from '../measurement-item/measurement-item.component';

function distanceBetweenVectors(
  vector1: TVerticle,
  vector2: TVerticle
): number {
  return Math.sqrt(
    Math.pow(vector1.x - vector2.x, 2) +
      Math.pow(vector1.y - vector2.y, 2) +
      Math.pow(vector1.z - vector2.z, 2)
  );
}

@Component({
  selector: 'feature-distance-measurement-attachment',
  standalone: true,
  imports: [
    CommonModule,
    DesignIconButton,
    UiMenuModule,
    UiMenuPanelModule,
    DesignRadioAccordionModule,
    UnitsDistancePreferredByUserPipe,
    UiTooltip,
    UiHelperModule,
    MeasurementItemComponent,
    DesignIcon
  ],
  templateUrl: './distance-measurement-note-attachment.component.html',
  styleUrls: ['./distance-measurement-note-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnitsDistancePreferredByUserPipe]
})
export class DistanceMeasurementAttachmentComponent
  extends MeasurementPermission
  implements OnInit
{
  ngOnInit(): void {
    this._measurementFacade.init(this.stageId());
  }
  private readonly _measurementFacade = inject(DistanceFacade);
  private readonly _unitsPreferredByUserPipe = inject(
    UnitsDistancePreferredByUserPipe
  );
  private readonly _measurements: Signal<TDistance[]> = toSignal(
    this._measurementFacade.allMeasurement$.pipe(tap((e) => console.log(e)))
  ) as Signal<TDistance[]>;

  readonly systemOfMeasurement = input<SystemOfMeasurement>();
  readonly stageId = input.required<string>();
  readonly noteId = input<string>();
  readonly goToAreaMeasurement = output<void>();

  get measurements() {
    return this._measurements();
  }

  readonly extendedMeasurements: Signal<TMeasurementExtended[]> = computed(
    () => {
      const noteId = this.noteId();
      if (!noteId) return [];
      const units = this.systemOfMeasurement() || SystemOfMeasurement.Metric;
      const extendedMeasurements =
        this.measurements
          .filter((measurement) => measurement.rootNoteId === noteId)
          .map((measurement) => {
            const data = measurement.data as TVerticle[];
            let value: number = 0;
            for (let index = 0; index < data.length - 1; index++) {
              value += distanceBetweenVectors(data[index], data[index + 1]);
            }
            return {
              title: measurement.title,
              color: measurement.color,
              rootNoteCreatorId: measurement.rootNoteCreatorId,
              rootNoteId: measurement.rootNoteId,
              displayValue: `${this._unitsPreferredByUserPipe
                .transform(value, units)
                .toFixed(4)} ${DISTANCE_CONVERSION[units].segmentUnit}`
            } as TMeasurementExtended;
          }) || [];

      return extendedMeasurements;
    }
  );
  areaMeasurementsId(_: number, item: TMeasurementExtended) {
    return item.id;
  }

  edit(area: TMeasurementExtended) {
    this._measurementFacade.selectedMeasurement(area.id);
    this.goToAreaMeasurement.emit();
  }
  unassignFromNote({ id }: TMeasurementExtended) {
    this._measurementFacade.unassignMeasurementFromNote({
      id
    });
  }
}
