import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  CalculateDistanceMeasurementSumPipe,
  CalculateMeasurementSumPipe,
  DISTANCE_CONVERSION,
  UnitsDistancePreferredByUserPipe,
  UnitsPreferredByUserPipe
} from '@simlab/feature-measurement/pipes';
import {
  DistanceFacade,
  MeasurementFacade
} from '@simlab/feature-measurement/state';
import { TMeasurementMesh } from '@simlab/simlab-facility-management/sub-features/line-measurement';
import { SIDENAV_CONTENT } from '@simlab/ui/sidenav';
import { displayDistanceMeasurementValue } from '../../../functions/displayValue';
import { distanceParamsToSort } from '../../../functions/params-to-sort';
import {
  DISPLAY_VALUE,
  MEASUREMENT_CONVERSION,
  PARAMS_TO_SORT,
  SORT_OPTION,
  TYPE_MEASUREMENT
} from '../../../tokens/token';
import { MeasurementBaseComponent } from '../measurement-base/measurement-base.component';
import { MeasurementSidenavContent } from '../measurement-sidenav-content';
import { SortOption } from '../search-and-sort-filter/search-and-sort-filter.component';

const DISTANCE_SORT_OPTION: SortOption[] = [
  {
    direction: 'asc',
    display: $localize`:@@NAME_SORT_A_Z:Name A-Z`,
    propertyName: 'title'
  },
  {
    direction: 'desc',
    display: $localize`:@@NAME_SORT_Z_A:Name Z-A`,
    propertyName: 'title'
  },
  {
    direction: 'asc',
    display: $localize`:@@SIZE_SORT_ASC:Size Asc`,
    propertyName: 'displayValue'
  },
  {
    direction: 'desc',
    display: $localize`:@@SIZE_SORT_DESC:Size Desc`,
    propertyName: 'displayValue'
  }
];

@Component({
  selector: 'feature-measurement-distance',
  standalone: true,
  imports: [CommonModule, MeasurementBaseComponent],
  templateUrl: './distance.component.html',
  styleUrl: './distance.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MeasurementFacade, useClass: DistanceFacade },
    { provide: MEASUREMENT_CONVERSION, useValue: DISTANCE_CONVERSION },
    { provide: SORT_OPTION, useValue: DISTANCE_SORT_OPTION },
    {
      provide: CalculateMeasurementSumPipe,
      useClass: CalculateDistanceMeasurementSumPipe
    },
    {
      provide: TYPE_MEASUREMENT,
      useValue: 'measurement-distance'
    },
    {
      provide: UnitsPreferredByUserPipe,
      useClass: UnitsDistancePreferredByUserPipe
    },
    {
      provide: SIDENAV_CONTENT,
      useExisting: DistanceComponent
    },
    {
      provide: DISPLAY_VALUE,
      useValue: displayDistanceMeasurementValue
    },
    {
      provide: PARAMS_TO_SORT,
      useValue: distanceParamsToSort
    }
  ]
})
export class DistanceComponent extends MeasurementSidenavContent<TMeasurementMesh> {}
