import { computed, Directive, input, signal } from '@angular/core';

@Directive({})
export abstract class CommentLengthBase {
  readonly maxLength = input<number>();
  protected readonly commentLength = signal<number>(0);
  protected readonly isMaxLengthErrorVisible = computed(() => {
    const maxLength = this.maxLength();
    const length = this.commentLength();
    return !!maxLength && !!length && length > maxLength;
  });
  protected readonly maxLengthErrorMessage = computed(() => {
    const maxLength = this.maxLength();
    return $localize`:@@MAXIMUM_COMMENT_LENGTH_PH:Maximum length of comment is ${maxLength}.`;
  });
}
