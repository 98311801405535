import { Directive, ElementRef, inject } from '@angular/core';
import {
  outputFromObservable,
  takeUntilDestroyed,
} from '@angular/core/rxjs-interop';
import {
  EMPTY,
  filter,
  from,
  fromEvent,
  map,
  merge,
  mergeMap,
  Observable,
  Subject,
  switchMap,
  tap,
  toArray,
} from 'rxjs';

export type TUploadFile = { toUpload: File; webkitRelativePath: string };

@Directive({
  selector: '[designDragAndDrop]',
  standalone: true,
})
export class DragAndDropDirective {
  readonly subscribed = new Subject<void>();

  private readonly _elementRef = inject(ElementRef);
  private readonly _windowDragNDrop$ = merge(
    fromEvent<DragEvent>(window, 'drop', { capture: false }),
    fromEvent<DragEvent>(window, 'dragover', { capture: false }),
  ).pipe(tap((e) => e && e.preventDefault()));

  private readonly _dragEnter$ = fromEvent<DragEvent>(
    this._elementRef.nativeElement,
    'dragover',
  ).pipe(
    tap((target) => {
      target.dataTransfer &&
        (target.dataTransfer.effectAllowed = 'copy') &&
        (target.dataTransfer.dropEffect = 'copy');

      target.preventDefault();
      target.stopPropagation();
    }),
  );

  private readonly _dragLeave$ = fromEvent<DragEvent>(
    this._elementRef.nativeElement,
    'dragleave',
  ).pipe(
    tap((event) => {
      event.preventDefault();
      event.stopPropagation();
    }),
  );

  traverseFileTree(items: DataTransferItemList): Observable<TUploadFile[]> {
    const traverse = (
      item: FileSystemEntry,
      path: string,
    ): Observable<TUploadFile> => {
      if (this._isFile(item)) {
        return new Observable((observer) => {
          item.file((file: File) => {
            observer.next({
              toUpload: file,
              webkitRelativePath: item.fullPath.substring(1),
            });
            observer.complete();
          });
        });
      } else if (this._isDirectory(item)) {
        return new Observable((observer) => {
          const dirReader = item.createReader();
          dirReader.readEntries((entries: any[]) => {
            from(entries)
              .pipe(
                mergeMap((entry) => traverse(entry, `${path}${item.name}/`)),
              )
              .subscribe({
                next: (value) => observer.next(value),
                complete: () => observer.complete(),
              });
          });
        });
      }
      return new Observable((observer) => observer.complete());
    };

    return from(Array.from(items)).pipe(
      mergeMap((item, index) => {
        const entry = item.webkitGetAsEntry();
        if (entry) {
          return traverse(entry, '');
        }
        return EMPTY;
      }),
      toArray(),
    );
  }

  private readonly _drop$ = fromEvent<DragEvent>(
    this._elementRef.nativeElement,
    'drop',
  ).pipe(
    map((target) => target.dataTransfer),
    filter((dataTransfer): dataTransfer is DataTransfer => !!dataTransfer),
    switchMap((dataTransfer) =>
      this.traverseFileTree(dataTransfer.items || dataTransfer.files),
    ),
  );
  readonly dragEnter = outputFromObservable<DragEvent>(this._dragEnter$);
  readonly dragLeave = outputFromObservable<DragEvent>(this._dragLeave$);
  readonly dropedFiles = outputFromObservable<TUploadFile[]>(this._drop$);

  constructor() {
    this._windowDropObserver();
  }

  private _isDirectory(
    item: FileSystemEntry,
  ): item is FileSystemDirectoryEntry {
    return item.isDirectory;
  }

  private _windowDropObserver() {
    this._windowDragNDrop$.pipe(takeUntilDestroyed()).subscribe();
  }
  private _isFile(entry: FileSystemEntry): entry is FileSystemFileEntry {
    return 'file' in entry;
  }
}
