import { InjectionToken } from '@angular/core';
import { DesignSymbol } from '@simlab/design/internal';
import { DocumentComponentsLoadedState } from '@simlab/documents/models';

export interface DocumentsAction {
  name: string;
  icon: DesignSymbol;
  trigger: (data: DocumentComponentsLoadedState) => unknown;
}

export interface DocumentsActionsBase {
  actionList: DocumentsAction[];
}

export const documentsActionsToken = new InjectionToken<DocumentsActionsBase>(
  'documents-actions-token'
);
