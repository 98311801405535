<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel i18n="@@ORGANIZATION_FOR_NEW_PROJECT">
      Select an organization for the project
    </h2>
  </ng-container>

  <ng-container designDialogContent [formGroup]="formGroup">
    <ng-container *ngIf="hasOrganizations !== undefined; else spinner">
      <ng-container *ngIf="hasOrganizations; else createOrganization">
        <ui-form-field>
          <label i18n="@@SELECT_ORGANIZATION">Select organization</label>
          <ui-select
            formControlName="organizationId"
            i18n-placeholder="@@SELECT_OPTION"
            placeholder="Select option"
            data-cy="select-organization"
          >
            <ui-option
              *ngFor="let organization of organizations; trackBy: trackRoleById"
              [value]="organization.organizationId"
              [attr.data-cy]="'org-option-' + organization.organizationName"
            >
              {{ organization.organizationName }}
            </ui-option>
          </ui-select>
        </ui-form-field>
      </ng-container>
    </ng-container>
    <ng-template #createOrganization>
      <div data-cy="info-not-organization" i18n="@@INFO_NO_ORGANIZATION">
        You don't have any organization where you can start new project. Setup
        your own organization or ask for permission in organization where you
        are invited.
      </div>
    </ng-template>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-stroked-button
      designButtonColor="primary"
      (click)="closeDialog()"
      data-cy="add-project-cancel-button"
    >
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <ng-container *ngIf="hasOrganizations; else goToOrganization">
      <button
        design-flat-button
        designButtonColor="accent"
        (click)="confirmDialog()"
        [disabled]="formGroup.valid === false"
        data-cy="add-project-confirm-button"
      >
        <div i18n="@@CONFIRM">Confirm</div>
      </button>
    </ng-container>

    <ng-template #goToOrganization>
      <button
        design-flat-button
        designButtonColor="accent"
        (click)="goToOrganizations()"
        data-cy="go-to-organization-button"
      >
        <div i18n="@@GO_TO_ORGANIZATION">Go to organizations</div>
      </button>
    </ng-template>
  </ng-container>
</design-dialog-wrapper>

<ng-template #spinner>
  <div class="spinner-wrapper">
    <ui-progress-spinner></ui-progress-spinner>
  </div>
</ng-template>
