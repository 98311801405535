export const NoteTypeRecords: Record<NoteType, string> = {
  Issue: $localize`:@@NOTE_TYPE_ISSUE:Issue`,
  Information: $localize`:@@NOTE_TYPE_INFORMATION:Information`
} as const;

export const NoteIssueTypeStatusRecords: Record<
  NoteExtendStatus<'Issue'>,
  string
> = {
  Pending: $localize`:@@NOTE_STATUS_PENDING:Pending`,
  InProgress: $localize`:@@NOTE_STATUS_IN_PROGRESS:In Progress`,
  Resolved: $localize`:@@NOTE_STATUS_RESOLVED:Resolved`,
  Unresolved: $localize`:@@NOTE_STATUS_UNRESOLVED:Unresolved`
} as const;

export const NoteTypeWithStatusesMap: NoteTypeWithStatusesMap = {
  Issue: {
    ...NoteIssueTypeStatusRecords
  },
  Information: {
    None: 'None'
  }
} as const;

export type NoteTypeWithStatusesMap = {
  [K in NoteType]: Record<NoteExtendStatus<K>, string>;
};

export type NoteExtendStatus<T extends NoteType> = T extends 'Issue'
  ? NoteStatus
  : 'None';
export type NoteType = 'Issue' | 'Information';
export type NoteStatus = 'Pending' | 'InProgress' | 'Resolved' | 'Unresolved';
