import { DocumentComponentsLoadedState } from '@simlab/documents/models';

export function mapIds(
  elements: DocumentComponentsLoadedState[],
  filterCondition: (element: DocumentComponentsLoadedState) => boolean
) {
  return elements.reduce<string[]>((acc, element) => {
    if (filterCondition(element)) {
      acc.push(element.id);
    }
    return acc;
  }, []);
}
