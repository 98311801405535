<documents-root>
  <documents-action-panel></documents-action-panel>
  <documents-selected-item-action-panel>
    <button
      design-icon-button
      type="button"
      (click)="downloadDocuments()"
      class="action-btn"
    >
      <i
        designIcon="file_download"
        designSize="24px"
        class="action-btn__icon"
      ></i>
    </button>
  </documents-selected-item-action-panel>
</documents-root>

<ng-template #downloadTask let-context="context">
  <event-ui-element
    [title]="context.title"
    [subTitle]="context.subTitle"
    [currentElement]="context.currentElement"
    [countOfElements]="context.countOfElements"
    [status]="context.status"
  />
</ng-template>
