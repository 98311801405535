import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  forwardRef,
  inject,
  input,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Event,
  NavigationEnd,
  Params,
  Router,
  RouterModule
} from '@angular/router';
import {
  OrganizationLimitsFacade,
  ProcoreConnectionStatusEnum,
  Project
} from '@simlab/data-access';
import {
  PositionCameraService,
  RouterFacadeService,
  StageFiltersService
} from '@simlab/util-shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AnnotationsFacade,
  ProjectPrivilegesFacade,
  ProjectsFacade,
  UserPreferenceFacade
} from '@simlab/data-store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { NotesFacade, StagesFacade } from '@simlab/data-store';
import { HamburgerService, NoteSidePanelService } from '@simlab/util/core';
import {
  BehaviorSubject,
  defer,
  EMPTY,
  exhaustMap,
  filter,
  firstValueFrom,
  from,
  map,
  mergeMap,
  Observable,
  pairwise,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';

import {
  matterport3DWalkControlToken,
  Matterport3DWalkInterface
} from '@simlab/feature/matterport';
import { Camera } from '@simlab/matterport/assets/mpSdk/sdk';

import {
  AsyncPipe,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet
} from '@angular/common';
import {
  DesignFabButtonMenu,
  DesignFabButtonModule,
  DesignFlatButtonModule
} from '@simlab/design/button';

import { Dialog } from '@angular/cdk/dialog';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  MatterportAnnotationControlService,
  MatterportNoteControlService
} from '@simlab/annotation/data-access';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { UiTooltip } from '@simlab/design/tooltip';
import { AreaFacade, DistanceFacade } from '@simlab/feature-measurement/state';
import {
  AreaComponent,
  DistanceComponent
} from '@simlab/feature-measurement/ui';
import { ApiProjectService } from '@simlab/feature-stages/data-access';
import { StageSidePanelComponent } from '@simlab/feature-stages/ui';
import {
  NoteMediaService,
  PanelLeftComponent,
  PanelRightComponent,
  PanelRightService,
  PanelState,
  ProjectLimitsService,
  StagesRootService
} from '@simlab/feature/stages';
import {
  OnTransferPunchItemElementModel,
  OnTransferRFIElementModel,
  PunchItemSidePanelComponent,
  RfiSidePanelComponent,
  TStageNameId,
  TTransferModalData,
  TTransferModalResults
} from '@simlab/procore/annotation-panel/ui';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  AnnotationsProcorePermissionsService,
  PunchItemsListStore,
  RFIListStore
} from '@simlab/procore/services';
import { UiMenuModule } from '@simlab/ui/menu';
import { SidenavComponent, UiSidenavModule } from '@simlab/ui/sidenav';
import { TitleService } from '@simlab/ui/title';
import { AnnotationsFilterService } from 'libs/pages/project/services/annotations-filter.service';
import { AnnotationsActiveFilterChipsComponent } from '../../../ui/src/lib/components/annotations-active-filter-chips/annotations-active-filter-chips.component';

const GEN_STAGES = $localize`:@@GEN_STAGES:Stages`;

type ProcoreTransferElements = {
  'Punch Item': OnTransferPunchItemElementModel;
  RFI: OnTransferRFIElementModel;
};

export type TSidenavContent =
  | 'note'
  | 'rfi'
  | 'punch-item'
  | 'measurement-area'
  | 'measurement-distance';
export type StageRootType = 'info' | 'notes' | 'matterport';
@Component({
  standalone: true,
  templateUrl: './stages-root.component.html',
  styleUrls: ['./stages-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgIf,
    NgTemplateOutlet,
    NgSwitch,
    NgSwitchCase,
    UiSidenavModule,
    PanelLeftComponent,
    DistanceComponent,
    AreaComponent,
    PanelRightComponent,
    RfiSidePanelComponent,
    UiTooltip,
    DesignFlatButtonModule,
    DesignFabButtonModule,
    UiMenuPanelModule,
    UiMenuModule,
    RouterModule,
    StageSidePanelComponent,
    PunchItemSidePanelComponent,
    DesignFabButtonMenu,
    AnnotationsActiveFilterChipsComponent
  ],
  providers: [
    RFIListStore,
    PunchItemsListStore,
    AnnotationsProcorePermissionsService,
    AnnotationsFacade,
    PositionCameraService,
    ProjectLimitsService,
    StagesRootService,
    PanelRightService,
    StageFiltersService,
    NoteMediaService,
    OrganizationLimitsFacade,
    MatterportNoteControlService,
    AnnotationsFilterService,
    MatterportAnnotationControlService,
    {
      provide: matterport3DWalkControlToken,
      useExisting: forwardRef(() => StagesRootComponent)
    },
    ApiProjectService
  ]
})
export class StagesRootComponent
  implements OnDestroy, Matterport3DWalkInterface, OnInit
{
  private readonly _viewRef = inject(ViewContainerRef);

  private readonly _dialog = inject(Dialog);
  private readonly _areaFacade = inject(AreaFacade);
  private readonly _distanceFacade = inject(DistanceFacade);
  private readonly _apiProcoreService = inject(ApiProcoreService);
  private readonly _annotationFacade = inject(AnnotationsFacade);
  private readonly _userPreferenceFacade = inject(UserPreferenceFacade);
  readonly systemOfMeasurement$ =
    this._userPreferenceFacade.getUserPreference$.pipe(
      map((e) => e?.systemOfMeasurement)
    );
  private readonly routerFacade = inject(RouterFacadeService);
  private readonly _closeSidePanelBySidenavContentValue$ =
    this.activatedRoute.queryParams.pipe(
      map((params) => params['sidenavContent']),
      tap((sidenavContent) => {
        sidenavContent
          ? this.noteSidePanelService.open()
          : this.noteSidePanelService.close();
      })
    );
  readonly projectId = input.required<string>();
  readonly stageId = input.required<string>();
  readonly sidenavContent$: Observable<TSidenavContent | undefined> =
    this.routerFacade
      .getNthRouterParam$(0)
      .pipe(
        map(
          (route: ActivatedRouteSnapshot | null) =>
            route?.queryParams['sidenavContent'] as TSidenavContent
        )
      );
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private _smallScreen = false;

  @ViewChild('leftPanel', { read: PanelLeftComponent })
  private _leftPanel!: PanelLeftComponent;

  @ViewChildren('rightPanel', { read: SidenavComponent })
  readonly rightPanel!: QueryList<SidenavComponent>;

  readonly stageName = toSignal(
    this.stagesFacade.selectedStages$.pipe(
      map((stage) => (stage ? stage.name : ''))
    ),
    { initialValue: '' }
  );

  readonly notes = toSignal(
    this.stagesFacade.selectedId$.pipe(
      filter((stageId: string) => !!stageId),
      switchMap((stageId: string) => {
        this.noteSidePanelService.close();
        return this.notesFacade
          .getSimpleRootNotesForStage$({ stageId })
          .pipe(shareReplay(1));
      })
    )
  );

  private _observableStageId = effect(
    () => {
      const stageId = this.stageId();
      this._areaFacade.init(stageId);
      this._distanceFacade.init(stageId);
    },
    {
      allowSignalWrites: true
    }
  );

  readonly noteAdded$ = this.notesFacade.noteAdded$.pipe(map((n) => n.id));

  readonly noteId = toSignal(this.notesFacade.selectedNoteId$);

  createNote(value: { name: string; stageId: string }) {
    this.notesFacade.addSimpleNote({
      name: value.name,
      type: 'Information',
      status: 'None',
      marker: undefined,
      stageId: value.stageId
    });
  }

  readonly rightPanelContent$ = <T>(): Observable<T> =>
    defer(() => {
      const rightPanel = this.rightPanel?.first;
      if (rightPanel) {
        return rightPanel.sidenavContent.changes.pipe(
          startWith(rightPanel.sidenavContent),
          filter((component) => component && !!component.first),
          map((component) => component.first)
        );
      }
      return this.ngZone.onStable.pipe(
        switchMap(() => this.rightPanelContent$()),
        take(1)
      );
    });

  readonly containStages$: Observable<boolean> =
    this.stagesFacade.containsAnyStage$;

  readonly screenshot$: Observable<boolean> =
    this.stagesRootService.screenshot$;

  readonly hamburgerState$: Observable<boolean> = this.hamburgerService.state$;

  readonly noteSidePanelState$: Observable<boolean> =
    this.noteSidePanelService.state$;
  readonly canAddNote$: Observable<boolean> =
    this.privilegesFacade.addEditDeleteOwnNotes$;

  private readonly _matterportRoot: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  readonly matterportRoot$: Observable<boolean> =
    this._matterportRoot.asObservable();

  get menuVisibilityState(): boolean {
    return (
      this.activatedRoute.snapshot.firstChild?.routeConfig?.path !==
      'synchronization'
    );
  }

  readonly screenshotMode$: Observable<boolean> =
    this.stagesRootService.screenshot$;

  constructor(
    private readonly stagesRootService: StagesRootService,
    private readonly titleService: TitleService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly projectsFacade: ProjectsFacade,
    private readonly router: Router,
    private readonly privilegesFacade: ProjectPrivilegesFacade,
    private readonly noteSidePanelService: NoteSidePanelService,
    private readonly hamburgerService: HamburgerService,
    private readonly ngZone: NgZone,
    private readonly notesFacade: NotesFacade,
    private readonly stagesFacade: StagesFacade,
    private readonly panelService: PanelRightService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.screenObserver();
    this.rightPanelStateObserver();
    this.leftPanelStateObserver();
    this._selectedNoteIdObserver();
    this._routerEventsObservable();

    this._closeSidePanelBySidenavContentValue$
      .pipe(takeUntilDestroyed())
      .subscribe();
  }
  ngOnInit(): void {
    this.setTitle();
    this._procoreConnectionChangeStatusObserver();
  }

  rightPanelOpenedChange($event: boolean) {
    this.stagesRootService.rightPanelState = $event ? 'open' : 'close';
  }
  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  onOpenRelatedNote(id: string) {
    this.notesFacade.selectedNoteId(id);
  }

  onTransferElement<TElementType extends keyof ProcoreTransferElements>(
    type: TElementType,
    model: ProcoreTransferElements[TElementType]
  ) {
    const allStages = this.stagesFacade.allStages();

    if (!allStages) throw new Error('Not found Stages for transfer');

    const data: TTransferModalData = {
      type,
      stages: allStages
        .map((stage) => ({ name: stage.name, id: stage.id }) as TStageNameId)
        .filter((stage) => stage.id !== this.stageId()),
      itemId: model.id
    };

    const isRFI = (model: unknown): model is ProcoreTransferElements['RFI'] =>
      type === 'RFI';

    from(import('@simlab/procore/annotation-panel/ui'))
      .pipe(
        take(1),
        exhaustMap(
          ({ TransferModalComponent }) =>
            this._dialog.open<TTransferModalResults, TTransferModalData>(
              TransferModalComponent,
              {
                data,
                width: 'min(90%, 610px)'
              }
            ).closed
        ),
        filter((results): results is TTransferModalResults => !!results),
        exhaustMap((results) => {
          if (results.state && results.data)
            return isRFI(model)
              ? this._transferRfi$(results.data, model)
              : this._transferPunchItem$(results.data, model);

          return EMPTY;
        }),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  private _transferRfi$(
    data: Exclude<TTransferModalResults['data'], undefined>,
    model: ProcoreTransferElements['RFI']
  ) {
    return this._apiProcoreService.transferRfi(data).pipe(
      tap(() => {
        this.stagesFacade.incrementRfiCount(data.stageId, model.status);
        this.stagesFacade.decrementRfiCount(this.stageId(), model.status);
        this._annotationFacade.deleteRfiItemFromStore(model.id);
        this.closeAndCleanSidenav();
      })
    );
  }

  private _transferPunchItem$(
    data: Exclude<TTransferModalResults['data'], undefined>,
    model: ProcoreTransferElements['Punch Item']
  ) {
    return this._apiProcoreService.transferPunchItem(data).pipe(
      tap(() => {
        this.stagesFacade.incrementPunchItemCount(
          data.stageId,
          model.withActionNumber
        );
        this.stagesFacade.decrementPunchItemCount(
          this.stageId(),
          model.withActionNumber
        );
        this._annotationFacade.deletePunchItemFromStore(model.id);
        this.closeAndCleanSidenav();
      })
    );
  }

  private _routerEventsObservable() {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        map((event: Event) =>
          this._matterportRoot.next(
            (event as NavigationEnd).urlAfterRedirects.includes('matterport')
          )
        ),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  private _selectedNoteIdObserver(): void {
    this.notesFacade.selectedNoteId$
      .pipe(
        tap((noteId: string | undefined) => {
          if (noteId && this.router.url.includes('notes')) {
            this.noteSidePanelService.open();
          }
          if (!noteId && this.router.url.includes('sidenavContent=note')) {
            this.noteSidePanelService.close();
          }
        })
      )
      .subscribe();
  }

  private _procoreConnectionChangeStatusObserver(): void {
    const projectId = this.projectId();

    this.projectsFacade
      .procoreConnectionStatus$(projectId)
      .pipe(
        pairwise(),
        tap(([prevStatus, currStatus]) => {
          const { Disconnecting, Disconnected, Reconnecting, Connected } =
            ProcoreConnectionStatusEnum;

          const isDisconnected =
            prevStatus === Disconnecting && currStatus === Disconnected;
          if (isDisconnected) {
            this.stagesFacade.resetProcoreCountsFromStages();
            this._annotationFacade.resetListStates();
          }

          const isReconnected =
            prevStatus === Reconnecting && currStatus === Connected;
          const isConnected =
            prevStatus === Disconnected && currStatus === Connected;
          if (isReconnected || isConnected) {
            this.stagesFacade.initStore(projectId);
          }
        }),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  openLeftPanel(): void {
    if (this._smallScreen) {
      this.stagesRootService.rightPanelState = 'close';
    }
    this.stagesRootService.leftPanelState = 'open';
  }
  openRightPanel(): void {
    if (this._smallScreen) {
      this.stagesRootService.leftPanelState = 'close';
    }
    this.stagesRootService.rightPanelState = 'open';
  }
  closeLeftPanel(): void {
    this.stagesRootService.leftPanelState = 'close';
  }

  closeRightPanel(): void {
    this.stagesRootService.rightPanelState = 'close';
  }

  closeAndCleanSidenav() {
    const queryParams: Params = {
      sidenavContent: null,
      punchItemId: null,
      rfiId: null
    };
    this.routerFacade.setQueryParams(undefined, queryParams, 'merge');
    this.closeRightPanel();
  }

  closeMeasurementTool(
    sidenavContent: 'measurement-area' | 'measurement-distance' | null
  ) {
    const queryParams: Params = { sidenavContent };
    this.routerFacade.setQueryParams(undefined, queryParams, 'merge');
    this.closeRightPanel();
  }

  private setTitle(): void {
    if (this.projectId()) {
      firstValueFrom(
        this.projectsFacade.selectProjectById$(this.projectId()).pipe(
          filter((project) => project !== undefined),
          mergeMap((project: Project | undefined) =>
            this.ngZone.onStable.asObservable().pipe(
              take(1),
              tap(() => {
                this.titleService.setTitle(project?.name ?? GEN_STAGES);
                this._leftPanel.owner = project?.isOwner ?? false;
              })
            )
          )
        )
      );
    }
  }

  private screenObserver() {
    const breakpoints: string[] = [
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait,
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
      Breakpoints.Small
    ];

    this.breakpointObserver
      .observe(breakpoints)
      .pipe(takeUntil(this._destroySource))
      .subscribe((result) => {
        if (
          !this._smallScreen &&
          result.matches &&
          this.stagesRootService.rightPanelState === 'open' &&
          this.stagesRootService.leftPanelState === 'open'
        ) {
          this.stagesRootService.rightPanelState = 'close';
        }
        this._smallScreen = result.matches;
        this.stagesRootService.mobileScreenSize = result.matches;
      });
  }
  openMeasurementTool(type: 'area' | 'distance') {
    const queryParams: Params = { sidenavContent: 'measurement-' + type };

    this.router
      .navigate(['./matterport'], {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge'
      })
      .then(() => this.openRightPanel());
  }
  private rightPanelStateObserver(): void {
    this.stagesRootService.rightPanelState$
      .pipe(takeUntil(this._destroySource))
      .subscribe((state: PanelState) => {
        if (state === 'open') {
          this.noteSidePanelService.open();
        } else {
          this.noteSidePanelService.close();
        }
      });
  }

  private leftPanelStateObserver(): void {
    this.stagesRootService.leftPanelState$
      .pipe(takeUntil(this._destroySource))
      .subscribe((state: PanelState) => {
        if (state === 'open') {
          this.hamburgerService.open();
        } else {
          this.hamburgerService.close();
        }
      });
  }

  private async _createGlobalFiltersDialog() {
    const component = (
      await import(
        '../../../ui/src/lib/components/annotations-filter/annotations-filter.component'
      )
    ).AnnotationsFilterComponent;
    this._dialog.open(component, {
      width: 'min(655px, 95%)',
      height: 'min(90%, 850px)',
      data: this.projectId(),
      viewContainerRef: this._viewRef
    });
  }

  openFilterModal(): void {
    this._createGlobalFiltersDialog();
  }

  goToSplitView(position: Camera.Pose | undefined, floor: number) {
    this.router.navigate([`./splitView`], {
      relativeTo: this.activatedRoute,
      state: { position, floor }
    });
  }

  addNote(): void {
    const queryParams: Params = { sidenavContent: 'note' };
    this.routerFacade.setQueryParams(undefined, queryParams, 'merge');
    this.panelService
      .addNote$()
      .pipe(
        tap((value: boolean) => {
          if (value) {
            this.stagesRootService.rightPanelState = 'open';
          }
        }),
        take(1)
      )
      .subscribe();
  }
}
