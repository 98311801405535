<ng-container *ngIf="audio$ | async; let audio">
  <div class="audio-metadata">
    <div class="metadata-inline-wrapper">
      <i ui-font fontName="users" fontSize="1.25rem"></i>
      <span> {{ audio.addedByName }}</span>
    </div>
    <div class="metadata-inline-wrapper">
      <i ui-font fontName="calendar" fontSize="1.25rem"></i>
      <span>{{ audio.addedAt | formatDate | async }}</span>
    </div>
  </div>
  <ui-audio [src]="audio.privateUrl" [title]="audio.name" controls>
    <button design-menu-item (click)="deleteAudioNote(audio)">
      <ng-container i18n="@@REMOVE">Remove</ng-container>
    </button>
  </ui-audio>
</ng-container>
