import { Platform } from '@angular/cdk/platform';
import { Injectable, inject } from '@angular/core';
import { filter, fromEvent, map, merge, startWith } from 'rxjs';

@Injectable()
export class DocumentsPlatformKeyEventsService {
  private readonly _platform = inject(Platform);

  bindKeys(event: KeyboardEvent) {
    return {
      ctrl: this._platform.IOS ? event.metaKey : event.ctrlKey,
      shift: event.shiftKey,
      delete: event.key === 'Delete'
    };
  }

  readonly keyStates$ = merge(
    fromEvent<KeyboardEvent>(document, 'keydown'),
    fromEvent<KeyboardEvent>(document, 'keyup')
  ).pipe(
    startWith(undefined),
    filter((event) => !event?.repeat),
    map((event) => (event ? this.bindKeys(event) : undefined))
  );

  readonly keyDownStates$ = fromEvent<KeyboardEvent>(document, 'keydown').pipe(
    startWith(undefined),
    filter((event) => !event?.repeat),
    map((event) => (event ? this.bindKeys(event) : undefined))
  );
}
