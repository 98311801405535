import { inject, Injectable } from '@angular/core';
import { PunchItemState } from '@simlab/procore/annotation-panel/services';
import { OpenNewTabService } from '@simlab/util-shared';

@Injectable()
export abstract class BasePunchItemSidePanel {
  protected readonly punchItemState = inject(PunchItemState);
  protected readonly openNewTab = inject(OpenNewTabService);

  protected readonly punchItem = this.punchItemState.punchItem;
  protected readonly isLoading = this.punchItemState.isLoading;
  protected readonly displayStatus = this.punchItemState.displayStatus;
}
