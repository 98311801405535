import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  API_URL,
  Marker,
  PunchItemsWithMarkers,
  RFIWithMarkers
} from '@simlab/data-access';
import {
  AddUserToken,
  AssignProject,
  BallInCourtUser,
  Company,
  DeleteProcoreItemPayload,
  ProcoreDataProgressResponse,
  ProcoreItemGetPayload,
  ProcorePermission,
  Project,
  PunchItem,
  PunchItemDisplayElements,
  PunchItemPayload,
  PunchItemsGetPayload,
  PunchItemsResponse,
  RFI,
  RFIGetPayload,
  RFIListResponse,
  RfiUserDisplayElements,
  RFIUserDisplayElementsRequest,
  TransferElementByStage,
  UnAssignProject,
  UserToken
} from '@simlab/procore/models';
import { requestToHttpParamsMapper } from '@simlab/util/core';
import { catchError, mergeMap, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiProcoreService {
  public readonly url = inject<string>(API_URL);
  public readonly httpClient = inject(HttpClient);

  hasUserProcoreToken(): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.url}/users-module/has-user-procore-token`
    );
  }

  getUserCompanyPermissions(
    procoreCompanyId: number
  ): Observable<ProcorePermission[]> {
    return this.httpClient.get<ProcorePermission[]>(
      `${this.url}/procore-api/get-user-company-permissions?procoreCompanyId=${procoreCompanyId}`
    );
  }

  getCompanies$(): Observable<Company[]> {
    return this.httpClient.get<Company[]>(
      `${this.url}/procore-api/get-companies`
    );
  }

  getCompanyProjects$(procoreCompanyId: number): Observable<Project[]> {
    return this.httpClient.get<Project[]>(
      `${this.url}/procore-api/get-company-projects?procoreCompanyId=${procoreCompanyId}`
    );
  }

  getUserProjectPermissions({
    procoreCompanyId,
    procoreProjectId
  }: {
    procoreCompanyId: number;
    procoreProjectId: number;
  }): Observable<ProcorePermission[]> {
    return this.httpClient.get<ProcorePermission[]>(
      `${this.url}/procore-api/get-user-project-permissions?procoreCompanyId=${procoreCompanyId}&procoreProjectId=${procoreProjectId}`
    );
  }

  assignProcoreProject$(body: AssignProject): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/assign-procore-project`,
      body
    );
  }

  unAssignProcoreProject$(body: UnAssignProject): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/unassign-procore-project`,
      body
    );
  }

  getProcoreUserToken(): Observable<UserToken> {
    return this.httpClient.get<UserToken>(
      `${this.url}/users-module/get-procore-user-token`
    );
  }

  getProcoreAuthorizationCodeUrl(returnUrl: string): Observable<string> {
    return this.httpClient.get(
      `${this.url}/users-module/get-procore-authorization-code-url?returnUrl=${returnUrl}`,
      { responseType: 'text' }
    );
  }

  addProcoreUserToken(body: AddUserToken): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/users-module/add-procore-user-token`,
      body
    );
  }

  removeProcoreUserToken(): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.url}/users-module/remove-procore-user-token`
    );
  }

  reassignProcoreItems(projectId: string, includeManuallyAssigned: boolean) {
    return this.httpClient
      .post(`${this.url}/procore-module/reassign-procore-items-to-stages`, {
        projectId,
        includeManuallyAssigned
      })
      .pipe(mergeMap(() => this.importProcoreData(projectId)));
  }

  importProcoreData(projectId: string) {
    return this.httpClient.post(
      `${this.url}/procore-module/import-procore-data`,
      { projectId }
    );
  }

  getRfiList(payload: RFIGetPayload) {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<RFIListResponse>(
      `${this.url}/procore-module/get-rfis`,
      {
        params
      }
    );
  }

  getPunchItemsList(payload: PunchItemsGetPayload) {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItemsResponse>(
      `${this.url}/procore-module/get-punch-items`,
      {
        params
      }
    );
  }

  deletePunchItem(payload: DeleteProcoreItemPayload) {
    const params = requestToHttpParamsMapper(payload);
    return this.httpClient.delete<void>(
      `${this.url}/procore-module/delete-punch-item`,
      {
        params
      }
    );
  }

  deleteRfi(payload: DeleteProcoreItemPayload) {
    const params = requestToHttpParamsMapper(payload);
    return this.httpClient.delete<void>(
      `${this.url}/procore-module/delete-rfi`,
      { params }
    );
  }

  transferPunchItem(body: TransferElementByStage) {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/move-punch-item-to-stage`,
      body
    );
  }

  transferRfi(body: TransferElementByStage) {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/move-rfi-to-stage`,
      body
    );
  }

  getRfiItemBallInCourtUsers(projectId: string): Observable<BallInCourtUser[]> {
    return this.httpClient
      .get<
        BallInCourtUser[]
      >(`${this.url}/procore-module/get-rfi-ball-in-court-users?projectId=${projectId}`)
      .pipe(
        catchError((e) => {
          console.error(e);
          return [];
        })
      );
  }

  getPunchItemBallInCourtUsers(
    projectId: string
  ): Observable<BallInCourtUser[]> {
    return this.httpClient.get<BallInCourtUser[]>(
      `${this.url}/procore-module/get-punch-item-ball-in-court-users?projectId=${projectId}`
    );
  }

  placeMarkerToPunchItem(payload: { id: string } & Marker): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/place-marker-to-punch-item`,
      payload
    );
  }

  placeMarkerToRFI(payload: { id: string } & Marker): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/place-marker-to-rfi`,
      payload
    );
  }

  getRfi(payload: ProcoreItemGetPayload): Observable<RFI> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<RFI>(`${this.url}/procore-module/get-rfi`, {
      params
    });
  }

  getPunchItem(payload: ProcoreItemGetPayload): Observable<PunchItem> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItem>(
      `${this.url}/procore-module/get-punch-item`,
      {
        params
      }
    );
  }

  getPunchItemDisplayElements(
    payload: PunchItemPayload
  ): Observable<PunchItemDisplayElements> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItemDisplayElements>(
      `${this.url}/procore-module/get-user-punch-item-display-elements`,
      {
        params
      }
    );
  }

  isAnythingToImportFromProcore(projectId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.url}/procore-module/is-anything-to-import?projectId=${projectId}`
    );
  }

  removeMarkerFromPunchItem(punchItemId: string): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/remove-marker-from-punch-item`,
      { id: punchItemId }
    );
  }

  removeMarkerFromRFI(punchItemId: string): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/remove-marker-from-rfi`,
      { id: punchItemId }
    );
  }

  getPunchItemsWithMarkersForStages(
    payload: Omit<PunchItemsGetPayload, 'page'>
  ): Observable<PunchItemsWithMarkers> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItemsWithMarkers>(
      `${this.url}/procore-module/get-punch-items-with-markers-for-stages`,
      { params }
    );
  }

  getRfisWithMarkersForStages(
    payload: Omit<RFIGetPayload, 'page'>
  ): Observable<RFIWithMarkers> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<RFIWithMarkers>(
      `${this.url}/procore-module/get-rfis-with-markers-for-stages`,
      { params }
    );
  }

  getRfiAllowedActions(
    payload: RFIUserDisplayElementsRequest
  ): Observable<RfiUserDisplayElements> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<RfiUserDisplayElements>(
      `${this.url}/procore-module/get-user-rfi-display-elements`,
      { params }
    );
  }

  getImportProcoreDataProgress(
    projectId: string
  ): Observable<ProcoreDataProgressResponse> {
    return this.httpClient.get<ProcoreDataProgressResponse>(
      `${this.url}/procore-module/get-import-procore-data-progress?projectId=${projectId}`
    );
  }
}
