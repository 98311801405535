/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  ViewEncapsulation,
  input
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { DesignPrefix } from '@simlab/design/common';
import { DesignFormField } from '@simlab/design/form-field';
import { DesignIcon } from '@simlab/design/icon';
import { DesignInput } from '@simlab/design/input';
import { UiSelectModule } from '@simlab/ui/select';
import { Observable, defer, map } from 'rxjs';

export class FilterChange {
  constructor(
    readonly filter: string | undefined | null,
    readonly sortBy: SortOption | undefined | null
  ) {}
}
type FilterForm = {
  [Property in keyof FilterChange]: FormControl<FilterChange[Property]>;
};

export type SortOption = {
  display: string;
  propertyName: string;
  direction: 'asc' | 'desc';
};
export const DEFAULT_SORT_OPTIONS: SortOption[] = [
  {
    display: 'User name A-Z (desc)',
    propertyName: 'userName',
    direction: 'asc'
  },
  {
    display: 'User name Z-A (asc)',
    propertyName: 'userName',
    direction: 'desc'
  },
  { display: 'Name A-Z (desc)', propertyName: 'name', direction: 'asc' },
  { display: 'Name Z-A (asc)', propertyName: 'name', direction: 'desc' }
];
@Component({
  selector: 'feature-measurement-search-and-sort-filter',
  standalone: true,
  imports: [
    DesignInput,
    UiSelectModule,
    DesignPrefix,
    DesignFormField,
    DesignIcon,
    ReactiveFormsModule,
    FormsModule,
    CommonModule
  ],
  templateUrl: './search-and-sort-filter.component.html',
  styleUrls: ['./search-and-sort-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'search-and-filters'
  }
})
export class SearchAndSortFilterComponent {
  readonly sortOptions = input<SortOption[]>(DEFAULT_SORT_OPTIONS);

  readonly formGroup = new FormGroup<FilterForm>({
    sortBy: new FormControl<SortOption | undefined | null>(undefined),
    filter: new FormControl<string | undefined | null>('')
  });

  @Output() filterChange: Observable<FilterChange> = defer(() =>
    this.formGroup.valueChanges.pipe(
      map(({ filter, sortBy }) => {
        return new FilterChange(filter, sortBy && { ...sortBy });
      })
    )
  );
}
