import { InjectionToken } from '@angular/core';
import { UrlTree } from '@angular/router';

export type FolderAsPathItem = {
  id: string | null;
  name: string;
  url?: string | UrlTree;
  clickHandler?: () => void;
};

export const CUSTOM_ROOT_FOLDER = new InjectionToken<FolderAsPathItem[]>(
  'Custom root folder',
  {
    providedIn: 'root',
    factory: CUSTOM_ROOT_FOLDER_FACTORY
  }
);

export const CUSTOM_MOVE_TO_ACTION_ROOT_FOLDER = new InjectionToken<
  FolderAsPathItem[]
>('Custom root folder for move to action modal purpouse', {
  providedIn: 'root',
  factory: CUSTOM_ROOT_FOLDER_FACTORY
});

export function CUSTOM_ROOT_FOLDER_FACTORY() {
  return ROOT_FOLDER;
}

const ROOT_FOLDER: FolderAsPathItem[] = [
  {
    id: null,
    name: 'Stages Main'
  }
];
