<i class="header__icon" ui-icon [name]="icon()" defaultColor></i>

<header>
  <span>
    <h3 class="header__name">{{ name() }}</h3>
  </span>
  <div class="header__count" i18n="@@X_RESULTS">
    <span class="header__count-value">
      {{ resultCount() }}
    </span>
    results
  </div>
</header>

<div class="status-badge-container">
  <ng-content select="design-status-badge" />
</div>
