import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DesignOption } from '@simlab/design/common';
import { MentionOverlayComponent } from './mention-overlay.component';
import { MentionDirective } from './mention.directive';
import { MentionComponent } from './mention/mention.component';
import { UserComponent } from './user.component';

@NgModule({
  declarations: [
    MentionOverlayComponent,
  ],
  exports: [MentionDirective, MentionComponent],
  imports: [CommonModule, OverlayModule,UserComponent, MentionDirective, MentionComponent, DesignOption],
})
export class UiMentionModule {}
