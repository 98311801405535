<ng-container *ngIf="document$ | async; let document">
  <div class="doc-wrapper">
    <div class="doc-conatiner">
      <div class="doc-info" [title]="document.name">
        <span>{{ document.name }}</span>
      </div>
      <button
        design-icon-button
        (click)="open(idx)"
        type="button"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
      >
        <i designIcon="more_second" designSize="1.25rem"></i>
      </button>
    </div>
  </div>
  <ng-template
    cdkConnectedOverlay
    (overlayOutsideClick)="close()"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen && selectedMenuIdx === idx"
  >
    <ul class="menu-list">
      <li (click)="download(document)">
        <i designIcon="download" designSize="1.5rem"></i>
        <ng-container i18n="@@GEN_DOWNLOAD">Download</ng-container>
      </li>
      <li (click)="delete(document)" class="pointer">
        <i designIcon="trash" designSize="1.5rem"></i>
        <ng-container i18n="@@DELETE">Delete</ng-container>
      </li>
    </ul>
  </ng-template>
</ng-container>
