import { ICON_TYPE } from '@simlab/ui/icon';

export const NoteStatusIcon: Record<string, ICON_TYPE> = {
  Information: 'icon_note_info_type',
  InProgress: 'icon_note_in_progress_type',
  None: 'icon_note_info_type',
  Pending: 'icon_note_pending_type',
  Resolved: 'icon_note_resolved_type',
  Unresolved: 'icon_note_unresolved_type'
};
