import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideEffects } from '@ngrx/effects';
import { ProjectsFacade } from '@simlab/data-store';
import { DesignInput } from '@simlab/design/input';

import { UiButtonModule } from '@simlab/ui/button';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiHintModule } from '@simlab/ui/hint';
import { UiIconModule } from '@simlab/ui/icon';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import { UiInputModule } from '@simlab/ui/input';
import { UiMapModule } from '@simlab/ui/map';
import { UiModalModule } from '@simlab/ui/modal';
import { UiPaginatorModule } from '@simlab/ui/paginator';
import { UiProgressSpinnerModule } from '@simlab/ui/progress-spinner';
import { UiSelectModule } from '@simlab/ui/select';
import { UiSidenavModule } from '@simlab/ui/sidenav';
import { UiTextareaModule } from '@simlab/ui/textarea';
import { UiUploadImageModule } from '@simlab/ui/upload-image';
import { GridContainerItemComponent } from './components/grid-container-item/grid-container-item.component';
import { GridContainerComponent } from './components/grid-container/grid-container.component';
import { LimitReachedActivateProjectModule } from './components/limit-reached-activate-project/limit-reached-activate-project.module';
import { SidePanelContainerComponent } from './components/side-panel-container/side-panel-container.component';
import { SidePanelFiltersComponent } from './components/side-panel-filters/side-panel-filters.component';
import { FeatureProjectsRoutingModule } from './feature-projects.routing';
import { HasOrganizationParamGuard } from './guards/has-organization-param.guard';
import { filterProjectServiceFactory } from './models/projects-filters-base';
import { ListComponent } from './pages/list/list.component';
import { ProjectsRootComponent } from './pages/projects-root/projects-root.component';
import { PipesModule } from './pipes/pipes.module';
import { FILTER_SERVICE_TOKEN } from './tokens/filter-service.token';

const pageDeclarations: Type<any>[] = [ProjectsRootComponent, ListComponent];
const componentDeclarations: Type<any>[] = [
  GridContainerComponent,
  SidePanelContainerComponent
];

const uiImports: Type<any>[] = [
  UiButtonModule,
  UiInputModule,
  UiSelectModule,
  UiModalModule,
  UiUploadImageModule,
  UiFormFieldModule,
  UiTextareaModule,
  UiHintModule,
  UiIconModule,
  UiPaginatorModule,
  UiDividerModule,
  UiSidenavModule,
  UiMapModule,
  UiProgressSpinnerModule
];

const modules: Type<any>[] = [
  FeatureProjectsRoutingModule,
  PortalModule,
  LimitReachedActivateProjectModule,
  UiImageInfoModule
];

@NgModule({
  declarations: [...pageDeclarations, ...componentDeclarations],
  imports: [
    ...modules,
    CommonModule,
    FormsModule,
    PipesModule,
    GridContainerItemComponent,
    ReactiveFormsModule,
    SidePanelFiltersComponent,
    DesignInput,
    ...uiImports
  ],
  providers: [
    provideEffects(ProjectsFacade),
    { provide: FILTER_SERVICE_TOKEN, useFactory: filterProjectServiceFactory },
    HasOrganizationParamGuard
  ]
})
export class FeatureProjectsModule {}
