<ng-container *ngIf="tag" [formGroup]="tagsFormGroupRef">
  <ui-checkbox
    [formControlName]="tag.id"
    *ngIf="tag.id"
    type="customize"
  ></ui-checkbox>
  <!-- [disabled]="disabled" -->

  <label [class.disabled]="disabled">{{ tag.label }}</label>
  <div class="color" style="--clr:{{ tag.color }}"></div>
</ng-container>
