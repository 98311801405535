import { FocusKeyManager } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import { A, ENTER, SPACE, hasModifierKey } from '@angular/cdk/keycodes';
import { _getFocusedElementPierceShadowDom } from '@angular/cdk/platform';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  InjectionToken,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { UiListBase } from '../ui-list-base';
import { UiListOption } from '../ui-list-option/ui-list-option.component';

declare const ngDevMode: boolean;

/**
 * Injection token that can be used to reference instances of `UiSelectionList`. It serves as
 * alternative token to the actual `UiSelectionList` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const SELECTION_LIST = new InjectionToken<UiSelectionList>(
  'UiSelectionList',
);

export const UI_SELECTION_LIST_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UiSelectionList),
  multi: true,
};

/** Change event that is being fired whenever the selected state of an option changes. */
export class UiSelectionListChange {
  constructor(
    /** Reference to the selection list that emitted the event. */
    public source: UiSelectionList,

    /** Reference to the options that have been changed. */
    public options: UiListOption[],
  ) {}
}

@Component({
  selector: 'design-selection-list',
  standalone: true,
  exportAs: 'uiSelectionList',
  templateUrl: './ui-selection-list.component.html',
  styleUrls: ['./ui-selection-list.component.scss'],
  host: {
    class: 'ui-selection-list',
    role: 'listbox',
    '[attr.aria-multiselectable]': 'multiple',
    '(keydown)': '_handleKeydown($event)',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    UI_SELECTION_LIST_VALUE_ACCESSOR,
    { provide: UiListBase, useExisting: UiSelectionList },
    { provide: SELECTION_LIST, useExisting: UiSelectionList },
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiSelectionList
  extends UiListBase
  implements ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges
{
  private _initialized = false;
  private _keyManager!: FocusKeyManager<UiListOption>;

  /** Emits when the list has been destroyed. */
  private _destroyed = new Subject<void>();

  /** Whether the list has been destroyed. */
  private _isDestroyed!: boolean;

  @ContentChildren(UiListOption, { descendants: true })
  _listOptions!: QueryList<UiListOption>;

  /** Keeps track of the currently-selected value. */
  _value!: string[] | null;

  get value() {
    return this._value;
  }

  /** Emits a change event whenever the selected state of an option changes. */
  @Output() readonly selectionChange: EventEmitter<UiSelectionListChange> =
    new EventEmitter<UiSelectionListChange>();

  /**
   * Function used for comparing an option against the selected value when determining which
   * options should appear as selected. The first argument is the value of an options. The second
   * one is a value from the selected value. A boolean must be returned.
   */
  @Input() compareWith: (o1: any, o2: any) => boolean = (a1, a2) => a1 === a2;

  /** Whether selection is limited to one or multiple items (default multiple). */
  @Input()
  get multiple(): boolean {
    return this._multiple;
  }
  set multiple(value: BooleanInput) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._multiple) {
      if (
        (typeof ngDevMode === 'undefined' || ngDevMode) &&
        this._initialized
      ) {
        throw new Error(
          'Cannot change `multiple` mode of mat-selection-list after initialization.',
        );
      }

      this._multiple = newValue;
      this.selectedOptions = new SelectionModel(
        this._multiple,
        this.selectedOptions.selected,
      );
    }
  }
  private _multiple = false;

  /** The currently selected options. */
  selectedOptions = new SelectionModel<UiListOption>(this._multiple);

  constructor(
    private readonly _element: ElementRef<HTMLElement>,
    private readonly _ngZone: NgZone,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    // Mark the selection list as initialized so that the `multiple`
    // binding can no longer be changed.
    this._initialized = true;
    this._setupRovingTabindex();

    // These events are bound outside the zone, because they don't change
    // any change-detected properties and they can trigger timeouts.
    this._ngZone.runOutsideAngular(() => {
      this._element.nativeElement.addEventListener(
        'focusin',
        this._handleFocusin,
      );
      this._element.nativeElement.addEventListener(
        'focusout',
        this._handleFocusout,
      );
    });

    if (this._value) {
      this._setOptionsFromValues(this._value);
    }

    this._watchForSelectionChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const disabledChanges = changes['disabled'];

    if (disabledChanges && !disabledChanges.firstChange) {
      this._markOptionsForCheck();
    }
  }

  ngOnDestroy(): void {
    this._element.nativeElement.removeEventListener(
      'focusin',
      this._handleFocusin,
    );
    this._element.nativeElement.removeEventListener(
      'focusout',
      this._handleFocusout,
    );
    this._destroyed.next();
    this._destroyed.complete();
    this._isDestroyed = true;
  }

  /** View to model callback that should be called whenever the selected options change. */
  private _onChange: (value: any) => void = (_: any) => {};

  /** View to model callback that should be called if the list or its options lost focus. */
  _onTouched: () => void = () => {};

  /** Implemented as a part of ControlValueAccessor. */
  writeValue(values: string[]): void {
    this._value = values;

    if (this._listOptions) {
      this._setOptionsFromValues(values || []);
    }
  }

  /** Implemented as a part of ControlValueAccessor. */
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  /** Implemented as a part of ControlValueAccessor. */
  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  /** Implemented as a part of ControlValueAccessor. */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** Reports a value change to the ControlValueAccessor */
  _reportValueChange() {
    // Stop reporting value changes after the list has been destroyed. This avoids
    // cases where the list might wrongly reset its value once it is removed, but
    // the form control is still live.
    if (this._listOptions && !this._isDestroyed) {
      const value = this._getSelectedOptionValues();
      this._onChange(value);
      this._value = value;
    }
  }

  /** Sets the selected options based on the specified values. */
  private _setOptionsFromValues(values: string[]) {
    this._listOptions.forEach((listOption) => listOption._setSelected(false));
    values.forEach((value) => {
      const correspondingOption = this._listOptions.find((listOption) => {
        /**
         * Skip options that are already in the model. This allows us to handle cases
         * where the same primitive value is selected multiple times.
         */
        return listOption.selected
          ? false
          : this.compareWith(listOption.value, value);
      });
      if (correspondingOption) {
        correspondingOption._setSelected(true);
      }
    });
  }

  /** Returns the values of the selected options. */
  private _getSelectedOptionValues(): string[] {
    return this._listOptions
      .filter((listOption) => listOption.selected)
      .map((listOption) => listOption.value);
  }

  /** Emits a change event if the selected state of an option changed. */
  _emitChangeEvent(options: UiListOption[]) {
    this.selectionChange.emit(new UiSelectionListChange(this, options));
  }

  /** Handles keydown events within the list. */
  _handleKeydown(event: KeyboardEvent) {
    const activeItem = this._keyManager.activeItem;

    if (
      (event.keyCode === ENTER || event.keyCode === SPACE) &&
      !this._keyManager.isTyping() &&
      activeItem &&
      !activeItem.disabled
    ) {
      event.preventDefault();
      activeItem._toggleOnInteraction();
    } else if (
      event.keyCode === A &&
      this.multiple &&
      !this._keyManager.isTyping() &&
      hasModifierKey(event, 'ctrlKey')
    ) {
      const shouldSelect = this._listOptions.some(
        (option) => !option.disabled && !option.selected,
      );
      event.preventDefault();
      this._emitChangeEvent(this._setAllOptionsSelected(shouldSelect, true));
    } else {
      this._keyManager.onKeydown(event);
    }
  }

  /** Sets up the logic for maintaining the roving tabindex. */
  private _setupRovingTabindex() {
    this._keyManager = new FocusKeyManager(this._listOptions)
      .withHomeAndEnd()
      .withTypeAhead()
      .withWrap()
      // Allow navigation to disabled items.
      .skipPredicate(() => false);

    // Set the initial focus.
    this._resetActiveOption();

    // Move the tabindex to the currently-focused list item.
    this._keyManager.change
      .pipe(takeUntil(this._destroyed))
      .subscribe((activeItemIndex) => this._setActiveOption(activeItemIndex));

    // If the active item is removed from the list, reset back to the first one.
    this._listOptions.changes.pipe(takeUntil(this._destroyed)).subscribe(() => {
      const activeItem = this._keyManager.activeItem;

      if (!activeItem || !this._listOptions.toArray().indexOf(activeItem)) {
        this._resetActiveOption();
      }
    });
  }

  /** Resets the active option to the first selected option. */
  private _resetActiveOption() {
    const activeItem =
      this._listOptions.find(
        (listOption) => listOption.selected && !listOption.disabled,
      ) || this._listOptions.first;
    this._setActiveOption(
      activeItem ? this._listOptions.toArray().indexOf(activeItem) : -1,
    );
  }

  /**
   * Sets an option as active.
   * @param index Index of the active option. If set to -1, no option will be active.
   */
  private _setActiveOption(index: number) {
    this._listOptions.forEach((listOption, listOptionIndex) =>
      listOption._setTabindex(listOptionIndex === index ? 0 : -1),
    );
    this._keyManager.updateActiveItem(index);
  }

  /**
   * Sets the selected state on all of the options
   * and emits an event if anything changed.
   */
  private _setAllOptionsSelected(
    isSelected: boolean,
    skipDisabled?: boolean,
  ): UiListOption[] {
    // Keep track of whether anything changed, because we only want to
    // emit the changed event when something actually changed.
    const changedOptions: UiListOption[] = [];

    this._listOptions.forEach((listOption) => {
      if (
        (!skipDisabled || !listOption.disabled) &&
        listOption._setSelected(isSelected)
      ) {
        changedOptions.push(listOption);
      }
    });

    if (changedOptions.length) {
      this._reportValueChange();
    }

    return changedOptions;
  }

  /** Marks all the options to be checked in the next change detection run. */
  private _markOptionsForCheck() {
    if (this._listOptions) {
      this._listOptions.forEach((listOption) => listOption._markForCheck());
    }
  }

  /** Watches for changes in the selected state of the options and updates the list accordingly. */
  private _watchForSelectionChange() {
    this.selectedOptions.changed
      .pipe(takeUntil(this._destroyed))
      .subscribe((event) => {
        // Sync external changes to the model back to the options.
        for (let item of event.added) {
          item.selected = true;
        }

        for (let item of event.removed) {
          item.selected = false;
        }

        if (!this._containsFocus()) {
          this._resetActiveOption();
        }
      });
  }

  /** Returns whether the focus is currently within the list. */
  private _containsFocus() {
    const activeElement = _getFocusedElementPierceShadowDom();
    return activeElement && this._element.nativeElement.contains(activeElement);
  }

  /** Handles focusin events within the list. */
  private _handleFocusin = (event: FocusEvent) => {
    const activeIndex = this._listOptions
      .toArray()
      .findIndex((listOption) =>
        listOption.hostElement.contains(event.target as HTMLElement),
      );

    if (activeIndex > -1) {
      this._setActiveOption(activeIndex);
    } else {
      this._resetActiveOption();
    }
  };

  /** Handles focusout events within the list. */
  private _handleFocusout = () => {
    // Focus takes a while to update so we have to wrap our call in a timeout.
    setTimeout(() => {
      if (!this._containsFocus()) {
        this._resetActiveOption();
      }
    });
  };
}
