import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { Mattertag } from '@simlab/matterport/api';
import { UiCheckboxModule } from '@simlab/ui/checkbox';
@Component({
  selector: 'feature-stages-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiCheckboxModule, ReactiveFormsModule]
})
export class TagComponent {
  @Input() tag!: Partial<Mattertag>;
  @Input() tagsFormGroupRef!: UntypedFormGroup;
  @Input() disabled = false;

  @Output() tagClick: EventEmitter<string> = new EventEmitter<string>();
  @HostListener('click')
  onClick() {
    this.tagClick.emit(this.tag.id);
  }
}
