<ui-sidenav-container>
  <ui-sidenav
    [opened]="sidenavEndOpen()"
    mode="side"
    position="end"
    class="sidenav"
  >
    @defer (on viewport) {
      <documents-element-info
        [selectedItems]="selectedItems()"
        [hasInfoSidenav]="hasInfoSidenav"
        [isSidenavEnvOpened]="sidenavEndOpen()"
      ></documents-element-info>
    } @placeholder {
      <div>empty</div>
    }
  </ui-sidenav>

  <ui-sidenav-content class="flex-container">
    <div class="non-scrollable shrink">
      <div class="state-panel non-scrollable">
        @if (isFilterMode()) {
          @if (!!search().length) {
            <div class="state-panel__active-search-chips">
              <div>
                <span
                  class="state-panel__active-search-chips-value"
                  i18n="@@SEARCH_RESULTS_IN"
                >
                  Search results in
                </span>
                <span class="state-panel__active-search-chips-folder">
                  {{ rootFolderName }}
                </span>
              </div>
              <button design-icon-button type="button" (click)="onSearch('')">
                <i designIcon="cross" designSize="30px"></i>
              </button>
            </div>
          } @else {
            <!-- NOTE (Łukasz): empty block for fit in flex -->
            <div></div>
          }
        } @else {
          <design-breadcrumb
            [path]="path()"
            (customNavigate)="breadcrumbNavigation($event)"
          />
        }
        <div class="state-panel__right-side">
          <design-search-input
            [value]="search()"
            (searchValueChange)="onSearch($event)"
          />

          @if (!isLessThanMd()) {
            <ng-container *ngTemplateOutlet="viewSwitch"></ng-container>

            @if (hasInfoSidenav) {
              <button
                design-icon-button
                type="button"
                class="state-panel__toggle-open-info-btn"
                [ngClass]="{
                  'state-panel__toggle-open-info-btn--active': sidenavEndOpen()
                }"
                (click)="toggleOpenInfo()"
              >
                <i designIcon="file_info" designSize="24px"></i>
              </button>
            }
          }
        </div>
      </div>

      @if (!isLessThanMd()) {
        <ui-divider />
      }

      <div class="action-panel non-scrollable">
        @if (selectedItems().length > 0) {
          <div class="action-panel__selected-item-panel non-scrollable">
            <div
              class="action-panel__selected-item-panel-container non-scrollable"
            >
              <button
                design-icon-button
                type="button"
                (click)="clearSelection()"
                class="action-panel__selected-item-panel-btn"
              >
                <i designIcon="cross" designSize="32px"></i>
              </button>

              <div class="action-panel__selected-item-panel-label">
                {{ selectedItems().length }}
                &nbsp;
                <span i18n="@@SELECTED"> selected</span>
              </div>
            </div>

            <ng-content
              select="documents-selected-item-action-panel"
            ></ng-content>
          </div>
        } @else {
          <ng-content select="documents-action-panel"></ng-content>

          @if (isLessThanMd()) {
            <ng-container *ngTemplateOutlet="viewSwitch"></ng-container>
          }
        }
      </div>
    </div>

    <div class="main-content-container flex-container non-scrollable">
      @if (isLoading()) {
        <design-progress-spinner
          designColor="accent"
          class="progress-spinner"
        />
      }
      <design-drag-and-drop
        [disabled]="!hasDndFilesListeners"
        class="drag-and-drop-area"
      >
        <div
          id="documents-container"
          cdkScrollable
          designDragAndDrop
          (dropedFiles)="uploadFile($event)"
          class="main-content scrollable"
        >
          @if (data().length && !isLoading()) {
            @switch (view()) {
              @case ('TABLE') {
                <documents-table
                  [data]="data()"
                  [sortData]="sort()"
                  [isFilterMode]="isFilterMode()"
                  [selectedIds]="state.selectedIds()"
                  (sortChanges)="setSort($event)"
                />
              }

              @case ('GRID') {
                <documents-grid
                  [data]="data()"
                  [selectedIds]="state.selectedIds()"
                >
                  <design-sort-switch
                    [dictionary]="sortDictionary"
                    [defaultSortState]="defaultSortState"
                    [sortData]="sort()"
                    (sortDataChange)="setSort($event)"
                  />
                </documents-grid>
              }
            }
          }

          @if (!existData() && !isLoading()) {
            @if (isFilterMode()) {
              <design-empty-placeholder
                illustration="empty_filter_illustration"
              >
                <ng-container titleSlot i18n="@@NO_MATCHING_RESULTS"
                  >No matching results</ng-container
                >
                <ng-container descriptionSlot>
                  <ng-container i18n="@@SORRY_WE_COULDNT_FIND_A_MATCH"
                    >Sorry we couldn't find a match
                  </ng-container>
                  <br />
                  <ng-container
                    i18n="
                      @@ADJUST_YOUR_FILTERS_OR_TRY_SEARCHING_ALL_OF_DOCUMENTS"
                    >Adjust your filters or try searching all of
                    Documents</ng-container
                  >
                </ng-container>
              </design-empty-placeholder>
            } @else {
              <ng-content select="[emptyListSlot]"> Empty Content </ng-content>
            }
          }
        </div>
      </design-drag-and-drop>
    </div>
  </ui-sidenav-content>
</ui-sidenav-container>

<ng-template #viewSwitch>
  <documents-view-switch [view]="view()" (switchView)="switchView($event)" />
</ng-template>
