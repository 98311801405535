import {
  DocumentStates,
  FileCardStates,
  FolderStates,
  RowStates
} from '@simlab/documents/models';

export function isNotDirectory<
  TData extends DocumentStates | FileCardStates | FolderStates
>(data: TData['data']): data is FileCardStates['data'] | RowStates['data'] {
  return !data.isDirectory;
}
