<ui-sidenav-container>
  <ui-sidenav
    #sidenav
    class="left-panel"
    mode="side"
    position="start"
    [opened]="hamburgerState$ | async"
  >
    <feature-projects-side-panel-container>
      <feature-projects-side-panel-filters
        [createProject$]="createDialogAction$"
        (filterChange)="filterChange($event)"
        (orderChanges)="orderChange($event)"
      ></feature-projects-side-panel-filters>
    </feature-projects-side-panel-container>
  </ui-sidenav>
  <ui-sidenav-content>
    <div *ngIf="projects$ | async as projects" class="sidenav-content-wrapper">
      @if (projects.length > 0) {
        <feature-projects-grid-container>
          @for (project of projects; track project.id) {
            <feature-projects-grid-container-item
              [attr.data-cy]="'grid-project-item-' + project.name"
              [item]="project"
              [canConnectProcore]="false"
            />
          }

          <div class="empty-object" *ngIf="creatingProject$ | async">
            <ui-progress-spinner [diameter]="100"></ui-progress-spinner>
          </div>
        </feature-projects-grid-container>
        <ui-paginator
          *ngIf="metadata$ | async as metadata"
          [metadata]="{
            hasNext: metadata.hasNext,
            hasPrevious: metadata.hasPrevious,
            currentPage: metadata.currentPage,
            totalPages: metadata.totalPages,
            pageSize: metadata.pageSize
          }"
          [loading]="(projectLoaded$ | async) === false"
          (pagingUpdated)="handlePageSelected($event)"
        >
        </ui-paginator>
      } @else {
        <div class="wrapper-image-info">
          <ui-image-info
            imageSrc="icon_no_projects"
            i18n-header="@@NO_PROJECT_DETECTED"
            header="No project detected"
            [descriptions]="descriptionImageInfo"
            [maxWidth]="400"
          ></ui-image-info>
        </div>
      }
    </div>
  </ui-sidenav-content>
</ui-sidenav-container>
