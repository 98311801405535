import { Inject, Injectable, OnDestroy, ViewContainerRef, inject } from '@angular/core';
import { ApiFacadeService, ModelAccess } from '@simlab/data-access';
import {
  CustomPhases,
  MatterportScanStatus,
  MatterportService,
} from '@simlab/matterport';
import { MatterportApiFacadeService } from '@simlab/matterport/api';
import { SupportedLinks } from '@simlab/matterport/lib/models/supported-language';
import {
  BehaviorSubject,
  MonoTypeOperatorFunction,
  Observable,
  Subject,
  catchError,
  defer,
  filter,
  iif,
  map,
  mergeMap,
  of,
  retry,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { AuthorizationResult } from '../models/matterport-authorization-results.interface';
import { MATTERPORT_TOKEN } from './matterport-base.service';

export interface OpenScanConfig {
  containerRef: ViewContainerRef;
  matterportScanId: string;
  matterportOffset?: string | undefined;
  showMattertags?: boolean | undefined;
  language?: SupportedLinks;
  blockPrivateLink?: boolean;
}

export enum OauthState {
  INITIAL = 'state.initial',
  LOADING = 'state.loading',
  LOADED = 'state.loaded',
  EXTERNAL = 'state.external',
  ERROR = 'state.error',
  ERROR_PUBLIC = 'state.error_public',
  ERROR_PROTECTED = 'state.error_protected',
  ERROR_PRIVATE = 'state.error_private',
  ERROR_CLIENT = 'state.error_client',
  UNDEFINED_COMPONENT = 'state.error_undefined_component',
  NOT_SYNCHRONIZED_COMPONENT = 'state.error_not_synchronized_component',
  NOT_ENABLE_PUBLIC_LINK = 'state.error_not_enable_public_link',
}

export const OAUTH_ERRORS = Object.values(OauthState).filter(
  (value) => typeof value === 'string' && value.startsWith('state.error')
);

@Injectable()
export class MatterportOauthService implements OnDestroy {
      private readonly matterportManagerService = inject<MatterportService>(MATTERPORT_TOKEN);
      private readonly matterportApiService = inject(MatterportApiFacadeService);
      private readonly apiFacadeService = inject(ApiFacadeService);
  private readonly _destroyed = new Subject<void>();
  private readonly _stateSource = new BehaviorSubject<OauthState>(
    OauthState.INITIAL
  );

  readonly oauthState$: Observable<OauthState> =
    this._stateSource.asObservable();

  readonly createAndOpenScan$ = (
    openScanConfig: OpenScanConfig
  ): Observable<MatterportScanStatus> => {
    this._stateSource.next(OauthState.LOADING);

    this._destroyed.next();
    this.matterportManagerService.destroy();

    return this._internalLoad$(openScanConfig);
  };

  private readonly _getAuthToken$ = (matterportScanId: string) => {
    let retryCount = 0;
    const checkIfScanAvailable$: Observable<boolean> = this.matterportApiService
      .getScanVisibility$(matterportScanId)
      .pipe(
        map(() => true),
        catchError(() => {
          this._stateSource.next(OauthState.EXTERNAL);
          if (retryCount === 0)
            return this._openExternalAuth$().pipe(
              tap(() => {
                throw new Error('CHECK_AGAIN');
              }),
              map(() => true)
            );
          return of(false);
        })
      );
    return iif(
      () => this.matterportApiService.checkTokenExist(),
      checkIfScanAvailable$,
      this.apiFacadeService.tokens.getMatterportUserToken().pipe(
        map((response) => response.accessToken),
        tap((accessToken: string) => {
          this.matterportApiService.setMatterportToken(accessToken);
        }),
        switchMap(() => checkIfScanAvailable$),
        catchError((response) => {
          if (
            //NOTE: non existing token
            response.status === 404 ||
            //NOTE: expired token
            (response.status === 400 &&
              response.error.errorCode === 'GetMatterportTokenError')
          ) {
            this._stateSource.next(OauthState.EXTERNAL);
            return checkIfScanAvailable$;
          }
          return of(response);
        })
      )
    ).pipe(
      retry({
        count: 1,
        delay: () => {
          retryCount++;
          return of(false);
        },
      })
    );
  };

  private _internalLoad$ = (
    openScanConfig: OpenScanConfig
  ): Observable<MatterportScanStatus> =>
    this.apiFacadeService.matterport
      .getMatterportModelAccess(openScanConfig.matterportScanId)
      .pipe(
        tap((access: ModelAccess) => {
          console.log(
            `%c${access}`,
            'background-color: black; color: lightgreen; padding: 2px; text-transform: Uppercase'
          );
        }),
        filter((access) => {
          if (
            access !== ModelAccess.Public &&
            openScanConfig.blockPrivateLink
          ) {
            this._stateSource.next(OauthState.NOT_ENABLE_PUBLIC_LINK);
            return false;
          }
          return true;
        }),
        mergeMap((access: ModelAccess) => {
          switch (access) {
            case ModelAccess.Public:
              return this._openPublicScan$(openScanConfig).pipe(
                this._tapOauthState$(OauthState.ERROR_PUBLIC)
              );

            case ModelAccess.Private:
              return this._openProtectedScan$(openScanConfig).pipe(
                this._tapOauthState$(OauthState.ERROR_PRIVATE)
              );

            case ModelAccess.Protected:
              return this._openProtectedScan$(openScanConfig).pipe(
                this._tapOauthState$(OauthState.ERROR_PROTECTED)
              );
          }

          this._stateSource.next(OauthState.ERROR);
          return throwError(() => new Error('Unknown model access!'));
        }),
        takeUntil(this._destroyed)
      );

  private readonly _openPublicScan$ = (
    openScanConfig: OpenScanConfig
  ): Observable<MatterportScanStatus> => {
    const {
      containerRef,
      matterportScanId,
      matterportOffset,
      showMattertags,
      language,
    } = openScanConfig;

    this.matterportApiService.deleteToken();
    return this.matterportManagerService.createAndOpenScan$(
      containerRef,
      matterportScanId,
      matterportOffset,
      showMattertags,
      language,
      true
    );
  };

  // private readonly _openPrivateScan$ = (
  //   openScanConfig: OpenScanConfig
  // ): Observable<MatterportScanStatus> => {
  //   const {
  //     containerRef,
  //     matterportScanId,
  //     matterportOffset,
  //     showMattertags,
  //     language,
  //   } = openScanConfig;
  //   return this._getAuthToken$(matterportScanId).pipe(
  //     mergeMap((canOpen: boolean) => {
  //       if (!canOpen) {
  //         this._stateSource.next(OauthState.ERROR_CLIENT);
  //         throw new Error('Cant load  scan');
  //       }
  //       return this.matterportManagerService.createAndOpenScan$(
  //         containerRef,
  //         matterportScanId,
  //         matterportOffset,
  //         showMattertags,
  //         language
  //       );
  //     })
  //   );
  // };

  private readonly _openProtectedScan$ = (
    openScanConfig: OpenScanConfig
  ): Observable<MatterportScanStatus> => {
    const {
      containerRef,
      matterportScanId,
      matterportOffset,
      showMattertags,
      language,
    } = openScanConfig;
    return this._getAuthToken$(matterportScanId).pipe(
      mergeMap((canOpen: boolean) => {
        if (!canOpen) {
          this._stateSource.next(OauthState.ERROR_CLIENT);
          throw new Error('Cant load  scan');
        }
        return this.matterportManagerService.createAndOpenScan$(
          containerRef,
          matterportScanId,
          matterportOffset,
          showMattertags,
          language,
          true
        );
      })
    );
  };

  //NOTE: we don't support password protected scans
  // this._stateSource.next(OauthState.PASSWORD);
  // this.matterportApiService.deleteToken();

  // return this._getAuthToken$().pipe(
  //   mergeMap(() =>
  //     of({}).pipe(
  //       mergeMap(() =>
  //         this.matterportManagerService
  //           .createAndOpenScan$(
  //             containerRef,
  //             matterportScanId,
  //             matterportOffset,
  //             showMattertags
  //           )
  //           .pipe(
  //             tap((status: MatterportScanStatus) => {
  //               if (status === CustomPhases.ERROR) {
  //                 throw new Error(status);
  //               }
  //             })
  //           )
  //       ),
  //       retry(<RetryConfig>{
  //         count: 1,
  //         delay: (error, retryCount) => {
  //           if (retryCount === 1) {
  //             this.matterportApiService.deleteToken();
  //             return of(0);
  //           }

  //           return throwError(() => error);
  //         },
  //       })
  //     )
  //   )
  // );

  private readonly _tapOauthState$ = (
    onErrorState: OauthState
  ): MonoTypeOperatorFunction<CustomPhases> =>
    tap((phase: CustomPhases) => {
      if (phase === CustomPhases.ERROR) {
        this._destroyed.next();
        this.matterportManagerService.destroy();

        this._stateSource.next(onErrorState);
      } else {
        this._stateSource.next(OauthState.LOADED);
      }
    });

  private readonly _setMatterportToken$ = (result: AuthorizationResult) =>
    this.apiFacadeService.tokens
      .addMatterportUserToken({
        code: <string>result?.response?.authCode,
      })
      .pipe(
        switchMap((token: string) =>
          this.matterportApiService.clearCache$().pipe(map(() => token))
        ),
        tap((token: string) => {
          this.matterportApiService.setMatterportToken(token);
        })
      );

  private readonly _openExternalAuth$ = () =>
    defer(() => this.matterportApiService.authorization$()).pipe(
      take(1),
      switchMap((result: AuthorizationResult) =>
        iif(
          () => result?.status === 'SUCCESS',
          this._setMatterportToken$(result),
          throwError(() => {
            this._stateSource.next(OauthState.ERROR_CLIENT);
            this._destroyed.next();
            this.matterportManagerService.destroy();
            return new Error('Auth closed by client!');
          })
        )
      )
    );

  componentUndefined(): void {
    this._stateSource.next(OauthState.UNDEFINED_COMPONENT);
    this._destroyed.next();
    this.matterportManagerService.destroy();
  }

  componentNotSynchronized(): void {
    this._stateSource.next(OauthState.NOT_SYNCHRONIZED_COMPONENT);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
