import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ShowcaseHelper } from '@simlab/util-shared';

export function validateShowcaseUrl(
  control: AbstractControl
): ValidationErrors | null {
  try {
    ShowcaseHelper.findShowcaseId(control.value);
    return null;
  } catch {
    return { unknownShowcaseId: true };
  }
}
