import { PunchListWorkflowStatus } from '@simlab/procore/models';
import { ICreationInfo } from './stages-base.interace';

export interface Stage extends ICreationInfo {
  id: string;
  name: string;
  stageDate: string;
  description: string;
  creatorId: string;
  lastEditorId: string;
  hasRootNotes: boolean;
  hasComponents: boolean;
  hasSynchronizedModel: boolean;
  hasSynchronizedMatterport: boolean;
  isFrozen: boolean;
  sequenceNumber?: number;
  punchItemStatuses?: PunchItemStatuses[] | undefined;
  rfiStatuses?: RfiStatuses | undefined;
  rootNoteCount: number;
  punchItemCount?: number | undefined;
  rfiCount?: number | undefined;
}

export type RfiStatuses = {
  closed: number;
  draft: number;
  open: number;
  total: number;
};

export type PunchItemStatuses = {
  punchItemsWithActionNumber: number;
  punchItemsWithNoActionNumber: number;
  status: PunchListWorkflowStatus;
};
