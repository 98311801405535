import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnnotationModules } from '@simlab/data-access';
import { StagesRootService } from '@simlab/feature/stages';
import { BehaviorSubject, map } from 'rxjs';
import { MatterportNoteControlService } from './matterport-note-control.service';

type AnnotationMarkerAddMode = AnnotationModules | 'none';

@Injectable()
export class MatterportAnnotationControlService {
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _stagesRootService = inject(StagesRootService);
  private readonly _matterportNoteControl = inject(
    MatterportNoteControlService
  );

  private readonly _isAnnotationMarkerActiveMode$ =
    new BehaviorSubject<AnnotationMarkerAddMode>('none');

  readonly isAnnotationMarkerInActiveMode$ = this._isAnnotationMarkerActiveMode$
    .asObservable()
    .pipe(map((status) => status !== 'none'));

  triggerAnnotationMarkerChangeMode(status: AnnotationModules) {
    this.triggerAnnotationMarkerAddMode(status);
    this.moveToAnnotation(status);
  }

  moveToAnnotation(
    module: AnnotationModules,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fn: () => void = () => {}
  ) {
    this._stagesRootService.leftPanelState = 'close';
    this._stagesRootService.rightPanelState = 'close';
    fn();

    this._navigateToMatterport().then((response) => {
      this._matterportNoteControl.moveToNote = {
        annotationType: module,
        flag: response
      };
    });
  }

  triggerAnnotationMarkerAddMode(
    status: AnnotationMarkerAddMode,
    withRedirectToMatterport = false
  ) {
    this._isAnnotationMarkerActiveMode$.next(status);
    this._stagesRootService.rightPanelState = 'close';

    if (withRedirectToMatterport && status !== 'none') {
      this._navigateToMatterport();
      this._stagesRootService.leftPanelState = 'close';
    }
  }

  offAnnotationMarkAddMode(): void {
    this._isAnnotationMarkerActiveMode$.next('none');
  }

  private _navigateToMatterport(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this._route.snapshot.firstChild?.routeConfig?.path !== 'matterport') {
        this._router
          .navigate(['matterport'], {
            queryParamsHandling: 'preserve',
            relativeTo: this._route
          })
          .then(() => {
            resolve(true);
          });
      } else {
        resolve(false);
      }
    });
  }
}
