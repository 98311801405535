import { Pipe, PipeTransform } from '@angular/core';
import { NoteStatus } from '@simlab/feature-stages/models';

export const NoteStatusLabel: Record<NoteStatus, string> = {
  InProgress: $localize`:@@IN_PROGRESS:In Progress`,
  Resolved: $localize`:@@RESOLVED:Resolved`,
  Unresolved: $localize`:@@UNRESOLVED:Unresolved`,
  Pending: $localize`:@@PENDING:Pending`
};

@Pipe({ name: 'getNoteStatusLabel', standalone: true })
export class NoteStatusLabelGetterPipe implements PipeTransform {
  transform(status: NoteStatus): string {
    return NoteStatusLabel[status];
  }
}
