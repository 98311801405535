import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProjectsFacade } from '@simlab/data-store';
import { Observable } from 'rxjs';

export function canRenderProcoreEntity(
  route: ActivatedRouteSnapshot
): Observable<boolean>;
export function canRenderProcoreEntity(projectId: string): Observable<boolean>;
export function canRenderProcoreEntity(
  data: string | ActivatedRouteSnapshot
): Observable<boolean> {
  const projectFacade = inject(ProjectsFacade);
  const projectId: string =
    data instanceof ActivatedRouteSnapshot ? data.params['projectId'] : data;

  return projectFacade.projectConnectedWithProcore$(projectId);
}
