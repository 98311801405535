<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel i18n="@@PU_IMPORTING_JUST_MINUTE">Just a minute...</h2>
  </ng-container>
  <ng-container designDialogContent>
    <p i18n="@@PU_IMPORTING_TAGS">Importing tags</p>
    <ng-container *ngIf="progression | async as progress">
      <ui-progress-bar
        [value]="(100 / progress.total) * progress.current"
      ></ui-progress-bar>
      <span i18n="@@PU_IMPORTED_X_OF_X">
        Imported {{ progress.current }} of {{ progress.total }}
      </span>
    </ng-container>
  </ng-container>
</design-dialog-wrapper>
