import { NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  OnChanges,
  SimpleChanges,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import { outputFromObservable, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DesignIconButton } from '@simlab/design/button';
import { DesignSuffix } from '@simlab/design/common';
import { DesignFormField } from '@simlab/design/form-field';
import { DesignIcon } from '@simlab/design/icon';
import { DesignInput } from '@simlab/design/input';
import { filter, fromEvent, map, merge, mergeMap, tap } from 'rxjs';

@Component({
  selector: 'design-search-input',
  standalone: true,
  imports: [
    NgStyle,
    ReactiveFormsModule,
    DesignInput,
    DesignIconButton,
    DesignIcon,
    DesignFormField,
    DesignSuffix,
  ],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'search-input',
  },
})
export class SearchInputComponent implements OnChanges {
  private readonly _formElement =
    viewChild.required<ElementRef<HTMLFormElement>>('form');
  private readonly _inputElement =
    viewChild.required<ElementRef<HTMLInputElement>>('input');

  private readonly _inputLostFocusEvent$ = toObservable(
    this._inputElement,
  ).pipe(mergeMap(({ nativeElement }) => fromEvent(nativeElement, 'blur')));

  private readonly _formSubmitEvent$ = toObservable(this._formElement).pipe(
    mergeMap(({ nativeElement }) =>
      fromEvent(nativeElement, 'submit').pipe(
        tap((event) => event.preventDefault()),
      ),
    ),
  );

  readonly formControl = input(
    new FormControl<string>('', { nonNullable: true }),
  );
  readonly value = input<string>('');

  readonly searchValueChange = outputFromObservable(
    merge(this._inputLostFocusEvent$, this._formSubmitEvent$).pipe(
      map(() => this.formControl().value),
      filter((inputValue: string) => {
        const currentValue = this.value();
        return inputValue !== currentValue;
      }),
    ),
  );

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] === undefined) return;
    const value = changes['value'].currentValue;

    this.formControl().patchValue(value);
  }

  protected clearControl(): void {
    this.formControl().reset();
  }
}
