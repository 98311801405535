import {
  AfterContentInit,
  computed,
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  Input,
  Signal,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroupDirective } from '@angular/forms';
import * as _dayjs from 'dayjs';
import {
  DatePickerComponent,
  IDatePickerDirectiveConfig,
} from 'ng2-date-picker';
import { Observable, Subject, tap } from 'rxjs';
import {
  DATA_PICKER_FORMAT_CONFIG,
  DatepickerConfig,
  FormatDateDataPicker,
} from '../../models/datepicker-config.interface';
import {
  DatePickerEndDirective,
  DatePickerStartDirective,
} from './date-range-picker.component';

const dayjs = _dayjs;
declare const ngDevMode: boolean;

const RANGE_PICKER_LIMIT = 2;

const DEFAULT_CONFIG = {
  hideInputContainer: true,
  monthFormat: 'MMMM YYYY',
  enableMonthSelector: false,
  weekDayFormat: 'dd',
  showGoToCurrent: false,
  closeOnSelect: false,
  closeOnSelectDelay: 50,
  allowMultiSelect: true,
} satisfies IDatePickerDirectiveConfig;

@Directive()
export abstract class DateRangePickerBase implements AfterContentInit {
  readonly ngControl = inject(FormGroupDirective);
  readonly stateChanges = new Subject<void>();
  errorState = false;

  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _elementRef = inject(ElementRef);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _dateFormat$: Observable<FormatDateDataPicker> = inject(
    DATA_PICKER_FORMAT_CONFIG,
  );

  abstract readonly startDatepicker: Signal<DatePickerStartDirective>;
  abstract readonly endDatepicker: Signal<DatePickerEndDirective>;

  protected startDatepickerControl = computed(
    () => this.startDatepicker().control.control,
  );
  protected endDatepickerControl = computed(
    () => this.endDatepicker().control.control,
  );

  protected readonly _datePicker: DatePickerComponent =
    this._createDatePicker();

  @Input()
  get config(): IDatePickerDirectiveConfig {
    return this._config;
  }
  set config(value: DatepickerConfig) {
    //NOTE: date format https://day.js.org/docs/en/display/format
    this._config = <IDatePickerDirectiveConfig>{
      ...value,
      ...DEFAULT_CONFIG,
      inputElementContainer: this._elementRef,
      format: value.format?.toUpperCase() || 'DD/MM/YYYY',
    };

    this._updateDatepickerConfig();
    this._datePicker.cd.markForCheck();
  }
  private _config!: IDatePickerDirectiveConfig;

  ngAfterContentInit(): void {
    this.config = {};

    this._dateFormatObserver();
    this._attachFormToDatePicker();
    this._formStatusObserver();
  }

  private _dateFormatObserver() {
    this._dateFormat$
      .pipe(
        tap((data: FormatDateDataPicker) => {
          this.config = { ...this._config, format: data } as DatepickerConfig;
          this._updateDatepickerConfig();
          this._datePicker.cd.markForCheck();
          this._datePicker.selected = this._datePicker._selected;
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }

  private _formStatusObserver() {
    this.ngControl.control.statusChanges
      ?.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((status) => {
        this.errorState = status === 'INVALID';
      });
  }

  open(): void {
    this._datePicker.onClick();
  }

  private _createDatePicker(): DatePickerComponent {
    return this._viewContainerRef.createComponent(DatePickerComponent).instance;
  }

  private _updateDatepickerConfig() {
    if (!this._datePicker) {
      if (typeof ngDevMode === 'undefined' || ngDevMode) {
        throw new Error('Datepicker does not exist!');
      }

      return;
    }

    this._datePicker.theme = 'design-date-range-picker-wrapper';
    this._datePicker.config = this.config;

    this._datePicker.init();
  }

  private _attachFormToDatePicker() {
    const startControl = this.startDatepickerControl();
    const endControl = this.endDatepickerControl();
    if (!startControl || !endControl) {
      if (typeof ngDevMode === 'undefined' || ngDevMode) {
        throw new Error('Datepicker requires FormControl!');
      }
      return;
    }

    this._datePicker.registerOnChange((value: any) => {
      if (value === undefined || !Array.isArray(value)) return;

      if (value.length > RANGE_PICKER_LIMIT) {
        this._datePicker.selected.splice(0, RANGE_PICKER_LIMIT);
      }
      const sortedDates = this._datePicker.selected.sort(
        (a, b) =>
          new Date(a.toDate()).getTime() - new Date(b.toDate()).getTime(),
      );

      const [startDateValue, endDateValue] = sortedDates;
      startDateValue
        ? startControl.setValue(startDateValue.toString())
        : startControl.reset();
      endDateValue
        ? endControl.setValue(endDateValue.toString())
        : endControl.reset();

      this.stateChanges.next();
    });

    this._datePicker.onViewDateChange([
      dayjs(startControl.value),
      dayjs(endControl.value),
    ]);
  }
}
