import { inject, Inject, Injectable, OnDestroy } from '@angular/core';
import { Params } from '@angular/router';
import { Note } from '@simlab/data-access';
import { AnnotationsFacade, NotesFacade } from '@simlab/data-store';
import {
  MATTERPORT_TOKEN,
  MatterportBaseService
} from '@simlab/feature/matterport';
import {
  ICONS,
  MatterportComponent,
  MatterportService,
  TagNoteTypes
} from '@simlab/matterport';
import {
  AnnotationType,
  ClickEventEmitter,
  SpriteComponent
} from '@simlab/simlab-facility-management/scene-object';
import { RouterFacadeService } from '@simlab/util-shared';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  Observable,
  race,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { Vector3 } from 'three';
import { StagesRootService } from './stages-root.service';

type SelectedNoteComponent = {
  id: ClickEventEmitter['id'];
  annotationType: AnnotationType;
};
@Injectable()
export class StageMatterportService implements OnDestroy {
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private readonly _destroyNoteObserver: Subject<void> = new Subject<void>();

  private readonly _annotationsFacade = inject(AnnotationsFacade);

  private _selectedNoteId$: Observable<string> =
    this.matterportService.componentClicked$.pipe(
      filter(
        (component: ClickEventEmitter) =>
          component.id !== undefined &&
          component.userData?.['type'] !== 'portal' &&
          component.userData?.['annotationType'] !== undefined
      ),
      map(
        (component: ClickEventEmitter) =>
          <SelectedNoteComponent>{
            id: component.id,
            annotationType: component.userData?.['annotationType']
          }
      ),
      takeUntil(race(this._destroySource, this._destroyNoteObserver)),
      tap(({ annotationType, id }) => {
        this._openAnnotationSidePanelBy(annotationType, id);
      }),
      map(({ id }) => id)
    );

  private _openAnnotationSidePanelBy(
    annotationType: AnnotationType,
    annotationId: number | string
  ) {
    switch (annotationType) {
      case 'note': {
        const queryParams: Params = {
          sidenavContent: 'note',
          noteId: annotationId,
          punchItemId: null,
          rfiId: null
        };
        this.routerFacadeService.setQueryParams(
          undefined,
          queryParams,
          'merge'
        );
        this.stagesRootService.rightPanelState = 'open';
        this.notesFacade.selectedNoteId(annotationId as string);
        break;
      }

      case 'punch-item': {
        const queryParams: Params = {
          sidenavContent: 'punch-item',
          punchItemId: annotationId,
          noteId: null,
          rfiId: null
        };
        this.routerFacadeService.setQueryParams(
          undefined,
          queryParams,
          'merge'
        );
        this.stagesRootService.rightPanelState = 'open';
        break;
      }

      case 'rfi': {
        const queryParams: Params = {
          sidenavContent: 'rfi',
          rfiId: annotationId,
          noteId: null,
          punchItemId: null
        };
        this.routerFacadeService.setQueryParams(
          undefined,
          queryParams,
          'merge'
        );
        this.stagesRootService.rightPanelState = 'open';
        break;
      }

      default: {
        const queryParams: Params = {
          sidenavContent: null,
          rfiId: null,
          noteId: null,
          punchItemId: null
        };
        this.routerFacadeService.setQueryParams(
          undefined,
          queryParams,
          'merge'
        );
        this.stagesRootService.rightPanelState = 'close';

        throw new Error('Undefined annotation type');
      }
    }
  }

  private _selectedNoteStatusChange$: Observable<Note> =
    this.notesFacade.changeNoteStatusSuccess$.pipe(
      takeUntil(race(this._destroySource, this._destroyNoteObserver)),
      map((action) => action.note),
      tap((note: Note) => {
        const matterportComponents: MatterportComponent[] =
          this.matterportBaseService.matterportComponents;
        const noteType =
          note.type === 'Information'
            ? TagNoteTypes.INFO
            : (note.status.toUpperCase() as TagNoteTypes);
        const matterportComponent: MatterportComponent | undefined =
          matterportComponents.find(
            (matterportComponent: MatterportComponent) =>
              matterportComponent.comp.inputs.id === note.id
          );
        if (matterportComponent) {
          (
            matterportComponent.children.find(
              (comp) => 'icon' in comp
            ) as SpriteComponent
          ).icon = ICONS[noteType];
          matterportComponent.comp.inputs.position = new Vector3(
            note.marker?.position.x || 0,
            note.marker?.position.y || 0,
            note.marker?.position.z || 0
          );
        }
      })
    );

  constructor(
    @Inject(MATTERPORT_TOKEN)
    private readonly matterportService: MatterportService,
    private readonly matterportBaseService: MatterportBaseService,
    private readonly stagesRootService: StagesRootService,
    private readonly routerFacadeService: RouterFacadeService,
    private readonly notesFacade: NotesFacade
  ) {
    this._noteObserver();
  }

  hideAllNotes(hideAll: boolean) {
    this.matterportBaseService.hideAllNotes(hideAll);
  }
  private _noteObserver(): void {
    const combined = combineLatest([
      this._selectedNoteId$,
      this._selectedNoteStatusChange$
    ]);

    this.matterportService.scanLoaded$
      .pipe(
        takeUntil(this._destroySource),
        tap(() => this._destroyNoteObserver.next()),
        switchMap(() =>
          this.matterportService.hasMatterport$.pipe(
            filter((hasMatterport: boolean) => hasMatterport),
            take(1),
            takeUntil(this._destroySource),
            distinctUntilChanged(),
            mergeMap(() => combined)
          )
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }
}
