import { Pipe, PipeTransform } from '@angular/core';
import { PunchListWorkflowStatus } from '@simlab/procore/models';

export const PunchItemStatusLabel: Record<PunchListWorkflowStatus, string> = {
  [PunchListWorkflowStatus.Draft]: $localize`:@@DRAFT:Draft`,
  [PunchListWorkflowStatus.Initiated]: $localize`:@@INITIATED:Initiated`,
  [PunchListWorkflowStatus.InDispute]: $localize`:@@IN_DISPUTE:In Dispute`,
  [PunchListWorkflowStatus.WorkRequired]: $localize`:@@WORK_REQUIRED:Work Required`,
  [PunchListWorkflowStatus.ReadyForReview]: $localize`:@@READY_FOR_REVIEW:Ready For Review`,
  [PunchListWorkflowStatus.WorkNotAccepted]: $localize`:@@WORK_NOT_ACCEPTED:Work Not Accepted`,
  [PunchListWorkflowStatus.ReadyToClose]: $localize`:@@READY_TO_CLOSE:Ready To Close`,
  [PunchListWorkflowStatus.NotAcceptedByCreator]: $localize`:@@NOT_ACCEPTED_BY_CREATOR:Not Accepted By Creator`,
  [PunchListWorkflowStatus.Closed]: $localize`:@@CLOSED:Closed`
};

@Pipe({ name: 'getPunchItemStatusLabel', standalone: true })
export class PunchItemStatusLabelGetterPipe implements PipeTransform {
  transform(status: PunchListWorkflowStatus): string {
    return PunchItemStatusLabel[status];
  }
}
