import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LimitReachedActivateProjectComponent } from './limit-reached-activate-project.component';
import { UiButtonModule } from '@simlab/ui/button';

@NgModule({
  declarations: [LimitReachedActivateProjectComponent],
  exports: [LimitReachedActivateProjectComponent],
  imports: [CommonModule, UiButtonModule]
})
export class LimitReachedActivateProjectModule {}
