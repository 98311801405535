<form
  class="form"
  #form
  (submit)="$event.stopPropagation(); $event.preventDefault()"
>
  <design-form-field class="form-field">
    <input
      #input
      designInput
      type="search"
      i18n-placeholder="@@SEARCH"
      placeholder="Search"
      [formControl]="formControl()"
    />

    <button
      [ngStyle]="{
        visibility: formControl().value.length ? 'visible' : 'hidden',
      }"
      design-icon-button
      designSuffix
      type="button"
      (click)="clearControl()"
      class="form-field__clear-btn"
    >
      <i designIcon="cross"></i>
    </button>

    <button
      design-icon-button
      designSuffix
      type="submit"
      class="form-field__search-btn"
    >
      <i designIcon="search_bold" designSize="20px"></i>
    </button>
  </design-form-field>
</form>
