import { KeyValuePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  input,
  viewChild
} from '@angular/core';
import {
  outputFromObservable,
  takeUntilDestroyed,
  toSignal
} from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotesFacade } from '@simlab/data-store';
import { DesignIcon } from '@simlab/design/icon';
import {
  AreaMeasurementAttachmentComponent,
  DistanceMeasurementAttachmentComponent
} from '@simlab/feature-measurement/ui';
import {
  NoteIssueTypeStatusRecords,
  NoteTypeRecords
} from '@simlab/feature-stages/models';
import {
  CommentListComponent,
  NoteMediaContainerComponent,
  NoteStatusIcon,
  PanelRightService,
  StagesRootService
} from '@simlab/feature/stages';
import { UiContentEditableComponent } from '@simlab/ui/content-editable';
import { UiHintModule } from '@simlab/ui/hint';
import { UiIconModule } from '@simlab/ui/icon';
import { ISidenavContent, SIDENAV_CONTENT } from '@simlab/ui/sidenav';
import { RouterFacadeService } from '@simlab/util-shared';
import { annotationsActionsToken } from 'common/annotation-panel/models';
import {
  AnnotationPanelLocation3DComponent,
  AnnotationPanelRootComponent
} from 'common/annotation-panel/ui';
import { distinctUntilChanged, Subject, tap } from 'rxjs';

import { FormatDateSignalPipe } from '@simlab/design/format-date';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiSelectModule } from '@simlab/ui/select';
import {
  StageSidePanelAction,
  StagesSidePanelActions
} from './data-access/stage-side-panel-actions.service';

const TRANSLATIONS = {
  inactive: $localize`:@@INACTIVE:Inactive`,
  stakeholder: $localize`:@@STAKEHOLDER:Stakeholder`
} as const;

@Component({
  selector: 'feature-stages-stage-side-panel',
  standalone: true,
  imports: [
    AnnotationPanelRootComponent,
    AnnotationPanelLocation3DComponent,
    UiIconModule,
    DesignIcon,
    FormatDateSignalPipe,
    UiDividerModule,
    UiFormFieldModule,
    UiSelectModule,
    UiContentEditableComponent,
    KeyValuePipe,
    UiHintModule,
    NoteMediaContainerComponent,
    AreaMeasurementAttachmentComponent,
    DistanceMeasurementAttachmentComponent,
    CommentListComponent,
    ReactiveFormsModule
  ],
  templateUrl: './stage-side-panel.component.html',
  styleUrl: './stage-side-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StagesSidePanelActions,
    {
      provide: SIDENAV_CONTENT,
      useExisting: StageSidePanelComponent
    },
    {
      provide: annotationsActionsToken,
      useClass: StageSidePanelAction
    }
  ]
})
export class StageSidePanelComponent
  implements ISidenavContent<StageSidePanelComponent>
{
  private readonly _panelRightService = inject(PanelRightService);
  private readonly _router = inject(Router);
  private readonly _activatedRouter = inject(ActivatedRoute);
  private readonly _stagesRootService = inject(StagesRootService);
  private readonly _noteFacade = inject(NotesFacade);
  private readonly _routerFacade = inject(RouterFacadeService);
  private readonly _elementRef = inject(ElementRef);

  private readonly _noteNameView =
    viewChild<ElementRef<HTMLInputElement>>('noteName');
  private readonly _openAction$ = new Subject<void>();

  protected readonly name = toSignal(
    this._panelRightService.nameControl.valueChanges
  );
  protected readonly stageName = toSignal(this._panelRightService.stageName$);
  protected readonly isLoaded = toSignal(
    this._noteFacade.getNoteRightPanelLoaded$
  );
  protected readonly formGroup = this._panelRightService.formGroup;

  protected readonly noteIcon = computed(() => {
    const selectedNote = this.selectedNote();
    if (selectedNote == null) return undefined;

    return selectedNote.type === 'Information'
      ? NoteStatusIcon['Information']
      : NoteStatusIcon[selectedNote.status];
  });

  protected readonly noteNotification = computed(
    () => !!this.selectedNote()?.notificationsEnabled
  );

  protected readonly noteType = NoteTypeRecords;
  protected readonly noteStatus = NoteIssueTypeStatusRecords;

  protected readonly addEditDeleteOwnElements = toSignal(
    this._panelRightService.addEditDeleteOwnElements$
  );
  protected readonly addReplaceMarker = toSignal(
    this._panelRightService.addReplaceMarker$
  );

  protected readonly collaborators = toSignal(
    this._panelRightService.collaborators$
  );
  protected readonly stakeholderPlaceholder = computed(() => {
    const collaborations = this.collaborators();
    const selectedNote = this.selectedNote();
    const defaultLabel = TRANSLATIONS.stakeholder;

    if (
      !this._panelRightService.stakeholderControl.value ||
      collaborations === undefined ||
      selectedNote === undefined
    ) {
      return defaultLabel;
    }

    const notExistCollaborators = collaborations.find(
      (item) => item.id === this._panelRightService.stakeholderControl.value
    );

    if (notExistCollaborators === undefined) {
      const inactive = TRANSLATIONS.inactive;
      return `${selectedNote.stakeholderName} [${inactive}]`;
    }

    return defaultLabel;
  });

  readonly selectedNote = toSignal(this._panelRightService.selectedNote$);

  readonly closeAction = new Subject<void>();

  readonly projectId = input.required<string>();

  readonly closePanelAction$ = outputFromObservable(
    this.closeAction.pipe(
      tap(() => {
        const queryParams: Params = { sidenavContent: null, noteId: null };
        this._routerFacade.setQueryParams(undefined, queryParams, 'merge');
      })
    )
  );
  readonly openPanelAction$ = outputFromObservable(this._openAction$);
  protected readonly maxCommentLength = 256;

  constructor() {
    this._noteFacade.selectedNoteId$
      .pipe(
        distinctUntilChanged(),
        tap(() => (this._elementRef.nativeElement.scrollTop = 0)),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  getSelf(): StageSidePanelComponent {
    return this;
  }

  changeTitle(): void {
    this._panelRightService.nameControl.enable();
    (this._noteNameView()?.nativeElement as HTMLInputElement).focus();
    (this._noteNameView()?.nativeElement as HTMLInputElement).select();
  }

  protected get descriptionControl() {
    return this._panelRightService.descriptionControl;
  }

  protected goToAreaMeasurement() {
    const queryParams: Params = {
      sidenavContent: 'measurement',
      addMeasurementToNote: null
    };
    this._router
      .navigate(['./matterport'], {
        queryParams,
        queryParamsHandling: 'merge',
        relativeTo: this._activatedRouter
      })
      .then(() => (this._stagesRootService.rightPanelState = 'open'));
  }

  protected focusOut(): void {
    this._panelRightService.nameControl.disable();
  }

  protected assignNewLocation(): void {
    this._panelRightService.addLocalization();
  }

  protected reassignLocation(): void {
    this._panelRightService.changeLocalization();
  }

  protected moveTo(): void {
    this._panelRightService.moveTo(() => this.closeAction.next());
  }
}
