import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
  inject
} from '@angular/core';
import { StageWithCount, StagesFacade } from '@simlab/data-store';
import { FormatDatePipe } from '@simlab/design/format-date';
import { UiButtonModule } from '@simlab/ui/button';
import { UiIconModule } from '@simlab/ui/icon';
import {
  Observable,
  Subject,
  debounceTime,
  filter,
  fromEvent,
  map,
  mergeMap,
  pairwise,
  race,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { TimelineDraggableDirective } from './timeline-draggable-slider.directive';

const DEBOUNCE_BETWEEN_SCROLL_EMIT = 1000;

@Component({
  selector: 'simlab-timeline-condensed-draggable',
  standalone: true,
  imports: [CommonModule, UiIconModule, UiButtonModule, FormatDatePipe],
  templateUrl: './timeline-condensed-draggable.component.html',
  styleUrls: ['./timeline-condensed-draggable.component.scss']
})
export class TimelineCondensedDraggableComponent
  extends TimelineDraggableDirective
  implements OnDestroy, AfterViewInit
{
  private readonly ngZone = inject(NgZone);
  private readonly stagesFacade = inject(StagesFacade);
  @ViewChild('parent') private _parent!: ElementRef<HTMLDivElement>;
  private readonly _destroy$ = new Subject<void>();
  private _dragging = false;
  private _offset = 0;

  selectedElement = '';

  @Output() selectionChange: EventEmitter<string> = new EventEmitter<string>();

  readonly stages$: Observable<StageWithCount[]> =
    this.stagesFacade.allStages$.pipe(
      map((stages: StageWithCount[]) => {
        return [...stages].reverse();
      })
    );

  ngAfterViewInit(): void {
    this.initWithSelected();
    this.mouseDownListener();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  trackById(index: number, stage: StageWithCount): string {
    return stage.id;
  }

  selectItem(itemId: string): void {
    if (this._dragging) {
      return;
    }
    const element = this._items.find(
      (item) => item.nativeElement.id === itemId
    )?.nativeElement;
    if (!element) return;
    this.setSelected(element.id);

    const elementWidth = element.offsetWidth;
    this._offset =
      element.offsetLeft * -1 +
      this._parent.nativeElement.offsetWidth * 0.5 -
      elementWidth * 0.5;
    this._container.nativeElement.style.transform = `translateX(${this._offset}px)`;
  }

  private setSelected(id: string): void {
    if (this.selectedElement !== id) {
      this.selectedElement = id;
      this.selectionChange.next(id);
    }
  }

  private initWithSelected(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(
        take(1),
        mergeMap(() => this.stagesFacade.selectedId$)
      )
      .subscribe((stageId: string) => {
        this.ngZone.run(() => this.selectItem(stageId));
      });
  }

  private mouseDownListener(): void {
    const mouseDown$ = this.mouseDown$();
    const touchStart$ = this.touchStart$();
    const mouseMove$ = this.mouseMove$();
    const touchMove$ = this.touchMove$();
    const mouseUp$ = this.mouseUp$();
    const mouseLeave$ = this.mouseLeave$();
    const touchEnd$ = this.touchEnd$();

    fromEvent<WheelEvent>(this._container.nativeElement, 'mousewheel')
      .pipe(
        tap((event: WheelEvent) => {
          console.log(event);
          event.preventDefault();
          event.stopPropagation();
          const halfScroll = event.deltaY * 0.5;
          this.translatePosition(halfScroll);
        }),
        debounceTime(DEBOUNCE_BETWEEN_SCROLL_EMIT),
        tap(() => {
          this.findClosestPoint();
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();

    race(mouseDown$, touchStart$)
      .pipe(
        takeUntil(this._destroy$),
        filter(() => !this._dragging),
        switchMap(() =>
          race(mouseMove$, touchMove$).pipe(
            tap(() => (this._dragging = true)),
            pairwise(),
            tap(
              ([before, after]: [
                MouseEvent | TouchEvent,
                MouseEvent | TouchEvent
              ]) => {
                let result = 0;
                if ('touches' in after && 'touches' in before) {
                  result =
                    ((after as TouchEvent).touches?.item(0)?.clientX ?? 0) -
                    ((before as TouchEvent).touches?.item(0)?.clientX ?? 0);
                } else {
                  result =
                    (after as MouseEvent).clientX -
                    (before as MouseEvent).clientX;
                }
                this.translatePosition(result);
              }
            ),
            takeUntil(
              race(mouseUp$, mouseLeave$, touchEnd$).pipe(
                tap(() => this.findClosestPoint())
              )
            )
          )
        )
      )
      .subscribe();
  }

  private mouseDown$(): Observable<MouseEvent> {
    return fromEvent<MouseEvent>(
      this._container.nativeElement,
      'mousedown'
    ).pipe(
      tap((event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
      })
    );
  }

  private touchStart$(): Observable<TouchEvent> {
    return fromEvent<TouchEvent>(
      this._container.nativeElement,
      'touchstart'
    ).pipe();
  }

  private touchMove$(): Observable<TouchEvent> {
    return fromEvent<TouchEvent>(
      this._container.nativeElement,
      'touchmove'
    ).pipe();
  }

  private touchEnd$(): Observable<TouchEvent> {
    return fromEvent<TouchEvent>(
      this._container.nativeElement,
      'touchend'
    ).pipe();
  }

  private mouseUp$(): Observable<MouseEvent> {
    return fromEvent<MouseEvent>(
      this._container.nativeElement,
      'mouseup'
    ).pipe();
  }

  private mouseLeave$(): Observable<MouseEvent> {
    return fromEvent<MouseEvent>(
      this._parent.nativeElement,
      'mouseleave'
    ).pipe();
  }

  private mouseMove$(): Observable<MouseEvent> {
    return fromEvent<MouseEvent>(
      this._container.nativeElement,
      'mousemove'
    ).pipe();
  }

  private translatePosition(result: number): void {
    this._offset += result;
    this._offset > 0 ? (this._offset = 0) : null;
    this._offset <
    -this._container.nativeElement.offsetWidth +
      this._parent.nativeElement.offsetWidth
      ? (this._offset =
          -this._container.nativeElement.offsetWidth +
          this._parent.nativeElement.offsetWidth)
      : null;
    this._container.nativeElement.style.transform = `translateX(${this._offset}px)`;
  }

  private findClosestPoint() {
    if (this._items.get(1)) {
      const elWidth = this._items.get(1)?.nativeElement.offsetWidth ?? 0;
      const distance =
        ((this._items.get(1)?.nativeElement.offsetLeft ?? 0) -
          (this._items.get(0)?.nativeElement.offsetLeft ?? 0)) *
        0.5;
      const beforeElEnd =
        this._offset * -1 +
        this._parent.nativeElement.offsetWidth * 0.5 -
        distance;
      const afterElStart =
        this._offset * -1 +
        this._parent.nativeElement.offsetWidth * 0.5 +
        distance;
      this._items.forEach((el) => {
        if (
          beforeElEnd <= el.nativeElement.offsetLeft &&
          el.nativeElement.offsetLeft < afterElStart
        ) {
          // this.setSelected(el.nativeElement.id);
          this._offset =
            el.nativeElement.offsetLeft * -1 +
            this._parent.nativeElement.offsetWidth * 0.5 -
            elWidth * 0.5;
          this._container.nativeElement.style.transform = `translateX(${this._offset}px)`;
          setTimeout(() => {
            this._dragging = false;
          }, 1);
        }
      });
    }
  }
}
