/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive } from '@angular/core';
import { ChipActionBase } from './chip-action-base.directive';

@Directive({
  selector: 'button[designChipRemove]',
  standalone: true,
  host: {
    class: 'design-chip-remove',
    '(click)': 'handleClick($event)',
  },
})
export class DesignChipRemove extends ChipActionBase {
  override handleClick(event: MouseEvent) {
    if (!this.disabled && this._parentChip.removable) {
      event.preventDefault();
      this._parentChip.remove();
    }
  }
}
