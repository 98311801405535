import { InjectionToken, Signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TFormExtended } from '@simlab/data-access';

export const ProcoreRFIStatuses = {
  Draft: 'draft',
  Open: 'open',
  Closed: 'closed'
} as const;
export type ProcoreRFIStatusesKeys = keyof typeof ProcoreRFIStatuses;
export type ProcoreRFIStatusesValues =
  (typeof ProcoreRFIStatuses)[ProcoreRFIStatusesKeys];

export const ProcoreRFIStatusesRecord: Record<ProcoreRFIStatusesKeys, string> =
  {
    Draft: $localize`:@@DRAFT:Draft`,
    Closed: $localize`:@@CLOSED:Closed`,
    Open: $localize`:@@OPEN:Open`
  };

export type RFIAnnotation = {
  rfiDueDate: string | Date | undefined;
  rfiBallInCourt: number[] | undefined;
  rfiStatuses: Record<ProcoreRFIStatusesKeys, boolean>;
};

export type RFIAnnotationForm = TFormExtended<RFIAnnotation>;

export interface IRfiAnnotation {
  rfiForm: FormGroup<RFIAnnotationForm>;
}

export const rfiAnnotationFormGroup = new InjectionToken<IRfiAnnotation>(
  'rfiAnnotationFormGroup'
);

export interface ProcoreProjectInfo {
  procoreCompanyId: number;
  procoreProjectId: number;
  projectId: string;
}

export const procoreBaseInfoPayload = new InjectionToken<
  Signal<ProcoreProjectInfo | undefined>
>('ProcoreBaseInfoPayload');

export type ProcoreBaseInfoPayload = {
  procoreCompanyId: number;
  procoreProjectId: number;
};

export type RFIItemPayload = {
  procoreCompanyId: number;
  procoreProjectId: number;
  rfiProcoreId: number;
};
