import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

const FILTER_CHANGE_DEBOUNCE = 300;
@Injectable()
export class FiltersService<T> {
  private readonly _filterForm: UntypedFormGroup = new UntypedFormGroup({});

  private readonly _initialFormValues: { [name: string]: unknown } = {};

  /**
   * @description
   * Reset filters value to initial state
   * @returns
   */
  public reset(): void {
    this._filterForm.reset();
    this._filterForm.patchValue(this._initialFormValues);
  }

  /**
   * @description
   * Initialize filter form group and create controls
   * Then return reference
   * @returns
   */
  public initFilterForm<T extends { [prop: string]: any }>(
    model: T
  ): UntypedFormGroup {
    Object.keys(model).forEach((prop) => {
      this._initialFormValues[prop] = model[prop];
      this._filterForm.addControl(prop, new UntypedFormControl(model[prop]));
    });
    return this._filterForm;
  }

  /**
   * @description
   * Return reference to filter form
   * @returns
   */
  public get filterForm(): UntypedFormGroup {
    return this._filterForm;
  }

  /**
   * @description
   * Emits any filter value change
   * @returns
   */
  public get filterChanges$(): Observable<T> {
    return this._filterForm.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(FILTER_CHANGE_DEBOUNCE)
    );
  }

  /**
   * @description
   * Return filter form value
   * @returns
   */
  public get selectedValues(): T {
    return this._filterForm.value;
  }
}
