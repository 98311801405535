import { UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

const TEXT = {
  Title: $localize`:@@TITLE:Title`,
  Subject: $localize`:@@SUBJECT:Subject`,
  Description: $localize`:@@DESCRIPTION:Description`,
  Question: $localize`:@@QUESTION:Question`
};

@Component({
  selector: 'procore-request-container',
  standalone: true,
  imports: [UpperCasePipe],
  templateUrl: './request-container.component.html',
  styleUrl: './request-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestContainerComponent {
  private readonly _sanitizer = inject(DomSanitizer);

  readonly type = input.required<'Issue' | 'Request'>();
  readonly title = input.required<string>();
  readonly description = input('', {
    transform: (description: string) =>
      this._sanitizer.bypassSecurityTrustHtml(description)
  });

  readonly titleLabel = computed(() => {
    return this.type() === 'Issue' ? TEXT.Title : TEXT.Subject;
  });

  readonly descriptionLabel = computed(() => {
    return this.type() === 'Issue' ? TEXT.Description : TEXT.Question;
  });
}
