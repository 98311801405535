import { Update } from '@ngrx/entity';
import {
  createAction,
  createActionGroup,
  emptyProps,
  props
} from '@ngrx/store';
import {
  PunchItemStatuses,
  RfiStatuses,
  SimplifiedStage,
  Stage
} from '@simlab/data-access';
import { Vector3 } from '@simlab/matterport/assets/mpSdk/sdk';
import { StageWithCount } from './stages.models';
// import { Stage } from '@simlab/data-access';

export const init = createAction(
  '[Stages Page] Init',
  props<{
    projectId: string;
    stageId: string | undefined;
  }>()
);

export const selectInitStage = createAction(
  '[Stages Page] Init set Stage',
  props<{ selectedId: string }>()
);

export const clearStore = createAction('[Stages Local] Clear Store');
export const decrementRfi = createAction('[Stages Local] Decrement RFI');
export const changeRfiCount = createAction(
  '[Stages Local] Change Rfi Count',
  props<{ stageId: string; rfiStatusName: keyof RfiStatuses; change: number }>()
);
export const changePunchItemCount = createAction(
  '[Stages Local] Change Punch Item Count',
  props<{
    stageId: string;
    punchItemStatusName: Exclude<keyof PunchItemStatuses, 'status'>;
    change: number;
  }>()
);
export const decrementPunchItem = createAction(
  '[Stages Local] Decrement Punch Item'
);
export const clearSelection = createAction('[Stages Local] Clear Selection');
export const selectLocalStage = createAction('[Stages Local] Select Stage');
export const selectedLocalStageSuccess = createAction(
  '[Stages Local] Select Stage Success',
  props<{ selectedId: string; cameraPose?: Vector3 }>()
);

export const setHasComponent = createAction(
  '[Stages Local] Set Has Component',
  props<{ stageId: string }>()
);

export const setSynchronizedMatterport = createAction(
  '[Stages/LOCAL] Set Synchronized Matterport',
  props<{ stageId: string }>()
);

export const StagesApiActions = createActionGroup({
  source: 'Stages/API',
  events: {
    'Edit Stage': props<{ id: string; name: string; stageDate: string }>(),
    'Edit Stage Failure': props<{ error: any }>(),
    'Update Stage Counter': props<{
      stageId: string;
      noteStatus: string;
      noteType: string;
    }>(),
    'Update Stage Description ': props<{
      newDescription: string;
      stageId: string;
    }>(),
    'Update Stage Description Success': props<{
      update: Update<StageWithCount>;
    }>(),
    'Update Stage Description Failure': props<{ error: any }>(),
    'Update Stage Name': props<{ newName: string; stageId: string }>(),
    'Update Stage Success': props<{ update: Update<StageWithCount> }>(),
    'Update Stage Failure': props<{ error: any }>(),
    'Remove All Stages': emptyProps(),
    'Remove Stage Success': props<{ stageId: string }>(),
    'Remove Stage Failure': props<{ error: any }>(),
    'Remove Stage': props<{ stageId: string; projectId: string }>(),
    'No Exist Stages': emptyProps(),
    'Load Stages Success': props<{ stages: StageWithCount[] }>(),
    'Load Stages Failure': props<{ error: any }>(),
    'Add Stage': props<{ stage: SimplifiedStage }>(),
    'Add Stage Success': props<{ stage: Stage }>(),
    'Add Stage Failure': props<{ error: any }>()
  }
});

export const resetProcoreCountsFromStages = createAction(
  '[Stages Local] Reset Stages Count'
);
