import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import {
  ApiProcoreService,
  canRenderProcoreEntity
} from '@simlab/procore/data-access';
import { procoreBaseInfoPayload } from '@simlab/procore/models';
import { ProcoreAuthorizationService } from '@simlab/procore/services';
import { of } from 'rxjs';

export const documentRoutes: Routes = [
  {
    path: 'stages',
    loadComponent: () =>
      import('./stages-documents/stages-documents.component').then(
        (e) => e.StagesDocumentsComponent
      )
  },
  {
    path: 'procore',
    providers: [ProcoreAuthorizationService],
    canMatch: [() => inject(ProcoreAuthorizationService).userConnected$],
    resolve: {
      procoreProjectUserPermissions: () => {
        const procoreBaseInfo = inject(procoreBaseInfoPayload);
        const procoreBaseInfoInstance = procoreBaseInfo();

        if (!procoreBaseInfoInstance) return of(undefined);
        return inject(ApiProcoreService).getUserProjectPermissions(
          procoreBaseInfoInstance
        );
      }
    },
    canActivate: [canRenderProcoreEntity],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./procore-wrapper/procore-wrapper.component').then(
            (e) => e.ProcoreWrapperComponent
          )
      },
      {
        path: 'documents',
        loadComponent: () =>
          import('./procore-documents/procore-documents.component').then(
            (e) => e.ProcoreDocumentsComponent
          )
      },
      {
        path: 'drawings',
        loadComponent: () =>
          import('./procore-drawings/procore-drawings.component').then(
            (e) => e.ProcoreDrawingsComponent
          )
      },
      {
        path: 'pictures',
        loadComponent: () =>
          import('./procore-pictures/procore-pictures.component').then(
            (e) => e.ProcorePicturesComponent
          )
      }
    ]
  },
  {
    path: 'procore',
    canActivate: [canRenderProcoreEntity],
    loadComponent: () =>
      import(
        './procore-not-connected-account/procore-not-connected-account.component'
      ).then((e) => e.ProcoreNotConnectedAccountComponent)
  },
  {
    path: '',
    redirectTo: 'stages',
    pathMatch: 'full'
  }
];
