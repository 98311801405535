import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  viewChild
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { UiIconModule } from '@simlab/ui/icon';
import { UiProgressSpinnerComponent } from '@simlab/ui/progress-spinner';
import { ImageBaseDirective } from '@simlab/ui/slideshow';
import { defer, filter, fromEvent, map, mergeMap } from 'rxjs';
import { BaseMedia } from '../../../directives/base-media.directive';

@Component({
  selector: 'feature-stages-note-media-photo-item',
  templateUrl: './photo-item.component.html',
  styleUrls: ['./photo-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UiProgressSpinnerComponent,
    ImageBaseDirective,
    UiIconModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteMediaPhotoItemComponent extends BaseMedia {
  private readonly _photoElement =
    viewChild<ElementRef<HTMLImageElement>>('photoElement');

  @Output() imageLoadedEmitter: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private readonly _cantLoadImage$ = toObservable(this._photoElement).pipe(
    filter(
      (element): element is ElementRef<HTMLImageElement> =>
        element !== undefined
    ),
    mergeMap((element) =>
      fromEvent(element.nativeElement, 'error').pipe(
        map(() => {
          this.loadedImage(true);
          return true;
        })
      )
    )
  );

  protected readonly cantLoadImage = toSignal(this._cantLoadImage$);

  readonly photo$ = defer(() =>
    this.getSingleBlobSecureUrl(this.media, 'Thumbnail')
  );

  loadedImage(value: boolean) {
    this.imageLoadedEmitter.next(value);
  }
}
