import {
  DirectoryModel,
  DocumentsFetchQuery,
  DocumentsListResponse,
  ImportProcoreResoures,
  ImportProcoreStatus,
  MoveResourcesRequest,
  SubdirectoryModel,
  TDocumentsToZipStatus,
  TElementsToZip,
  TFileModelFull,
  TTaskIdElementsToZip
} from '@simlab/documents/models';
import { EMPTY, Observable, forkJoin, iif, of, switchMap } from 'rxjs';

import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ApiBase } from '@simlab/data-access';
import { ProjectsFacade } from '@simlab/data-store';
import { DocumentsInstanceDataToken } from '@simlab/documents/data-access';
import { requestToHttpParamsMapper } from '@simlab/util/core';
import { ProcoreDocumentsApi } from '../pages/documents-page/procore-documents/models/procore-documents-api.modal';
import { ProcoreDocumentTypeEnum } from '../pages/documents-page/procore-documents/models/procore-documents.enum';

const counter = 0;

@Injectable()
export class ProcoreDocumentsApiService
  extends ApiBase
  implements ProcoreDocumentsApi
{
  private readonly _projectId = inject(DocumentsInstanceDataToken).projectId;
  private readonly _selectedProject = toSignal(
    inject(ProjectsFacade).selectProjectById$(this._projectId())
  );
  fetch$(query: DocumentsFetchQuery): Observable<DocumentsListResponse> {
    const params = requestToHttpParamsMapper(query);
    return this.httpClient.get<DocumentsListResponse>(
      `${this.url}/procore-api/get-project-documents`,
      { params }
    );
  }
  getDirectory$(id: string): Observable<DirectoryModel> {
    const params = new HttpParams()
      .set('id', id)
      .set('projectId', this._projectId());
    return this.httpClient.get<DirectoryModel>(
      `${this.url}/procore-api/get-project-documents-directory`,
      { params }
    );
  }

  importProcoreResources$(request: ImportProcoreResoures): Observable<string> {
    return this.httpClient.post<string>(
      `${this.url}/construction-site-management-module/import-procore-resources`,
      request
    );
  }

  importProcoreResourcesStatuses$(): Observable<ImportProcoreStatus[]> {
    return this.httpClient.get<ImportProcoreStatus[]>(
      `${this.url}/construction-site-management-module/get-procore-import-status`
    );
  }

  getDocument$(id: string): Observable<TFileModelFull> {
    const params = new HttpParams()
      .set('id', id)
      .set('projectId', this._projectId());
    return this.httpClient.get<TFileModelFull>(
      `${this.url}/procore-api/get-project-document`,
      { params }
    );
  }

  moveResources$({
    directoryIds,
    documentIds,
    targetDirectoryId
  }: MoveResourcesRequest) {
    return EMPTY;
  }

  getSubdirectories$(
    projectId: string,
    directoryId: string
  ): Observable<SubdirectoryModel[]> {
    let params = new HttpParams();
    params = params.append('ProjectId', projectId);
    params = params.append('DirectoryId', directoryId);

    return this.httpClient.get<SubdirectoryModel[]>(
      `${this.url}/construction-site-management-module/get-subdirectories?${params.toString()}`
    );
  }

  getResourcesDownloadUrls$(documentIds: string[]): Observable<string[]> {
    const procoreCompanyId = this._selectedProject()?.procoreInfo?.companyId;

    if (!procoreCompanyId) return EMPTY;
    return forkJoin(
      documentIds.map((docId) =>
        this.getDocument$(docId).pipe(
          switchMap((document) => {
            return iif(
              () => 'url' in document && document.url !== undefined,
              this.httpClient.get(
                `${this.url}/procore-api/get-url-location?ProcoreCompanyId=${procoreCompanyId}&Url=${encodeURIComponent(document.url!)}`,
                { responseType: 'text' }
              ),
              of('')
            );
          })
        )
      )
    );
  }
  getPackDocumentsToZipStatus$(
    taskId: string
  ): Observable<TDocumentsToZipStatus> {
    return this.httpClient.get<TDocumentsToZipStatus>(
      `${this.url}/construction-site-management-module/get-pack-documents-to-zip-status?TaskId=${taskId}`
    );
  }

  packDocumentsToZip$(
    payload: TElementsToZip
  ): Observable<TTaskIdElementsToZip> {
    const body = {
      projectId: payload.projectId,
      procoreContainerIds: payload.directoryIds,
      procoreResourceIds: payload.documentIds,
      procoreDocumentType: ProcoreDocumentTypeEnum.File
    };

    return this.httpClient.post<TTaskIdElementsToZip>(
      `${this.url}/construction-site-management-module/pack-procore-resources-to-zip`,
      body
    );
  }
}
