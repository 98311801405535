import { inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ApiFacadeService, Note } from '@simlab/data-access';
import { NEW_NOTE_LOCALLY_ID, NotesFacade } from '@simlab/data-store';
import { TagNoteTypes } from '@simlab/matterport/index';
import { map, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs';
import { Vector3 } from 'three';
import {
  AnnotationMarker,
  AnnotationTagPlacement,
  PlaceMarkerInAnnotationParams
} from '../tag-placement.component';

export class NotesTagPlacementService implements AnnotationTagPlacement {
  private readonly _notesFacade = inject(NotesFacade);
  private readonly _apiFacadeService = inject(ApiFacadeService);

  readonly hasMarker = toSignal(
    this._notesFacade.selectedNote$.pipe(map(({ marker }: Note) => !!marker))
  );

  readonly selectedAnnotationId$ = this._notesFacade.selectedNoteId$.pipe(
    take(1)
  );

  readonly removePosition$ = (): Observable<AnnotationMarker> =>
    this._notesFacade.selectedNote$.pipe(
      tap((note: Note) => {
        this._notesFacade.removeMarker(note.id);
      }),
      map((note: Note) => this._getMappedNoteToAnnotation(note)),
      mergeMap((note) =>
        this._apiFacadeService.rootNotes
          .removeMarkerFromRootNote({
            rootNoteId: note.id
          })
          .pipe(map(() => note))
      ),
      take(1)
    );

  readonly initMarkerSet$ = () =>
    this._notesFacade.selectedNote$.pipe(
      switchMap((note) => {
        if (note.id === NEW_NOTE_LOCALLY_ID) {
          this._notesFacade.saveLocalNote();
          return this._notesFacade.noteAdded$.pipe(
            map((newNote) => this._getMappedNoteToAnnotation(newNote))
          );
        }
        return of(this._getMappedNoteToAnnotation(note));
      })
    );

  placeMarkerInAnnotation$(params: PlaceMarkerInAnnotationParams) {
    return this._apiFacadeService.rootNotes
      .placeMarkerInRootNote({
        rootNoteId: params.annotationId,
        positionDto: params.convertedPosition,
        anchorPointNormalDto: new Vector3(0, 0, 0),
        rotationDto: {
          x: 0,
          y: 0,
          z: 0,
          w: 1
        }
      })
      .pipe(
        switchMap(() =>
          this._apiFacadeService.rootNotes.getRootNote({
            id: params.annotationId
          })
        ),
        tap((note) => {
          this._notesFacade.selectedNote(note);
          this._notesFacade.updateNote(note);
        }),
        map(() => params.annotationId)
      );
  }

  private _getMappedNoteToAnnotation({
    type,
    ...noteParams
  }: Note): AnnotationMarker {
    const markerType =
      type === 'Information'
        ? ('INFO' as TagNoteTypes)
        : (type.toUpperCase() as TagNoteTypes);

    return <AnnotationMarker>{ ...noteParams, type: markerType };
  }
}
