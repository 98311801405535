<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel i18n="@@PERSONAL_SETTINGS">Personal settings</h2>
  </ng-container>

  <ng-container designDialogContent>
    <div class="container__context">
      <div class="container__context__left-menu">
        @for (menu of menuList; track menu.id) {
          <button
            type="button"
            class="button-settings"
            [attr.data-cy]="menu.dataCy"
            design-flat-button
            designButtonColor="primary"
            [designButtonSelected]="menu.id === selectedMenuItem()"
            (click)="selectView(menu)"
          >
            <div class="label-settings">{{ menu.label }}</div>
          </button>
        }
      </div>

      <div class="container__context__portal">
        <ng-template
          #outlet="cdkPortalOutlet"
          [cdkPortalOutlet]="selectedPortal"
        ></ng-template>
      </div>
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      data-cy="personal-settings-ok-button"
      design-flat-button
      designButtonColor="accent"
      (click)="cancel()"
    >
      <div i18n="@@OK">Ok</div>
    </button>
  </ng-container>
</design-dialog-wrapper>
