<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel>{{ title }}</h2>
  </ng-container>

  <ng-container designDialogContent>
    <div class="dialog-content-container" [formGroup]="stageForm">
      <ui-form-field>
        <label i18n="@@ADD_STAGE_NAME_HEADER">Name</label>
        <ui-input
          data-cy="add-stage-name-input"
          i18n-placeholder="@@ADD_STAGE_NAME_PLACEHOLDER"
          placeholder="Stage Name"
          formControlName="name"
        ></ui-input>
        <ui-hint
          uiRawColor="red"
          [hidden]="
            !(stageForm.get('name')?.errors && stageForm.get('name')?.touched)
          "
          [validationErrors]="stageForm.get('name')?.errors"
        >
        </ui-hint>
      </ui-form-field>

      <design-form-field>
        <label designLabel i18n="@@SCAN_CREATION_DATE">
          Scan Creation Date
        </label>
        <input
          data-cy="add-stage-date-input"
          #stageDateRef="designDatepickerInput"
          designInput
          designDatepicker
          placeholder="to stage range"
          formControlName="stageDate"
          [maxlength]="10"
        />
        <button design-icon-button designSuffix (click)="stageDateRef.open()">
          <i [designIcon]="'Calendar'"></i>
        </button>

        <design-error></design-error>
      </design-form-field>
    </div>
  </ng-container>
  <ng-container designDialogFooter>
    <button design-stroked-button (click)="cancelForm()">
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button
      design-flat-button
      designButtonColor="accent"
      [disabled]="stageForm.valid === false"
      (click)="submitForm()"
      data-cy="add-stage-confirm-button"
    >
      <div i18n="@@CONFIRM">Confirm</div>
    </button>
  </ng-container>
</design-dialog-wrapper>
