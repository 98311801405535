<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel i18n="@@ADD_PROJECT">Add Project</h2>
  </ng-container>
  <ng-container designDialogContent>
    <ng-container
      *ngTemplateOutlet="mainInformation; context: { form: formGroup }"
    ></ng-container>

    <ui-divider class="container__content--divider"></ui-divider>
    <div class="container__google-map">
      <ui-map
        *ngIf="googleApi$ | async"
        class="google-map"
        (coordinatesEmitter)="setCoordinates($event)"
      ></ui-map>
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-stroked-button
      designButtonColor="primary"
      (click)="cancel()"
    >
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button
      design-flat-button
      designButtonColor="accent"
      [disabled]="formGroup.valid === false"
      (click)="submitForm()"
      data-cy="project-confirm-button"
    >
      <div i18n="@@CONFIRM">Confirm</div>
    </button>
  </ng-container>
</design-dialog-wrapper>

<ng-template #mainInformation [formGroup]="formGroup">
  <h3 i18n="@@ADD_INFORMATION">Add information</h3>
  <div class="information__content">
    <design-form-field class="information__content--title">
      <label designLabel i18n="@@PROJECT_TITLE">PROJECT TITLE</label>
      <input
        designInput
        i18n-placeholder="@@TITLE"
        placeholder="Title"
        formControlName="name"
        data-cy="project-title-input"
      />
      <design-error></design-error>
    </design-form-field>
    <ui-form-field class="information__content--photo">
      <label i18n="@@PHOTO">Photo</label>
      <ui-upload-image
        (selected)="handleImageReady($event)"
        class="information__content__image-upload"
      >
        <div
          *ngIf="imageSource$ | async as imageSource; else noImage"
          [style.backgroundImage]="'url(' + imageSource + ')'"
          class="information__content__image-upload--image"
        ></div>
        <ng-template #noImage>
          <i ui-icon name="icon_stages" size="img"></i>
        </ng-template>
        <div class="information__content__image-upload--overlay"></div>
      </ui-upload-image>
      <ui-hint
        i18n-message="@@ONLY_EXTENSION"
        message="You can add only .jpg, .jpeg, .png, .bmp"
        [hidden]="!notSupportedFile"
      >
      </ui-hint>
    </ui-form-field>
    <ui-form-field class="information__content--description">
      <label i18n="@@PROJECT_DESCRIPTION">Project description</label>
      <ui-textarea
        [realtime]="true"
        formControlName="description"
        i18n-placeholder="@@DESCRIPTION"
        placeholder="Description"
        data-cy="project-description-input"
        [minRows]="6"
      ></ui-textarea>
      <ui-hint [validationErrors]="descriptionControl?.errors"></ui-hint>
    </ui-form-field>
  </div>
</ng-template>
