<design-radio-expansion-panel class="measurement-group-list">
  <design-expansion-panel-header
    color="secondary"
    class="measurement-group-list--header"
  >
    <div designLabel class="measurement-group-list--header-label">
      <span class="name"
        >{{ group().name }} ({{ group().measurements.length }})</span
      >
      <span class="measurement-sum" *ngIf="group().measurements.length"
        >{{ group().summaryDisplayValue }} {{ unit() }}</span
      >
      <button
        design-icon-button
        class="action-button"
        [designMenuTriggerFor]="menu"
      >
        <i designIcon="more_second"></i>
      </button>
      <design-menu-panel #menu>
        <ng-container
          *ngIf="!canEditDeleteMeasurementGroup(group().creatorId); else rename"
        >
          <design-tooltip
            i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
            tooltip="Unsufficient privileges"
            position="left"
          >
            <ng-template [ngTemplateOutlet]="rename"></ng-template>
          </design-tooltip>
        </ng-container>

        <ng-template #rename>
          <button
            design-menu-item
            (click)="changeName.emit()"
            [disabled]="!canEditDeleteMeasurementGroup(group().creatorId)"
          >
            <ng-container i18n="@@RENAME">Rename</ng-container>
          </button>
        </ng-template>
        <ng-container
          *ngIf="!canEditDeleteMeasurementGroup(group().creatorId); else Attach"
        >
          <design-tooltip
            i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
            tooltip="Unsufficient privileges"
            position="left"
          >
            <ng-template [ngTemplateOutlet]="Attach"></ng-template>
          </design-tooltip>
        </ng-container>

        <ng-template #Attach>
          <button
            design-menu-item
            (click)="addMeasurementsToGroup.emit()"
            [disabled]="!canEditDeleteMeasurementGroup(group().creatorId)"
            i18n="@@AREM_ATTACH_MEASUREMENTS"
          >
            Attach measurements
          </button>
        </ng-template>
        <ng-container
          *ngIf="!canEditDeleteMeasurementGroup(group().creatorId); else Delete"
        >
          <design-tooltip
            i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
            tooltip="Unsufficient privileges"
            position="left"
          >
            <ng-template [ngTemplateOutlet]="Delete"></ng-template>
          </design-tooltip>
        </ng-container>

        <ng-template #Delete>
          <button
            design-menu-item
            (click)="deleteAreaMeasurementGroup.emit()"
            [disabled]="!canEditDeleteMeasurementGroup(group().creatorId)"
          >
            <ng-container i18n="@@DELETE">Delete</ng-container>
          </button>
        </ng-template>
      </design-menu-panel>
    </div>
  </design-expansion-panel-header>
  <ng-template designRadioExpansionPanelContent>
    <ng-container
      *ngFor="let measurement of group().measurements; trackBy: measurementsId"
    >
      <feature-measurement-item
        [measurement]="measurement"
        [suffix]="suffix"
        class="measurement-item"
      >
        <ng-template #suffix>
          <button design-icon-button [designMenuTriggerFor]="menu">
            <i designIcon="more_second"></i>
          </button>
          <design-menu-panel #menu>
            <ng-container
              *ngIf="
                !canEditDeleteMeasurementGroup(group().creatorId);
                else detach
              "
            >
              <design-tooltip
                i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
                tooltip="Unsufficient privileges"
                position="left"
              >
                <ng-template [ngTemplateOutlet]="detach"></ng-template>
              </design-tooltip>
            </ng-container>

            <ng-template #detach>
              <button
                design-menu-item
                [disabled]="!canEditDeleteMeasurementGroup(group().creatorId)"
                (click)="
                  removeMeasurementFromGroup.emit({
                    id: measurement.id,
                    groupId: group().id
                  })
                "
              >
                <ng-container i18n="@@AREM_DETACH_FROM_GROUP">
                  Detach from group
                </ng-container>
              </button>
            </ng-template>

            <button
              design-menu-item
              (click)="goToMeasurementList.emit(measurement.id)"
            >
              <ng-container i18n="@@AREM_GO_TO_LIST_MEASUREMENTS">
                Go to list measurements
              </ng-container>
            </button>
          </design-menu-panel></ng-template
        >
      </feature-measurement-item>
    </ng-container>
  </ng-template>
</design-radio-expansion-panel>
