<ng-container
  *ngTemplateOutlet="
    windowView === 'base' ? mainWindowTemplate : privateListWindowTemplate
  "
></ng-container>

<ng-template #mainWindowTemplate>
  <design-dialog-wrapper cdkTrapFocus>
    <ng-container designDialogHeader>
      <h2 designLabel i18n="@@PU_ADD_COMPONENT">Add Component</h2>
    </ng-container>
    <ng-container designDialogContent>
      <ui-select
        [(ngModel)]="modeSelect"
        data-cy="select-type-matterport-ui-select"
      >
        <ui-option
          data-cy="public-matterport-option"
          value="public"
          i18n="@@PU_MATTER_PUBLIC_LINK"
        >
          Matterport 360 &#176; - public link
        </ui-option>
        <ui-option
          data-cy="private-matterport-option"
          value="private"
          i18n="@@PU_MATTER_PRIVATE_LINK"
        >
          Matterport 360 &#176; - private link
        </ui-option>
      </ui-select>

      <ng-container
        *ngTemplateOutlet="modeSelect === 'public' ? publicMainTemplate : null"
      ></ng-container>
    </ng-container>

    <ng-container designDialogFooter>
      <!-- <button ui-stroked-button (click)="close()">Cancel</button> -->
      <button
        design-stroked-button
        designButtonColor="primary"
        (click)="close()"
      >
        <div i18n="@@CANCEL">Cancel</div>
      </button>
      <ng-container
        *ngTemplateOutlet="
          modeSelect === 'public' ? publicFooterTemplate : privateFooterTemplate
        "
      ></ng-container>
    </ng-container>
  </design-dialog-wrapper>
</ng-template>

<ng-template #publicMainTemplate>
  <form
    [formGroup]="componentForm"
    autocomplete="off"
    id="form-test-1"
    (submit)="confirm()"
  >
    <div class="design-padding">
      <design-form-field>
        <label designLabel i18n="@@COMPONENT_NAME_HEADER">Component Name</label>
        <input
          data-cy="add-component-name-input"
          designInput
          formControlName="name"
          i18n-placeholder="@@COMPONENT_NAME_PLACEHOLDER"
          placeholder="Ex. Living room model"
        />
        <design-error></design-error>
      </design-form-field>
    </div>

    <div class="design-padding">
      <design-form-field>
        <label designLabel i18n="@@MATTERPORT_COMPONENT_HEADER">
          Matterport Component Url
        </label>
        <input
          designInput
          data-cy="component-matterport-url-input"
          i18n-placeholder="@@MATTERPORT_COMPONENT_PLACEHOLDER"
          placeholder="Ex. my.matterport.com/models/00000000000"
          formControlName="componentUrl"
        />
        <design-error></design-error>
      </design-form-field>
    </div>
  </form>
</ng-template>

<ng-template #publicFooterTemplate>
  <!-- TODO: link somehow id of form to button -->
  <button
    design-flat-button
    designButtonColor="accent"
    type="submit"
    form="form-test-1"
    value="Submit"
    [disabled]="!componentForm.valid"
    data-cy="add-component-confirm-button"
  >
    <div i18n="@@CONFIRM">Confirm</div>
  </button>
</ng-template>

<ng-template #privateFooterTemplate>
  <button
    design-flat-button
    designButtonColor="accent"
    type="submit"
    form="form-test-1"
    value="Submit"
    data-cy="login-to-matterport-button"
    [designFireOnClick]="externalApiAuth$"
  >
    <design-button-loader
      designIconSuffix
      designColor="surface"
    ></design-button-loader>
    <div i18n="@@MATTER_LOGIN">Login to Matterport</div>
  </button>
</ng-template>

<ng-template #privateListWindowTemplate>
  <design-dialog-wrapper cdkTrapFocus>
    <ng-container designDialogHeader>
      <h2 designLabel i18n="@@PU_SELECT_SPACE">Select Space</h2>
    </ng-container>
    <ng-container designDialogContent>
      <main
        *ngIf="filteredModelsWithSteroids$ | async as models"
        class="ui-dialog-body"
      >
        <div class="ui-dialog-body__filter">
          <design-form-field class="full-width">
            <label designLabel i18n="@@PU_SELECT_SPACE_INFO">
              Select space to add as private Matterpoert link.
            </label>
            <input
              designInput
              i18n-placeholder="@@SEARCH"
              placeholder="Search"
            />
          </design-form-field>
        </div>

        <ng-container *ngIf="models.length; else nothingToShow">
          <design-selection-list
            ngModel
            (ngModelChange)="updateFormGroup($event)"
            uiScrollbarChecker
            class="list-container"
          >
            <design-list-option
              class="list-item"
              *ngFor="let model of models; trackBy: trackByModelId"
              [value]="model"
            >
              <feature-stages-model-list-item
                [item]="model"
              ></feature-stages-model-list-item>
            </design-list-option>
          </design-selection-list>
        </ng-container>
        <ng-template #nothingToShow>
          <p class="empty-list" i18n="@@PU_NOTHING_TO_SHOW">Nothing to show</p>
        </ng-template>
      </main>
    </ng-container>

    <ng-container designDialogFooter>
      <button design-stroked-button (click)="return()">
        <div i18n="@@CANCEL">Cancel</div>
      </button>
      <button
        design-flat-button
        designButtonColor="accent"
        [disabled]="!componentForm.valid"
        (click)="confirm()"
      >
        <b i18n="@@CONFIRM">Confirm</b>
      </button>
    </ng-container>
  </design-dialog-wrapper>
</ng-template>
