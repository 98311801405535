<div class="state-panel__wrapper__switch-state">
  <design-button-group
    [value]="view()"
    (selectionChange)="onSwitchView($event)"
  >
    <design-button-toggle value="TABLE">
      <i designIcon="table_list" designSize="12px"></i>
    </design-button-toggle>
    <design-button-toggle value="GRID">
      <i designIcon="grid_list" designSize="16px"></i>
    </design-button-toggle>
  </design-button-group>
</div>
