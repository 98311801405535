import { Clipboard } from '@angular/cdk/clipboard';
import { inject } from "@angular/core";
import { AbstractConstructor, Constructor } from "@simlab/design/internal";
import { ToastEventService } from "@simlab/util-shared";
import { ToastEvent } from 'libs/util/shared/src/lib/services/toast-event.service';
import { of } from "rxjs";


const COPY: ToastEvent = {
  action: of('Info'),
  message: {
    Info: $localize`:@@NOTE_COPIED:note's url copied to clipboard`,
    Error: '',
    Warning: '',
    Success: '',
    Pending: ''
  },
  performOnce: true
} as const;

export type CanCopyNoteUrl = {
  copyUrl(params?: ToastEvent): void;
}

type TCanCopyNoteUrl = Constructor<CanCopyNoteUrl> & AbstractConstructor<CanCopyNoteUrl>;
export function mixinCopyNoteUrl<T extends AbstractConstructor<any>>(base: T): TCanCopyNoteUrl & T;
export function mixinCopyNoteUrl<T extends Constructor<any>>(base: T) {
  return class extends base { 
      private _toastEventService = inject(ToastEventService);
      private readonly _clipboard = inject(Clipboard);
    
      copyUrl(param = COPY) {
        this._clipboard.copy(window.location.href);
        this._toastEventService.addEvent(param);
      }
}
}
