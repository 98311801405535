import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup
} from '@angular/forms';
import {
  ApiFacadeService,
  Author,
  FormatDate,
  SortingType
} from '@simlab/data-access';
import { UserPreferenceFacade } from '@simlab/data-store';
import {
  DesignButton,
  DesignFlatButtonModule,
  DesignIconButton
} from '@simlab/design/button';
import { DesignCheckbox } from '@simlab/design/checkbox';
import { DesignLabel, DesignSuffix } from '@simlab/design/common';
import {
  DesignDateValidators,
  DesignDatepickerInput
} from '@simlab/design/datepicker';
import { DesignFormFieldModule } from '@simlab/design/form-field';
import { FORMAT_DATE_CONFIG } from '@simlab/design/format-date';
import { DesignIcon } from '@simlab/design/icon';
import { DesignInput } from '@simlab/design/input';
import { UiButtonModule } from '@simlab/ui/button';
import { UiCheckboxModule } from '@simlab/ui/checkbox';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiIconModule } from '@simlab/ui/icon';
import { UiInputModule } from '@simlab/ui/input';
import { UiSelectModule } from '@simlab/ui/select';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { ProjectsFilter } from '../../models/projects-filters-base';
import { FiltersService } from '../../services/filters/filters.service';
import { FILTER_SERVICE_TOKEN } from '../../tokens/filter-service.token';

interface Organization {
  id: string;
  name: string;
  invitationPending: boolean;
  invitationId?: string;
  description?: string;
}

const SORTING: {
  name: keyof typeof SortingType;
  displayName: string;
  isAscending: boolean;
}[] = [
  {
    isAscending: true,
    name: 'Name',
    displayName: $localize`:@@PROJECT_NAME_SORT_ASC:Project name A-Z`
  },
  {
    isAscending: false,
    name: 'Name',
    displayName: $localize`:@@PROJECT_NAME_SORT_DESC:Project name Z-A`
  },
  {
    isAscending: true,
    name: 'CreatedAt',
    displayName: $localize`:@@CREATED_SORT_ASC:Creation date ↑`
  },
  {
    isAscending: false,
    name: 'CreatedAt',
    displayName: $localize`:@@CREATED_SORT_DESC:Creation date ↓`
  },
  {
    isAscending: true,
    name: 'CreatorName',
    displayName: $localize`:@@AUTHOR_SORT_ASC:Author A-Z`
  },
  {
    isAscending: false,
    name: 'CreatorName',
    displayName: $localize`:@@AUTHOR_SORT_DESC:Author Z-A`
  },
  {
    isAscending: true,
    name: 'EditedAt',
    displayName: $localize`:@@MODIFIED_SORT_ASC:Last modified ↑`
  },
  {
    isAscending: false,
    name: 'EditedAt',
    displayName: $localize`:@@MODIFIED_SORT_DESC:Last modified ↓`
  }
];

@Component({
  selector: 'feature-projects-side-panel-filters',
  templateUrl: './side-panel-filters.component.html',
  styleUrls: ['./side-panel-filters.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CommonModule,
    UiSelectModule,
    UiDividerModule,
    UiInputModule,
    FormsModule,
    ReactiveFormsModule,
    UiIconModule,
    UiButtonModule,
    UiCheckboxModule,
    DesignFormFieldModule,
    DesignLabel,
    DesignSuffix,
    DesignIcon,
    DesignInput,
    DesignDatepickerInput,
    DesignIconButton,
    DesignFlatButtonModule,
    DesignButton,
    DesignCheckbox
  ]
})
export class SidePanelFiltersComponent implements OnInit, OnDestroy {
  private readonly filterService =
    inject<FiltersService<ProjectsFilter>>(FILTER_SERVICE_TOKEN);
  private readonly apiFacadeService = inject(ApiFacadeService);
  private readonly _userPreferenceFacade = inject(UserPreferenceFacade);
  readonly formatDate: Observable<string> =
    inject(FORMAT_DATE_CONFIG).observable;
  readonly sorting: {
    name: keyof typeof SortingType;
    displayName: string;
    isAscending: boolean;
  }[] = SORTING;
  @Input() createProject$!: Observable<unknown>;

  public filterFormRef!: UntypedFormGroup;

  private readonly _destroySource: Subject<void> = new Subject<void>();

  readonly authors$: Observable<Author[]> =
    this.apiFacadeService.projects.getAllProjectsAuthors();

  readonly organizations$: Observable<Organization[]> =
    this.apiFacadeService.organizations.getOrganizations$().pipe(
      map((organization: Organization[]) => {
        organization.sort(function (previous, next) {
          if (previous.name && next.name) {
            const previousName = previous.name.toUpperCase();
            const nextName = next.name.toUpperCase();
            return previousName < nextName
              ? -1
              : previousName > nextName
                ? 1
                : 0;
          }
          return 0;
        });
        return organization;
      }),
      takeUntil(this._destroySource)
    );

  public orderBy = this.sorting[0];

  /**
   * @description
   * Emit every time filter value change
   */
  @Output() filterChange = new EventEmitter<ProjectsFilter>();

  /**
   * @description
   * Emit every time sorting change
   */
  @Output() orderChanges = new EventEmitter<{
    name: keyof typeof SortingType;
    displayName: string;
    isAscending: boolean;
  }>();

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  private _formatDate: FormatDate = 'dd/MM/yyyy';
  ngOnInit(): void {
    this.filterFormRef = this.filterService.initFilterForm(
      new ProjectsFilter()
    );
    this.changesEmitter();
    if (this.filterFormRef) {
      this.filterChange.next(this.filterFormRef.value);
    }

    this._userPreferenceFacade.getDateFormat$
      .pipe(takeUntil(this._destroySource))
      .subscribe((e) => {
        this._formatDate = e;
      });
  }

  /**
   * @description
   * Set next value on output event emitter
   */
  private changesEmitter(): void {
    this.filterService.filterChanges$
      .pipe(takeUntil(this._destroySource))
      .subscribe((filter: ProjectsFilter) => {
        if (this._formatDate === 'dd/MM/yyyy') {
          filter = {
            ...filter,
            createdFrom: filter.createdFrom
              ? DesignDateValidators.convertToDateByEuropeanFormat(
                  filter.createdFrom
                ).toString()
              : '',
            createdTo: filter.createdTo
              ? DesignDateValidators.convertToDateByEuropeanFormat(
                  filter.createdTo
                ).toString()
              : ''
          };
        } else {
          filter = {
            ...filter,
            createdFrom: filter.createdFrom
              ? DesignDateValidators.convertToDateByUSAFormat(
                  filter.createdFrom
                ).toString()
              : '',
            createdTo: filter.createdTo
              ? DesignDateValidators.convertToDateByUSAFormat(
                  filter.createdTo
                ).toString()
              : ''
          };
        }
        this.filterChange.next(filter);
      });
  }

  /**
   * @description
   * Reset filters value to initial state
   */
  public reset(): void {
    this.filterService.reset();
  }

  orderChange(event: any): void {
    console.log(event);
    this.orderChanges.next(event);
  }
}
