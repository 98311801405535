export const DocumentTypeEnum = {
  Unknown: 0,
  Image: 1,
  Video: 2,
  Audio: 3,
  Doc: 4,
  Model3D: 5,
  Blueprint: 6
} as const;

export const DocumentSourceEnum = {
  Unknown: 0,
  Local: 1,
  Procore: 2
} as const;

export const ExternalTransferStatusEnum = {
  None: 0,
  Processing: 1,
  Success: 2,
  Failure: 3
} as const;

export const DirectorySearchLevelEnum = {
  All: 0,
  Directory: 1,
  DirectoryAndSubdirectories: 2
} as const;

export const DocumentReferenceTypeEnum = {
  RootNote: 1,
  Component: 2
} as const;

export const DocumentsSortColumn = {
  CreatedAt: 1,
  Name: 2,
  Size: 3,
  Attached: 4
} as const;

export const DocumentReferenceTypePath: Record<
  DocumentReferenceTypeEnumValues,
  string
> = {
  [DocumentReferenceTypeEnum.Component]: 'info',
  [DocumentReferenceTypeEnum.RootNote]: 'notes'
};

export const DocumentReferenceTypeName: Record<
  DocumentReferenceTypeEnumValues,
  string
> = {
  [DocumentReferenceTypeEnum.Component]: 'Component',
  [DocumentReferenceTypeEnum.RootNote]: 'Note'
};

type EnumValueGetter<TEnum extends Record<string, number | string>> = {
  [TKey in keyof TEnum]: TEnum[TKey];
}[keyof TEnum];

export type DocumentTypeEnumValues = EnumValueGetter<typeof DocumentTypeEnum>;

export type DocumentReferenceTypeEnumValues = EnumValueGetter<
  typeof DocumentReferenceTypeEnum
>;

export type DocumentSourceEnumValues = EnumValueGetter<
  typeof DocumentSourceEnum
>;
export type ExternalTransferStatusEnumValues = EnumValueGetter<
  typeof ExternalTransferStatusEnum
>;
export type DirectorySearchLevelEnumValues = EnumValueGetter<
  typeof DirectorySearchLevelEnum
>;
export type DocumentsSortColumnValues = EnumValueGetter<
  typeof DocumentsSortColumn
>;
