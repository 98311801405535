import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MatterportApiConfig } from '@simlab/matterport/api';
import { MatterportConfig } from '@simlab/matterport';
import { Environment } from '@simlab/util-shared';

export function provideMatterportConfiguration(
  environment: Environment
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: MatterportApiConfig,
      useFactory: () => {
        return {
          applicationKey: environment.configuration.matterport.apiKey,
          clientId: environment.configuration.matterport.clientId,
          redirectOrigin: `${window.location.origin}${environment.configuration.matterport.redirectAfterLoginUrl}`,
          matterportModelApiUrl:
            environment.configuration.matterport.apiGraphUrl,
          matterportShowcaseApiUrl:
            environment.configuration.matterport.showcaseGraphUrl
        } as MatterportApiConfig;
      }
    },
    {
      provide: MatterportConfig,
      useFactory: () => {
        return {
          key: environment.configuration.matterport.apiKey,
          hideFullscreen: true,
          hideHelp: true
        } as MatterportConfig;
      }
    }
  ]);
}
