import { DataSource } from '@angular/cdk/collections';
import { computed, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { DocumentStates } from '@simlab/documents/models';
import { Observable, Subject, takeUntil } from 'rxjs';

export class DocumentTableDataSource implements DataSource<DocumentStates> {
  private readonly _data = computed(() => {
    const documents = this.documents();
    const selectedState = this.selectedState();

    return documents.map((item: DocumentStates, index: number) => ({
      ...item,
      isSelected: selectedState[index]
    }));
  });

  private readonly _destroyRef$ = new Subject<void>();
  private readonly _source$ = toObservable(this._data).pipe(
    takeUntil(this._destroyRef$)
  );

  constructor(
    readonly documents: Signal<DocumentStates[]>,
    readonly selectedState: Signal<boolean[]>
  ) {}

  connect(): Observable<readonly DocumentStates[]> {
    return this._source$;
  }

  disconnect(): void {
    this._destroyRef$.next();
    this._destroyRef$.complete();
  }
}
