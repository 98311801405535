import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignIcon } from '@simlab/design/icon';
import { breakpointsObserver } from '@simlab/design/layout';
import { TActionNameOnCloseModal } from '@simlab/procore/models';
import {
  LinkedProjectCardComponent,
  ProjectCard
} from '../linked-project-card/linked-project-card.component';

export type TLinkedProjectsInformationData = {
  status: 'CONNECTED' | 'DISCONNECTED';
  projects: [ProjectCard, ProjectCard];
};

@Component({
  selector: 'procore-linked-projects-information-modal',
  standalone: true,
  imports: [
    DesignDialogWrapperModule,
    DesignIcon,
    DesignFlatButton,
    DesignStrokedButton,
    LinkedProjectCardComponent,
    TitleCasePipe
  ],
  templateUrl: './linked-projects-information-modal.component.html',
  styleUrl: './linked-projects-information-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkedProjectsInformationModalComponent {
  private readonly _ref = inject(DialogRef<TActionNameOnCloseModal>);
  protected readonly data = inject<TLinkedProjectsInformationData>(DIALOG_DATA);
  protected readonly isMobile = inject(breakpointsObserver).phone;

  protected close() {
    this._ref.close();
  }

  backTo() {
    this._ref.close('BackTo');
  }
}
