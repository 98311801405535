/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  ViewEncapsulation,
  input
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuModule } from '@simlab/ui/menu';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { TMeasurementExtended } from '../../../models/src/common-models';

@Component({
  selector: 'feature-measurement-item',
  standalone: true,
  imports: [
    CommonModule,
    DesignIconButton,
    DesignIcon,
    UiMenuModule,
    UiMenuPanelModule
  ],
  templateUrl: './measurement-item.component.html',
  styleUrls: ['./measurement-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'feature-stages-measurement-item',
    '[style.--color]': 'color'
  }
})
export class MeasurementItemComponent {
  readonly suffix = input<TemplateRef<unknown> | null>();
  readonly measurement = input.required<TMeasurementExtended>();

  get color() {
    return this.measurement().color;
  }
}
