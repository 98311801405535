import { effect, Injectable, OnDestroy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ApiFacadeService, StageComponent } from '@simlab/data-access';
import { ComponentsFacade, StagesFacade } from '@simlab/data-store';
import { MatterportApiFacadeService } from '@simlab/matterport/api';
import { Camera } from '@simlab/matterport/assets/mpSdk/sdk';
import {
  BehaviorSubject,
  defer,
  iif,
  Observable,
  Subject,
  switchMap,
  take,
  tap,
  throwError
} from 'rxjs';
import { AuthorizationResult } from '../components/add-component-dialog/add-component-dialog.component';
import { PanelState } from '../models/panel-state';
export const SHOWCASE_ROUTE = '/stages/matterport';
export const SYNCHRONIZATION_ROUTE = '/stages/synchronization';
@Injectable()
export class StagesRootService implements OnDestroy {
  private readonly _destroyed = new Subject<void>();
  private readonly _selectedStageId = toSignal(this.stagesFacade.selectedId$);
  get projectId(): string {
    const _projectId = window.location.href.split('/')[5];
    if (!_projectId) {
      throw new Error('something went wrong!');
    }

    return _projectId;
  }

  private readonly _screenshot = new BehaviorSubject<boolean>(false);
  readonly screenshot$ = this._screenshot.asObservable();

  set screenshot(value: boolean) {
    this._screenshot.next(value);
  }

  private readonly _mobileScreenSize = new BehaviorSubject<boolean>(false);
  private readonly _mobileScreenSize$ = this._mobileScreenSize.asObservable();
  get mobileScreenSize$(): Observable<boolean> {
    return this._mobileScreenSize$;
  }
  set mobileScreenSize(value: boolean) {
    this._mobileScreenSize.next(value);
  }

  private readonly _leftPanelState = new BehaviorSubject<PanelState>('close');
  private readonly _leftPanelState$ = this._leftPanelState.asObservable();
  get leftPanelState$(): Observable<PanelState> {
    return this._leftPanelState$;
  }
  set leftPanelState(value: PanelState) {
    this._leftPanelState.next(value);
  }

  private readonly _rightPanelState = new BehaviorSubject<PanelState>('close');
  private readonly _rightPanelState$ = this._rightPanelState.asObservable();
  get rightPanelState$(): Observable<PanelState> {
    return this._rightPanelState$;
  }
  set rightPanelState(value: PanelState) {
    this._rightPanelState.next(value);
  }

  private _lastKnownCameraPose: Camera.Pose | undefined;
  private _lastKnownFloor: number | undefined;

  get lastKnownCameraPose() {
    return this._lastKnownCameraPose;
  }

  get lastKnownFloor() {
    return this._lastKnownFloor;
  }

  public _lastKnownMatterportOffset: string | undefined;
  private _selectedScan!: StageComponent;

  private readonly _ownerSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly _ownerSource$ = this._ownerSource.asObservable();
  readonly owner$: Observable<boolean> = this._ownerSource$;

  set owner(value: boolean) {
    this._ownerSource.next(value);
  }

  readonly externalApiAuth$ = defer(() =>
    this.matterportApiService.authorization$()
  ).pipe(
    take(1),
    switchMap((result: AuthorizationResult) =>
      iif(
        () => result?.status === 'SUCCESS' && !!result?.response?.authCode,
        this.apiFacadeService.tokens
          .addMatterportUserToken({
            code: <string>result?.response?.authCode
          })
          .pipe(
            tap((token: string) => {
              this.matterportApiService.setMatterportToken(token);
            })
          ),
        throwError(() => new Error('Auth api error occurred!'))
      )
    )
  );

  constructor(
    private readonly router: Router,
    private readonly stagesFacade: StagesFacade,
    private readonly componentsFacade: ComponentsFacade,
    private readonly matterportApiService: MatterportApiFacadeService,
    private readonly apiFacadeService: ApiFacadeService
  ) {
    this._lastKnownFloor =
      this.router.getCurrentNavigation()?.extras.state?.['floor'];
    if (this.router.getCurrentNavigation()?.extras.state?.['position']) {
      this._lastKnownCameraPose =
        this.router.getCurrentNavigation()?.extras.state?.['position'];
    }

    this.initStagesFacade();
    this._stageChangesObserver();
  }
  private _stageChangesObserver() {
    effect(
      () => {
        const stageId = this._selectedStageId();
        if (!stageId) return;
        //this._araMeasurementFacade.init(stageId);
      },
      {
        allowSignalWrites: true
      }
    );
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  loadSelectedScan(selectedComponent: StageComponent) {
    if (!this._selectedScan || this._selectedScan.id !== selectedComponent.id) {
      this.componentsFacade.setSelectedComponentById(selectedComponent.id);
      if (selectedComponent !== undefined && !selectedComponent.sweepsUpdating)
        this.componentsFacade.checkTheSweepOnComponent(selectedComponent);
    }
  }

  private initStagesFacade(): void {
    if (this.projectId) {
      this.stagesFacade.removeAllStage();
      this.componentsFacade.removeAllComponent();
    }
  }
}
