import { Observable } from 'rxjs';
import { ICreationInfo } from './stages-base.interace';

export interface Media extends MediaICreationInfo {
  id: string;
  name: string;
  url: string;
  creatorId: string;
  addedAt: string;
  lastEditorId: string;
  privateUrl?: string;
  digitalNoteUrl?: string; //NOTE:(olek) abomination; the same as url but in other endpoint
  type: ResponseMediaType;
  // Procore Media
  // Image
  procoreImageId?: number;
  thumbnailUrl?: string;
  // Drawing
  procoreDrawingId?: number;
  pdfUrl?: string;
  pngUrl?: string;
}

export type ResponseMediaType =
  | 'AngleMeasurement'
  | 'Audio'
  | 'Brush'
  | 'Document'
  | 'Photo'
  | 'Video'
  | 'VolumeMeasurement'
  | 'ProcoreImage'
  | 'ProcoreDocument'
  | 'ProcoreDrawing';

export type MediaICreationInfo = Omit<
  ICreationInfo,
  'creatorName' | 'creatorId'
> & {
  addedByName: string;
  addedBy: string;
};

export interface MediaSlideShow extends Media {
  url$?: Observable<string>;
}

export type MediaInfo = Pick<Media, 'id' | 'name'> & {
  type: Media['type'] | undefined;
};

export const ACCEPTED_VIDEO = [
  'video/*',
  'video',
  '.dv',
  '.m4v',
  '.mov',
  '.mp4',
  '.mpg',
  '.mpeg',
  '.ogv',
  '.vp8',
  '.webm'
];
export const ACCEPTED_DOCUMENT = [
  '.doc',
  '.docx',
  '.xml',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.pdf',
  'application/pdf',
  '.odt',
  '.txt',
  '.dwg',
  '.svg',
  '.xlsx',
  '.xls',
  '.ods',
  '.model',
  '.CATPart',
  '.CATProduct',
  '.CGR',
  '.prt',
  '.asm',
  '.jt',
  '.j_t',
  '.ipt',
  '.iam',
  '.dgn',
  '.sldprt',
  '.sldasm',
  '.par',
  '.psm'
];
export const ACCEPTED_AUDIO = ['audio/mp3', 'audio/wav', '.mp3', '.wav'];
export const ACCEPTED_PHOTO = ['.jpeg', '.png', '.bmp', '.jpg'];
