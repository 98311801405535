<div
  cdk-overlay-origin
  #origin="cdkOverlayOrigin"
  #trigger
  class="annotation-active-filters__trigger"
  [class.annotation-active-filters__trigger--visible]="chips().length"
>
  <div
    class="annotation-active-filters__trigger__wrapper"
    (click)="openPanel()"
  >
    <span
      i18n="@@FILTER_COUNT"
      class="annotation-active-filters__trigger__wrapper__text"
      >{{ filtersLength() }} filters selected</span
    >

    <div class="annotation-active-filters__trigger__buttons">
      <button
        class="annotation-active-filters__trigger__button cursor-pointer"
        (click)="clearAllFilters()"
      >
        <i designIcon="cross" designSize="24px" class="pointer"></i>
      </button>

      <button
        class="annotation-active-filters__trigger__button annotation-active-filters__trigger__button--opener cursor-pointer"
      >
        <i
          designIcon="arrow_right"
          designSize="20px"
          [designRotateBy]="panelOpen() ? 270 : 90"
        ></i>
      </button>
    </div>
  </div>
</div>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="panelOpen()"
  [cdkConnectedOverlayPositions]="positions"
  (backdropClick)="close()"
  (detach)="close()"
>
  @if (chips().length) {
    <div class="annotation-active-filters__panel">
      <div class="annotation-active-filters__panel__chips-wrapper">
        @for (
          chip of chips() | slice: 0 : maxVisibleFiltersLength;
          track chip
        ) {
          <div>
            <ui-chip [removable]="true" (eClose)="removeSelectedFilter(chip)">
              <span
                class="annotation-active-filters__panel__chips-wrapper__category"
                >{{ chip.key | chipsCategoryFilterTranslate }}</span
              >
              <span
                class="annotation-active-filters__panel__chips-wrapper__value text-bold"
                >{{ chip.displayValue }}</span
              >
            </ui-chip>
          </div>
        }
      </div>
      <div class="annotation-active-filters__panel__show-all">
        <a
          class="annotation-active-filters__panel__show-all__link"
          i18n="@@SHOW_ALL_FILTERS"
          (click)="showAllFilters.emit()"
          >Show all filters</a
        >
      </div>
    </div>
  }
</ng-template>
