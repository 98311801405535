import { inject, Injectable } from '@angular/core';
import { ApiFacadeService } from '@simlab/data-access';

import { filter, map, Observable, shareReplay } from 'rxjs';

import {
  documentSourcesList,
  documentTypeOptions
} from '../../pages/documents-page/stages-documents/data-access/stages-documents.data';
import { StagesDocumentsFilters } from './StagesDocumentsFiltersFormBuilder';

type MappedFilters<TData = StagesDocumentsFilters> = {
  [TKey in keyof TData]: (
    data: NonNullable<ExtractTypeFromArray<TData[TKey]>>
  ) => string | Observable<string | undefined> | undefined | any;
};

export type ExtractTypeFromArray<TData> =
  TData extends Array<unknown> ? TData[number] : TData;

@Injectable()
export class StagesDocumentsSelectedFiltersMapperClass {
  private readonly _apiFacadeService = inject(ApiFacadeService);
  private readonly _authorsList$ = this._apiFacadeService.projects
    .getAllProjectsAuthors()
    .pipe(shareReplay());

  mapper(): MappedFilters {
    return {
      documentTypes: (data) =>
        documentTypeOptions.find(({ id }) => id === data)?.name,
      creatorIds: (data) => {
        return this._authorsList$.pipe(
          filter((authorList) => authorList.length > 0),
          map(
            (authorList) =>
              authorList.find((item) => item.userId === data)?.userName
          )
        );
      },
      documentSources: (data) =>
        documentSourcesList.find(({ id }) => id === data)?.name
    };
  }
}
