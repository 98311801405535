import {
  AnimationTriggerMetadata,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

const SORT_ANIMATION_TRANSITION = '0.5s';

export const DesignSortAnimations: {
  readonly arrowOpacity: AnimationTriggerMetadata;
} = {
  arrowOpacity: trigger('arrowOpacity', [
    state('desc-to-active, asc-to-active, active', style({ opacity: 1 })),
    state('desc-to-hint, asc-to-hint, hint', style({ opacity: 0.54 })),
    state(
      'hint-to-desc, active-to-desc, desc, hint-to-asc, active-to-asc, asc, void',
      style({ opacity: 0 }),
    ),
    transition(
      '* => asc, * => desc, * => active, * => hint, * => void',
      animate('0ms'),
    ),
    transition('* <=> *', animate(SORT_ANIMATION_TRANSITION)),
  ]),
};
