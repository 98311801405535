<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <div class="header">
      <i
        ui-icon
        name="icon_warning_color"
        customSize="7.25rem"
        defaultColor
      ></i>
      <h2 designLabel i18n="@@IMPORT_FROM_PRIVATE_LINK">
        Import from a Private Link
      </h2>
    </div>
  </ng-container>

  <ng-container designDialogContent>
    <div class="content">
      <div class="main-text" i18n="@@IMPORT_TAG_FROM_PRIVATE_MATT_INFO">
        We have detected an import from a private model (3D scan). Currently,
        the application supports importing only from default TAG 2.0 layer. To
        load other layers you need to temporarily switch the model to the public
        and enter to TAG 2.0 importer again. If you can't do so, don't hesitate
        to get in touch with our support team.
      </div>
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-stroked-button
      designButtonColor="warning"
      (click)="contactUs()"
    >
      <div i18n="@@CONTACT_US">Contact us</div>
      <ui-button-loader></ui-button-loader>
    </button>
    <button design-flat-button designButtonColor="warning" (click)="confirm()">
      <div i18n="@@CONTINUE">Continue</div>
      <ui-button-loader></ui-button-loader>
    </button>
  </ng-container>
</design-dialog-wrapper>
