import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatterportAnnotationControlService } from '@simlab/annotation/data-access';
import { ProjectPrivilegesFacade } from '@simlab/data-store';
import { PunchItemState } from '@simlab/procore/annotation-panel/services';
import { PunchItem } from '@simlab/procore/models';
import { PunchItemStatusIconGetterPipe } from '@simlab/procore/pipes';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import { OpenNewTabService } from '@simlab/util-shared';
import { annotationsActionsToken } from 'common/annotation-panel/models';
import {
  AnnotationPanelLocation3DComponent,
  AnnotationPanelRootComponent
} from 'common/annotation-panel/ui';
import { map } from 'rxjs';
import { NotificationsComponent } from '../notifications/notifications.component';
import { RequestContainerComponent } from '../request-container/request-container.component';
import { BasePunchItemSidePanel } from './base-punch-item-side-panel';
import { PunchItemSidePanelAction } from './punch-item-side-panel-actions';

export type OnTransferPunchItemElementModel = {
  id: PunchItem['id'];
  withActionNumber: PunchItem['showYellowBackground'];
};

@Component({
  selector: 'procore-punch-item-side-panel',
  standalone: true,
  imports: [
    AnnotationPanelRootComponent,
    AnnotationPanelLocation3DComponent,
    NotificationsComponent,
    UiImageInfoModule,
    RequestContainerComponent,
    PunchItemStatusIconGetterPipe
  ],
  templateUrl: './punch-item-side-panel.component.html',
  styleUrl: './punch-item-side-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PunchItemState,
    OpenNewTabService,
    {
      provide: annotationsActionsToken,
      useClass: PunchItemSidePanelAction
    }
  ]
})
export class PunchItemSidePanelComponent extends BasePunchItemSidePanel {
  private readonly _matterportAnnotationControl = inject(
    MatterportAnnotationControlService
  );
  private readonly _privileges = inject(ProjectPrivilegesFacade);

  private readonly _hasEditPrivilegesInProject = toSignal(
    this._privileges.noEditPrivilegesInProject$.pipe(
      map((noEditPrivilegesInProject) => !noEditPrivilegesInProject)
    )
  );

  readonly stageName = input.required<string>();
  readonly onClosePanel = output<void>();
  readonly onTransferElement = output<OnTransferPunchItemElementModel>();

  readonly itemUrl = computed(() => {
    const { itemUrl } = this.punchItem();
    return itemUrl;
  });

  readonly name = computed(() => {
    const { name, position } = this.punchItem();
    return `#${position ?? ''}: ${name}`;
  });

  readonly status = computed(() => {
    const { status } = this.punchItem();
    return status;
  });

  readonly ballInCourt = computed(() => {
    const { ballInCourt } = this.punchItem();
    return ballInCourt.map((item) => item.name);
  });

  readonly richTextDescription = computed(() => {
    const { richTextDescription } = this.punchItem();
    return richTextDescription;
  });

  readonly hasMarker = computed(() => {
    return this.punchItem().marker !== undefined;
  });

  readonly canEdit = computed(() => {
    return (
      !!this.displayStatus()?.showEdit && this._hasEditPrivilegesInProject()
    );
  });

  get button() {
    return {
      title: $localize`:@@OPEN_IN_PROCORE:Open in Procore`,
      action$: this.openNewTab.openUrlInNewTabWithSafariSupport(this.itemUrl())
    };
  }

  protected setLocation() {
    this._matterportAnnotationControl.triggerAnnotationMarkerAddMode(
      'punchList',
      true
    );
  }

  protected reassignLocation() {
    this._matterportAnnotationControl.triggerAnnotationMarkerChangeMode(
      'punchList'
    );
  }

  protected goTo() {
    this._matterportAnnotationControl.moveToAnnotation('punchList');
  }
}
