import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output, inject } from '@angular/core';
import {
  CanColor,
  CanDisable,
  CanSize,
  mixinColor,
  mixinDisabled,
  mixinSize,
} from '@simlab/design/internal';

const _ChipBase = mixinColor(
  mixinSize(
    mixinDisabled(
      class {
        constructor(public _elementRef: ElementRef) {}
      }
    )
  )
);

@Directive()
export abstract class ChipBase
  extends _ChipBase
  implements CanColor, CanDisable, CanSize
{
  @Output()
  removed: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  label = '';

  @Input()
  get removable(): boolean {
    return this._removable;
  }

  set removable(value: BooleanInput) {
    this._removable = coerceBooleanProperty(value);
  }
  private _removable = false;

  constructor() {
        const elementRef = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);

    super(elementRef);
  }

  remove(): void {
    if (this.removable && !this.disabled) {
      this.removed.emit();
    }
  }
}
