<div class="ui-tooltip-content">
  <ng-content></ng-content>
</div>
@if (!disabled()) {
  <div class="ui-tooltip-wrapper" #tooltipWrapper [ngClass]="customClass()">
    @if (hasEmbeddedView) {
      <ng-template *ngTemplateOutlet="template"></ng-template>
    } @else {
      {{ text }}
    }
  </div>
}
