<i
  designIconPrefix
  [designIcon]="isPrimary() ? 'cloud_filled' : 'folder'"
  designSize="20px"
></i>

<span class="name">
  <ng-content></ng-content>
</span>

@if (!!isCurrentLocation()) {
  <span class="current-location-label">
    <i designIcon="check" designSize="28px"></i>
    <div i18n="@@CURRENT_LOCATION">Current location</div>
  </span>
}

@if (!isTouchableScreen() && !isPrimary()) {
  <button
    type="button"
    class="btn"
    [ngStyle]="{ visibility: isSelected() ? 'visible' : 'hidden' }"
    design-icon-button
    #goBtn
  >
    <i designIcon="arrow_right" designSize="20px"></i>
  </button>
}
