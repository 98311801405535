<div class="slider-color">
  <canvas #canvas class="slider-color__slider"> </canvas>
  <div class="wrapper">
    <input
      type="range"
      [step]="1"
      [min]="0"
      [max]="255"
      (input)="trackInput($event)"
    />
  </div>

  <div #pointer class="slider-color__thumb"></div>
</div>
