<div class="filter-container__form">
  <ui-input
    i18n-placeholder="@@SEARCH"
    placeholder="Search"
    [prefix]="search"
    [formControl]="searchControl"
  ></ui-input>
  <ng-template #search>
    <i ui-font fontName="search" fontSize="1.5em"></i>
  </ng-template>
  <!-- <button ui-flat-button>Search</button> -->
  <button
    design-icon-button
    class="filter-button"
    [designMenuTriggerFor]="menu"
  >
    <i designIcon="filter" designSize="1.5em"></i>
  </button>
  <design-menu-panel #menu>
    <div class="grid-menu">
      <div
        *ngFor="let color of colors"
        (click)="select(color)"
        style="--clr: {{ color }}"
        class="color-selector"
      ></div>
    </div>
  </design-menu-panel>
</div>
<div class="selected-colors">
  <ui-chip-list>
    <ui-chip
      (eClose)="removeSelected(color.key)"
      [removable]="true"
      *ngFor="let color of selectedColors | keyvalue"
      style="--clr2: {{ color.key }}"
    ></ui-chip>
    <span
      *ngIf="(selectedColors | keyvalue).length"
      class="clear-all"
      (click)="clearAllSelected()"
      i18n="@@CLEAR_ALL"
    >
      Clear All
    </span>
  </ui-chip-list>
</div>
