import { Pipe, PipeTransform } from '@angular/core';
import { NotificationType, RFIStatus } from '@simlab/procore/models';

const rfiStatusLabel: Record<RFIStatus, NotificationType> = {
  [RFIStatus.Draft]: 'Blue',
  [RFIStatus.Open]: 'Blue',
  [RFIStatus.Closed]: 'Green'
};

@Pipe({ name: 'getRFIStatusType', standalone: true })
export class RFIStatusTypeGetterPipe implements PipeTransform {
  transform(status: RFIStatus): NotificationType {
    return rfiStatusLabel[status];
  }
}
