<table
  cdk-table
  designSort
  [designSortActive]="sortActive()"
  [designSortDirection]="sortDirection()"
  [dataSource]="dataSource"
  [trackBy]="trackById"
  (contentChanged)="cdr.markForCheck()"
  class="ui-table"
>
  <ng-container cdkColumnDef="name">
    <th
      class="ui-header-cell"
      cdk-header-cell
      *cdkHeaderCellDef
      [design-sort-header]="sortDictionary['Name'].key"
    >
      <div i18n="@@NAME">Name</div>
    </th>
    <td class="ui-cell" cdk-cell *cdkCellDef="let row">
      <div class="name-cell__container ui-cell__content">
        <documents-document-badge-state
          class="name-cell__badge"
          [data]="row"
          [isSelected]="row.isSelected"
        />
        <span [title]="row.data.name" class="name-cell__badge-name">
          {{ row.data.name }}
        </span>
      </div>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="author">
    <th class="ui-header-cell" cdk-header-cell *cdkHeaderCellDef>
      <div i18n="@@AUTHOR">Author</div>
    </th>
    <td class="ui-cell" cdk-cell *cdkCellDef="let row">
      <div class="ui-cell__content">
        <div class="creator__name">{{ row.data.creatorName }}</div>
        <div class="creator__email">{{ row.data.creatorEmail }}</div>
      </div>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="created">
    <th
      class="ui-header-cell"
      cdk-header-cell
      *cdkHeaderCellDef
      [design-sort-header]="sortDictionary['CreatedAt'].key"
    >
      <div i18n="@@CREATED">Created</div>
    </th>
    <td class="ui-cell" cdk-cell *cdkCellDef="let row">
      {{ row.data.createdAt | formatDateSignal }}
    </td>
  </ng-container>

  <ng-container cdkColumnDef="attached">
    <th
      class="ui-header-cell"
      cdk-header-cell
      *cdkHeaderCellDef
      [design-sort-header]="sortDictionary['Attached'].key"
    >
      <div i18n="@@ATTACHED">Attached</div>
    </th>
    <td class="ui-cell" cdk-cell *cdkCellDef="let row">
      {{ row.data.attached ?? '' }}
    </td>
  </ng-container>

  <ng-container cdkColumnDef="location">
    <th class="ui-header-cell" cdk-header-cell *cdkHeaderCellDef>
      <div i18n="@@LOCATION">Location</div>
    </th>
    <td class="ui-cell" cdk-cell *cdkCellDef="let row">
      @if ({ computed: row.data.path | getLocationByPath }; as path) {
        <documents-breadcrumb-tooltip [path]="path.computed">
          <button
            type="button"
            class="ui-cell__content location-btn"
            [ngClass]="{ 'location-btn--main': path.computed.length === 1 }"
          >
            <i
              designIconPrefix
              [designIcon]="
                path.computed.length === 1 ? 'cloud_filled' : 'folder'
              "
              designSize="20px"
            ></i>
            <div class="ui-cell__content--text">
              {{ path.computed[path.computed.length - 1].name }}
            </div>
          </button>
        </documents-breadcrumb-tooltip>
      }
    </td>
  </ng-container>

  <ng-container cdkColumnDef="size">
    <th
      class="ui-header-cell"
      cdk-header-cell
      *cdkHeaderCellDef
      [design-sort-header]="sortDictionary['Size'].key"
    >
      <div i18n="@@SIZE">Size</div>
    </th>
    <td class="ui-cell" cdk-cell *cdkCellDef="let row">
      @if (row.data.isDirectory) {
        -
      } @else {
        {{ row.data.size | covertByte }}
      }
    </td>
  </ng-container>

  <ng-container cdkColumnDef="actions">
    <th class="ui-header-cell" cdk-header-cell *cdkHeaderCellDef></th>
    <td class="ui-cell" cdk-cell *cdkCellDef="let row">
      <documents-documents-action-btn
        [data]="row.data"
      ></documents-documents-action-btn>
    </td>
  </ng-container>

  <tr
    class="ui-header-row"
    cdk-header-row
    *cdkHeaderRowDef="columns(); sticky: true"
  ></tr>
  <tr
    *cdkRowDef="let row; columns: columns()"
    cdk-row
    documentsCanSelect
    documentsCanUploading
    documentsCanOpen
    [selected]="selectedIds()?.includes(row.data.id) || false"
    [data]="row"
    class="ui-row"
  ></tr>
</table>
