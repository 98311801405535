import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { QueueService } from '@simlab/event-queue/services';
import { UiSelectModule } from '@simlab/ui/select';
import { TEventItemStatus } from '../ui-event-element/ui-event-element.component';

@Component({
  selector: 'event-queue',
  standalone: true,
  imports: [NgTemplateOutlet, UiSelectModule, DesignIconButton, DesignIcon],
  templateUrl: './event-queue.component.html',
  styleUrl: './event-queue.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventQueueComponent {
  private readonly _queueService = inject(QueueService);
  readonly views = this._queueService.views;
  readonly hasFailedEvents = computed(() => {
    const views = this.views();
    if (!views) return false;

    return views.some(
      ({ context }) =>
        'status' in context &&
        (context.status as TEventItemStatus).workStatus ===
          'COMPLETED_WITH_FAILURES'
    );
  });

  cleaningAllFailedTask() {
    this._queueService.removeAllFailed();
  }
}
