import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable, TemplateRef } from '@angular/core';
import { ProjectsFacade } from '@simlab/data-store';
import { ToastService } from '@simlab/design/toast';
import { TEventElementItem } from '@simlab/event-queue';
import { TEventQueueItem } from '@simlab/event-queue/models';
import { QueueService } from '@simlab/event-queue/services';
import type { ModalComponent, ModalData } from '@simlab/modal/ui';
import { ApiProcoreService } from '@simlab/procore/data-access';
import { AssignProject } from '@simlab/procore/models';
import {
  catchError,
  EMPTY,
  exhaustMap,
  filter,
  from,
  map,
  of,
  Subject,
  take,
  takeUntil,
  tap
} from 'rxjs';

export type ReconnectProcoreProjectParams = AssignProject;
export type RedistributeProcoreProjectDataParams = {
  projectId: string;
  redistributeDataForm: TemplateRef<any>;
  includeManuallyAssignedFn: () => boolean;
  redistributeTaskTemplate: TemplateRef<any>;
  proceduresAfterSuccessfulRedistribute?: () => void;
};
export type DisconnectProcoreProjectDataParams = {
  projectId: string;
  contentTemplate: TemplateRef<any>;
};

export const connectProjectInfoTranslations: Record<string, string> = {
  disconnectProjectTitle: $localize`:@@DISCONNECT_PROJECT:Disconnect project`,
  disconnectProjectMessage: $localize`:@@DISCONNECT_PROJECT_MESSAGE:You are trying to disconnect this project from Procore. After disconnecting, all data from the Procore will no longer be displayed in Stages.`,
  disconnectProjectMessage2: $localize`:@@DISCONNECT_PROJECT_MESSAGE_2:All Procore data settings will be permanently deleted from Stages after 30 days if not reconnected again.`,
  reconnectProjectTitle: $localize`:@@UPDATE_PROCORE_DATA:Update Procore data`,
  reconnectProjectMessage: $localize`:@@RECONNECT_PROJECT_MESSAGE: Project that has been reconnected may contain data the needs to be updated. This can done now or late (context menu on this tab)`,
  connectToAnotherProjectTitle: $localize`:@@CONNECT_TO_ANOTHER_PROJECT_TITLE:Connect to another project?`,
  connectToAnotherProjectMessage: $localize`:@@CONNECT_TO_ANOTHER_PROJECT_MESSAGE:You are trying to connect this project with another Procore source, than it was connected before. You previous Procore data will be deleted from Stages and replaced with new one.`,
  unassignUnexpectedError: $localize`:@@ERROR_UNEXPECTED_DISCONNECT_PROCORE:Unexpected error during disconnect project. Please try again later.`,
  redistributeDataTitle: $localize`:@@REDISTRIBUTE_DATA:Redistribute the data?`,
  redistributeDataMessage: $localize`:@@REDISTRIBUTE_DATA_MESSAGE:Procore data can be redistributed again to reflect changes in Stages project since the previous import.`,
  redistributeDataMessageTitle: $localize`:@@REDISTRIBUTING_PROCORE_DATA:Redistributing Procore data`
};
@Injectable()
export class ConnectProjectInfoRootService {
  private readonly _dialog = inject(Dialog);
  private readonly _apiProcoreService = inject(ApiProcoreService);
  private readonly _queueService = inject(QueueService);
  private readonly _toastService = inject(ToastService);
  private readonly _projectsFacade = inject(ProjectsFacade);

  unassignProjectAction$ = exhaustMap(
    (params: DisconnectProcoreProjectDataParams) => {
      const { projectId, contentTemplate } = params;
      return this._confirmDisconnectModal(contentTemplate).pipe(
        exhaustMap((isConfirm) => {
          if (!isConfirm) return of(false);

          this._projectsFacade.disconnectingProcoreProject(projectId);
          return this._unassignProject$(projectId).pipe(
            tap({
              next: () =>
                this._projectsFacade.unassignProcoreProject(projectId),
              error: () => this._projectsFacade.assignProcoreProject(projectId)
            })
          );
        })
      );
    }
  );

  reconnectProjectAction$ = exhaustMap(
    (params: ReconnectProcoreProjectParams) =>
      this._reconnectModal().pipe(
        exhaustMap((isConfirm) => {
          if (!isConfirm) return EMPTY;

          this._projectsFacade.reconnectingProcoreProject(params.projectId);
          return this._apiProcoreService.assignProcoreProject$(params).pipe(
            tap({
              next: () =>
                this._projectsFacade.assignProcoreProject(params.projectId),
              error: () =>
                this._projectsFacade.unassignProcoreProject(params.projectId)
            })
          );
        })
      )
  );

  connectToAnotherProjectAction$ = exhaustMap(() =>
    this._connectToAnotherProjectModal().pipe(
      filter((isConfirm) => !!isConfirm)
    )
  );

  redistributeDataAction$ = exhaustMap(
    ({
      redistributeDataForm,
      ...eventData
    }: RedistributeProcoreProjectDataParams) =>
      this._redistributeDataModal(redistributeDataForm).pipe(
        filter((isConfirm) => !!isConfirm),
        tap(() => this._reassignProcoreItems(eventData))
      )
  );

  private _reassignProcoreItems({
    redistributeTaskTemplate,
    projectId,
    includeManuallyAssignedFn,
    proceduresAfterSuccessfulRedistribute
  }: Omit<RedistributeProcoreProjectDataParams, 'redistributeDataForm'>) {
    const cancelSubject$ = new Subject<void>();
    const context: TEventElementItem = {
      title: connectProjectInfoTranslations['redistributeDataMessageTitle'],
      currentElement: 0,
      countOfElements: 100,
      status: {
        workStatus: 'WORKING',
        showErrorDetails: false,
        failed: []
      },
      cancel: () => cancelSubject$.next()
    };
    const id = this._queueService.addEvent({
      type: 'NOTIFICATION',
      templateRef: redistributeTaskTemplate,
      context,
      execute$: this._apiProcoreService
        .reassignProcoreItems(projectId, includeManuallyAssignedFn())
        .pipe(
          takeUntil(cancelSubject$),
          tap({
            next: () => {
              context.currentElement = 100;
              context.status.workStatus = 'COMPLETED';
              context.cancel = () => this._queueService.removeById(id);

              proceduresAfterSuccessfulRedistribute &&
                proceduresAfterSuccessfulRedistribute();
              this._queueService.updateView(id, context);
            },
            error: () => {
              context.currentElement = 100;
              context.status.workStatus = 'COMPLETED_WITH_FAILURES';
              context.cancel = () => this._queueService.removeById(id);
              this._queueService.updateView(id, context);
            },
            complete: () => {
              this._queueService.removeById(id);
            }
          })
        ),
      end: this._jobUploadEnd
    });
  }

  protected _jobUploadEnd = (
    e: any,
    event: TEventQueueItem<any, TEventElementItem>
  ) => {
    this._projectsFacade.refreshProcore();
  };

  private _redistributeDataModal(redistributeDataForm: TemplateRef<any>) {
    const modalData: ModalData = {
      title: connectProjectInfoTranslations['redistributeDataTitle'],
      message: connectProjectInfoTranslations['redistributeDataMessage'],
      type: 'inform',
      content: { template: redistributeDataForm }
    };

    return this._getModalClosed$(modalData);
  }

  private _unassignProject$(projectId: string) {
    return this._apiProcoreService
      .unAssignProcoreProject$({
        projectId
      })
      .pipe(
        map(() => true),
        catchError(() => {
          this._toastService.open(
            connectProjectInfoTranslations['unassignUnexpectedError'],
            'Warning'
          );
          return of(false);
        })
      );
  }

  private _connectToAnotherProjectModal() {
    const modalData: ModalData = {
      title: connectProjectInfoTranslations['connectToAnotherProjectTitle'],
      message: connectProjectInfoTranslations['connectToAnotherProjectMessage'],
      type: 'alert'
    };

    return this._getModalClosed$(modalData);
  }

  private _confirmDisconnectModal(template: TemplateRef<any>) {
    const modalData: ModalData = {
      title: connectProjectInfoTranslations['disconnectProjectTitle'],
      message: '',
      content: {
        template
      },
      type: 'alert'
    };

    return this._getModalClosed$(modalData);
  }

  private _reconnectModal() {
    const modalData: ModalData = {
      title: connectProjectInfoTranslations['reconnectProjectTitle'],
      message: connectProjectInfoTranslations['reconnectProjectMessage'],
      type: 'inform'
    };

    return this._getModalClosed$(modalData);
  }

  private _getModalClosed$(data: ModalData) {
    return from(import('@simlab/modal/ui')).pipe(
      exhaustMap(
        ({ ModalComponent }) =>
          this._dialog.open<boolean, ModalData, ModalComponent>(
            ModalComponent,
            {
              data,
              width: 'min(90%, 610px)'
            }
          ).closed
      ),
      take(1)
    );
  }
}
