import { Pipe, PipeTransform } from '@angular/core';
import { CHIP_CATEGORY, TChipCategory } from '../models/chip.models';

@Pipe({
  standalone: true,
  name: 'chipsCategoryFilterTranslate'
})
export class ChipsFilterCategoryTranslatePipe implements PipeTransform {
  transform(key: TChipCategory): string {
    return `${CHIP_CATEGORY[key]}: `;
  }
}
