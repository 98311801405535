<div
  class="animation"
  [@takePicture]="isOpen ? 'doneScreenshot' : 'doScreenshot'"
  (@takePicture.done)="isOpen = true"
></div>

<div class="body" *ngIf="!imageToDraw; else imageDrawing">
  <div class="body__frame">
    <div class="body__frame__corridor left-top-corridor"></div>
    <div class="body__frame__corridor left-bottom-corridor"></div>
    <div class="body__frame__corridor right-top-corridor"></div>
    <div class="body__frame__corridor right-bottom-corridor"></div>
    <div class="body__frame__circle"></div>

    <button design-icon-button class="body__frame__button" (click)="close()">
      <i designIcon="cross" designSize="3rem"></i>
    </button>
  </div>

  <div class="body__input" [formGroup]="formGroup">
    <ui-form-field
      class="title"
      [hideRequiredMarker]="false"
      [hideLabel]="true"
    >
      <ui-input
        placeholder="Screenshot name"
        i18n="@@SCREENSHOT_NAME_PLACEHOLDER"
        formControlName="screenshotName"
      >
      </ui-input>
      <ui-hint
        uiRawColor="red"
        [hidden]="!checkScreenShotIsValid()"
        [validationErrors]="screenshotNameAbstractControl.errors"
      >
      </ui-hint>
    </ui-form-field>
    <div class="body__input--button">
      <button
        design-flat-button
        designButtonColor="secondary"
        class="body__input--take-screenshot-draw"
        [designFireOnClick]="takeScreenshotAndDraw$"
        [disabled]="checkScreenShotIsValid()"
      >
        <i
          class="body__input--take-screenshot-draw--image"
          designIcon="brush_first"
          designSize="1.5rem"
        ></i>
        <div i18n="@@TAKE_SCREENSHOT_AND_DRAW">Take a Screenshot and draw</div>
        <design-button-loader
          designIconPrefix
          designColor="primary"
        ></design-button-loader>
      </button>
      <button
        design-flat-button
        designButtonColor="primary"
        class="body__input--take-screenshot"
        [designFireOnClick]="takeScreenshot$"
        [disabled]="checkScreenShotIsValid()"
      >
        <div i18n="@@TAKE_SCREENSHOT">Take Screenshot</div>
        <design-button-loader designIconSuffix></design-button-loader>
      </button>
    </div>
  </div>
</div>

<ng-template #imageDrawing
  ><feature-stages-image-drawing
    [image]="imageToDraw"
    [imageName]="screenshotName"
    (closeEmitter)="close()"
    (saveImageEmitter)="saveDrawingScreenshot($event)"
    [savingImage$]="savingImage$"
  >
  </feature-stages-image-drawing
></ng-template>
