import { InjectionToken } from '@angular/core';
import { ApiBase } from '@simlab/data-access';
import {
  DirectoryModel,
  DocumentsFetchQuery,
  DocumentsListResponse,
  MoveResourcesRequest,
  MoveResourcesResponse,
  SubdirectoryModel,
  TDocumentsToZipStatus,
  TElementsToZip,
  TFileModelFull,
  TTaskIdElementsToZip
} from '@simlab/documents/models';
import { Observable } from 'rxjs';

export interface HasDocumentApi<
  DocumentsInstance extends DocumentsApi = DocumentsApi
> {
  _api: DocumentsInstance;
}

export interface DocumentsApi extends ApiBase {
  fetch$: (query: DocumentsFetchQuery) => Observable<DocumentsListResponse>;
  getDirectory$(id: string): Observable<DirectoryModel>;
  getDocument$: (id: string) => Observable<TFileModelFull>;
  moveResources$: (
    request: MoveResourcesRequest
  ) => Observable<MoveResourcesResponse>;
  getSubdirectories$: (
    projectId: string,
    directoryId: string
  ) => Observable<SubdirectoryModel[]>;
  packDocumentsToZip$: (
    documentIds: TElementsToZip
  ) => Observable<TTaskIdElementsToZip>;
  getPackDocumentsToZipStatus$: (
    taskId: string
  ) => Observable<TDocumentsToZipStatus>;
  getResourcesDownloadUrls$: (documentIds: string[]) => Observable<string[]>;
}

export const documentsApiToken = new InjectionToken<DocumentsApi>(
  'documents-api'
);
