import { Pipe, PipeTransform, inject } from '@angular/core';
import { SystemOfMeasurement } from '@simlab/data-access';
import { CalculateMeasurementSumPipe } from './base-calculate-measurement.pipe';
import { UnitsPreferredByUserPipe } from './base-units-preferred.pipe';
import {
  TMeasurement,
  TMeasurementData
} from '@simlab/feature-measurement/models';

@Pipe({
  name: 'calculateAreaMeasurementSum'
})
export class CalculateAreaMeasurementSumPipe
  extends CalculateMeasurementSumPipe
  implements PipeTransform
{
  private readonly _unitsPreferredByUserPipe = inject(UnitsPreferredByUserPipe);
  transform(value: TMeasurement[], units: SystemOfMeasurement): number {
    return value.reduce(
      (previousValue: number, currentValue: TMeasurement) =>
        previousValue +
        this._unitsPreferredByUserPipe.transform(
          (currentValue.data as TMeasurementData).surfaceSize,
          units
        ),
      0
    );
  }
}
