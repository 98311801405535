export enum ProcoreElements {
  Admin = 1,
  RFIs,
  PunchItems,
  Documents,
  Drawings,
  Photos,
  AppManagement
}

export enum ProcoreUserAccessLevels {
  'None' = 1,
  'Read Only',
  'Standard',
  'Admin'
}

export const ProcorePermittedActions = {
  create_bid_note: 1,
  update_bid_note: 2,
  delete_bid_note: 3,
  create_prime_contract: 4,
  update_prime_contract: 5,
  delete_prime_contract: 6,
  view_payment_application_detail: 7,
  view_workflowable_setup: 8,
  view_full_workflow_history: 9,
  view_previous_step_history_only: 10,
  can_be_workflow_manager: 11,
  can_start_workflows: 12,
  configure_tool_workflow_settings: 13,
  import_line_items_from_csv: 14,
  modify_original_budget_amount: 15,
  view_direct_cost_details: 16,
  delete_budget_line_items: 17,
  lock_budget: 18,
  send_and_retrieve_from_erp: 19,
  delete_budget_changes: 20,
  create_edit_delete_budget_changes: 21,
  create_direct_cost: 22,
  update_direct_cost: 23,
  delete_direct_cost: 24,
  view_private_purchase_order_contract: 25,
  view_private_work_order_contract: 26,
  create_purchase_order_contract: 27,
  create_work_order_contract: 28,
  update_purchase_order_contract: 29,
  update_work_order_contract: 30,
  act_as_rfi_manager: 31,
  mark_official_responses: 32,
  view_private_rfis: 33,
  create_submittal: 34,
  create_submittal_package: 35,
  view_private_submittals: 36,
  view_private_transmittals: 37,
  view_vendor_observation_items: 38,
  respond_to_vendor_observation_items: 39,
  assign_standard_user: 40,
  view_vendor_punch_items: 41,
  respond_to_vendor_punch_items: 42,
  add_comment: 43,
  delete_own_comment: 44,
  create: 45,
  distribute: 46,
  edit: 47,
  manage_meeting_items: 48,
  manage_meeting_categories: 49,
  manage_related_items: 50,
  send_emails: 51,
  task_update_percent_complete: 52,
  lookahead_create: 53,
  lookahead_delete: 54,
  lookahead_view: 55,
  lookahead_view_change_history: 56,
  lookahead_task_create: 57,
  lookahead_task_delete: 58,
  lookahead_task_update: 59,
  log_restrictively: 60,
  update_own_entries: 61,
  view_company_entries: 62,
  delete_photo_album: 63,
  reorder_photo_albums: 64,
  view_private_photo_album: 65,
  create_photo_album: 66,
  upload_drawings: 67,
  upload_and_review_drawings: 68,
  access_private: 69,
  rename_files_and_folders: 70,
  move_files_and_folders: 71,
  set_permissions: 72,
  destroy_files_and_folders: 73,
  see_recycle_bin: 74,
  add_user: 75,
  assign_project_role: 76,
  create_user: 77,
  create_vendor: 78,
  create_distribution_group: 79,
  create_person: 80,
  manage_permission_templates: 81,
  remove_user: 82,
  remove_vendor: 83,
  request_imports: 84,
  see_vendor_insurances: 85,
  update_person: 86,
  update_general_settings: 87,
  update_active_tabs: 88,
  update_cost_codes: 89,
  manage_wbs_codes: 90,
  create_wbs_codes: 91,
  manage_locations: 92,
  create_vendors: 93,
  view_vendor_details: 94,
  create_users: 95,
  view_user_details: 96,
  can_see_company_templates: 97,
  can_create_company_templates: 98,
  can_destroy_company_templates: 99,
  can_assign_company_templates: 100,
  can_access_projects_for_all_users: 101,
  can_edit_project_details: 102,
  can_edit_docs: 103,
  can_add_notes: 104,
  can_export_bid: 105
} as const;

export type TProcorePermittedActions =
  (typeof ProcorePermittedActions)[keyof typeof ProcorePermittedActions];

export type ProcorePermission = {
  element: ProcoreElements;
  accessType: ProcoreUserAccessLevels;
  permitedActions: TProcorePermittedActions;
};
