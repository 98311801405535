import {
  AfterViewInit,
  computed,
  DestroyRef,
  Directive,
  inject,
  input,
  OnChanges,
  Signal,
  SimpleChanges
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TForm } from '@simlab/data-access';
import {
  DocumentFiltersBase,
  FiltersData,
  SelectedDocumentFilterItem
} from '@simlab/documents/data-access';
import { defer, distinctUntilChanged, filter, map, Subject, tap } from 'rxjs';

export type FiltersParams<TData extends Record<string, unknown>> = Record<
  keyof TData,
  string | string[] | undefined
>;

@Directive()
export abstract class DocumentsFilters<TModel extends Record<string, any>>
  implements DocumentFiltersBase<TModel>, FiltersData, OnChanges, AfterViewInit
{
  private readonly _router = inject(Router);
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _destroyRef = inject(DestroyRef);

  abstract readonly formGroup: Signal<FormGroup<TForm<TModel>>>;
  abstract readonly formView: Signal<NgForm>;
  abstract readonly selectedFilters: Signal<
    SelectedDocumentFilterItem[] | undefined
  >;

  readonly isRenderInModal = input(false);
  readonly formsValue = input<Record<string, any> | undefined>();
  readonly valueChanges = new Subject<Record<string, any>>();

  readonly valueChanges$ = defer(() =>
    this.formGroup().valueChanges.pipe(
      distinctUntilChanged(
        (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
      ),
      tap((data) => {
        this._router.navigate([], {
          queryParams: { ...data, folderId: null },
          queryParamsHandling: 'merge',
          state: { rerender: false }
        });
      })
    )
  );

  readonly value = computed(() => this.formGroup().value);

  constructor(
    private readonly _mapper: (urlParams: FiltersParams<TModel>) => TModel
  ) {}
  ngAfterViewInit(): void {
    this.valueChanges$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((data) => this.valueChanges.next(data));
    this.updateFormByUrlQueryParamsChanges$()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe();
  }

  updateFormByUrlQueryParamsChanges$() {
    return this._activatedRoute.queryParams.pipe(
      filter((queryParams) => this.hasDomainFields(queryParams)),
      map((data: Params) => data as FiltersParams<TModel>),
      tap((data) => this.formGroup().patchValue(this._mapper(data)))
    );
  }

  abstract hasDomainFields(queryParams: Params): boolean;

  ngOnChanges(changes: SimpleChanges): void {
    const formsValue = changes['formsValue'].currentValue;
    if (formsValue === undefined || Object.keys(formsValue).length === 0)
      return;

    const formGroup = this.formGroup();
    formGroup.patchValue(formsValue, { emitEvent: false, onlySelf: true });

    this._router.navigate([], {
      queryParams: { ...formsValue },
      queryParamsHandling: 'merge',
      state: { rerender: false }
    });
  }

  applyFilters(): void {
    const event: Event = new Event('submit');
    event.preventDefault();
    this.formView().onSubmit(event);
  }

  resetFilters(): void {
    this.formGroup().reset();
  }

  removeFilter(filterToRemove: SelectedDocumentFilterItem): void {
    const formControl = this.formGroup().get(filterToRemove.key);
    if (formControl === null) {
      console.error(
        `Control "${filterToRemove.key}" doesn't exists in formGroup`
      );
      return;
    }

    if (Array.isArray(formControl.value)) {
      const newValue = formControl.value.filter(
        (item: string | number) => item != filterToRemove.id
      );

      formControl.patchValue(newValue);
      return;
    }

    formControl.patchValue(null as any);
  }
}
