<div class="scroller" #parent>
  <div class="container" style="transform: translateX(0px)" #container>
    <div class="items-wrapper">
      <ng-container
        *ngFor="
          let item of stages$ | async;
          trackBy: trackById;
          let idx = index
        "
      >
        <div
          [ngClass]="{ selected: item.id === selectedElement }"
          class="item"
          [id]="item.id"
          #items
          (click)="selectItem(items.id)"
        >
          <div class="header">
            <h6 [class.bold]="selectedElement">
              {{ item.stageDate | formatDate | async }}
            </h6>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
