import { CommonModule, DOCUMENT, PlatformLocation } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
  output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApiFacadeService,
  OrganizationLimitsFacade,
  Project,
  SimplifiedStage
} from '@simlab/data-access';
import {
  AnnotationsFacade,
  ComponentsFacade,
  NotesFacade,
  ProjectPrivilegesFacade,
  ProjectsFacade,
  StageWithCount,
  StagesFacade
} from '@simlab/data-store';

import {
  PositionCameraService,
  RouterStoreParams,
  StageFiltersService
} from '@simlab/util-shared';

import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  defer,
  exhaustMap,
  filter,
  from,
  iif,
  map,
  mergeMap,
  of,
  retry,
  skip,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';

import {
  DesignFlatButton,
  DesignFlatButtonModule,
  DesignIconButton
} from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignIcon } from '@simlab/design/icon';

import { Dialog } from '@angular/cdk/dialog';
import { ToastService } from '@simlab/design/toast';
import { TProjectInfoBase } from '@simlab/feature/projects';
import type { ModalComponent, ModalData } from '@simlab/modal/ui';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  ProcoreDataProgressStatus,
  TActionNameOnCloseModal,
  TActionOnCloseModal,
  TProcoreProjectIntegration
} from '@simlab/procore/models';
import {
  LinkedProjectsInformationModalComponent,
  ProjectCard,
  ProjectIntegrationComponent,
  TLinkedProjectsInformationData
} from '@simlab/procore/ui';
import { UiIconModule } from '@simlab/ui/icon';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import {
  ConfirmationModalRef,
  DialogResponse,
  MODAL_DATA,
  ModalService
} from '@simlab/ui/modal';
import { UiProgressSpinnerModule } from '@simlab/ui/progress-spinner';
import { StageForm } from '../../models/stage-form';
import { ProjectLimitsService } from '../../services/project-limits.service';
import { StagesRootService } from '../../services/stages-root.service';
import { StageFormDialogComponent } from '../stage-form-dialog/stage-form-dialog.component';
import { StageListItemComponent } from '../stage-list-item/stage-list-item.component';
import { StageListComponent } from '../stage-list/stage-list.component';

const ADD_STAGE = $localize`:@@ADD_STAGE:Add stage`;
const LIMIT_OF_STAGES_INFO = $localize`:@@LIMIT_OF_STAGES_INFO:Limit of stages is over. \nGo to a higher plan to solve the problem.`;

const DESCRIPTION_IMAGE_INFO: string[] = [
  $localize`:@@NO_STAGES_IN_PROJECT:There aren't any stages in this project yet.`,
  $localize`:@@ADD_STAGE_TO_START:Add stage to start.`
];

const translation = {
  importProcoreModalTitle: $localize`:@@IMPORT_PROCORE_MODAL_TITLE:Import Procore data?`,
  importProcoreModalMessage: $localize`:@@IMPORT_PROCORE_MODAL_MESSAGE:The project that has been connected has data that can be imported. The data will be automatically distributed by date to the appropriate stages. This can be done now or later from the context menu on this tab`,
  importProcoreDataFailed: $localize`:@@IMPORT_PROCORE_DATA_FAILED:Import procore data failed`
};

@Component({
  standalone: true,
  selector: 'feature-stages-panel-left',
  templateUrl: './panel-left.component.html',
  styleUrls: ['./panel-left.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [OrganizationLimitsFacade],
  imports: [
    CommonModule,
    DesignIcon,
    DesignFlatButton,
    DesignCommonModule,
    DesignIconButton,
    UiProgressSpinnerModule,
    UiImageInfoModule,
    UiIconModule,
    StageListComponent,
    StageListItemComponent,

    DesignFlatButtonModule
  ]
})
export class PanelLeftComponent implements OnDestroy, OnInit {
  private readonly _api = inject(ApiProcoreService);
  private readonly _annotationFacade = inject(AnnotationsFacade);
  private readonly _toastService = inject(ToastService);

  private readonly _destroySource = new Subject<void>();
  @Output() closed = new EventEmitter();

  readonly openLeftPanel = output();

  readonly descriptionImageInfo = DESCRIPTION_IMAGE_INFO;

  readonly stages$: Observable<StageWithCount[]> = this.stagesFacade.allStages$;

  readonly stagesLoaded$: Observable<boolean> = this.stagesFacade.stagesLoaded$;

  readonly stagesLoading$: Observable<boolean> =
    this.stagesFacade.stagesLoading$;

  readonly selectedId$: Observable<string> = this.stagesFacade.selectedId$;

  private readonly _ownerSource$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly owner$: Observable<boolean> = this._ownerSource$.asObservable();

  @Input()
  set owner(value: boolean) {
    this._ownerSource$.next(value);
  }

  readonly addEditDeleteStages$ = this.owner$.pipe(
    switchMap((owner: boolean) => {
      if (owner) return this.privilegesFacade.addEditDeleteStagesInOwnProjects$;
      return this.privilegesFacade.AddEditDeleteStagesInOtherUsersProjects$;
    })
  );

  private _projectId!: string;
  public get projectId(): string {
    return this._projectId;
  }
  public set projectId(value: string) {
    this._projectId = value;
  }

  readonly projectName$: Observable<string> = defer(() =>
    this.projectFacade
      .selectProjectById$(this.projectId)
      .pipe(map((data) => data?.name || ''))
  );

  readonly projectIsActive$: Observable<boolean> =
    this.projectLimitsService.projectIsActive$;

  readonly checkOrganizationLimits$ = defer(() =>
    this.organizationLimitsFacade.canAddStage(this.projectId).pipe(
      mergeMap((privilege) =>
        iif(
          () => privilege,
          of(true).pipe(
            switchMap(() =>
              this._createStageFormDialog<StageForm>().events$.pipe(
                take(1),
                tap((response: DialogResponse<StageForm>) => {
                  if (response.state) {
                    const stageForm = {
                      projectId: this.projectId,
                      name: response.result?.name,
                      stageDate: response.result?.stageDate as string
                    } as SimplifiedStage;
                    this.stagesFacade.addStage(stageForm);
                  }
                })
              )
            )
          ),
          of(false).pipe(
            switchMap(() =>
              this.organizationLimitsFacade
                .getOrganizationId(this.projectId)
                .pipe(
                  switchMap((organizationId: string) =>
                    from(this._noAccessLimitDialog(organizationId)).pipe(
                      switchMap((component) => component.events$)
                    )
                  )
                )
            )
          )
        )
      )
    )
  );

  readonly openAddStageModal$ = defer(() =>
    this.projectLimitsService.checkViewOnly$.pipe(
      switchMap((isActivate: boolean) => {
        if (isActivate) {
          return this.checkOrganizationLimits$;
        }
        return of(false);
      })
    )
  );

  private readonly _dialog = inject(Dialog);

  constructor(
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly notesFacade: NotesFacade,
    private readonly stagesFacade: StagesFacade,
    private readonly componentFacade: ComponentsFacade,

    private readonly positionCameraService: PositionCameraService,

    private readonly modalService: ModalService,
    private readonly projectFacade: ProjectsFacade,
    private readonly apiFacade: ApiFacadeService,
    private readonly privilegesFacade: ProjectPrivilegesFacade,
    private readonly platformLocation: PlatformLocation,
    private readonly filterService: StageFiltersService,
    private readonly stagesRootService: StagesRootService,
    private readonly projectLimitsService: ProjectLimitsService,
    private readonly organizationLimitsFacade: OrganizationLimitsFacade
  ) {
    this.platformLocation.onPopState(() => {
      this.stagesFacade.getRouteNestedParams$
        .pipe(
          skip(1),
          take(1),
          tap((w) =>
            this.stagesFacade.setSelectedStageId(w.queryParams['stageId'])
          )
        )
        .subscribe();
    });
  }
  ngOnInit(): void {
    this.stagesFacade.getRouteNestedParams$
      .pipe(
        tap((routerParams: RouterStoreParams) => {
          this.projectId = routerParams.params['projectId'];
        }),
        take(1),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  handleSelected(stageId: string): void {
    this.notesFacade.selectedNoteId(undefined);
    this.stagesRootService.rightPanelState = 'close';
    this.stagesFacade.setSelectedStageId(
      stageId,
      this.positionCameraService.cameraPose?.position
    );

    const hamburgerMenu = this.document.getElementById('hamburger-button');
    if (hamburgerMenu?.offsetHeight && hamburgerMenu?.offsetHeight > 0) {
      this.stagesRootService.leftPanelState = 'close';
    }
  }

  navigateToDocuments(): void {
    this.router.navigate(['/project', this._projectId, 'documents']);
  }

  trackById(index: number, item: StageWithCount): string {
    return item.id;
  }

  private _createStageFormDialog<T>(): ConfirmationModalRef<T> {
    return this.modalService.createModalWithProviders(
      StageFormDialogComponent,
      {
        width: 'min(600px,100vw)',
        maxWidth: 'min(90%, 500px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: {
            title: ADD_STAGE
          }
        }
      ]
    );
  }

  openFilterModal(): void {
    this.openLeftPanel.emit();
  }

  openProjectInfo(): void {
    if (!this._projectId) return;

    const projectId = this._projectId;
    this.projectFacade.loaded$
      .pipe(
        switchMap((data: boolean) =>
          iif(
            () => data,
            this.projectFacade.selectProjectById$(projectId).pipe(take(1)),
            this.apiFacade.projects.getProject({ id: projectId }).pipe(take(1))
          )
        ),
        take(1),
        switchMap((project: Project | undefined) =>
          from(this._openProjectInfoDialog(project)).pipe(
            switchMap((component) =>
              component.events$.pipe(
                take(1),
                switchMap(({ state, result }) => {
                  if (state && result?.action === 'connectProcore') {
                    return this._openProcoreConnectDialog(
                      result.data
                    ).closed.pipe(
                      take(1),
                      tap((result) => {
                        if (result?.action === 'BackTo') {
                          this.openProjectInfo();
                        }
                      }),
                      filter((data) => !!data && data.state),
                      mergeMap(() =>
                        this._checkIfIsAnythingToImportFromProcore$(
                          result.data.id
                        ).pipe(map(() => result))
                      )
                    );
                  } else if (state && result?.action === 'detailConnection') {
                    return this._openProcoreConnectDetailsDialog(
                      result.data
                    ).closed.pipe(
                      tap((result) => {
                        if (result === 'BackTo') {
                          this.openProjectInfo();
                        }
                      })
                    );
                  } else if (state && result?.action === 'refreshData') {
                    return this._resetStagesStateIfImportIsCompleted$(
                      projectId
                    );
                  }
                  return EMPTY;
                }),
                switchMap((e) => {
                  if (e === 'BackTo') {
                    throw Error('Open again info modal');
                  }
                  return EMPTY;
                })
              )
            )
          )
        ),
        retry(),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  private async _openProjectInfoDialog(
    project: Project | undefined
  ): Promise<
    ConfirmationModalRef<
      import('@simlab/feature/projects').ProjectInfoDialogResponse
    >
  > {
    const component = (await import('@simlab/feature/projects'))
      .ProjectInfoDialogComponent;
    return this.modalService.createModalWithProviders(
      component,
      {
        maxWidth: 'min(90%, 850px)',
        minWidth: 'min(90%, 850px)',
        maxHeight: '90%'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: project
        }
      ]
    );
  }

  private _openProcoreConnectDetailsDialog({
    companyName,
    name,
    procoreInfo
  }: TProjectInfoBase) {
    const stagesProject: ProjectCard = {
      logoUrl: 'assets/icons/logo_text.svg',
      companyName: companyName,
      projectName: name,
      status: 'CONNECTED'
    };
    const procoreProject: ProjectCard = {
      logoIcon: 'icon_procore_logo',
      companyName: procoreInfo?.companyName ?? 'unknown',
      projectName: procoreInfo?.projectName ?? 'unknown',
      status: 'CONNECTED'
    };

    const data: TLinkedProjectsInformationData = {
      status: 'CONNECTED',
      projects: [stagesProject, procoreProject]
    };

    return this._dialog.open<
      TActionNameOnCloseModal,
      TLinkedProjectsInformationData,
      LinkedProjectsInformationModalComponent
    >(LinkedProjectsInformationModalComponent, {
      width: 'min(90%, 850px)',
      maxHeight: 'min(90%, 850px)',
      panelClass: 'cdk-modal-overflow',
      autoFocus: false,
      data
    });
  }

  private _openProcoreConnectDialog(data: {
    id: string;
    name: string;
    companyName: string;
  }) {
    return this._dialog.open<
      TActionOnCloseModal,
      TProcoreProjectIntegration,
      ProjectIntegrationComponent
    >(ProjectIntegrationComponent, {
      width: 'min(90%, 850px)',
      maxHeight: 'min(90%, 850px)',
      panelClass: 'cdk-modal-overflow',
      autoFocus: false,
      disableClose: true,
      data
    });
  }

  private async _noAccessLimitDialog(
    organizationId: string
  ): Promise<
    ConfirmationModalRef<
      import('@simlab/feature/projects').NoAccessDialogComponent
    >
  > {
    const component = (await import('@simlab/feature/projects'))
      .NoAccessDialogComponent;
    return this.modalService.createModalWithProviders(
      component,
      {
        width: '100%',
        maxWidth: 'min(90%, 380px)',
        maxHeight: 'min(90%, 1000px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: {
            organizationId,
            text: LIMIT_OF_STAGES_INFO
          }
        }
      ]
    );
  }

  private _checkIfIsAnythingToImportFromProcore$(projectId: string) {
    return this._api.isAnythingToImportFromProcore(projectId).pipe(
      filter((isAnythingToImport) => !!isAnythingToImport),
      mergeMap(() => this._importProcoreData()),
      filter((isImportConfirm) => !!isImportConfirm),
      mergeMap(() => this._api.importProcoreData(projectId)),
      mergeMap(() => this._resetStagesStateIfImportIsCompleted$(projectId))
    );
  }

  private _importProcoreData() {
    return from(import('@simlab/modal/ui')).pipe(
      exhaustMap(
        ({ ModalComponent }) =>
          this._dialog.open<boolean, ModalData, ModalComponent>(
            ModalComponent,
            {
              data: {
                type: 'inform',
                title: translation.importProcoreModalTitle,
                message: translation.importProcoreModalMessage
              },
              width: 'min(90%, 610px)',
              disableClose: true
            }
          ).closed
      )
    );
  }

  private _resetStagesStateIfImportIsCompleted$(projectId: string) {
    const refreshDelay = 1000;

    const { InProgress, Completed, Failed } = ProcoreDataProgressStatus;

    return this._api.getImportProcoreDataProgress(projectId).pipe(
      tap(({ status }) => {
        if (status === InProgress) throw Error('Import in progress, try again');
      }),
      retry({ delay: refreshDelay }),
      tap(({ status }) => {
        if (status === Completed) {
          this.stagesFacade.initStore(this._projectId);
          this._annotationFacade.resetState();
          return;
        }

        if (status === Failed) {
          console.error('Import procore data failed');
          this._toastService.open(translation.importProcoreDataFailed, 'Error');
          return;
        }
      }),
      take(1)
    );
  }
}
