import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgStyle } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  inject,
  signal,
  viewChild,
  viewChildren,
  ViewEncapsulation
} from '@angular/core';
import {
  outputToObservable,
  takeUntilDestroyed,
  toObservable,
  toSignal
} from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbComponent, DtoNameId } from '@simlab/design/breadcrumb';
import {
  DesignFlatButton,
  DesignIconButton,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignIcon } from '@simlab/design/icon';
import { breakpointsObserver } from '@simlab/design/layout';
import { UiTooltip } from '@simlab/design/tooltip';
import {
  CUSTOM_MOVE_TO_ACTION_ROOT_FOLDER,
  documentsApiToken,
  FolderAsPathItem
} from '@simlab/documents/data-access';
import { SubdirectoryModel } from '@simlab/documents/models';
import { filter, fromEvent, map, merge, startWith, switchMap, tap } from 'rxjs';
import { DocumentMoveModalItemComponent } from './document-move-modal-item/document-move-modal-item.component';

export type DocumentMoveModalComponentData = {
  title: string;
  btnLabel: string;
  projectId: string;
  isFilterMode: boolean;
  moveFoldersIds?: string[];
};

export type TDocumentMoveResponse = {
  selectedFolder: FolderAsPathItem;
  path: DtoNameId[];
};

@Component({
  selector: 'feature-projects-document-move-modal',
  standalone: true,
  imports: [
    NgStyle,
    DesignDialogWrapperModule,
    DocumentMoveModalItemComponent,
    DesignStrokedButton,
    DesignFlatButton,
    DesignIconButton,
    BreadcrumbComponent,
    UiTooltip,
    DesignIcon
  ],
  templateUrl: './document-move-modal.component.html',
  styleUrl: './document-move-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'feature-projects-document-move-modal'
  }
})
export class DocumentMoveModalComponent implements AfterViewInit {
  protected readonly rootFolder = inject<FolderAsPathItem[]>(
    CUSTOM_MOVE_TO_ACTION_ROOT_FOLDER
  );
  private readonly _api = inject(documentsApiToken);
  private readonly _dialogRef = inject(DialogRef);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _breakpoints = inject(breakpointsObserver);
  private readonly _activatedRoute = inject(ActivatedRoute);

  private readonly _foldersView = viewChildren(DocumentMoveModalItemComponent);
  private readonly _foldersView$ = toObservable(this._foldersView);

  private readonly _breadcrumbView = viewChild(
    BreadcrumbComponent<FolderAsPathItem>
  );
  private readonly _breadcrumbView$ = toObservable(this._breadcrumbView);

  private readonly _backBtnView = viewChild('backBtn', {
    read: ElementRef<HTMLButtonElement>
  });
  private readonly _backBtnView$ = toObservable(this._backBtnView);

  private readonly _foldersSelectionEvent$ = this._foldersView$.pipe(
    switchMap((items) =>
      merge(...items.map((item) => outputToObservable(item.selection)))
    )
  );

  private readonly _backBtnNavigateEvent$ = this._backBtnView$.pipe(
    filter(
      (backBtnView): backBtnView is ElementRef<HTMLButtonElement> =>
        backBtnView !== undefined
    ),
    switchMap((backBtnView) =>
      fromEvent(backBtnView.nativeElement, 'click').pipe(
        map(() => {
          const prevFolderIndex = this.path().length - 2;
          return this.path()[prevFolderIndex];
        }),
        tap((item) => this._splicePathProcedure(item))
      )
    )
  );

  private readonly _breadcrumbCustomNavigateEvent$ = this._breadcrumbView$.pipe(
    filter(
      (
        breadcrumbView
      ): breadcrumbView is BreadcrumbComponent<FolderAsPathItem> =>
        breadcrumbView !== undefined
    ),
    switchMap(({ customNavigate }) => outputToObservable(customNavigate)),
    tap((item) => this._splicePathProcedure(item))
  );

  private readonly _foldersOpenEvent$ = this._foldersView$.pipe(
    switchMap((items) =>
      merge(
        ...items.map((item) =>
          outputToObservable(item.open).pipe(map((item) => item.data()))
        )
      )
    ),
    tap((openedFolder) => {
      if (openedFolder === null) return;

      this.path.update((item) => [...item, openedFolder]);
    })
  );

  protected readonly dialogData =
    inject<DocumentMoveModalComponentData>(DIALOG_DATA);
  protected readonly isMobile = this._breakpoints.phone;

  protected readonly path = signal<FolderAsPathItem[]>([...this.rootFolder]);
  protected readonly selectedFolder = computed(() => {
    const path = this.path();
    const selectedFolder = this._foldersView().find((folder) =>
      folder.isSelected()
    );

    return selectedFolder?.data() ?? path[path.length - 1];
  });
  protected readonly folders = signal<SubdirectoryModel[]>([]);

  protected readonly foldersWithDisabledStatus = computed(() => {
    const folders = this.folders();
    const moveFoldersIds = this.dialogData.moveFoldersIds;
    return folders.map((folder) => ({
      folder,
      disabled: moveFoldersIds?.includes(folder.id)
    }));
  });

  protected readonly currentLocationFolderId = toSignal<
    undefined | null | string
  >(
    this._activatedRoute.queryParams.pipe(
      map((params) =>
        //  currentLocationFolderId is undefined, because in filterMode folderId is not specified
        this.dialogData.isFilterMode
          ? undefined
          : (params['folderId'] ?? this.rootFolder[0].id)
      )
    )
  );

  protected readonly isCurrentMoveFolder = computed(() => {
    const selectedFolderId = this.selectedFolder().id;
    const moveFoldersIds = this.dialogData.moveFoldersIds;

    if (moveFoldersIds === undefined || selectedFolderId === null) return false;

    return moveFoldersIds.includes(selectedFolderId);
  });

  ngAfterViewInit(): void {
    this._foldersSelectionEvent$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((folder) => {
        this._foldersView().forEach(({ isSelected }) => isSelected.set(false));
        folder.isSelected.set(true);
      });

    merge(
      this._foldersOpenEvent$,
      this._breadcrumbCustomNavigateEvent$,
      this._backBtnNavigateEvent$
    )
      .pipe(
        // null means root directory
        startWith(null),
        switchMap((folder) =>
          this._api.getSubdirectories$(
            this.dialogData.projectId,
            folder?.id ?? ''
          )
        ),
        tap((data) => this.folders.set(data)),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }

  onSelect() {
    this._dialogRef.close({
      selectedFolder: this.selectedFolder(),
      path: this.path()
    });
  }

  onCancel() {
    this._dialogRef.close(undefined);
  }

  private _splicePathProcedure(item: FolderAsPathItem) {
    const selectedFolderIndex = this.path().indexOf(item);
    if (selectedFolderIndex === -1) return;

    this.path.update((value) => {
      value.splice(selectedFolderIndex + 1);
      return [...value];
    });
  }
}
