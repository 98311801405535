import { Injectable } from '@angular/core';
import {
  ApiFacadeService,
  Comment,
  Note,
  OrganizationProjectUserBase,
} from '@simlab/data-access';
import {
  NEW_NOTE_LOCALLY_ID,
  NotesFacade,
  StagesFacade,
} from '@simlab/data-store';
import { MentionData } from '@simlab/design/mention';
import { RouterStoreParams } from '@simlab/util-shared';
import {
  BehaviorSubject,
  Observable,
  Subject,
  defer,
  filter,
  map,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';

@Injectable()
export class CommentsService {
  private readonly _commentListChange: BehaviorSubject<void> =
    new BehaviorSubject<void>(undefined);
  private readonly _commentList$: Observable<Comment[]> = defer(() =>
    this._commentListChange.pipe(
      switchMap(() =>
        this.noteFacade.selectedNoteId$.pipe(
          filter((rootNoteId: string) => rootNoteId !== undefined),
          switchMap((rootNoteId: string) => {
            if (rootNoteId === NEW_NOTE_LOCALLY_ID) {
              return of([]);
            }

            this.resetComments$.next(rootNoteId);

            return this.apiFacadeService.rootNoteComments.getCommentsForRootNote(
              {
                rootNoteId,
              }
            );
          })
        )
      )
    )
  );
  readonly resetComments$: Subject<string> = new Subject<string>();
  readonly mentionData$: Observable<MentionData[]> =
    this.stagesFacade.getRouteNestedParams$.pipe(
      take(1),
      switchMap((routerParams: RouterStoreParams) =>
        this.apiFacadeService.project
          .getOrganizationProjectUsersByUsername$({
            projectId: routerParams.params['projectId'],
            userName: '',
          })
          .pipe(
            map((collaborators: OrganizationProjectUserBase[]) =>
              collaborators.map(
                (collaborator: OrganizationProjectUserBase) =>
                  ({
                    displayName: collaborator.userName,
                    value: collaborator.userId,
                  } as MentionData)
              )
            )
          )
      ),
      shareReplay(1)
    );

  constructor(
    private readonly apiFacadeService: ApiFacadeService,
    private readonly noteFacade: NotesFacade,
    private readonly stagesFacade: StagesFacade
  ) {}

  public init() {
    this._commentListChange.next();
  }

  readonly commentList$: Observable<Comment[]> = this._commentList$;

  addComment$(text: string): Observable<{ commandResponseData: string }> {
    return this.noteFacade.selectedNoteId$.pipe(
      switchMap((rootNoteId: string) => {
        if (rootNoteId === NEW_NOTE_LOCALLY_ID) {
          this.noteFacade.saveLocalNote();
          return this.noteFacade.noteAdded$.pipe(
            map((e: Note) => {
              return e.id;
            })
          );
        }
        return of(rootNoteId);
      }),
      switchMap((rootNoteId: string) => {
        return this.apiFacadeService.rootNoteComments.addCommentToRootNote({
          rootNoteId,
          text,
        });
      }),
      tap(() => this._commentListChange.next())
    );
  }

  removeComment$(rootNoteCommentId: string): Observable<void> {
    return this.noteFacade.selectedNoteId$.pipe(
      switchMap((rootNoteId: string) => {
        return this.apiFacadeService.rootNoteComments.removeCommentFromRootNote(
          {
            rootNoteId,
            rootNoteCommentId,
          }
        );
      }),
      tap(() => this._commentListChange.next())
    );
  }

  replayToComment$(id: string, text: string): Observable<void> {
    return this.apiFacadeService.rootNoteComments
      .replyToComment({
        inReplyToRootNoteCommentId: id,
        text,
      })
      .pipe(tap(() => this._commentListChange.next()));
  }

  editComment$(rootNoteCommentId: string, text: string): Observable<void> {
    return this.apiFacadeService.rootNoteComments
      .editComment({ rootNoteCommentId, text })
      .pipe(tap(() => this._commentListChange.next()));
  }
}
