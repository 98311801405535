import { InjectionToken } from '@angular/core';
import { fromEvent, map, Observable } from 'rxjs';

export const AUDIO_SCRIPT_DIR = new InjectionToken<string>('AUDIO_SCRIPT_DIR');

export const AUDIO_SCRIPT = new InjectionToken<Observable<boolean>>(
  'AUDIO_SCRIPT'
);

export function loadAudioScript(document: Document): Observable<boolean> {
  const script = document.createElement('script');
  script.src = `assets/web-audio-recorder/WebAudioRecorder.js`;

  document.body.appendChild(script);
  return fromEvent(script, 'load').pipe(map(() => true));
}
