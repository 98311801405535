import {
  Directive,
  EventEmitter,
  Output,
  Signal,
  WritableSignal,
  inject,
  input,
  output,
  signal
} from '@angular/core';
import { IVector3 } from '@simlab/data-access';
import { ISidenavContent } from '@simlab/ui/sidenav';
import { Observable, ReplaySubject, Subject, filter } from 'rxjs';
import { Vector3 } from 'three';
import {
  NoteMeasurement,
  TypeMeasurement
} from '../../models/src/common-models';
import { SystemOfMeasurement } from '../../models/src/system-measurement';
import { TYPE_MEASUREMENT } from '../../tokens/token';

@Directive({ standalone: true })
export class MeasurementSidenavContent<T> implements ISidenavContent {
  private readonly _typeMeasurement = inject(TYPE_MEASUREMENT);
  private readonly _closePanelAction = new Subject<void>();
  readonly cancel = new Subject<void>();
  readonly closeAction = new Subject<TypeMeasurement | null>();

  readonly closePanelAction$: Observable<void> = this._closePanelAction;
  readonly editData: Subject<Required<T>> = new Subject<Required<T>>();
  getSelf = () => this;
  onSidenavOpen?: (() => void) | undefined;

  readonly stageId = input.required<string>();
  readonly noteId = input.required<string | undefined>();
  readonly notes = input.required<NoteMeasurement[]>();
  readonly successCreateNote$ = input<Observable<string>>();
  readonly systemOfMeasurement = input<SystemOfMeasurement>();

  readonly opened = input.required<boolean>();
  readonly onClose = output();
  readonly onCloseWithType = output<TypeMeasurement>();
  readonly onCreateNote = output<{ name: string; stageId: string }>();
  readonly onOpenRelatedNote = output<string>();

  @Output() openPanelAction$: EventEmitter<void> = new EventEmitter<void>();

  protected readonly _openEditor: WritableSignal<boolean> = signal(false);
  readonly openEditor: Signal<boolean> = this._openEditor;

  protected readonly _goTo = new Subject<IVector3>();
  readonly goTo = this._goTo.asObservable();

  protected readonly _addAction = new ReplaySubject<TypeMeasurement>(1);
  readonly addAction = this._addAction.asObservable();

  onCloseAction(value: TypeMeasurement | null) {
    this.closeAction.next(value);
    this._closePanelAction.next();
  }

  get editorMeasurementOpened() {
    return this.openEditor();
  }

  get measurementType() {
    return this._typeMeasurement;
  }

  private readonly _editAction: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);

  readonly editAction = this._editAction.asObservable().pipe(filter((e) => e));

  goToMeasurement(value: Vector3) {
    this._goTo.next(value);
  }

  openPanelAction() {
    this.openPanelAction$.next();
  }

  addMeasurement() {
    this._addAction.next(this._typeMeasurement);
    this._openEditor.set(true);
  }

  editMeasurement() {
    this._openEditor.set(true);
    this._editAction.next(true);
  }

  onGoTo(value: IVector3) {
    this._goTo.next(value);
  }
}
