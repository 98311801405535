import { inject, Pipe, PipeTransform } from '@angular/core';
import {
  DocumentTypeEnum,
  DocumentTypeEnumValues
} from '@simlab/documents/models';
import { ICON_TYPE } from '@simlab/ui/icon';
import { ThemeService } from '@simlab/ui/theme';
import { map, mergeMap, Observable, of, shareReplay } from 'rxjs';

const fileTypeExtensions = [
  DocumentTypeEnum['Image'],
  DocumentTypeEnum['Video'],
  DocumentTypeEnum['Audio']
] as const;

type FileTypeExtensions = (typeof fileTypeExtensions)[number];

type Extensions = string | FileTypeExtensions;

type IconByTheme = {
  readonly dark: ICON_TYPE;
  readonly light: ICON_TYPE;
};

const defaultIcon: IconByTheme = {
  dark: 'thumbnail_file_dark',
  light: 'thumbnail_file'
} as const;

const iconsStore: Record<Extensions, IconByTheme> = {
  pdf: { light: 'thumbnail_pdf', dark: 'thumbnail_pdf_dark' },
  svg: { light: 'thumbnail_svg', dark: 'thumbnail_svg_dark' },
  ogv: { light: 'thumbnail_ogv', dark: 'thumbnail_ogv_dark' },
  doc: { light: 'thumbnail_doc', dark: 'thumbnail_doc_dark' },
  jt: { light: 'thumbnail_jt', dark: 'thumbnail_jt_dark' },
  par: { light: 'thumbnail_par', dark: 'thumbnail_par_dark' },
  xls: { light: 'thumbnail_xls', dark: 'thumbnail_xls_dark' },
  asm: { light: 'thumbnail_asm', dark: 'thumbnail_asm_dark' },
  e57: { light: 'thumbnail_e57', dark: 'thumbnail_e57_dark' },
  txt: { light: 'thumbnail_txt', dark: 'thumbnail_txt_dark' },
  stp: { light: 'thumbnail_stp', dark: 'thumbnail_stp_dark' },
  ipt: { light: 'thumbnail_ipt', dark: 'thumbnail_ipt_dark' },
  psm: { light: 'thumbnail_psm', dark: 'thumbnail_psm_dark' },
  cgr: { light: 'thumbnail_cgr', dark: 'thumbnail_cgr_dark' },
  ppt: { light: 'thumbnail_ppt', dark: 'thumbnail_ppt_dark' },
  zip: { light: 'thumbnail_zip', dark: 'thumbnail_zip_dark' },
  obj: { light: 'thumbnail_obj', dark: 'thumbnail_obj_dark' },
  iam: { light: 'thumbnail_iam', dark: 'thumbnail_iam_dark' },
  ifc: { light: 'thumbnail_ifc', dark: 'thumbnail_ifc_dark' },
  prt: { light: 'thumbnail_prt', dark: 'thumbnail_prt_dark' },
  fbx: { light: 'thumbnail_fbx', dark: 'thumbnail_fbx_dark' },
  dgn: { light: 'thumbnail_dgn', dark: 'thumbnail_dgn_dark' },
  bmp: { light: 'thumbnail_bmp', dark: 'thumbnail_bmp_dark' },
  ods: { light: 'thumbnail_ods', dark: 'thumbnail_ods_dark' },
  jpg: { light: 'thumbnail_image', dark: 'thumbnail_image_dark' },
  jpeg: { light: 'thumbnail_image', dark: 'thumbnail_image_dark' },
  png: { light: 'thumbnail_image', dark: 'thumbnail_image_dark' },
  [DocumentTypeEnum.Image]: {
    light: 'thumbnail_image',
    dark: 'thumbnail_image_dark'
  },
  [DocumentTypeEnum.Video]: {
    light: 'thumbnail_video',
    dark: 'thumbnail_video_dark'
  },
  [DocumentTypeEnum.Audio]: {
    light: 'thumbnail_audio',
    dark: 'thumbnail_audio_dark'
  }
} as const;

@Pipe({
  name: 'documentsThumbnailIconGetter',
  standalone: true
})
export class DocumentsThumbnailIconGetterPipe implements PipeTransform {
  private readonly _themeService = inject(ThemeService);
  private readonly _theme$ = this._themeService.update$.pipe(shareReplay());
  private readonly _defaultIcon$ = this._theme$.pipe(
    map((theme) => (theme === 'light' ? defaultIcon.light : defaultIcon.dark))
  );

  transform(
    fileName: string,
    fileType: DocumentTypeEnumValues
  ): Observable<ICON_TYPE> {
    const extension = fileName.split('.').pop();
    if (extension === undefined) return this._defaultIcon$;

    let icon: IconByTheme = iconsStore[extension];

    if (icon === undefined) {
      if ((fileTypeExtensions as unknown as number[]).includes(fileType)) {
        icon = iconsStore[fileType];
      } else {
        return this._defaultIcon$;
      }
    }

    return this._theme$.pipe(
      mergeMap((theme) => of(theme === 'light' ? icon.light : icon.dark))
    );
  }
}
