import { Injectable, inject } from '@angular/core';
import { UserPreferenceFacade } from '@simlab/data-store';

import { Observable, filter, take, tap } from 'rxjs';

@Injectable()
export class UserPreferencesResolver {
  private readonly _userPreferenceFacade = inject(UserPreferenceFacade);

  resolve(): Observable<boolean> {
    return this._userPreferenceFacade.loaded$.pipe(
      tap((loaded) => {
        if (loaded) return;
        this._userPreferenceFacade.initStore();
      }),
      filter((state: boolean) => state),
      take(1)
    );
  }
}
