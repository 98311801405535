import { Directive, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ProjectPermission } from '@simlab/data-access';
import { ProjectPrivilegesFacade } from '@simlab/data-store';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Directive()
export abstract class MeasurementPermission {
  private readonly _loggedUserInfo$ = inject(OidcSecurityService).getUserData();
  private readonly _privilegesFacade = inject(ProjectPrivilegesFacade);

  readonly canAddEditDeleteOwnNotes = toSignal(
    this._privilegesFacade.hasPrivilegesTo$(
      ProjectPermission.AddEditDeleteOwnNotes
    )
  );
  readonly canEditDeleteToOtherUserElements = toSignal(
    this._privilegesFacade.hasPrivilegesTo$(
      ProjectPermission.EditDeleteOtherUserElements
    )
  );

  readonly canAddEditDeleteOwnMeasurement = toSignal(
    this._privilegesFacade.hasPrivilegesTo$(
      ProjectPermission.AddEditDeleteOwnMeasurements
    )
  );
  readonly canEditDeleteOtherUserMeasurement = toSignal(
    this._privilegesFacade.hasPrivilegesTo$(
      ProjectPermission.EditDeleteOtherUsersMeasurements
    )
  );
  readonly canAddEditDeleteOwnMeasurementGroup = toSignal(
    this._privilegesFacade.hasPrivilegesTo$(
      ProjectPermission.AddEditDeleteOwnMeasurementGroups
    )
  );
  readonly canEditDeleteOtherUserMeasurementGroup = toSignal(
    this._privilegesFacade.hasPrivilegesTo$(
      ProjectPermission.EditDeleteOtherUsersMeasurementGroups
    )
  );
  readonly loggedUserInfo = toSignal(this._loggedUserInfo$);

  canAttachDetachToNote = (creatorId: string) => {
    const isOwner = this.isOwner(creatorId);
    return isOwner
      ? this.canAddEditDeleteOwnNotes() || false
      : this.canEditDeleteToOtherUserElements() || false;
  };
  canAddMeasurement = () => {
    return this.canAddEditDeleteOwnMeasurement() || false;
  };
  canEditDeleteMeasurement = (creatorId: string) => {
    const isOwner = this.isOwner(creatorId);
    return isOwner
      ? this.canAddEditDeleteOwnMeasurement() || false
      : this.canEditDeleteOtherUserMeasurement() || false;
  };
  canAddMeasurementGroup = () => {
    return this.canAddEditDeleteOwnMeasurementGroup();
  };
  canEditDeleteMeasurementGroup = (creatorId: string) => {
    const isOwner = this.isOwner(creatorId);
    return isOwner
      ? this.canAddEditDeleteOwnMeasurementGroup() || false
      : this.canEditDeleteOtherUserMeasurementGroup() || false;
  };
  allMeasurementGroupPrivilegesNotAllowed = () =>
    !this.canAddEditDeleteOwnMeasurementGroup() &&
    !this.canEditDeleteOtherUserMeasurementGroup();
  isOwner(creatorId: string) {
    const { sub } = this.loggedUserInfo();
    return creatorId === sub;
  }
}
