import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApiFacadeService,
  ModelAccess,
  StageComponent
} from '@simlab/data-access';
import {
  ComponentsFacade,
  StageWithCount,
  StagesFacade
} from '@simlab/data-store';
import { MatterportApiFacadeService, ModelSweep } from '@simlab/matterport/api';
import {
  DialogResponse,
  MODAL_DATA,
  ModalService,
  WarningConfirmationModalComponent,
  WarningModalComponent
} from '@simlab/ui/modal';
import { ShowcaseHelper, ToastEventService } from '@simlab/util-shared';
import {
  Observable,
  Subject,
  catchError,
  exhaustMap,
  firstValueFrom,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import { AddComponentDialogComponent } from '../components/add-component-dialog/add-component-dialog.component';
import { SynchronizeConfirmationDialogComponent } from '../components/synchronize-confirmation-dialog/synchronize-confirmation-dialog.component';
import { StagesRootService } from './stages-root.service';

const INFO_SYNCHRONIZATION = $localize`:@@INFO_SYNCHRONIZATION:Do you want to start synchronization?`;
const PU_SURE_WANT_DELETE_COMPONENT = $localize`:@@PU_SURE_WANT_DELETE_COMPONENT:Are you sure you want to delete this component?`;
const DELETE = $localize`:@@DELETE:Delete`;
const PU_SURE_WANT_DELETE_SYNCHRONIZATION = $localize`:@@PU_SURE_WANT_DELETE_SYNCHRONIZATION:Are you sure you want to delete this synchronization?`;
@Injectable()
export class ComponentListService implements OnDestroy {
  private readonly _destroySource: Subject<void> = new Subject<void>();

  constructor(
    private readonly matterportApiService: MatterportApiFacadeService,
    private readonly apiFacadeService: ApiFacadeService,
    private readonly stage: StagesFacade,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly stagesRootService: StagesRootService,
    private readonly modalService: ModalService,
    private readonly matterport: MatterportApiFacadeService,
    private readonly componentsFacade: ComponentsFacade,
    private readonly toastEventService: ToastEventService
  ) {}

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  renameComponent(newName: string, component: StageComponent): void {
    this.componentsFacade.editComponentName(component.id, newName);
  }

  addComponent(): Observable<StageComponent | undefined> {
    const modalRef = this.modalService.createModalWithProviders<
      AddComponentDialogComponent,
      {
        name: string;
        componentUrl: string;
        modeSelect: string;
      }
    >(AddComponentDialogComponent, {
      centered: true,
      maxWidth: 'min(100%, 512px)',
      minWidth: 'min(100%, 512px)'
    });

    return modalRef.events$.pipe(
      mergeMap(
        (
          response: DialogResponse<{
            name: string;
            componentUrl: string;
            modeSelect: string;
          }>
        ) => {
          if (!response.state || !response.result)
            return of({} as StageComponent);
          return this._addComponentToStage({
            type: 'matterport',
            name: <string>response.result?.name,
            componentUrl: <string>response.result?.componentUrl,
            mode: <string>response.result?.modeSelect
          });
        }
      ),
      take(1)
    );
  }

  warningDialog(contentText: string, title?: string) {
    return this.modalService.createModalWithProviders(
      WarningModalComponent,
      {
        centered: true,
        width: 'min(90%, 380px)',
        offset: {
          x: 0,
          y: 0
        }
      },
      [
        {
          provide: MODAL_DATA,
          useValue: { mainText: title, content: contentText }
        }
      ]
    );
  }

  private _addComponentToStage(data: {
    name: string;
    type: string;
    componentUrl: string;
    mode: string;
  }): Observable<StageComponent | undefined> {
    const scanId = ShowcaseHelper.findShowcaseId(data.componentUrl);

    if (data.mode === 'public') {
      return this.apiFacadeService.matterport
        .getMatterportModelAccess(scanId)
        .pipe(
          switchMap((modelAccess: ModelAccess) => {
            if (modelAccess !== ModelAccess.Public) {
              return this.warningDialog(
                `This happened because of one of the following:
 - you entered a private link in the public link field
- the scan to which the link leads is password protected (it is a private scan)
- the link is corrupted
- the scan does not exist`,
                'The link you provided was rejected.'
              ).events$.pipe(map(() => undefined));
            }
            return this._processToAddComponentToStage(scanId, data);
          })
        );
    }
    return this._processToAddComponentToStage(scanId, data);
  }

  private _processToAddComponentToStage(
    scanId: string,
    data: {
      name: string;
      type: string;
      componentUrl: string;
      mode: string;
    }
  ) {
    return this.matterport.getModelSweeps$(scanId).pipe(
      exhaustMap((modelSweeps: ModelSweep[]) =>
        this.stage.selectedStages$.pipe(
          tap((stage: StageWithCount | undefined) => {
            const stageId = stage?.id ?? '';
            this.componentsFacade.addComponentToStage({
              componentUrl: data.componentUrl,
              name: data.name,
              stageId,
              type: data.type,
              sweeps: modelSweeps
            });
          }),
          take(1)
        )
      ),

      catchError((e) => {
        this.matterportApiService.deleteToken();
        return of(e);
      })
    );
  }

  startSynchronization(component: StageComponent) {
    this.stagesRootService.loadSelectedScan(component);
    this.router.navigate(['../synchronization'], {
      queryParamsHandling: 'preserve',
      relativeTo: this.route
    });
  }

  openTagImporter(element: StageComponent) {
    this.componentsFacade.setSelectedComponentById(element.id);
    this.router.navigate(['../importer'], {
      relativeTo: this.route,
      queryParamsHandling: 'merge'
    });
  }

  openSynchronizeConfirmationDialog(component: StageComponent) {
    const dialogRef = this.modalService.createModalWithProviders(
      SynchronizeConfirmationDialogComponent,
      {},
      [
        {
          provide: MODAL_DATA,
          useValue: INFO_SYNCHRONIZATION
        }
      ]
    );

    firstValueFrom(
      dialogRef.events$.pipe(
        tap((response: DialogResponse<any>) => {
          if (response.state) {
            this.startSynchronization(component);
          }
        })
      )
    );
  }

  private _removeComponent(component: StageComponent): void {
    this.componentsFacade.removeComponent(component.stageId, component.id);
  }

  private _deleteSynchronization(component: StageComponent) {
    this.componentsFacade.deleteSynchronization(
      component.stageId,
      component.id
    );
  }

  openDeleteConfirmationDialog(component: StageComponent) {
    const dialogRef = this.modalService.createModalWithProviders(
      WarningConfirmationModalComponent,
      {},
      [
        {
          provide: MODAL_DATA,
          useValue: {
            text: PU_SURE_WANT_DELETE_COMPONENT,
            buttonText: DELETE
          }
        }
      ]
    );
    return dialogRef.events$.pipe(
      switchMap((response: DialogResponse<any>) =>
        iif(
          () => response.state,
          of(true).pipe(tap(() => this._removeComponent(component))),
          of(false)
        )
      ),
      take(1)
    );
  }

  openDeleteSynchronizationConfirmationDialog(component: StageComponent) {
    const dialogRef = this.modalService.createModalWithProviders(
      WarningConfirmationModalComponent,
      {},
      [
        {
          provide: MODAL_DATA,
          useValue: {
            text: PU_SURE_WANT_DELETE_SYNCHRONIZATION,
            buttonText: DELETE
          }
        }
      ]
    );
    return dialogRef.events$.pipe(
      tap((response: DialogResponse<any>) => {
        if (response.state) this._deleteSynchronization(component);
      }),
      take(1)
    );
  }
}
