<design-dialog-wrapper class="dialog-wrapper" cdkTrapFocus>
  <div designDialogHeader class="header">
    <div class="header__title-container">
      <i designIcon="filter" designSize="24px"></i>
      <h1 i18n="@@FILTERS">Filters</h1>
    </div>

    <button type="button" design-icon-button (click)="onCancel()">
      <i designIcon="cross" designSize="36px"></i>
    </button>
  </div>

  <ng-container designDialogContent>
    <ng-template #dynamicComponentOutlet></ng-template>

    <button type="button" (click)="resetFilters()" class="reset-btn">
      <div i18n="@@RESET_FILTERS">Reset filters</div>
    </button>
  </ng-container>

  <ng-container designDialogFooter>
    <button design-stroked-button (click)="onCancel()" type="button">
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button
      design-flat-button
      designButtonColor="accent"
      (click)="onApply()"
      type="button"
    >
      <div i18n="@@APPLY">Apply</div>
    </button>
  </ng-container>
</design-dialog-wrapper>
