/* eslint-disable @angular-eslint/no-host-metadata-property */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { Subject, filter, fromEvent, merge, takeUntil, tap } from 'rxjs';
import { UiButtonModule } from '../../../ui-button/ui-button.module';
import { UiIconModule } from '../../../ui-icon/public-api';

const MAX_TICK_TIME = 60;

@Component({
  selector: 'ui-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UiMenuPanelModule,
    UiIconModule,
    UiButtonModule,
    DesignIconButton,
    DesignIcon
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ui-audio'
  }
})
export class AudioComponent implements AfterViewInit, OnDestroy {
  private readonly _destroySource = new Subject<void>();
  @ViewChild('audioElement', { static: false })
  audioElement?: ElementRef<HTMLElement>;
  @ViewChild('rangeElement', { static: false })
  rangeElement?: ElementRef<HTMLElement>;

  private _audio!: HTMLAudioElement;
  private _range!: HTMLInputElement;

  get isPlaying(): boolean {
    return (
      this._audio &&
      !this._audio.paused &&
      !this._audio.ended &&
      this._audio.readyState > 2
    );
  }

  get duration(): string {
    return this._audio ? this._toMSS(`${this._audio.duration}`) : '0:00';
  }

  get currentTime(): string {
    return this._audio ? this._toMSS(`${this._audio.currentTime}`) : '0:00';
  }

  get timeProgress(): string {
    return `${this.currentTime} / ${this.duration}`;
  }

  get hasTitle(): boolean {
    return !!this.title;
  }

  @Input() title!: string;
  @Input()
  set src(value: SafeUrl | string | undefined) {
    this._src = value;
    //NOTE: used to update src
    this._audio?.load();
  }
  get src(): SafeUrl | string | undefined {
    return this._src;
  }
  private _src: SafeUrl | string | undefined;

  @Input()
  set controls(value: BooleanInput) {
    this._controls = coerceBooleanProperty(value);
  }
  get controls(): boolean {
    return this._controls;
  }
  _controls!: boolean;
  _rangeElementPressed = false;
  _hasBeenPlayingBeforePaused = false;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this._audio = this.audioElement?.nativeElement as HTMLAudioElement;
    this._range = this.rangeElement?.nativeElement as HTMLInputElement;

    this._audio.onloadeddata = () => {
      this.changeDetectorRef.detectChanges();
    };

    merge(fromEvent(this._audio, 'playing'), fromEvent(this._audio, 'pause'))
      .pipe(
        takeUntil(this._destroySource),
        tap(() => this.changeDetectorRef.markForCheck())
      )
      .subscribe();

    fromEvent(this._audio, 'timeupdate')
      .pipe(
        filter(() => this._rangeElementPressed === false),
        takeUntil(this._destroySource),
        tap(() => {
          const invLerp = this._inverseLerp(
            0,
            this._audio.duration,
            this._audio.currentTime
          );

          this._range.value = `${this._lerp(0, 100, invLerp)}`;
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe();

    fromEvent(this._audio, 'durationchange')
      .pipe(
        takeUntil(this._destroySource),
        tap(() => {
          this._range.value = this._range.min = '0';
          this._range.max = '100';
        })
      )
      .subscribe();

    merge(
      fromEvent(this._range, 'touchstart'),
      fromEvent(this._range, 'mousedown')
    )
      .pipe(
        takeUntil(this._destroySource),
        tap(() => {
          this._hasBeenPlayingBeforePaused = this.isPlaying;
          this._rangeElementPressed = true;
          this._audio.pause();
        })
      )
      .subscribe();

    merge(fromEvent(this._range, 'touchend'), fromEvent(this._range, 'mouseup'))
      .pipe(
        takeUntil(this._destroySource),
        tap(() => {
          if (!this._audio) {
            return;
          }

          this._rangeElementPressed = false;
          const currentPosition: number = this._range
            .value as unknown as number;
          const invLerp = this._inverseLerp(0, 100, currentPosition);
          const currentTime = this._lerp(0, this._audio.duration, invLerp);

          this._audio.currentTime = currentTime;

          if (this._hasBeenPlayingBeforePaused) {
            this._audio.play();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  toggle(): void {
    if (!this._audio) {
      return;
    }

    if (!this.isPlaying && this._audio.readyState >= 2) {
      this._audio.play();
    } else {
      this._audio.pause();
    }
  }

  private _toMSS(secs: string) {
    const sec_num = parseInt(secs, 10);
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

  private _lerp(begin: number, end: number, t: number): number {
    return (1.0 - t) * begin + end * t;
  }

  private _inverseLerp(begin: number, end: number, v: number): number {
    return (v - begin) / (end - begin);
  }
}
