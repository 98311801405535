import { CdkTableModule } from '@angular/cdk/table';
import { DatePipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
  computed,
  inject,
  input,
  viewChild,
  viewChildren
} from '@angular/core';
import { outputFromObservable, toObservable } from '@angular/core/rxjs-interop';
import { FormatDateSignalPipe } from '@simlab/design/format-date';
import { DesignIcon } from '@simlab/design/icon';
import { breakpointsObserver } from '@simlab/design/layout';
import {
  DesignSort,
  DesignSortModule,
  Sort,
  SortDirection
} from '@simlab/design/sort';
import { SortModel, sortDictionaryHelpers } from '@simlab/design/sort-switch';
import { documentsSortDictionary } from '@simlab/documents/data-access';
import { DocumentStates, DocumentsSortColumn } from '@simlab/documents/models';
import { EmptyObject } from '@simlab/documents/services';
import { CovertByte } from '@simlab/util-shared';
import { Observable, map, switchMap } from 'rxjs';
import { BreadcrumbTooltipComponent } from '../breadcrumb-tooltip/breadcrumb-tooltip.component';
import { CanOpenDirective } from '../can-open/can-open.directive';
import {
  CanSelectDirective,
  SelectableOption,
  TSelectableItem
} from '../can-select/can-select.directive';
import { CanUploadingDirective } from '../can-uploading/can-uploading.directive';
import { DocumentBadgeStateComponent } from '../document-badge-state/document-badge-state.component';
import { DocumentsActionBtnComponent } from '../documents-action-btn/documents-action-btn.component';
import { DocumentsLocationByPathGetterPipe } from './documents-location-by-path-getter/documents-location-by-path-getter.pipe';
import { DocumentTableDataSource } from './documents-table.data-source';

const tabletColumns = ['name', 'actions'];
const desktopColumns = [
  'name',
  'author',
  'created',
  'attached',
  'size',
  'actions'
];
const filteredDataDesktopColumns = [
  'name',
  'author',
  'created',
  'attached',
  'location',
  'size',
  'actions'
];

@Component({
  selector: 'documents-table',
  standalone: true,
  imports: [
    DatePipe,
    NgClass,
    CdkTableModule,
    CanSelectDirective,
    CanUploadingDirective,
    CanOpenDirective,
    DocumentBadgeStateComponent,
    DocumentsActionBtnComponent,
    CanSelectDirective,
    BreadcrumbTooltipComponent,
    DesignSortModule,
    DocumentsLocationByPathGetterPipe,
    DesignIcon,
    CovertByte,
    FormatDateSignalPipe
  ],
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'documents-table-component'
  }
})
export class DocumentTableComponent {
  private readonly _breakpoints = inject(breakpointsObserver);
  private readonly _selected = computed(() =>
    this.options().map((item) => item.selected())
  );
  private readonly _sort = viewChild.required(DesignSort);
  private readonly _desktopColumns = computed(() =>
    this.isFilterMode() ? filteredDataDesktopColumns : desktopColumns
  );

  protected readonly sortActive = computed(() => {
    const sortColumn = this.sortData().sortColumn;
    if (sortColumn === undefined) return undefined;

    return sortDictionaryHelpers.sortDataByType(documentsSortDictionary)[
      sortColumn
    ].key;
  });

  protected readonly sortDirection = computed<SortDirection>(() =>
    this.sortData().isAscending ? 'asc' : 'desc'
  );
  readonly selectedIds = input<string[]>();
  readonly data = input.required<DocumentStates[]>();
  readonly isFilterMode = input.required<boolean>();
  readonly sortData = input.required<SortModel | EmptyObject>();
  readonly options = viewChildren(SelectableOption);
  readonly options$: Observable<readonly TSelectableItem<any>[]> = toObservable(
    this.options
  );

  readonly sortChanges = outputFromObservable(
    toObservable(this._sort).pipe(
      switchMap(({ sortChange }) =>
        sortChange.pipe(map((sort) => this._mappedSortData(sort)))
      )
    )
  );

  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly dataSource = new DocumentTableDataSource(
    this.data,
    this._selected
  );

  protected readonly columns = computed(() =>
    this._breakpoints.extraSmallMobile()
      ? tabletColumns
      : this._desktopColumns()
  );

  protected readonly sortDictionary = documentsSortDictionary;

  protected trackById(_: number, item: DocumentStates): string {
    return item.data.id;
  }

  private _mappedSortData(rawSort: Sort): SortModel {
    const activeColumn = rawSort.active as keyof typeof DocumentsSortColumn;

    return {
      isAscending: rawSort.direction === 'asc',
      sortColumn: documentsSortDictionary[activeColumn].type
    };
  }
}
