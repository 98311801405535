import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { Media } from '@simlab/data-access';
import { NotesFacade } from '@simlab/data-store';
import { Observable, catchError, map, of } from 'rxjs';
import { NoteMediaService } from '../../services/note-media.service';
import { NoteMediaPhotoItemComponent } from './photo-item/photo-item.component';

@Component({
  selector: 'feature-stages-note-media-photo',
  templateUrl: './note-media-photo.component.html',
  styleUrls: ['./note-media-photo.component.scss'],
  standalone: true,
  imports: [CommonModule, NoteMediaPhotoItemComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteMediaPhotoComponent {
  @Output() imageLoadedEmitter: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  readonly photos$: Observable<Media[]> = this.notesFacade.photos$.pipe(
    map((photos: Media[] | null) => {
      this.imageLoadedEmitter.next(false);
      if (photos?.length) {
        return photos;
      } else {
        return [];
      }
    }),
    catchError((e) => {
      console.log(e);
      return of(e);
    })
  );

  constructor(
    private readonly notesFacade: NotesFacade,
    private readonly noteMediaService: NoteMediaService
  ) {}

  trackByPhotoUrl(index: number, item: Media): string {
    return item.url;
  }

  openDialog(index: number, name: string, mediaId: string): void {
    this.noteMediaService.openDialog(
      this.photos$,
      'photo',
      index,
      name,
      mediaId
    );
  }

  loadedImage(value: boolean) {
    this.imageLoadedEmitter.next(value);
  }
}
