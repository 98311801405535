import { Marker, Page } from '@simlab/data-access';

export interface RFIListResponse extends Page {
  items: RFIListItem[];
  totalCountForStagesWithoutFilters: number;
}

export type RFIListItem = {
  id: string;
  procoreCreatorId: number;
  procoreId: number;
  number: string | undefined;
  title: string;
  status: RFIStatus;
  modyfiedAt: string;
  ballInCourts: string[];
  marker: Marker | undefined;
};

export const RFIStatus = {
  Draft: 1,
  Open: 2,
  Closed: 3
} as const;
export type RFIStatus = (typeof RFIStatus)[keyof typeof RFIStatus];

export const getKeyofRFIStatus = (
  status: RFIStatus
): keyof typeof RFIStatus => {
  switch (status) {
    case RFIStatus.Draft:
      return 'Draft';
    case RFIStatus.Open:
      return 'Open';
    case RFIStatus.Closed:
      return 'Closed';
    default:
      throw new Error(`Unhandled case ${status}`);
  }
};

export type ProcoreUser = {
  id: number;
  login: string;
  name: string;
  companyName: string;
};

export type CostCode = {
  id: 0;
  name: string;
  fullCode: string;
};

export type ProcoreStatus = {
  status: string;
  value: 0;
};

export enum ProcoreBallInCourtRoleType {
  Assignees = 1,
  Creator,
  RfiManager
}

export type SpecificationSection = {
  id: number;
  number: string;
  description: string;
  label: string;
  currentRevisionId: number;
};

export type ResponsibleContractor = {
  id: number;
  name: string;
};

export type Attachment = {
  id: number;
  canBeViewed: boolean;
  filename: string;
  name: string;
  url: string;
  viewable: boolean;
};

export type Answer = {
  id: number;
  answerDate: string;
  richTextBody: string;
  plainTextBody: string;
  attachments: Attachment[];
  createdBy: string;
  createdById: number;
  official: boolean;
};

export type Question = {
  id: number;
  body: string;
  plainTextBody: string;
  richTextBody: string;
  answers: Answer[];
};

export type RFI = {
  id: string;
  procoreId: number;
  stageId: string;
  accepted: boolean;
  assignee: ProcoreUser;
  assignees: ProcoreUser[];
  ballInCourts: ProcoreUser[];
  ballInCourt: ProcoreUser;
  ballInCourtRole: ProcoreBallInCourtRoleType;
  costCode: CostCode;
  costImpact: ProcoreStatus;
  createdAt: string;
  createdBy: ProcoreUser;
  deletedAt: string;
  deleted: boolean;
  drawingNumber: number;
  drawingIds: number[];
  dueDate: string;
  draft: boolean;
  fullNumber: string;
  initiatedAt: string;
  link: string;
  number: string;
  prefix: string;
  private: boolean;
  rfiManager: ProcoreUser;
  status: RFIStatus;
  specificationSection: SpecificationSection;
  subject: string;
  scheduleImpact: ProcoreStatus;
  updatedAt: string;
  translatedStatus: string;
  timeResolved: string;
  title: string;
  distributionlist: ProcoreUser[];
  receivedFrom: ProcoreUser;
  reference: string;
  responsibleContractor: ResponsibleContractor;
  questions: Question[];
  marker: Marker | undefined;
  customFields: {
    additionalProp1: {
      dataType: string;
      value: string;
    };
    additionalProp2: {
      dataType: string;
      value: string;
    };
    additionalProp3: {
      dataType: string;
      value: string;
    };
  };
  itemUrl: string;
};

export type RfiUserDisplayElements = {
  showEdit: true;
  showDelete: true;
  showReopen: true;
  showAddResponse: true;
  showDeleteResponse: true;
  showClose: true;
  returnToCourt: number;
  showOpenRfi: true;
  showMarkOfficial: true;
  showAddAssignees: true;
  showForwardForReview: true;
};

export type ProcoreDataProgressResponse = {
  progress?: number | undefined;
  totalCount?: number | undefined;
  status: (typeof ProcoreDataProgressStatus)[keyof typeof ProcoreDataProgressStatus];
};

export const ProcoreDataProgressStatus = {
  None: 0,
  InProgress: 1,
  Completed: 2,
  Failed: 3
} as const;
