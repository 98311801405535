<div class="comment-wrapper comment">
  <ng-template
    [ngTemplateOutlet]="commentTemplate"
    [ngTemplateOutletContext]="comment"
  ></ng-template>
  <div class="replies"><ng-content></ng-content></div>
  <ng-container *ngIf="showReply && comment.isRemoved === false">
    <feature-stages-add-comment
      [maxLength]="maxLength()"
      (commented)="addReply($event)"
      [mentionData]="mentionData"
      [enableAddComment]="
        ((comment.isOwner | checkCommentsPrivilegesAsync | async) ?? true) ===
        true
      "
    ></feature-stages-add-comment>
  </ng-container>
</div>

<ng-template
  #commentTemplate
  let-authorName="authorName"
  let-createdAt="createdAt"
  let-text="text"
  let-id="id"
  let-authorId="authorId"
  let-isRemoved="isRemoved"
  let-hasReplies="replies"
  let-isOwner="isOwner"
>
  <div [ngClass]="{ 'reply-comment comment-wrapper': !hasReplies }">
    <div class="comment-header">
      <div class="created-by">
        <i designIcon="profile" designSize="1.5rem"></i>
        {{ authorName }}
      </div>
      <div class="created-at">
        <i designIcon="calendar" designSize="1.5rem"></i>
        {{ createdAt | formatDate | async }}
        <button
          design-icon-button
          class="menu-btn"
          [designMenuTriggerFor]="menu"
          *ngIf="projectIsActive$ | async"
        >
          <i designIcon="more_second" designSize="1rem"></i>
        </button>
        <design-menu-panel #menu>
          <button
            design-menu-item
            [disabled]="
              ((isOwner | checkCommentsPrivilegesAsync | async) ?? true) ===
                false || isRemoved
            "
            (click)="readonly = false; mention.focus()"
          >
            <ng-container i18n="@@EDIT"> Edit </ng-container>
          </button>
          <button
            design-menu-item
            (click)="removeComment(id)"
            [disabled]="
              ((isOwner | checkCommentsPrivilegesAsync | async) ?? true) ===
                false || isRemoved
            "
          >
            <ng-container i18n="@@GEN_REMOVE"> Remove </ng-container>
          </button>
        </design-menu-panel>
      </div>
    </div>
    <div class="comment-body">
      <design-mention
        #mention
        [value]="commentText"
        externalTagName="user-link"
        [readonly]="readonly"
        [mentionData]="mentionData"
        (focusout)="saveChanges(mention)"
        (textContent)="commentLength.set($event.length)"
      >
      </design-mention>
      @if (isMaxLengthErrorVisible()) {
        <p class="comment-body__error">
          {{ maxLengthErrorMessage() }}
        </p>
      }
    </div>

    <div
      *ngIf="
        hasReplies &&
        !isRemoved &&
        ((isOwner | checkCommentsPrivilegesAsync | async) ?? true) === true
      "
      class="comment-footer"
    >
      <button
        design-flat-button
        designButtonColor="secondary"
        class="ft-btn-override"
        (click)="showReply = !showReply"
        *ngIf="projectIsActive$ | async"
      >
        <ng-container i18n="@@GEN_REPLY"> Reply </ng-container>
      </button>
    </div>
  </div>
</ng-template>
