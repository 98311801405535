<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <div class="header">
      <i ui-icon [name]="icon" customSize="7.25rem" defaultColor></i>
      <h2 designLabel>{{ title }}</h2>
    </div>
  </ng-container>

  <ng-container designDialogContent>
    <div class="content">
      <div class="main-text">
        {{ message }}
      </div>
    </div>
    <ng-container *ngIf="summary.reports.length > 0">
      <div class="content">
        <a
          (click)="detailsOpened = !detailsOpened"
          class="main-text link"
          i18n="@@SHOW_DETAILS"
        >
          Show details
        </a>
        <div *ngIf="detailsOpened" class="details-container">
          <ng-container *ngFor="let report of summary.reports">
            {{ report.tagName }}
            <p>&nbsp;-&nbsp;{{ report.name }} - {{ report.errors }}</p>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-flat-button
      [designButtonColor]="this.summary.type"
      (click)="confirm()"
    >
      <div i18n="@@CONTINUE">Continue</div>
      <ui-button-loader></ui-button-loader>
    </button>
  </ng-container>
</design-dialog-wrapper>
