import { SortDictionary } from '@simlab/design/sort-switch';
import { DocumentsSortColumn } from '@simlab/documents/models';

export const documentsSortDictionary: SortDictionary<
  typeof DocumentsSortColumn
> = {
  Name: {
    type: DocumentsSortColumn.Name,
    name: $localize`:@@NAME:Name`,
    key: 'Name'
  },
  CreatedAt: {
    type: DocumentsSortColumn.CreatedAt,
    name: $localize`:@@CREATED:Created`,
    key: 'CreatedAt'
  },
  Attached: {
    type: DocumentsSortColumn.Attached,
    name: $localize`:@@ATTACHED:Attached`,
    key: 'Attached'
  },
  Size: {
    type: DocumentsSortColumn.Size,
    name: $localize`:@@SIZE:Size`,
    key: 'Size'
  }
};
