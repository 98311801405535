<section class="header">
  <h2 class="header--title" i18n="@@AREM_MEASUREMENT">
    <ng-content></ng-content>
  </h2>
  <div class="header__button_group">
    <design-tooltip
      i18n-tooltip="@@SHOW_HIDE_MEASUREMENTS"
      tooltip="show/hide measurements"
      position="left"
    >
      <button design-icon-button (click)="toggleVisibility()" title="">
        <i
          [designIcon]="measurementsVisibility() ? 'visible' : 'invisible'"
          designSize="32px"
        ></i>
      </button>
    </design-tooltip>
    <design-tooltip
      i18n-tooltip="@@EXPORT_MEASUREMENTS"
      tooltip="export measurements to file"
      position="left"
    >
      <button design-icon-button (click)="exportMeasurements()">
        <i designIcon="export" designSize="26px"></i>
      </button>
    </design-tooltip>
    <button design-icon-button (click)="onClose.emit()">
      <i designIcon="cross" designSize="32px"></i>
    </button>
  </div>
</section>
<section class="content">
  <div class="content__tabs">
    <ng-container
      i18n-name="@@GEN_MEASUREMENTS"
      [ngTemplateOutlet]="button"
      [ngTemplateOutletContext]="{
        type: 'measurements',
        name: 'Measurements'
      }"
    />
    <ng-container
      [ngTemplateOutlet]="button"
      [ngTemplateOutletContext]="{
        type: 'measurements_groups',
        name: 'Measurements groups'
      }"
    />
  </div>
  <ui-divider></ui-divider>
</section>
@if (opened()) {
  <ng-container [ngSwitch]="selected">
    <ng-container *ngSwitchCase="'measurements_groups'">
      <feature-measurements-group
        class="overflowed-flex-list"
        [measurements]="measurements() || []"
        [measurementsGroup]="measurementsGroup()"
        [systemOfMeasurement]="systemOfMeasurement()"
        (createGroup)="createGroup($event)"
        (changeName)="changeName($event)"
        (deleteMeasurementGroup)="deleteMeasurementGroup($event)"
        (addMeasurementsToGroup)="addMeasurementsToGroup($event)"
        (goToMeasurementList)="goToMeasurementList($event)"
        (removeMeasurementFromGroup)="removeMeasurementFromGroup($event)"
      ></feature-measurements-group>
    </ng-container>
    <ng-container *ngSwitchCase="'measurements'">
      <feature-measurements
        [measurements]="measurements() || []"
        [notes]="notes()"
        class="overflowed-flex-list"
        [measurementsGroup]="measurementsGroup()"
        [systemOfMeasurement]="systemOfMeasurement()"
        (onAdd)="add()"
        (edit)="edit($event)"
        (goToSelected)="goToSelected()"
        (changeTitle)="changeTitle($event)"
        (changeColor)="changeColor($event)"
        (addMeasurementToGroups)="addMeasurementToGroups($event)"
        (assignToNote)="assignToNote($event)"
        (openRelatedNote)="openRelatedNote($event)"
        (unassignFromNote)="unassignFromNote($event)"
        (deleteAreaMeasurement)="deleteAreaMeasurement($event)"
        [(selectedMeasurement)]="selectedMeasurement"
      ></feature-measurements>
    </ng-container>
  </ng-container>
}

<ng-template #button let-type="type" let-name="name">
  <button
    design-flat-button
    class="content__tabs--tab"
    [class.selected]="selected === type"
    (click)="selected = type"
  >
    <span class="font-color">
      {{ name }}
    </span>
  </button>
</ng-template>
