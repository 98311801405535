import { Directive, HostBinding, input } from '@angular/core';
import { FileCardStates } from '@simlab/documents/models';

@Directive({
  selector: '[documentsCanUploading]',
  standalone: true
})
export class CanUploadingDirective {
  readonly data = input<FileCardStates>();

  @HostBinding('class.uploading') get uploadingHandler() {
    return this.data()?.state === 'UPLOADING';
  }
}
