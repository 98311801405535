import { InjectionToken, InputSignal, Signal } from '@angular/core';
import { FiltersParams } from '@simlab/documents/ui';
import { Observable, Subject } from 'rxjs';

export type SelectedDocumentFilterItem = {
  key: string;
  id: number | string;
  value: string | Observable<string>;
};

export interface DocumentFiltersBase<TData extends Record<string, unknown>> {
  readonly isRenderInModal: InputSignal<boolean>;
  updateFormByUrlQueryParamsChanges$(
    mapper: (urlParams: FiltersParams<TData>) => TData
  ): Observable<unknown>;
}

export interface FiltersData {
  readonly selectedFilters: Signal<SelectedDocumentFilterItem[] | undefined>;
  readonly valueChanges$: Observable<Record<string, any>>;
  readonly value: Signal<Record<string, any>>;
  readonly valueChanges: Subject<Record<string, any>>;

  removeFilter: (item: SelectedDocumentFilterItem) => void;
  resetFilters: () => void;
  applyFilters: () => void;
}

export const filtersToken = new InjectionToken<FiltersData>('filters token');
