/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { designAutocompleteControlToken } from '@simlab/design/autocomplete';
import { DesignFormFieldControl } from '@simlab/design/form-field';
import { ChipInputPanelBase } from './chip-input-panel-base.directive';

@Component({
  selector: 'design-chip-input-panel, [designChipInputPanel]',
  exportAs: 'designChipInputPanel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chip-input-panel.component.html',
  styleUrls: ['./chip-input-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'design-chip-input-panel',
  },
  providers: [
    {
      provide: DesignFormFieldControl,
      useExisting: DesignChipInputPanel,
    },
    {
      provide: designAutocompleteControlToken,
      useExisting: DesignChipInputPanel,
    },
  ],
})
export class DesignChipInputPanel extends ChipInputPanelBase<any> {}
