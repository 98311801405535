import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation
} from '@angular/core';
import { BreadcrumbComponent } from '@simlab/design/breadcrumb';
import { TooltipOnClickDirective, UiTooltip } from '@simlab/design/tooltip';
import { FolderAsPathItem } from '@simlab/documents/data-access';

@Component({
  selector: 'documents-breadcrumb-tooltip',
  standalone: true,
  imports: [BreadcrumbComponent, UiTooltip, TooltipOnClickDirective],
  templateUrl: './breadcrumb-tooltip.component.html',
  styleUrl: './breadcrumb-tooltip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'documents-breadcrumb-tooltip',
    '(click)': '$event.stopPropagation()'
  }
})
export class BreadcrumbTooltipComponent {
  readonly path = input.required<FolderAsPathItem[]>();
}
