import { inject, Injectable, signal } from '@angular/core';
import { PunchItemsListStore, RFIListStore } from '@simlab/procore/services';
import { EMPTY } from 'rxjs';

@Injectable()
export class AnnotationsFacade {
  private readonly _punchItemsListStore = inject(PunchItemsListStore, {
    optional: true
  });
  private readonly _rfiListStore = inject(RFIListStore, { optional: true });

  readonly punchItemActiveMarkers =
    this._punchItemsListStore?.activateMarkers ?? signal([]);
  readonly rfiItemActiveMarkers =
    this._rfiListStore?.activateMarkers ?? signal([]);

  readonly getSelectedPunchItem$ =
    this._punchItemsListStore?.getItemSelected$ ?? (() => EMPTY);
  readonly getSelectedRfiItem$ =
    this._rfiListStore?.getItemSelected$ ?? (() => EMPTY);

  deleteRfiItemFromStore(itemId: string) {
    this._rfiListStore?.deleteItemFromStore(itemId);
    this._rfiListStore?.deleteItemMarkerFromStore(itemId);
  }

  deletePunchItemFromStore(itemId: string) {
    this._punchItemsListStore?.deleteItemFromStore(itemId);
    this._punchItemsListStore?.deleteItemMarkerFromStore(itemId);
  }

  resetState() {
    this._punchItemsListStore?.resetState();
    this._rfiListStore?.resetState();
  }

  resetListStates() {
    this._punchItemsListStore?.resetListStates();
    this._rfiListStore?.resetListStates();
  }
}
