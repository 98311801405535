import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Coordinates, IMAGE_MIME_TYPE_EXTENSIONS } from '@simlab/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { inject } from '@angular/core';
import {
  ConfirmationModalRef,
  DialogResponse,
  MODAL_DATA,
  ModalService
} from '@simlab/ui/modal';
import { CropConfirmationDialogComponent } from '@simlab/util-shared';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  from,
  map,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { CroppedImage } from '../components/crop-confirmation-dialog/models/cropped-image';
import { CroppedImageModalData } from '../components/crop-confirmation-dialog/models/cropped-image-modal-data';

export class ProjectBase {
  private readonly _modalService = inject(ModalService);
  protected readonly _destroySource = new Subject<void>();
  private _imageSource = new BehaviorSubject<string | null>(null);
  private _notSupportedFile = false;
  public get notSupportedFile() {
    return this._notSupportedFile;
  }
  public set notSupportedFile(value) {
    this._notSupportedFile = value;
  }

  get imageSource$(): Observable<string> {
    return this._imageSource.asObservable().pipe(
      filter((url: string | null) => url !== null),
      map((url: string | null) => url as string)
    );
  }

  protected _initialThumbnail!: string;
  set initialThumbnail(value: string | undefined) {
    if (!value) return;

    this._initialThumbnail = value;
    this._imageSource.next(value);
  }

  readonly formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60)
    ]),
    description: new UntypedFormControl('', [Validators.maxLength(500)]),
    address: new UntypedFormControl('', [Validators.maxLength(120)]),
    geolocation: new UntypedFormGroup({
      latitude: new UntypedFormControl(''),
      longitude: new UntypedFormControl('')
    })
  });

  set geolocation(value: Coordinates | undefined) {
    if (!value) return;
    this.formGroup.patchValue({ geolocation: value });
  }

  get geolocationControl(): AbstractControl | null {
    return this.formGroup.get('geolocation');
  }

  set address(value: string | undefined) {
    if (!value) return;
    this.formGroup.patchValue({ address: value });
  }

  get addressControl(): AbstractControl | null {
    return this.formGroup.get('address');
  }

  set name(value: string | undefined) {
    if (!value) return;
    this.formGroup.patchValue({ name: value });
  }

  get nameControl(): AbstractControl | null {
    return this.formGroup.get('name');
  }

  set description(value: string | undefined) {
    if (!value) return;
    this.formGroup.patchValue({ description: value });
  }

  get descriptionControl(): AbstractControl | null {
    return this.formGroup.get('description');
  }

  handleImageReady(file: File): void {
    this.notSupportedFile = false;
    if (IMAGE_MIME_TYPE_EXTENSIONS.includes(file.type)) {
      this._cropModal(file);
    } else {
      this.notSupportedFile = true;
    }
  }

  private async _createCropModal(
    modalData: CroppedImageModalData
  ): Promise<ConfirmationModalRef<CroppedImage>> {
    return this._modalService.createModalWithProviders(
      CropConfirmationDialogComponent,
      {
        maxWidth: 'min(90%, 810px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: modalData
        }
      ]
    );
  }

  private _cropModal(file: File): void {
    const modalData: CroppedImageModalData = {
      imageData: file
    };

    from(this._createCropModal(modalData))
      .pipe(
        switchMap((component) =>
          component.events$.pipe(
            tap((response: DialogResponse<CroppedImage>) => {
              if (response.state && response.result) {
                this._imageSource.next(response.result?.base64Image);

                this.formGroup.addControl(
                  'imageData',
                  new UntypedFormGroup({
                    name: new UntypedFormControl(
                      response.result?.name.split('.')[0]
                    ),
                    extension: new UntypedFormControl('png'),
                    mimeType: new UntypedFormControl('image/png'),
                    base64data: new UntypedFormControl(
                      response.result?.base64Image.split(',')[1]
                    )
                  })
                );
              }
            }),
            take(1),
            takeUntil(this._destroySource)
          )
        )
      )
      .subscribe();
  }

  protected cleanUrl(url: string): string {
    const urlWithOutToken = url.slice(0, url.indexOf('?'));

    const indexOfFormat = urlWithOutToken.includes('.png')
      ? urlWithOutToken.indexOf('.png')
      : urlWithOutToken.indexOf('.jpg');

    const indexOfImageSize = urlWithOutToken.indexOf('_');
    return (
      urlWithOutToken.slice(0, indexOfImageSize) +
      urlWithOutToken.slice(indexOfFormat)
    );
  }
}
