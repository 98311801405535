import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { inject, TemplateRef, ViewContainerRef } from '@angular/core';
import { AbstractConstructor, Constructor } from '@simlab/design/internal';
import {
  documentsApiToken,
  DocumentsInstanceDataToken
} from '@simlab/documents/data-access';
import {
  DocumentComponentsLoadedState,
  ExternalTransferStatusEnum,
  ImportProcoreResoures
} from '@simlab/documents/models';
import { DocumentsStore } from '@simlab/documents/services';
import {
  catchError,
  delay,
  exhaustMap,
  filter,
  firstValueFrom,
  from,
  of,
  retry,
  Subject,
  switchMap,
  take,
  tap
} from 'rxjs';

import { TEventElementItem } from '@simlab/event-queue';
import { TEventQueueItem } from '@simlab/event-queue/models';
import { QueueService } from '@simlab/event-queue/services';
import type { ModalComponent, ModalData } from '@simlab/modal/ui';
import { ProcoreDocumentsApi } from '../procore-documents/models/procore-documents-api.modal';
import { ProcoreDocumentTypeEnumValues } from '../procore-documents/models/procore-documents.enum';
import {
  DocumentMoveModalComponent,
  DocumentMoveModalComponentData,
  TDocumentMoveResponse
} from '../stages-documents/ui/document-move-modal/document-move-modal.component';

const copyToTranslation = $localize`:@@COPY_TO:Copy to`;
const copyBtnTranslation = $localize`:@@COPY:Copy`;

export interface CanCopyToProcore {
  copyToProcore(
    items: DocumentComponentsLoadedState[],
    procoreDocumentType: ProcoreDocumentTypeEnumValues,
    templateRef: TemplateRef<any> | undefined
  ): void;
}

type TCanCopyToProcore = Constructor<CanCopyToProcore> &
  AbstractConstructor<CanCopyToProcore>;
export function mixinCopyToProcore<T extends AbstractConstructor<any>>(
  base: T
): TCanCopyToProcore & T;
export function mixinCopyToProcore<T extends Constructor<any>>(base: T) {
  return class extends base implements CanCopyToProcore {
    private readonly _dialog = inject(Dialog);
    private readonly _viewRef = inject(ViewContainerRef);
    private readonly _queueService = inject(QueueService);
    private readonly _instanceData = inject(DocumentsInstanceDataToken);
    private readonly _api = inject<ProcoreDocumentsApi>(documentsApiToken);
    protected readonly _documentsStore = inject(DocumentsStore);
    private _templateToErrorUpload: TemplateRef<any> | undefined;

    copyToProcore(
      items: DocumentComponentsLoadedState[],
      procoreDocumentType: ProcoreDocumentTypeEnumValues,
      templateRef: TemplateRef<any> | undefined
    ): void {
      const { procoreDirectories, procoreDocuments } =
        this._getMoveDataRequest(items);

      this._copyModal()
        .closed.pipe(
          take(1),
          filter(
            (targetDirectoryId): targetDirectoryId is TDocumentMoveResponse =>
              targetDirectoryId !== undefined
          ),
          tap((targetDirectoryId) => {
            procoreDocuments.forEach((document) => {
              this.copyToProcoreElement(
                {
                  projectId: this._instanceData?.projectId() ?? '',
                  targetDirectoryId: targetDirectoryId.selectedFolder.id ?? '',
                  procoreDirectoryIds: [],
                  procoreDocumentIds: [document.id],
                  procoreDocumentType
                },
                document.name,
                templateRef
              );
            });

            procoreDirectories.forEach((folder) => {
              this.copyToProcoreElement(
                {
                  projectId: this._instanceData?.projectId() ?? '',
                  targetDirectoryId: targetDirectoryId.selectedFolder.id ?? '',
                  procoreDirectoryIds: [folder.id],
                  procoreDocumentIds: [],
                  procoreDocumentType
                },
                folder.name,
                templateRef
              );
            });
          })
        )
        .subscribe();
    }

    private copyToProcoreElement(
      {
        targetDirectoryId,
        procoreDirectoryIds,
        procoreDocumentIds,
        procoreDocumentType
      }: ImportProcoreResoures,
      name: string,
      templateRef: TemplateRef<any> | undefined
    ) {
      const title = $localize`:@@COPY_FILES_STAGES:Copy ${name}`;
      const destroy = new Subject<void>();
      let context: TEventElementItem = {
        title,
        subTitle: $localize`:@@FROM_PROCORE:from Procore`,
        currentElement: 0,
        countOfElements: 100,
        status: {
          workStatus: 'WORKING',
          showErrorDetails: false,
          failed: []
        },
        cancel: () =>
          this.cancelModalUpload(() => {
            destroy.next();
          })
      };
      const eventQueueId = this._queueService.addEvent({
        type: 'UPLOAD',
        templateRef,
        context,
        execute$: this._api
          .importProcoreResources$({
            projectId: this._instanceData?.projectId() ?? '',
            targetDirectoryId,
            procoreDirectoryIds,
            procoreDocumentIds,
            procoreDocumentType
          })
          .pipe(
            switchMap((statusId) =>
              this._api.importProcoreResourcesStatuses$().pipe(
                delay(500),
                tap((statuses) => {
                  const importElementStatus = statuses.find(
                    ({ procoreImportStatusId }) =>
                      procoreImportStatusId === statusId
                  );
                  if (!importElementStatus) return;

                  const failed = importElementStatus.details.filter(
                    (detail) =>
                      detail.status === ExternalTransferStatusEnum.Failure
                  );

                  context = {
                    ...context,
                    currentElement: importElementStatus.progress,
                    status: {
                      ...context.status,
                      showErrorDetails: true,
                      failed: failed.map(({ name }) => name),
                      errorDetails: (files: string[]) =>
                        this.errorModalCopyToUpload(files)
                    }
                  };

                  this._queueService.updateView(eventQueueId, context);
                  throw Error('Pending, try again');
                }),
                retry(),
                catchError((e) => {
                  console.error(e);
                  return of(e);
                })
              )
            ),
            catchError((e) => {
              context = {
                ...context,
                status: {
                  ...context.status,
                  showErrorDetails: true,
                  failed: [name],
                  errorDetails: (files: string[]) =>
                    this.errorModalCopyToUpload(files)
                }
              };
              this._queueService.updateView(eventQueueId, context);
              return of(e);
            })
          ),
        start: this._jobCopyStart,
        end: this._jobCopyEnd
      });
    }

    private _getMoveDataRequest(items: DocumentComponentsLoadedState[]) {
      const procoreDirectories = items
        .filter((data) => data.isDirectory)
        .map((data) => ({ id: Number(data.id), name: data.name }));
      const procoreDocuments = items
        .filter((data) => !data.isDirectory)
        .map((data) => ({ id: Number(data.id), name: data.name }));

      return {
        procoreDirectories,
        procoreDocuments
      };
    }

    protected _jobCopyStart = (
      e: TEventQueueItem<any, TEventElementItem>
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    ) => {};
    protected _jobCopyEnd = (
      e: any,
      event: TEventQueueItem<any, TEventElementItem>
    ) => {
      const context = this._queueService.getViewContext(event.id!);
      const workStatus =
        context.status.failed.length > 0
          ? 'COMPLETED_WITH_FAILURES'
          : 'COMPLETED';
      this._queueService.updateView(event.id!, {
        ...context,
        status: {
          ...context.status,
          workStatus
        }
      });
    };

    private _copyModal = (): DialogRef<
      TDocumentMoveResponse | undefined,
      DocumentMoveModalComponent
    > => {
      return this._dialog.open<
        TDocumentMoveResponse | undefined,
        DocumentMoveModalComponentData,
        DocumentMoveModalComponent
      >(DocumentMoveModalComponent, {
        panelClass: 'move-modal',
        width: 'min(100%, 610px)',
        viewContainerRef: this._viewRef,
        data: {
          title: copyToTranslation,
          btnLabel: copyBtnTranslation,
          projectId: this._instanceData?.projectId() ?? '',
          isFilterMode: this._documentsStore.isFilterMode()
        }
      });
    };
    cancelModalUpload(onSuccess: () => void) {
      const modal = from(import('@simlab/modal/ui'));

      firstValueFrom(
        modal.pipe(
          exhaustMap(
            ({ ModalComponent }) =>
              this._dialog.open<boolean, ModalData, ModalComponent>(
                ModalComponent,
                {
                  data: {
                    title: $localize`:@@ARE_YOU_SURE_TO_CANCEL:Are you sure you want to cancel operation?`,
                    type: 'alert',
                    message: '',
                    btnContent: {
                      confirm: {
                        label: $localize`:@@YES:Yes`,
                        returnValue: true
                      },
                      cancel: {
                        label: $localize`:@@NO:No`,
                        returnValue: false
                      }
                    }
                  },
                  width: 'min(90%, 610px)'
                }
              ).closed
          ),
          tap((confirm) => {
            if (confirm) onSuccess();
          })
        )
      );
    }

    errorModalCopyToUpload(files: string[]) {
      const modal = from(import('@simlab/modal/ui'));
      const title = files.length === 1 ? files[0] : 'files';

      firstValueFrom(
        modal.pipe(
          exhaustMap(
            ({ ModalComponent }) =>
              this._dialog.open<boolean, ModalData, ModalComponent>(
                ModalComponent,
                {
                  data: {
                    title: $localize`:@@SOMETHING_WENT_WRONG:Something went wrong!`,
                    message: $localize`:@@TRY_UPLOADING_FILES:Try uploading this files again.`,
                    type: 'alert',
                    btnContent: {
                      confirm: {
                        label: $localize`:@@OK:Ok`,
                        returnValue: true
                      },
                      cancel: {
                        label: $localize`:@@CANCEL:Cancel`,
                        returnValue: false
                      }
                    },
                    content: {
                      template: this._templateToErrorUpload,
                      data: files
                    }
                  },
                  width: 'min(90%, 610px)'
                }
              ).closed
          )
        )
      );
    }
  };
}
