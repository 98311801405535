@if (data(); as data) {
  <div class="folder__name-container">
    <documents-document-badge-state
      [data]="data"
      [isSelected]="isSelected()"
      class="folder__badge"
    ></documents-document-badge-state>

    <span class="folder__name" [title]="data.data.name">{{
      data.data.name
    }}</span>
  </div>

  <documents-documents-action-btn [data]="data.data" />
}
