import { computed, inject, Signal } from '@angular/core';
import { Marker } from '@simlab/data-access';
import { TagNoteTypes } from '@simlab/matterport/lib/models/dto';
import { ApiProcoreService } from '@simlab/procore/data-access';
import { PunchItemsListStore } from '@simlab/procore/services';
import { map, mergeMap, of, tap } from 'rxjs';
import { Vector3 } from 'three';
import {
  AnnotationMarker,
  AnnotationTagPlacement,
  PlaceMarkerInAnnotationParams
} from '../tag-placement.component';

export class PunchItemTagPlacementService implements AnnotationTagPlacement {
  private readonly _api = inject(ApiProcoreService);
  private readonly _punchItemListStore = inject(PunchItemsListStore);

  readonly hasMarker: Signal<boolean | undefined> = computed(
    () => this._punchItemListStore.itemSelected()?.marker !== undefined
  );
  readonly selectedAnnotationId$ = this._punchItemListStore
    .getItemSelected$()
    .pipe(map(({ id }) => id));

  readonly removePosition$ = () =>
    this.selectedAnnotationId$.pipe(
      mergeMap((id) =>
        this._api.removeMarkerFromPunchItem(id).pipe(
          map(() => {
            const selectedPunchItem = this._punchItemListStore.itemSelected();
            if (selectedPunchItem === undefined) return <AnnotationMarker>{};

            return this._getMappedPunchItemToAnnotationMarker();
          }),
          tap(() =>
            this._punchItemListStore.updateSelectedItemMarker(undefined)
          )
        )
      )
    );

  readonly initMarkerSet$ = () =>
    of(this._getMappedPunchItemToAnnotationMarker());

  placeMarkerInAnnotation$(params: PlaceMarkerInAnnotationParams) {
    const marker: Marker = {
      position: params.convertedPosition,
      rotation: { x: 0, y: 0, z: 0, w: 1 },
      anchorPointNormal: new Vector3(0, 0, 0)
    };

    return this._api
      .placeMarkerToPunchItem({
        id: params.annotationId,
        ...marker
      })
      .pipe(
        tap(() => {
          this._punchItemListStore.updateSelectedItemMarker(marker);
        }),
        map(() => params.annotationId)
      );
  }

  private _getMappedPunchItemToAnnotationMarker(): AnnotationMarker {
    const punchItem = this._punchItemListStore.itemSelected();
    if (punchItem === undefined) throw new Error('Punch item is not selected');

    const markerType = punchItem.showYellowBackground
      ? <TagNoteTypes>'PUNCH_DRAFT'
      : <TagNoteTypes>'PUNCH_CLOSED';

    return <AnnotationMarker>{
      id: punchItem.procoreId,
      marker: punchItem.marker,
      type: markerType
    };
  }
}
