import { computed, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { UserPreferenceFacade } from '@simlab/data-store';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  procoreBaseInfoPayload,
  ProcoreElements,
  ProcoreUserAccessLevels
} from '@simlab/procore/models';
import { derivedAsync } from 'ngxtension/derived-async';
import { catchError, filter, of, pairwise, repeat, shareReplay } from 'rxjs';

@Injectable()
export class AnnotationsProcorePermissionsService {
  private readonly _api = inject(ApiProcoreService);
  private readonly _baseInfoProcore = inject(procoreBaseInfoPayload);
  private readonly _userPreferences = inject(UserPreferenceFacade);

  private readonly _refreshPermissionsNotify$ =
    this._userPreferences.userConnectedToProcore$.pipe(
      pairwise(),
      filter(([prev, curr]) => {
        // NOTE (Łukasz) Is state when we want to trigger refresh permissions procedure.
        const isConnectedFromPrevUnconnectedState =
          prev === false && curr === true;
        return isConnectedFromPrevUnconnectedState;
      }),
      takeUntilDestroyed()
    );

  private readonly _userProjectPermissions = derivedAsync<undefined | any[]>(
    () => {
      const baseInfoProcore = this._baseInfoProcore();
      if (baseInfoProcore?.procoreCompanyId === undefined) return undefined;

      return this._api.getUserProjectPermissions(baseInfoProcore).pipe(
        repeat({ delay: () => this._refreshPermissionsNotify$ }),
        catchError((errorMsg) => {
          console.error(errorMsg);
          return of(undefined);
        }),
        shareReplay()
      );
    }
  );

  readonly hasUserRfiTemplatePermissions = computed(() => {
    const userProjectPermissions = this._userProjectPermissions();

    if (!userProjectPermissions) return undefined;

    return userProjectPermissions.some(
      (permission) =>
        permission.element === ProcoreElements.RFIs &&
        permission.accessType !== ProcoreUserAccessLevels['None']
    );
  });

  readonly hasUserRfiTemplatePermissions$ = toObservable(
    this.hasUserRfiTemplatePermissions
  );

  readonly hasUserPunchItemPermissions = computed(() => {
    const userProjectPermissions = this._userProjectPermissions();

    if (!userProjectPermissions) return undefined;

    return userProjectPermissions.some(
      (permission) =>
        permission.element === ProcoreElements.PunchItems &&
        permission.accessType !== ProcoreUserAccessLevels['None']
    );
  });

  readonly hasUserPunchItemPermissions$ = toObservable(
    this.hasUserPunchItemPermissions
  );
}
