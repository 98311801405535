import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Signal,
  computed,
  inject,
  input,
  output
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DesignRadioAccordionModule } from '@simlab/design/accordion';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { UiHelperModule } from '@simlab/ui/helper';
import { UiMenuModule } from '@simlab/ui/menu';

import { SystemOfMeasurement } from '@simlab/data-access';
import { UiTooltip } from '@simlab/design/tooltip';
import {
  TAreaMeasurement,
  TMeasurementExtended
} from '@simlab/feature-measurement/models';
import {
  AREA_CONVERSION,
  UnitsAreaPreferredByUserPipe
} from '@simlab/feature-measurement/pipes';
import { AreaFacade } from '@simlab/feature-measurement/state';
import { MeasurementPermission } from '../../../services/measurement.privileges';
import { MeasurementItemComponent } from '../measurement-item/measurement-item.component';

@Component({
  selector: 'feature-area-measurement-attachment',
  standalone: true,
  imports: [
    CommonModule,
    DesignIconButton,
    UiMenuModule,
    UiMenuPanelModule,
    DesignRadioAccordionModule,
    UnitsAreaPreferredByUserPipe,
    UiTooltip,
    UiHelperModule,
    MeasurementItemComponent,
    DesignIcon
  ],
  templateUrl: './area-measurement-note-attachment.component.html',
  styleUrls: ['./area-measurement-note-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnitsAreaPreferredByUserPipe]
})
export class AreaMeasurementAttachmentComponent
  extends MeasurementPermission
  implements OnInit
{
  ngOnInit(): void {
    this._measurementFacade.init(this.stageId());
  }
  private readonly _measurementFacade = inject(AreaFacade);
  private readonly _unitsPreferredByUserPipe = inject(
    UnitsAreaPreferredByUserPipe
  );
  private readonly _measurements: Signal<TAreaMeasurement[]> = toSignal(
    this._measurementFacade.allMeasurement$
  ) as Signal<TAreaMeasurement[]>;

  readonly systemOfMeasurement = input<SystemOfMeasurement>();
  readonly stageId = input.required<string>();
  readonly noteId = input<string>();
  readonly goToAreaMeasurement = output<void>();

  get measurements() {
    return this._measurements();
  }

  readonly extendedMeasurements: Signal<TMeasurementExtended[]> = computed(
    () => {
      const noteId = this.noteId();
      if (!noteId) return [];
      const units = this.systemOfMeasurement() || SystemOfMeasurement.Metric;
      const extendedMeasurements =
        this.measurements
          .filter((measurement) => measurement.rootNoteId === noteId)
          .map((measurement) => {
            return {
              ...measurement,
              displayValue: `${this._unitsPreferredByUserPipe
                .transform(measurement.data.surfaceSize, units)
                .toFixed(4)} ${AREA_CONVERSION[units].unit}`
            };
          }) || [];

      return extendedMeasurements;
    }
  );
  areaMeasurementsId(_: number, item: TMeasurementExtended) {
    return item.id;
  }

  edit(area: TMeasurementExtended) {
    this._measurementFacade.selectedMeasurement(area.id);
    this.goToAreaMeasurement.emit();
  }
  unassignFromNote({ id }: TMeasurementExtended) {
    this._measurementFacade.unassignMeasurementFromNote({
      id
    });
  }
}
