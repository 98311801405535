export interface IChipItem {
  key: TChipCategory;
  displayValue: string | number;
  value: string | number;
}

export type TChipCategory =
  | 'Author'
  | 'Stakeholder'
  | 'Assignee'
  | 'Stage'
  | 'Created'
  | 'Modified'
  | 'NoteType'
  | 'NoteStatus'
  | 'Punch'
  | 'PunchBallInCourt'
  | 'PunchDueDate'
  | 'RFI'
  | 'RFIBallInCourt'
  | 'RFIDueDate';

export const CHIP_CATEGORY = {
  Author: $localize`:@@AUTHOR:Author`,
  Stakeholder: $localize`:@@STAKEHOLDER:Stakeholder`,
  Assignee: $localize`:@@ASSIGNEE:Assignee`,
  Stage: $localize`:@@STAGE:Stage`,
  Created: $localize`:@@DATE_CREATION:Date creation`,
  Modified: $localize`:@@DATE_MODIFICATION:Date modification`,
  NoteType: $localize`:@@NOTE:Note`,
  NoteStatus: $localize`:@@NOTE:Note`,
  Punch: $localize`:@@PUNCH:Punch`,
  PunchDueDate: $localize`:@@PUNCH_DUE_DATE:Punch due date`,
  PunchBallInCourt: $localize`:@@PUNCH_BALL_IN_COURT:Punch ball in court`,
  RFI: $localize`:@@RFI:RFI`,
  RFIDueDate: $localize`:@@RFI_DUE_DATE:Rfi due date`,
  RFIBallInCourt: $localize`:@@RFI_BALL_IN_COURT:RFI ball in court`
} satisfies Record<TChipCategory, string>;
