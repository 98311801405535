<div class="add-comment__wrapper">
  <design-mention
    #mention
    i18n-placeholder="@@WRITE_A_COMMENT"
    placeholder="Write a comment"
    externalTagName="user-link"
    [mentionData]="mentionData"
    [readonly]="!enableAddComment"
    (enterPressed)="emitComment(mention)"
    (textContent)="commentLength.set($event.length)"
  />
  <button
    class="add-comment__button"
    *ngIf="enableAddComment"
    #sendCommentButton
    ui-icon-button
    (click)="emitComment(mention)"
    [disabled]="isMaxLengthErrorVisible()"
  >
    <i ui-font fontName="send" fontSize="1rem" class="cursor"></i>
  </button>
</div>

@if (isMaxLengthErrorVisible()) {
  <p class="add-comment__error" i18n="@@MAXIMUM_COMMENT_LENGTH_INTERPOLATION">
    Maximum length of comment is {{ maxLength() }}.
  </p>
}
