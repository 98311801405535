import { computed, inject, Injectable } from "@angular/core";
import { toSignal } from '@angular/core/rxjs-interop';
import { NotesFacade, StagesFacade } from "@simlab/data-store";
import { PanelRightService } from "@simlab/feature/stages";
import { StageFiltersService } from "@simlab/util-shared";
import { AnnotationAction, AnnotationActionsBase } from 'common/annotation-panel/models';
import { map } from "rxjs";
import { StageSidePanelComponent } from '../stage-side-panel.component';
import { mixinCopyNoteUrl } from "./stage-side-panel-copy-note-ul-action";
import { mixinDeleteNote } from './stage-side-panel-delete-note-action';
import { mixinToggleNoteNotification } from './stage-side-panel-toggle-note-notification-action';
import { mixinTransferNote } from './stage-side-panel-transfer-note-action';



@Injectable()
export class StageSidePanelAction implements AnnotationActionsBase {
  private readonly _panelRightService = inject(PanelRightService);
  private readonly _parent = inject(StageSidePanelComponent);
  private readonly _noteFacade = inject(NotesFacade);
  private readonly _stageFiltersService = inject(StageFiltersService);

  private readonly _actions = inject(StagesSidePanelActions)

  readonly actionList: AnnotationAction[] = [
    {
      name: $localize`:@@CHANGE_TITLE:Change title`,
      trigger: () => this._parent.changeTitle(),
      disabled: toSignal(this._panelRightService.renameAndChangeDescriptionNote$.pipe(map((renameAndChangeDescriptionNote) => !renameAndChangeDescriptionNote)))
    },
    {
      name:  $localize`:@@TRANSFER_NOTE:Transfer note`,
      trigger: () => this._actions.transferNote(this.closePanel),
      disabled: toSignal(this._panelRightService.renameAndChangeDescriptionNote$.pipe(map((renameAndChangeDescriptionNote) => !renameAndChangeDescriptionNote)))
    },
    {
      name: computed(() => this._parent.selectedNote()?.notificationsEnabled ?  $localize`:@@TURN_OFF_EMAIL_NOTIFICATION:Turn off email notification` : $localize`:@@TURN_ON_EMAIL_NOTIFICATION:Turn on e-mail notification`),
      trigger: () => this._actions.toggleNoteNotification(),
      disabled: toSignal(this._panelRightService.hasAdminRoleInProject$.pipe(map((hasAdminRoleInProject) => !hasAdminRoleInProject)))
    },
    {
      name: $localize`:@@DELETE_NOTE:Delete note`,
      trigger: () => this._actions.deleteNote(),
      disabled: toSignal(this._panelRightService.deleteNote$.pipe(map((deleteNote) => !deleteNote)))
    },
    {
      name: $localize`:@@COPY_URL:Note's url`,
      trigger: () => this._actions.copyUrl()
    }
  ];

  closePanel = () => {
    this._noteFacade.selectedNoteId(undefined);
    this._parent.closeAction.next();
    this._stageFiltersService.applyFilters();
  };
}

const _StageSidePanelActionsBase = mixinTransferNote(mixinToggleNoteNotification(mixinDeleteNote(mixinCopyNoteUrl(StagesFacade))));

@Injectable()
export  class StagesSidePanelActions extends _StageSidePanelActionsBase {}
