import { Directive, inject, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  DocumentsPlatformKeyEventsService,
  DocumentsStateService
} from '@simlab/documents/services';
import {
  defer,
  fromEvent,
  map,
  merge,
  Observable,
  startWith,
  tap,
  withLatestFrom
} from 'rxjs';
import { TSelectableItem } from '../can-select/can-select.directive';

@Directive()
export abstract class DocumentsSelection {
  private _prevSelectedIndex = -1;
  private _prevSelectedItems: TSelectableItem<any>[] = [];

  private readonly _keyEvents = inject(DocumentsPlatformKeyEventsService);
  private readonly _windowBlurEvent$ = fromEvent(window, 'blur').pipe(
    startWith(undefined),
    map((event) => ({ ...event, shift: false, ctrl: false }))
  );
  protected abstract readonly state: DocumentsStateService;
  abstract readonly selectableItems: Signal<TSelectableItem<any>[]>;
  abstract readonly selection$: Observable<TSelectableItem<any>>;

  protected selectionObserver = defer(() => {
    return this.selection$.pipe(
      withLatestFrom(merge(this._keyEvents.keyStates$, this._windowBlurEvent$)),
      tap(([selected, pressedKey]) => {
        if (pressedKey?.shift) {
          this._selectGroupOfDocuments(selected.id);
          return;
        }

        this._prevSelectedItems = [];
        this.selectableItems().forEach((option, index) => {
          const id = option.id;
          const isSelected = selected.id === id;

          if (isSelected) this._prevSelectedIndex = index;

          if (pressedKey?.ctrl) {
            if (isSelected) option.toggle();
            return;
          }
          isSelected ? option.select() : option.deselect();
        });
      }),
      tap(() => this._setSelected()),
      takeUntilDestroyed()
    );
  });

  private _setSelected() {
    const selectedIds = this.selectableItems()
      .filter((item) => item.selected())
      .map(({ id }) => id!);
    this.state.setSelectedIds(selectedIds);
  }

  private _selectGroupOfDocuments(selectedId: string | undefined) {
    const { sliceFrom, sliceTo } = this._selectableRangeVariables(selectedId);
    const itemsToSelect = this.selectableItems().slice(sliceFrom, sliceTo);

    this._prevSelectedItems.forEach((item) => item.deselect());
    itemsToSelect.forEach((option) => option.select());

    this._prevSelectedItems = itemsToSelect;
  }

  private _selectableRangeVariables(selectedId: string | undefined) {
    const currSelectedIndex = this.selectableItems().findIndex(
      (option) => option.id === selectedId
    );

    const sliceFrom =
      this._prevSelectedIndex < currSelectedIndex
        ? this._prevSelectedIndex
        : currSelectedIndex;
    const sliceTo =
      currSelectedIndex > this._prevSelectedIndex
        ? currSelectedIndex
        : this._prevSelectedIndex;

    return { sliceFrom, sliceTo: sliceTo + 1 };
  }
}
