@if (thumbnailUrlWithoutSvg() !== undefined) {
  <img
    #imgRef
    [ngSrc]="thumbnailUrlWithoutSvg()!"
    priority
    fill
    [alt]="name()"
    class="card__image"
  />
  @if (!isImageLoaded()) {
    <ng-container
      *ngTemplateOutlet="
        thumbnailView;
        context: {
          $implicit: name(),
          type: documentType()
        }
      "
    ></ng-container>
  }
} @else {
  <ng-container
    *ngTemplateOutlet="
      thumbnailView;
      context: { $implicit: name(), type: documentType() }
    "
  ></ng-container>
}

<ng-template #thumbnailView let-name let-type="type">
  <i
    ui-icon
    [name]="(name | documentsThumbnailIconGetter: type | async)!"
    defaultColor
    class="card__thumbnail"
  ></i>
</ng-template>
