import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { ProjectPaging } from '@simlab/data-access';
import { DtoNameId } from '@simlab/design/breadcrumb';
import { ScrollObserverService } from '@simlab/design/layout';
import { SortModel } from '@simlab/design/sort-switch';
import { documentsApiToken } from '@simlab/documents/data-access';
import {
  DirectorySearchLevelEnum,
  DirectorySearchLevelEnumValues,
  DocumentModel,
  DocumentStates,
  DocumentsFetchQuery,
  ExternalTransferStatusEnum,
  LoadedState
} from '@simlab/documents/models';
import {
  animationFrameScheduler,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  merge,
  pipe,
  switchMap,
  tap
} from 'rxjs';

export type EmptyObject = Record<string, never>;
export type DocumentsFilters = Record<string, any> | EmptyObject;

const INIT_PAGING_STATE = { pageSize: 20, pageNumber: 1 } as const;
const CONTAINER_ID = 'documents-container' as const;

type State = {
  projectId: string;
  data: DocumentStates[];
  breadcrumbs: DtoNameId[] | undefined;
  selectedIds: string[];
  directoryId: string | null;
  directorySearchLevel: DirectorySearchLevelEnumValues;
  searchInput: string;
  isLoading: boolean;
  isFetching: boolean;
  sort: SortModel | EmptyObject;
  filters: DocumentsFilters;
  page: ProjectPaging;
  fetchDataLoaded: boolean;
};

const initialState: State = {
  projectId: '',
  data: [],
  breadcrumbs: [],
  directoryId: null,
  selectedIds: [],
  directorySearchLevel: DirectorySearchLevelEnum.Directory,
  searchInput: '',
  filters: {},
  isLoading: true,
  isFetching: true,
  sort: {},
  page: INIT_PAGING_STATE,
  fetchDataLoaded: false
};

function mappedRawData(rawData: DocumentModel[]): DocumentStates[] {
  return rawData.map((item): DocumentStates => {
    if ('transferStatus' in item) {
      return {
        state:
          item.transferStatus === ExternalTransferStatusEnum.Processing
            ? 'UPLOADING'
            : 'LOADED',
        data: item
      };
    }
    return { state: 'LOADED', data: item };
  });
}

function isFilterMode(filters: DocumentsFilters): boolean {
  return Object.values(filters).some(
    (value) =>
      (Array.isArray(value) && value.length > 0) ||
      (!Array.isArray(value) && !!value)
  );
}

function getDirectorySearchLevelBy(isFilterMode: boolean) {
  return isFilterMode
    ? DirectorySearchLevelEnum.All
    : DirectorySearchLevelEnum.Directory;
}

export const DocumentsStore = signalStore(
  withState(initialState),
  withComputed((store) => ({
    documentsFetchQuery: computed(() => {
      return {
        projectId: store.projectId(),
        page: {
          pageSize: store.page().pageSize,
          pageNumber: store.page().pageNumber
        },
        filter: {
          ...store.filters(),
          name: store.searchInput(),
          directoryId: store.directoryId(),
          directorySearchLevel: store.directorySearchLevel()
        },
        isAscending: store.sort().isAscending ?? null,
        sortColumn: store.sort().sortColumn ?? null
      } as DocumentsFetchQuery;
    }),
    isFilterMode: computed(() =>
      isFilterMode({ ...store.filters(), name: store.searchInput() })
    )
  })),
  withMethods(
    (
      store,
      api = inject(documentsApiToken),
      router = inject(Router),
      scrollObserver = inject(ScrollObserverService)
    ) => ({
      updateProjectId(projectId: string): void {
        patchState(store, { projectId });
      },
      updateFolderId(directoryId: string | null): void {
        const resetFilters = Object.keys(store.filters()).reduce(
          (obj, key) => {
            obj[key] = [];

            return obj;
          },
          {} as Record<string, any>
        );

        patchState(store, (data) => ({
          ...data,
          directoryId: directoryId,
          directorySearchLevel: DirectorySearchLevelEnum.Directory,
          filters: resetFilters,
          searchInput: '',
          data: [],
          page: INIT_PAGING_STATE,
          isLoading: true,
          selectedIds: []
        }));
      },
      updateSearchName(name: string): void {
        router.navigate([], {
          queryParams: { folderId: null },
          queryParamsHandling: 'merge',
          state: { rerender: false }
        });

        if (name === '') {
          patchState(store, (data) => {
            const isFilterModeComputed = isFilterMode({
              ...store.filters(),
              name
            });

            return {
              ...data,
              searchInput: name,
              directoryId: null,
              directorySearchLevel:
                getDirectorySearchLevelBy(isFilterModeComputed),
              page: INIT_PAGING_STATE,
              sort: {},
              data: [],
              isLoading: true,
              selectedIds: []
            };
          });
          return;
        }

        patchState(store, () => ({
          searchInput: name,
          directoryId: null,
          directorySearchLevel: getDirectorySearchLevelBy(!!name.length),
          page: INIT_PAGING_STATE,
          sort: {},
          data: [],
          isLoading: true,
          selectedIds: []
        }));
      },
      setFilters(newFilters: Record<string, any>): void {
        patchState(store, (data) => {
          const accFilters = { ...data.filters, ...newFilters };

          return {
            ...data,
            filters: accFilters,
            directoryId: null,
            directorySearchLevel: getDirectorySearchLevelBy(
              isFilterMode(accFilters)
            ),
            page: INIT_PAGING_STATE,
            sort: {},
            data: [],
            isLoading: true,
            selectedIds: []
          };
        });
      },
      updatePage(): void {
        patchState(store, ({ page }) => {
          return {
            page: { pageSize: page.pageSize, pageNumber: page.pageNumber + 1 },
            isFetching: true
          };
        });
      },
      setSort(sort: SortModel | EmptyObject): void {
        patchState(store, {
          sort,
          page: INIT_PAGING_STATE,
          data: [],
          isLoading: true
        });
      },
      setSelected(selectedIds: string[] | string): void {
        patchState(store, {
          selectedIds: Array.isArray(selectedIds) ? selectedIds : [selectedIds]
        });
      },

      reloadDataWithCurrentSettings() {
        const state = store.documentsFetchQuery();
        this.refreshData(state);
      },

      moveTo(ids: string[], path: DtoNameId[]) {
        patchState(store, ({ data }) => {
          if (store.isFilterMode()) {
            const newData = data.map((ele) => {
              if (ids.includes(ele.data.id)) {
                return {
                  ...ele,
                  data: {
                    ...ele.data,
                    path
                  }
                };
              }
              return ele;
            });
            return { data: newData };
          } else {
            const newData = data.filter((e) => !ids.includes(e.data.id));
            return { data: newData };
          }
        });
      },

      //#region Update Local Data
      addItem: (item: DocumentModel) => {
        const addItem: LoadedState<DocumentModel> = {
          state: 'LOADED',
          data: item
        };

        patchState(store, ({ data }) => ({
          data: [addItem, ...data]
        }));
      },
      renameElement(id: string, name: string): void {
        patchState(store, ({ data }) => {
          const newData = data.map((element) => {
            if (element.data.id === id) {
              element.data.name = name;
            }
            return { ...element };
          });
          return {
            data: newData
          };
        });
      },
      deleteItems(ids: string[]): void {
        patchState(store, ({ data }) => {
          const newData = data.filter((e) => !ids.includes(e.data.id));
          return {
            data: newData
          };
        });
      },
      //#endregion

      refreshData: rxMethod<DocumentsFetchQuery>(
        pipe(
          concatMap((dataQuery) => {
            return api.fetch$(dataQuery).pipe(
              tapResponse({
                next: ({ documents: { items }, path }) => {
                  patchState(store, {
                    data: mappedRawData(items),
                    breadcrumbs: path
                  });
                },
                error: (error: HttpErrorResponse) => {
                  console.error('An error occurred: ', error.message);
                },
                finalize: () => {
                  patchState(store, {
                    isLoading: false,
                    fetchDataLoaded: true
                  });
                }
              })
            );
          })
        )
      ),

      setDataLoaded: rxMethod<void>(
        pipe(
          switchMap(() =>
            toObservable(store.fetchDataLoaded).pipe(
              tapResponse({
                next: () => {
                  animationFrameScheduler.schedule(() =>
                    scrollObserver.check()
                  );
                  patchState(store, { fetchDataLoaded: false });
                },
                error: (error: HttpErrorResponse) => {
                  console.error('An error occurred: ', error.message);
                }
              })
            )
          )
        )
      ),
      autoFetch: rxMethod<void>(
        pipe(
          concatMap(() =>
            toObservable(store.documentsFetchQuery).pipe(
              concatMap((dataQuery) => {
                let _hasNext = false;
                return api.fetch$(dataQuery).pipe(
                  tapResponse({
                    next: ({ documents: { items, hasNext }, path }) => {
                      _hasNext = hasNext;
                      patchState(store, ({ data }) => {
                        return {
                          data: [...data, ...mappedRawData(items)],
                          breadcrumbs: path
                        };
                      });
                    },
                    error: (error: HttpErrorResponse) => {
                      console.error('An error occurred: ', error.message);
                    },
                    finalize: () => {
                      patchState(store, {
                        isLoading: false,
                        isFetching: false
                      });
                      if (!_hasNext) return;
                      patchState(store, { fetchDataLoaded: true });
                    }
                  })
                );
              })
            )
          )
        )
      )
    })
  ),
  withHooks({
    onInit(
      store,
      activatedRoute = inject(ActivatedRoute),
      scrollObserver = inject(ScrollObserverService),
      router = inject(Router)
    ) {
      //DO PR, czy zostawić to w tym miejscu, czy ewentualnie przenieść wyżej
      merge(
        scrollObserver.scrolledDown$({ containerId: CONTAINER_ID }),
        scrollObserver.isNotScrollableContent$(CONTAINER_ID)
      )
        .pipe(
          filter(() => !store.isFetching()),
          tap(() => store.updatePage()),
          takeUntilDestroyed()
        )
        .subscribe();

      activatedRoute.params
        .pipe(
          map((params) => params['projectId'] as string),
          distinctUntilChanged(),
          takeUntilDestroyed()
        )
        .subscribe((id) => {
          if (!id) return;
          store.updateProjectId(id);
        });

      activatedRoute.queryParams
        .pipe(
          map((params) => (params['folderId'] ?? null) as string | null),
          filter(
            () =>
              router.getCurrentNavigation()?.extras.state?.['rerender'] !==
              false
          ),
          takeUntilDestroyed()
        )
        .subscribe((id) => {
          store.updateFolderId(id || '');
        });
      store.autoFetch();
      store.setDataLoaded();
    }
  })
);
