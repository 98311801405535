import { Dialog } from '@angular/cdk/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  inject,
  input
} from '@angular/core';
import { Router } from '@angular/router';
import { ProcoreConnectionStatusEnum, Project } from '@simlab/data-access';
import { ProjectsFacade } from '@simlab/data-store';
import { DesignFlatButton, DesignIconButton } from '@simlab/design/button';
import { DesignChip } from '@simlab/design/chip';
import { DesignCommonModule, DesignLabel } from '@simlab/design/common';
import { FormatDatePipe } from '@simlab/design/format-date';
import { DesignIcon } from '@simlab/design/icon';
import type { ModalComponent, ModalData } from '@simlab/modal/ui';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  TActionNameOnCloseModal,
  TActionOnCloseModal,
  TProcoreProjectIntegration
} from '@simlab/procore/models';
import {
  LinkedProjectsInformationModalComponent,
  ProjectCard,
  ProjectIntegrationComponent,
  TLinkedProjectsInformationData
} from '@simlab/procore/ui';
import { UiButtonModule } from '@simlab/ui/button';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiIconModule } from '@simlab/ui/icon';
import {
  ConfirmationModalRef,
  MODAL_DATA,
  ModalService
} from '@simlab/ui/modal';
import { UiProgressSpinnerModule } from '@simlab/ui/progress-spinner';
import { ENVIRONMENT_CONFIG } from '@simlab/util-shared';
import {
  EMPTY,
  Subject,
  exhaustMap,
  filter,
  firstValueFrom,
  from,
  fromEvent,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import ProjectInfoDialogComponent, {
  ProjectInfoDialogResponse,
  TProjectInfoBase
} from '../project-info-dialog/project-info-dialog.component';

export const ASPECT_16_9 = 16 / 9;

const translation = {
  importProcoreModalTitle: $localize`:@@IMPORT_PROCORE_MODAL_TITLE:Import Procore data?`,
  importProcoreModalMessage: $localize`:@@IMPORT_PROCORE_MODAL_MESSAGE:The project that has been connected has data that can be imported. The data will be automatically distributed by date to the appropriate stages. This can be done now or later from the context menu on this tab`
};

@Component({
  selector: 'feature-projects-grid-container-item',
  templateUrl: './grid-container-item.component.html',
  styleUrls: ['./grid-container-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UiDividerModule,
    UiProgressSpinnerModule,
    UiButtonModule,
    UiIconModule,
    //NEW
    DesignChip,
    DesignLabel,
    DesignFlatButton,
    DesignIconButton,
    DesignIcon,
    DesignCommonModule,
    FormatDatePipe
  ]
})
export class GridContainerItemComponent implements AfterViewInit, OnDestroy {
  private readonly _elementRef = inject(ElementRef);
  private readonly _dialog = inject(Dialog);
  private readonly _projectsFacade = inject(ProjectsFacade);
  private readonly _envi = inject(ENVIRONMENT_CONFIG);
  private readonly _router = inject(Router);
  private readonly _scrollStrategyOptions = inject(ScrollStrategyOptions);
  private readonly _api = inject(ApiProcoreService);
  private readonly _modalService = inject(ModalService);
  private readonly _destroySource: Subject<void> = new Subject<void>();
  readonly scrollStrategyClose = this._scrollStrategyOptions.close();

  readonly item = input.required<Project>();
  readonly canConnectProcore = input.required<boolean>();

  overlayActive = false;

  ngAfterViewInit(): void {
    //TODO: (david) add orientation/size change detection???
    //this.applyThumbnailHeight();

    fromEvent(window, 'resize')
      .pipe(takeUntil(this._destroySource))
      .subscribe(() => {
        this.applyThumbnailHeight();
      });
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  private applyThumbnailHeight(): void {
    const hostElement = this._elementRef.nativeElement as HTMLElement;
    hostElement.style.setProperty(
      '--current-height',
      `${hostElement.offsetWidth / ASPECT_16_9}px`
    );
  }

  get procoreProject(): boolean {
    return (
      this.item().procoreConnectionStatus ===
      ProcoreConnectionStatusEnum.Connected
    );
  }

  get thumbnail(): string | undefined {
    return this.item().projectThumbnailUrlWithSas
      ? this.item().projectThumbnailUrlWithSas.urlWithSas
      : undefined;
  }

  get markedAsFavourite(): boolean {
    return this.item().markedAsFavourite;
  }

  toggleFavorite(): void {
    this._projectsFacade.setProjectFavoriteState(
      this.item().id,
      !this.item().markedAsFavourite
    );
  }

  navigateToProject(): void {
    this._router.navigate([`project/${this.item().id}/stages`]);
  }

  async openProjectInfo(): Promise<void> {
    const res = await firstValueFrom(
      this._openProjectInfoDialog().events$.pipe(
        take(1),
        switchMap(({ state, result }) => {
          if (state && result?.action === 'connectProcore') {
            return this._openProcoreConnectDialog(result.data).closed.pipe(
              take(1),
              tap((res) => {
                if (res?.action === 'BackTo') {
                  this.openProjectInfo();
                }
              }),
              filter((data) => !!data && data.state),
              mergeMap(() =>
                this._checkIfIsAnythingToImportFromProcore$(result.data.id)
              )
            );
          } else if (state && result?.action === 'detailConnection') {
            return this._openProcoreConnectDetailsDialog(
              result.data
            ).closed.pipe(take(1));
          }
          return EMPTY;
        })
      )
    );
    if (res === 'BackTo') this.openProjectInfo();
  }

  private _checkIfIsAnythingToImportFromProcore$(projectId: string) {
    return this._api.isAnythingToImportFromProcore(projectId).pipe(
      filter((is) => is),
      mergeMap(() => this._importProcoreData()),
      filter((isImportConfirm) => !!isImportConfirm),
      mergeMap(() => this._api.importProcoreData(projectId))
    );
  }

  private _importProcoreData() {
    return from(import('@simlab/modal/ui')).pipe(
      exhaustMap(
        ({ ModalComponent }) =>
          this._dialog.open<boolean, ModalData, ModalComponent>(
            ModalComponent,
            {
              data: {
                type: 'inform',
                title: translation.importProcoreModalTitle,
                message: translation.importProcoreModalMessage
              },
              width: 'min(90%, 610px)',
              disableClose: true
            }
          ).closed
      )
    );
  }

  private _openProcoreConnectDetailsDialog({
    companyName,
    name,
    procoreInfo
  }: TProjectInfoBase) {
    const stagesProject: ProjectCard = {
      logoUrl: 'assets/icons/logo_text.svg',
      companyName: companyName,
      projectName: name,
      status: 'CONNECTED'
    };
    const procoreProject: ProjectCard = {
      logoIcon: 'icon_procore_logo',
      companyName: procoreInfo?.companyName ?? 'unknown',
      projectName: procoreInfo?.projectName ?? 'unknown',
      status: 'CONNECTED'
    };

    const data: TLinkedProjectsInformationData = {
      status: 'CONNECTED',
      projects: [stagesProject, procoreProject]
    };

    return this._dialog.open<
      TActionNameOnCloseModal,
      TLinkedProjectsInformationData,
      LinkedProjectsInformationModalComponent
    >(LinkedProjectsInformationModalComponent, {
      width: 'min(90%, 850px)',
      maxHeight: 'min(90%, 850px)',
      panelClass: 'cdk-modal-overflow',
      autoFocus: false,
      data
    });
  }

  private _openProcoreConnectDialog(data: {
    id: string;
    name: string;
    companyName: string;
  }) {
    return this._dialog.open<
      TActionOnCloseModal,
      TProcoreProjectIntegration,
      ProjectIntegrationComponent
    >(ProjectIntegrationComponent, {
      width: 'min(90%, 850px)',
      maxHeight: 'min(90%, 850px)',
      panelClass: 'cdk-modal-overflow',
      autoFocus: false,
      disableClose: true,
      data
    });
  }

  private _openProjectInfoDialog(): ConfirmationModalRef<ProjectInfoDialogResponse> {
    return this._modalService.createModalWithProviders(
      ProjectInfoDialogComponent,
      {
        maxHeight: '90%',
        width: 'min(90%, 850px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: this.item()
        }
      ]
    );
  }
}
