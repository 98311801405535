<div class="image-wrapper">
  <img
    class="image-wrapper__image"
    src="assets/ui/images/no_access_white_background.svg"
  />
</div>

<div class="dialog-header-container" i18n="@@PU_LIMIT_REACHED">
  Limit reached
</div>

<div class="dialog-container" i18n="@@PU_LIMIT_REACHED_INFO">
  You cannot activate the project because its content exceeds the limits of your
  current subscription plan. Please upgrade your plan to continue.
</div>

<div class="dialog-action-container">
  <button
    ui-flat-button
    class="button-cancel"
    uiColor="primary"
    (click)="cancel()"
  >
    <div i18n="@@CANCEL">Cancel</div>
  </button>
  <button
    ui-flat-button
    class="button-get-it"
    uiColor="primary"
    (click)="confirmDialog()"
  >
    <div i18n="@@PU_CHECK_YOUR_PLAN">Check Your Plan</div>
  </button>
</div>
