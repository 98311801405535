import { Vector3 } from 'three';
import {
  TMeasurement,
  TMeasurementData,
  TVerticle
} from '../models/src/common-models';
import { SystemOfMeasurement } from '../models/src/system-measurement';
import { UnitsPreferredByUserPipe } from '../pipes/src/lib/base-units-preferred.pipe';
import { AREA_CONVERSION } from '../pipes/src/lib/units-preferred-by-the-user-area.pipe';
import { DISTANCE_CONVERSION } from '../pipes/src/lib/units-preferred-by-the-user-distance.pipe';

export function displayAreaMeasurementValue(
  value: TMeasurement,
  units: SystemOfMeasurement,
  unitsPreferredByUserPipe: UnitsPreferredByUserPipe
) {
  if (!value) return '';

  const surfaceSize = (value.data as TMeasurementData).surfaceSize;
  const transformedValue = unitsPreferredByUserPipe.transform(
    surfaceSize,
    units
  );
  const formattedValue = transformedValue.toFixed(4);
  const unit = AREA_CONVERSION[units].unit;

  return `${formattedValue} ${unit}`;
}

export function displayDistanceMeasurementValue(
  value: TMeasurement,
  units: SystemOfMeasurement,
  unitsPreferredByUserPipe: UnitsPreferredByUserPipe
) {
  if (!value) return '';

  const data = value.data as TVerticle[];
  let sum = 0;
  for (let index = 0; index < data.length - 1; index++) {
    sum += distanceBetweenVectors(data[index], data[index + 1]);
  }

  return `${unitsPreferredByUserPipe
    .transform(sum, units)
    .toFixed(4)} ${DISTANCE_CONVERSION[units].segmentUnit}`;
}

function distanceBetweenVectors(
  vector1: TVerticle,
  vector2: TVerticle
): number {
  const point = new Vector3(vector1.x, vector1.y, vector1.z);
  return new Vector3(vector2.x, vector2.y, vector2.z).distanceTo(point);

  return Math.sqrt(
    Math.pow(vector1.x - vector2.x, 2) +
      Math.pow(vector1.y - vector2.y, 2) +
      Math.pow(vector1.z - vector2.z, 2)
  );
}
