<ng-container *ngIf="alreadyUsed$ | async; let usedTags">
  <ng-container *ngIf="tags$ | async; let tags; else: loading">
    <ng-container *ngIf="tags.length; else emptyTagList">
      <ui-checkbox
        class="select-all-checkbox"
        [formControl]="selectAllControl"
        type="customize"
      >
        <label i18n="@@SELECT_ALL_TAGS">Select All Tags</label>
      </ui-checkbox>
      <ui-tree class="tag-list padding" [formGroup]="tagsForm">
        <cdk-tree [dataSource]="tags" [treeControl]="treeControl" #tree>
          <cdk-nested-tree-node *cdkTreeNodeDef="let node">
            <feature-stages-tag
              [disabled]="usedTags.includes(node.id)"
              [tagsFormGroupRef]="tagsForm"
              [tag]="node"
              (tagClick)="moveToTag($event)"
            ></feature-stages-tag>
          </cdk-nested-tree-node>
          <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild">
            <div class="tree-header" cdkTreeNodeToggle>
              <i
                designIcon="arrow_big"
                designSize="1.25rem"
                class="arrow"
                [class.expanded]="treeControl.isExpanded(node)"
              ></i>
              {{ node.label }}
            </div>
            <div [class.invisible]="!treeControl.isExpanded(node)">
              <ng-container
                cdkTreeNodeOutlet
                *ngIf="treeControl.isExpanded(node)"
              ></ng-container>
            </div>
          </cdk-nested-tree-node>
        </cdk-tree>
      </ui-tree>
    </ng-container>
    <ng-template #emptyTagList>
      <div class="tag-list padding-top-bottom">
        <span i18n="@@EMPTY_TAG_LIST_INFO">
          The list is empty or there is no item that meets the filter criteria
        </span>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loading>
    <div class="tag-list padding-top-bottom second-text">
      <p i18n="@@LOADING">loading...</p>
    </div></ng-template
  >
</ng-container>
