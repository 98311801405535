<section class="measurement-group-actions">
  <div class="measurement-group-actions__buttons">
    <ng-container>
      <design-tooltip
        i18n-tooltip="@@UNSUFFICIENT_PRIVILEGES"
        tooltip="Unsufficient privileges"
        position="left"
      >
        <ng-template [ngTemplateOutlet]="Create"></ng-template>
      </design-tooltip>
    </ng-container>

    <ng-template #Create>
      <button
        design-flat-button
        designButtonColor="accent"
        [disabled]="!canAddMeasurementGroup()"
        (click)="openCreateGroupModal()"
      >
        <i designIcon="folder" designSize="1.4rem"></i>

        <ng-container i18n="@@AREM_CREATE_GROUP">Create group</ng-container>
      </button>
    </ng-template>
    <button
      design-flat-button
      designButtonColor="secondary"
      (click)="calculator()"
    >
      <ng-container i18n="@@GEN_CALCULATOR">Calculator</ng-container>
    </button>
  </div>
  <div class="measurement-group-actions__filters">
    <feature-measurement-search-and-sort-filter
      [sortOptions]="sortOptions"
      class="measurement-group-actions__filters--component"
    />
  </div>
</section>
<ng-container *ngIf="filteredMeasurements()?.length; else noItems">
  <section class="measurement-group-list">
    <ng-container
      *ngFor="let group of filteredMeasurements(); trackBy: trackByGroupId"
    >
      <feature-stages-measurement-group-item
        [group]="group"
        [unit]="measurmentConversion[units].unit"
        (addMeasurementsToGroup)="
          addMeasurementsToGroupModal(group.id, group.creatorId)
        "
        (changeName)="openRenameGroupModal(group)"
        (deleteAreaMeasurementGroup)="deleteGroupModal(group)"
        (goToMeasurementList)="goToMeasurementList.emit($event)"
        (removeMeasurementFromGroup)="removeMeasurementFromGroup.emit($event)"
      ></feature-stages-measurement-group-item>
    </ng-container>
  </section>
</ng-container>

<ng-template #noItems>
  <ui-image-info
    class="image-info"
    imageSrc="icon_no_measurement"
    i18n-header="@@AREM_NO_GROUP"
    header="No measurement group"
    [descriptions]="descriptions"
    [maxWidth]="220"
  ></ui-image-info>
</ng-template>
