<ui-sidenav-container>
  <ui-sidenav
    #sidenav
    class="left-panel"
    mode="side"
    position="start"
    [opened]="(isOpen | async) && (hamburgerState$ | async)"
  >
    <feature-stages-tag-importer-left-panel
      *ngIf="isOpen | async"
      [scanId]="(scanId$ | async)!"
      [projectId]="(projectId$ | async)!"
    ></feature-stages-tag-importer-left-panel>
  </ui-sidenav>
  <ui-sidenav-content>
    <div
      class="importer-toolbar"
      *ngIf="selectedComponent$ | async; let component"
    >
      <div class="importer-toolbar__title" i18n="@@SELECTED_MATTER_SCAN_NAME">
        Selected Matterport scan: <b>{{ component.name }}</b>
      </div>
      <button design-flat-button designButtonColor="accent" (click)="close()">
        <div i18n="@@EXIT_IMPORTER">Exit Importer</div>
      </button>
    </div>
    <div class="matterport-container">
      <simlab-matterport></simlab-matterport>
    </div>
  </ui-sidenav-content>
</ui-sidenav-container>
