import { CdkTreeModule, NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  Inject,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormControl
} from '@angular/forms';
import { ApolloQueryResult } from '@apollo/client';
import { ApiFacadeService } from '@simlab/data-access';
import { StagesFacade } from '@simlab/data-store';
import { DesignIcon } from '@simlab/design/icon';
import { MATTERPORT_TOKEN } from '@simlab/feature/matterport';
import { NoAccessDialogComponent } from '@simlab/feature/projects';
import { MatterportService } from '@simlab/matterport';
import {
  GetModelTagsQuery,
  MatterportApiFacadeService,
  Mattertag,
  Maybe
} from '@simlab/matterport/api';
import { UiCheckboxModule } from '@simlab/ui/checkbox';
import {
  ConfirmationModalRef,
  MODAL_DATA,
  ModalService
} from '@simlab/ui/modal';
import { UiTreeModule } from '@simlab/ui/tree';
import { RouterStoreParams } from '@simlab/util-shared';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  defer,
  filter,
  firstValueFrom,
  forkJoin,
  map,
  of,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { SelectedColors } from '../tag-filters/tag-filters.component';
import { TagComponent } from '../tag/tag.component';

export type TagGroupedByFloor = {
  label?: Maybe<string> | undefined;
  id?: string;
  tags?: (Partial<Mattertag> & {
    tags?: Partial<Mattertag>;
  } & TagGroupedByFloor)[];
};
@Component({
  selector: 'feature-stages-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    UiCheckboxModule,
    UiTreeModule,
    CdkTreeModule,
    ReactiveFormsModule,
    TagComponent,
    DesignIcon
  ]
})
export class TagListComponent implements OnInit, DoCheck, OnDestroy {
  private _simlabIds: { [x: string]: string } | undefined;
  private _tagLimits: number | null = null;
  public get tagLimits(): number | null {
    return this._tagLimits;
  }
  public set tagLimits(value: number | null) {
    this._tagLimits = value;
  }
  @Input() set searchText(value: string) {
    this.selectAllControl.patchValue('');
    this._searchText.next(value);
  }
  @Input() selectedColors: SelectedColors = {};
  private _scanId: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);

  @Input() set scanId(scanId: string) {
    this._scanId.next(scanId);
  }
  get scanId() {
    return this._scanId.getValue() || '';
  }
  @Input() projectId!: string;
  readonly selectAllControl: UntypedFormControl = new UntypedFormControl('');
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private readonly _differ!: KeyValueDiffer<string, boolean>;
  private readonly _selectedColor: BehaviorSubject<{
    [color: string]: boolean;
  }> = new BehaviorSubject<{ [color: string]: boolean }>({});
  readonly alreadyUsed$: Observable<string[]> = defer(() =>
    this.refresh.asObservable().pipe(
      startWith(true),
      tap(() => {
        this.getNotesLimit();
        this._changeAll(false);
      }),
      switchMap(() =>
        this.apiFacadeService.projects.getUsedMattertags({
          projectId: this.projectId,
          scanId: this.scanId
        })
      ),
      tap((usedTags: string[]) => this._disableExistingControl(usedTags))
    )
  );
  readonly refresh: Subject<void> = new Subject<void>();
  private readonly _searchText: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  readonly tagsForm: FormGroup = new FormGroup({});
  readonly treeControl = new NestedTreeControl<TagGroupedByFloor>(
    (node) => node.tags
  );
  readonly mattertags$ = defer(() =>
    this._scanId.asObservable().pipe(
      filter((scanId: string | undefined) => scanId !== undefined),
      switchMap((scanId: string | undefined) => {
        if (!scanId) return of(undefined);
        return this.matterportApi.getTags$(scanId).pipe(
          tap((tags) => {
            firstValueFrom(
              this.matterport
                .loadMattertags$(tags.data.model?.mattertags as Mattertag[])
                .pipe(
                  map((simlabIds: string[]) => {
                    this._simlabIds = tags.data.model?.mattertags
                      ?.map((tag, idx) => ({ [tag.id]: simlabIds[idx] }))
                      .reduce((prev, curr) => ({ ...prev, ...curr }), {});
                    return tags;
                  })
                )
            );
          }),
          catchError((e) => {
            console.error(e);
            this.matterport.clearAllTagNotes();
            return of({ data: undefined });
          }),
          shareReplay(1)
        );
      }),
      catchError((e) => {
        console.error(e);
        return of(e);
      })
    )
  );
  readonly tags$: Observable<TagGroupedByFloor[]> = defer(() => {
    return combineLatest([
      this._searchText.asObservable(),
      this._selectedColor.asObservable()
    ]).pipe(
      switchMap((search: [string, { [color: string]: boolean }]) =>
        this.mattertags$.pipe(
          map((response: ApolloQueryResult<GetModelTagsQuery> | undefined) => {
            if (!response?.data) return [];
            let filtered: Partial<Mattertag>[] =
              (response.data.model?.mattertags?.filter((tag) =>
                tag.label?.toLowerCase().includes(search[0].toLowerCase())
              ) as Partial<Mattertag>[]) || [];
            if (Object.keys(search[1]).length) {
              filtered = filtered.filter((tag: Partial<Mattertag>) =>
                Object.keys(search[1]).includes(tag.color || '')
              );
            }
            return filtered as Partial<Mattertag>[];
          }),
          switchMap((response: Partial<Mattertag>[]) =>
            forkJoin([this.alreadyUsed$.pipe(take(1)), of(response)])
          ),
          tap((response: [string[], Partial<Mattertag>[]]) => {
            this._clearTagForm();
            response[1].forEach((tag) => {
              if (tag.id) {
                if (!this.tagsForm.get(tag.id)) {
                  this.tagsForm.addControl(
                    tag.id,
                    new FormControl<boolean>({
                      value: false,
                      disabled: response[0].includes(tag.id)
                    })
                  );
                }
              }
            });

            this.selectedTagObserver();
          }),
          map((response: [string[], Partial<Mattertag>[]]) => {
            const mappedResponse: TagGroupedByFloor[] = [];
            const grouped = response[1].reduce(
              (group: { [floor: string]: Partial<Mattertag>[] }, product) => {
                const floor = product.floor?.label || 'undefined';
                group[floor] = group[floor] ?? [];
                group[floor].push(product as Partial<Mattertag>);
                return group;
              },
              {}
            );
            if (grouped) {
              Object.keys(grouped).forEach((floorName: string) => {
                mappedResponse.push({
                  id: grouped[floorName][0].floor?.id || floorName,
                  label: floorName,
                  tags: grouped[floorName]
                });
              });
            }
            this.treeControl.dataNodes = mappedResponse;
            this.treeControl.expandAll();
            return mappedResponse;
          })
        )
      ),
      shareReplay(1)
    );
  });
  constructor(
    private readonly matterportApi: MatterportApiFacadeService,
    @Inject(MATTERPORT_TOKEN)
    private readonly matterport: MatterportService,
    private readonly apiFacadeService: ApiFacadeService,
    private readonly differs: KeyValueDiffers,
    private readonly modalService: ModalService,
    private readonly stagesFacade: StagesFacade
  ) {
    this._differ = this.differs.find(this.selectedColors).create();
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }
  ngDoCheck(): void {
    const changes = this._differ.diff(this.selectedColors);
    if (changes) {
      changes.forEachAddedItem(() => {
        this.selectAllControl.patchValue('');
        this._selectedColor.next(this.selectedColors);
      });
      changes.forEachRemovedItem(() => {
        this.selectAllControl.patchValue('');
        this._selectedColor.next(this.selectedColors);
      });
    }
  }

  ngOnInit(): void {
    this.getNotesLimit();
    this.toggleAllObserver();
  }

  private getNotesLimit(): void {
    firstValueFrom(
      this.stagesFacade.getRouteNestedParams$.pipe(
        switchMap((routerParams: RouterStoreParams) =>
          this.apiFacadeService.subscriptionService.getRemainingRootNoteLimitsForStage$(
            {
              projectId: routerParams.params['projectId'] as string
            }
          )
        ),
        tap((limit: number) => {
          this.tagLimits = limit;
        })
      )
    );
  }

  private _clearTagForm() {
    const controls = [...Object.keys(this.tagsForm.value)]; //.map((control:AbstractControl)=> control.)
    controls.forEach((key: string) => {
      this.tagsForm.removeControl(key);
    });
  }
  private _disableExistingControl(usedTags: string[]): void {
    for (let i = 0; i < usedTags.length; i++) {
      const control = this.tagsForm.get(usedTags[i]);
      if (control) {
        control.disable();
      }
    }
  }
  moveToTag(tagId: string): void {
    if (!this._simlabIds) return;

    this.matterport
      .moveToMattertag$(this._simlabIds[tagId])
      .pipe(take(1))
      .subscribe();
  }

  toggleAllObserver() {
    this.selectAllControl.valueChanges
      .pipe(takeUntil(this._destroySource))
      .subscribe((selectAll: boolean) => {
        this._changeAll(selectAll);
      });
  }

  selectedTagObserver(): void {
    this.tagsForm.valueChanges
      .pipe(
        startWith(this.tagsForm.value),
        pairwise(),
        tap(([prev, curr]) => {
          if (this.tagLimits !== null) {
            const curEntries = Object.entries(curr);
            const ele = curEntries.find(
              (element) => element[1] && element[1] !== prev[element[0]]
            );

            if (ele && this.tagLimits === 0) {
              this.tagsForm.get(ele[0])?.setValue(false);

              this.showNoAccessLimit();
            }

            const prevValues = Object.values(prev).filter((prev) => prev);
            const curValues = Object.values(curr).filter((curr) => curr);

            this.tagLimits =
              this.tagLimits - (curValues.length - prevValues.length);
          }
        }),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  private _changeAll(state: boolean) {
    try {
      Object.values(this.tagsForm.controls).forEach(
        (value: AbstractControl) => {
          if (!value.disabled) {
            if (this.tagLimits === 0 && state) {
              this.showNoAccessLimit();
              this.selectAllControl.setValue(false, { emitEvent: false });
              throw 'Limit of Notes';
            }
            value.patchValue(state);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  private showNoAccessLimit() {
    firstValueFrom(
      this.stagesFacade.getRouteNestedParams$.pipe(
        switchMap((routerStoreParams: RouterStoreParams) =>
          this.apiFacadeService.subscriptionService.getOrganizationIdByProjectId$(
            {
              projectId: routerStoreParams.params['projectId']
            }
          )
        ),
        switchMap(
          (organizationId: string) =>
            this._noAccessLimitDialog(organizationId).events$
        )
      )
    );
  }

  hasChild = (_: number, node: TagGroupedByFloor) =>
    !!node.tags && node.tags.length > 0;

  private _noAccessLimitDialog(
    organizationId: string
  ): ConfirmationModalRef<NoAccessDialogComponent> {
    return this.modalService.createModalWithProviders(
      NoAccessDialogComponent,
      {
        width: '100%',
        maxWidth: 'min(90%, 380px)',
        maxHeight: 'min(90%, 1000px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: {
            organizationId,
            text: $localize`:@@LIMIT_OF_NOTES_OVER:Limit of notes is over. \nGo to a higher plan to solve the problem.`
          }
        }
      ]
    );
  }
}
