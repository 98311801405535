<design-dialog-wrapper cdkTrapFocus data-cy="modal-no-access">
  <ng-container designDialogHeader>
    <div class="header">
      <div class="svg-wrapper">
        <img src="assets/ui/images/no_access_white_background.svg" />
      </div>
      <h2 designLabel i18n="@@NO_ACCESS">No access</h2>
    </div>
  </ng-container>

  <ng-container designDialogContent>
    <label>
      {{ data.text }}
    </label>
  </ng-container>

  <ng-container designDialogFooter>
    <div class="footer-no-access">
      <a
        design-button
        *ngIf="data.organizationId"
        href="/organizations/{{ data.organizationId }}/subscription/select"
        i18n="@@PU_CHECK_SUBSCRIPTION_PLANS"
      >
        Check Subscription Plans
      </a>
      <button
        design-stroked-button
        designButtonColor="primary"
        (click)="confirmDialog()"
        data-cy="no-access-dialog-cancel-button"
      >
        <div i18n="@@CANCEL">Cancel</div>
      </button>
    </div>
  </ng-container>
</design-dialog-wrapper>
