import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StageComponent } from '@simlab/data-access';
import {
  AnnotationsFacade,
  ComponentsFacade,
  StagesFacade
} from '@simlab/data-store';
import { DesignFlatButton } from '@simlab/design/button';
import {
  matterport3DWalkControlToken,
  MATTERPORT_TOKEN,
  MatterportBaseService,
  MatterportComponent,
  MatterportOauthService,
  OauthState
} from '@simlab/feature/matterport';
import { TagImporterLeftPanelComponent } from '@simlab/feature/stages';
import {
  MatterportManagerService,
  MatterportService
} from '@simlab/matterport';
import {
  DefaultSidenavBehavior,
  SidenavComponent,
  UiSidenavModule
} from '@simlab/ui/sidenav';
import { RouterStoreParams, ShowcaseHelper } from '@simlab/util-shared';
import { defer, map, Observable } from 'rxjs';

@Component({
  templateUrl: './tags-importer.component.html',
  styleUrls: ['./tags-importer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    UiSidenavModule,
    MatterportComponent,
    TagImporterLeftPanelComponent,
    DesignFlatButton
  ],
  providers: [
    { provide: MATTERPORT_TOKEN, useClass: MatterportService },
    {
      provide: matterport3DWalkControlToken,
      useExisting: forwardRef(() => TagsImporterComponent)
    },
    MatterportBaseService,
    MatterportManagerService,
    MatterportOauthService,
    AnnotationsFacade
  ]
})
export class TagsImporterComponent extends DefaultSidenavBehavior {
  private readonly componentFacade = inject(ComponentsFacade);
  private readonly stageFacade = inject(StagesFacade);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly matterportBaseService = inject(MatterportBaseService);

  @ViewChild('sidenav', { read: SidenavComponent }) sidenav!: SidenavComponent;
  title = 'Tag importer';

  @ViewChild('matterport', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;
  readonly projectId$: Observable<string> = defer(() =>
    this.stageFacade.getRouteNestedParams$.pipe(
      map((params: RouterStoreParams) => params.params['projectId'])
    )
  );
  readonly selectedComponent$: Observable<StageComponent> =
    this.componentFacade.selectedComponent$.pipe(
      map((component: StageComponent | undefined) => {
        if (!component) {
          this.close();
        }
        return component as StageComponent;
      })
    );
  readonly isOpen = this.matterportBaseService.oauthState$.pipe(
    map((state) => state === OauthState.LOADED)
  );
  readonly scanId$: Observable<string> = defer(() =>
    this.selectedComponent$.pipe(
      map((component: StageComponent) => {
        return ShowcaseHelper.findShowcaseId(component.componentUrl);
      })
    )
  );
  constructor() {
    super();

    this.matterportBaseService.hideOverlayElements = true;
  }

  close() {
    this.router.navigate(['../info'], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'preserve'
    });
  }
}
