import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiTooltip } from '@simlab/design/tooltip';
import { TextareaComponent } from './components/textarea/textarea.component';

@NgModule({
  declarations: [TextareaComponent],
  imports: [
    CommonModule,
    TextFieldModule,
    UiTooltip,
    DesignIcon,
    DesignIconButton
  ],
  exports: [TextareaComponent]
})
export class UiTextareaModule {}
