<div class="sort-header">
  <ng-content></ng-content>
  <button
    [@arrowOpacity]="getArrowViewState()"
    design-icon-button
    type="button"
    designColor="secondary"
    class="sort-btn"
  >
    <i
      designIcon="arrow_drop_down"
      [designRotateBy]="arrowDirection === 'asc' ? 180 : 0"
    ></i>
  </button>
</div>
