<ng-container [formGroup]="formGroup">
  <design-form-field class="search-and-filters--search">
    <i class="prefix" designPrefix designIcon="search" designSize="1.3rem"></i>
    <input
      designInput
      i18n-placeholder="@@SEARCH"
      placeholder="Search"
      formControlName="filter"
  /></design-form-field>
  <ui-select
    class="search-and-filters--sort"
    i18n-placeholder="@@GEN_SORT_BY"
    placeholder="Sort by"
    formControlName="sortBy"
  >
    <ng-container *ngFor="let option of sortOptions()">
      <ui-option [value]="option">{{ option.display }}</ui-option>
    </ng-container>
  </ui-select>
</ng-container>
