<!-- <div class="text-area-container"> -->

<!-- cdkTextareaAutosize
  #autosize="cdkTextareaAutosize"
  [cdkAutosizeMinRows]="minRows"
  [cdkAutosizeMaxRows]="maxRows" -->

<!-- [readonly]="(!realtime && !editMode) || readonly" -->
<!-- [class.cursor-readonly]="readonly" -->
<textarea
  #textArea
  [rows]="minRows"
  [value]="tempValue ?? ''"
  [placeholder]="placeholder"
  (input)="valueChange($event)"
  (focusout)="focusoutEmitter($event)"
  (keydown)="keydownDetect ? keyDownFunction($event) : null"
  [disabled]="disabled"
  [readonly]="!editMode && !realtime"
  [class.cursor-readonly]="!notEditable && !editMode"
  [class.text-cursor]="editMode"
></textarea>
<label *ngIf="maxCharacters !== 0"
  >{{ characterInputLength }} / {{ maxCharacters }}</label
>
<div class="toolbar" *ngIf="!disabled && !realtime">
  <ng-content *ngIf="enableNgContentAction"></ng-content>
  <ng-container *ngIf="!enableNgContentAction">
    <ng-container *ngIf="!editMode; else confirm">
      <ng-container *ngIf="tooltipText; else noTooltip">
        <design-tooltip [tooltip]="tooltipText" position="right">
          <button design-icon-button (click)="edit()">
            <i designIcon="edit_first" designSize="1.3rem" (click)="edit()"></i>
          </button>
        </design-tooltip>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #noTooltip>
    <button design-icon-button (click)="edit()">
      <i designIcon="edit_first" designSize="1.3rem" (click)="edit()"></i>
    </button>
  </ng-template>
  <ng-template #confirm>
    <button
      design-icon-button
      (click)="saveChanges(textArea)"
      data-cy="text-area-confirm"
    >
      <i designIcon="check" designSize="2.5rem"></i>
    </button>
    <button
      design-icon-button
      (click)="discardChanges(textArea)"
      data-cy="text-area-discard"
    >
      <i designIcon="cross" designSize="2.5rem"></i>
    </button>
  </ng-template>
</div>
<!-- </div> -->
