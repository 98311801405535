import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ApiFacadeService,
  BlobBody,
  Media,
  MediaType,
  Note,
  NoteFilters,
  Page,
  StageNotes
} from '@simlab/data-access';
import {
  Observable,
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import { StagesFacade } from '../stages/stages.facade';
import * as NotesActions from './notes.actions';
import { NotesFacade } from './notes.facade';
@Injectable()
export class NotesEffects {
  private readonly actions$ = inject(Actions);
  private readonly apiFacadeService = inject(ApiFacadeService);
  private readonly notesFacade = inject(NotesFacade);
  private readonly stagesFacade = inject(StagesFacade);
  private readonly router = inject(Router);
  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotesActions.init),
        filter(
          (action) =>
            !!action.payload.stageId &&
            Array.isArray(action.payload.stageId) &&
            !!action.payload.stageId[0]
        ),
        switchMap((action) => {
          return this.apiFacadeService.rootNotes
            .getRootNotesForStages(action.payload)
            .pipe(
              tap((note: StageNotes<Note>) => {
                this.notesFacade.loadNoteSuccess(note.items);
                if (action.noteId) {
                  this.notesFacade.selectedNoteId(action.noteId);
                  this.router.navigate([], {
                    replaceUrl: true,
                    queryParams: {
                      noteId: action.noteId,
                      sidenavContent: 'note'
                    },
                    queryParamsHandling: 'merge'
                  });
                }
              }),
              catchError((e) => {
                this.notesFacade.loadNoteListFailure();
                console.error(e);
                return of();
              })
            );
        }),
        tap((note: StageNotes<Note>) => {
          const metadata: Page = {
            currentPage: note.currentPage,
            hasNext: note.hasNext,
            hasPrevious: note.hasPrevious,
            pageSize: note.pageSize,
            totalCount: note.totalCount,
            totalPages: note.totalPages
          };
          this.notesFacade.metadata(metadata);
        }),
        catchError((error) => {
          this.notesFacade.loadNoteFailure(error);
          return of(error);
        })
      ),
    { dispatch: false }
  );

  addSimpleNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.addSimpleNote),
      switchMap((action) =>
        this.apiFacadeService.rootNotes.addRootNoteToStage(action.note).pipe(
          switchMap((response: string) => {
            return this.apiFacadeService.rootNotes
              .getRootNote({
                id: response
              })
              .pipe(map((note) => NotesActions.addSimpleNoteSuccess({ note })));
          }),
          catchError((error) => {
            console.log(error);
            return of(NotesActions.addSimpleNoteFailure({ error }));
          })
        )
      )
    )
  );

  selected$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotesActions.selectedNoteId),
        distinctUntilChanged((prev, act) => prev.noteId === act.noteId),
        switchMap((action) => {
          if (action.noteId) {
            return this.apiFacadeService.rootNotes
              .getRootNote({
                id: action.noteId
              })
              .pipe(
                catchError((e) => {
                  this.notesFacade.selectedNoteId(undefined);
                  this.router.navigate([], {
                    replaceUrl: true,
                    queryParams: {
                      noteId: null,
                      sidenavContent: null
                    },
                    queryParamsHandling: 'merge'
                  });

                  return of(e);
                })
              );
          } else {
            return of({} as Note);
          }
        }),
        tap((note: Note) => {
          this.notesFacade.selectedNote(note);
        })
      ),
    { dispatch: false }
  );

  selectedNoteChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NotesActions.changeNoteName,
        NotesActions.changeNoteStatus,
        NotesActions.changeNoteType
      ),
      switchMap((action) => {
        const test$ = this.notesFacade.selectedNote$.pipe(
          take(1),
          switchMap((note: Note) => {
            return this.apiFacadeService.rootNotes.getRootNote({
              id: note.id
            });
          }),
          tap((note: Note) => {
            this.notesFacade.updateNote(note);
            this.notesFacade.selectedNote(note);

            if (
              action.type === NotesActions.changeNoteStatus.type ||
              action.type === NotesActions.changeNoteType.type
            ) {
              this.notesFacade.changeNoteStatusSuccess(note);
            }
          })
        );

        return test$;
      })
    )
  );

  deleteNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.deleteNote),
      exhaustMap((action) =>
        this.apiFacadeService.rootNotes
          .removeRootNoteFromStage({
            rootNoteId: action.rootNoteId,
            stageId: action.stageId
          })
          .pipe(
            take(1),
            map(() => {
              return NotesActions.deleteNoteSuccess({
                noteId: action.rootNoteId,
                stageId: action.stageId
              });
            })
          )
      )
    )
  );

  deleteNoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.deleteNoteSuccess),
      tap(() => {
        this._clearRouterFromNoteId();
      }),
      switchMap((data) =>
        this.notesFacade.metadata$.pipe(
          take(1),
          switchMap((metadata: Page | null) =>
            this.notesFacade.filters$.pipe(
              take(1),
              switchMap((filters: NoteFilters | null) => {
                if (metadata) {
                  return this.stagesFacade.selectedId$.pipe(
                    take(1),
                    switchMap((stageId: string) => {
                      const minCountOnPage =
                        metadata.currentPage * metadata.pageSize -
                        metadata.pageSize +
                        1;

                      const selectedPage: {
                        pageSize?: number;
                        pageNumber: number;
                      } = {
                        pageNumber: metadata.hasNext
                          ? metadata.currentPage
                          : metadata.hasPrevious
                            ? metadata.totalCount > minCountOnPage
                              ? metadata.currentPage
                              : metadata.currentPage - 1
                            : metadata.currentPage
                      };
                      selectedPage.pageNumber =
                        selectedPage.pageNumber === 0
                          ? 1
                          : selectedPage.pageNumber;
                      const notesPayload: {
                        stageId: string[] | null;
                        page: { pageSize?: number; pageNumber: number };
                        rootNotesFilter?: NoteFilters;
                      } = {
                        stageId: [stageId],
                        rootNotesFilter: filters || undefined,
                        page: selectedPage
                      };

                      return this.apiFacadeService.rootNotes
                        .getRootNotesForStages(notesPayload)
                        .pipe(
                          map((note: StageNotes<Note>) => {
                            this.notesFacade.loadNoteSuccess(note.items);
                            const metadata: Page = {
                              currentPage: note.currentPage,
                              hasNext: note.hasNext,
                              hasPrevious: note.hasPrevious,
                              pageSize: note.pageSize,
                              totalCount: note.totalCount,
                              totalPages: note.totalPages
                            };
                            this.notesFacade.metadata(metadata);
                            return data.noteId;
                          })
                        );
                    })
                  );
                }
                return of(data.noteId);
              })
            )
          )
        )
      ),
      map((noteId) => NotesActions.selectedNoteIdAfterDeleteNote({ noteId })),
      catchError((error) => of(NotesActions.deleteNoteFailure({ error })))
    )
  );

  addMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.addMedia),
      mergeMap(
        (action: {
          projectId: string;
          noteId: string;
          file: File;
          mediaType: MediaType;
          fileType?: string;
        }) =>
          iif(
            () => action.mediaType === 'photo',
            this._X(action),
            this._Y(action)
          ).pipe(
            mergeMap((audioNote: Media) => {
              audioNote.url = audioNote.digitalNoteUrl ?? '';
              return of(
                NotesActions.addMediaSuccess({
                  media: audioNote,
                  mediaType: action.mediaType
                })
              );
            }),
            catchError((error) => {
              return of(NotesActions.addMediaFailure({ error }));
            })
          )
      )
    )
  );

  updateNoteNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.updateNoteNotification),
      mergeMap((action: { rootNoteId: string; areEnabled: boolean }) =>
        this.apiFacadeService.rootNotes
          .changeRootNoteNotificationEnabled({
            rootNoteId: action.rootNoteId,
            areEnabled: action.areEnabled
          })
          .pipe(map(() => action))
      ),
      map((action: { rootNoteId: string; areEnabled: boolean }) => {
        return NotesActions.updateNoteNotificationSuccess(action);
      }),
      catchError((error) => {
        return of(NotesActions.updateNoteNotificationFailure({ error }));
      })
    )
  );

  updateNoteNotificationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.updateNoteNotificationSuccess),
      map((action: { rootNoteId: string; areEnabled: boolean }) => {
        return NotesActions.updateSelectedNoteNotificationSuccess(action);
      }),
      catchError((error) => {
        return of(NotesActions.updateNoteNotificationFailure({ error }));
      })
    )
  );

  private _X(action: {
    projectId: string;
    noteId: string;
    file: File;
    mediaType: MediaType;
  }): Observable<any> {
    //TODO: !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    return of(false);
  }

  private _Y(action: {
    projectId: string;
    noteId: string;
    file: File;
    mediaType: MediaType;
    fileType?: string;
  }): Observable<any> {
    return this.apiFacadeService.blobs
      .sendFileToBlobWithNoteId(
        action.file,
        action.projectId,
        action.noteId,
        action.mediaType
      )
      .pipe(
        mergeMap((blobBody: BlobBody) =>
          this.apiFacadeService.digitalNotes.addDigitalNoteToRootNote({
            ...blobBody,
            type: action.mediaType
          })
        )
      );
  }

  private _clearRouterFromNoteId() {
    this.router.navigate([], {
      replaceUrl: true,
      queryParams: {
        noteId: null,
        sidenavContent: null
      },
      queryParamsHandling: 'merge'
    });
  }
}
