@if (data(); as data) {
  <div class="company">
    @if (data.logoIcon) {
      <i ui-icon [name]="data.logoIcon" size="img" defaultColor></i>
    } @else {
      <img
        [src]="data.logoUrl"
        alt="Company logo image"
        class="company__logo"
      />
    }

    <i
      class="company__status"
      designIcon="success"
      [ngClass]="{
        'company__status--is-connected': data.status === 'CONNECTED',
        'company__status--is-disconnected': data.status === 'DISCONNECTED'
      }"
    ></i>
  </div>

  <ng-container
    *ngTemplateOutlet="
      dataTemplate;
      context: { label: translation.company, value: data.companyName }
    "
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="
      dataTemplate;
      context: { label: translation.project, value: data.projectName }
    "
  ></ng-container>

  <ng-template #dataTemplate let-label="label" let-value="value">
    <div class="data">
      <div class="data__label">{{ label }}</div>
      <div class="data__value">{{ value }}</div>
      <ui-divider />
    </div>
  </ng-template>
}
