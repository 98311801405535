import { inject, Injectable, TemplateRef } from '@angular/core';
import { DesignSymbol } from '@simlab/design/internal';
import { DocumentsAction } from '@simlab/documents/data-access';
import { DocumentComponentsLoadedState } from '@simlab/documents/models';
import { StagesDocumentsInModalStateService } from '../../stages-documents-in-modal-state.service';
import { StagesDocumentsInModalActionsBase } from '../stages-documents-in-modal-menu-actions.token';

const translation = {
  info: $localize`:@@INFO:Info`,
  download: $localize`:@@GEN_DOWNLOAD:Download`
};

@Injectable()
export class StagesDocumentsInModalActionsService
  implements StagesDocumentsInModalActionsBase
{
  templateToDownload: TemplateRef<any> | undefined;
  private readonly _docState = inject(StagesDocumentsInModalStateService);

  download(items: DocumentComponentsLoadedState[]) {
    this._docState.downloadFiles(items, this.templateToDownload);
  }

  actionList: DocumentsAction[] = [
    {
      name: translation.download,
      icon: DesignSymbol.FileDownload,
      trigger: (data) => this.download([data])
    }
  ];
}
