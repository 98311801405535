import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl
} from '@angular/forms';
import { DesignFlatButtonModule } from '@simlab/design/button';
import {
  MatterportApiFacadeService,
  ModelView,
  ViewType
} from '@simlab/matterport/api';
import { UiDividerModule } from '@simlab/ui/divider';
import { ICON_FONT_TYPE, UiIconModule } from '@simlab/ui/icon';
import { UiSelectModule } from '@simlab/ui/select';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  defer,
  distinctUntilChanged,
  map,
  of,
  startWith,
  takeUntil,
  tap
} from 'rxjs';
import { NoteStatusIcon } from '../../models/note-status-icon';
import {
  SelectedColors,
  TagFiltersComponent
} from '../tag-filters/tag-filters.component';
import { TagListComponent } from '../tag-list/tag-list.component';
import { TagImporterControllerService } from './tag-importer-controller.service';

@Component({
  selector: 'feature-stages-tag-importer-left-panel',
  templateUrl: './tag-importer-left-panel.component.html',
  styleUrls: ['./tag-importer-left-panel.component.scss'],
  viewProviders: [TagImporterControllerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    UiSelectModule,
    DesignFlatButtonModule,
    UiIconModule,
    FormsModule,
    UiSelectModule,
    UiDividerModule,
    TagListComponent,
    TagFiltersComponent,
    ReactiveFormsModule
  ]
})
export class TagImporterLeftPanelComponent implements OnInit, OnDestroy {
  @Input() scanId!: string;
  @Input() projectId!: string;
  @ViewChild('tagList', { read: TagListComponent })
  set _tagList(tagList: TagListComponent) {
    this.tagImporterControllerService.tagList = tagList;
  }

  get emptyTagList(): boolean {
    return this.tagImporterControllerService.tagListLength === 0;
  }

  private readonly _destroySource: Subject<void> = new Subject<void>();
  readonly noteType: UntypedFormControl =
    this.tagImporterControllerService.noteType;
  readonly noteStatus: UntypedFormControl =
    this.tagImporterControllerService.noteStatus;
  readonly noteIcon: typeof NoteStatusIcon = NoteStatusIcon;
  readonly importInProgress: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly importDialogAction$ =
    this.tagImporterControllerService.importDialogAction$;
  readonly scanViews$: Observable<ModelView[]> = defer(() =>
    this.matterportApi.getModelViews$(this.scanId).pipe(
      map((data) => {
        return data.data.model.views;
      }),
      catchError(() => {
        console.log('Error on get model view return default');
        this.tagImporterControllerService.importFromPrivateScanDialog();
        return of([
          {
            enabled: true,
            id: this.scanId,
            name: 'Default',
            type: ViewType.DefaultView
          } as ModelView
        ] as ModelView[]);
      })
    )
  );
  readonly selectedIcon$: Observable<ICON_FONT_TYPE> = combineLatest([
    this.noteType.valueChanges.pipe(startWith('Information')),
    this.noteStatus.valueChanges.pipe(startWith(''))
  ]).pipe(
    map((values: [string, string]) => {
      if (values[0] === 'Information') {
        return this.noteIcon['Information'] as ICON_FONT_TYPE;
      } else {
        return this.noteIcon[values[1]] as ICON_FONT_TYPE;
      }
    })
  );
  readonly selectedColors: BehaviorSubject<SelectedColors> =
    new BehaviorSubject<SelectedColors>({});

  constructor(
    private readonly matterportApi: MatterportApiFacadeService,
    private readonly tagImporterControllerService: TagImporterControllerService
  ) {}

  ngOnInit(): void {
    this.tagImporterControllerService.init(this.projectId, this.scanId);

    this._notesDescriptionObserver();
  }
  private _notesDescriptionObserver() {
    combineLatest([
      this.noteType.valueChanges.pipe(startWith('Information')),
      this.noteStatus.valueChanges.pipe(startWith(''))
    ])
      .pipe(
        distinctUntilChanged(
          (previous: [string, string], current: [string, string]) =>
            previous[0] === current[0] && previous[1] === current[1]
        ),
        tap((values: [string, string]) => {
          if (values[0] === 'Information') {
            this.noteStatus.patchValue(null);
            this.noteStatus.disable({ emitEvent: false });
          } else if (values[0] === 'Issue') {
            this.noteStatus.enable({ emitEvent: false });
            if (!values[1]) {
              this.noteStatus.patchValue('Pending');
            }
          }
        }),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }
}
