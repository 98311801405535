<common-annotation-panel-root
  [name]="name()"
  [subName]="stageName()"
  [iconType]="status() | getRFIIconByStatus"
  [loading]="isLoading()"
>
  @if (!isLoading()) {
    <procore-annotation-panel-notifications
      [title]="status() | getRFIStatusLabel"
      [text]="status() | getRFIStatusText"
      [icon]="status() | getRFIStatusIcon"
      [type]="status() | getRFIStatusType"
    />

    <common-annotation-panel-location-3d
      [positionExist]="hasLocation()"
      [changeAndSetActionDisabled]="!canEdit()"
      (goToButtonClick)="goTo()"
      (setLocationButtonClick)="setLocation()"
      (changeLocationButtonClick)="reassignLocation()"
    />

    <procore-request-container
      [type]="'Request'"
      [title]="title()"
      [description]="questions()"
    />

    <ui-image-info
      class="image-info"
      imageSrc="icon_open_in_browser"
      i18n-header="@@GO_TO_PROCORE_MANAGE_RFI"
      header="Go to Procore to manage the RFI"
      [button]="button"
    />
  }
</common-annotation-panel-root>
