/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { DesignSymbolInput } from '@simlab/design/internal';

@Component({
  selector: 'feature-stages-other-component-item',
  templateUrl: './other-component-item.component.html',
  styleUrls: ['./other-component-item.component.scss'],
  standalone: true,
  imports: [CommonModule, DesignIconButton, DesignIcon],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'other-component-item',
    '[class.disabled]': 'disabled',
  },
})
export class OtherComponentItemComponent {
  private _disabled = false;
  public get disabled() {
    return this._disabled;
  }
  public set disabled(value) {
    this._disabled = value;
    this._cdr.markForCheck();
  }
  @Input() icon: DesignSymbolInput;
  @Input() name!: string;
  @Input() id!: string;
  @Input() visible = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private readonly _cdr: ChangeDetectorRef) {}
  @HostListener('click', ['$event'])
  onClick(event: PointerEvent): void {
    this.changeVisibility();
  }
  changeVisibility() {
    if (this._disabled) return;
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible);
  }
}
