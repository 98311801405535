import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  DoCheck,
  ElementRef,
  Input,
  QueryList,
  inject,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  NgForm,
  Validators,
} from '@angular/forms';
import { AutocompleteControl } from '@simlab/design/autocomplete';
import { DesignFormFieldControl } from '@simlab/design/form-field';
import {
  CanDisable,
  CanUpdateErrorState,
  ErrorStateMatcher,
  mixinErrorState,
} from '@simlab/design/internal';
import { Subject, tap } from 'rxjs';
import { designChipToken } from '../tokens/chip.token';
import { DesignChipInputControl } from './chip-input.directive';
import { DesignChip } from './chip.component';

declare const ngDevMode: boolean;

const _ChipInputPanelBase = mixinErrorState(
  class {
    /**
     * Emits whenever the component state changes and should cause the parent
     * form field to update. Implemented as part of `DesignFormFieldControl`.
     * @docs-private
     */
    readonly stateChanges = new Subject<void>();
    readonly _defaultErrorStateMatcher = inject(ErrorStateMatcher);
    readonly _parentForm = inject(NgForm);
    readonly _parentFormGroup = inject(FormGroupDirective);
    readonly ngControl = inject(NgControl, { optional: true });
  }
);

@Directive()
export abstract class ChipInputPanelBase<T>
  extends _ChipInputPanelBase
  implements
    DesignFormFieldControl<Array<T>>,
    CanUpdateErrorState,
    ControlValueAccessor,
    DoCheck,
    AfterContentInit,
    AfterViewInit,
    CanDisable,
    AutocompleteControl
{
  @ContentChildren(designChipToken)
  protected _chipElements!: QueryList<DesignChip>;

  /** The chip input to add more chips */
  protected _chipInput!: DesignChipInputControl;

  /**
   * Implemented as part of DesignFormFieldControl.
   * @docs-private
   */
  override readonly stateChanges: Subject<void> = new Subject<void>();

  /**
   * Implemented as part of DesignFormFieldControl.
   * @docs-private
   */
  @Input()
  get placeholder(): string {
    return this._chipInput ? this._chipInput.placeholder : this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  protected _placeholder!: string;

  protected _elementRef = inject<ElementRef<HTMLInputElement>>(
    ElementRef<HTMLInputElement>
  );
  protected _changeDetectorRef = inject(ChangeDetectorRef);

  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */
  get id(): string {
    return this._chipInput.id;
  }

  /**
   * Implemented as part of DesignFormFieldControl.
   * @docs-private
   */
  @Input()
  get required(): boolean {
    return (
      this._required ??
      this.ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }
  //TODO: pass it to input
  protected _required: boolean | undefined;

  /**
   * Implemented as part of DesignFormFieldControl.
   * @docs-private
   */
  get value(): Array<T> {
    return this._value;
  }
  set value(newValue: Array<T>) {
    this._value = newValue;
  }
  private _value: Array<T> = [];

  /**
   * Implemented as part of DesignFormFieldControl.
   * @docs-private
   */
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }
  protected _disabled = false;

  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */
  focused = false;

  constructor() {
    super();

    if (this.ngControl) {
      //NOTE: we can't provide NG_VALUE_ACCESSOR with DesignFormFieldControl so we use this workaround
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    if (!this._chipInput && (typeof ngDevMode === 'undefined' || ngDevMode)) {
      throw Error(
        'mat-chip-grid must be used in combination with matChipInputFor.'
      );
    }
  }

  ngAfterContentInit(): void {
    this._chipElements.changes
      .pipe(tap(() => this._changeDetectorRef.markForCheck()))
      .subscribe();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }

  //TODO: update value?
  // if (!this.disabled) {
  // this.writeValue(value);
  // }

  /**
   * Implemented as part of ControlValueAccessor.
   * @docs-private
   *
   * // Allows Angular to update the model
   * // Update the model and changes needed for the view here.
   */
  writeValue(value: Array<any>): void {
    if (!this.disabled) {
      this._value.push(...value);
    }
  }

  /**
   * Implemented as part of ControlValueAccessor.
   * @docs-private
   *
   * // Allows Angular to register a function to call when the model changes.
   * // Save the function as a property to call later here.
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Implemented as part of ControlValueAccessor.
   * @docs-private
   *
   * // Allows Angular to register a function to call when the input has been touched.
   * // Save the function as a property to call later here.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Implemented as part of ControlValueAccessor.
   * @docs-private
   *
   * // Allows Angular to disable the input.
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Function to call when the value changes.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (value: any): void => {};

  // Function to call when the input is touched.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = (): void => {};

  /** Associates an HTML input element with this chip grid. */
  registerInput(inputElement: DesignChipInputControl): void {
    this._chipInput = inputElement;
  }

  /** Focuses the input. */
  focus(options?: FocusOptions): void {
    if (this._chipInput) {
      this._chipInput.hostElement.focus(options);
    }
  }

  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */
  onContainerClick(): void {
    // Do not re-focus the input element if the element is already focused.
    if (!this.focused) {
      this.focus();
    }
  }
}
