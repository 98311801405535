import {
  ChangeDetectionStrategy,
  Component,
  input,
  NgModule,
  ViewEncapsulation
} from '@angular/core';
import { ILLUSTRATION_TYPE } from '@simlab/design/illustration';
import { StatusBadgeComponent } from '@simlab/design/status-badge';
import { UiIconModule } from '@simlab/ui/icon';

@Component({
  selector: 'feature-stages-annotation-item-info-card',
  standalone: true,
  imports: [UiIconModule],
  templateUrl: './annotation-item-info-card.component.html',
  styleUrl: './annotation-item-info-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'feature-stages-annotation-item-info-card' }
})
export class AnnotationItemInfoCardComponent {
  readonly name = input.required<string>();
  readonly resultCount = input(0);
  readonly icon = input.required<ILLUSTRATION_TYPE>();
}

@NgModule({
  imports: [AnnotationItemInfoCardComponent, StatusBadgeComponent],
  exports: [AnnotationItemInfoCardComponent, StatusBadgeComponent]
})
export class AnnotationItemInfoModule {}
