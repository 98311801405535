import {
  TMeasurementData,
  TMeasurementExtended
} from '../models/src/common-models';
import { SortOption } from '../ui/src/search-and-sort-filter/search-and-sort-filter.component';

export function areaParamsToSort(
  value: TMeasurementExtended,
  sortByValue: SortOption
) {
  if (!value) return '';

  const propertyName: keyof TMeasurementExtended | 'surfaceSize' =
    sortByValue.propertyName as keyof TMeasurementExtended | 'surfaceSize';

  return propertyName === 'surfaceSize'
    ? (value.data as TMeasurementData)[propertyName]
    : value[propertyName];
}

export function distanceParamsToSort(
  value: TMeasurementExtended,
  sortByValue: SortOption
) {
  if (!value) return '';

  const propertyName: keyof TMeasurementExtended | 'surfaceSize' =
    sortByValue.propertyName as keyof TMeasurementExtended | 'surfaceSize';

  return propertyName === 'surfaceSize'
    ? (value.data as TMeasurementData)[propertyName]
    : value[propertyName];
}
