/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/component-class-suffix */
import { A11yModule } from '@angular/cdk/a11y';
import { CdkOverlayOrigin, OverlayModule } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DesignOption, designOptionToken } from '@simlab/design/common';
import { designAutocompleteToken } from '../tokens/autocomplete.token';
import { AutocompleteBase } from './autocomplete-base.directive';

@Component({
  selector: 'design-autocomplete',
  exportAs: 'designAutocomplete',
  standalone: true,
  imports: [OverlayModule, A11yModule],
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'design-autocomplete',
  },
  providers: [
    {
      provide: designAutocompleteToken,
      useExisting: DesignAutocomplete,
    },
  ],
})
export class DesignAutocomplete extends AutocompleteBase {
  @ViewChild('optionsPanel') panel!: ElementRef;

  @ContentChildren(designOptionToken, { descendants: true })
  protected readonly _options!: QueryList<DesignOption>;

  @Input()
  readonly originOverlay!: CdkOverlayOrigin;
}
