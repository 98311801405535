@if (data(); as data) {
  @if (data.state === 'LOADED') {
    @if (isSelected() && isTouchableScreen()) {
      <ui-checkbox
        class="badge__checkbox"
        [checked]="true"
        type="customize"
      ></ui-checkbox>
    } @else {
      <i
        designIconPrefix
        [designIcon]="data.data | getDocumentIcon"
        [designSize]="data.data.isDirectory ? '20px' : '24px'"
        class="badge__icon"
      ></i>
    }
  } @else {
    <design-progress-spinner
      [diameter]="20"
      [strokeWidth]="5"
      designColor="accent"
    />
  }
}
