import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { FORMAT_DATE_CONFIG } from '@simlab/design/format-date';
import { Observable, map } from 'rxjs';

@Pipe({
  standalone: true,
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  public readonly formatDate = inject<Observable<string>>(FORMAT_DATE_CONFIG);
  private datePipe = inject(DatePipe);

  transform(value: any, time = '') {
    if (!value) return value;
    return this.formatDate.pipe(
      map((date: string) =>
        this.datePipe.transform(new Date(value), date + time)
      )
    );
  }
}
