import { Injectable, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  GlobalFiltersState,
  GlobalFiltersStore
} from '@simlab/annotations-filters/services';
import { CURRENT_PROJECT_ID } from '@simlab/data-store';
import {
  NoteFiltersState,
  NoteFiltersStore
} from '@simlab/feature-stages/services';
import { canRenderProcoreEntity } from '@simlab/procore/data-access';
import {
  ProcoreFiltersState,
  ProcoreFiltersStore
} from '@simlab/procore/services';
import { IChipItem } from '../ui/src/lib/models/chip.models';

export type GlobalFilters = GlobalFiltersState['filter'] &
  Partial<ProcoreFiltersState['filter']> &
  NoteFiltersState['filter'];

export type PartialGlobalFilters = Partial<
  Omit<GlobalFilters, 'dateCreation' | 'dateModification'>
> & {
  dateCreation?:
    | Partial<GlobalFiltersState['filter']['dateCreation']>
    | undefined;
  dateModification?:
    | Partial<GlobalFiltersState['filter']['dateCreation']>
    | undefined;
};

@Injectable()
export class AnnotationsFilterService {
  private readonly _globalFilterStore = inject(GlobalFiltersStore);
  private readonly _procoreFilterStore = inject(ProcoreFiltersStore, {
    optional: true
  });
  private readonly _notesFilterStore = inject(NoteFiltersStore);
  private readonly _projectId = inject(CURRENT_PROJECT_ID);

  private readonly _canRenderProcoreEntity = toSignal(
    canRenderProcoreEntity(this._projectId()!)
  );

  private readonly _procoreFiltersStoreInstance = computed(() => {
    const canRenderProcoreEntity = this._canRenderProcoreEntity();

    return canRenderProcoreEntity ? this._procoreFilterStore : undefined;
  });

  /**
   * @description
   * Return merged state of all filters
   */
  readonly filterState = computed(() => {
    return {
      ...this._globalFilterStore.filter(),
      ...this._procoreFiltersStoreInstance()?.filter(),
      ...this._notesFilterStore.filter()
    } as GlobalFilters;
  });

  readonly isAnyFilterActive = computed(() => this.filtersLength() > 0);

  readonly filtersLength = computed(() => {
    return (
      this._globalFilterStore.filtersLength() +
      this._notesFilterStore.filtersLength() +
      (this._procoreFiltersStoreInstance()?.filtersLength() || 0)
    );
  });

  /**
   * @description
   * This method could reset all filters
   */
  resetFilters() {
    this._globalFilterStore.resetFilters();
    this._notesFilterStore.resetFilters();
    this._procoreFiltersStoreInstance()?.resetFilters();
  }

  /**
   * @description
   * This method could set all filters
   */
  setFilters(filters: PartialGlobalFilters) {
    this.setGlobalFilters(filters);
    this.setNoteFilters(filters);
    this.setProcoreFilters(filters);
  }

  /**
   * @description
   * This method could set Procore filters if token exist
   */
  setProcoreFilters(filters: PartialGlobalFilters) {
    const procoreFiltersStoreInstance = this._procoreFiltersStoreInstance();

    if (!procoreFiltersStoreInstance) return;
    const {
      punchItemStatuses,
      punchItemBallInCourt,
      punchItemDueDate,
      rfiStatuses,
      rfiDueDate,
      rfiBallInCourt
    } = filters;

    procoreFiltersStoreInstance.setFilters({
      punchItemStatuses,
      punchItemBallInCourt,
      punchItemDueDate,
      rfiStatuses,
      rfiDueDate,
      rfiBallInCourt
    });
  }

  /**
   * @description
   * This method could set note filters
   */
  setNoteFilters(filters: PartialGlobalFilters) {
    const { stagesRange, noteTypes, noteStatuses } = filters;
    this._notesFilterStore.setFilters({ stagesRange, noteTypes, noteStatuses });
  }

  /**
   * @description
   * This method could set global/shared filters
   */
  setGlobalFilters(filters: PartialGlobalFilters) {
    const { authors, stakeholders, dateCreation, dateModification } = filters;
    this._globalFilterStore.setFilters({
      authors,
      stakeholders,
      dateCreation,
      dateModification
    });
  }

  removeSelectedFilter(chip: IChipItem) {
    let state = this.filterState();
    switch (chip.key) {
      case 'Author':
        state = this._removeArrayValue(chip.value, 'authors');
        break;

      case 'Stakeholder':
      case 'Assignee':
        state = this._removeArrayValue(chip.value, 'stakeholders');
        break;

      case 'Created':
        state.dateCreation.from = undefined;
        state.dateCreation.to = undefined;
        break;

      case 'Modified':
        state.dateModification.from = undefined;
        state.dateModification.to = undefined;
        break;

      case 'Stage':
        state = this._removeArrayValue(chip.value, 'stagesRange');
        break;

      case 'NoteStatus':
        state = this._removeArrayValue(chip.value, 'noteStatuses');
        break;

      case 'NoteType':
        state = this._removeArrayValue(chip.value, 'noteTypes');
        break;

      case 'Punch':
        state = this._removeArrayValue(chip.value, 'punchItemStatuses');
        break;

      case 'PunchDueDate':
        state.punchItemDueDate = undefined;
        break;

      case 'PunchBallInCourt':
        state = this._removeArrayValue(chip.value, 'punchItemBallInCourt');
        break;

      case 'RFI':
        state = this._removeArrayValue(chip.value, 'rfiStatuses');
        break;

      case 'RFIDueDate':
        state.rfiDueDate = undefined;
        break;

      case 'RFIBallInCourt':
        state = this._removeArrayValue(chip.value, 'rfiBallInCourt');
        break;

      default:
        this._chipCategoryChecker(chip.key);
    }

    this.setFilters(state);
  }

  private _removeArrayValue<T>(
    value: T,
    property: keyof GlobalFilters
  ): GlobalFilters {
    const _state = this.filterState();
    const values = (_state[property] as T[]).filter((v) => v !== value);

    return {
      ..._state,
      [property]: values
    };
  }

  private _chipCategoryChecker(key: never): never {
    throw new Error(`Unhandled case: ${key}`);
  }
}
