<form [formGroup]="formGroup()" class="form" #form="ngForm">
  <ui-select
    class="form-field"
    formControlName="documentTypes"
    i18n-placeholder="@@TYPE"
    placeholder="Type"
    [multiple]="true"
    [showPlaceholder]="true"
  >
    @for (documentType of documentTypeOptions; track documentType.id) {
      <ui-option [value]="documentType.id">{{ documentType.name }}</ui-option>
    }
  </ui-select>

  <ui-select
    class="form-field"
    formControlName="creatorIds"
    i18n-placeholder="@@AUTHOR"
    placeholder="Author"
    [multiple]="true"
    [showPlaceholder]="true"
  >
    @for (author of authorsList(); track author.userId) {
      <ui-option [value]="author.userId">{{ author.userName }}</ui-option>
    }
  </ui-select>

  <ui-select
    class="form-field"
    formControlName="documentSources"
    i18n-placeholder="@@SOURCE"
    placeholder="Source"
    [multiple]="true"
    [showPlaceholder]="true"
  >
    @for (documentSource of documentSourcesList; track documentSource.id) {
      <ui-option [value]="documentSource.id">{{
        documentSource.name
      }}</ui-option>
    }
  </ui-select>
</form>
