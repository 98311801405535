import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { select, Store } from '@ngrx/store';
import { SimplifiedStage } from '@simlab/data-access';
import { Vector3 } from '@simlab/matterport/assets/mpSdk/sdk';
import { getKeyofRFIStatus, RFIStatus } from '@simlab/procore/models';
import { RouterSelectors, RouterStoreParams } from '@simlab/util-shared';
import { Observable } from 'rxjs';
import * as StagesActions from './stages.actions';
import { StagesApiActions } from './stages.actions';
import { StageWithCount } from './stages.models';
import * as StagesSelectors from './stages.selectors';

@Injectable()
export class StagesFacade {
  private readonly store = inject(Store);
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  readonly getRouteNestedParams$: Observable<RouterStoreParams> =
    this.store.pipe(
      select(RouterSelectors.getRouteNestedParams)
      // distinctUntilChanged(RouterHelpers.distinctUntilChangedComparator)
    );
  readonly stagesLoaded$ = this.store.pipe(
    select(StagesSelectors.getStagesLoaded)
  );
  readonly stagesLoading$ = this.store.pipe(
    select(StagesSelectors.getStagesLoading)
  );
  readonly allStagesIds$ = this.store.pipe(select(StagesSelectors.getAllIds));
  readonly allStages$ = this.store.pipe(select(StagesSelectors.getAllStages));
  readonly allStages = toSignal(
    this.store.pipe(select(StagesSelectors.getAllStages))
  );

  readonly containsAnyStage$ = this.store.pipe(
    select(StagesSelectors.containsAnyStage)
  );
  readonly selectedStages$ = this.store.pipe(
    select(StagesSelectors.getSelected)
  );
  readonly selectedId$ = this.store.pipe(select(StagesSelectors.getSelectedId));
  readonly hasSynchronizedShowcase$ = this.store.pipe(
    select(StagesSelectors.hasSynchronizedShowcase)
  );
  readonly selectedStages = toSignal(this.selectedStages$);

  readonly noteCount = computed(() => {
    const selectedStages = this.selectedStages();
    if (!selectedStages) return 0;
    return selectedStages.noteCounts?.totalRootNotesCount || 0;
  });
  readonly rfiCount = computed(() => {
    const selectedStages = this.selectedStages();
    if (!selectedStages) return 0;
    return selectedStages.rfiCount;
  });
  readonly punchCount = computed(() => {
    const selectedStages = this.selectedStages();
    if (!selectedStages) return 0;
    return selectedStages.punchItemCount;
  });

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  initStore(projectId: string, stageId?: string) {
    this.store.dispatch(StagesActions.init({ projectId, stageId }));
  }

  initSetStage(selectedId: string) {
    this.store.dispatch(StagesActions.selectInitStage({ selectedId }));
  }

  clearStore() {
    this.store.dispatch(StagesActions.clearStore());
  }

  clearSelection() {
    this.store.dispatch(StagesActions.clearSelection());
  }

  setSelectedStageId(selectedId: string, cameraPose?: Vector3) {
    this.store.dispatch(
      StagesActions.selectedLocalStageSuccess({ selectedId, cameraPose })
    );
  }

  hasComponent(stageId: string) {
    this.store.dispatch(StagesActions.setHasComponent({ stageId }));
  }

  noExistStages() {
    this.store.dispatch(StagesApiActions.noExistStages());
  }

  loadStagesSuccess(stages: StageWithCount[]) {
    this.store.dispatch(StagesApiActions.loadStagesSuccess({ stages }));
  }

  addStage(stage: SimplifiedStage) {
    this.store.dispatch(StagesApiActions.addStage({ stage }));
  }
  removeStage(stageId: string, projectId: string) {
    this.store.dispatch(StagesApiActions.removeStage({ projectId, stageId }));
  }

  removeAllStage() {
    this.store.dispatch(StagesApiActions.removeAllStages());
  }

  updateStageName(newName: string, stageId: string) {
    this.store.dispatch(StagesApiActions.updateStageName({ newName, stageId }));
  }

  incrementStageNoteCounter(
    stageId: string,
    noteStatus: string,
    noteType: string
  ) {
    this.store.dispatch(
      StagesApiActions.updateStageCounter({ stageId, noteStatus, noteType })
    );
  }

  updateStageDescription(newDescription: string, stageId: string) {
    this.store.dispatch(
      StagesApiActions.updateStageDescription({ newDescription, stageId })
    );
  }

  editStage(id: string, name: string, stageDate: string) {
    this.store.dispatch(StagesApiActions.editStage({ id, name, stageDate }));
  }

  decrementRfiCount(stageId: string, rfiStatus: RFIStatus) {
    const rfiStatusName = getKeyofRFIStatus(
      rfiStatus
    ).toLocaleLowerCase() as Lowercase<ReturnType<typeof getKeyofRFIStatus>>;

    this.store.dispatch(
      StagesActions.changeRfiCount({ stageId, rfiStatusName, change: -1 })
    );
  }

  incrementRfiCount(stageId: string, rfiStatus: RFIStatus) {
    const rfiStatusName = getKeyofRFIStatus(
      rfiStatus
    ).toLocaleLowerCase() as Lowercase<ReturnType<typeof getKeyofRFIStatus>>;

    this.store.dispatch(
      StagesActions.changeRfiCount({ stageId, rfiStatusName, change: +1 })
    );
  }

  incrementPunchItemCount(stageId: string, withActionNumber: boolean) {
    this.store.dispatch(
      StagesActions.changePunchItemCount({
        stageId,
        punchItemStatusName: withActionNumber
          ? 'punchItemsWithActionNumber'
          : 'punchItemsWithNoActionNumber',
        change: +1
      })
    );
  }

  decrementPunchItemCount(stageId: string, withActionNumber: boolean) {
    this.store.dispatch(
      StagesActions.changePunchItemCount({
        stageId,
        punchItemStatusName: withActionNumber
          ? 'punchItemsWithActionNumber'
          : 'punchItemsWithNoActionNumber',
        change: -1
      })
    );
  }

  resetProcoreCountsFromStages() {
    this.store.dispatch(StagesActions.resetProcoreCountsFromStages());
  }
}
