import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { ComponentsFacade } from '@simlab/data-store';
import { mergeMap, Observable, of, take } from 'rxjs';

@Injectable()
export class FetchAvailableComponentsResolver  {
  constructor(private readonly componentsFacade: ComponentsFacade) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.componentsFacade.getRouteNestedParams$.pipe(
      take(1),
      mergeMap((data: { params: Params; queryParams: Params }) => {
        this.componentsFacade.initStore(data.params['projectId']);

        return of(true);
      })
    );
  }
}
