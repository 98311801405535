import { Pipe, PipeTransform } from '@angular/core';
import {
  ACCEPTED_AUDIO_TAG_EXTENSION,
  ACCEPTED_IMG_TAG_EXTENSION,
  ACCEPTED_VIDEO_TAG_EXTENSION
} from '@simlab/data-access';
import { DesignSymbol } from '@simlab/design/internal';
import {
  DocumentStates,
  DocumentTypeEnum,
  DocumentTypeEnumValues,
  FileCardStates,
  FolderStates,
  RowStates
} from '@simlab/documents/models';

type TAcceptableExtensions =
  | (typeof ACCEPTED_IMG_TAG_EXTENSION)[number]
  | (typeof ACCEPTED_AUDIO_TAG_EXTENSION)[number]
  | (typeof ACCEPTED_VIDEO_TAG_EXTENSION)[number];

function isAcceptableExtension(arg: string): arg is TAcceptableExtensions {
  const values: string[] = [
    ...ACCEPTED_IMG_TAG_EXTENSION,
    ...ACCEPTED_AUDIO_TAG_EXTENSION,
    ...ACCEPTED_VIDEO_TAG_EXTENSION
  ];
  return values.includes(arg);
}
@Pipe({
  name: 'getDocumentIcon',
  standalone: true
})
export class DocumentIconGetterPipe<
  TData extends DocumentStates | FileCardStates | FolderStates
> implements PipeTransform
{
  private static readonly _iconsStore: Record<
    DocumentTypeEnumValues,
    DesignSymbol
  > = {
    [DocumentTypeEnum.Unknown]: DesignSymbol.File,
    [DocumentTypeEnum.Image]: DesignSymbol.ImageFile,
    [DocumentTypeEnum.Video]: DesignSymbol.AudioFile,
    [DocumentTypeEnum.Audio]: DesignSymbol.AudioFile,
    [DocumentTypeEnum.Doc]: DesignSymbol.DocumentFile,
    [DocumentTypeEnum.Model3D]: DesignSymbol.File,
    [DocumentTypeEnum.Blueprint]: DesignSymbol.File
  };

  private static readonly _iconsByExtStore: Record<
    TAcceptableExtensions,
    DesignSymbol
  > = {
    ...ACCEPTED_IMG_TAG_EXTENSION.reduce(
      (prev, act) => ({ ...prev, [act]: DesignSymbol.ImageFile }),
      {} as Record<TAcceptableExtensions, DesignSymbol>
    ),
    ...ACCEPTED_VIDEO_TAG_EXTENSION.reduce(
      (prev, act) => ({ ...prev, [act]: DesignSymbol.AudioFile }),
      {} as Record<TAcceptableExtensions, DesignSymbol>
    ),
    ...ACCEPTED_AUDIO_TAG_EXTENSION.reduce(
      (prev, act) => ({ ...prev, [act]: DesignSymbol.AudioFile }),
      {} as Record<TAcceptableExtensions, DesignSymbol>
    )
  };

  transform(data: TData['data']): DesignSymbol {
    if (this._isNotDirectory(data)) {
      let icon = DocumentIconGetterPipe._iconsStore['0'];
      const ext: string = data.name.split('.').pop() ?? '';
      if (data.documentType)
        icon = DocumentIconGetterPipe._iconsStore[data.documentType];
      else if (ext && isAcceptableExtension(ext)) {
        icon = DocumentIconGetterPipe._iconsByExtStore[ext];
      }

      return icon;
    }

    return DesignSymbol.FolderFill;
  }

  private _isNotDirectory(
    data: TData['data']
  ): data is FileCardStates['data'] | RowStates['data'] {
    return !data.isDirectory;
  }
}
