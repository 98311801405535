@if (selected(); as stage) {
  <div class="switch-container">
    <design-tooltip
      i18n-tooltip="@@SHOW_HIDE_TIMELINE"
      tooltip="show/hide timeline"
      position="top"
    >
      <button
        ui-mini-fab-button
        data-cy="timeline-toogle"
        (click)="toggle()"
        uiColor="primary"
      >
        <i
          ui-font
          fontName="arrow-big"
          fontSize="1.5rem"
          [fontRotateBy]="state() ? 90 : -90"
          fontColor="black"
        ></i>
      </button>
    </design-tooltip>
  </div>
  <div class="timeline-content" [@openClose]="state() ? 'open' : 'closed'">
    <div class="timeline-content__wrapper">
      <simlab-timeline-draggable (selectionChange)="selectionChange($event)">
      </simlab-timeline-draggable>
    </div>
  </div>
}
