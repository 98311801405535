import { inject } from '@angular/core';
import { documentsApiToken } from '@simlab/documents/data-access';
import { BehaviorSubject } from 'rxjs';
import { DocumentsFilters } from '../documents.store';

export abstract class DocumentsActionService {
  readonly _api = inject(documentsApiToken);
  readonly filtersChange = new BehaviorSubject<DocumentsFilters>({});

  getElementFull$(id: string) {
    return this._api.getDocument$(id);
  }

  getDirectory$(id: string) {
    return this._api.getDirectory$(id);
  }
}
