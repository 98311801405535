<ng-container #showcaseSocket></ng-container>
<ng-container *ngIf="hideOverlayElements === false && screenshotMode === false">
  <ng-container>
    <div
      class="timeline-wrapper"
      *ngIf="
        !localizationPanelOpen$ || (localizationPanelOpen$ | async) === false;
        else localizationEditor
      "
    >
      @if ((hasMatterport$ | async) && (hasSpinner$ | async) === false) {
        <div class="matterport__add-note-button">
          <div class="matterport__add-note-button--rigth-container">
            <design-tooltip
              i18n-tooltip="@@COMPARE_VIEW"
              tooltip="Compare view mode"
              position="left"
            >
              <button
                design-fab-button
                designButtonColor="primary"
                (click)="goToSplitView()"
              >
                <i designIcon="compare_view"></i>
              </button>
            </design-tooltip>
            <ng-container
              *ngIf="{ canAddNote: (canAddNote$ | async) } as permission"
            >
              <design-tooltip
                [tooltip]="
                  permission.canAddNote ? addNoteText : permissionDeniedText
                "
                position="left"
              >
                <button
                  *ngIf="matterport3DWalkControlToken?.addNote"
                  design-fab-button
                  designButtonColor="primary"
                  [disabled]="permission.canAddNote === false"
                  (click)="addNote()"
                >
                  <i designIcon="add"></i>
                </button>
              </design-tooltip>
            </ng-container>

            <div class="web-version">
              <ng-container
                *ngTemplateOutlet="measurement; context: { $implicit: 'right' }"
              >
              </ng-container>
            </div>

            <div class="mobile-version">
              <ng-container
                *ngTemplateOutlet="measurement; context: { $implicit: 'left' }"
              >
              </ng-container>
            </div>
          </div>
        </div>

        <ng-template #measurement let-direction>
          <design-fab-button-menu
            #menu
            designIconName="Ruler"
            [direction]="direction"
          >
            <button
              design-fab-button
              (click)="openMeasurementTool('distance'); menu.toggle()"
              designButtonColor="secondary"
            >
              <div class="fab-item-menu">
                <i designIcon="measure_tool" designSize="20px"></i>
                <div class="fab-item-menu__text">Distance</div>
              </div>
            </button>

            <button
              design-fab-button
              (click)="openMeasurementTool('area'); menu.toggle()"
              designButtonColor="secondary"
            >
              <div class="fab-item-menu">
                <i designIcon="area_measurements" designSize="20px"></i>
                <div class="fab-item-menu__text">Area</div>
              </div>
            </button>
          </design-fab-button-menu>
        </ng-template>
      }

      <div class="timeline-wrapper__timeline">
        <ng-container *ngIf="timelineModeExpanded$ | async; else condensed">
          <simlab-timeline data-cy="timeline"></simlab-timeline>
        </ng-container>
        <ng-template #condensed>
          <simlab-timeline-condensed
            data-cy="timeline-condensed"
          ></simlab-timeline-condensed>
        </ng-template>
      </div>
    </div>
    <ng-template #localizationEditor>
      <ng-container
        *ngIf="(hasMatterport$ | async) && (hasSpinner$ | async) === false"
      >
        <div class="timeline-wrapper">
          <ng-container
            *ngTemplateOutlet="tagPlacementContent() ?? null"
          ></ng-container>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
</ng-container>

<ui-matterport-loading
  data-cy="matterport-loading"
  *ngIf="(hasSpinner$ | async) || (isCompletelyLoaded$ | async) === false"
></ui-matterport-loading>

<ng-container
  *ngIf="(hasSpinner$ | async) === false && (isCompletelyLoaded$ | async)"
>
  <ng-container *ngIf="(hasMatterport$ | async) === false">
    <ng-container *ngIf="containStages$ | async">
      <div class="wrapper-image-info">
        <ng-container [ngSwitch]="oauthState$ | async">
          <!-- Matterport is private but outside of organization -->
          <ng-container *ngSwitchCase="'login'">
            <ui-image-info
              imageSrc="icon_no_access"
              i18n-header="@@NO_ACCESS"
              header="No access"
              [descriptions]="descriptionImageInfoNotExistComponent"
              [maxWidth]="300"
            ></ui-image-info>
            <button
              design-flat-button
              designButtonColor="accent"
              class="allow-events"
              [designFireOnClick]="refreshOpenScan$()"
            >
              <design-button-loader
                designIconSuffix
                designColor="surface"
              ></design-button-loader>
              <div i18n="@@MATTER_LOGIN">Login to Matterport</div>
            </button>
          </ng-container>

          <!-- Matterport is private/protected -->
          <ng-container *ngSwitchCase="'outside'">
            <ui-image-info
              imageSrc="icon_no_access"
              i18n-header="@@NO_ACCESS"
              header="No access"
              [descriptions]="descriptionImageInfoPrivateScan"
              [maxWidth]="300"
            ></ui-image-info>
            <button
              design-flat-button
              designButtonColor="accent"
              class="allow-events"
              [designFireOnClick]="refreshOpenScan$()"
            >
              <design-button-loader
                designIconSuffix
                designColor="surface"
              ></design-button-loader>
              <div i18n="@@MATTER_LOGIN">Login to Matterport</div>
            </button>
          </ng-container>

          <!-- <ng-container *ngSwitchCase="'notSynchronized'">
            <ui-image-info
              imageSrc="icon_matterport_not_found"
              i18n-header="@@PROJ_DASH_MATTER_NOT_SYNCHRO"
              header="Matterport not synchronized"
              [descriptions]="descriptionImageInfoMatterportNotSynchro"
              [maxWidth]="300"
            ></ui-image-info>
          </ng-container> -->

          <ng-container *ngSwitchCase="'not_enable_public_link'">
            <ui-image-info
              imageSrc="icon_matterport_not_found"
              i18n-header="@@NO_ACCESS"
              header="No access"
              [descriptions]="descriptionImageInfoPublicLink"
              [maxWidth]="300"
            ></ui-image-info>
          </ng-container>

          <!-- Matterport does not exist! -->
          <!-- <ng-container *ngSwitchDefault>
            <ui-image-info
              imageSrc="icon_matterport_not_found"
              i18n-header="@@MATTERPORT_NOT_FOUND"
              header="Matterport not found"
              [descriptions]="descriptionImageInfoMatterportNotFound"
              [maxWidth]="300"
            ></ui-image-info>
          </ng-container> -->
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
