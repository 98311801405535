import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  ViewEncapsulation
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { StageWithCount } from '@simlab/data-store';
import { canRenderProcoreEntity } from '@simlab/procore/data-access';
import { AnnotationItemInfoModule } from './annotation-item-info-card/annotation-item-info-card.component';

@Component({
  selector: 'feature-stages-annotation-item-info',
  standalone: true,
  imports: [AnnotationItemInfoModule],
  templateUrl: './annotation-item-info.component.html',
  styleUrl: './annotation-item-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'feature-stages-annotation-item-info' }
})
export class AnnotationItemInfoComponent {
  protected readonly projectConnectedWithProcore = toSignal(
    canRenderProcoreEntity(inject(ActivatedRoute).snapshot)
  );

  protected readonly inProgressNoteCount = computed(() => {
    return this.selectedStage().noteCounts?.inProgressRootNotesCount ?? 0;
  });

  protected readonly inPendingNoteCount = computed(() => {
    return this.selectedStage().noteCounts?.pendingRootNotesCount ?? 0;
  });

  protected readonly resolvedNoteCount = computed(() => {
    return this.selectedStage().noteCounts?.resolvedRootNotesCount ?? 0;
  });

  protected readonly unresolvedNoteCount = computed(() => {
    return this.selectedStage().noteCounts?.unresolvedRootNotesCount ?? 0;
  });

  protected readonly activeIssueNotesCount = computed(() => {
    const inProgressNoteCount = this.inProgressNoteCount();
    const inPendingNoteCount = this.inPendingNoteCount();
    const resolvedNoteCount = this.resolvedNoteCount();
    const unresolvedNoteCount = this.unresolvedNoteCount();

    return (
      inProgressNoteCount +
      inPendingNoteCount +
      resolvedNoteCount +
      unresolvedNoteCount
    );
  });

  protected readonly rfiCount = computed(() => {
    const selectedStage = this.selectedStage();

    return selectedStage.rfiCount ?? 0;
  });

  protected readonly rfiDraftAndOpenCount = computed(() => {
    const selectedStage = this.selectedStage();

    return (
      (selectedStage.rfiStatuses?.draft ?? 0) +
      (selectedStage.rfiStatuses?.open ?? 0)
    );
  });

  protected readonly rfiClosedCount = computed(() => {
    const selectedStage = this.selectedStage();

    return selectedStage.rfiStatuses?.closed ?? 0;
  });

  protected readonly punchListCount = computed(() => {
    const selectedStage = this.selectedStage();

    return selectedStage.punchItemCount ?? 0;
  });

  protected readonly punchItemWithActionCount = computed(() => {
    const selectedStage = this.selectedStage();
    return selectedStage.punchItemCounts?.punchItemsWithActionNumber ?? 0;
  });

  protected readonly punchItemWithNoActionCount = computed(() => {
    const selectedStage = this.selectedStage();

    return selectedStage.punchItemCounts?.punchItemsWithNoActionNumber ?? 0;
  });

  readonly selectedStage = input.required<StageWithCount>();
}
