/* eslint-disable @typescript-eslint/no-empty-function */
import { HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  WritableSignal,
  inject,
  input,
  output,
  signal
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';
import {
  IAddAreaMeasurementToGroups,
  IAddMeasurementToStage,
  IAreaMeasurementAction,
  IAssignAreaMeasurementToRootNote,
  IRemoveAreaMeasurementElement,
  IUpdateAreaMeasurementColor,
  IUpdateAreaMeasurementData,
  IUpdateMeasurementTitle,
  IVector3,
  NoteMeasurement,
  SystemOfMeasurement,
  TAddAreaMeasurementTo,
  TAddMeasurementsToGroup,
  TAddRootNote,
  TBaseUpdate,
  TMeasurementData,
  TMeasurementGroupBase,
  TMeasurementGroups,
  TRemoveAreaMeasurementFromGroup,
  TUpdateData,
  TVerticle,
  _IAreaMeasurementAction
} from '@simlab/feature-measurement/models';
import { TAreaMesh } from '@simlab/simlab-facility-management/sub-features/measurement';
import { RouterFacadeService } from '@simlab/util-shared';

import { TMeasurementMesh } from '@simlab/simlab-facility-management/sub-features/line-measurement';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  filter,
  finalize,
  first,
  firstValueFrom,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { Vector3 } from 'three';
import { MeasurementPermission } from '../../../services/measurement.privileges';
import { MeasurementFacade } from '@simlab/feature-measurement/state';

@Directive()
// IAreaMeasurementAction<_IAreaMeasurementsGroupAction>,
export class _MeasurementBase
  extends MeasurementPermission
  implements
    IAreaMeasurementAction<_IAreaMeasurementAction>,
    OnDestroy,
    OnInit,
    AfterViewInit
{
  readonly stageId = input.required<string>();
  readonly noteId = input.required<string | undefined>();
  protected readonly _measurementFacade = inject(MeasurementFacade);
  private readonly _routingFacade = inject(RouterFacadeService);
  readonly measurements = toSignal(this._measurementFacade.allMeasurement$);
  readonly notes = input.required<NoteMeasurement[]>();

  @Input() cancel!: Observable<void>;

  readonly measurementsGroup: WritableSignal<TMeasurementGroups[]> = signal<
    TMeasurementGroups[]
  >([]);
  readonly allAreaHidden = toSignal(
    this._measurementFacade.allMeasurementsHidden$
  );
  readonly openEditor: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  @Output() onOpenEditor = this.openEditor.asObservable();
  private readonly _editAction: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);
  private readonly _addAction: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);
  private readonly _goTo: Subject<IVector3> = new Subject<IVector3>();
  readonly goTo = this._goTo.asObservable();
  readonly opened = input.required<boolean>();
  readonly onAdd = output();
  readonly onGoTo = output<Vector3>();
  readonly editAction = this._editAction.asObservable().pipe(filter((e) => e));
  readonly addAction = this._addAction.asObservable().pipe(filter((e) => e));
  readonly deleteAction: EventEmitter<string> = new EventEmitter<string>();
  readonly systemOfMeasurement = input<SystemOfMeasurement>();
  readonly editData =
    input.required<Observable<Required<TAreaMesh | TMeasurementMesh>>>();
  private readonly _selectedMeasurement = toSignal(
    this._measurementFacade.selectedMeasurement$
  );
  private readonly _destroySource: Subject<void> = new Subject<void>();

  readonly onCreateNote = output<{ name: string; stageId: string }>();
  readonly successCreateNote$ = input<Observable<string>>();
  readonly onOpenRelatedNote = output<string>();
  set selectedMeasurement(selectedMeasurement: string | undefined) {
    this._measurementFacade.selectedMeasurement(selectedMeasurement);
  }
  get selectedMeasurement(): string | undefined {
    return this._selectedMeasurement()?.id || undefined;
  }
  get editorOpened() {
    return this.openEditor.getValue();
  }

  ngOnInit(): void {
    firstValueFrom(this._getGroups(this.stageId()));
  }

  ngAfterViewInit(): void {
    const measurements = this.measurements();
    if (measurements?.length) {
      if (!this.selectedMeasurement)
        this._measurementFacade.selectedMeasurement(measurements[0].id);
    }
  }
  goToSelected = () => {
    const selected = this._selectedMeasurement();
    if (!selected) return;
    const min: Vector3 = new Vector3(9999, 9999, 9999);
    const max: Vector3 = new Vector3(-9999, -9999, -9999);
    //calc box
    const verticle = selected.data.vertices || selected.data;

    verticle.forEach((vertex: TVerticle) => {
      //calc min
      if (vertex.x < min.x) min.setX(vertex.x);
      if (vertex.y < min.y) min.setY(vertex.y);
      if (vertex.z < min.z) min.setZ(vertex.z);

      //calc max
      if (vertex.x > max.x) max.setX(vertex.x);
      if (vertex.y > max.y) max.setY(vertex.y);
      if (vertex.z > max.z) max.setZ(vertex.z);
    });
    //calc box center
    const center = new Vector3();
    center.addVectors(min, max).multiplyScalar(0.5);
    this.onGoTo.emit(center);
  };
  ngOnDestroy(): void {
    this._destroySource.next();

    this.selectedMeasurement = undefined;
    // firstValueFrom(this._measurmentFacade.allMeasurementsHidden$)
    //   .then((hidden) => this.hideAllArea(hidden))
    //   .catch();
  }
  private _getGroups(stageId: string) {
    return this._measurementFacade.getMeasurementsGroup$(stageId).pipe(
      tap((group) => {
        this.measurementsGroup.set(group);
      })
    );
    // return this._stageFacade.selectedId$.pipe(
    //   filter((stageId: string) => !!stageId),
    //   switchMap((stageId: string) => {
    //     return this._measurmentFacade.areaMeasurementsGroup$(stageId);
    //   }),
    //   tap((group) => this.measurementsGroup.set(group))
    // );
  }
  exportMeasurements() {
    const ids = this.measurements()?.map((e) => e.id);
    if (!ids) return;
    firstValueFrom(
      this._measurementFacade
        .exportMeasurements('export', ids)
        .pipe(tap((response) => this._downloadAsCsv(response)))
    );
  }
  private _downloadAsCsv(data: HttpResponse<Blob>): void {
    if (data.body) {
      const newBlob = new Blob([data.body], { type: 'text/csv' });
      const fileURL = URL.createObjectURL(newBlob);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      const contentDisposition = data.headers.get('Content-Disposition');
      const fileName =
        contentDisposition?.split('filename=')[1].split(';')[0] || 'export';
      fileLink.download = fileName;

      fileLink.click();
    }
  }

  private _checkMeasurementGroupPermission(groupId: string) {
    const group = this.measurementsGroup().find((e) => e.id === groupId);
    if (!group || !this.canEditDeleteMeasurementGroup(group.creatorId))
      throw Error('Insufficient permission');
  }

  addMeasurementsToGroup = (payload: TAddMeasurementsToGroup) => {
    this._checkMeasurementGroupPermission(payload.groupId);
    this._measurementFacade
      .addMeasurementsToGroup$(payload)
      .pipe(
        switchMap(() => this._getGroups(this.stageId()).pipe(first())),
        catchError((e) => {
          console.log(e);
          return of(e);
        }),
        first()
      )
      .subscribe();
  };
  removeMeasurementFromGroup = (payload: TRemoveAreaMeasurementFromGroup) => {
    this._checkMeasurementGroupPermission(payload.groupId);
    this._measurementFacade
      .removeMeasurementFromGroup$(payload)
      .pipe(
        tap(() => {
          this.measurementsGroup.update((groups: TMeasurementGroups[]) =>
            groups.map((group) => {
              if (group.id !== payload.groupId) return group;
              return {
                ...group,
                measurements: group.measurements.filter(
                  (measurement) => measurement.id !== payload.id
                )
              };
            })
          );
        }),
        catchError((e) => {
          console.log(e);
          return of(e);
        }),
        first()
      )
      .subscribe();
  };
  goToMeasurementList(payload: string) {
    this._measurementFacade.selectedMeasurement(payload);
  }
  createGroup(name: string) {
    if (!this.canAddMeasurementGroup()) throw Error('Insufficient permission');

    this._measurementFacade.addMeasurementGroup({
      name,
      stageId: this.stageId()
    });
    return firstValueFrom(
      this._measurementFacade.addMeasurementGroupSuccess$.pipe(
        switchMap((id: string) =>
          this._getGroups(this.stageId()).pipe(
            map((groups) => groups.find((group) => group.id === id))
          )
        ),
        map((e) => {
          if (e) return e;

          throw 'Undefined';
        })
      )
    );
  }
  changeName = (payload: TMeasurementGroupBase) => {
    const group = this.measurementsGroup().find((e) => e.id === payload.id);
    if (!group || !this.canEditDeleteMeasurementGroup(group.creatorId))
      throw Error('Insufficient permission');

    firstValueFrom(
      this._measurementFacade.renameMeasurementGroup(payload).pipe(
        switchMap(() => this._getGroups(this.stageId()).pipe(first())),
        catchError((e) => {
          console.log(e);
          return of(e);
        })
      )
    );
  };

  deleteMeasurementGroup = (payload: IRemoveAreaMeasurementElement) => {
    this._checkMeasurementGroupPermission(payload.id);
    firstValueFrom(
      this._measurementFacade.deleteMeasurementGroup(payload.id).pipe(
        tap(() =>
          this.measurementsGroup.update((groups: TMeasurementGroups[]) =>
            groups.filter(({ id }) => id !== payload.id)
          )
        ),
        catchError((e) => {
          console.log(e);
          return of(e);
        })
      )
    );
  };
  deleteAreaMeasurement = (payload: IRemoveAreaMeasurementElement) => {
    this._checkMeasurementPermission(payload.id);
    this._measurementFacade.deleteMeasurement(payload.id);
    this.measurementsGroup.update((groups: TMeasurementGroups[]) =>
      groups.map((group) => ({
        ...group,
        measurements: group.measurements.filter(
          (measurement) => measurement.id !== payload.id
        )
      }))
    );
  };

  assignToNote = async (payload: IAssignAreaMeasurementToRootNote) => {
    if (!payload.rootNoteId) {
      if (!payload.name) return;
      const successCreateNote = this.successCreateNote$();
      if (successCreateNote) {
        firstValueFrom(successCreateNote).then((id) =>
          this._measurementFacade.assignMeasurementToNote({
            id: payload.id,
            rootNoteId: id
          })
        );

        this.onCreateNote.emit({ name: payload.name, stageId: this.stageId() });
      }
    } else {
      this._measurementFacade.assignMeasurementToNote(payload);
    }
  };
  unassignFromNote = (payload: TBaseUpdate) => {
    this._measurementFacade.unassignMeasurementFromNote(payload);
  };
  addMeasurementToGroups = (payload: IAddAreaMeasurementToGroups) => {
    const groups = this.measurementsGroup().filter((e) =>
      payload.groupsIds.includes(e.id)
    );
    if (
      !groups.length ||
      groups.some(
        (group) => !this.canEditDeleteMeasurementGroup(group.creatorId)
      )
    )
      throw Error('Insufficient permission');
    this._measurementFacade
      .addMeasurementToGroups$(payload)
      .pipe(
        switchMap(() => this._getGroups(this.stageId()).pipe(first())),
        catchError((e) => {
          console.log(e);
          return of(e);
        }),
        first()
      )
      .subscribe();
  };
  changeColor = (payload: IUpdateAreaMeasurementColor) => {
    this._checkMeasurementPermission(payload.id);
    this._measurementFacade.updateMeasurementColor(payload);
  };

  changeTitle = (payload: IUpdateMeasurementTitle) => {
    this._checkMeasurementPermission(payload.id);
    this._measurementFacade.updateMeasurementTitle(payload);
  };

  hideAllArea = (hideAllArea: boolean) =>
    this._measurementFacade.hideAllMeasurements(hideAllArea);

  private _checkMeasurementPermission(id: string) {
    const creatorId = this.measurements()?.find(
      (measure) => measure.id === id
    )?.creatorId;
    if (!creatorId || !this.canEditDeleteMeasurement(creatorId))
      throw Error('Insufficient permission');
  }

  edit(payload: IUpdateAreaMeasurementData) {
    this._checkMeasurementPermission(payload.id);
    this.openEditor.next(true);
    this._editAction.next(true);
    this.editData()
      .pipe(
        tap((test) => {
          let items: IVector3[] | TMeasurementData;
          if ('vertices' in test) {
            const { surfaceSize, triangles, vertices } =
              test as Required<TAreaMesh>;
            items = {
              vertices,
              triangles,
              surfaceSize
            };
            if (triangles.length !== vertices.length - 2) {
              console.error('blad');
              return;
            }
          } else {
            items = (test as Required<TMeasurementMesh>).points;
          }

          const updateAreaMeasurement: TUpdateData = {
            id: test.id,
            data: items
          };
          this._measurementFacade.updateMeasurementData(updateAreaMeasurement);
          this.openEditor.next(false);
        }),
        takeUntil(this.cancel),
        take(1),
        catchError((e) => {
          console.log(e);
          return of(e);
        }),
        finalize(
          () => (this.openEditor.next(false), this._editAction.next(false))
        )
      )
      .subscribe();
  }
  add(addMeasurementTo: TAddAreaMeasurementTo | void) {
    if (!this.canAddMeasurement()) throw Error('Insufficient permission');
    this.openEditor.next(true);
    //this._addAction.next(true);
    this.onAdd.emit();

    this.editData()
      .pipe(
        tap((data) => this.deleteAction.emit(data.id)),
        tap((data) => {
          let items: IVector3[] | TMeasurementData;
          if ('vertices' in data) {
            const { color, surfaceSize, triangles, vertices } =
              data as Required<TAreaMesh>;
            items = {
              vertices,
              triangles,
              surfaceSize
            };
            if (triangles.length !== vertices.length - 2) {
              console.error('blad');
              return;
            }
          } else {
            items = (data as Required<TMeasurementMesh>).points;
          }

          const rootNoteId = this.noteId();
          if (addMeasurementTo === 'note' && rootNoteId) {
            const addAreaMeasurement: TAddRootNote = {
              rootNoteId,
              title: '',
              color: data.color,
              data: items
            };
            this._measurementFacade.addMeasurementToRootNote(
              addAreaMeasurement
            );
          } else {
            const addAreaMeasurement: IAddMeasurementToStage = {
              stageId: this.stageId(),
              title: '',
              color: data.color,
              data: items
            };
            this._measurementFacade.addMeasurement(addAreaMeasurement);
          }
        }),
        takeUntil(this.cancel),
        take(1),
        finalize(
          () => (this.openEditor.next(false), this._addAction.next(false))
        )
      )
      .subscribe();
  }
  openRelatedNote(noteId: string) {
    this.onOpenRelatedNote.emit(noteId);
    const queryParams: Params = { sidenavContent: 'note' };
    this._routingFacade.setQueryParams(undefined, queryParams, 'merge');
  }
}
