/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-class-suffix */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  DOWN_ARROW,
  ENTER,
  ESCAPE,
  hasModifierKey,
  TAB,
  UP_ARROW,
} from '@angular/cdk/keycodes';
import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
  ScrollStrategy,
  ViewportRuler,
} from '@angular/cdk/overlay';
import { _getEventTarget } from '@angular/cdk/platform';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {
  DesignOptionBase,
  DesignOptionSelectionChange,
} from '@simlab/design/common';
import {
  DesignFormField,
  designFormFieldToken,
} from '@simlab/design/form-field';

import {
  defer,
  delay,
  filter,
  fromEvent,
  map,
  merge,
  Observable,
  of,
  startWith,
  Subject,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { AutocompleteControl } from '../models/autocomplete-control.interface';
import { designAutocompleteControlToken } from '../tokens/autocomplete-control.token';
import { AutocompleteBase } from './autocomplete-base.directive';
import { AutocompleteOriginBase } from './autocomplete-origin.directive';

declare const ngDevMode: boolean;

@Directive({
  selector: 'input[designAutocompleteTrigger]',
  standalone: true,
  host: {
    '(focusin)': '_handleFocus()',
    '(blur)': '_onTouched()',
    '(input)': '_handleInput($event)',
    '(keydown)': '_handleKeydown($event)',
    '(click)': '_handleClick()',
  },
})
export class DesignAutocompleteTrigger
  implements OnDestroy, AfterViewInit, OnChanges
{
      private readonly _viewContainerRef = inject(ViewContainerRef);
      private readonly _overlay = inject(Overlay);
      private readonly _zone = inject(NgZone);
      private readonly _changeDetectorRef = inject(ChangeDetectorRef);
      private readonly _elementRef = inject<ElementRef<HTMLInputElement>>(ElementRef<HTMLInputElement>);
      private readonly _viewportRuler = inject(ViewportRuler);
      private _document = inject<any>(DOCUMENT, { optional: true });
      private _formField = inject<DesignFormField | null>(designFormFieldToken, { optional: true, host: true });
      private _designAutocompleteControlToken = inject<AutocompleteControl>(designAutocompleteControlToken, { optional: true });
  /**
   * Position of the autocomplete panel relative to the trigger element. A position of `auto`
   * will render the panel underneath the trigger if there is enough space for it to fit in
   * the viewport, otherwise the panel will be shown above it. If the position is set to
   * `above` or `below`, the panel will always be shown above or below the trigger. no matter
   * whether it fits completely in the viewport.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('designAutocompletePosition')
  position: 'auto' | 'above' | 'below' = 'auto';

  /** The autocomplete panel to be attached to this trigger. */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('designAutocompleteTrigger')
  set autocomplete(value: AutocompleteBase) {
    this._autocomplete = value;
  }
  get autocomplete(): AutocompleteBase {
    return this._autocomplete;
  }
  private _autocomplete!: AutocompleteBase;

  private _overlayRef!: OverlayRef | null;
  private _portal!: TemplatePortal;

  /**
   * Current option that we have auto-selected as the user is navigating,
   * but which hasn't been propagated to the model value yet.
   */
  private _pendingAutoselectedOption!: DesignOptionBase | null;

  private _componentDestroyed = false;

  /** Subscription to viewport size changes. */
  private _viewportSubscription = Subscription.EMPTY;

  /**
   * Reference relative to which to position the autocomplete panel.
   * Defaults to the autocomplete trigger element.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('designAutocompleteConnectedTo')
  set connectedTo(value: AutocompleteOriginBase) {
    this._connectedTo = value;
  }
  get connectedTo(): AutocompleteOriginBase {
    return this._connectedTo;
  }
  private _connectedTo!: AutocompleteOriginBase;

  /** The subscription for closing actions (some are bound to document). */
  private _closingActionsSubscription!: Subscription;

  /** Stream of keyboard events that can close the panel. */
  private readonly _closeKeyEventStream = new Subject<void>();

  /** Strategy that is used to position the panel. */
  private _positionStrategy!: FlexibleConnectedPositionStrategy;

  /** Whether or not the autocomplete panel is open. */
  get panelOpen(): boolean {
    return this._overlayAttached && this.autocomplete.showPanel;
  }
  private _overlayAttached = false;

  /** Value inside the input before we auto-selected an option. */
  private _valueBeforeAutoSelection: string | undefined;

  /** Stream of changes to the selection state of the autocomplete options. */
  readonly optionSelections: Observable<DesignOptionSelectionChange> = defer(
    () => {
      const options = this.autocomplete ? this.autocomplete.options : null;

      if (options) {
        return options.changes.pipe(
          startWith(options),
          switchMap(() =>
            merge(...options.map((option) => option.selectionChange))
          )
        );
      }

      // If there are any subscribers before `ngAfterViewInit`, the `autocomplete` will be undefined.
      // Return a stream that we'll replace with the real one once everything is in place.
      return this._zone.onStable.pipe(
        take(1),
        switchMap(() => this.optionSelections)
      );
    }
  ) as Observable<DesignOptionSelectionChange>;

  /**
   * A stream of actions that should close the autocomplete panel, including
   * when an option is selected, on blur, and when TAB is pressed.
   */
  get panelClosingActions(): Observable<DesignOptionSelectionChange | null> {
    return merge(
      this.optionSelections,
      this.autocomplete._keyManager.tabOut.pipe(
        filter(() => this._overlayAttached)
      ),
      this._closeKeyEventStream,
      this._getOutsideClickStream(),
      this._overlayRef
        ? this._overlayRef
            .detachments()
            .pipe(filter(() => this._overlayAttached))
        : of()
    ).pipe(
      // Normalize the output so we return a consistent type.
      map((event) =>
        event instanceof DesignOptionSelectionChange ? event : null
      )
    );
  }

  /**
   * Whether the autocomplete is disabled. When disabled, the element will
   * act as a regular input and the user won't be able to open the panel.
   */
  @Input('designAutocompleteDisabled')
  get autocompleteDisabled(): boolean {
    return this._autocompleteDisabled;
  }
  set autocompleteDisabled(value: BooleanInput) {
    this._autocompleteDisabled = coerceBooleanProperty(value);
  }
  private _autocompleteDisabled = false;

  /** Old value of the native input. Used to work around issues with the `input` event on IE. */
  private _previousValue!: string | number | null;

  /**
   * Whether the autocomplete can open the next time it is focused. Used to prevent a focused,
   * closed autocomplete from being reopened if the user switches to another browser tab and then
   * comes back.
   */
  private _canOpenOnNextFocus = true;

  /** Class to apply to the panel when it's above the input. */
  protected _aboveClass = 'design-autocomplete-panel-above';

  private _scrollStrategy: () => ScrollStrategy;

  /**
   * Event handler for when the window is blurred. Needs to be an
   * arrow function in order to preserve the context.
   */
  private _windowBlurHandler = () => {
    // If the user blurred the window while the autocomplete is focused, it means that it'll be
    // refocused when they come back. In this case we want to skip the first focus event, if the
    // pane was closed, in order to avoid reopening it unintentionally.
    this._canOpenOnNextFocus =
      this._document.activeElement !== this._elementRef.nativeElement ||
      this.panelOpen;
  };

  constructor(
) {
    this._scrollStrategy = () => this._overlay.scrollStrategies.reposition();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['position'] && this._positionStrategy) {
      this._setStrategyPositions(this._positionStrategy);

      if (this.panelOpen) {
        this._overlayRef?.updatePosition();
      }
    }
  }
  ngAfterViewInit(): void {
    const window = this._getWindow();

    if (typeof window !== 'undefined') {
      this._zone.runOutsideAngular(() =>
        window.addEventListener('blur', this._windowBlurHandler)
      );
    }
  }
  ngOnDestroy(): void {
    const window = this._getWindow();

    if (typeof window !== 'undefined') {
      window.removeEventListener('blur', this._windowBlurHandler);
    }

    this._viewportSubscription.unsubscribe();
    this._componentDestroyed = true;
    this._destroyPanel();
    this._closeKeyEventStream.complete();
  }

  _handleClick(): void {
    if (this._canOpen() && !this.panelOpen) {
      this.openPanel();
    }
  }

  private _attachOverlay(): void {
    if (!this.autocomplete && (typeof ngDevMode === 'undefined' || ngDevMode)) {
      // throw getMatAutocompleteMissingPanelError();
    }

    let overlayRef = this._overlayRef;

    if (!overlayRef) {
      this._portal = new TemplatePortal(
        this.autocomplete.template,
        this._viewContainerRef,
        {
          id: this._formField?.getLabelId(),
        }
      );
      overlayRef = this._overlay.create(this._getOverlayConfig());

      this._overlayRef = overlayRef;
      this._handleOverlayEvents(overlayRef);
      this._viewportSubscription = this._viewportRuler
        .change()
        .subscribe(() => {
          if (this.panelOpen && overlayRef) {
            overlayRef.updateSize({ width: this._getPanelWidth() });
          }
        });
    } else {
      // Update the trigger, panel width and direction, in case anything has changed.
      this._positionStrategy.setOrigin(this._getConnectedElement());
      overlayRef.updateSize({ width: this._getPanelWidth() });
    }

    if (overlayRef && !overlayRef.hasAttached()) {
      overlayRef.attach(this._portal);
      this._closingActionsSubscription = this._subscribeToClosingActions();
    }

    const wasOpen = this.panelOpen;

    this.autocomplete._setVisibility();
    this.autocomplete.isOpen = this._overlayAttached = true;
    // this.autocomplete._setColor(this._formField?.color);

    // We need to do an extra `panelOpen` check in here, because the
    // autocomplete won't be shown if there are no options.
    if (this.panelOpen && wasOpen !== this.panelOpen) {
      this.autocomplete.opened.emit();
    }
  }

  /** Use defaultView of injected document if available or fallback to global window reference */
  private _getWindow(): Window {
    return this._document?.defaultView || window;
  }

  /**
   * This method listens to a stream of panel closing actions and resets the
   * stream every time the option list changes.
   */
  private _subscribeToClosingActions(): Subscription {
    const firstStable = this._zone.onStable.pipe(take(1));
    const optionChanges = this.autocomplete.options.changes.pipe(
      tap(() => this._positionStrategy.reapplyLastPosition()),
      // Defer emitting to the stream until the next tick, because changing
      // bindings in here will cause "changed after checked" errors.
      delay(0)
    );

    // When the zone is stable initially, and when the option list changes...
    return (
      merge(firstStable, optionChanges)
        .pipe(
          // create a new stream of panelClosingActions, replacing any previous streams
          // that were created, and flatten it so our stream only emits closing events...
          switchMap(() => {
            // The `NgZone.onStable` always emits outside of the Angular zone, thus we have to re-enter
            // the Angular zone. This will lead to change detection being called outside of the Angular
            // zone and the `autocomplete.opened` will also emit outside of the Angular.
            this._zone.run(() => {
              const wasOpen = this.panelOpen;
              this._resetActiveItem();
              this.autocomplete._setVisibility();
              this._changeDetectorRef.detectChanges();

              if (this.panelOpen) {
                this._overlayRef?.updatePosition();
              }

              if (wasOpen !== this.panelOpen) {
                // If the `panelOpen` state changed, we need to make sure to emit the `opened` or
                // `closed` event, because we may not have emitted it. This can happen
                // - if the users opens the panel and there are no options, but the
                //   options come in slightly later or as a result of the value changing,
                // - if the panel is closed after the user entered a string that did not match any
                //   of the available options,
                // - if a valid string is entered after an invalid one.
                if (this.panelOpen) {
                  this.autocomplete.opened.emit();
                } else {
                  this.autocomplete.closed.emit();
                }
              }
            });

            return this.panelClosingActions;
          }),
          // when the first closing event occurs...
          take(1)
        )
        // set the value, close the panel, and complete.
        .subscribe((event) => this._setValueAndClose(event))
    );
  }

  /**
   * Resets the active item to -1 so arrow events will activate the
   * correct options, or to 0 if the consumer opted into it.
   */
  private _resetActiveItem(): void {
    const autocomplete = this.autocomplete;

    if (autocomplete.autoActiveFirstOption) {
      // Note that we go through `setFirstItemActive`, rather than `setActiveItem(0)`, because
      // the former will find the next enabled option, if the first one is disabled.
      autocomplete._keyManager.setFirstItemActive();
    } else {
      autocomplete._keyManager.setActiveItem(-1);
    }
  }

  /** Stream of clicks outside of the autocomplete panel. */
  private _getOutsideClickStream(): Observable<any> {
    return merge(
      fromEvent(this._document, 'click') as Observable<MouseEvent>,
      fromEvent(this._document, 'auxclick') as Observable<MouseEvent>,
      fromEvent(this._document, 'touchend') as Observable<TouchEvent>
    ).pipe(
      filter((event) => {
        // If we're in the Shadow DOM, the event target will be the shadow root, so we have to
        // fall back to check the first element in the path of the click event.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const clickTarget = _getEventTarget<HTMLElement>(event)!;
        const formField = this._formField
          ? this._formField.elementRef.nativeElement
          : null;
        const customOrigin = this.connectedTo
          ? this.connectedTo.elementRef.nativeElement
          : null;

        return (
          this._overlayAttached &&
          clickTarget !== this._elementRef.nativeElement &&
          // Normally focus moves inside `mousedown` so this condition will almost always be
          // true. Its main purpose is to handle the case where the input is focused from an
          // outside click which propagates up to the `body` listener within the same sequence
          // and causes the panel to close immediately (see #3106).
          this._document.activeElement !== this._elementRef.nativeElement &&
          (!formField || !formField.contains(clickTarget)) &&
          (!customOrigin || !customOrigin.contains(clickTarget)) &&
          !!this._overlayRef &&
          !this._overlayRef.overlayElement.contains(clickTarget)
        );
      })
    );
  }

  /**
   * This method closes the panel, and if a value is specified, also sets the associated
   * control to that value. It will also mark the control as dirty if this interaction
   * stemmed from the user.
   */
  private _setValueAndClose(event: DesignOptionSelectionChange | null): void {
    const toSelect = event ? event.source : this._pendingAutoselectedOption;

    if (toSelect) {
      this._clearPreviousSelectedOption(toSelect);
      this._assignOptionValue(toSelect.value);
      // this._onChange(toSelect.value); //TODO?
      this.autocomplete._emitSelectEvent(toSelect);
      this._elementRef.nativeElement.focus();
    }

    this.closePanel();
  }

  /**
   * Clear any previous selected option and emit a selection change event for this option
   */
  private _clearPreviousSelectedOption(skip: DesignOptionBase) {
    this.autocomplete.options.forEach((option) => {
      if (option !== skip && option.selected) {
        option.deselect();
      }
    });
  }

  private _assignOptionValue(value: any): void {
    const toDisplay =
      this.autocomplete && this.autocomplete.displayWith
        ? this.autocomplete.displayWith(value)
        : value;

    // Simply falling back to an empty string if the display value is falsy does not work properly.
    // The display value can also be the number zero and shouldn't fall back to an empty string.
    this._updateNativeInputValue(toDisplay != null ? toDisplay : '');
  }

  private _updateNativeInputValue(value: any): void {
    // If it's used within a `MatFormField`, we should set it through the property so it can go
    // through change detection.
    if (this._formField) {
      this._formField.control.value = value;
    } else {
      this._elementRef.nativeElement.value = value;
    }

    this._previousValue = value;
  }

  /** Closes the autocomplete suggestion panel. */
  closePanel(): void {
    // this._resetLabel();

    if (!this._overlayAttached) {
      return;
    }

    if (this.panelOpen) {
      // Only emit if the panel was visible.
      // The `NgZone.onStable` always emits outside of the Angular zone,
      // so all the subscriptions from `_subscribeToClosingActions()` are also outside of the Angular zone.
      // We should manually run in Angular zone to update UI after panel closing.
      this._zone.run(() => {
        this.autocomplete.closed.emit();
      });
    }

    this.autocomplete.isOpen = this._overlayAttached = false;
    this._pendingAutoselectedOption = null;

    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
      this._closingActionsSubscription.unsubscribe();
    }

    // Note that in some cases this can end up being called after the component is destroyed.
    // Add a check to ensure that we don't try to run change detection on a destroyed view.
    if (!this._componentDestroyed) {
      // We need to trigger change detection manually, because
      // `fromEvent` doesn't seem to do it at the proper time.
      // This ensures that the label is reset when the
      // user clicks outside.
      this._changeDetectorRef.detectChanges();
    }
  }

  /** Destroys the autocomplete suggestion panel. */
  private _destroyPanel(): void {
    if (this._overlayRef) {
      this.closePanel();
      this._overlayRef.dispose();
      this._overlayRef = null;
    }
  }

  /** Opens the autocomplete suggestion panel. */
  openPanel(): void {
    this._attachOverlay();
    // this._floatLabel();
  }

  /** Determines whether the panel can be opened. */
  private _canOpen(): boolean {
    const element = this._elementRef.nativeElement;
    return (
      !element.readOnly && !element.disabled && !this._autocompleteDisabled
    );
  }

  _handleFocus(): void {
    if (!this._canOpenOnNextFocus) {
      this._canOpenOnNextFocus = true;
    } else if (this._canOpen()) {
      this._previousValue = this._elementRef.nativeElement.value;
      this._attachOverlay();
      // this._floatLabel(true);
    }
  }

  _onTouched(): void {
    if (this._designAutocompleteControlToken) {
      this._designAutocompleteControlToken.onTouched();
    }
  }

  _onChange(value: any): void {
    if (this._designAutocompleteControlToken) {
      this._designAutocompleteControlToken.onChange(value);
      this._designAutocompleteControlToken.stateChanges.next();
    }
  }

  _handleInput(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;
    let value: number | string | null = target.value;

    // Based on `NumberValueAccessor` from forms.
    if (target.type === 'number') {
      value = value == '' ? null : parseFloat(value);
    }

    // If the input has a placeholder, IE will fire the `input` event on page load,
    // focus and blur, in addition to when the user actually changed the value. To
    // filter out all of the extra events, we save the value on focus and between
    // `input` events, and we check whether it changed.
    // See: https://connect.microsoft.com/IE/feedback/details/885747/
    if (this._previousValue !== value) {
      this._previousValue = value;
      this._pendingAutoselectedOption = null;
      this._onChange(value);

      if (this._canOpen() && this._document.activeElement === event.target) {
        this.openPanel();
      }
    }
  }

  _handleKeydown(event: KeyboardEvent): void {
    const keyCode = event.keyCode;
    const hasModifier = hasModifierKey(event);

    // Prevent the default action on all escape key presses. This is here primarily to bring IE
    // in line with other browsers. By default, pressing escape on IE will cause it to revert
    // the input value to the one that it had on focus, however it won't dispatch any events
    // which means that the model value will be out of sync with the view.
    if (keyCode === ESCAPE && !hasModifier) {
      event.preventDefault();
    }

    if (
      this.activeOption &&
      keyCode === ENTER &&
      this.panelOpen &&
      !hasModifier
    ) {
      this.activeOption._selectViaInteraction();
      this._resetActiveItem();
      event.preventDefault();
    } else if (this.autocomplete) {
      const prevActiveItem = this.autocomplete._keyManager.activeItem;
      const isArrowKey = keyCode === UP_ARROW || keyCode === DOWN_ARROW;

      if (keyCode === TAB || (isArrowKey && !hasModifier && this.panelOpen)) {
        this.autocomplete._keyManager.onKeydown(event);
      } else if (isArrowKey && this._canOpen()) {
        this.openPanel();
      }

      if (
        isArrowKey ||
        this.autocomplete._keyManager.activeItem !== prevActiveItem
      ) {
        // this._scrollToOption(
        //   this.autocomplete._keyManager.activeItemIndex || 0
        // );

        if (this.autocomplete.autoSelectActiveOption && this.activeOption) {
          if (!this._pendingAutoselectedOption) {
            this._valueBeforeAutoSelection =
              this._elementRef.nativeElement.value;
          }

          this._pendingAutoselectedOption = this.activeOption;
          this._assignOptionValue(this.activeOption.value);
        }
      }
    }
  }

  /** The currently active option, coerced to MatOption type. */
  get activeOption(): DesignOptionBase | null {
    if (this.autocomplete && this.autocomplete._keyManager) {
      return this.autocomplete._keyManager.activeItem;
    }

    return null;
  }

  private _getPanelWidth(): number | string {
    return this.autocomplete.panelWidth || this._getHostWidth();
  }

  /** Sets the positions on a position strategy based on the directive's input state. */
  private _setStrategyPositions(
    positionStrategy: FlexibleConnectedPositionStrategy
  ) {
    // Note that we provide horizontal fallback positions, even though by default the dropdown
    // width matches the input, because consumers can override the width. See #18854.
    const belowPositions: ConnectedPosition[] = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
      { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
    ];

    // The overlay edge connected to the trigger should have squared corners, while
    // the opposite end has rounded corners. We apply a CSS class to swap the
    // border-radius based on the overlay position.
    const panelClass = this._aboveClass;
    const abovePositions: ConnectedPosition[] = [
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        panelClass,
      },
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        panelClass,
      },
    ];

    let positions: ConnectedPosition[];

    if (this.position === 'above') {
      positions = abovePositions;
    } else if (this.position === 'below') {
      positions = belowPositions;
    } else {
      positions = [...belowPositions, ...abovePositions];
    }

    positionStrategy.withPositions(positions);
  }

  /** Returns the width of the input element, so the panel width can match it. */
  private _getHostWidth(): number {
    return this._getConnectedElement().nativeElement.getBoundingClientRect()
      .width;
  }

  private _getConnectedElement(): ElementRef<HTMLElement> {
    if (this.connectedTo) {
      return this.connectedTo.elementRef;
    }

    return this._formField ? this._formField.elementRef : this._elementRef;
  }

  /** Handles keyboard events coming from the overlay panel. */
  private _handleOverlayEvents(overlayRef: OverlayRef) {
    // Use the `keydownEvents` in order to take advantage of
    // the overlay event targeting provided by the CDK overlay.
    overlayRef.keydownEvents().subscribe((event) => {
      // Close when pressing ESCAPE or ALT + UP_ARROW, based on the a11y guidelines.
      // See: https://www.w3.org/TR/wai-aria-practices-1.1/#textbox-keyboard-interaction
      if (
        (event.keyCode === ESCAPE && !hasModifierKey(event)) ||
        (event.keyCode === UP_ARROW && hasModifierKey(event, 'altKey'))
      ) {
        // If the user had typed something in before we autoselected an option, and they decided
        // to cancel the selection, restore the input value to the one they had typed in.
        if (this._pendingAutoselectedOption) {
          this._updateNativeInputValue(this._valueBeforeAutoSelection ?? '');
          this._pendingAutoselectedOption = null;
        }

        this._closeKeyEventStream.next();
        this._resetActiveItem();

        // We need to stop propagation, otherwise the event will eventually
        // reach the input itself and cause the overlay to be reopened.
        event.stopPropagation();
        event.preventDefault();
      }
    });

    // Subscribe to the pointer events stream so that it doesn't get picked up by other overlays.
    // TODO(crisbeto): we should switch `_getOutsideClickStream` eventually to use this stream,
    // but the behvior isn't exactly the same and it ends up breaking some internal tests.
    overlayRef.outsidePointerEvents().subscribe();
  }

  private _getOverlayPosition(): PositionStrategy {
    const strategy = this._overlay
      .position()
      .flexibleConnectedTo(this._getConnectedElement())
      .withFlexibleDimensions(false)
      .withPush(false);

    this._setStrategyPositions(strategy);
    this._positionStrategy = strategy;
    return strategy;
  }

  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._getOverlayPosition(),
      scrollStrategy: this._scrollStrategy(),
      width: this._getPanelWidth(),
      // direction: this._dir ?? undefined,
      // panelClass: this._defaults?.overlayPanelClass,
    });
  }
}
