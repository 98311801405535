import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ApiFacadeService,
  STAGES_USER_LANGUAGE,
  Theme,
  ThemeRecord,
  UserPreferences
} from '@simlab/data-access';
import { ToastService } from '@simlab/design/toast';
import { ThemeService } from '@simlab/ui/theme';
import {
  catchError,
  filter,
  firstValueFrom,
  map,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import * as UserPreferenceActions from './user-preference.actions';
import { UserPreferenceFacade } from './user-preference.facade';

@Injectable()
export class UserPreferenceEffects {
  private readonly _actions$ = inject(Actions);
  private readonly _toastService = inject(ToastService);
  private readonly _router = inject(Router);
  private readonly _apiFacadeService = inject(ApiFacadeService);
  private readonly _userPreferenceFacade = inject(UserPreferenceFacade);
  private readonly _themeService = inject(ThemeService);
  init$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserPreferenceActions.init),
      take(1),
      switchMap(() => this._userPreferenceFacade.loaded$),
      filter((loaded) => !loaded),
      switchMap(() =>
        this._apiFacadeService.userPreferences.getUserPreferences()
      ),
      map((userPreferences: UserPreferences) => {
        firstValueFrom(
          this._router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            tap((e) => {
              if (
                location.pathname.split('/')[1] !== userPreferences.language &&
                !location.href.includes('callback')
              ) {
                localStorage.setItem(
                  STAGES_USER_LANGUAGE,
                  userPreferences.language
                );

                location.href =
                  location.origin +
                  location.pathname
                    .split('/')
                    .map((element, index) => {
                      if (index === 1) {
                        return userPreferences.language;
                      }
                      return element;
                    })
                    .join('/') +
                  location.search;
              }
            })
          )
        );

        this._themeService.set(ThemeRecord[userPreferences.theme as Theme]);
        return UserPreferenceActions.initSuccess({ userPreferences });
      }),
      catchError((error) => of(UserPreferenceActions.initFailure()))
    )
  );

  userLoggedToProcore$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserPreferenceActions.userLoggedToProcore),
      switchMap(() =>
        this._apiFacadeService.userPreferences.hasUserProcoreToken().pipe(
          map((userLoggedToProcore) =>
            UserPreferenceActions.userLoggedToProcoreSuccess({
              userLoggedToProcore
            })
          ),
          catchError((error) => {
            return of(
              UserPreferenceActions.userLoggedToProcoreFailure({ error })
            );
          })
        )
      )
    )
  );

  update$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserPreferenceActions.updateUserPreferences),
      switchMap(({ userPreferences }) =>
        this._apiFacadeService.userPreferences
          .updateUserPreferences(userPreferences)
          .pipe(
            map(() => {
              this._themeService.set(
                ThemeRecord[userPreferences.theme as Theme]
              );
              return UserPreferenceActions.updateUserPreferencesSuccess({
                userPreferences
              });
            }),
            catchError((error) =>
              of(UserPreferenceActions.updateUserPreferencesFailure({ error }))
            )
          )
      )
    )
  );
}
