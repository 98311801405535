import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  DesignButton,
  DesignFlatButton,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { UiIconModule } from '@simlab/ui/icon';
import { MODAL_DATA, ConfirmationModalRef } from '@simlab/ui/modal';

@Component({
  selector: 'feature-projects-no-access-dialog',
  templateUrl: './no-access-dialog.component.html',
  styleUrls: ['./no-access-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DesignCommonModule,
    DesignDialogWrapperModule,
    UiIconModule,
    DesignFlatButton,
    DesignStrokedButton,
    DesignButton
  ]
})
export class NoAccessDialogComponent {
      public readonly data = inject<{ organizationId?: string; text: string }>(MODAL_DATA);
      private readonly modalRef = inject<ConfirmationModalRef<unknown>>(ConfirmationModalRef<unknown>);

  confirmDialog(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }
}
