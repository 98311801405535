/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { DesignIcon } from '@simlab/design/icon';
import { designChipToken } from '../tokens/chip.token';
import { ChipBase } from './chip-base.directive';

@Component({
  selector: 'design-chip',
  exportAs: '[designChip]',
  standalone: true,
  imports: [NgIf, DesignIcon],
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'design-chip',
    '[class.design-chip--removable]': 'removable === true',
    '[class.design-color--primary]': 'color === "primary"',
    '[class.design-color--secondary]': 'color === "secondary"',
    '[class.design-color--error]': 'color === "error"',
    '[class.design-color--accent]': 'color === "accent"',
    '[class.design-size--small]': 'size === "small"',
    '[class.design-size--medium]': 'size === "medium"',
    '[class.design-size--large]': 'size === "large"',
  },
  inputs: ['color', 'size', 'disable', 'removable'],
  providers: [{ provide: designChipToken, useExisting: DesignChip }],
})
export class DesignChip extends ChipBase {}
