import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ApiFacadeService,
  ImageSize,
  PagedResponse,
  Project,
  ProjectPaging,
  ProjectsFilterBase,
  ProjectsSort
} from '@simlab/data-access';
import { RouterFacadeService, RouterStoreParams } from '@simlab/util-shared';
import {
  Observable,
  catchError,
  exhaustMap,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import * as ProjectsActions from './projects.actions';
import { ProjectForm, ProjectsFacade } from './projects.facade';

@Injectable()
export class ProjectsEffects {
  private readonly actions$ = inject(Actions);
  private readonly projectsFacade = inject(ProjectsFacade);
  private readonly apiFacadeService = inject(ApiFacadeService);
  private readonly routerFacadeService = inject(RouterFacadeService);

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.initProjects),
      switchMap(
        (action: {
          filters?: ProjectsFilterBase | undefined;
          sort?: ProjectsSort | undefined;
          paging?: ProjectPaging | undefined;
        }) => {
          return this.apiFacadeService.projects.getAllProjects({
            filterBy: action.filters,
            sortBy: action.sort,
            paging: action.paging,
            imageSize: ImageSize.Medium
          });
        }
      ),
      switchMap((response: PagedResponse<Project[]>) => {
        return this.routerFacadeService.getRouteNestedParams$.pipe(
          take(1),
          mergeMap((routerStoreParams: RouterStoreParams) =>
            of(true).pipe(
              tap(() => {
                const projectId = routerStoreParams.params['projectId'];
                if (
                  projectId &&
                  !!response.records.find((p) => p.id === projectId) === false
                ) {
                  this.projectsFacade.getProjectById(projectId);
                }
              })
            )
          ),
          map(() =>
            ProjectsActions.loadProjectsSuccess({
              projects: response.records,
              metadata: response.metadata
            })
          ),
          catchError((error) =>
            of(ProjectsActions.loadProjectsFailure({ error }))
          )
        );
      })
    )
  );

  toggleFavorite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectsActions.toggleFavorite),
        switchMap(({ projectId, flag }) => {
          return iif(
            () => flag,
            this.apiFacadeService.projects.addFavoriteMark({ projectId }),
            this.apiFacadeService.projects.removeFavoriteMark({ projectId })
          );
        })
      ),
    {
      dispatch: false
    }
  );

  removeProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.removeProject),
      switchMap(({ projectId }) =>
        this.apiFacadeService.projects
          .deleteProject({ id: projectId })
          .pipe(map(() => ProjectsActions.removeProjectSuccess({ projectId })))
      ),
      catchError((error) => of(ProjectsActions.removeProjectFailure({ error })))
    )
  );

  getProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.getProject),
      mergeMap(({ projectId }) =>
        this.apiFacadeService.projects.getProject({ id: projectId }).pipe(
          map((project: Project) =>
            ProjectsActions.getProjectSuccess({ project })
          ),
          catchError((error) =>
            of(ProjectsActions.getProjectFailure({ error }))
          )
        )
      )
    )
  );

  createProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.createProject),
      exhaustMap((action) =>
        this.apiFacadeService.projects
          .createProject({
            organizationId: action.project.organizationId,
            name: action.project.name,
            description: action.project.description,
            address: action.project.address,
            geolocation: action.project.geolocation
          })
          .pipe(
            switchMap((projectId: string) =>
              this.createWithThumbnail$(projectId, action.project)
            )
          )
      ),
      switchMap((project: Project) =>
        of(ProjectsActions.createProjectSuccess({ project }))
      ),
      catchError((error) => of(ProjectsActions.createProjectFailure({ error })))
    )
  );

  updateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.updateProject),
      exhaustMap((action: { projectId: string; data: any }) =>
        this.updateProjectAttributes$(action).pipe(
          mergeMap(() =>
            this.apiFacadeService.projects.getProject({ id: action.projectId })
          ),
          switchMap((project: Project) =>
            of(ProjectsActions.updateProjectSuccess({ project: project }))
          ),

          catchError((error) =>
            of(ProjectsActions.updateProjectFailure({ error }))
          )
        )
      )
    )
  );

  //NOTE: api redesign needed!!!
  createWithThumbnail$ = (projectId: string, data: ProjectForm) => {
    if (data.imageData) {
      const formData = new FormData();
      formData.append('mimeType', data.imageData.mimeType);
      formData.append('extension', data.imageData.extension);
      formData.append('base64Image', data.imageData.base64data);
      formData.append('contextId', projectId);

      return this.apiFacadeService.blobs
        .uploadBase64ImagesToBlob(formData)
        .pipe(
          switchMap((thumbnailUrl: string) =>
            this.apiFacadeService.projects.editProjectMainAttributes({
              id: projectId,
              newName: data.name,
              newProjectThumbnailUrl: thumbnailUrl,
              newDescription: data.description,
              newAddress: data.address,
              newGeolocation: data.geolocation
            })
          ),
          switchMap(() =>
            this.apiFacadeService.projects.getProject({
              id: projectId
            })
          )
        );
    }

    return this.apiFacadeService.projects.getProject({
      id: projectId
    });
  };

  updateProjectAttributes$ = (action: {
    projectId: string;
    data: ProjectForm;
  }): Observable<void> => {
    if (action.data.imageData) {
      const formData = new FormData();
      formData.append('mimeType', action.data.imageData.mimeType);
      formData.append('extension', action.data.imageData.extension);
      formData.append('base64Image', action.data.imageData.base64data);
      formData.append('contextId', action.projectId);
      return this.apiFacadeService.blobs
        .uploadBase64ImagesToBlob(formData)
        .pipe(
          switchMap((thumbnailUrl: string) =>
            this.apiFacadeService.projects.editProjectMainAttributes({
              id: action.projectId,
              newName: action.data.name,
              newDescription: action.data.description,
              newAddress: action.data.address,
              newProjectThumbnailUrl: thumbnailUrl,
              newGeolocation: action.data.geolocation
            })
          )
        );
    }

    return this.apiFacadeService.projects.editProjectMainAttributes({
      id: action.projectId,
      newName: action.data.name,
      newDescription: action.data.description,
      newAddress: action.data.address,
      newProjectThumbnailUrl: action.data.thumbnail,
      newGeolocation: action.data.geolocation
    });
  };
}
