<div class="project-list" [formGroup]="filterFormRef">
  <section class="project-list__header">
    <h2 i18n="@@PROJECT_LIST">Project List</h2>
    <button
      design-flat-button
      designButtonColor="accent"
      [designFireOnClick]="createProject$"
      data-cy="add-project-button"
    >
      <i designIcon="add" designSize="1.4rem"></i>
      <design-button-loader uiColor="surface"></design-button-loader>
      <div i18n="@@ADD_PROJECT">Add Project</div>
    </button>

    <ui-input
      [prefix]="input_prefix"
      formControlName="projectName"
      [type]="'text'"
      i18n-placeholder="@@SEARCH_PROJECT_PLACEHOLDER_NAME"
      placeholder="Search project name"
      deleteButton
    >
      <ng-template #input_prefix>
        <i ui-font fontName="search" fontSize="1.5rem"></i>
      </ng-template>
    </ui-input>
  </section>
  <ui-divider class="divider-class"></ui-divider>

  <div class="filters">
    <h2 i18n="@@FILTERS">Filters</h2>
    <div class="filters__content">
      <ng-container *ngIf="organizations$ | async as organizations">
        <div class="filters__content__form">
          <div class="filters__content__sort">
            <ui-select
              [(ngModel)]="orderBy"
              [ngModelOptions]="{ standalone: true }"
              (valueChange)="orderChange($event)"
              i18n-placeholder="@@SORT"
              placeholder="Sort"
            >
              <ui-option [value]="item" *ngFor="let item of sorting">{{
                item.displayName
              }}</ui-option>
            </ui-select>
          </div>

          <ui-checkbox
            class="filters__content__form--filter-checkbox"
            type="customize"
            formControlName="owner"
          >
            <label i18n="@@MY_PROJECTS">My projects</label>
          </ui-checkbox>

          <ui-checkbox
            class="filters__content__form--filter-checkbox"
            type="customize"
            formControlName="archived"
          >
            <label i18n="@@ARCHIVED_PROJECTS">Archived projects</label>
          </ui-checkbox>

          <design-form-field>
            <input
              #fromDatepickerRef="designDatepickerInput"
              designInput
              designDatepicker
              i18n-placeholder="@@FROM_DATE"
              placeholder="From"
              formControlName="createdFrom"
              [maxlength]="10"
            />
            <button
              design-icon-button
              designSuffix
              (click)="fromDatepickerRef.open()"
            >
              <i designIcon="Calendar" designSize="16px"></i>
            </button>
            <design-error></design-error>
          </design-form-field>

          <design-form-field>
            <input
              #toDatepickerRef="designDatepickerInput"
              designInput
              designDatepicker
              i18n-placeholder="@@TO_DATE"
              placeholder="To"
              formControlName="createdTo"
              [maxlength]="10"
            />
            <button
              design-icon-button
              designSuffix
              (click)="toDatepickerRef.open()"
            >
              <i designIcon="Calendar" designSize="16px"></i>
            </button>

            <design-error></design-error>
          </design-form-field>

          <ui-select
            class="filters__content__form--filter-select"
            formControlName="projectAuthors"
            i18n-placeholder="@@AUTHOR_FILTER"
            placeholder="Select author"
            iconName="users"
            multiple
          >
            <ui-option
              [value]="author.userId"
              *ngFor="let author of authors$ | async"
              >{{ author.userName }}</ui-option
            >
          </ui-select>

          <ui-select
            class="filters__content__form--filter-select--second"
            formControlName="organizationsId"
            i18n-placeholder="@@ORGANIZATION_FILTER"
            placeholder="Select organization"
            iconName="organization"
            multiple
          >
            <ui-option [value]="item.id" *ngFor="let item of organizations">{{
              item.name
            }}</ui-option>
          </ui-select>
          <button
            class="filters__content__form--reset-all"
            design-button
            (click)="reset()"
          >
            <div i18n="@@RESET_FILTERS">Reset filters</div>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
