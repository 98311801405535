import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  QueryList,
  ViewChildren,
  inject,
  input
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Comment } from '@simlab/data-access';
import { ProjectPrivilegesFacade } from '@simlab/data-store';
import { Observable, take } from 'rxjs';
import { ProjectLimitsService } from '../../services/project-limits.service';
import { AddCommentComponent } from '../add-comment/add-comment.component';
import { CommentComponent } from '../comment/comment.component';
import { CommentsService } from './comments.service';

@Component({
  standalone: true,
  selector: 'feature-stages-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CommentsService],
  imports: [NgFor, NgIf, AsyncPipe, AddCommentComponent, CommentComponent]
})
export class CommentListComponent {
  private readonly _projectPrivilegesFacade = inject(ProjectPrivilegesFacade);
  readonly commentList = toSignal(this.commentsService.commentList$);
  @ViewChildren(CommentComponent) comments!: QueryList<CommentComponent>;
  readonly resetComments$ = this.commentsService.resetComments$;
  readonly projectIsActive = toSignal(
    this.projectLimitsService.projectIsActive$
  );
  readonly mentionData = toSignal(this.commentsService.mentionData$);
  readonly maxCommentLength = input<number>();

  readonly addEditDeleteOwnComments$: Observable<boolean> =
    this._projectPrivilegesFacade.addEditDeleteOwnComments$;

  constructor(
    private readonly commentsService: CommentsService,
    private readonly projectLimitsService: ProjectLimitsService
  ) {}

  private _scrollToNewComment() {
    this.comments.changes.pipe(take(1)).subscribe((c) => {
      const el = document.getElementById(c.first.comment.id);
      const container = document.getElementsByTagName(
        'feature-stages-right-panel'
      )[0];
      if (!el) return;
      const headerOffset = 200;
      const rect = el?.getBoundingClientRect();
      if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      ) {
        return;
      }

      const y = rect.top + window.pageYOffset - headerOffset;
      container.scrollTo({ top: y, behavior: 'smooth' });
    });
  }
  trackByCommentId(index: number, comment: Comment) {
    if (!comment) {
      return index;
    }
    return comment.id;
  }

  addComment(text: string | number) {
    if (text) {
      this.commentsService
        .addComment$(text as string)
        .pipe(take(1))
        .subscribe(() => {
          this._scrollToNewComment();
        });
    }
  }
}
