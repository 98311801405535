import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client';
import { ApiFacadeService, BlobBody } from '@simlab/data-access';
import { StagesFacade } from '@simlab/data-store';
import { MATTERPORT_TOKEN } from '@simlab/feature/matterport';
import { MatterportService } from '@simlab/matterport';
import { GetModelTagsQuery, Mattertag } from '@simlab/matterport/api';
import {
  ConfirmationModalRef,
  DialogResponse,
  MODAL_DATA,
  ModalService
} from '@simlab/ui/modal';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  concatAll,
  exhaustMap,
  finalize,
  iif,
  last,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError
} from 'rxjs';
import { TagListComponent } from '../tag-list/tag-list.component';
import {
  ERRORS,
  MattertagAdapter,
  ReportErrors
} from './mattertag-to-root-note';
import {
  ImportSummary,
  TagImportFinishModalComponent
} from './tag-import-finish-modal/tag-import-finish-modal.component';
import { TagImporterInformationModalComponent } from './tag-importer-information-modal/tag-importer-information-modal.component';
import { TagImporterPrivateInformationComponent } from './tag-importer-private-information/tag-importer-private-information.component';
import {
  Progression,
  TagImporterProgressModalComponent
} from './tag-importer-progress-modal/tag-importer-progress-modal.component';
@Injectable()
export class TagImporterControllerService implements OnDestroy {
  private _selectedStageId!: string;
  private _projectId!: string;
  private _scanId!: string;

  private readonly _destroySource: Subject<void> = new Subject<void>();
  readonly noteType: UntypedFormControl = new UntypedFormControl('Information');
  readonly noteStatus: UntypedFormControl = new UntypedFormControl('');

  private _tagList!: TagListComponent;

  set tagList(value: TagListComponent) {
    this._tagList = value;
  }

  get tagListLength(): number {
    if (this._tagList === undefined) return 0;

    const entries: [string, boolean][] = Object.entries(
      this._tagList?.tagsForm.value
    );
    return entries.filter((control: [string, boolean]) => control[1]).length;
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly stageFacade: StagesFacade,
    @Inject(MATTERPORT_TOKEN)
    private readonly matterportService: MatterportService,
    private readonly apiFacadeService: ApiFacadeService,
    private readonly router: Router,
    private readonly modalService: ModalService
  ) {}

  readonly importDialogAction$: Observable<boolean> = of('').pipe(
    map(() => this._createInformationDialog()),
    mergeMap(
      (
        dialogRef: ConfirmationModalRef<TagImporterInformationModalComponent>
      ) => {
        const entries: [string, boolean][] = Object.entries(
          this._tagList.tagsForm.value
        );
        const selectedTags = entries
          .filter((control: [string, boolean]) => control[1])
          .map((control: [string, boolean]) => control[0]);

        return dialogRef.events$.pipe(
          mergeMap(
            (
              response: DialogResponse<TagImporterInformationModalComponent>
            ) => {
              if (response.state) {
                return this._importMattertags$(selectedTags);
              }
              return of(false);
            }
          )
        );
      }
    )
  );

  private _importMattertags$(selectedTags: string[]): Observable<boolean> {
    const progression = new BehaviorSubject<Progression>({
      current: 0,
      total: selectedTags.length
    });
    const dialogRef = this._createProgressionDialog(progression);
    const reports: ReportErrors[] = [];
    let unexpected_error = false;
    return this._tagList.mattertags$.pipe(
      take(1),
      map((tags: ApolloQueryResult<GetModelTagsQuery> | undefined) => {
        if (!tags) return [];
        return (tags.data.model?.mattertags as Partial<Mattertag>[]).filter(
          (tag: Partial<Mattertag>) => {
            return tag.id ? selectedTags.includes(tag.id) : null;
          }
        );
      }),
      map((tags: Partial<Mattertag>[]) => {
        const notes: MattertagAdapter[] = tags.map(
          (tag: Partial<Mattertag>) => {
            return new MattertagAdapter(
              tag,
              this.matterportService.transformConverter(),
              this.noteStatus.value ?? 'None',
              this.noteType.value,
              this._scanId
            );
          }
        );
        return notes;
      }),
      concatAll(),
      mergeMap((response2: MattertagAdapter) => {
        const response = response2;
        return of(response).pipe(
          exhaustMap((mattertag) => {
            if (mattertag.digitalNotes?.length) {
              return this._uploadDigitalNotesFromMattertag$(mattertag);
            }
            return of(mattertag);
          }),
          mergeMap((mattertagWithAttachment: MattertagAdapter) => {
            if (!mattertagWithAttachment.name)
              return of(mattertagWithAttachment);
            return this.apiFacadeService.rootNotes
              .addRootNotesToStage({
                stageId: this._selectedStageId,
                rootNotes: [mattertagWithAttachment.rootNote]
              })
              .pipe(
                map(() => mattertagWithAttachment),
                catchError((e) => {
                  mattertagWithAttachment.addError({
                    tagName: mattertagWithAttachment.name,
                    errors: ERRORS.UNEXPECTED,
                    name: mattertagWithAttachment.name,
                    type: 'attachment',
                    details: e.message
                  });
                  return of(mattertagWithAttachment);
                })
              );
          }),
          tap((matterportAdapter: MattertagAdapter) => {
            matterportAdapter.report?.length &&
              reports.push(...matterportAdapter.report);
            progression.next({
              ...progression.getValue(),
              current: progression.getValue().current + 1
            });
          })
        );
      }),
      last(),
      map(() => {
        dialogRef.close();
        this._tagList.refresh.next();
        return true;
      }),
      catchError((e) => {
        unexpected_error = true;
        return of(false);
      }),
      finalize(() => {
        let type: ImportSummary['type'] = 'success';
        if (reports.length) {
          type = 'warning';
        }
        if (unexpected_error) {
          type = 'error';
        }
        this._openSummaryDialog({
          reports,
          type
        });
      })
    );
  }
  private _openSummaryDialog(summary: ImportSummary): void {
    this.modalService.createModalWithProviders(
      TagImportFinishModalComponent,
      {
        maxWidth: 'min(90%, 450px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: summary
        }
      ]
    );
  }

  private _uploadDigitalNotesFromMattertag$(
    mattertag: MattertagAdapter
  ): Observable<MattertagAdapter> {
    return combineLatest(
      (mattertag.digitalNotes as BlobBody[]).map((note) => {
        const file = this._getFile(note.blobUrl, note.name);
        return file.pipe(
          switchMap((uploadedFile: File | undefined) => {
            if (!uploadedFile) return throwError(() => 'Upload file error');
            return iif(
              () => note.type !== 'photo',
              this.apiFacadeService.blobs.sendFileToBlobWithNoteId(
                uploadedFile,
                this._projectId,
                ''
              ),
              this.apiFacadeService.blobs
                .uploadImagesToBlob(
                  this._formData(uploadedFile, this._projectId)
                )
                .pipe(
                  map(
                    (fileUrl: string) =>
                      ({
                        name: uploadedFile.name,
                        blobUrl: fileUrl,
                        extension: uploadedFile.name.split('.')[1]
                      }) as BlobBody
                  )
                )
            );
          }),
          map((sendFileResponse) => {
            return {
              ...note,
              blobUrl: sendFileResponse.blobUrl
            } as BlobBody;
          }),
          catchError((e) => {
            mattertag.addError({
              tagName: mattertag.name,
              errors: ERRORS.UNEXPECTED,
              name: mattertag.name,
              type: 'attachment',
              details: e.message
            });
            return of(note);
          })
        );
      })
    ).pipe(
      map((notes) => {
        mattertag.setDigitalNotes(notes);
        return mattertag as MattertagAdapter;
      })
    );
  }
  private _formData(file: File, projectId: string): FormData {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('extension', file.name.split('.').pop() ?? '');
    formData.append('contextId', projectId);
    return formData;
  }
  private _getFile(
    url: string,
    fileName: string
  ): Observable<File | undefined> {
    return this.httpClient
      .get<Blob>(url, {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
      .pipe(
        map((e) => {
          return e.body ? new File([e.body], fileName) : undefined;
        })
      );
  }
  init(projectId: string, scanId: string): void {
    this._projectId = projectId;
    this._scanId = scanId;

    this.stageFacade.selectedId$
      .pipe(
        tap((selectedStageId: string) => {
          if (selectedStageId) {
            this._selectedStageId = selectedStageId;
          } else {
            this.router.navigate(['/stages/info'], {
              queryParamsHandling: 'preserve'
            });
          }
        }),
        take(1),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }
  private _createInformationDialog(): ConfirmationModalRef<TagImporterInformationModalComponent> {
    return this.modalService.createModalWithProviders(
      TagImporterInformationModalComponent,
      {
        maxWidth: 'min(90%, 500px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: {
            text: 'Selected Mattertags are ready to Import. \n Do you want to continue importing?',
            cancel: {
              visible: true,
              label: 'Cancel'
            },
            confirm: {
              visible: true,
              label: 'Import'
            }
          }
        }
      ]
    );
  }

  private _createProgressionDialog(
    progressionSubject: BehaviorSubject<Progression>
  ): ConfirmationModalRef<TagImporterProgressModalComponent> {
    return this.modalService.createModalWithProviders(
      TagImporterProgressModalComponent,
      {
        width: 'min(90%,380px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: progressionSubject
        }
      ]
    );
  }
  importFromPrivateScanDialog(): ConfirmationModalRef<TagImporterInformationModalComponent> {
    return this.modalService.createModalWithProviders(
      TagImporterPrivateInformationComponent,
      {
        maxWidth: 'min(90%, 500px)'
      }
    );
  }
  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }
}
