import { StageComponent } from '@simlab/data-access';

export function selectSyncComponentsByStage(
  stageId: string,
  components: StageComponent[]
) {
  return components.filter(
    (component: StageComponent) =>
      component.stageId === stageId &&
      !component.extension &&
      component.isSynchronizingAccepted
  );
}
