/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  LANGUAGES_LIST,
  SupportedLanguage,
  UserPreferences
} from '@simlab/data-access';
import { UiSelectModule } from '@simlab/ui/select';
import { Observable, map } from 'rxjs';
import { PersonalSettingBase } from '../../personal-settings.component';
import { UserPreferencesToken } from '../units/units.component';

@Component({
  selector: 'simlab-language',
  imports: [CommonModule, UiSelectModule, FormsModule, ReactiveFormsModule],
  template: ` <label i18n="@@CHOOSE_MATTERPORT_SERVER">
      Choose the type of server based on your location
    </label>
    <ui-select [formControl]="languageFormControl">
      <ui-option *ngFor="let item of regions" [value]="item.value">
        {{ item.displayName }}
      </ui-option>
    </ui-select>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./language.component.scss'],
  standalone: true,
  host: {
    class: 'simlab-language'
  }
})
export class LanguageComponent implements PersonalSettingBase {
  readonly regions: {
    displayName: string;
    value: SupportedLanguage;
  }[] = LANGUAGES_LIST;

  readonly languageFormControl = new FormControl<SupportedLanguage>('en');

  private _userPreferences: UserPreferences = inject(UserPreferencesToken);

  @Input() set userPreferences(userPreferences: UserPreferences) {
    this._userPreferences = userPreferences;
    const language = userPreferences.language;
    this.languageFormControl.patchValue(language, { emitEvent: false });
  }
  get userPreferences() {
    return this._userPreferences;
  }

  @Output() readonly userPreferencesChange: Observable<UserPreferences> =
    this.languageFormControl.valueChanges.pipe(
      map((value: SupportedLanguage | null) => {
        if (!value) return this.userPreferences;
        return {
          ...this.userPreferences,
          language: value
        };
      })
    );

  constructor() {
    this.languageFormControl.patchValue(this._userPreferences.language);
  }
}
