<div class="container">
  <div class="container__header">
    <div class="container__header--upper">
      <h1 data-cy="left-panel-project-title">{{ projectName$ | async }}</h1>

      <ng-container *ngIf="(projectIsActive$ | async) !== true">
        <div
          class="container__header--upper__info-view-only"
          i18n="@@VIEW_ONLY"
        >
          View Only
        </div>
      </ng-container>
      <button
        data-cy="open-close-stages-left-panel-button"
        design-icon-button
        (click)="this.closed.emit()"
      >
        <i designIcon="arrow_big" [designRotateBy]="180"></i>
      </button>
    </div>
    <div class="container__header--bottom">
      <!-- <button
        design-flat-button
        designButtonColor="secondary"
        (click)="navigateToDocuments()"
      >
        <i designIconPrefix designIcon="info_first"></i>
        <div i18n="@@Documents">Documents</div>
      </button> -->
      <button
        design-flat-button
        designButtonColor="secondary"
        (click)="navigateToDocuments()"
      >
        <i designIconPrefix designIcon="info_first"></i>
        <div i18n="@@DOCUMENTS">Documents</div>
      </button>
      <button
        design-flat-button
        designButtonColor="secondary"
        (click)="openProjectInfo()"
      >
        <i designIconPrefix designIcon="info_first"></i>
        <div i18n="@@PROJECT_INFO">Project info</div>
      </button>
    </div>
    <button
      class="container__btn--full-width"
      design-flat-button
      designButtonColor="secondary"
      (click)="openFilterModal()"
    >
      <i designIconPrefix designIcon="filter"></i>
      <div i18n="@@FILTERS">Filters</div>
    </button>
    <button
      class="container__btn--full-width"
      design-flat-button
      [disabled]="((addEditDeleteStages$ | async) ?? true) === false"
      designButtonColor="accent"
      [designFireOnClick]="openAddStageModal$"
      data-cy="add-stage-button"
    >
      <i designIcon="add" designSize="1.4rem"></i>
      <div i18n="@@ADD_STAGE">Add stage</div>
      <design-button-loader
        designIconPrefix
        designColor="surface"
      ></design-button-loader>
    </button>
  </div>
  <div class="container__stage-list">
    <div class="container__stage-list__header">
      <div class="container__stage-list--title" i18n="@@STAGE_LIST">
        Stage List
      </div>
    </div>

    <ng-container *ngIf="(stagesLoading$ | async) === true">
      <ui-progress-spinner
        class="spinner"
        uiColor="primary"
      ></ui-progress-spinner>
    </ng-container>
    <feature-stages-stage-list
      [class.loadingElement]="(stagesLoaded$ | async) === false"
    >
      <ng-container *ngIf="stages$ | async; let stages">
        <ng-container *ngIf="stages.length > 0; else noStages">
          <feature-stages-stage-list-item
            (selected)="handleSelected($event)"
            *ngFor="let item of stages; let i = index; trackBy: trackById"
            [attr.data-cy]="'stage-item-' + item.name"
            [attr.aria-selected]="item.id === (selectedId$ | async)"
            [attr.id]="item.id"
            [item]="item"
            [indexNumber]="stages.length - i"
          >
          </feature-stages-stage-list-item>
        </ng-container>
      </ng-container>
    </feature-stages-stage-list>
  </div>
</div>

<ng-template #noStages>
  <ng-container *ngIf="(stagesLoading$ | async) === false">
    <div class="wrapper-no-stages">
      <ui-image-info
        data-cy="left-panel-image-info"
        imageSrc="icon_no_stages"
        i18n-header="@@NO_STAGES_HEADER"
        header="No Stages"
        [descriptions]="descriptionImageInfo"
        [maxWidth]="100"
      ></ui-image-info>
    </div>
  </ng-container>
</ng-template>
