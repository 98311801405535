<button
  design-icon-button
  type="button"
  class="ascending-switch-btn"
  (click)="toggleIsAscendingState()"
  #ascendingBtn
>
  <i
    designIcon="arrow_forward"
    designSize="20px"
    [designRotateBy]="90"
    [designRotateBy]="isAscending() ? 90 : 270"
  ></i>
</button>

<button type="button" class="sort-switch" [designMenuTriggerFor]="menu">
  <div class="sort-switch__label">
    {{ sortStateName() }}
  </div>
  <i designIcon="arrow_small" designSize="32px" [designRotateBy]="90"></i>
</button>

<design-menu-panel #menu customPanelClass="sort-options-container">
  <div class="title" i18n="@@GEN_SORT_BY">Sort by</div>
  @for (sortItem of sortDataList(); track $index) {
    <design-option (click)="selectSortState(sortItem)">{{
      sortItem.name
    }}</design-option>
  }
</design-menu-panel>
