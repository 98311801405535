<div class="body">
  <div class="body__header">
    <label class="body__header--label">{{ imageName }}</label>

    <div class="body__header--button-action">
      <button design-icon-button (click)="close()">
        <i class="button--close" designIcon="cross" designSize="2.5rem"></i>
      </button>
    </div>

    <button
      #buttonConfirm
      design-flat-button
      class="body__save-draw"
      (click)="save()"
      [designFireOnClick]="savingImage$"
    >
      <design-button-loader
        designIconPrefix
        designColor="surface"
      ></design-button-loader>
      <i designIcon="save" designSize="1.3rem"></i>
      Save
    </button>
  </div>

  <div #containerCanvas class="body__canvas-image">
    <canvas id="canvas" class="body__canvas-image__canvas-drawing"></canvas>
  </div>
  <div class="body__brush-color">
    <button
      #brushButton
      ui-mini-fab-button
      uiColor="secondary"
      class="body__brush-color--button"
    >
      <i designIcon="brush" designSize="1.5rem"></i>
    </button>

    <div class="body__brush-color--slider-color">
      <ui-slider
        type="color"
        (changeColorOfBrush)="changeColorOfBrush($event)"
        (color)="setBrushColor($event)"
      ></ui-slider>
    </div>
  </div>

  <div class="body__tools">
    <ui-slider
      type="triangle"
      (value)="setBrushThickness($event)"
      [defaultRange]="defaultBrush"
      [rangeMin]="2"
      [rangeMax]="40"
    ></ui-slider>
    <div class="body__tools__actions">
      <button
        [disabled]="disableDraw"
        design-icon-button
        designButtonColor="secondary"
        class="body__tools--buttons"
        (click)="undoAction()"
      >
        <i designIcon="back" designSize="2rem"></i>
      </button>
      <button
        [disabled]="disableDraw"
        design-icon-button
        designButtonColor="secondary"
        class="body__tools--buttons"
        [class.body__tools__actions--selected]="toolsAction === 'drawCircle'"
        (click)="drawEllipsis()"
      >
        <i designIcon="circle" designSize="2rem"></i>
      </button>
      <button
        [disabled]="disableDraw"
        design-icon-button
        designButtonColor="secondary"
        class="body__tools--buttons"
        [class.body__tools__actions--selected]="toolsAction === 'drawSquare'"
        (click)="drawSquare()"
      >
        <i designIcon="square" designSize="2rem"></i>
      </button>
      <!-- NOTE: Tymczasowo wyłączone -->
      <!-- <button
        design-icon-button
        uiColor="secondary"

        class="body__tools--buttons"
        [class.body__tools__actions--selected]="toolsAction === 'marking'"
        (click)="markingElement()"
      >
        <i designIcon="click" designSize="2rem"></i>
      </button> -->
      <button
        [disabled]="disableDraw"
        design-icon-button
        designButtonColor="secondary"
        class="body__tools--buttons"
        [class.body__tools__actions--selected]="toolsAction === 'drawBrush'"
        (click)="drawBrush()"
      >
        <i designIcon="brush" designSize="2rem"></i>
      </button>
    </div>
  </div>
</div>
