import { DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ApiFacadeService, Note } from "@simlab/data-access";
import { NEW_NOTE_LOCALLY_ID, NotesFacade, StagesFacade, StageWithCount } from "@simlab/data-store";
import { AbstractConstructor, Constructor } from "@simlab/design/internal";
import { TransferNoteDialogComponent } from "@simlab/feature/stages";
import { ConfirmationModalRef, DialogResponse, MODAL_DATA, ModalService } from "@simlab/ui/modal";
import { combineLatest, iif, map, mergeMap, of, switchMap, take, tap } from "rxjs";

export type CanTransferNote = {
  transferNote(close: () => void): void;
}

type TCanTransferNote = Constructor<CanTransferNote> & AbstractConstructor<CanTransferNote>;
export function mixinTransferNote<T extends AbstractConstructor<any>>(base: T): TCanTransferNote & T;
export function mixinTransferNote<T extends Constructor<any>>(base: T) {
  return class extends base {
    private readonly _stagesFacade = inject(StagesFacade);
    private readonly _notesFacade = inject(NotesFacade);
    private readonly _modalService =  inject(ModalService);
    private readonly _apiFacadeService = inject(ApiFacadeService);
    private readonly _destroyRef = inject(DestroyRef);
  
    transferNote(close: () => void): void {
      const dialogRef = this._createTransferNoteDialog<{
        stageId: string;
        rootNoteId: string;
        moveMarker: boolean;
      }>();
  
      dialogRef.events$
        .pipe(
          mergeMap(
            (
              response: DialogResponse<{
                stageId: string;
                rootNoteId: string;
                moveMarker: boolean;
              }>
            ) => {
              if (response.state && !!response.result)
                if (response.result.rootNoteId === NEW_NOTE_LOCALLY_ID) {
                  this._notesFacade.saveLocalNote();
                  return this._notesFacade.noteAdded$.pipe(
                    map((note: Note) => {
                      response.result!.rootNoteId = note.id;
                      return response;
                    })
                  );
                }
              return of(response);
            }
          ),
  
          mergeMap(
            (
              response: DialogResponse<{
                stageId: string;
                rootNoteId: string;
                moveMarker: boolean;
              }>
            ) =>
              iif(
                () => response.state && !!response.result,
  
                this._apiFacadeService.rootNotes
                  .moveRootNoteToStage({
                    stageId: <string>response.result?.stageId,
                    rootNoteId: <string>response.result?.rootNoteId,
                    moveMarker: <boolean>response.result?.moveMarker
                  })
                  .pipe(
                    switchMap((note: Note) => {
                      return this._stagesFacade.selectedId$.pipe(
                        tap((selectedId: string) => {
                          this._notesFacade.removeNote({
                            rootNoteId: note.id,
                            stageId: selectedId,
                            status: note.status,
                            type: note.type
                          });
                          this._stagesFacade.incrementStageNoteCounter(
                            note.stageId,
                            note.status,
                            note.type
                          );
                          close();
                        })
                      );
                    })
                  ),
                of(false)
              )
          ),
          take(1),
          takeUntilDestroyed(this._destroyRef)
        )
        .subscribe();
    }
  
  
  
    private _createTransferNoteDialog<T>(): ConfirmationModalRef<T> {
      const stages$ = this._stagesFacade.allStages$.pipe(take(1));
      const note$ = this._notesFacade.selectedNote$.pipe(take(1));
  
      const data$ = combineLatest([stages$, note$]).pipe(
        map(([stages, note]: [StageWithCount[], Note]) => ({
          stages: this._filterStages(stages, note.stageId),
          containMeasurements: note.areaMeasurements.length > 0,
          noteId: note.id
        }))
      );
  
      return this._modalService.createModalWithProviders(
        TransferNoteDialogComponent,
        {
          minWidth: 'min(85%, 400px)',
          maxWidth: 'min(85%, 400px)'
        },
        [{ provide: MODAL_DATA, useValue: data$ }]
      );
    }
  
    private _filterStages(
      stages: StageWithCount[],
      excludeId: string
    ): { id: string; name: string }[] {
      const filteredStages = stages.filter(
        (stage: StageWithCount) => stage.id !== excludeId
      );
  
      return filteredStages.map((stage: StageWithCount) => ({
        id: stage.id,
        name: stage.name
      }));
    }
  }
}