import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import type { ModalData } from '@simlab/modal/ui';
import { PunchItemState } from '@simlab/procore/annotation-panel/services';
import { PunchItemsListStore } from '@simlab/procore/services';
import { RouterFacadeService } from '@simlab/util-shared';
import {
  AnnotationAction,
  AnnotationActionsBase
} from 'common/annotation-panel/models';
import { exhaustMap, firstValueFrom, from, take, tap } from 'rxjs';
import { PunchItemSidePanelComponent } from './punch-item-side-panel.component';

const TEXT = {
  transferPunchItem: $localize`:@@TRANSFER_PUNCH_ITEM:Transfer Punch Item`,
  deletePunchItem: $localize`:@@DELETE_PUNCH_ITEM:Delete Punch Item`,
  title: (name: string) =>
    $localize`:@@REMOVE_PUNCH_ITEM:Are you sure you want to delete ${name}?`,
  message: $localize`:@@REMOVE_PUNCH_ITEM_LABEL:This document will be removed from both platforms. You will be able to restore it in the Recycle Bin in Procore.`,
  delete: $localize`:@@DELETE:Delete`,
  cancel: $localize`:@@CANCEL:Cancel`
};

@Injectable()
export class PunchItemSidePanelAction implements AnnotationActionsBase {
  private readonly _dialog = inject(Dialog);
  private readonly _punchItemState = inject(PunchItemState);
  private readonly _punchItemStore = inject(PunchItemsListStore);
  private readonly _parent = inject(PunchItemSidePanelComponent);
  private readonly _routerFacade = inject(RouterFacadeService);

  closePanel() {
    this._punchItemStore.setSelected(undefined);
    this._parent.onClosePanel.emit();

    const queryParams: Params = { sidenavContent: null, punchItemId: null };
    this._routerFacade.setQueryParams(undefined, queryParams, 'merge');
  }
  actionList: AnnotationAction[] = [
    {
      name: TEXT.transferPunchItem,
      trigger: () => {
        const { id } = this._punchItemState.punchItem();
        const displayStatus = this._punchItemState.displayStatus();
        if (displayStatus === undefined) {
          console.error('displayStatus is undefined');
          return;
        }

        this._parent.onTransferElement.emit({
          id,
          withActionNumber: displayStatus.showYellowBackground
        });
      }
    },
    {
      name: TEXT.deletePunchItem,
      trigger: () => {
        firstValueFrom(
          this._getModalDelete$().pipe(
            tap((res) => {
              if (!res) return;
              this._punchItemState.deletePunchItem();
              this._parent.onClosePanel.emit();
            })
          )
        );
      }
    }
  ];

  private _getModalDelete$() {
    const { name, position } = this._punchItemState.punchItem();
    const data: ModalData = {
      title: TEXT.title(`#${position ?? ''}: ${name}`),
      message: TEXT.message,
      type: 'alert',
      btnContent: {
        confirm: {
          label: TEXT.delete,
          returnValue: true
        },
        cancel: {
          label: TEXT.cancel,
          returnValue: false
        }
      }
    };

    return from(import('@simlab/modal/ui')).pipe(
      exhaustMap(
        ({ ModalComponent }) =>
          this._dialog.open<boolean, ModalData>(ModalComponent, {
            data,
            width: 'min(90%, 610px)'
          }).closed
      ),
      take(1)
    );
  }
}
