import { Environment } from '@simlab/util-shared';
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

export const environment: Environment = {
  production: false,
  staging: false,
  configuration: {
    contactPage: 'https://simlabinc.com/contact/',
    payPalClientId:
      'AZcm0dnad0aL0QAdNPp0E7a-3KWwdtgJyT1nQ10J7ZT4ycGP_yj_GAmaSdQUz7D3Y_Ar4Yc4qSIpkfX9',
    identity: {
      authority: 'https://dev-identity.simlab.pl',
      clientId: 'Simlab.Stages.JS',
      scope: 'openid email profile Simlab.StagesAPI offline_access',
      redirectUrl:
        window.location.origin + `/${$localize.locale}` + `/callback`,
      postLoginRoute: '/projects',
      postLogoutRedirectUri: window.location.origin
    },
    api: {
      url: 'https://dev-stages-api.simlab.pl'
    },
    matterport: {
      apiKey: '083ea9dbab9f47fc9a7e638bca8c32e0',
      clientId: '642yak2eqf4ia7q4c356sdbra',
      redirectAfterLoginUrl: '/en/callbackMatterport',
      // apiGraphUrl: 'https://api.matterport.com/api/models/graph', //WAIT for matterport release attachment on model API
      apiGraphUrl: 'https://my.matterport.com/api/mp/models/graph',
      showcaseGraphUrl: 'https://my.matterport.com/api/mp/models/graph'
    },
    payU: {
      posId_EUR: '412599',
      posId_USD: '412599',
      redirect: '/payment-summary',
      sdkUrl: 'https://secure.snd.payu.com/javascript/sdk'
    },
    googleMaps: {
      apiKey: 'AIzaSyD1yzgr9Qc5QSN5xRa2Csqiv4ZX8j0NJOQ'
    },
    microsoft: {
      instrumentationKey: '5af60a48-e75c-450d-8a32-4198c997f61d',
      ingestionEndpoint:
        'https://westeurope-5.in.applicationinsights.azure.com',
      liveEndpoint: 'https://westeurope.livediagnostics.monitor.azure.com'
    },
    simlabCloud: 'https://dev-identity.simlab.pl',
    procore: {
      redirectUrl: window.location.origin + '/en/callbackProcore',
      logout: 'https://login-sandbox.procore.com/logout',
      baseUrl: 'https://sandbox.procore.com/'
    },
    unity: {
      windows: `https://devsimlabthestages.blob.core.windows.net/project-files/Stages Launcher Installer Dev.exe?sv=2020-10-02&st=2021-12-23T07%3A26%3A20Z&se=3000-12-24T07%3A26%3A00Z&sr=b&sp=r&sig=nRap7KNtJlzXnYDl7SNwUGuf8ja%2B3LKuvUCXeB2zfdU%3D`,
      macBook: `https://devsimlabthestages.blob.core.windows.net/project-files/Stages Launcher Dev Mac.zip?sv=2020-10-02&st=2021-12-22T13%3A11%3A16Z&se=3000-12-23T13%3A11%3A00Z&sr=b&sp=r&sig=I2ks%2FGnRvs0YcdXu8UcP4DUeHjkLGe4EVAizFZX1Uvs%3D`
    }
  }
};
