import {
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  InjectionToken,
  input,
  InputSignal,
  model,
  ModelSignal,
  OnInit,
  output,
  OutputEmitterRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MediaObserverService } from '@simlab/design/layout';
import {
  FileCardStates,
  FolderStates,
  RowStates
} from '@simlab/documents/models';
import {
  filter,
  fromEvent,
  merge,
  switchMap,
  takeUntil,
  tap,
  timer
} from 'rxjs';

export type TSelectableItem<
  T extends FileCardStates | FolderStates | RowStates = any
> = {
  selected: ModelSignal<boolean>;
  data: InputSignal<T>;
  get id(): string | undefined;
  get isDirectory(): boolean;
  selection: OutputEmitterRef<TSelectableItem>;
  deselect: () => void;
  select: () => void;
  toggle: () => void;
};

export const SelectableOption = new InjectionToken<TSelectableItem>(
  'SelectableOption'
);
const HOLD_INTERVAL = 500;

@Directive({
  selector: '[documentsCanSelect]',
  standalone: true,
  providers: [
    {
      provide: SelectableOption,
      useExisting: CanSelectDirective
    }
  ]
})
export class CanSelectDirective<
    T extends FileCardStates | FolderStates | RowStates
  >
  implements TSelectableItem, OnInit
{
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _elementRef = inject(ElementRef);
  private readonly _nativeElement = this._elementRef
    .nativeElement as HTMLElement;
  private readonly _media = inject(MediaObserverService);

  readonly selected = model<boolean>(false);
  readonly data = input.required<T>();
  readonly selection = output<TSelectableItem>();

  ngOnInit(): void {
    this.selected() && this._nativeElement.classList.add('selected');
    merge(
      fromEvent(this._elementRef.nativeElement, 'touchstart').pipe(
        switchMap(() => this._mobileSelectEvent$)
      ),
      fromEvent(this._elementRef.nativeElement, 'click').pipe(
        switchMap(() => this._desktopSelectEvent$)
      )
    )
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe();
  }

  private readonly _mobileSelectEvent$ = this._media.isTouchableScreen$.pipe(
    filter((isTouchableScreen) => isTouchableScreen),
    switchMap(() => {
      const mouseUpEvent$ = fromEvent(document, 'touchend');
      return timer(HOLD_INTERVAL).pipe(
        tap(() => this.toggle()),
        takeUntil(mouseUpEvent$)
      );
    })
  );

  private readonly _desktopSelectEvent$ = this._media.isTouchableScreen$.pipe(
    filter((isTouchableScreen) => !isTouchableScreen),
    tap(() => this.selection.emit(this))
  );

  toggle() {
    if (this.data().state !== 'LOADED') return;

    this.selected.update((selectionState) => !selectionState);
    this._nativeElement.classList.toggle('selected');
  }

  deselect() {
    this.selected.update(() => false);
    this._nativeElement.classList.remove('selected');
  }

  select() {
    if (this.data().state !== 'LOADED') return;

    this.selected.update(() => true);
    this._nativeElement.classList.add('selected');
  }

  get id(): string | undefined {
    const data = this.data();
    return data.state === 'LOADED' ? data.data.id : undefined;
  }

  get isDirectory(): boolean {
    const data = this.data();
    return data.state === 'LOADED' ? data.data.isDirectory : false;
  }
}
