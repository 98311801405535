import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
  ComponentRef,
  Injectable,
  Injector,
  NgZone,
  Type,
  inject,
} from '@angular/core';
import { ToastOverlayService } from '../components/custom-overlay/overlay-base';
import { OverlayRef } from '../components/custom-overlay/overlay-ref';
import { ToastContainerBase } from '../components/toast-container-base.directive';
import { TOAST_DATA, ToastConfig, ToastType } from '../models/toast-config';
import { TOAST_DEFAULT_OPTIONS } from './toast.service';

@Injectable()
export abstract class _ToastBase {
      private _overlay = inject(ToastOverlayService);
      private _injector = inject(Injector);
      private _ngZone = inject(NgZone);
      private readonly _defaultConfig = inject<ToastConfig>(TOAST_DEFAULT_OPTIONS);
  protected abstract toastContainerComponent: Type<ToastContainerBase>;

  constructor(
) {
    this._defaultConfig = { ...this._defaultConfig };
  }

  open(message: string, toastType: ToastType = 'Info', showType = true): void {
    const _config = { ...this._defaultConfig, toastType, message, showType };

    if (_config.onActivateTick) {
      this._ngZone.run(() =>
        this._attach(this.toastContainerComponent, _config)
      );
    }
    this._attach(this.toastContainerComponent, _config);
  }

  private _attach<T>(
    content: ComponentType<T> | undefined,
    config: ToastConfig
  ): void {
    if (content === undefined) {
      throw new Error('toastComponent required');
    }

    const overlayRef = this._overlay.create(config.positionClass);
    this._attachToastContainer(overlayRef, config);
  }

  private _attachToastContainer(
    overlayRef: OverlayRef,
    config: ToastConfig
  ): ComponentRef<ToastContainerBase> {
    const toastInjector = this._createInjector(config);
    const component = new ComponentPortal(
      this.toastContainerComponent,
      null,
      toastInjector
    );

    const portal = overlayRef.attach(component);
    return portal;
  }

  private _createInjector<T>(config: ToastConfig): Injector {
    return Injector.create({
      parent: this._injector,
      providers: [
        { provide: TOAST_DATA, useValue: config.payload },
        { provide: ToastConfig, useValue: config },
      ],
    });
  }
}
