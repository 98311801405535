import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Media } from '@simlab/data-access';
import { NotesFacade } from '@simlab/data-store';
import { Observable, catchError, map, of } from 'rxjs';
import { NoteMediaService } from '../../services/note-media.service';
import { NoteMediaVideoItemComponent } from './note-media-video-item/note-media-video-item.component';

@Component({
  selector: 'feature-stages-note-media-video',
  templateUrl: './note-media-video.component.html',
  styleUrls: ['./note-media-video.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NoteMediaVideoItemComponent]
})
export class NoteMediaVideoComponent {
  readonly videos$: Observable<Media[]> = this.noteFacade.videos$.pipe(
    map((videos: Media[] | null) => {
      if (videos?.length) {
        return videos;
      } else {
        return [];
      }
    }),
    catchError((e) => {
      console.log(e);
      return of(e);
    })
  );

  openDialog(index: number, name: string, mediaId: string): void {
    this.noteMediaService.openDialog(
      this.videos$,
      'video',
      index,
      name,
      mediaId
    );
  }

  constructor(
    private readonly noteFacade: NotesFacade,
    private readonly noteMediaService: NoteMediaService
  ) {}

  trackById(index: number, item: Media): string {
    return item.id;
  }
}
