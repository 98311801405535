import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  AsyncPipe,
  NgComponentOutlet,
  NgTemplateOutlet
} from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  computed,
  contentChild,
  createComponent,
  inject,
  input,
  OnDestroy,
  ViewContainerRef
} from '@angular/core';
import { DesignIconButton, DesignStrokedButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { breakpointsObserver } from '@simlab/design/layout';
import { FiltersData, filtersToken } from '@simlab/documents/data-access';
import { DocumentsStore } from '@simlab/documents/services';
import { UiBadgeModule } from '@simlab/ui/badge';
import { UiChipsModule } from '@simlab/ui/chips';
import { defer, EMPTY, filter, merge, Subject, tap } from 'rxjs';
import {
  FiltersModalComponent,
  FiltersModalData
} from '../filters-modal/filters-modal.component';
import { DocumentSelectedFilterLabelGetterPipe } from './document-selected-filter-label-getter/document-selected-filter-label-getter.pipe';
import { DocumentActionsDirective } from './documentAction.directive';

@Component({
  selector: 'documents-action-panel',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgComponentOutlet,
    AsyncPipe,
    DocumentSelectedFilterLabelGetterPipe,
    UiChipsModule,
    DesignIcon,
    DesignIconButton,
    DesignStrokedButton,
    DialogModule,
    DocumentActionsDirective,
    UiBadgeModule
  ],
  template: `
    @if (isLessThanMd()) {
      @if (filterComponent() !== undefined) {
        <button
          type="button"
          (click)="showFiltersModal()"
          design-stroked-button
          class="filters-btn"
        >
          <div class="badge-wrapper">
            @if (filtersLength() > 0) {
              <div class="badge-wrapper__badge">
                <div class="badge-wrapper__badge--text">
                  {{ filtersLength() }}
                </div>
              </div>
            }
            <i designIcon="filter" designSize="24px"></i>
          </div>

          <div i18n="@@FILTERS">Filters</div>
        </button>
      }
    } @else {
      <div class="document-actions-container">
        <ng-content select="[filters]"></ng-content>
        <ng-content select="[actions]"></ng-content>
      </div>

      <!--Note (Łukasz): Temporary hidden -->
      <!-- <ui-chip-list class="document-actions-chips">
        @for (selectedFilterItem of selectedFilters(); track $index) {
          <ui-chip
            [label]="
              (selectedFilterItem.value
                | documentSelectedFilterLabelGetter
                | async) ?? '-'
            "
            [removable]="true"
            (eClose)="removeFilter(selectedFilterItem)"
          />
        }
      </ui-chip-list> -->
    }
  `,
  styleUrl: './document-action-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentActionPanelComponent implements OnDestroy {
  readonly filterComponent = input<ComponentType<FiltersData>>();

  private readonly _dialog = inject(Dialog);
  private readonly _viewRef = inject(ViewContainerRef);
  private readonly _filters = inject(DocumentsStore).filters;

  private readonly _filtersFromDialog$ = new Subject<Record<string, any>>();
  private readonly _applicationRef = inject(ApplicationRef);
  private _componentRef: ComponentRef<FiltersData> | undefined = undefined;

  readonly filtersToken = contentChild(filtersToken, {
    descendants: true
  });

  readonly filtersLength = computed(() => {
    const filters = this._filters();
    const val = Object.values(filters).map((element) => element.length);
    return val.reduce((sum, current) => sum + current, 0);
  });

  readonly filtersEmitter$ = defer(() =>
    merge(
      this.filtersToken()?.valueChanges.asObservable() ?? EMPTY,
      this._filtersFromDialog$
    ).pipe(filter((filters) => filters !== undefined))
  );

  protected readonly selectedFilters = computed(() =>
    this.filtersToken()?.selectedFilters()
  );

  protected readonly isLessThanMd = inject(breakpointsObserver).lessThanMd;

  protected filtersPortal!: ComponentPortal<FiltersData>;

  ngOnDestroy(): void {
    this._componentRef?.destroy();
  }

  protected removeFilter(item: any): void {
    this.filtersToken()?.removeFilter(item);
  }

  protected resetFilters(): void {
    this.filtersToken()?.resetFilters();
  }

  protected showFiltersModal() {
    const filterComponent = this.filterComponent();
    if (filterComponent === undefined) return;

    this._componentRef = createComponent(filterComponent, {
      environmentInjector: this._applicationRef.injector
    });
    this._componentRef.setInput('isRenderInModal', true);
    this._componentRef.setInput('formsValue', this._filters());

    this._dialog
      .open<
        Record<string, any> | undefined,
        FiltersModalData,
        FiltersModalComponent
      >(FiltersModalComponent, {
        panelClass: 'move-modal',
        width: 'min(100%, 960px)',
        viewContainerRef: this._viewRef,
        data: {
          formComponentRef: this._componentRef
        }
      })
      .closed.pipe(
        filter((data): data is Record<string, any> => data !== undefined),
        tap((data) => this._filtersFromDialog$.next(data))
      )
      .subscribe();
  }
}
