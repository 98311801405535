import { inject } from "@angular/core";
import { Note } from "@simlab/data-access";
import { NEW_NOTE_LOCALLY_ID, NotesFacade } from "@simlab/data-store";
import { AbstractConstructor, Constructor } from "@simlab/design/internal";
import { PanelRightService, StagesRootService } from "@simlab/feature/stages";
import { firstValueFrom, take, tap } from "rxjs";

export type CanDeleteNote = {
  deleteNote(): void;
}

type TCanDeleteNote = Constructor<CanDeleteNote> & AbstractConstructor<CanDeleteNote>;
export function mixinDeleteNote<T extends AbstractConstructor<any>>(base: T): TCanDeleteNote & T;
export function mixinDeleteNote<T extends Constructor<any>>(base: T) {
  return class extends base {
    private readonly _panelRightService = inject(PanelRightService);
    private readonly _notesFacade = inject(NotesFacade);
    private readonly _stagesRootService =  inject(StagesRootService);
  
  
    private readonly _selectedNote$ = this._panelRightService.selectedNote$;
  
    deleteNote() {
      firstValueFrom(
        this._selectedNote$.pipe(
          take(1),
          tap((note: Note) => {
            if (note.id === NEW_NOTE_LOCALLY_ID) {
              this._notesFacade.deleteNoteLocally();
            } else {
              this._notesFacade.deleteNote(note.id, note.stageId);
            }
            this._stagesRootService.rightPanelState = 'close';
          })
        )
      );
    }
  }
}