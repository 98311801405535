import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Stage, StageComponent } from '@simlab/data-access';
import { ComponentsFacade, StagesFacade } from '@simlab/data-store';
import { DesignFlatButton } from '@simlab/design/button';

import {
  ConfirmationModalRef,
  DialogResponse,
  ExitCompareConfirmationDialogComponent,
  MODAL_DATA,
  ModalService
} from '@simlab/ui/modal';
import {
  BehaviorSubject,
  Subject,
  catchError,
  exhaustMap,
  filter,
  firstValueFrom,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { IframeTrackerDirective } from '../../directives/iframe-tracker.directive';
import { MatterportSplitViewControl } from '../../models/matterport-split-view.control.interface';
import { matterportSplitViewControlToken } from '../../tokens/matterport-control.token';
import { MatterportCompareElementComponent } from '../matterport-compare-element/matterport-compare-element.component';

export interface SynchronizedComponentV3 extends StageComponent {
  stageDate: string;
  stageName: string;
}

const EXIT_COMPARE_VIEW_PROMPT = $localize`:@@EXIT_COMPARE_VIEW_PROMPT:Are you sure you want to Exit compare view?`;
const EXIT_COMPARE_VIEW = $localize`:@@EXIT_COMPARE_VIEW:Exit compare view`;
const EXIT = $localize`:@@EXIT:Exit`;

@Component({
  standalone: true,
  selector: 'simlab-matterport-split-view',
  templateUrl: './matterport-slit-view.component.html',
  styleUrls: ['./matterport-slit-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DesignFlatButton,
    MatterportCompareElementComponent,
    IframeTrackerDirective
  ],
  providers: [StagesFacade, ComponentsFacade, StagesFacade]
})
export class MatterportSlitViewComponent implements OnInit, OnDestroy {
      public readonly matterportSplitViewControl = inject<MatterportSplitViewControl>(matterportSplitViewControlToken, { skipSelf: true, optional: true });
  @ViewChild('src', { read: MatterportCompareElementComponent, static: true })
  src!: MatterportCompareElementComponent;
  @ViewChild('dst', { read: MatterportCompareElementComponent, static: true })
  dst!: MatterportCompareElementComponent;

  private readonly _stagesFacade = inject(StagesFacade);
  private readonly _modalService = inject(ModalService);
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _componentsFacade = inject(ComponentsFacade);

  private readonly _destroySource: Subject<void> = new Subject<void>();
  private readonly _projectId: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);

  @Input() selectedComponent!: StageComponent;
  @Input() initialPosition?: any;
  @Input() initialFloor?: number;

  private _stages: Stage[] = [];
  public get stages(): Stage[] {
    return this._stages;
  }
  public set stages(value: Stage[]) {
    this._stages = value;
  }

  private _stageInitSecond!: StageComponent;
  public get stageInitSecond(): StageComponent {
    return this._stageInitSecond;
  }
  public set stageInitSecond(value: StageComponent) {
    this._stageInitSecond = value;
  }

  ngOnInit(): void {
    this._initProjectId();
    this._initParameters();
    this._positionSynchronizationObserver(this.dst, this.src);
    this._positionSynchronizationObserver(this.src, this.dst);
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  private _initProjectId() {
    this._activatedRoute.params
      .pipe(
        filter((params: Params) => params !== undefined),
        tap((params: Params) => this._projectId.next(params['projectId'])),
        take(1),

        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  private _initParameters() {
    this.initialFloor;
    this.src.focus = true;
    firstValueFrom(
      this._stagesFacade.allStages$.pipe(
        map((stages: Stage[]) => {
          const stagesFiltered = stages.filter(
            (stage: Stage) =>
              stage.hasComponents && stage.hasSynchronizedMatterport
          );
          this.stages = stagesFiltered;
          return stagesFiltered;
        }),
        switchMap(([stages]: Stage[]) =>
          this._componentsFacade.matterportComponentsForStage$(stages.id)
        ),
        tap(([stageComponents]: StageComponent[]) => {
          this.stageInitSecond = stageComponents;
        })
      )
    );
  }

  private _positionSynchronizationObserver(
    src: MatterportCompareElementComponent,
    dst: MatterportCompareElementComponent
  ) {
    src.cameraMovement$
      .pipe(
        exhaustMap((camera) => dst.checkAndSwitchComponentByCamera(camera)),
        mergeMap((camera) => dst.moveToCamera(camera)),
        takeUntil(this._destroySource),
        catchError((e) => {
          return of(e);
        })
      )
      .subscribe();
  }

  private _exitCompareViewModal(): ConfirmationModalRef<ExitCompareConfirmationDialogComponent> {
    return this._modalService.createModalWithProviders(
      ExitCompareConfirmationDialogComponent,
      {
        width: 'min(90%, 400px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: {
            text: EXIT_COMPARE_VIEW_PROMPT,
            title: EXIT_COMPARE_VIEW,
            confirmButton: EXIT
          }
        }
      ]
    );
  }

  close(
    src: MatterportCompareElementComponent,
    dst: MatterportCompareElementComponent
  ) {
    firstValueFrom(
      this._exitCompareViewModal().events$.pipe(
        map((response: DialogResponse<unknown>) => response.state),
        filter((state: boolean) => state),
        tap(() => {
          const element = src.focus ? src : dst;
          const stageId = element.selectedStageId;
          const camera = element.latestCameraPose;
          const floor = element.latestFloor;

          this.matterportSplitViewControl?.exitCompareView(
            stageId,
            camera,
            floor
          );
        }),
        takeUntil(this._destroySource)
      )
    );
  }
}
