import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { ApiFacadeService, StageComponent } from '@simlab/data-access';
// import { PrivilegesFacade } from 'libs/feature/organizations/src/lib/+state/privileges/privileges.facade';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  ComponentsFacade,
  ProjectPrivilegesFacade,
  StagesFacade
} from '@simlab/data-store';
import { DesignFlatButton, DesignIconButton } from '@simlab/design/button';
import { FormatDatePipe } from '@simlab/design/format-date';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { UiIconModule } from '@simlab/ui/icon';
import { InfoButton, UiImageInfoModule } from '@simlab/ui/image-info';
import { UiMenuModule } from '@simlab/ui/menu';
import { RouterStoreParams } from '@simlab/util-shared';
import {
  Observable,
  combineLatest,
  filter,
  firstValueFrom,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  tap
} from 'rxjs';
import { ComponentListService } from '../../services/component-list.service';
import { ProjectLimitsService } from '../../services/project-limits.service';
import { StagesRootService } from '../../services/stages-root.service';

const DESCRIPTION_IMAGE_INFO: string[] = [
  $localize`:@@THERE_ARE_NO_COMPONENTS:There are no components yet.`,
  $localize`:@@TAP_TO_ADD_COMPONENT:Tap +Add component to add one.`
];

@Component({
  selector: 'feature-stages-component-list',
  templateUrl: './component-list.component.html',
  styleUrls: ['./component-list.component.scss'],
  providers: [ComponentListService],
  standalone: true,
  imports: [
    CommonModule,
    CdkTableModule,
    CdkTreeModule,
    OverlayModule,
    UiMenuModule,
    UiMenuPanelModule,
    UiImageInfoModule,
    DesignFlatButton,
    DesignIconButton,
    DesignIcon,
    UiIconModule,
    FormsModule,
    ReactiveFormsModule,
    FormatDatePipe
  ]
})
export class ComponentListComponent {
  readonly descriptionImageInfo: string[] = DESCRIPTION_IMAGE_INFO;
  @ViewChildren('inputRef') private _inputs!: QueryList<ElementRef>;
  readonly componentsGroup: FormGroup = new FormGroup({});

  readonly canAccessSynchronizationPanel$: Observable<boolean> =
    this.privilegesFacade.canAccessSynchronizationPanel$;

  readonly canAccessSynchronizationPanelWithComponentsCheck$: Observable<boolean> =
    this.privilegesFacade.canAccessSynchronizationPanel$;

  readonly addEditDeleteOwnComponents$: Observable<boolean> =
    this.privilegesFacade.AddEditDeleteComponentsInOwnProjects$;

  readonly buttonInfo: InfoButton = {
    title: $localize`:@@ADD_COMPONENT:Add component`,
    icon: 'Add',
    action$: of(true).pipe(tap(() => this.addComponent()))
  };

  readonly addEditDeleteElements$ = this.stagesRootService.owner$.pipe(
    switchMap((owner: boolean) =>
      iif(
        () => owner,
        this.privilegesFacade.AddEditDeleteComponentsInOwnProjects$,
        this.privilegesFacade.addEditDeleteComponentsInOtherUsersProjects$
      )
    )
  );

  readonly projectIsActive$: Observable<boolean> =
    this.projectLimitsService.projectIsActive$;

  readonly selectedStage$: Observable<string> = this.stage.selectedId$;

  constructor(
    private readonly apiFacadeService: ApiFacadeService,
    private readonly stage: StagesFacade,
    private readonly stagesRootService: StagesRootService,
    private readonly componentList: ComponentListService,
    private readonly privilegesFacade: ProjectPrivilegesFacade,
    private readonly stagesFacade: StagesFacade,
    private readonly componentFacade: ComponentsFacade,
    private readonly projectLimitsService: ProjectLimitsService
  ) {}

  editNameComponentId: string | undefined;

  readonly displayedColumns: string[] = [
    'icon',
    'name',
    'isSynchronizingAccepted',
    'contextMenu'
  ];

  readonly componentList$: Observable<StageComponent[]> =
    this.stage.selectedId$.pipe(
      filter((stageId: string) => stageId !== undefined),
      switchMap((stageId) =>
        combineLatest([
          this.componentFacade.matterportComponentsForStage$(stageId),
          this.componentFacade.blueprintComponentsForStage$(stageId, true)
        ]).pipe(
          map(
            ([matterportComponents, blueprintComponents]: [
              StageComponent[],
              StageComponent[]
            ]) => [...matterportComponents, ...blueprintComponents]
          )
        )
      ),
      tap((stages: StageComponent[]) => {
        stages.forEach((element: StageComponent) => {
          this.componentsGroup.addControl(
            element.id,
            new FormControl({ value: element.name, disabled: true })
          );
        });
      })
    );

  trackByComponentId(index: number, item: StageComponent): string {
    return item.id;
  }

  openTagImporter(element: StageComponent): void {
    this.componentList.openTagImporter(element);
  }

  renameComponent(componentId: string): void {
    this.editNameComponentId = componentId;

    this.componentsGroup.get([`${componentId}`])?.enable();
    const ele = this._inputs.find(
      (el: ElementRef<any>) => el.nativeElement.id === componentId
    )?.nativeElement as HTMLInputElement;
    ele.focus();
    ele.select();
  }

  startSynchronization(component: StageComponent) {
    this.componentList.startSynchronization(component);
  }

  private _updateSynchronizedState(
    stageId: string,
    componentId: string,
    state: boolean
  ): void {
    this.componentFacade.setSynchronizingAccepted(stageId, componentId, state);
  }
  onFocus(input: HTMLInputElement, span: HTMLSpanElement) {
    input.select();
    if (!span.classList.contains('input-selected')) {
      span.classList.add('input-selected');
    }
  }
  synchronizeComponent(component: StageComponent) {
    this.componentFacade.setSelectedComponentById(component.id);
    firstValueFrom(
      this.stagesFacade.getRouteNestedParams$.pipe(
        mergeMap((routerParams: RouterStoreParams) =>
          iif(
            () => routerParams.params['projectId'],
            this.apiFacadeService.projects.checkSynchronizationInStages({
              projectId: routerParams.params['projectId']
            }),
            of(false)
          )
        ),
        tap((data) => {
          if (data) {
            this.componentList.openSynchronizeConfirmationDialog(component);
          } else {
            this.componentList.warningDialog(
              $localize`:@@USE_TOOL_NEED_HAVE_REFERENCE_COMPONENT:In order to use this tool you must have at least one reference component. To create reference component mark it as "Set as synchronized"`
            );
          }
        })
      )
    );
  }

  setAsSynchronized(element: StageComponent) {
    this._updateSynchronizedState(element.stageId, element.id, true);
  }

  setAsDesynchronized(element: StageComponent) {
    firstValueFrom(
      this.componentList.openDeleteSynchronizationConfirmationDialog(element)
    );
  }

  removeComponent(component: StageComponent) {
    firstValueFrom(this.componentList.openDeleteConfirmationDialog(component));
  }

  updateName(event: Event, component: StageComponent) {
    this.componentList.renameComponent(
      (event.target as HTMLInputElement)?.value,
      component
    );
  }

  stopEditing(componentId: string, span: HTMLSpanElement): void {
    this.componentsGroup.get([`${componentId}`])?.disable();
    this.editNameComponentId = undefined;
    if (span.classList.contains('input-selected')) {
      span.classList.remove('input-selected');
    }
  }

  addComponent(): void {
    firstValueFrom(this.componentList.addComponent());
  }
}
