import {
  ACCEPTED_AUDIO,
  ACCEPTED_DOCUMENT,
  ACCEPTED_PHOTO,
  ACCEPTED_VIDEO,
  BlobBody,
  CreateRootNote,
  IVector3,
  NoteType,
  RootNotePosition,
  RootNoteStatus,
  ScanProviderEnum
} from '@simlab/data-access';
import { Mattertag } from '@simlab/matterport/api';
import { TransformConverter } from '@simlab/transform';
import { Vector3 } from 'three';
export const MAX_FILE_NAME_LENGTH = 75;
export const MAX_FILE_SIZE_IN_MB = 50;
export const MAX_ATTACHMENT_NAME_LENGTH = 95;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
export const MAX_PHOTO_FILE_SIZE_IN_MB = 15;
export const MAX_PHOTO_FILE_SIZE = MAX_PHOTO_FILE_SIZE_IN_MB * 1024 * 1024;
export const MAX_DESCRIPTION_SIZE = 2000;

export type ErrorType = 'fileName' | 'attachment' | 'description';

export const ERRORS = {
  SIZE: `Root note media size limit is ${MAX_FILE_SIZE_IN_MB}mb. Skipped.`,
  PHOTO_SIZE: `Root note photo media size limit is ${MAX_PHOTO_FILE_SIZE_IN_MB}mb. Skipped.`,
  EXT: 'Unsupported media type extension. Skipped.',
  ATTACHMENT_NAME_LENGTH: `Attachment name is too long. Trimmed to ${MAX_ATTACHMENT_NAME_LENGTH} characters`,
  DESCRIPTION_LENGTH: `Text in description is too long. Trimmed to ${MAX_DESCRIPTION_SIZE} characters`,
  DESCRIPTION_LENGTH_LIMIT: 'Description length limit was accrued. Skipped',
  UNEXPECTED: 'Unexpected error on file uploading. Please try again later.'
} as const;
type Keys = keyof typeof ERRORS;
export type ReportErrors = {
  name: string;
  tagName: string;
  type: ErrorType;
  errors: (typeof ERRORS)[Keys];
  details?: string;
};
export class MattertagAdapter implements CreateRootNote, RootNotePosition {
  readonly name!: string;
  readonly description: string;
  readonly scanTagId: string | undefined;
  readonly scanProvider = ScanProviderEnum.Matterport;
  readonly positionDto: IVector3;
  readonly rotationDto: {
    x: number;
    y: number;
    z: number;
    w: number;
  } = {
    x: 0,
    y: 0,
    z: 0,
    w: 1
  };
  readonly anchorPointNormalDto: {
    x: number;
    y: number;
    z: number;
  } = {
    x: 0,
    y: 0,
    z: 1
  };
  digitalNotes?: BlobBody[] | undefined;
  public report: ReportErrors[] = [];
  constructor(
    private readonly _tag: Partial<Mattertag>,
    private readonly _transformConverter: TransformConverter,
    readonly status: RootNoteStatus,
    readonly type: NoteType,
    readonly scanId: string
  ) {
    (this.name = this._name),
      (this.description = this._description),
      (this.scanTagId = this._tag.id || undefined),
      (this.positionDto = this._position),
      (this.digitalNotes = this._digitalNotes);
  }

  private get _digitalNotes(): BlobBody[] {
    const fileAttachments: any[] = (this._tag as any)['fileAttachments'];
    const rootNoteMedia: (BlobBody | { skipFile: boolean })[] =
      fileAttachments.map((file) => {
        if (file.bytes > MAX_FILE_SIZE) {
          this.addError({
            tagName: this._tag.label || '',
            type: 'attachment',
            errors: ERRORS.SIZE,
            name: file.filename as string
          });
          return {
            skipFile: true
          };
        }

        const splittedFileName = /[.]/.exec(file.filename)
          ? /[^.]+$/.exec(file.filename)?.[0]
          : undefined;
        const extension = splittedFileName?.length
          ? `${splittedFileName}`
          : undefined;
        const mimeType = file.mimeType;
        const type = this._checkIsSupported(`.${extension}`, mimeType);
        if (type === 'photo' && file.bytes > MAX_PHOTO_FILE_SIZE) {
          this.addError({
            tagName: this._tag.label || '',
            type: 'attachment',
            errors: ERRORS.PHOTO_SIZE,
            name: file.filename as string
          });
          return {
            skipFile: true
          };
        }
        if (type === 'unsupported') {
          this.addError({
            tagName: this._tag.label || '',
            errors: ERRORS.EXT,
            name: file.filename as string,
            type: 'attachment'
          });

          return {
            skipFile: true
          };
        }

        let fileName: string = file.filename;
        if (fileName && fileName.length > MAX_ATTACHMENT_NAME_LENGTH) {
          const fileNameWithoutExt = /^.[^.]*/.exec(fileName)
            ? /^.[^.]*/.exec(fileName)?.[0]
            : fileName;
          fileName = `${fileNameWithoutExt?.substring(
            0,
            MAX_ATTACHMENT_NAME_LENGTH -
              1 - //length to index
              (extension?.length || 0) - //minus extension length
              1 //minus dot (".") separator
          )}.${extension}`;
          this.addError({
            tagName: this._tag.label || '',
            errors: ERRORS.ATTACHMENT_NAME_LENGTH,
            name: file.filename as string,
            type: 'attachment'
          });
        }
        return {
          blobUrl: file.url,
          name: fileName,
          type,
          extension
        } as BlobBody;
      });
    return rootNoteMedia.filter(
      (rootNote: BlobBody | { skipFile: boolean }) => !('skipFile' in rootNote)
    ) as BlobBody[];
  }
  setDigitalNotes(notes: BlobBody[]) {
    this.digitalNotes = notes;
  }
  private _checkIsSupported(
    extension: string | undefined,
    mimeType: string
  ): BlobBody['type'] | 'unsupported' {
    if (
      (extension && ACCEPTED_AUDIO.includes(extension)) ||
      ACCEPTED_AUDIO.includes(mimeType)
    ) {
      return 'audio';
    }
    if (
      (extension && ACCEPTED_DOCUMENT.includes(extension)) ||
      ACCEPTED_DOCUMENT.includes(mimeType)
    ) {
      return 'document';
    }
    if (
      (extension && ACCEPTED_PHOTO.includes(extension)) ||
      ACCEPTED_PHOTO.includes(mimeType)
    ) {
      return 'photo';
    }
    if (
      (extension && ACCEPTED_VIDEO.includes(extension)) ||
      ACCEPTED_VIDEO.includes(mimeType)
    ) {
      return 'video';
    }
    return 'unsupported';
  }

  private get _description() {
    let description = this._tag.description || '';
    if (description.length > MAX_DESCRIPTION_SIZE) {
      description = description.substring(0, MAX_DESCRIPTION_SIZE - 1);
      this.addError({
        tagName: this._tag.label || '',
        errors: ERRORS.DESCRIPTION_LENGTH,
        name: '',
        type: 'description'
      });
    }
    if (this._tag.media) {
      const mediaLink = '\n[media link](' + this._tag.media + ')';
      if (description.length + mediaLink.length < MAX_DESCRIPTION_SIZE) {
        description = `${description}${mediaLink}`;
      } else {
        this.addError({
          tagName: this._tag.label || '',
          errors: ERRORS.DESCRIPTION_LENGTH_LIMIT,
          name: '',
          type: 'description'
        });
      }
    }

    return description;
  }
  private get _name() {
    let name = `[Imported] ${this._tag.label}`;
    if (name.length > MAX_FILE_NAME_LENGTH) {
      name = name.substring(0, MAX_FILE_NAME_LENGTH - 1);
    }
    return name;
  }

  addError(error: ReportErrors): void {
    this.report.push(error);
  }

  private get _position() {
    return this._transformConverter.to3dPositionWithStem(
      new Vector3(
        this._tag.anchorPosition?.x || 0,
        this._tag.anchorPosition?.y || 0,
        this._tag.anchorPosition?.z || 0
      ),
      {
        normal: new Vector3(
          this._tag.stemNormal?.x || 0,
          this._tag.stemNormal?.y || 0,
          this._tag.stemNormal?.z || 0
        ),
        length: this._tag.stemLength || 0
      }
    );
  }

  get rootNote(): CreateRootNote & RootNotePosition {
    return {
      status: this.status,
      type: this.type,
      name: this.name,
      description: this.description,
      scanId: this.scanId,
      scanTagId: this.scanTagId,
      scanProvider: this.scanProvider,
      positionDto: this.positionDto,
      rotationDto: this.rotationDto,
      anchorPointNormalDto: this.anchorPointNormalDto,
      digitalNotes: this.digitalNotes
    };
  }
}
