import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TreeComponent } from './components/tree/tree.component';

@NgModule({
  declarations: [TreeComponent],
  exports: [TreeComponent],
  imports: [CommonModule],
})
export class UiTreeModule {}
