import { Pipe, PipeTransform } from '@angular/core';
import { RFIStatus } from '@simlab/procore/models';

const rfiStatusLabel: Record<RFIStatus, string> = {
  [RFIStatus.Draft]: $localize`:@@DRAFT_TEXT:For draft RFIs, Number and Due Date are both suggested values. Neither will be applied until the RFI is Open.`,
  [RFIStatus.Open]: $localize`:@@OPEN_TEXT:You have been assigned to this RFI`,
  [RFIStatus.Closed]: $localize`:@@THIS_RFI_HAS_BEEN_CLOSED:This RFI has been closed`
};

@Pipe({ name: 'getRFIStatusText', standalone: true })
export class RFIStatusTextGetterPipe implements PipeTransform {
  transform(status: RFIStatus): string {
    return rfiStatusLabel[status];
  }
}
