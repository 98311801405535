import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';

const TEXT = {
  location: $localize`:@@LOCATION_IN_3D:Location in the 3D`,
  assign: $localize`:@@ASSIGN_LOCATION_3D:Assign location in the 3D`
};

@Component({
  selector: 'common-annotation-panel-location-3d',
  standalone: true,
  imports: [DesignIconButton, DesignIcon],
  template: `
    <div class="content">
      <div>{{ text() }}</div>

      @if (positionExist()) {
        <div class="btns">
          @if (!changeAndSetActionDisabled()) {
            <button
              class="btns__change-location-btn"
              type="button"
              (click)="changeLocationButtonClick.emit()"
            >
              <i designIcon="edit_second" designSize="20px"></i>
              <div i18n="@@EDIT_LOCATION">Edit location</div>
            </button>
          }

          <button
            design-icon-button
            type="button"
            designButtonColor="accent"
            (click)="goToButtonClick.emit()"
          >
            <i designIcon="location" designSize="1.5rem"></i>
          </button>
        </div>
      } @else {
        <button
          design-icon-button
          type="button"
          designButtonColor="accent"
          (click)="setLocationButtonClick.emit()"
          [disabled]="changeAndSetActionDisabled()"
        >
          <i designIcon="add" designSize="1.5rem"></i>
        </button>
      }
    </div>
  `,
  styleUrl: 'annotation-panel-location-3D.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotationPanelLocation3DComponent {
  readonly goToButtonClick = output<void>();
  readonly setLocationButtonClick = output<void>();
  readonly changeLocationButtonClick = output<void>();

  readonly positionExist = input.required<boolean>();
  readonly changeAndSetActionDisabled = input.required<boolean>();

  readonly text = computed(() =>
    this.positionExist() ? TEXT.location : TEXT.assign
  );
}
