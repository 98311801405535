import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as ComponentsActions from '../components/components.actions';
import * as NotesActions from '../notes/notes.actions';
// import { Stage } from '@simlab/data-access';
import * as StagesActions from './stages.actions';
import { StagesApiActions } from './stages.actions';
import { StageWithCount } from './stages.models';

export const STAGES_FEATURE_KEY = 'stages';

export interface State extends EntityState<StageWithCount> {
  selectedId?: string | number; // which Stages record has been selected
  loaded: boolean; // has the Stages list been loaded
  loading: boolean;
  error?: string | null; // last known error (if any)
}

export interface StagesPartialState {
  readonly [STAGES_FEATURE_KEY]: State;
}

export const stagesAdapter: EntityAdapter<StageWithCount> =
  createEntityAdapter<StageWithCount>({
    sortComparer: (item0: StageWithCount, item1: StageWithCount) => {
      const time0 = new Date(item0.stageDate).getTime();
      const time1 = new Date(item1.stageDate).getTime();

      if (time0 > time1) return -1;
      if (time0 < time1) return 1;

      return 0;
    }
  });

export const initialState: State = stagesAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: false
});

export const stagesReducer = createReducer(
  initialState,
  on(StagesActions.init, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(StagesApiActions.noExistStages, (state) => ({
    ...state,
    loaded: true,
    loading: false
  })),
  on(StagesActions.selectInitStage, (state, { selectedId }) => ({
    ...state,
    selectedId
  })),

  on(StagesActions.selectLocalStage, (state) => {
    return {
      ...state,
      selectedId: undefined
    };
  }),

  on(StagesActions.selectedLocalStageSuccess, (state, { selectedId }) => {
    return {
      ...state,
      selectedId
    };
  }),
  on(StagesApiActions.loadStagesSuccess, (state, { stages }) => {
    return stagesAdapter.setAll(stages, {
      ...state,
      loaded: true,
      loading: false
    });
  }),

  on(StagesActions.decrementRfi, (state) => {
    const selectedId = state.selectedId;
    if (!selectedId) return state;

    const rfiCount = state.entities[selectedId]?.rfiCount;
    if (rfiCount === undefined) return state;

    const updatedStageWithCount: Update<StageWithCount> = {
      changes: {
        rfiCount: rfiCount - 1
      },
      id: selectedId as string
    };

    return stagesAdapter.updateOne(updatedStageWithCount, state);
  }),

  on(
    StagesActions.changeRfiCount,
    (state, { stageId, rfiStatusName, change }) => {
      const entity = state.entities[stageId];
      if (!entity) return state;

      const rfiCount = entity.rfiCount;
      const rfiStatuses = entity.rfiStatuses;
      if (rfiCount === undefined || rfiStatuses === undefined) return state;

      const updatedStageWithCount: Update<StageWithCount> = {
        changes: {
          rfiStatuses: {
            ...rfiStatuses,
            [rfiStatusName]: rfiStatuses[rfiStatusName] + change,
            total: rfiStatuses.total + change
          },
          rfiCount: rfiCount + change
        },
        id: stageId
      };

      return stagesAdapter.updateOne(updatedStageWithCount, state);
    }
  ),

  on(
    StagesActions.changePunchItemCount,
    (state, { stageId, punchItemStatusName, change }) => {
      const entity = state.entities[stageId];
      if (!entity || entity.punchItemCounts === undefined) return state;

      const punchItemCount = entity.punchItemCount;
      if (punchItemCount === undefined) return state;

      const updatedStageWithCount: Update<StageWithCount> = {
        changes: {
          punchItemCounts: {
            ...entity.punchItemCounts,
            [punchItemStatusName]:
              entity.punchItemCounts[punchItemStatusName] + change
          },
          punchItemCount: punchItemCount + change
        },
        id: stageId
      };
      return stagesAdapter.updateOne(updatedStageWithCount, state);
    }
  ),

  on(StagesActions.decrementPunchItem, (state) => {
    const selectedId = state.selectedId;
    if (!selectedId) return state;

    const punchItemCount = state.entities[selectedId]?.punchItemCount;
    if (punchItemCount === undefined) return state;

    const updatedStageWithCount: Update<StageWithCount> = {
      changes: {
        punchItemCount: punchItemCount - 1
      },
      id: selectedId as string
    };

    return stagesAdapter.updateOne(updatedStageWithCount, state);
  }),

  on(StagesActions.clearStore, (state) => ({
    ...stagesAdapter.removeAll(state),
    selectedId: ''
  })),

  on(StagesActions.clearSelection, (state) => ({
    ...state,
    selectedId: '',
    loaded: false
  })),

  on(StagesActions.setSynchronizedMatterport, (state, action) => {
    const updatedStageWithCount: Update<StageWithCount> = {
      changes: {
        hasSynchronizedMatterport: true
      },
      id: action.stageId
    };

    return stagesAdapter.updateOne(updatedStageWithCount, state);
  }),

  on(StagesApiActions.loadStagesFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    loading: false,
    error
  })),
  on(StagesApiActions.addStage, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(StagesApiActions.removeAllStages, (state) => {
    return { ...stagesAdapter.removeAll(state), loaded: true };
  }),
  on(StagesApiActions.addStageSuccess, (state, { stage }) => {
    const noteCounts = {
      totalRootNotesCount: 0,
      informationRootNotesCount: 0,
      pendingRootNotesCount: 0,
      inProgressRootNotesCount: 0,
      unresolvedRootNotesCount: 0,
      resolvedRootNotesCount: 0
    };

    return stagesAdapter.addOne(
      { noteCounts, ...stage },
      { ...state, loaded: true, loading: false }
    );
  }),
  on(StagesApiActions.addStageFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    loading: false,
    error
  })),
  on(StagesApiActions.removeStage, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(StagesApiActions.removeStageSuccess, (state, { stageId }) =>
    stagesAdapter.removeOne(stageId, { ...state, loaded: true })
  ),
  on(StagesApiActions.removeStageFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error
  })),
  on(StagesApiActions.updateStageName, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(StagesApiActions.updateStageSuccess, (state, action) =>
    stagesAdapter.updateOne(action.update, { ...state, loaded: true })
  ),
  on(StagesApiActions.updateStageDescription, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(StagesApiActions.updateStageDescriptionSuccess, (state, action) =>
    stagesAdapter.updateOne(action.update, { ...state, loaded: true })
  ),
  on(StagesApiActions.updateStageDescriptionFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error
  })),

  on(
    StagesApiActions.updateStageCounter,
    (state, { stageId, noteStatus, noteType }) => {
      const forUpdate: StageWithCount = {
        ...state.entities[stageId]
      } as StageWithCount;

      if (forUpdate && forUpdate.noteCounts) {
        const noteCounts = { ...forUpdate.noteCounts };
        console.log(noteType);
        if (noteType === 'Information') {
          noteCounts.informationRootNotesCount += 1;
        } else {
          switch (noteStatus) {
            case 'Pending':
              noteCounts.pendingRootNotesCount += 1;
              break;
            case 'InProgress':
              noteCounts.inProgressRootNotesCount += 1;
              break;
            case 'Resolved':
              noteCounts.resolvedRootNotesCount += 1;
              break;
            case 'Unresolved':
              noteCounts.unresolvedRootNotesCount += 1;
              break;
          }
        }

        noteCounts.totalRootNotesCount += 1;

        const updatedStageWithCount: Update<StageWithCount> = {
          changes: {
            noteCounts
          },
          id: stageId
        };

        return stagesAdapter.updateOne(updatedStageWithCount, state);
      }

      return state;
    }
  ),

  on(NotesActions.deleteNoteSuccess, (state, { noteId, stageId }) => {
    const forUpdate: StageWithCount = {
      ...state.entities[stageId]
    } as StageWithCount;

    if (forUpdate && forUpdate.noteCounts) {
      const noteCounts = { ...forUpdate.noteCounts };
      noteCounts.informationRootNotesCount -= 1;
      noteCounts.totalRootNotesCount -= 1;

      const updatedStageWithCount: Update<StageWithCount> = {
        changes: {
          noteCounts
        },
        id: stageId
      };

      return stagesAdapter.updateOne(updatedStageWithCount, state);
    }

    return state;
  }),

  on(NotesActions.addSimpleNoteSuccess, (state, { note }) => {
    const forUpdate: StageWithCount = {
      ...state.entities[note.stageId]
    } as StageWithCount;

    if (forUpdate && forUpdate.noteCounts) {
      const noteCounts = { ...forUpdate.noteCounts };
      noteCounts.informationRootNotesCount += 1;
      noteCounts.totalRootNotesCount += 1;

      const updatedStageWithCount: Update<StageWithCount> = {
        changes: {
          noteCounts
        },
        id: note.stageId
      };

      return stagesAdapter.updateOne(updatedStageWithCount, state);
    }

    return state;
  }),
  on(NotesActions.removeNote, (state, { response }) => {
    const forUpdate: StageWithCount = {
      ...state.entities[response.stageId]
    } as StageWithCount;

    if (forUpdate && forUpdate.noteCounts) {
      const noteCounts = { ...forUpdate.noteCounts };

      if (response.type === 'Information') {
        noteCounts.informationRootNotesCount -= 1;
      } else {
        switch (response.status) {
          case 'Pending':
            noteCounts.pendingRootNotesCount -= 1;
            break;
          case 'InProgress':
            noteCounts.inProgressRootNotesCount -= 1;
            break;
          case 'Resolved':
            noteCounts.resolvedRootNotesCount -= 1;
            break;
          case 'Unresolved':
            noteCounts.unresolvedRootNotesCount -= 1;
            break;
        }
      }

      noteCounts.totalRootNotesCount -= 1;

      const updatedStageWithCount: Update<StageWithCount> = {
        changes: {
          noteCounts
        },
        id: response.stageId
      };

      return stagesAdapter.updateOne(updatedStageWithCount, state);
    }

    return state;
  }),

  on(StagesActions.setHasComponent, (state, action) => {
    const updatedStageWithCount: Update<StageWithCount> = {
      changes: {
        hasComponents: true
      },
      id: action.stageId
    };

    return stagesAdapter.updateOne(updatedStageWithCount, state);
  }),
  on(ComponentsActions.deleteSynchronizationSuccess, (state, action) => {
    const updatedStageWithCount: Update<StageWithCount> = {
      changes: {
        hasSynchronizedMatterport: false
      },
      id: action.stageId
    };

    return stagesAdapter.updateOne(updatedStageWithCount, state);
  }),
  on(StagesActions.resetProcoreCountsFromStages, (state, _) => {
    const updatedEntities = Object.entries(state.entities).reduce(
      (curr, [key, value]) => {
        curr.push({
          id: key,
          changes: {
            ...value,
            punchItemCounts: {
              punchItemsWithActionNumber: 0,
              punchItemsWithNoActionNumber: 0
            },
            punchItemCount: 0,
            rfiCount: 0,
            rfiStatuses: {
              closed: 0,
              draft: 0,
              open: 0,
              total: 0
            }
          }
        });

        return curr;
      },
      [] as Update<StageWithCount>[]
    );

    return stagesAdapter.updateMany(updatedEntities, state);
  })
);

export function reducer(state: State | undefined, action: Action) {
  return stagesReducer(state, action);
}
