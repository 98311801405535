import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import {
  ApiFacadeService,
  IVector3,
  NoteCounts,
  Stage,
  StageComponent
} from '@simlab/data-access';
import { RouterStoreParams } from '@simlab/util-shared';
import {
  catchError,
  combineLatest,
  exhaustMap,
  filter,
  first,
  firstValueFrom,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import { Vector3 } from 'three';
import { ComponentsFacade } from '../components/components.facade';
import { ComponentHelper } from '../models/find-closest';
import { NotesFacade } from '../notes/notes.facade';
import * as StagesActions from './stages.actions';
import { StagesApiActions } from './stages.actions';
import { StagesFacade } from './stages.facade';
import { StageWithCount } from './stages.models';
@Injectable()
export class StagesEffects {
  private readonly _actions$ = inject(Actions);
  private readonly _apiFacadeService = inject(ApiFacadeService);
  private readonly _componentsFacade = inject(ComponentsFacade);
  private readonly _stagesFacade = inject(StagesFacade);
  private readonly _notesFacade = inject(NotesFacade);
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);

  init$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(StagesActions.init),
        switchMap(
          (action: { projectId: string; stageId: string | undefined }) => {
            return this._apiFacadeService.stages
              .getStagesForProject({
                projectId: action.projectId
              })
              .pipe(
                mergeMap((stages: Stage[]) =>
                  this._stagesFacade.getRouteNestedParams$.pipe(
                    take(1),
                    mergeMap((routerParams: RouterStoreParams) => {
                      const stagesWithCount = stages.map((stage: Stage) =>
                        stage.hasRootNotes &&
                        routerParams.params['token'] === undefined
                          ? this._apiFacadeService.rootNotes
                              .getRootNoteCountsForStage({
                                stageId: stage.id
                              })
                              .pipe(
                                map((noteCounts: NoteCounts) => {
                                  return {
                                    ...stage,
                                    noteCounts: noteCounts,
                                    punchItemCounts:
                                      stage.punchItemStatuses?.reduce(
                                        (acc, current) => {
                                          acc.punchItemsWithActionNumber +=
                                            current.punchItemsWithActionNumber;
                                          acc.punchItemsWithNoActionNumber +=
                                            current.punchItemsWithNoActionNumber;

                                          return acc;
                                        },
                                        {
                                          punchItemsWithActionNumber: 0,
                                          punchItemsWithNoActionNumber: 0
                                        } as Exclude<
                                          StageWithCount['punchItemCounts'],
                                          undefined
                                        >
                                      ) ?? {
                                        punchItemsWithNoActionNumber: 0,
                                        punchItemsWithActionNumber: 0
                                      }
                                  } as StageWithCount;
                                })
                              )
                          : of({
                              ...stage,
                              noteCounts: {
                                inProgressRootNotesCount: 0,
                                informationRootNotesCount: 0,
                                pendingRootNotesCount: 0,
                                resolvedRootNotesCount: 0,
                                totalRootNotesCount: 0,
                                unresolvedRootNotesCount: 0
                              } as NoteCounts,
                              punchItemCounts: {
                                punchItemsWithNoActionNumber: 0,
                                punchItemsWithActionNumber: 0
                              }
                            } as StageWithCount)
                      );
                      if (stagesWithCount.length === 0) {
                        this._stagesFacade.noExistStages();
                      }

                      return forkJoin([
                        forkJoin(stagesWithCount.length ? stagesWithCount : []),
                        of(routerParams.params['token'] === undefined)
                      ]);
                    }),
                    tap(
                      ([stages, notPublicLink]: [
                        StageWithCount[],
                        boolean
                      ]) => {
                        let number = 1;
                        stages = stages.map((stage) => {
                          const stageWithNumber = {
                            ...stage,
                            sequenceNumber: number++
                          };
                          return stageWithNumber;
                        });
                        const stageId =
                          action.stageId ?? stages.at(-1)?.id ?? '';

                        this._stagesFacade.loadStagesSuccess(stages);
                        this._stagesFacade.initSetStage(stageId);
                        this._componentsFacade.initSetComponent(stageId);

                        if (stageId && notPublicLink) {
                          this._router.navigate([], {
                            replaceUrl: true,
                            queryParams: { stageId: stageId },
                            queryParamsHandling: 'merge'
                          });
                        }
                      }
                    ),
                    catchError((error) => {
                      console.error(error);
                      return of(StagesApiActions.loadStagesFailure({ error }));
                    })
                  )
                ),
                catchError((error) => {
                  console.error(error);
                  return of(StagesApiActions.loadStagesFailure({ error }));
                })
              );
          }
        )
      ),
    { dispatch: false }
  );

  addStage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(StagesApiActions.addStage),
      exhaustMap((action) => {
        return this._apiFacadeService.stages
          .addStageToProject(action.stage)
          .pipe(
            switchMap((response: string) => {
              return this._apiFacadeService.stages.getStageForProject({
                stageId: response,
                projectId: action.stage.projectId
              });
            })
          );
      }),
      switchMap((stage: Stage) =>
        of(StagesApiActions.addStageSuccess({ stage })).pipe(
          tap((result) => {
            this._router.navigate([], {
              replaceUrl: true,
              queryParams: { stageId: result.stage.id },
              queryParamsHandling: 'merge'
            });
          })
        )
      ),
      catchError((error) => {
        console.log(error);
        return of(StagesApiActions.addStageFailure({ error }));
      })
    )
  );

  removeStage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(StagesApiActions.removeStage),
      exhaustMap((action) =>
        this._apiFacadeService.stages
          .removeStageFromProject({
            projectId: action.projectId,
            stageId: action.stageId
          })
          .pipe(map(() => action.stageId))
      ),
      switchMap((stageId: string) =>
        of(
          StagesApiActions.removeStageSuccess({ stageId }),
          StagesActions.selectLocalStage()
        )
      ),
      catchError((error) => {
        console.log(error);
        return of(StagesApiActions.removeStageFailure({ error }));
      })
    )
  );

  selectLocalStage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(StagesActions.selectLocalStage),
      switchMap(() => this._stagesFacade.allStages$.pipe(first())),
      map((stages: StageWithCount[]) => {
        return StagesActions.selectedLocalStageSuccess({
          selectedId: stages[0]?.id ?? ''
        });
      })
    )
  );

  updateStage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(StagesApiActions.updateStageName),
      exhaustMap((action) => {
        return this._apiFacadeService.stages
          .editStageName({ id: action.stageId, newName: action.newName })
          .pipe(map(() => action));
      }),
      switchMap((action) => {
        const update: Update<StageWithCount> = {
          changes: {
            name: action.newName
          },
          id: action.stageId
        };
        return of(StagesApiActions.updateStageSuccess({ update }));
      }),
      catchError((error) => {
        console.log(error);
        return of(StagesApiActions.updateStageFailure({ error }));
      })
    )
  );

  addStageSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(StagesApiActions.addStageSuccess),
        tap((action: { stage: Stage }) => {
          this._stagesFacade.setSelectedStageId(action.stage.id);
        })
      ),
    { dispatch: false }
  );

  editStage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(StagesApiActions.editStage),
      mergeMap((action: { id: string; name: string; stageDate: string }) =>
        this._apiFacadeService.stages
          .editStage({
            id: action.id,
            newName: action.name,
            newStageDate: action.stageDate
          })
          .pipe(
            switchMap((results) => {
              const update: Update<StageWithCount> = {
                changes: {
                  name: action.name,
                  stageDate: action.stageDate,
                  modifiedAt: results.modifiedAt
                },
                id: action.id
              };
              return of(StagesApiActions.updateStageSuccess({ update }));
            })
          )
      ),
      catchError((error) => {
        console.log(error);
        return of(StagesApiActions.editStageFailure({ error }));
      })
    )
  );

  updateStageDescription$ = createEffect(() =>
    this._actions$.pipe(
      ofType(StagesApiActions.updateStageDescription),
      exhaustMap((action) => {
        return this._apiFacadeService.stages
          .editStageDescription({
            id: action.stageId,
            newDescription: action.newDescription
          })
          .pipe(map(() => action));
      }),
      switchMap((action) => {
        const update: Update<StageWithCount> = {
          changes: {
            description: action.newDescription
          },
          id: action.stageId
        };
        return of(StagesApiActions.updateStageDescriptionSuccess({ update }));
      }),
      catchError((error) => {
        console.log(error);
        return of(StagesApiActions.updateStageDescriptionFailure({ error }));
      })
    )
  );

  selectionChanges$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(StagesActions.selectedLocalStageSuccess),
        tap((action) => {
          if (!action.selectedId) {
            this._notesFacade.loadNoteSuccess([]);
          } else {
            this._router.navigate([], {
              relativeTo: this._route,
              queryParams: {
                stageId: action.selectedId,
                sidenavContent: null,
                noteId: null,
                rfiId: null,
                punchItemId: null
              },
              queryParamsHandling: 'merge'
            });
          }
        }),
        filter((action) => !!action.selectedId),
        tap((action) => {
          this._selectedComponent(action.selectedId, action.cameraPose);
        })
      ),
    { dispatch: false }
  );

  private _selectedComponent(selectedId: string, position?: IVector3) {
    firstValueFrom(
      combineLatest([
        this._componentsFacade.allComponents$,
        this._componentsFacade.selectedComponent$
      ]).pipe(
        tap(
          ([allComponents, selectedComponent]: [
            StageComponent[] | undefined,
            StageComponent | undefined
          ]) => {
            if (allComponents) {
              const stageComponents = allComponents?.filter(
                (component: StageComponent) => {
                  return (
                    component.stageId === selectedId &&
                    component.isSynchronizingAccepted &&
                    !component.extension
                  );
                }
              );
              if (stageComponents !== undefined && stageComponents.length > 0) {
                const positionHelp = position
                  ? position
                  : selectedComponent?.sweeps &&
                      selectedComponent?.sweeps.length > 0
                    ? selectedComponent?.sweeps[0]?.position
                    : undefined;

                if (positionHelp) {
                  const { x, y, z } = positionHelp;

                  const closestComponent = ComponentHelper.findClosestId(
                    new Vector3(x, y, z),
                    stageComponents
                  );
                  this._componentsFacade.setSelectedComponentById(
                    closestComponent?.id ?? stageComponents[0].id
                  );
                } else {
                  this._componentsFacade.setSelectedComponentById(
                    stageComponents[0].id
                  );
                }
              } else {
                const componentsForStage = allComponents.filter(
                  (component: StageComponent) =>
                    component.stageId === selectedId
                );

                this._componentsFacade.setSelectedComponentById(
                  componentsForStage[0]?.id ?? undefined
                );
              }
            } else {
              this._componentsFacade.setSelectedComponentById(undefined);
            }
          }
        )
      )
    );
  }
}
