import { FocusableOption, ListKeyManagerOption } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UiListOptionBase } from '../ui-list-option-base';
import {
  SELECTION_LIST,
  UiSelectionList,
} from '../ui-selection-list/ui-selection-list.component';

@Component({
  selector: 'design-list-option',
  standalone: true,
  exportAs: 'uiListOption',
  templateUrl: './ui-list-option.component.html',
  styleUrls: ['./ui-list-option.component.scss'],
  host: {
    class: 'ui-list-option',
    role: 'option',
    '[class.ui-list-option--selected]': 'selected',
    '[attr.aria-selected]': 'selected',
    '(blur)': '_handleBlur()',
    '(click)': '_toggleOnInteraction()',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiListOption
  extends UiListOptionBase
  implements OnInit, OnDestroy, FocusableOption, ListKeyManagerOption
{
  /** Value of the option */
  @Input()
  get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    if (this.selected && newValue !== this.value && this._inputsInitialized) {
      this.selected = false;
    }

    this._value = newValue;
  }
  private _value: any;

  @Input()
  get label(): string {
    return this._value;
  }
  set label(value: string) {
    this._label = value;
  }
  private _label: string | undefined;

  /**
   * Emits when the selected state of the option has changed.
   * Use to facilitate two-data binding to the `selected` property.
   * @docs-private
   */
  @Output()
  readonly selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** Whether the option is selected. */
  @Input()
  get selected(): boolean {
    return this._selectionList.selectedOptions.isSelected(this);
  }
  set selected(value: BooleanInput) {
    const isSelected = coerceBooleanProperty(value);

    if (isSelected !== this._selected) {
      this._setSelected(isSelected);

      if (isSelected || this._selectionList.multiple) {
        this._selectionList._reportValueChange();
      }
    }
  }
  private _selected = false;

  /**
   * This is set to true after the first OnChanges cycle so we don't
   * clear the value of `selected` in the first cycle.
   */
  private _inputsInitialized = false;

  constructor(
    @Inject(SELECTION_LIST)
    private readonly _selectionList: UiSelectionList,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _elementRef: ElementRef<HTMLElement>,
  ) {
    super(_elementRef);
  }

  ngOnInit(): void {
    if (
      this._selectionList._value &&
      this._selectionList._value.some((value) =>
        this._selectionList.compareWith(this._value, value),
      )
    ) {
      this._setSelected(true);
    }

    const wasSelected = this._selected;
    // List options that are selected at initialization can't be reported properly to the form
    // control. This is because it takes some time until the selection-list knows about all
    // available options. Also it can happen that the ControlValueAccessor has an initial value
    // that should be used instead. Deferring the value change report to the next tick ensures
    // that the form control value is not being overwritten.
    Promise.resolve().then(() => {
      if (this._selected || wasSelected) {
        this.selected = true;
        this._changeDetectorRef.markForCheck();
      }
    });

    this._inputsInitialized = true;
  }

  ngOnDestroy(): void {
    if (this.selected) {
      // We have to delay this until the next tick in order
      // to avoid changed after checked errors.
      Promise.resolve().then(() => {
        this.selected = false;
      });
    }
  }

  /**
   * Sets the selected state of the option.
   * @returns Whether the value has changed.
   */
  _setSelected(selected: boolean): boolean {
    if (selected === this._selected) {
      return false;
    }

    this._selected = selected;

    if (selected) {
      this._selectionList.selectedOptions.select(this);
    } else {
      this._selectionList.selectedOptions.deselect(this);
    }

    this.selectedChange.emit(selected);
    this._changeDetectorRef.markForCheck();

    return true;
  }

  /** Toggles the selection state of the option. */
  toggle(): void {
    this.selected = !this.selected;
  }

  /**
   * Allows for programmatic focusing of the option.
   * Implemented as a part of FocusableOption
   * */
  focus(): void {
    this._elementRef.nativeElement.focus();
  }

  /**
   * Gets the text label of the list option. Used for the typeahead functionality in the list.
   * Implemented as a part of ListKeyManagerOption
   * */
  getLabel() {
    return this._label || '';
  }

  /** Reports a value change to the ControlValueAccessor */
  _handleBlur() {
    this._selectionList._onTouched();
  }

  /** Toggles the option's value based on a user interaction. */
  _toggleOnInteraction() {
    if (!this.disabled) {
      if (this._selectionList.multiple) {
        this.selected = !this.selected;
        this._selectionList._emitChangeEvent([this]);
      } else if (!this.selected) {
        this.selected = true;
        this._selectionList._emitChangeEvent([this]);
      }
    }
  }

  /**
   * Notifies Angular that the option needs to be checked in the next change detection run.
   * Mainly used to trigger an update of the list option if the disabled state of the selection
   * list changed.
   */
  _markForCheck() {
    this._changeDetectorRef.markForCheck();
  }

  /** Sets the tabindex of the list option. */
  _setTabindex(value: number) {
    this._elementRef.nativeElement.setAttribute('tabindex', value + '');
  }
}
