import { Pipe, PipeTransform } from '@angular/core';
import { DesignSymbolInput } from '@simlab/design/internal';
import { RFIStatus } from '@simlab/procore/models';

export const rfiStatusLabel: Record<RFIStatus, DesignSymbolInput> = {
  [RFIStatus.Draft]: 'InfoCircle',
  [RFIStatus.Open]: 'InfoCircle',
  [RFIStatus.Closed]: 'CheckCircle'
};

@Pipe({ name: 'getRFIStatusIcon', standalone: true })
export class RFIStatusIconGetterPipe implements PipeTransform {
  transform(status: RFIStatus): DesignSymbolInput {
    return rfiStatusLabel[status];
  }
}
