<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogContent>
    <p>{{ data }}</p>
  </ng-container>

  <ng-container designDialogFooter>
    <button design-stroked-button i18n="@@NO" (click)="cancel()">No</button>
    <button
      design-flat-button
      (click)="confirm()"
      designButtonColor="secondary"
    >
      <b i18n="@@YES">Yes</b>
    </button>
  </ng-container>
</design-dialog-wrapper>
