import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Media } from '@simlab/data-access';
import { UiProgressSpinnerComponent } from '@simlab/ui/progress-spinner';
import { Observable, defer } from 'rxjs';
import { BaseMedia } from '../../../directives/base-media.directive';

@Component({
  selector: 'feature-stages-note-media-video-item',
  standalone: true,
  imports: [CommonModule, UiProgressSpinnerComponent],
  templateUrl: './note-media-video-item.component.html',
  styleUrls: ['./note-media-video-item.component.scss']
})
export class NoteMediaVideoItemComponent extends BaseMedia {
  readonly video$: Observable<Media> = defer(() =>
    this.getSingleBlobSecureUrl(this.media)
  );
}
